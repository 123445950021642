import React from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'config/colors'

import { Avatar, Badge, Typography } from 'antd'
const { Text } = Typography

import FreezeIcon from 'assets/icon/assetStatus/freeze.png'
import AutomaticIcon from 'assets/icon/assetStatus/automatic.png'
import StandByFlatIcon from 'assets/icon/assetStatus/standByFlat.png'
import StandByLongIcon from 'assets/icon/assetStatus/standByLong.png'
import StandByShortIcon from 'assets/icon/assetStatus/standByShort.png'

import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import { SessionType } from 'types/enums'

const Legend: React.FC = () => {
  const { t } = useTranslation()

  const session = useSelector(selectOperativeSessionSession)
  const isBacktestOrVirtual = session.type === SessionType.BackTest || session.type === SessionType.Virtual

  return (
    <section>
      <div>
        <Avatar src={FreezeIcon} size={20} shape="square" />
        <Text>{t('assets.status.freeze')}</Text>
      </div>
      <div>
        <Avatar src={AutomaticIcon} size={20} shape="square" />
        <Text>{t('assets.status.automatic')}</Text>
      </div>
      <div>
        <Avatar src={StandByLongIcon} size={20} shape="square" />
        <Text>{t('assets.status.standByLong')}</Text>
      </div>
      <div>
        <Avatar src={StandByShortIcon} size={20} shape="square" />
        <Text>{t('assets.status.standByShort')}</Text>
      </div>
      <div>
        <Avatar src={StandByFlatIcon} size={20} shape="square" />
        <Text>{t('assets.status.standByFlat')}</Text>
      </div>
      <div>
        <Badge color={colors.quit} text={t('status.quit')} />
      </div>
      <div>
        <Badge color={colors.broken} text={t('status.broken')} />
      </div>
      <div>
        <Badge color={colors.updating} text={t('status.updating')} />
      </div>
      <div>
        <Badge color={colors.terminating} text={t('status.terminating')} />
      </div>
      <div>
        <Badge
          color={colors.off}
          text={isBacktestOrVirtual ? t('status.off') : t('assets.status.notActivable')}
        />
      </div>
    </section>
  )
}

export default Legend
