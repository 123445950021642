import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import { ChartChild } from 'types/chart'

interface Props {
  data: [number | Date, number][]
  curved?: boolean
  color?: string
  thickness?: number
}

const Line: React.FC<Props & ChartChild> = ({
  data,
  yDomain,
  xDomain,
  chartWidth: width,
  chartHeight: height,
  color = 'red',
  thickness = 1,
  d3Transform: transform,
  clipId,
  transitionTime,
  curved,
}) => {
  const linea = useRef(null)

  const x = data.map((item) => {
    return item[0]
  })

  const y = data.map((item) => {
    return item[1]
  })

  const getYDomain = () => {
    if (yDomain) {
      return yDomain
    }

    return [d3.min(y), d3.max(y)]
  }

  const getXDomain = () => {
    if (xDomain) {
      return xDomain
    }

    return [d3.min(x), d3.max(x)]
  }

  const render = (dataChanged: boolean) => {
    const transition = dataChanged ? 0 : transitionTime

    const xScale = transform.rescaleX(d3.scaleLinear().domain(getXDomain()).range([0, width]))
    const yScale = d3.scaleLinear().domain(getYDomain()).range([height, 0])

    const generateLine = (): any => {
      const line = d3
        .line()
        .x(function (d) {
          return xScale(d[0])
        })
        .y(function (d) {
          return yScale(d[1])
        })

      if (curved) {
        return line.curve(d3.curveNatural)
      }
      return line
    }

    d3.select(linea.current)
      .attr('clip-path', `url(#${clipId})`)
      .datum(data)
      .transition()
      .duration(transition)
      .ease(d3.easeExpOut)
      .attr('d', generateLine())
  }
  useEffect(() => {
    render(true)
  }, [render, data])

  useEffect(() => {
    render(false)
  }, [render, transform])

  return <path ref={linea} fill="none" stroke={color} strokeWidth={thickness} clipPath="url(#clip)" />
}

export default Line
