import * as K from './constants'
import * as Store from 'types/store'
import { AnyAction } from 'redux'

export function bootstrap() {
  return {
    type: K.APP_BOOTSTRAP,
    payload: null,
  }
}

export function fetchResources() {
  return {
    type: K.FETCH_RESOURCES,
    payload: null,
  }
}

export function fetchHistoricalResources(sessionId: number): AnyAction {
  return {
    type: K.FETCH_HISTORICAL_RESOURCES,
    payload: sessionId,
  }
}

export function setIsSocketAlive(isAlive: boolean): AnyAction {
  return {
    type: K.SET_SOCKET_ALIVE,
    payload: isAlive,
  }
}

export function saveUser(user: Store.UserState): AnyAction {
  return {
    type: K.APP_SAVE_USER,
    payload: user,
  }
}
