import React from 'react'
import HistoricalPanelContentContainer from 'v2/containers/frames/Historical/PortfolioCandleStick/PanelContent'
import PanelContentContainer from 'v2/containers/frames/PortfolioCandleStick/PanelContent'
import * as Store from 'types/store'

interface Props {
  session: Store.Session
}

const PanelContentViewBySession: React.FC<Props> = ({ session }) => {
  if (session.isHistorical) {
    return <HistoricalPanelContentContainer />
  }

  return <PanelContentContainer />
}

export default PanelContentViewBySession
