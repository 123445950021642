import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import * as Store from 'types/store'
import * as UI from 'types/ui'
import { removeFrame, updateFrameOptions } from 'store/dashboard/actions'
import { GridItemActions } from 'types/ui'
import { CandleStickFrameContext, CandleStickFrameContextInterface } from 'contexts/CandleStickFrameContext'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import AddFrameModalContainer from 'v2/containers/settings/modals/AddFrameModal'
import { selectStrategies } from 'store/resources/selectors'
import Unavaliable from 'v2/components/frames/Unavailable'
import { useStrategySigmaSnapshotState } from 'hooks'
import StrategyCandleStick from 'v2/components/frames/StrategyCandleStick'
import { SessionStatus } from 'types/enums'
import sigmaServices from 'config/sigmaServices'
import { StrategyFeed } from 'types/enums.api'

const {
  Strategies: { ReferenceData },
} = sigmaServices

interface Props {
  frame: Store.Frame
}

const getResource = (frame: Store.Frame): Store.Strategy => {
  if (frame.selectedResources.length === 0) {
    return null
  }
  return frame.selectedResources[0].resource as Store.Strategy
}

const StrategyCandleStickContainer: React.FC<Props> = ({ frame }) => {
  const {
    options: { timeFrame, viewType },
  } = frame
  const dispatch = useDispatch()
  const [trace, setTrace] = useState(false)
  const [isFullScreen, setFullScreen] = useState(false)
  const [resource, setResource] = useState<Store.Strategy>(getResource(frame))
  const [isModalVisible, setModalVisible] = useState(false)
  const strategies = useSelector(selectStrategies)
  const [offlineDate, setOfflineDate] = useState<Date>(null)

  useEffect(() => {
    const resource = getResource(frame)
    setResource(resource)
  }, [frame.selectedResources])

  const getHasMissingResource = () => {
    const strategy = strategies.find((item) => item.id === resource?.id)
    if (!strategy) {
      return true
    }

    return false
  }

  const onClose = () => {
    setModalVisible(false)
  }

  const strategy = frame.selectedResources[0].resource as Store.Strategy
  const session = useSelector(selectOperativeSessionSession)
  const sessionId = session.id

  const [sigmas] = useStrategySigmaSnapshotState(
    strategy.id,
    sessionId,
    [ReferenceData.StgRefData, ReferenceData.StgCartesianHeading],
    StrategyFeed.ReferenceData,
  )

  const setTimeFrame = (timeFrame) => {
    const options = { ...frame.options, timeFrame }
    dispatch(updateFrameOptions({ id: frame.id, options }))
  }

  const setViewType = (viewType: UI.ViewType) => {
    const options = { ...frame.options, viewType }
    dispatch(updateFrameOptions({ id: frame.id, options }))
  }

  const setOffline = (goOffline: boolean) => {
    if (goOffline) {
      if (offlineDate === null) {
        //Start offline
        setOfflineDate(new Date())
      }
    } else {
      if (offlineDate !== null) {
        //End offline
        setOfflineDate(null)
      }
    }
  }

  const context: CandleStickFrameContextInterface = {
    id: frame.id,
    squares: {
      x: frame.layout.w,
      y: frame.layout.h,
    },
    isFullscreen: isFullScreen,
    trace,
    timeFrame,
    viewType,
    resource,
    isOffline: () => offlineDate !== null && session.status === SessionStatus.Running,
    setTimeFrame,
    setTrace,
    setViewType,
    setFullScreen,
    setOffline,
  }

  const onItemClick = (action: GridItemActions) => {
    switch (action) {
      case GridItemActions.Close:
        dispatch(removeFrame(frame))
        break
      case GridItemActions.Trace:
        setTrace(!context.trace)
        break
      case GridItemActions.Edit:
        setModalVisible(true)
        break
    }
  }

  if (getHasMissingResource()) {
    return <Unavaliable onItemClick={onItemClick} title={resource.name} />
  }

  return (
    <>
      <CandleStickFrameContext.Provider value={context}>
        <StrategyCandleStick
          onItemClick={onItemClick}
          sigmas={sigmas}
          key={resource?.name}
          session={session}
        />
      </CandleStickFrameContext.Provider>
      <AddFrameModalContainer
        initialFrame={frame}
        visible={isModalVisible}
        onClose={onClose}
        initialX={0}
        initialY={0}
      />
    </>
  )
}

export default StrategyCandleStickContainer
