import React from 'react'
import * as Store from 'types/store'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import HistoricalStrategyTableContainer from 'v2/containers/frames/Historical/StrategyTable'
import StrategyTableContainer from 'v2/containers/frames/StrategyTable'

interface Props {
  frame: Store.Frame
}

const StrategyTableFrame: React.FC<Props> = ({ frame }) => {
  const session = useSelector(selectOperativeSessionSession)
  if (session.isHistorical) {
    return <HistoricalStrategyTableContainer frame={frame} />
  }

  return <StrategyTableContainer frame={frame} />
}

export default StrategyTableFrame
