import React, { ReactNode } from 'react'
import ResourcePortfolio from './ReadPortfolio'

import { NodeType } from 'types/ui'
import ResourceStrategy from './ReadStrategy'
import * as Store from 'types/store'
import NoItemSelected from 'components/StrategyViewer/NoItemSelected'
import StrategyWrapper from 'components/StrategyViewer/StrategyWrapper'
import { useSelector } from 'react-redux'
import { selectStrategies } from 'store/resources/selectors'

interface Props {
  userAction: Store.UserAction
}

const StrategyViewer: React.FC<Props> = ({ userAction }) => {
  const { node, operation } = userAction
  const strategies = useSelector(selectStrategies)

  if (!operation) {
    return <NoItemSelected noStrategies={strategies && strategies.length === 0} />
  }

  const renderContent = (): ReactNode => {
    const { type } = node
    switch (type) {
      case NodeType.Strategy:
        return <ResourceStrategy userAction={userAction} />
      case NodeType.Portfolio:
      case NodeType.Group:
      case NodeType.Portfolio:
      case NodeType.Asset:
        return <ResourcePortfolio userAction={userAction} />
      default:
        return null
    }
  }

  return <StrategyWrapper>{renderContent()}</StrategyWrapper>
}

export default StrategyViewer
