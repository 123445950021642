import sigmaServices from 'config/sigmaServices'
import { TSInterval } from 'types/enums'
import { Sigma } from 'types/enums.api'

const { Operations } = sigmaServices.Assets

export const getTimeDifference = (from: Date, to: Date) => to.getTime() - from.getTime()

export const getInterval = (from: Date, to: Date, sigma: Sigma) => {
  if (sigma === Operations.ClosedOperation) {
    return null
  }

  const difference = getTimeDifference(from, to) / 60000

  let interval = TSInterval.Second

  if (difference <= TSInterval.Minute) {
    interval = TSInterval.HalfMinute
  } else if (difference <= TSInterval.TwoMinutes) {
    interval = TSInterval.Minute
  } else if (difference <= TSInterval.ThreeMinutes) {
    interval = TSInterval.TwoMinutes
  } else if (difference <= TSInterval.FourMinutes) {
    interval = TSInterval.ThreeMinutes
  } else if (difference <= TSInterval.FiveMinutes) {
    interval = TSInterval.FourMinutes
  } else if (difference <= TSInterval.TenMinutes) {
    interval = TSInterval.FiveMinutes
  } else if (difference <= TSInterval.Fifteen) {
    interval = TSInterval.TenMinutes
  } else if (difference <= TSInterval.ThirtyMinutes) {
    interval = TSInterval.Fifteen
  } else if (difference <= TSInterval.SixHours) {
    interval = TSInterval.ThirtyMinutes
  } else {
    interval = TSInterval.Day
  }
  return interval
}

export const getExtendedDates = (from: Date, to: Date, startTime: Date, endTime: Date): [Date, Date] => {
  const difference = getTimeDifference(from, to)
  const extraRange = difference * 1.5
  const fromWithExtraRange = new Date(from.getTime() - extraRange)
  const toWithExtraRange = new Date(to.getTime() + extraRange)

  const startFrom = startTime > fromWithExtraRange ? startTime : fromWithExtraRange
  const endTo = endTime < toWithExtraRange ? endTime : toWithExtraRange

  return [startFrom, endTo]
}
