import React from 'react'
import { useTranslation } from 'react-i18next'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Row, Col, Form, InputNumber, Select, TimePicker, Typography, Tooltip, Button, Input } from 'antd'
import * as Store from 'types/store'
import { SessionStatus, TimeUnit } from 'types/enums'
import moment from 'moment'
import * as Forms from 'types/form'
import { Control, Controller } from 'react-hook-form'
import FormField from 'components/FormField'

const onlyDigits = (value: string): string => {
  return value.replace(/\D/g, '')
}

interface Props {
  session: Store.Session
  control: Control<Forms.VirtualSessionPlayer>
  loading: boolean
  sessionEndTimer: moment.Moment
  reset: () => void
}

const VirtualSessionParameters: React.FC<Props> = ({ session, control, loading, sessionEndTimer, reset }) => {
  const { t } = useTranslation()
  const { status } = session

  const disabled = status !== SessionStatus.Off && status !== SessionStatus.Created

  return (
    <div className="parameters-container">
      <Typography.Title level={2} className="mt-0">
        {t('general.parameters')}
      </Typography.Title>
      <Form>
        <div className="view-statistics">
          <Typography.Text className="statistics-label">{t('operativeSession.strategies')}</Typography.Text>
          <Typography.Text className="statistics-input primary-color">{session.name}</Typography.Text>

          <Row gutter={[12, 12]} align="bottom" className="mt-15">
            <Col span={12}>
              <div className="fieldset">
                <Tooltip placement="topLeft" title={t('sessionParameters.marketOpenTimeTooltip')}>
                  <Typography.Text className="statistics-label">
                    {t('sessionParameters.marketOpenTime')} <InfoCircleOutlined />
                  </Typography.Text>
                </Tooltip>

                <Controller
                  control={control}
                  name="marketOpen"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormField error={error} className="statistics-input">
                      <TimePicker
                        size="small"
                        format="HH:mm"
                        minuteStep={10}
                        showNow={false}
                        allowClear={false}
                        value={moment(value)}
                        disabled={disabled || loading}
                        className="parameters-time-picker"
                        popupClassName="parameters-popup"
                        onChange={(value) => onChange(value.toDate())}
                      />
                    </FormField>
                  )}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="fieldset">
                <Typography.Text className="statistics-label">
                  {t('sessionParameters.marketCloseTime')}
                </Typography.Text>
                <Controller
                  control={control}
                  name="marketClose"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormField error={error} className="statistics-input">
                      <TimePicker
                        size="small"
                        format="HH:mm"
                        minuteStep={10}
                        showNow={false}
                        allowClear={false}
                        value={moment(value)}
                        disabled={disabled || loading}
                        className="parameters-time-picker"
                        popupClassName="parameters-popup"
                        onChange={(value) => onChange(value.toDate())}
                      />
                    </FormField>
                  )}
                />
              </div>
            </Col>

            <Col span={12}>
              <div className="fieldset">
                <Typography.Text className="statistics-label">{t('sessionParameters.rate')}</Typography.Text>
                <Controller
                  control={control}
                  name="rate"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormField
                      error={error}
                      className="statistics-input"
                      normalize={(value: number) => (Number(value) >= 0 ? value : '')}
                    >
                      <InputNumber
                        size="small"
                        disabled={disabled || loading}
                        value={value}
                        onChange={onChange}
                      />
                    </FormField>
                  )}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="fieldset rate-select">
                <Controller
                  control={control}
                  name="rateUnit"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormField error={error}>
                      <Select
                        getPopupContainer={(trigger) => trigger.parentElement}
                        size="small"
                        value={value}
                        onChange={onChange}
                        disabled={disabled || loading}
                      >
                        <Select.Option value={TimeUnit.Minutes}>{TimeUnit.Minutes}</Select.Option>
                        <Select.Option value={TimeUnit.Seconds}>{TimeUnit.Seconds}</Select.Option>
                        <Select.Option value={TimeUnit.MilliSeconds}>{TimeUnit.MilliSeconds}</Select.Option>
                      </Select>
                    </FormField>
                  )}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="fieldset">
                <Typography.Text className="statistics-label">
                  {t('sessionParameters.minSize')}
                </Typography.Text>
                <Controller
                  control={control}
                  name="minSize"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormField error={error} className="statistics-input">
                      {/* using Input instead of InputNumber to enforce only integer values */}
                      <Input
                        size="small"
                        disabled={disabled || loading}
                        value={value}
                        onChange={(e) => {
                          onChange(onlyDigits(e.target.value))
                        }}
                      />
                    </FormField>
                  )}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="fieldset">
                <Typography.Text className="statistics-label">
                  {t('sessionParameters.maxSize')}
                </Typography.Text>
                <Controller
                  control={control}
                  name="maxSize"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormField error={error} className="statistics-input">
                      {/* using Input instead of InputNumber to enforce only integer values */}
                      <Input
                        size="small"
                        disabled={disabled || loading}
                        value={value}
                        onChange={(e) => {
                          onChange(onlyDigits(e.target.value))
                        }}
                      />
                    </FormField>
                  )}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="fieldset">
                <Typography.Text className="statistics-label">
                  {t('sessionParameters.maxVarTradePrice')}
                </Typography.Text>
                <Controller
                  control={control}
                  name="maxVarTradePrice"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormField error={error} className="statistics-input">
                      <InputNumber
                        size="small"
                        disabled={disabled || loading}
                        value={value}
                        onChange={onChange}
                      />
                    </FormField>
                  )}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="fieldset">
                <Typography.Text className="statistics-label">
                  {t('sessionParameters.tradesConfirmationPercentage')}
                </Typography.Text>
                <Controller
                  control={control}
                  name="tradesConfirmationPercentage"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormField error={error} className="statistics-input">
                      <InputNumber
                        size="small"
                        disabled={disabled || loading}
                        value={value}
                        onChange={onChange}
                      />
                    </FormField>
                  )}
                />
              </div>
            </Col>
            {disabled && sessionEndTimer !== null && (
              <Col span={24}>
                <div className="fieldset time">
                  <Typography.Text className="statistics-label">
                    {t('sessionParameters.sessionEnding')}
                  </Typography.Text>

                  <Typography.Paragraph className="statistics-label">
                    {sessionEndTimer?.format('L LT')}
                  </Typography.Paragraph>
                </div>
              </Col>
            )}
            {!disabled && (
              <>
                <Col span={12}>
                  <div className="fieldset">
                    <Typography.Text className="statistics-label">
                      {t('sessionParameters.sessionEndTimer')}
                    </Typography.Text>
                    <Controller
                      control={control}
                      name="sessionEndTimer"
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormField
                          error={error}
                          className="statistics-input"
                          normalize={(value: number) => (Number(value) >= 0 ? value : '')}
                        >
                          <Input
                            size="small"
                            disabled={disabled || loading}
                            value={value}
                            onChange={(e) => {
                              onChange(onlyDigits(e.target.value))
                            }}
                          />
                        </FormField>
                      )}
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <div className="fieldset rate-select">
                    <Controller
                      control={control}
                      name="sessionEndTimerUnit"
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormField error={error} className="statistics-input">
                          <Select
                            getPopupContainer={(trigger) => trigger.parentElement}
                            size="small"
                            value={value}
                            onChange={onChange}
                            disabled={disabled || loading}
                          >
                            <Select.Option value={TimeUnit.Minutes}>{TimeUnit.Minutes}</Select.Option>
                            <Select.Option value={TimeUnit.Hours}>{TimeUnit.Hours}</Select.Option>
                            <Select.Option value={TimeUnit.Days}>{TimeUnit.Days}</Select.Option>
                          </Select>
                        </FormField>
                      )}
                    />
                  </div>
                </Col>
              </>
            )}
          </Row>
        </div>

        {!disabled && (
          <Button htmlType="reset" onClick={reset} disabled={loading}>
            {t('table.filter.reset')}
          </Button>
        )}
      </Form>
    </div>
  )
}

export default VirtualSessionParameters
