import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'
import routes from 'config/routes'

import { Space, Button, Dropdown, Menu, Modal, Typography, Row } from 'antd'
import * as Store from 'types/store'

import { LoadingState, SessionStatus, SessionType } from 'types/enums'
import AppContentCard from 'components/AppContentCard'
import { useSelector } from 'react-redux'
import { selectSessionsState } from 'store/sessions/selectors'

interface Props {
  record: Store.Session
  onPress: (id: number, isCleanUp: boolean) => void
}
interface DeleteModal {
  id: number
  isModalVisible: boolean
  isCleanUp: boolean
}

const Buttons: React.FC<Props> = ({ record, onPress }) => {
  const { t } = useTranslation()
  const loadingState = useSelector(selectSessionsState)
  const sessionSetupUrl = routes.sessionSetup.url
  const historicalSetupUrl = routes.historicalSetup.url
  const routeId = record.id.toString()

  const isRunning =
    record.status === SessionStatus.Running ||
    record.status === SessionStatus.Updating ||
    record.status === SessionStatus.Terminating

  const [deleteConfig, setDeleteConfig] = useState<DeleteModal>({
    id: null,
    isCleanUp: null,
    isModalVisible: false,
  })

  useEffect(() => {
    if (loadingState === LoadingState.Deleting) {
      return () => {
        setDeleteConfig({ id: null, isCleanUp: null, isModalVisible: false })
      }
    }
  }, [loadingState])

  const isEnterButtonEnabled = (): boolean => {
    if (record.type === SessionType.Trading || record.type === SessionType.Signals) {
      return true
    }
    return record.status !== SessionStatus.Off
  }

  const isDeleteDropdownEnabled = (): boolean => {
    if (record.isHistorical) {
      return true
    }

    if (record.status === SessionStatus.Off || record.status === SessionStatus.Broken || isRunning) {
      return false
    }

    return true
  }

  const isCleanUpItemEnabled = (): boolean => {
    return (
      (record.type === SessionType.BackTest || record.type === SessionType.Virtual) &&
      (record.status === SessionStatus.Completed ||
        record.status === SessionStatus.Stopped ||
        record.status === SessionStatus.Broken)
    )
  }

  const onCancel = () => {
    setDeleteConfig({ id: null, isCleanUp: null, isModalVisible: false })
  }

  const onOK = () => {
    onPress(deleteConfig.id, deleteConfig.isCleanUp)
  }

  const onDelete = (id: number, isCleanUp: boolean) => {
    setDeleteConfig({ id, isCleanUp, isModalVisible: true })
  }

  const addWarningMessage = (session: Store.Session, config: DeleteModal): string => {
    if (!config.isCleanUp) {
      return t('warning.deleteAllInSession')
    }

    switch (session.type) {
      case SessionType.BackTest:
        return t('warning.cleanUpBacktest')
      case SessionType.Virtual:
        return t('warning.cleanUpVirtual')
      default:
        return t('general.areYouSure')
    }
  }

  const menu = [
    {
      key: 'delete-all',
      label: t('general.deleteAll'),
      onClick: () => onDelete(record.id, false)
    },
  ]

  isCleanUpItemEnabled() && menu.push({
    key: 'column-cleanup',
    label: t('table.column.cleanUp'),
    onClick: () => onDelete(record.id, true),
  })

  return (
    <>
      <Space size="middle">
        <Button className="pl-40 pr-40 secondary" type="dashed">
          <Link
            to={generatePath(record.isHistorical ? historicalSetupUrl : sessionSetupUrl, {
              id: routeId,
            })}
          >
            {t('general.setup')}
          </Link>
        </Button>
        {isEnterButtonEnabled() && (
          <Button className="pl-40 pr-40 primary" type="primary">
            <Link to={generatePath(routes.operativeSession.url, { id: routeId })}>{t('general.enter')}</Link>
          </Button>
        )}
        {isDeleteDropdownEnabled() && (
          <Space>
            {record.status !== SessionStatus.Off && record.status !== SessionStatus.Running ? (
              <Dropdown
                menu={{items: menu}}
                placement="bottomLeft"
                trigger={['click']}
                getPopupContainer={(trigger) => trigger.parentElement}
              >
                <Button className="pl-20 pr-20" type="text" danger>
                  {t('general.delete')}
                </Button>
              </Dropdown>
            ) : (
              <></>
            )}
          </Space>
        )}
      </Space>

      <Modal centered open={deleteConfig.isModalVisible} title={null} footer={null} closable={false}>
        <AppContentCard>
          <Typography.Text>{addWarningMessage(record, deleteConfig)}</Typography.Text>
          <Row justify="end" className="mt-30">
            <Space>
              <Button type="dashed" onClick={onCancel}>
                {t('general.cancel')}
              </Button>
              <Button type="primary" loading={loadingState === LoadingState.Deleting} onClick={onOK}>
                {t('general.ok')}
              </Button>
            </Space>
          </Row>
        </AppContentCard>
      </Modal>
    </>
  )
}

export default Buttons
