import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import * as Store from 'types/store'
import * as UI from 'types/ui'
import { removeFrame, updateFrameOptions } from 'store/dashboard/actions'
import AssetCandleStick from 'v2/components/frames/AssetCandleStick'
import { GridItemActions } from 'types/ui'
import { CandleStickFrameContext, CandleStickFrameContextInterface } from 'contexts/CandleStickFrameContext'
import AddFrameModalContainer from 'v2/containers/settings/modals/AddFrameModal'
import { selectAssetById, selectPortfolios, selectStrategies } from 'store/resources/selectors'
import Unavaliable from 'v2/components/frames/Unavailable'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import { isApiError, isGraphQlError } from 'core'
import * as api from 'api/historicalResources'
import * as Api from 'types/api'
import { mapHistoricalAssetSecurityRefData } from 'services/store/mapHistoricals'

interface Props {
  frame: Store.Frame
}

const getAssetSecurityRefData = async (
  assetId: number,
  sessionId: number,
): Promise<Store.SecurityRefData> => {
  const response = await api.getAssetSecurityRefData(assetId, sessionId)
  if (isApiError(response) || isGraphQlError(response)) {
    return null
  }

  const json = response as Api.HistoricalAssetSecurityRefDataResponse

  const securityRefData = json.data.historical_data_asset_security_ref_data[0]

  const data = securityRefData && mapHistoricalAssetSecurityRefData(securityRefData)

  return data
}

const getResource = (frame: Store.Frame): Store.Asset => {
  if (frame.selectedResources.length === 0) {
    return null
  }
  return frame.selectedResources[0].resource as Store.Asset
}

const AssetCandleStickContainer: React.FC<Props> = ({ frame }) => {
  const {
    options: { timeFrame, viewType },
  } = frame
  const dispatch = useDispatch()
  const [trace, setTrace] = useState(false)
  const [isFullScreen, setFullScreen] = useState(false)
  const [resource, setResource] = useState<Store.Asset>(getResource(frame))
  const [isModalVisible, setModalVisible] = useState(false)
  const strategies = useSelector(selectStrategies)
  const portfolios = useSelector(selectPortfolios)
  const asset = useSelector(selectAssetById(resource.id))
  const session = useSelector(selectOperativeSessionSession)
  const [assetSecurityRefData, setAssetSecurityRefData] = useState<Store.SecurityRefData>()

  useEffect(() => {
    const getData = async () => {
      const data = await getAssetSecurityRefData(asset.id, session.id)
      if (data) {
        setAssetSecurityRefData(data)
      }
    }
    if (asset && session.endTime) {
      getData()
    }
  }, [asset])

  useEffect(() => {
    const resource = getResource(frame)
    setResource(resource)
  }, [frame.selectedResources])

  const getHasMissingResource = () => {
    const portfolio = portfolios.find((portfolio) => portfolio.id === asset?.portfolioId)
    const strategy = strategies.find((strategy) => strategy.id === portfolio?.strategyId)
    if (!asset || !portfolio || !strategy) {
      return true
    }

    return false
  }

  const onClose = () => {
    setModalVisible(false)
  }

  const setTimeFrame = (timeFrame) => {
    const options = { ...frame.options, timeFrame }
    dispatch(updateFrameOptions({ id: frame.id, options }))
  }

  const setViewType = (viewType: UI.ViewType) => {
    const options = { ...frame.options, viewType }
    dispatch(updateFrameOptions({ id: frame.id, options }))
  }

  const context: CandleStickFrameContextInterface = {
    id: frame.id,
    squares: {
      x: frame.layout.w,
      y: frame.layout.h,
    },
    isFullscreen: isFullScreen,
    trace,
    timeFrame,
    viewType,
    resource,
    isOffline: () => false,
    setTimeFrame,
    setTrace,
    setViewType,
    setFullScreen,
    setOffline: () => null,
  }

  const onItemClick = (action: GridItemActions) => {
    switch (action) {
      case GridItemActions.Close:
        dispatch(removeFrame(frame))
        break
      case GridItemActions.Trace:
        setTrace(!context.trace)
        break
      case GridItemActions.Edit:
        setModalVisible(true)
        break
    }
  }

  if (getHasMissingResource() && session.endTime) {
    return <Unavaliable onItemClick={onItemClick} title={resource.name} />
  }

  return (
    <>
      <CandleStickFrameContext.Provider value={context}>
        <AssetCandleStick
          onItemClick={onItemClick}
          key={resource?.name}
          securityRefData={assetSecurityRefData}
        />
      </CandleStickFrameContext.Provider>
      <AddFrameModalContainer
        initialFrame={frame}
        visible={isModalVisible}
        onClose={onClose}
        initialX={0}
        initialY={0}
      />
    </>
  )
}

export default AssetCandleStickContainer
