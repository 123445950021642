import * as K from './constants'
import * as Api from 'types/api'
import { LoadingState } from 'types/enums'
import { AnyAction } from 'redux'

export function fetchHistoricalPortfolios(sessionId: number): AnyAction {
  return {
    type: K.HISTORICAL_PORTFOLIOS_FETCH,
    payload: sessionId,
  }
}

export function updatePortfolio(form: Api.PortfolioRest): AnyAction {
  return {
    type: K.PORTFOLIO_UPDATE,
    payload: form,
  }
}

export function createPortfolio(form: Api.PortfolioRest): AnyAction {
  return {
    type: K.PORTFOLIO_CREATE,
    payload: form,
  }
}

export function deletePortfolio(id: number): AnyAction {
  return {
    type: K.PORTFOLIO_DELETE,
    payload: id,
  }
}

export function setLoadingState(loadingState: LoadingState): AnyAction {
  return {
    type: K.SET_STATUS,
    payload: loadingState,
  }
}
