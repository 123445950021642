import * as Api from 'types/api'
import { getAxios } from './apiService'
import { config } from 'core'
import { getApiError, getAuthenticationHeader } from './common'
import { AxiosResponse } from 'axios'
const axios = getAxios()

export async function getPortfolios(): Promise<Api.PortfoliosResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `{
      application_portfolio (where: {deletion_timestamp: {_is_null: true}}) {
        id
        creation_date
        creation_userid
        last_mod_date
        max_long
        max_net_hedging
        max_short
        min_net_hedging
        name
        strategy_id
      }
    }`,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function updatePortfolio(
  portfolioId: number,
  data: Api.PortfolioRest,
): Promise<AxiosResponse | ApiError> {
  const header = await getAuthenticationHeader()
  return axios
    .put(config.api.updatePortfolio(portfolioId), data, header)
    .then((response) => response)
    .catch((error) => getApiError(error))
}

export async function deletePortfolio(portfolioId: number): Promise<AxiosResponse | ApiError> {
  const header = await getAuthenticationHeader()
  return axios
    .delete(config.api.deletePortfolio(portfolioId), header)
    .then((response) => response)
    .catch((error) => getApiError(error))
}

export async function createPortfolio(data: Api.PortfolioRest): Promise<AxiosResponse | ApiError> {
  const header = await getAuthenticationHeader()
  return axios
    .post(config.api.createPortfolio(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}
