import React, { useEffect, useMemo, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import * as Store from 'types/store'
import * as UI from 'types/ui'
import { removeFrame, updateFrameOptions } from 'store/dashboard/actions'
import AssetCandleStick from 'v2/components/frames/AssetCandleStick'
import { GridItemActions } from 'types/ui'
import { CandleStickFrameContext, CandleStickFrameContextInterface } from 'contexts/CandleStickFrameContext'
import AddFrameModalContainer from 'v2/containers/settings/modals/AddFrameModal'
import { selectAssetById, selectPortfolios, selectStrategies } from 'store/resources/selectors'
import Unavaliable from 'v2/components/frames/Unavailable'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import { useAssetSigmaSnapshotState } from 'hooks'
import { AssetFeed } from 'types/enums.api'
import { BUFFERS, config } from 'core'
import { FlowControlSpeed, SessionStatus } from 'types/enums'
import sigmaServices from 'config/sigmaServices'

const getResource = (frame: Store.Frame): Store.Asset => {
  if (frame.selectedResources.length === 0) {
    return null
  }
  return frame.selectedResources[0].resource as Store.Asset
}

interface Props {
  frame: Store.Frame
}

const AssetCandleStickContainer: React.FC<Props> = ({ frame }) => {
  const {
    options: { timeFrame, viewType },
  } = frame
  const dispatch = useDispatch()
  const [trace, setTrace] = useState(false)
  const [isFullScreen, setFullScreen] = useState(false)
  const [resource, setResource] = useState<Store.Asset>(getResource(frame))
  const [isModalVisible, setModalVisible] = useState(false)
  const strategies = useSelector(selectStrategies)
  const portfolios = useSelector(selectPortfolios)
  const asset = useSelector(selectAssetById(resource.id))

  const session = useSelector(selectOperativeSessionSession)
  const assetArray = useMemo(() => [asset], [asset])
  const [offlineDate, setOfflineDate] = useState<Date>(null)
  const SecuritySigmas = sigmaServices.Assets.SecurityData

  // const [{ [asset.id]: sigmas }] = useAssetSigmaSnapshotState(
  //   assetArray,
  //   session.id,
  //   [SecuritySigmas.AssetSecurityRefData],
  //   AssetFeed.SecurityData,
  //   BUFFERS.XLarge,
  // )

  const [data, setData] = useState<Store.AssetSigmas>({} as Store.AssetSigmas)

  const webWorkerRef = useRef<Worker | null>(null)

  const [lastTick, setLastTick] = useState<Date>(new Date())

  const messageReceived = () => {
    setLastTick(new Date())
  }

  useEffect(() => {
    webWorkerRef.current = new Worker(new URL('../../../../workers/assetDetailsSockets.web.worker.ts', import.meta.url))

    webWorkerRef.current.onmessage = (event: MessageEvent) => {
      const { type, data: workerData, error } = event.data
      if (type === 'data') {
        setData(workerData)
        messageReceived()
      } else if (type === 'error') {
        console.error('Worker error:', error)
      }
    }

    const initMsg = {
      type: 'init',
      payload: {
        assets: assetArray,
        sessionId: session.id,
        sockets: [
          { service: AssetFeed.SecurityData, sigmas: [SecuritySigmas.AssetSecurityRefData] },
        ],
        flowControlSpeed: FlowControlSpeed.Slow,
        webSocketUrl: config.socketBaseUrl,
      },
    }

    // Send startup message (with all the setup params) to the worker
    webWorkerRef.current.postMessage(initMsg)

    return () => {
      webWorkerRef.current.postMessage({ type: 'terminate' })
      webWorkerRef.current.terminate()
    }
  }, [])

  useEffect(() => {
    const resource = getResource(frame)
    setResource(resource)
  }, [frame.selectedResources])

  const hasMissingResource = () => {
    const portfolio = portfolios.find((portfolio) => portfolio.id === asset?.portfolioId)
    const strategy = strategies.find((strategy) => strategy.id === portfolio?.strategyId)
    if (!asset || !portfolio || !strategy) {
      return true
    }

    return false
  }

  const onClose = () => {
    setModalVisible(false)
  }

  const setTimeFrame = (timeFrame) => {
    const options = { ...frame.options, timeFrame }
    dispatch(updateFrameOptions({ id: frame.id, options }))
  }

  const setViewType = (viewType: UI.ViewType) => {
    const options = { ...frame.options, viewType }
    dispatch(updateFrameOptions({ id: frame.id, options }))
  }

  const setOffline = (goOffline: boolean) => {
    if (goOffline) {
      if (offlineDate === null) {
        //Start offline
        setOfflineDate(new Date())
      }
    } else {
      if (offlineDate !== null) {
        //End offline
        setOfflineDate(null)
      }
    }
  }

  const context: CandleStickFrameContextInterface = {
    id: frame.id,
    squares: {
      x: frame.layout.w,
      y: frame.layout.h,
    },
    isFullscreen: isFullScreen,
    trace,
    timeFrame,
    viewType,
    resource,
    isOffline: () => offlineDate !== null && session.status === SessionStatus.Running,
    setTimeFrame,
    setTrace,
    setViewType,
    setFullScreen,
    setOffline,
  }

  const onItemClick = (action: GridItemActions) => {
    switch (action) {
      case GridItemActions.Close:
        dispatch(removeFrame(frame))
        break
      case GridItemActions.Trace:
        setTrace(!context.trace)
        break
      case GridItemActions.Edit:
        setModalVisible(true)
        break
    }
  }

  if (hasMissingResource()) {
    return <Unavaliable onItemClick={onItemClick} title={resource.name} />
  }

  return (
    <>
      <CandleStickFrameContext.Provider value={context}>
        <AssetCandleStick
          onItemClick={onItemClick}
          key={resource?.name}
          // TODO: JI-REFACTOR
          // This data.securityRefData is needed only to render 
          // - asset name,
          // - instrument code,
          // - description
          // are we sure do we need to take it from a socket?
          securityRefData={data?.securityRefData}
        />
      </CandleStickFrameContext.Provider>
      <AddFrameModalContainer
        initialFrame={frame}
        visible={isModalVisible}
        onClose={onClose}
        initialX={0}
        initialY={0}
      />
    </>
  )
}

export default AssetCandleStickContainer
