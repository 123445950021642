import React from 'react'
import { Layout } from 'antd'
import AppContent from 'components/AppContent'
import AppContentCard from 'components/AppContentCard'
import ThemeProvider from 'providers/ThemeProvider'
import { ThemeType } from 'themes/types'

const WINDOW_HEIGHT_NO_HEADER = window.innerHeight

const ServerNotAvailablePage: React.FC = () => {
  return (
    <ThemeProvider initialTheme={ThemeType.Sandybrown}>
      <Layout style={{ minHeight: WINDOW_HEIGHT_NO_HEADER, textAlign: 'center' }}>
        <section data-augmented-ui className="app-container" style={{ minHeight: WINDOW_HEIGHT_NO_HEADER }}>
          <Layout style={{ minHeight: WINDOW_HEIGHT_NO_HEADER }}>
            <AppContent>
              <AppContentCard fullHeight>
                <span>{'Service not available'}</span>
              </AppContentCard>
            </AppContent>
          </Layout>
        </section>
      </Layout>
    </ThemeProvider>
  )
}

export default ServerNotAvailablePage
