import React from 'react'

import * as Store from 'types/store'
import { Typography } from 'antd'
import RGL from 'react-grid-layout'

interface Props {
  frame: Store.Frame
  layout: RGL.Layout
}

//This is only a fallback component, it should never been displayed
const EmptyGridItem: React.FC<Props> = () => {
  return <Typography.Title>Empty Grid Item</Typography.Title>
}

export default EmptyGridItem
