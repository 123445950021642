import React, { useEffect, useState } from 'react'
import { SessionStatus } from 'types/enums'
import * as api from 'api/liveSession'
import * as Store from 'types/store'
import SessionStatusRenderer from 'components/Sessions/SessionStatusRenderer'

interface Props {
  session: Store.Session
}

const SessionStatusRendererContainer: React.FC<Props> = ({ session }) => {
  const [errors, setError] = useState<Store.SessionError[]>(null)

  const checkForError = async (sessionStatus: SessionStatus) => {
    const errors = await api.getSessionErrors(session)
    setError(errors)
  }

  useEffect(() => {
    checkForError(session.status)
  }, [])

  return <SessionStatusRenderer errors={errors} session={session} />
}

export default SessionStatusRendererContainer
