import { AnyAction } from 'redux'
import { LoadingState } from 'types/enums'
import * as Store from 'types/store'
import * as K from './constants'

export function fetchUser(): AnyAction {
  return {
    type: K.FETCH_USER,
    payload: null,
  }
}

export function changePassword(data: Json): AnyAction {
  return {
    type: K.CHANGE_PASSWORD,
    payload: data,
  }
}

export function login(data: Store.Login): AnyAction {
  return {
    type: K.LOGIN,
    payload: data,
  }
}

export function logout(): AnyAction {
  return {
    type: K.LOGOUT,
    payload: null,
  }
}

export function setLoadingState(state: LoadingState): AnyAction {
  return {
    type: K.SET_LOADING_STATE,
    payload: state,
  }
}
