import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectLoadingState } from 'store/user/selectors'
import { changePassword } from 'store/user/actions'
import ChangePassword from 'components/user-profile/ChangePassword'

const ChangePasswordContainer: React.FC = () => {
  const dispatch = useDispatch()
  const loadingState = useSelector(selectLoadingState)

  const onChangePasswordFinish = (data: Json) => {
    dispatch(changePassword(data))
  }

  return <ChangePassword changePassword={onChangePasswordFinish} loadingState={loadingState} />
}

export default ChangePasswordContainer
