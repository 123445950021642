const sigmaServices = {
  Assets: {
    AnalysisStatistics: {
      AssetPercRet: 'asset_perc_ret',
      AssetStatisticalIndicators: 'asset_statistical_indicators',
      GaussianCurve: 'gaussian_curve',
      GaussianBins: 'gaussian_bins',
      AssetMaxDrawdownList: 'asset_max_drawdown',
    },
    Operations: {
      OpenOperation: 'open_operation',
      ClosedOperation: 'closed_operation',
      OperationTradRetEnd: 'operation_trad_ret_end',
      PositionStatus: 'position_status',
      AssetNumTdyOp: 'asset_num_tdy_op',
      AssetCartesianOperations: 'asset_cartesian_operations',
    },
    Models: {
      ModelSignal: 'model_signal',
      TheoreticalOrder: 'theoretical_order',
      ModelAlgo: 'model_algo',
    },
    PositionAnalisys: {
      PositionAnalysis: 'position_analysis',
    },
    Principal: {
      AssetCumWdtPrincOp: 'asset_cum_wdt_princ_op',
      AssetWdtPrincCloseOpList: 'asset_wdt_princ_close_op',
      AssetAvgPrinc: 'asset_avg_princ',
      AssetAvgPrincClose: 'asset_avg_princ_close',
      AssetDtPrincCloseOpList: 'asset_dt_princ_close_op',
    },
    Return: {
      AssetTdyOpIndicators: 'asset_tdy_op_indicators',
      AssetTradRetList: 'asset_trad_ret',
      AssetOpenUnitRet: 'asset_open_unit_ret',
      AssetClosedUnitRet: 'asset_closed_unit_ret',
      AssetAllRetBcList: 'asset_all_ret_bc',
      AssetExchRetBcList: 'asset_exch_ret_bc',
      ReturnAnalysisList: 'return_analysis',
    },
    SecurityData: {
      AssetRefData: 'asset_ref_data',
      AssetSecurityRefData: 'asset_security_ref_data',
      AssetSecurityTrade: 'asset_security_trade',
      AssetSecurityBidAsk: 'asset_security_bid_ask',
      JiTrade: 'ji_trade',
      AssetOpenCandle: 'asset_open_candle',
      AssetClosedCandle: 'asset_closed_candle',
    },
  },
  Portfolios: {
    AnalysisIndicators: {
      PrtPercRetList: 'prt_perc_ret',
      PrtMaxDrawdownList: 'prt_max_drawdown',
      PrtStatisticalIndicators: 'prt_statistical_indicators',
    },
    Operations: {
      PrtCartesianOperationsList: 'prt_cartesian_operations',
      PrtCumPercRet: 'prt_cum_perc_ret',
      PrtAvgPrincBcList: 'prt_avg_princ_bc',
    },
    Position: {
      PrtCartesianPosition: 'prt_cartesian_position',
    },
    ReferenceData: {
      PrtRefData: 'prt_ref_data',
      PrtCartesianHeading: 'prt_cartesian_heading',
    },
    Return: {
      PrtTradRetBcList: 'prt_trad_ret_bc',
      PrtAllRetBcList: 'prt_all_ret_bc',
      PrtExchRetBcList: 'prt_exch_ret_bc',
      PrtOpenCandle: 'prt_open_candle',
      PrtClosedCandle: 'prt_closed_candle',
    },
  },
  Strategies: {
    AnalysisIndicators: {
      StgPercRetList: 'stg_perc_ret',
      StgMaxDrawdownList: 'stg_max_drawdown',
      StgStatisticalIndicators: 'stg_statistical_indicators',
    },
    Operations: {
      StgCartesianOperationsList: 'stg_cartesian_operations',
    },
    Position: {
      StgCartesianPosition: 'stg_cartesian_position',
    },
    ReferenceData: {
      StgRefData: 'stg_ref_data',
      StgCartesianHeading: 'stg_cartesian_heading',
    },
    Return: {
      StgTradRetBcList: 'stg_trad_ret_bc',
      StgAllRetBcList: 'stg_all_ret_bc',
      StgExchRetBcList: 'stg_exch_ret_bc',
      StgOpenCandle: 'stg_open_candle',
      StgClosedCandle: 'stg_closed_candle',
    },
  },
} as const

export default sigmaServices
