import React from 'react'
import { Typography } from 'antd'

import { useTranslation } from 'react-i18next'
import AppContentCard from 'components/AppContentCard'

const { Title } = Typography

const NoSessions: React.FC = () => {
  const { t } = useTranslation()
  return (
    <AppContentCard className="empty-card--no-session" full clipped>
      <Title className="select-item-title">{t('general.createOneAsset')}</Title>
    </AppContentCard>
  )
}

export default NoSessions
