import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Store from 'types/store'
import { selectAssets, selectPortfolios, selectStrategies } from 'store/resources/selectors'
import { useTranslation } from 'react-i18next'
import { AnalysisWindowTabType } from 'types/enums'
import { Typography } from 'antd'
import { AnalysisFrameContext } from 'contexts/AnalysisFrameContext'

enum TitleMode {
  OnlyStrategy,
  OnlyPortfolio,
  SubsetPortfolio,
  SubsetStrategy,
  Mixed,
}

interface Props {
  tabType: AnalysisWindowTabType
}

const TableTitle: React.FC<Props> = () => {
  const assets = useSelector(selectAssets)
  const context = useContext(AnalysisFrameContext)
  const portfolios = useSelector(selectPortfolios)
  const strategies = useSelector(selectStrategies)
  const [title, setTitle] = useState('')
  const { t } = useTranslation()

  const { selectedAssets } = context

  const selectedPortfolios: Store.Portfolio[] = selectedAssets
    .reduce((array, asset) => {
      if (!array.includes(asset.portfolioId)) {
        array.push(asset.portfolioId)
      }
      return array
    }, [] as number[])
    .map((id) => portfolios.find((portfolio) => portfolio.id == id))

  const selecedStrategies: number[] = selectedPortfolios.reduce((array, portfolio) => {
    if (!portfolio) {
      return []
    }

    if (!array.includes(portfolio.strategyId)) {
      array.push(portfolio.strategyId)
    }
    return array
  }, [])

  const getOnlyPortfolioTitle = () => {
    const portfolio = selectedPortfolios[0]
    return `${t('general.portfolio')} ${portfolio.name}`
  }

  const getAssets = () => {
    return `# ${t('general.assets')}(${selectedAssets.length})`
  }

  const getPortfolios = () => {
    return ` # ${t('general.portfolios')}(${selectedPortfolios.length}) ${getAssets()}`
  }

  const getSubsetPortfolioTitle = () => {
    const portfolio = selectedPortfolios[0]
    return `${t('general.subset')} ${t('general.portfolio')} ${portfolio.name} ${getAssets()}`
  }

  const getOnlyStrategyTitle = () => {
    const id = selecedStrategies[0]
    const strategy = strategies.find((item) => item.id === id)
    return `${t('general.strategy')} ${strategy.name} - ${strategy.target}`
  }

  const getSubsetStrategyTitle = (): string => {
    const id = selecedStrategies[0]
    const strategy = strategies.find((strategy) => strategy.id === id) as Store.Strategy
    return `${t('general.subset')} ${t('general.strategy')} ${strategy.name} ${getPortfolios()}`
  }

  const getMixedTitle = (): string => {
    return `Mix - ${getPortfolios()}`
  }

  const getMode = (): TitleMode => {
    if (selectedPortfolios.length === 1) {
      const portfolio = selectedPortfolios[0]
      const portfolioAssets = assets.filter((asset) => asset.portfolioId === portfolio.id)
      if (selectedAssets.length === portfolioAssets.length) {
        return TitleMode.OnlyPortfolio
      }
      return TitleMode.SubsetPortfolio
    }

    if (selecedStrategies.length === 1) {
      const id = selecedStrategies[0]
      const portfolioIds = portfolios
        .filter((portfolio) => portfolio.strategyId === id)
        .map((portfolio) => portfolio.id)
      const strategyAssets = assets.filter((asset) => portfolioIds.includes(asset.portfolioId))

      if (strategyAssets.length === selectedAssets.length) {
        return TitleMode.OnlyStrategy
      }
      return TitleMode.SubsetStrategy
    }

    return TitleMode.Mixed
  }

  const getTitle = () => {
    const mode = getMode()
    switch (mode) {
      case TitleMode.OnlyPortfolio:
        return getOnlyPortfolioTitle()
      case TitleMode.SubsetPortfolio:
        return getSubsetPortfolioTitle()
      case TitleMode.OnlyStrategy:
        return getOnlyStrategyTitle()
      case TitleMode.SubsetStrategy:
        return getSubsetStrategyTitle()
      default:
        return getMixedTitle()
    }
  }

  useEffect(() => {
    const title = getTitle()
    setTitle(title)
  }, [selectedAssets])

  return (
    <Typography.Title level={4} className="subtitle" ellipsis={{ tooltip: true }}>
      {title}
    </Typography.Title>
  )
}

export default TableTitle
