import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import { ChartChild } from 'types/chart'
import * as Store from 'types/store'
import colors from 'config/colors'
import { getStatusColor } from 'core/helpers'
import { NodeType } from 'types/ui'

const EMPTY_PORTFOLIO_VALUE = 3000
const EMPTY_STRATEGY_VALUE = 6000

interface Props {
  data: Store.DataTree
  padding: number
  onRightClick: (resource: Store.Resource, nodeType: NodeType) => void
}

const CirclePack: React.FC<Props & ChartChild> = ({
  data,
  padding,
  d3Transform: transform,
  chartWidth: width,
  chartHeight: height,
  onRightClick,
}) => {
  const element = useRef(null)
  const color = [colors.strategyCircle, colors.portfolioCircle, colors.azure]

  const getEmptyValue = (nodeType: NodeType) => {
    return nodeType === NodeType.Portfolio ? EMPTY_PORTFOLIO_VALUE : EMPTY_STRATEGY_VALUE
  }

  const pack = (data) => {
    return d3.pack().size([width, height]).padding(padding)(
      d3
        .hierarchy(data)
        .sum(({ value, nodeType }) => value || getEmptyValue(nodeType))
        .sort((a, b) => {
          if (a.value === b.value) {
            if (a.data.name > b.data.name) {
              return -1
            }
            if (a.data.name === b.data.name) {
              return 0
            }
            return 1
          }
          return b.value - a.value
        }),
    )
  }

  useEffect(() => {
    const hierarchy = pack(data)
    const smallerDimention = height > width ? width : height
    const k = smallerDimention / hierarchy.r / 2
    const root = d3.select(element.current)

    root.selectAll('*').remove()

    root
      .selectAll('circle')
      .data(hierarchy.descendants().slice(1))
      .join((selection) => {
        const item = selection
          .append('g')
          .attr('transform', (d) => `translate(${(d.x - hierarchy.x) * k}, ${(d.y - hierarchy.y) * k})`)

        item
          .append('circle')
          .attr('r', (d) => d.r || 1)
          .attr('fill', (d) => {
            if (d.depth < 3) {
              return color[d.depth - 1]
            } else {
              const dataLeaf = d.data as Store.DataLeaf
              return getStatusColor(dataLeaf.status)
            }
          })
          .on('contextmenu', function (e, d) {
            e.preventDefault()
            //using stopPropagation here will have issue on render dropdown menu in correct position
            //e.stopPropagation()
            const { resource, nodeType } = d.data as Store.DataLeaf
            onRightClick(resource, nodeType)
          })
          .append('title')
          .text((d) => (d.data as Store.DataLeaf).name)

        return item
      })
  }, [data])

  const { x, y, k } = transform

  return (
    <g
      className="circle-pack"
      ref={element}
      y={height / 2}
      transform={`translate(${(width * k) / 2 + x}, ${(height * k) / 2 + y}) scale(${k})`}
    />
  )
}

export default CirclePack
