import * as K from './constants'
import { put, call, takeLatest, all } from 'redux-saga/effects'
import Debug from 'debug'
import * as helpers from './helpers'
import * as Api from 'types/api'
import * as api from 'api/assets'
import { ErrorCode, LoadingState } from 'types/enums'
import { ApplicationError, isApiError } from 'core'
import { jsonToAssets } from 'services/store/mapService'
import { AnyAction } from 'redux'

const debug = Debug('Frontend')

export function* fetchAssets() {
  debug('Saga Fetch Assets')
  try {
    yield call(helpers.fetchAssets)
    yield put({ type: K.SET_STATUS, payload: LoadingState.Loaded })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.CreateFailure })
    debug('fetchAssets Error', error)
  }
}

export function* createAsset(action: AnyAction) {
  debug('Saga Create Assets')
  try {
    yield put({ type: K.SET_ERROR, payload: null })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Creating })
    const postData = action.payload as Api.AssetRest

    const securityIDResponse = yield call(
      api.getSecurityId,
      postData.mic,
      postData.securityId,
      postData.currency,
    )
    if (isApiError(securityIDResponse)) {
      throw new ApplicationError(ErrorCode.Api, 'fetch updateAsset failed on getting securityId')
    }
    const securityId = (securityIDResponse as Api.MasterSecurityResponse).data.application_master_security[0]
      .id

    postData.securityId = securityId

    const response = yield call(api.createAsset, postData)
    if (isApiError(response)) {
      debug('fetch createAsset failed', response, response as ApiError)
      yield put({ type: K.SET_ERROR, payload: (response.data as Api.RESTResponse)?.result})
      throw new ApplicationError(ErrorCode.Api, `createAsset failed with error: ${(response.data as Api.RESTResponse)?.result}`)
    }

    const { assetId } = (response as Api.AssetCreateFeed).content

    const newAsset = yield call(api.getAsset, assetId)
    if ((newAsset as ApiError).status) {
      debug('fetch getAsset failed', newAsset)
      throw new ApplicationError(ErrorCode.Api, 'fetch getAsset failed')
    }

    const json = (newAsset as Api.AssetsResponse).data.application_asset

    const assets = jsonToAssets(json)
    const asset = assets[0]

    debug('AssetId', assetId)

    // save in redux
    yield put({ type: K.ASSET_CREATE_SUCCESS, payload: asset })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Created })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.CreateFailure })
    debug('createAsset Error', error)
  }
}

export function* updateAsset(action: AnyAction) {
  debug('Saga Update Assets')
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Updating })
    yield put({ type: K.SET_ERROR, payload: null })
    const postData = action.payload as Api.AssetRest
    const securityIDResponse = yield call(
      api.getSecurityId,
      postData.mic,
      postData.securityId,
      postData.currency,
    )
    if (isApiError(securityIDResponse)) {
      throw new ApplicationError(ErrorCode.Api, 'fetch updateAsset failed on getting securityId')
    }
    const securityId = (securityIDResponse as Api.MasterSecurityResponse).data.application_master_security[0]
      .id

    postData.securityId = securityId

    const response = yield call(api.updateAsset, postData)
    if (isApiError(response)) {
      yield put({ type: K.SET_ERROR, payload: (response.data as Api.RESTResponse)?.result?.responseEnum })
      throw new ApplicationError(ErrorCode.Api, 'fetch updateAsset failed')
    }

    const newAsset = yield call(api.getAsset, postData.id)
    if ((newAsset as ApiError).status) {
      throw new ApplicationError(ErrorCode.Api, 'fetch getAsset failed')
    }

    const json = (newAsset as Api.AssetsResponse).data.application_asset
    const assets = jsonToAssets(json)

    // save in redux
    yield put({ type: K.ASSET_UPDATE_SUCCESS, payload: assets[0] })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Updated })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.UpdateFailure })
    debug('updateAsset Error', error)
  }
}

export function* deleteAsset(action: AnyAction) {
  debug('Saga Delete Assets')
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Deleting })
    yield put({ type: K.SET_ERROR, payload: null })
    const id = action.payload as number

    const response = yield call(api.deleteAsset, id)
    if (isApiError(response)) {
      yield put({ type: K.SET_ERROR, payload: (response.data as Api.RESTResponse)?.result?.responseEnum })
      throw new ApplicationError(ErrorCode.Api, 'fetch removeAsset failed')
    }

    // save in redux
    yield put({ type: K.ASSET_DELETE_SUCCESS, payload: id })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Deleted })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.DeleteFailed })
    debug('removeAsset Error', error)
  }
}

function* watch() {
  yield all([
    takeLatest(K.ASSETS_FETCH, fetchAssets),
    takeLatest(K.ASSET_CREATE, createAsset),
    takeLatest(K.ASSET_UPDATE, updateAsset),
    takeLatest(K.ASSET_DELETE, deleteAsset),
  ])
}

export default watch
