import produce from 'immer'
import { LoadingState } from 'types/enums'
import * as K from './constants'
import { AnyAction } from 'redux'
import * as Api from 'types/api'
import * as Store from 'types/store'
import { sortResources } from 'core/helpers'
import { ResponseCode } from 'types/enums.api'

export const initialState: Store.StrategyState = {
  data: [],
  state: LoadingState.Unset,
  error: null,
}

const reducer = (state = initialState, action: AnyAction) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case K.STRATEGIES_FETCH_SUCCESS:
        {
          const data = action.payload as Array<Store.Strategy>
          draft.data = sortResources(data) as Array<Store.Strategy>
        }
        break
      case K.HISTORICAL_STRATEGIES_FETCH_SUCCESS:
        {
          const data = action.payload as Array<Store.Strategy>
          draft.data = sortResources(data) as Array<Store.Strategy>
        }
        break

      case K.STRATEGY_UPDATE_SUCCESS:
        {
          const formData = action.payload as Api.StrategyRest
          const index = draft.data.findIndex((item) => item.id === formData.id)
          const strategy: Store.Strategy = { ...draft.data[index], ...formData }
          draft.data[index] = strategy
          draft.data = sortResources(draft.data) as Array<Store.Strategy>
        }
        break
      case K.STRATEGY_DELETE_SUCCESS:
        const id = action.payload as number
        const index = draft.data.findIndex((item) => item.id === id)
        draft.data.splice(index, 1)
        break

      case K.STRATEGY_CREATE_SUCCESS:
        const strategy = action.payload as Store.Strategy
        draft.data.push(strategy)
        draft.data = sortResources(draft.data) as Array<Store.Strategy>
        break

      case K.SET_STATUS:
        draft.state = action.payload as LoadingState
        break

      case K.SET_ERROR:
        draft.error = action.payload
        break
    }
  })
}

export default reducer
