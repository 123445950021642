import { Avatar, Button, Col, Input, Row, Space, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import AppContentCard from 'components/AppContentCard'
import { sortString } from 'helpers/tableHelpers'
import IconFont from 'core/IconFont'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CrudOperation, SessionStatus } from 'types/enums'
import * as Store from 'types/store'
import styles from './styles.module.scss'
import EditIcon from 'assets/icon/edit-icon.svg'
import TrashIcon from 'assets/icon/trash-icon.svg'

export interface AdminUserAction {
  id: number
  operation: CrudOperation
}

interface Props {
  users: Store.SystemUser[]
  loading: boolean
  onClick: (action: AdminUserAction) => void
  sessions: Store.Session[]
}

const Users: React.FC<Props> = ({ users, loading, onClick, sessions }) => {
  const [filter, setFilter] = useState<string>('')

  const { t } = useTranslation()

  const hasRunningSession = (email: string) => {
    return sessions.some(
      (session) =>
        session.author === email &&
        (session.status === SessionStatus.Running || session.status === SessionStatus.StandBy),
    )
  }

  const colums: ColumnsType<Store.SystemUser> = [
    {
      dataIndex: 'name',
      key: 'name',
      title: t('admin.name'),
      sorter: (a, b) => sortString(a.name, b.name),
    },
    {
      dataIndex: 'surname',
      key: 'surname',
      title: t('admin.surname'),
      sorter: (a, b) => sortString(a.surname, b.surname),
    },
    {
      dataIndex: 'email',
      key: 'email',
      title: t('admin.email'),
      sorter: (a, b) => sortString(a.email, b.email),
    },
    {
      dataIndex: 'active',
      key: 'active',
      title: t('admin.active'),
      render: function renderActions(active) {
        if (active) {
          return <IconFont className="m-0" style={{ fontSize: '25px' }} type="icon-icon_status_checkcircle" />
        } else {
          return <IconFont className="m-0" style={{ fontSize: '25px' }} type="icon-icon_status_closecircle" />
        }
      },
    },
    {
      dataIndex: 'id',
      key: 'id',
      render: function renderActions(id, { active, email }) {
        const disabled = active || hasRunningSession(email)
        return (
          <Space>
            <Button type="ghost" onClick={() => onClick({ operation: CrudOperation.Update, id })}>
              <Avatar shape="square" src={EditIcon} size={16} />
            </Button>
            {!disabled && (
              <Button type="ghost" onClick={() => onClick({ operation: CrudOperation.Delete, id })}>
                <Avatar shape="square" src={TrashIcon} size={16} />
              </Button>
            )}
          </Space>
        )
      },
    },
  ]

  function handleFilterSearch(e: React.ChangeEvent<HTMLInputElement>) {
    const currValue = e.target.value
    setFilter(currValue)
  }

  const filterBySearch = (data: Store.SystemUser[]) => {
    const filtered = data.filter(
      (entry) =>
        entry.email?.toLowerCase()?.includes(filter.toLowerCase()) ||
        entry.name?.toLowerCase()?.includes(filter.toLowerCase()) ||
        entry.name?.toLowerCase()?.includes(filter.toLowerCase()),
    )

    return filtered
  }

  const filteredUsers = filterBySearch(users)

  return (
    <section className={styles['users-table']}>
      <AppContentCard>
        <Row className="mb-20">
          <Col className="search" span={11}>
            <Input
              placeholder={t('table.filter.search')}
              value={filter}
              onChange={(e) => handleFilterSearch(e)}
              suffix={<IconFont type="icon-magnifier" />}
            />
          </Col>
          <Col span={13}>
            <Button
              type="primary"
              className="primary button-right"
              onClick={() => onClick({ operation: CrudOperation.Create, id: null })}
            >
              {t('admin.addNewUser')}
            </Button>
          </Col>
        </Row>

        <Table
          dataSource={filteredUsers}
          columns={colums}
          loading={loading}
          pagination={{
            hideOnSinglePage: true,
          }}
        />
      </AppContentCard>
    </section>
  )
}

export default Users
