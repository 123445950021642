import * as d3 from 'd3'

// Default configuration properties.
const defaults = {
  width: 1000,
  height: 1000,
  margin: { top: 20, right: 20, bottom: 30, left: 50 },
}

const HistogramWithNormalDistribution = (e, o) => {
  //the svg selection we are working with
  const svg = d3.select(e)
  //options
  const opt = { ...defaults, ...o }
  const { width, height, margin } = opt

  const bin_width = 5

  //References
  let x, y, curve_max, xAxis, yAxis, line, bins

  const init = () => {
    svg.attr('viewBox', [0, 0, width, height])
  }

  //Public Functions
  const enter = (data) => {
    function mean(values) {
      return values.reduce((sum, value) => (sum += value)) / values.length
    }

    function ssd(data, mean) {
      // total sum of squares
      const sst = data.map((x) => Math.pow(x - mean, 2)).reduce((sum, value) => (sum += value))

      return Math.sqrt((1 / (data.length - 1)) * sst)
    }

    function dnorm_standard(x) {
      return (1 / Math.sqrt(2 * Math.PI)) * Math.exp(-0.5 * Math.pow(x, 2))
    }

    function dnorm(x, mean = 0, sd = 1) {
      return (1 / sd) * dnorm_standard((x - mean) / sd)
    }

    const _mean = mean(data)
    const _sd = ssd(data, _mean)

    curve_max = dnorm(_mean, _mean, _sd) * bin_width * data.length

    x = d3
      .scaleLinear()
      .domain(d3.extent(data))
      .nice()
      .range([margin.left, width - margin.right])

    bins = d3
      .bin()
      .domain(x.domain())
      .thresholds(d3.range(...x.domain(), bin_width))(data)

    y = d3
      .scaleLinear()
      .domain([0, d3.max([curve_max, d3.max(bins, (d) => d.length)])])
      .nice()
      .range([height - margin.bottom, margin.top])

    xAxis = (g) =>
      g.attr('transform', `translate(0,${height - margin.bottom})`).call(
        d3
          .axisBottom(x)
          .ticks(width / 80)
          .tickSizeOuter(0),
      )

    yAxis = (g) =>
      g
        .attr('transform', `translate(${margin.left},0)`)
        .call(d3.axisLeft(y).ticks(height / 40))
        .call((g) => g.select('.domain').remove())

    line = d3
      .line()
      .curve(d3.curveMonotoneX)
      .x((d) => x(d[0]))
      .y((d) => y(d[1]))

    const normal_curve = (mean, sd) => {
      const points = []
      for (let value of d3.range(...x.domain(), bin_width / 10)) {
        points.push([value, dnorm(value, mean, sd) * bin_width * data.length])
      }
      return points
    }

    svg
      .append('g')
      .call(xAxis)
      .call((g) =>
        g
          .selectAll('.tick line')
          .clone()
          .attr('stroke-opacity', 0.1)
          .attr('y1', -height + margin.top + margin.bottom),
      )

    svg
      .append('g')
      .call(yAxis)
      .call((g) =>
        g
          .selectAll('.tick line')
          .clone()
          .attr('stroke-opacity', 0.1)
          .attr('x1', width - margin.left - margin.right),
      )

    svg
      .append('g')
      .attr('fill', '#a0b0c0')
      .selectAll('rect')
      .data(bins)
      .join('rect')
      .attr('x', (d) => x(d.x0) + 1)
      .attr('width', (d) => Math.max(0, x(d.x1) - x(d.x0) - 1))
      .attr('y', (d) => y(d.length))
      .attr('height', (d) => y(0) - y(d.length))

    svg
      .append('line')
      .attr('stroke', 'black')
      .attr('x1', x(_mean))
      .attr('y1', y(y.domain()[1]))
      .attr('x2', x(_mean))
      .attr('y2', y(0))

    svg
      .append('path')
      .datum(normal_curve(_mean, _sd))
      .attr('stroke', '#c4238f')
      .attr('stroke-width', 2)
      .attr('d', line)
      .attr('fill', 'none')
  }

  const update = () => null
  const exit = () => null

  //Initialize the component
  init()

  return {
    enter: enter,
    update: update,
    exit: exit,
  }
}

export default HistogramWithNormalDistribution
