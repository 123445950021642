import React, { Dispatch, SetStateAction, useCallback, useMemo, useState, useEffect } from 'react'
import 'antd/dist/antd.css'
import EditIcon from 'assets/icon/edit-icon.svg'
import TrashIcon from 'assets/icon/trash-icon.svg'
import { Avatar, Button, Col, Empty, Input, Popover, Row, Tree } from 'antd'
import { CrudOperation } from 'types/enums'
import * as Store from 'types/store'
import { ExtendedDataNode, NodeType } from 'types/ui'
import { useTranslation } from 'react-i18next'
import Title from 'antd/lib/typography/Title'


const { Search } = Input;

const getParentKey = (key: React.Key, tree: ExtendedDataNode[]): React.Key => {
  let parentKey: React.Key

	tree.forEach(node => {
		if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key
      } else if (getParentKey(key, node.children as any)) {
        parentKey = getParentKey(key, node.children as any)
      }
    }
	})

  return parentKey!
}

type ItemProps = {
	type: NodeType,
	resource: Store.Resource,
	isReadOnly: boolean,
	isDeletable: boolean,
	beforeStr?: string,
	afterStr?: string,
	strTitle?: string,
	searchValue?: string,
	portfolioAction: any,
	editResource: any,
	setResourceAction: Dispatch<SetStateAction<Store.ResourceAction>>,
}

type ModelInfo = {
	popoverText: string,
	isGroup: boolean,
}

const retrieveModelDataIfExist = (resource: Store.Asset | Store.Group): ModelInfo | false => {
	const { t } = useTranslation()
	
	// modelId is exclusive to Store.Group
	if (resource.hasOwnProperty('modelId') && (resource as Store.Group).hasOwnProperty('modelClass')) {
		const modelName = (resource as Store.Group).modelClass.modelName
		const version = (resource as Store.Group).modelClass.version

		return {
			popoverText: t('assets.analysisModel', { modelName, version }),
			isGroup: true,
		}
	}

	// Reading model info for Store.Asset (if it does NOT belong to a group)
	if ((resource as Store.Asset).hasOwnProperty('modelClass') && resource?.modelClass && !(resource as Store.Asset)?.group) {
		const	modelName = (resource as Store.Asset).modelClass.name
		const	version = (resource as Store.Asset)?.modelClass.version

		return {
			popoverText: t('assets.analysisModel', { modelName, version }),
			isGroup: false,
		}
	}

	// No model associated
	return false
}

const SelectedTreeElement = (props: ItemProps) => {

	const {
		type,
		resource,
		beforeStr,
		afterStr,
		searchValue,
		portfolioAction,
		editResource,
		isReadOnly,
		isDeletable,
		setResourceAction,
	} = props

	const handleItemClick = () => {
		setResourceAction({ resource: resource, operation: CrudOperation.Read, type: type })
	}

	const modelData = retrieveModelDataIfExist(resource as Store.Asset | Store.Group)

	let popover = null

	if (modelData) {
		popover = (
			<div>{modelData.popoverText}</div>
		)
	}

	return (
		<Row justify="space-between" align="bottom">
			<Col span={20} onClick={handleItemClick}>
				<Title level={3} className='has-model'>
					<Row justify="start" align="bottom">
						{modelData && modelData.isGroup && (
							<Col className='group-icon'>GRP</Col>
						)}
						<Col>{beforeStr}</Col>
						<Col className="site-tree-search-value">{searchValue}</Col>
						<Col>{afterStr}</Col>
						{modelData &&
							<Popover
								overlayClassName="assets-popover"
								placement="top"
								content={popover}
								trigger="hover"
								zIndex={50}
							>
								<Col className="info-icon"></Col>
							</Popover>
						}
					</Row>
				</Title>
			</Col>
			<Col span={4}>
				<Row justify="space-between">
					<Button
						type="ghost"
						key="copy-action"
						className="icon-action"
						disabled={isReadOnly || !isDeletable}
						onClick={() => editResource(resource, type)}
						// onClick={() => portfolioAction(resource, CrudOperation.Update, type)}
					>
						<Avatar shape="square" src={EditIcon} size={16} />
					</Button>
					<Button
						type="ghost"
						key="delete-action"
						className="icon-action"
						disabled={isReadOnly || !isDeletable}
						onClick={() => portfolioAction(resource, CrudOperation.Delete, type)}
					>
						<Avatar shape="square" src={TrashIcon} size={16} />
					</Button>
				</Row>
			</Col>
		</Row>
	)
}

const TreeElement = (props: ItemProps) => {

	const {
		type,
		resource,
		strTitle,
		portfolioAction,
		editResource,
		isReadOnly,
		isDeletable,
		setResourceAction
	} = props

	const handleItemClick = () => {
		setResourceAction({ resource: resource, operation: CrudOperation.Read, type: type })
	}

	const modelData = retrieveModelDataIfExist(resource as Store.Asset | Store.Group)

	let popover = null
	if (modelData) {
		popover = (
			<div>{modelData.popoverText}</div>
		)
	}

	return (
		<Row justify="space-between" align="bottom">
			<Col span={20} onClick={handleItemClick}>
				<Row align="bottom">
					{modelData && modelData.isGroup && (
						<Col className='group-icon'></Col>
					)}
					<Col>{strTitle}</Col>
					{modelData &&
						<Popover
							overlayClassName="assets-popover"
							placement="top"
							content={popover}
							trigger="hover"
							zIndex={50}
						>
							<Col className="info-icon"></Col>
						</Popover>
					}
				</Row>
			</Col>
			<Col span={4}>
				<Row justify="space-between">
					<Button
						type="ghost"
						key="copy-action"
						className="icon-action"
						disabled={isReadOnly || !isDeletable}
						onClick={() => editResource(resource, type)}
						// onClick={() => portfolioAction(resource, CrudOperation.Update, type)}
					>
						<Avatar shape="square" src={EditIcon} size={16} />
					</Button>
					<Button
						type="ghost"
						key="delete-action"
						className="icon-action"
						disabled={isReadOnly || !isDeletable}
						onClick={() => portfolioAction(resource, CrudOperation.Delete, type)}
					>
						<Avatar shape="square" src={TrashIcon} size={16} />
					</Button>
				</Row>
			</Col>
		</Row>
	)
}

type Props = {
	isReadOnly: boolean
	groupedAssets: ExtendedDataNode[]
	portfolioAction: (
		resource: Store.Asset | Store.Group,
		type: CrudOperation,
		nodeType: NodeType.Asset | NodeType.Group
	) => void
	editResource: (
		resource: Store.Asset | Store.Group,
		nodeType: NodeType.Asset | NodeType.Group
	) => void
	setResourceAction: Dispatch<SetStateAction<Store.ResourceAction>>
}


export const SearchableTree: React.FC<Props> = (props: Props) => {
	const { groupedAssets, portfolioAction, editResource, setResourceAction, isReadOnly } = props
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true)
	const [resourceList, setResourceList] = useState<ExtendedDataNode[]>(groupedAssets)
  const [height, setHeight] = useState<number>(400)

	useEffect(() => {
		// Need to change reference to the array and trigger re-render of treeData
		window.requestAnimationFrame(() => {
			setResourceList([...groupedAssets])
		})
	}, [groupedAssets])

	useEffect(() => {
		if (!groupedAssets || groupedAssets.length === 0) {
			return
		}
		window.requestAnimationFrame(() => {
			setHeight(document.querySelector('.col--assets').clientHeight - 130)
		})
	})

  const onExpand = (newExpandedKeys: React.Key[]) => {
		if (searchValue !== '') {
			return
		}
		setExpandedKeys(newExpandedKeys)
    setAutoExpandParent(false)
  }

	const searchTree = (nodes: ExtendedDataNode[], search: string): ExtendedDataNode[] => {
		if (search.trim() === '' || !search) {
			return groupedAssets
		}
		const matchesSet = new Set<ExtendedDataNode>()
		const searchRegex = new RegExp(search.trim(), 'i')

		const searchNodes = (nodes: ExtendedDataNode[]) => {
			nodes.forEach(node => {
				if (searchRegex.test(node.resource.name)) {
					matchesSet.add(node)
				}
	
				if (node.children && node.children.length > 0) {
					searchNodes(node.children)
				}
			})
		}
	
		searchNodes(nodes)
	
		return Array.from(matchesSet)
	}

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		// TODO: use a buffer
    const { value } = e.target

		const filteredList = searchTree(resourceList, value)

		const keys = filteredList.map(item => item.children && item.key)

		setResourceList(filteredList)
    setExpandedKeys(keys)
    setSearchValue(value)

		if (value === '') {
			setAutoExpandParent(false)
		} else {
			setAutoExpandParent(true)
		}
  }

  const treeData = useMemo(() => {
    const loop = (data: ExtendedDataNode[]): ExtendedDataNode[] => {
      return data.map(item => {
        const strTitle = item.resource.name as string
				const type = item.type
        const index = strTitle.indexOf(searchValue)
        const beforeStr = strTitle.substring(0, index)
        const afterStr = strTitle.slice(index + searchValue.length)

        const title =
          index > -1 ? (
						<SelectedTreeElement
							type={type}
							resource={item.resource}
							afterStr={afterStr}
							beforeStr={beforeStr}
							searchValue={searchValue}
							editResource={editResource}
							isReadOnly={isReadOnly}
							isDeletable={item.isDeletable}
							setResourceAction={setResourceAction}
							portfolioAction={portfolioAction}
						/>
          ) : (
						<TreeElement
							type={type}
							strTitle={strTitle}
							resource={item.resource}
							editResource={editResource}
							isReadOnly={isReadOnly}
							isDeletable={item.isDeletable}
							setResourceAction={setResourceAction}
							portfolioAction={portfolioAction}
						/>
          )

        if (item.children) {
          return {
						type,
						title,
						key: item.key,
						resource: item.resource,
						children: loop(item.children as any)
					}
        }

        return {
					type,
          title,
          key: item.key,
					resource: item.resource,
        }
      })
		}

		return loop(resourceList)

  }, [searchValue, resourceList])

  return (
    <div className='groups-assets-tree'>
      <Search style={{ marginBottom: 8 }} placeholder="Search" onChange={onChange} />
			{
				treeData.length > 0
				?
					<Tree
						height={height - 14}
						className='list-tree'
						onExpand={onExpand}
						expandedKeys={expandedKeys}
						autoExpandParent={autoExpandParent}
						treeData={treeData}
					/>
				: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="empty" />
			}
    </div>
  )
}
