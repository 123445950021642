import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as api from 'api/metrics'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import { isApiError } from 'core'
import * as Api from 'types/api'
import * as Store from 'types/store'
import { mapJsonToSessionMetrics, mapUint8ArrayMessage } from 'services/store/mapMetrics'
import SessionAnalysis from 'v2/components/frames/TechnicalChart/SessionAnalysis/SessionAnalysis'
import { TechnicalFrameContext } from 'contexts/TechnicalFrameContext'
import { getLastIntervalTime, getPoolingParams } from 'core/metricsHelper'
import { useInterval } from 'react-use'
import { AssetMetricStatus } from 'types/enums'

const getSessionMetrics = async (
  session: number,
  interval: number,
  startTime: string,
  endTime: string,
): Promise<Api.SessionMetricsResponse> => {
  const response = await api.getSessionMetrics(session, interval, startTime, endTime)
  if (isApiError(response)) {
    return null
  }

  return response as Api.SessionMetricsResponse
}

const SessionMetricsContainer: React.FC = () => {
  const session = useSelector(selectOperativeSessionSession)
  const [metrics, setMetrics] = useState<Store.SessionMetrics>()
  const [interval, setTimeInterval] = useState<[Date, Date]>()

  const [liveStatuses, setLiveStatuses] = useState<Json<string>>({})

  const onMessage = async (message: MessageEvent<Blob>) => {
    const bytes = new Uint8Array(await message.data.arrayBuffer())
    const protoMessage = mapUint8ArrayMessage(bytes)
    if (protoMessage.metric) {
      const { metric } = protoMessage
      setLiveStatuses((statuses) => ({
        ...statuses,
        [metric.key]: metric.status,
      }))
    }
  }

  const { timeRange } = useContext(TechnicalFrameContext)

  const getData = async (seconds: number, from: Date, to: Date) => {
    const toValue = !session.isHistorical
      ? getLastIntervalTime(from, to, seconds).toISOString()
      : to.toISOString()

    const data = await getSessionMetrics(session.id, seconds, from.toISOString(), toValue)
    if (data) {
      const metrics = mapJsonToSessionMetrics(data)
      setMetrics(metrics)
    }
  }

  const params = getPoolingParams(interval)

  const fetchData = () => {
    if (params) {
      const { fromWithExtraRange, seconds, toWithExtraRange } = params
      const [startTime, endTime] = timeRange

      const end = endTime?.toDate() || new Date()

      const startFrom = startTime.toDate() > fromWithExtraRange ? startTime.toDate() : fromWithExtraRange
      const endTo = end <= toWithExtraRange ? end : toWithExtraRange

      getData(seconds, startFrom, endTo)
    }
  }

  useEffect(() => {
    if (interval) {
      fetchData()
    }
  }, [interval])

  useEffect(() => {
    if (!session.isHistorical) {
      const unsubscribe = api.subscribeToAssetsMetrics(session.id, onMessage)
      return () => unsubscribe && unsubscribe()
    }
  }, [])

  const poolInterval = () => {
    if (!session.endTime && params && interval) {
      const [, endTime] = timeRange
      const { poolInterval, toWithExtraRange } = params

      if (endTime) {
        const end = endTime.toDate()
        return toWithExtraRange >= end ? poolInterval * 1000 : null
      }

      return poolInterval * 1000
    }
    return null
  }

  useInterval(fetchData, poolInterval())

  const countActiveModels = Object.values(liveStatuses).filter((value) => {
    return value !== AssetMetricStatus.Freeze && value !== AssetMetricStatus.Off
  }).length

  const updatedMetrics = {
    ...metrics,
    countActiveModels:
      Object.values(liveStatuses).length === metrics?.countAsset
        ? countActiveModels
        : metrics?.countActiveModels,
  }

  return <SessionAnalysis metrics={updatedMetrics} setTimeInterval={setTimeInterval} />
}

export default SessionMetricsContainer
