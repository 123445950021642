import * as K from './constants'
import produce from 'immer'
import { SessionType } from 'types/enums'
import { AnyAction } from 'redux'
import { ResponseCode } from 'types/enums.api'

type EnabledSessionTypesStatus = {
  enabledSessionTypes: SessionType[]
  error: ResponseCode | null
}

export const initialState: EnabledSessionTypesStatus = {
  enabledSessionTypes: [],
  error: null,
}

const reducer = (state = initialState, action: AnyAction) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case K.FETCH_ENABLED_SESSION_SUCCESS:
        draft.enabledSessionTypes = action.payload as SessionType[]
        break
      case K.SET_ERROR:
        draft.error = action.payload as ResponseCode
        break
    }
  })
}

export default reducer