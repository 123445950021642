import React from 'react'
import { useTranslation } from 'react-i18next'

import AppContentCard from 'components/AppContentCard'
import { Typography, Descriptions, Button, Row, Col } from 'antd'
import styles from './styles.module.scss'
import * as Store from 'types/store'

interface Props {
  user: Store.SystemUser
  onLogout: () => void
}

const Profile: React.FC<Props> = ({ user, onLogout }) => {
  const { t } = useTranslation()
  const onLogoutClick = () => {
    onLogout()
  }

  return (
    <AppContentCard className={styles['profile']}>
      <section className="p-10 user-info">
        <Row>
          <Col flex={'auto'}>
            <Typography.Title level={2}>{t('user.yourProfile')}</Typography.Title>
          </Col>
          <Col>
            <Button size="large" type="primary" onClick={onLogoutClick}>
              {t('buttons.logout')}
            </Button>
          </Col>
        </Row>

        <Row>
          <Col>
            <Descriptions title={null} layout="vertical" column={1} className="mt-50">
              <Descriptions.Item label={t('user.name')}>{user?.name}</Descriptions.Item>
              <Descriptions.Item label={t('user.surname')}>{user?.surname}</Descriptions.Item>
              <Descriptions.Item label={t('user.email')}>{user?.email}</Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </section>
    </AppContentCard>
  )
}

export default Profile
