export const STATE_KEY = 'dashboard'

export const LOAD_LAYOUT = `${STATE_KEY}/LOAD_LAYOUT`
export const CLEAR_LAYOUT = `${STATE_KEY}/CLEAR_LAYOUT`
export const SET_DIRTY = `${STATE_KEY}/SET_DIRTY`

export const SET_DASHBOARD_HEIGHT = `${STATE_KEY}/SET_DASHBOARD_HEIGHT`

export const CREATE_FRAME = `${STATE_KEY}/CREATE_FRAME`
export const UPDATE_FRAME_OPTIONS = `${STATE_KEY}/UPDATE_FRAME_OPTIONS`
export const REMOVE_FRAME = `${STATE_KEY}/REMOVE_FRAME`
export const REMOVE_FRAMES_BY_SESSION_TYPE = `${STATE_KEY}/REMOVE_FRAMES_BY_SESSION_TYPE`
export const LAYOUT_CHANGED = `${STATE_KEY}/LAYOUT_CHANGED`
export const LOAD_RESOLUTIONS = `${STATE_KEY}/LOAD_RESOLUTIONS`
export const SAVE_RESOLUTIONS = `${STATE_KEY}/SAVE_RESOLUTIONS`
export const SAVE_RESOLUTIONS_SUCCESS = `${STATE_KEY}/SAVE_RESOLUTIONS_SUCCESS`
export const SET_GUIDELINE_ACTIVE = `${STATE_KEY}/SET_GUIDELINE_ACTIVE`
export const SET_FILTER_ENTITIES = `${STATE_KEY}/SET_FILTER_ENTITIES`
export const SET_DRAGGING = `${STATE_KEY}/SET_DRAGGING`
export const SET_RESIZING = `${STATE_KEY}/SET_RESIZING`
