import { createSelector } from 'reselect'
import * as Store from 'types/store'

const resources = (state: { root: { resources } }) => state.root.resources as Store.ResourcesState

// Data Selectors
export const selectResources = createSelector(resources, (slice) => slice)
export const selectStrategies = createSelector(resources, (slice) => slice.strategies.data)
export const selectStrategiesState = createSelector(resources, (slice) => slice.strategies.state)
export const selectStrategiesErrorCode = createSelector(resources, (slice) => {
  return {
    erroCode: slice.strategies?.error?.responseEnum,
    errorDescription: slice.strategies?.error?.description ?? '',
  }
})
export const selectPortfolios = createSelector(resources, (slice) => slice.portfolios.data)
export const selectPortfoliosState = createSelector(resources, (slice) => slice.portfolios.state)
export const selectPortfoliosErrorcode = createSelector(resources, (slice) => {
  return {
    erroCode: slice.portfolios?.error?.responseEnum,
    errorDescription: slice.portfolios?.error?.description ?? '',
  }
})
export const selectAssets = createSelector(resources, (slice) => slice.assets.data)
export const selectAssetsState = createSelector(resources, (slice) => slice.assets.state)
export const selectAssetsErrorCode = createSelector(resources, (slice) => {
  return {
    erroCode: slice.assets?.error?.responseEnum,
    errorDescription: slice.assets?.error?.description ?? '',
  }
})
export const selectGroups = createSelector(resources, (slice) => slice.groups.data)
export const selectGroupsState = createSelector(resources, (slice) => slice.groups.state)
export const selectGroupsErrorCode = createSelector(resources, (slice) => {
  return {
    erroCode: slice.groups?.error?.responseEnum,
    errorDescription: slice.groups?.error?.description ?? '',
  }
})

//Select a specific asset ID from an array
export const selectAssetById = (id: number) => {
  return createSelector(selectAssets, (items) => items.find((item) => item.id === id))
}
