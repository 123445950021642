import * as Api from 'types/api'
import * as Store from 'types/store'
import colors from 'config/colors'
import moment from 'moment'

export function convertTime(time: string): Date {
  return new Date(time?.length !== 0 && time)
}

export function jsonToCandleStick(json: Api.DataPoint[]): Array<Store.CandleStick> {
  return json.map((item) => {
    const candle = item.asset_closed_candle
    return {
      close: candle.close,
      high: candle.high,
      low: candle.low,
      open: candle.open,
      date: convertTime(candle.start_ts),
      startTime: convertTime(candle.start_ts),
      endTime: convertTime(candle.end_ts),
      color:
        candle.open === candle.close
          ? colors.silver
          : candle.open > candle.close
          ? colors.candleBlack
          : colors.candleWhite,
    }
  })
}

export function jsonToPortfolioCandleStick(json: Api.DataPoint[]): Array<Store.CandleStick> {
  return json.map((item, i) => {
    const candle = item.prt_closed_candle

    const [min, max] =
      i === 0
        ? [candle.max_tdy_trading_ret_bc, candle.min_tdy_trading_ret_bc]
        : [
            json[i - 1].prt_closed_candle.min_tdy_trading_ret_bc,
            json[i - 1].prt_closed_candle.max_tdy_trading_ret_bc,
          ]
    return {
      close: candle.min_tdy_trading_ret_bc,
      high: candle.max_tdy_trading_ret_bc,
      low: candle.min_tdy_trading_ret_bc,
      open: candle.max_tdy_trading_ret_bc,
      date: convertTime(candle.start_ts),
      startTime: convertTime(candle.start_ts),
      endTime: convertTime(candle.end_ts),
      color:
        candle.max_tdy_trading_ret_bc === max && candle.min_tdy_trading_ret_bc === min
          ? colors.silver
          : candle.max_tdy_trading_ret_bc < max
          ? colors.red
          : colors.positiveGreen,
    }
  })
}

export function jsonToStrategyCandleStick(json: Api.DataPoint[]): Array<Store.CandleStick> {
  return json.map((item, i) => {
    const candle = item.stg_closed_candle

    const [min, max] =
      i === 0
        ? [candle.max_tdy_trading_ret_bc, candle.min_tdy_trading_ret_bc]
        : [
            json[i - 1].stg_closed_candle.min_tdy_trading_ret_bc,
            json[i - 1].stg_closed_candle.max_tdy_trading_ret_bc,
          ]
    return {
      close: candle.min_tdy_trading_ret_bc,
      high: candle.max_tdy_trading_ret_bc,
      low: candle.min_tdy_trading_ret_bc,
      open: candle.max_tdy_trading_ret_bc,
      date: convertTime(candle.start_ts),
      startTime: convertTime(candle.start_ts),
      endTime: convertTime(candle.end_ts),
      color:
        candle.max_tdy_trading_ret_bc === max && candle.min_tdy_trading_ret_bc === min
          ? colors.silver
          : candle.max_tdy_trading_ret_bc < max
          ? colors.red
          : colors.positiveGreen,
    }
  })
}

export function jsonToUnitPerformance(json: Api.DataPoint[]): Array<Store.UnitPerformance> {
  return json.map((item) => {
    const unitPerformance = item.asset_closed_unit_ret
    return {
      open: unitPerformance.unit_ret_open,
      close: unitPerformance.unit_ret_close,
      date: convertTime(unitPerformance.start_ts),
      startTime: convertTime(unitPerformance.start_ts),
      endTime: convertTime(unitPerformance.end_ts),
    }
  })
}

export function jsonToClosedOperation(json: Api.DataPoint[]): Array<Store.Operation> {
  return json.map((item) => {
    const closedOperation = item.closed_operation
    return {
      date: new Date(item.ts),
      avgClosePrice: closedOperation.avg_close_price,
      avgOpenPrice: closedOperation.avg_open_price,
      endTime: convertTime(closedOperation.end_ts),
      startTime: convertTime(closedOperation.start_ts),
      maxPrice: closedOperation.max_price,
      minPrice: closedOperation.min_price,
      size: closedOperation.size,
      sign: closedOperation.sign,
      tdyPercReturn: closedOperation.tdy_perc_ret,
      tdyTradingReturn: closedOperation.tdy_trading_ret,
      tdyTradingReturnBc: closedOperation.tdy_trading_ret_bc,
      totalPercReturn: closedOperation.tot_perc_ret,
      totalTradingReturn: closedOperation.tot_trading_ret,
      totalTradingReturnBc: closedOperation.tot_trading_ret_bc,
      ytdPercReturn: closedOperation.ytd_perc_ret,
      ytdTradingReturn: closedOperation.ytd_trading_ret,
      ytdTradingReturnBc: closedOperation.ytd_trading_ret_bc,
    }
  })
}

export function jsonToOperationsBarchartIndicators(
  json: Api.OperationBarsChartIndicatorsResponse,
): Store.OperationBarchartIndicator[] {
  return json?.datapoints?.map(({ asset_tdy_op_indicators: indicators, sequence, ts }) => {
    return {
      date: new Date(ts),
      sequence,
      avgPrinc: indicators.asset_tdy_avg_princ,
      avgPrincLng: indicators.asset_tdy_avg_princ_lng,
      avgPrincLngNeg: indicators.asset_tdy_avg_princ_lng_neg,
      avgPrincLngPos: indicators.asset_tdy_avg_princ_lng_pos,
      avgPrincNeg: indicators.asset_tdy_avg_princ_neg,
      avgPrincPos: indicators.asset_tdy_avg_princ_pos,
      avgPrincShr: indicators.asset_tdy_avg_princ_shr,
      avgPrincShrNeg: indicators.asset_tdy_avg_princ_shr_neg,
      avgPrincShrPos: indicators.asset_tdy_avg_princ_shr_pos,
      dtPrinc: indicators.asset_tdy_dt_princ,
      dtPrincLngNeg: indicators.asset_tdy_dt_princ_lng_neg,
      dtPrincLngPos: indicators.asset_tdy_dt_princ_lng_pos,
      dtPrincShrNeg: indicators.asset_tdy_dt_princ_shr_neg,
      dtPrincShrPos: indicators.asset_tdy_dt_princ_shr_pos,
      insertionTime: indicators.insertion_time,
      mean: indicators.asset_tdy_mean,
      mean2: indicators.asset_tdy_mean2,
      meanLng: indicators.asset_tdy_mean_lng,
      meanLng2: indicators.asset_tdy_mean_lng2,
      meanLngNeg: indicators.asset_tdy_mean_lng_neg,
      meanLngNeg2: indicators.asset_tdy_mean_lng_neg2,
      meanLngPos: indicators.asset_tdy_mean_lng_pos,
      meanLngPos2: indicators.asset_tdy_mean_lng_pos2,
      meanNeg: indicators.asset_tdy_mean_neg,
      meanNeg2: indicators.asset_tdy_mean_neg2,
      meanPos: indicators.asset_tdy_mean_pos,
      meanPos2: indicators.asset_tdy_mean_pos2,
      meanShr: indicators.asset_tdy_mean_shr,
      meanShr2: indicators.asset_tdy_mean_shr2,
      meanShrNeg: indicators.asset_tdy_mean_shr_neg,
      meanShrNeg2: indicators.asset_tdy_mean_shr_neg2,
      meanShrPos: indicators.asset_tdy_mean_shr_pos,
      meanShrPos2: indicators.asset_tdy_mean_shr_pos2,
      stdDev: indicators.asset_tdy_std_dev,
      stdDevLng: indicators.asset_tdy_std_dev_lng,
      stdDevLngNeg: indicators.asset_tdy_std_dev_lng_neg,
      stdDevLngPos: indicators.asset_tdy_std_dev_lng_pos,
      stdDevNeg: indicators.asset_tdy_std_dev_neg,
      stdDevPos: indicators.asset_tdy_std_dev_pos,
      stdDevShr: indicators.asset_tdy_std_dev_shr,
      stdDevShrNeg: indicators.asset_tdy_std_dev_shr_neg,
      stdDevShrPos: indicators.asset_tdy_std_dev_shr_pos,
      tradRet: indicators.asset_tdy_trad_ret,
      tradRetLngNeg: indicators.asset_tdy_trad_ret_lng_neg,
      tradRetLngPos: indicators.asset_tdy_trad_ret_lng_pos,
      tradRetShrNeg: indicators.asset_tdy_trad_ret_shr_neg,
      tradRetShrPos: indicators.asset_tdy_trad_ret_shr_pos,
    }
  })
}

export function jsonToOperationsBarchart(json: Api.OperationBarsChartResponse): Store.OperationBarchart[] {
  return json?.datapoints?.map(({ asset_num_tdy_op: operations, sequence, ts }) => {
    return {
      date: new Date(ts),
      sequence,
      insertionTime: operations.insertion_time,
      lngNegOp: operations.asset_tdy_lng_neg_op,
      lngPosOp: operations.asset_tdy_lng_pos_op,
      negOp: operations.asset_tdy_neg_op,
      posOp: operations.asset_tdy_pos_op,
      shrNegOp: operations.asset_tdy_shr_neg_op,
      shrPosOp: operations.asset_tdy_shr_pos_op,
      indicator: null,
    }
  })
}

export function jsonHistoricalToOperationsBarchartIndicators(
  json: Api.OperationBarsChartHistorical,
): Store.OperationBarchartIndicator {
  return {
    date: new Date(json.end_time),
    sequence: null,
    avgPrinc: json.asset_tdy_avg_princ,
    avgPrincLng: json.asset_tdy_avg_princ_lng,
    avgPrincLngNeg: json.asset_tdy_avg_princ_lng_neg,
    avgPrincLngPos: json.asset_tdy_avg_princ_lng_pos,
    avgPrincNeg: json.asset_tdy_avg_princ_neg,
    avgPrincPos: json.asset_tdy_avg_princ_pos,
    avgPrincShr: json.asset_tdy_avg_princ_shr,
    avgPrincShrNeg: json.asset_tdy_avg_princ_shr_neg,
    avgPrincShrPos: json.asset_tdy_avg_princ_shr_pos,
    dtPrinc: json.asset_tdy_dt_princ,
    dtPrincLngNeg: json.asset_tdy_dt_princ_lng_neg,
    dtPrincLngPos: json.asset_tdy_dt_princ_lng_pos,
    dtPrincShrNeg: json.asset_tdy_dt_princ_shr_neg,
    dtPrincShrPos: json.asset_tdy_dt_princ_shr_pos,
    insertionTime: null,
    mean: json.asset_tdy_mean,
    mean2: json.asset_tdy_mean2,
    meanLng: json.asset_tdy_mean_lng,
    meanLng2: json.asset_tdy_mean_lng2,
    meanLngNeg: json.asset_tdy_mean_lng_neg,
    meanLngNeg2: json.asset_tdy_mean_lng_neg2,
    meanLngPos: json.asset_tdy_mean_lng_pos,
    meanLngPos2: json.asset_tdy_mean_lng_pos2,
    meanNeg: json.asset_tdy_mean_neg,
    meanNeg2: json.asset_tdy_mean_neg2,
    meanPos: json.asset_tdy_mean_pos,
    meanPos2: json.asset_tdy_mean_pos2,
    meanShr: json.asset_tdy_mean_shr,
    meanShr2: json.asset_tdy_mean_shr2,
    meanShrNeg: json.asset_tdy_mean_shr_neg,
    meanShrNeg2: json.asset_tdy_mean_shr_neg2,
    meanShrPos: json.asset_tdy_mean_shr_pos,
    meanShrPos2: json.asset_tdy_mean_shr_pos2,
    stdDev: json.asset_tdy_std_dev,
    stdDevLng: json.asset_tdy_std_dev_lng,
    stdDevLngNeg: json.asset_tdy_std_dev_lng_neg,
    stdDevLngPos: json.asset_tdy_std_dev_lng_pos,
    stdDevNeg: json.asset_tdy_std_dev_neg,
    stdDevPos: json.asset_tdy_std_dev_pos,
    stdDevShr: json.asset_tdy_std_dev_shr,
    stdDevShrNeg: json.asset_tdy_std_dev_shr_neg,
    stdDevShrPos: json.asset_tdy_std_dev_shr_pos,
    tradRet: json.asset_tdy_trad_ret,
    tradRetLngNeg: json.asset_tdy_trad_ret_lng_neg,
    tradRetLngPos: json.asset_tdy_trad_ret_lng_pos,
    tradRetShrNeg: json.asset_tdy_trad_ret_shr_neg,
    tradRetShrPos: json.asset_tdy_trad_ret_shr_pos,
  }
}

export function jsonHistoricalToOperationsBarchart(
  json: Api.OperationBarsChartHistoricalResponse,
): Store.OperationBarchart[] {
  return json?.data?.historical_data_asset_tdy_op_indicators_v?.map((operation) => {
    const date = moment(operation.end_time).startOf('day').toDate()
    return {
      date,
      sequence: null,
      insertionTime: null,
      lngNegOp: operation.asset_tdy_lng_neg_op,
      lngPosOp: operation.asset_tdy_lng_pos_op,
      negOp: operation.asset_tdy_neg_op,
      posOp: operation.asset_tdy_pos_op,
      shrNegOp: operation.asset_tdy_shr_neg_op,
      shrPosOp: operation.asset_tdy_shr_pos_op,
      indicator: jsonHistoricalToOperationsBarchartIndicators(operation),
    }
  })
}
