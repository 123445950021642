import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import { ChartChild } from 'types/chart'
import * as Store from 'types/store'

interface Props {
  data: Store.UnitPerformance[]
  color?: string
  thickness?: number
}

const Line: React.FC<Props & ChartChild> = ({
  data,
  yDomain,
  xDomain,
  chartWidth: width,
  chartHeight: height,
  color = 'red',
  thickness = 1,
  d3Transform: transform,
  clipId,
  transitionTime,
}) => {
  const unitPerformance = data.reduce((array, item) => {
    if (array.length === 0) {
      return [
        [item.startTime, item.open],
        [item.endTime, item.close],
      ]
    }

    array.push([item.endTime, item.close])

    return array
  }, [])

  const linea = useRef(null)

  const x = unitPerformance.map((item) => {
    return item[0]
  })

  const y = unitPerformance.map((item) => {
    return item[1]
  })

  const getYDomain = () => {
    if (yDomain) {
      return yDomain
    }

    return [d3.min(y), d3.max(y)]
  }

  const getXDomain = () => {
    if (xDomain) {
      return xDomain
    }

    return [d3.min(x), d3.max(x)]
  }

  const render = (dataChanged: boolean) => {
    const transition = dataChanged ? 0 : transitionTime

    const xScale = transform.rescaleX(d3.scaleTime().domain(getXDomain()).range([0, width]))
    const yScale = d3.scaleLinear().domain(getYDomain()).range([height, 0])

    const generateLine = (): any => {
      return d3
        .line()
        .x(function (d) {
          return xScale(d[0])
        })
        .y(function (d) {
          return yScale(d[1])
        })
    }
    d3.select(linea.current)
      .attr('clip-path', `url(#${clipId})`)
      .datum(unitPerformance)
      .transition()
      .duration(transition)
      .ease(d3.easeExpOut)
      .attr('d', generateLine())
  }
  useEffect(() => {
    render(true)
  }, [render, data])

  useEffect(() => {
    render(false)
  }, [render, transform])

  return <path ref={linea} fill="none" stroke={color} strokeWidth={thickness} clipPath="url(#clip)" />
}

export default Line
