import { Typography, Tooltip } from 'antd'
import colors from 'config/colors'
import { getTimeFrameLabel, renderAvailableData } from 'core/helpers'
import { formatNumber } from 'core/formats'
import { NanToZero } from 'helpers/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TimeFrame } from 'types/enums'
import * as Store from 'types/store'

interface Props {
  operations: Store.CartesianOperations
  timeFrame: TimeFrame
}

const CartesianOperations: React.FC<Props> = ({ operations, timeFrame }) => {
  const { t } = useTranslation()

  const sumOfSignOperations = operations?.positiveOp + operations?.negativeOp || 0
  const sumOfOpenCloseOperations = operations?.openOp + operations?.closeOp || 0

  const posetivePercentage =
    operations?.positiveOp + operations?.negativeOp === 0
      ? 50
      : (operations?.positiveOp / (operations?.positiveOp + operations?.negativeOp)) * 100
  const negativePercentage = 100 - posetivePercentage
  const openPercentage =
    operations?.openOp + operations?.closeOp === 0
      ? 50
      : (operations?.openOp / (operations?.openOp + operations?.closeOp)) * 100

  const closePercentage = 100 - openPercentage

  const closeNegativePercentage =
    operations?.closeNegativeOp + operations?.openNegativeOp === 0
      ? 50
      : (operations?.closeNegativeOp / (operations?.closeNegativeOp + operations?.openNegativeOp)) * 100
  const closePosetivePercentage =
    operations?.closePositiveOp + operations?.openPositiveOp === 0
      ? 50
      : (operations?.closePositiveOp / (operations?.closePositiveOp + operations?.openPositiveOp)) * 100

  const timeFrameLabel = getTimeFrameLabel(timeFrame)

  const getCircumferenceOfCircle = (radius) => {
    return 2 * radius * Math.PI
  }

  const getTradeCircle = (negativePercentage: number, posetivePercentage: number) => {
    const radius = 16
    const circumferance = getCircumferenceOfCircle(radius)
    const offset = circumferance / 4

    return (
      <g className="trade-circle-tooltip">
        <circle
          cx="20"
          cy="20"
          r={radius}
          fill="transparent"
          stroke={sumOfSignOperations ? colors.red : colors.grey}
          strokeWidth="3"
          strokeDasharray={`${circumferance * negativePercentage} ${circumferance * posetivePercentage}`}
          strokeDashoffset={
            -offset + NanToZero((circumferance * negativePercentage - circumferance * posetivePercentage) / 2)
          }
        ></circle>
        <circle
          cx="20"
          cy="20"
          r={radius}
          fill="transparent"
          stroke={sumOfSignOperations ? colors.positiveGreen : colors.grey}
          strokeWidth="3"
          strokeDasharray={`${circumferance * posetivePercentage} ${circumferance * negativePercentage}`}
          strokeDashoffset={offset}
        ></circle>
      </g>
    )
  }

  const getOperationsCircle = (closePercentage: number, openPercentage: number) => {
    const radius = 15
    const circumferance = getCircumferenceOfCircle(radius)
    const offset = circumferance / 4

    return (
      <g className="trade-circle-tooltip--double">
        <circle
          cx="20"
          cy="20"
          r={radius}
          fill="transparent"
          stroke={sumOfOpenCloseOperations ? colors.blue : colors.grey}
          strokeWidth="4"
          strokeDasharray={`${closePercentage * circumferance} ${openPercentage * circumferance}`}
          strokeDashoffset={
            -offset + NanToZero((closePercentage * circumferance - openPercentage * circumferance) / 2)
          }
        ></circle>
        <circle
          cx="20"
          cy="20"
          r={radius}
          fill="transparent"
          stroke={sumOfOpenCloseOperations ? colors.azure : colors.grey}
          strokeWidth="4"
          strokeDasharray={`${openPercentage * circumferance} ${closePercentage * circumferance}`}
          strokeDashoffset={offset}
        ></circle>
      </g>
    )
  }

  return (
    <section className="trade-progress trade-typography">
      <div className="operations__title-wrapper">
        <Typography.Title level={2}>
          {timeFrameLabel} {t('chart.operations')}
        </Typography.Title>
      </div>
      <div className="trade-progress-graph">
        <div className="trade-progress-chart">
          <svg viewBox="0 0 40 40">
            <Tooltip
              placement="bottom"
              overlayClassName="tooltip-trades"
              title={
                <>
                  <div className="trade-icon trade-down">
                    <Typography.Title level={4}>
                      {sumOfSignOperations && renderAvailableData(formatNumber(negativePercentage))}%
                    </Typography.Title>
                  </div>
                  <div className="trade-icon trade-up">
                    <Typography.Title level={4}>
                      {sumOfSignOperations && renderAvailableData(formatNumber(posetivePercentage))}%
                    </Typography.Title>
                  </div>
                </>
              }
            >
              {getTradeCircle(negativePercentage / 100, posetivePercentage / 100)}
            </Tooltip>
            <g className="text" transform="translate(15,20)">
              <g transform="translate(0,-1)">
                <circle cx="-2" cy="-0.5" r="1" fill={colors.azure} />
                <text>
                  {t('general.open')}{' '}
                  {sumOfOpenCloseOperations && renderAvailableData(formatNumber(openPercentage))}%
                </text>
              </g>
              <g transform="translate(0,3)">
                <circle cx="-2" cy="-0.5" r="1" fill={colors.blue} />
                <text>
                  {t('general.close')}{' '}
                  {sumOfOpenCloseOperations && renderAvailableData(formatNumber(closePercentage))}%
                </text>
              </g>
            </g>
            <Tooltip
              placement="bottom"
              overlayClassName="tooltip-trades"
              title={
                <div>
                  <div className="trade-icon trade-open">
                    <Typography.Title level={4}>
                      {t('general.open')}{' '}
                      {sumOfOpenCloseOperations && renderAvailableData(formatNumber(openPercentage))}%
                    </Typography.Title>
                  </div>
                  <div className="trade-icon trade-close">
                    <Typography.Title level={4}>
                      {t('general.close')}{' '}
                      {sumOfOpenCloseOperations && renderAvailableData(formatNumber(closePercentage))}%
                    </Typography.Title>
                  </div>
                </div>
              }
            >
              {getOperationsCircle(closePercentage / 100, openPercentage / 100)}
            </Tooltip>
          </svg>
        </div>
      </div>

      <div className="trade-progress-values">
        <div className="trade-arrows-container">
          <div className="trade-arrow trade-down">
            <Typography.Title level={4}>
              {sumOfSignOperations && renderAvailableData(formatNumber(negativePercentage))}%
            </Typography.Title>
          </div>
          <div className="trade-arrow trade-up">
            <Typography.Title level={4}>
              {sumOfSignOperations && renderAvailableData(formatNumber(posetivePercentage))}%
            </Typography.Title>
          </div>
        </div>
        <div className="progress-1-values">
          <div data-augmented-ui="tl-clip tr-round bl-clip border" className="styleme trade-card trade-down">
            <Typography.Title level={4} className="trade-value trade-open">
              {t('general.open')}{' '}
              <Typography.Text>
                {sumOfSignOperations && renderAvailableData(formatNumber(100 - closeNegativePercentage))}%
              </Typography.Text>
            </Typography.Title>
            <Typography.Title level={4} className="trade-value trade-close">
              {t('general.close')}{' '}
              <Typography.Text>
                {sumOfSignOperations && renderAvailableData(formatNumber(closeNegativePercentage))}%
              </Typography.Text>
            </Typography.Title>
          </div>

          <div data-augmented-ui="tl-round tr-clip br-clip border" className="styleme trade-card trade-up">
            <Typography.Title level={4} className="trade-value trade-open">
              {t('general.open')}{' '}
              <Typography.Text>
                {sumOfOpenCloseOperations && renderAvailableData(formatNumber(100 - closePosetivePercentage))}
                %
              </Typography.Text>
            </Typography.Title>
            <Typography.Title level={4} className="trade-value trade-close">
              {t('general.close')}{' '}
              <Typography.Text>
                {sumOfOpenCloseOperations && renderAvailableData(formatNumber(closePosetivePercentage))}%
              </Typography.Text>
            </Typography.Title>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CartesianOperations
