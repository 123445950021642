import React, { useState } from 'react'

import MenuToggle from 'assets/icon/menu-toggle.svg'

import { Layout } from 'antd'
import style from './left-rail.module.scss'
import classNames from 'classnames'
const DEFAULT_WIDTH = 500

interface Props {
  customWidth?: number
  children: JSX.Element | JSX.Element[]
  classname?: string
}
const LeftRail: React.FC<Props> = ({ children, customWidth, classname }) => {
  const [collapsed, setCollapsed] = useState(false)
  const width = customWidth || DEFAULT_WIDTH
  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={width}
      className={classNames(style['left-rail'], classname)}
    >
      <img
        src={MenuToggle}
        className="menu-toggle"
        onClick={() => setCollapsed(!collapsed)}
        alt="Menu Toggle"
      />
      <section className="sider-content">{children}</section>
    </Layout.Sider>
  )
}

export default LeftRail
