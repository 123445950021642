import React from 'react'
import * as Store from 'types/store'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import HistoricalStrategyCandleStickContainer from 'v2/containers/frames/Historical/StrategyCandleStick'
import StrategyCandleStickContainer from 'v2/containers/frames/StrategyCandleStick'

interface Props {
  frame: Store.Frame
}

const CandleStickBySession: React.FC<Props> = ({ frame }) => {
  const session = useSelector(selectOperativeSessionSession)
  if (session.isHistorical) {
    return <HistoricalStrategyCandleStickContainer frame={frame} />
  }

  return <StrategyCandleStickContainer frame={frame} />
}

export default CandleStickBySession
