import { update_snapshot, update_ticker } from 'sockets/portfolios/portfolio_operations_pb'
import * as Store from 'types/store'
import { TimeFrame } from 'types/enums'

export const mapPortfolioOperationsSigmas = (
  feed: update_snapshot.AsObject | update_ticker.AsObject,
): Store.PortfolioSigmas => {
  const { sigmas } = feed
  if (!sigmas) {
    return null
  }

  const { prtCartesianOperationsList } = sigmas

  return {
    cartesianOperationsList: prtCartesianOperationsList?.map((operation) => {
      return {
        closeNegativeOp: operation.closeNegativeOp || 0,
        closeOp: operation.closeOp,
        closePositiveOp: operation.closePositiveOp || 0,
        negativeOp: operation.negativeOp || 0,
        openNegativeOp: operation.openNegativeOp || 0,
        openOp: operation.openOp,
        openPositiveOp: operation.openPositiveOp,
        positiveOp: operation.positiveOp || 0,
        type: operation.type as TimeFrame,
        total: operation.totalOp,
      }
    }),
  } as Store.PortfolioSigmas
}
