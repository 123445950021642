import { SearchOutlined } from '@ant-design/icons'
import { Button, Input, Space, Typography } from 'antd'
import { FULL_DATE_FORMAT } from 'core/formats'
import moment from 'moment'
import React from 'react'
import { t } from 'core/i18n'

export const getColumnSearchProps = (dataKey: string) => {
  // Fidan commented as hooks cannot be used outside React.
  // const searchInput = useRef(null)
  return {
    filterDropdown: function filterDropDown({ setSelectedKeys, selectedKeys, confirm, clearFilters }) {
      return (
        <div className="nonDraggable" style={{ padding: 8 }}>
          <Input
            placeholder={t('table.filter.search')}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t('general.ok')}
            </Button>
            <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
              {t('table.filter.reset')}
            </Button>
          </Space>
        </div>
      )
    },
    filterIcon: function filterIcon(filtered) {
      return <SearchOutlined style={{ color: filtered ? '#1890ff' : null }} />
    },
    onFilter: (value: string, record) =>
      record[dataKey] ? record[dataKey].toString().toLowerCase().includes(value.toLowerCase()) : false,
  }
}

export const sortString = (a: string, b: string) => {
  if (a?.toLowerCase() > b?.toLowerCase()) {
    return -1
  }
  if (a?.toLowerCase() < b?.toLowerCase()) {
    return 1
  }
  return 0
}

export const sortNumber = (a: number, b: number) => {
  return a - b
}

export const getCheckBoxFilter = (key: string, data: any, textLabel = (text: string) => text) => {
  return {
    filters: data.reduce((array, d) => {
      if (!array.some((item) => item.value === d[key])) {
        const item = {
          text: textLabel(d[key]),
          value: d[key],
        }
        array.push(item)
      }
      return array
    }, []),
    onFilter: (value: string, record: Json) => record[key].includes(value),
  }
}

export const compareByDate = (a: Date, b: Date): number => {
  //Force unexisting dates to go to the bottom of the list
  if (!b) {
    return 1
  }
  if (!a) {
    return -1
  }

  if (a > b) {
    return 1
  }
  if (a < b) {
    return -1
  }
  return 0
}

export const renderDate = (date?: Date) => {
  return date && <Typography.Text>{moment(date).format(FULL_DATE_FORMAT)}</Typography.Text>
}
