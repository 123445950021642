import React from 'react'
import { useTranslation } from 'react-i18next'
import Debug from 'debug'

import { Table } from 'antd'
import * as Store from 'types/store'

import styles from './styles.module.scss'
import { getAssetStatusLabel } from 'core/helpers'
import { formatNumber } from 'core/formats'
import { AssetStatus, OpenOperationType } from 'types/enums'

import { getTableHeights } from 'helpers/framePanelHelper'
import AssetStatusDropdown from 'v2/containers/CandleStick/Asset/AssetStatusDropdown'
import { ColumnsType } from 'antd/lib/table'
import { getCheckBoxFilter, getColumnSearchProps, sortNumber, sortString } from 'helpers/tableHelpers'
import { useSelector } from 'react-redux'
import { selectOperativeSessionAssets } from 'store/pages/selectors'

const debug = new Debug('Frontend')

const TABLE_HEIGHTS = getTableHeights()
debug('TABLE_HEIGHTS', TABLE_HEIGHTS)
const TOP_OFFSET = 204

interface Props {
  height: number
  data: Store.PortfolioListAssetsTable[]
  columnKeys: string[]
  baseCurrency: string
}

const AssetsTable: React.FC<Props> = ({ data, height, columnKeys = [], baseCurrency = '' }) => {
  const { t } = useTranslation()
  const assets = useSelector(selectOperativeSessionAssets)

  const tableHeight = height - TOP_OFFSET

  const getLastPosition = (type: OpenOperationType) => {
    switch (type) {
      case OpenOperationType.Long:
        return t('assets.long')
      case OpenOperationType.Short:
        return t('assets.short')
      case OpenOperationType.Flat:
        return t('assets.flat')
      default:
        return ''
    }
  }

  const TABLE_COLUMNS: ColumnsType<Store.PortfolioListAssetsTable> = [
    {
      title: t('table.column.assetName'),
      dataIndex: 'assetName',
      sorter: data.length > 1 ? (a, b) => sortString(a.assetName, b.assetName) : null,
      defaultSortOrder: 'descend',
      ...(data.length > 1 ? getColumnSearchProps('assetName') : {}),
      width: 199,
      fixed: 'left',
    },
    {
      title: t('table.column.lastPrice'),
      sorter: data.length > 1 ? (a, b) => sortNumber(a.lastPrice, b.lastPrice) : null,
      dataIndex: 'lastPrice',
      width: 140,
      render: (value) => {
        return value
      },
    },
    {
      title: t('table.column.instrumentIsin'),
      ...(data.length > 1 ? getColumnSearchProps('instrumentIsin') : {}),
      dataIndex: 'instrumentIsin',
      width: 140,
      sorter: data.length > 1 ? (a, b) => sortString(a.instrumentIsin, b.instrumentIsin) : null,
    },
    {
      // title: t('table.column.model'), JI-TRANSLATE
      title: 'Group',
      sorter: data.length > 1 ? (a, b) => sortString(a.groupName, b.groupName) : null,
      dataIndex: 'groupName',
      width: 140,
      ...(data.length > 1 ? getColumnSearchProps('groupName') : {}),
    },
    {
      title: t('table.column.model'),
      sorter: data.length > 1 ? (a, b) => sortString(a.modelName, b.modelName) : null,
      dataIndex: 'modelName',
      width: 140,
      ...(data.length > 1 ? getColumnSearchProps('modelName') : {}),
    },
    {
      // title: t('table.column.version'), JI-TRANSLATE
      title: 'Model version',
      sorter: data.length > 1 ? (a, b) => sortString(a.modelVersion, b.modelVersion) : null,
      dataIndex: 'modelVersion',
      width: 120,
      ...(data.length > 1 ? getColumnSearchProps('modelVersion') : {}),
    },
    {
      // title: t('table.column.modelType'), JI-TRANSLATE
      title: 'Model Type',
      sorter: data.length > 1 ? (a, b) => sortString(a.modelType, b.modelType) : null,
      dataIndex: 'modelType',
      width: 120,
      ...(data.length > 1 ? getColumnSearchProps('modelType') : {}),
    },
    {
      title: t('table.column.modelStatus'),
      sorter: data.length > 1 ? (a, b) => sortString(a.modelStatus, b.modelStatus) : null,
      dataIndex: 'modelStatus',
      width: 140,
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (modelStatus: AssetStatus, assetData: Store.PortfolioListAssetsTable) => {
        const asset = assets.find((asset) => asset.name === assetData.assetName)
        if (!asset) {
          return null
        }
        return <AssetStatusDropdown asset={asset} disabled={false} />
      },
      ...(data.length > 1 ? getCheckBoxFilter('modelStatus', data, getAssetStatusLabel) : {}),
    },
    {
      title: t('table.column.lastPosition'),
      sorter: data.length > 1 ? (a, b) => sortString(a.lastPosition, b.lastPosition) : null,
      dataIndex: 'lastPosition',
      width: 140,
      render: (lastPosition) => getLastPosition(lastPosition),
      ...(data.length > 1 ? getCheckBoxFilter('lastPosition', data, getLastPosition) : {}),
    },
    {
      title: t('table.column.quantity'),
      sorter: data.length > 1 ? (a, b) => sortNumber(a.quantity, b.quantity) : null,
      dataIndex: 'quantity',
      width: 140,
    },
    {
      title: t('table.column.countervalue', { baseCurrency: baseCurrency ?? '' }), // ?? to avoid warning when value is not ready
      sorter: data.length > 1 ? (a, b) => sortNumber(a.value, b.value) : null,
      dataIndex: 'value',
      width: 140,
      render: (value) => {
        return formatNumber(value)
      },
    },
    {
      title: t('table.column.tdyTradingReturn'),
      sorter: data.length > 1 ? (a, b) => sortNumber(a.tdyTradingRetBc, b.tdyTradingRetBc) : null,
      dataIndex: 'tdyTradingRetBc',
      width: 140,
      render: (value: number) => {
        return formatNumber(value)
      },
    },
    {
      // WRONG VALUE
      title: t('table.column.tdyTradingReturnPercentage'),
      sorter: data.length > 1 ? (a, b) => sortNumber(a.tdyPercRet, b.tdyPercRet) : null,
      dataIndex: 'tdyPercRet',
      width: 140,
      render: (value) => {
        return `${formatNumber(value * 100)} %`
      },
    },
    {
      title: t('table.column.ytdTradingReturn'),
      sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdTradingRetBc, b.ytdTradingRetBc) : null,
      dataIndex: 'ytdTradingRetBc',
      width: 140,
      render: (value) => {
        return formatNumber(value)
      },
    },
    {
      title: t('table.column.ytdExchangeRatesReturn'),
      sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdExchRetBc, b.ytdExchRetBc) : null,
      dataIndex: 'ytdExchRetBc',
      width: 200,
      render: (value) => {
        return formatNumber(value)
      },
    },
    {
      title: t('table.column.ytdTotalReturn'),
      sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdAllRetBc, b.ytdAllRetBc) : null,
      dataIndex: 'ytdAllRetBc',
      width: 140,
      render: (value: number) => {
        return formatNumber(value)
      },
    },
    {
      title: `YTD Total Ret. Fees ${baseCurrency}`,
      // sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdAllRetBc, b.ytdAllRetBc) : null,
      dataIndex: 'allRetBcFees',
      width: 140,
      render: (value: number) => {
        return formatNumber(value)
      },
    },
    {
      // WRONG VALUE
      title: t('table.column.ytdTradingReturnPercentage'),
      sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdPercRet, b.ytdPercRet) : null,
      dataIndex: 'ytdPercRet',
      width: 140,
      render: (value: number) => {
        return `${formatNumber(value * 100)} %`
      },
    },
    {
      title: t('table.column.lastOpenTdyTradingReturn'),
      sorter: data.length > 1 ? (a, b) => sortNumber(a.opTdyTradRetBc, b.opTdyTradRetBc) : null,
      dataIndex: 'opTdyTradRetBc',
      width: 200,
      render: (value: number) => {
        return formatNumber(value)
      },
    },
    {
      // RIGHT VALUE
      title: t('table.column.lastOpenTdyReturnPercetage'),
      sorter: data.length > 1 ? (a, b) => sortNumber(a.opTdyPercRet, b.opTdyPercRet) : null,
      dataIndex: 'opTdyPercRet',
      width: 200,
      render: (value: number) => {
        return `${formatNumber(value * 100)} %`
      },
    },
    {
      title: t('table.column.tdyTotalOperations'),
      sorter: data.length > 1 ? (a, b) => sortNumber(a.tdyTotalOp, b.tdyTotalOp) : null,
      dataIndex: 'tdyTotalOp',
      width: 140,
    },
    {
      title: t('table.column.tdyPositiveOperations'),
      sorter: data.length > 1 ? (a, b) => sortNumber(a.tdyPositiveOp, b.tdyPositiveOp) : null,
      dataIndex: 'tdyPositiveOp',
      width: 140,
    },
    {
      title: t('table.column.tdyNegativeOperations'),
      sorter: data.length > 1 ? (a, b) => sortNumber(a.tdyNegativeOp, b.tdyNegativeOp) : null,
      dataIndex: 'tdyNegativeOp',
      width: 200,
    },
    {
      title: t('table.column.ytdTotalOperations'),
      sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdTotalOp, b.ytdTotalOp) : null,
      dataIndex: 'ytdTotalOp',
      width: 140,
    },
    {
      title: t('table.column.ytdPositiveOperations'),
      sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdPositiveOp, b.ytdPositiveOp) : null,
      dataIndex: 'ytdPositiveOp',
      width: 140,
    },
    {
      title: t('table.column.ytdNegativeOperations'),
      sorter: data.length > 1 ? (a, b) => sortNumber(a.ytdNegativeOp, b.ytdNegativeOp) : null,
      dataIndex: 'ytdNegativeOp',
      width: 200,
    },
  ]

  const getColumns = (
    defaultColumns: ColumnsType<Store.PortfolioListAssetsTable>,
    selectedColumnsKeys,
  ): ColumnsType<Store.PortfolioListAssetsTable> => {
    let generatedCols = []

    if (selectedColumnsKeys.length) {
      const selectedColumns = defaultColumns.filter((col: any) => selectedColumnsKeys.includes(col.dataIndex))

      generatedCols = [
        {
          title: t('table.column.assetName'),
          dataIndex: 'assetName',
          sorter: (a, b) => sortString(a.assetName, b.assetName),
          defaultSortOrder: 'descend',
          ...getColumnSearchProps('assetName'),
          width: 199,
          fixed: 'left',
        },
        ...selectedColumns,
      ]
    } else {
      generatedCols = defaultColumns
    }

    return generatedCols
  }

  return (
    <section className={`${styles['assets-table']} assets-table nonDraggable`}>
      <Table
        rowKey="assetName"
        getPopupContainer={() => document.querySelector('.assets-table')}
        columns={getColumns(TABLE_COLUMNS, columnKeys)}
        dataSource={data}
        pagination={false}
        scroll={{ x: 200, y: tableHeight }}
      />
    </section>
  )
}

export default AssetsTable
