import React, { useContext } from 'react'
import * as Store from 'types/store'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
import { useMeasure } from 'react-use'
import PortfolioCandleStickContainer from 'v2/containers/CandleStick/Portfolio'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import HistoricalCandleStickContainer from 'v2/containers/CandleStick/historical/Portfolio'

const CandlestickView: React.FC = () => {
  const context = useContext(CandleStickFrameContext)
  const session = useSelector(selectOperativeSessionSession)
  const { timeFrame, resource } = context
  const [ref, { width, height }] = useMeasure()
  const portfolio = resource as Store.Portfolio

  const isSmall = () => {
    if (width < 50 || height < 50) {
      return true
    }

    return false
  }
  const getCandleStick = () => {
    if (session.isHistorical) {
      return (
        <HistoricalCandleStickContainer portfolio={portfolio} key={timeFrame} width={width} height={height} />
      )
    }
    return (
      <PortfolioCandleStickContainer portfolio={portfolio} key={timeFrame} width={width} height={height} />
    )
  }

  return (
    <section className="candlestick-view nonDraggable" ref={ref}>
      {!isSmall() && getCandleStick()}
    </section>
  )
}

export default CandlestickView
