import React from 'react'
import { Dropdown, Menu, Popover } from 'antd'
import { ExtendedDataNode, TransferType } from 'types/ui'
import { useTranslation } from 'react-i18next'
import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { getMainItem, getAllAssets } from 'helpers/transfer/itemRemoveHelper'
import { AssetStatus, TransferContext } from 'types/enums'
import colors from 'config/colors'
import * as Store from 'types/store'

interface Props {
  node: ExtendedDataNode
  onClick: (node: ExtendedDataNode, type: TransferType) => void
  context: TransferContext
}

const RemovePortfolio: React.FC<Props> = ({ node, onClick, context }) => {
  const { t } = useTranslation()

  const items1 = [getMainItem(node), getAllAssets(node)].filter((el) => el !== null)
  const items2 = [getAllAssets(node)].filter((el) => el !== null)
  const items = context === TransferContext.All ? items1 : items2
  const trigger: any =
    context === TransferContext.AtSessionLaunch ? ['contextMenu'] : ['click', 'contextMenu']

  const isBroken =
    node.children.length > 0 &&
    node.children.some((asset) => (asset.resource as Store.Asset).status === AssetStatus.Broken)

  const dropDownMenu = items.map(item => {
    return {
      key: item.key,
      label: item.label,
      onClick: () => {onClick(node, item.transferType)}
    }
  })

  return (
    <Dropdown menu={{items: dropDownMenu}} trigger={trigger} getPopupContainer={(trigger) => trigger.parentElement}>
      <span className={node.selected && 'selected-item'}>
        {node.selected && <CheckOutlined style={{ marginRight: 5 }} />}
        {isBroken && (
          <Popover content={t('error.selectedBrokenAsset')} trigger="hover">
            <ExclamationCircleOutlined style={{ color: colors.red }} className="mr-5" />
          </Popover>
        )}
        {node.title}
      </span>
    </Dropdown>
  )
}

export default RemovePortfolio
