import { update_snapshot, update_ticker } from 'sockets/portfolios/portfolio_return_pb'
import * as Store from 'types/store'
import { convertToFloat, formatSigmaDate } from '../common'
import { TimeFrame } from 'types/enums'
import colors from 'config/colors'

export const mapPortfolioReturnsSigmas = (
  feed: update_snapshot.AsObject | update_ticker.AsObject,
): Store.PortfolioSigmas => {
  const { sigmas } = feed
  if (!sigmas) {
    return null
  }

  const { prtAllRetBcList, prtExchRetBcList, prtTradRetBcList, prtClosedCandle, prtOpenCandle } = sigmas

  return {
    returnList: prtTradRetBcList?.map((item) => ({
      tradingRetBc: convertToFloat(item.tradingRetBc) || 0,
      type: item.type as TimeFrame,
      lastEvtTs: formatSigmaDate(item?.lastEvtTs),
    })),
    allRetBc: prtAllRetBcList?.map((prtAllRetBc) => ({
      value: convertToFloat(prtAllRetBc.allRetBc),
      type: prtAllRetBc.type,
    })),
    exchRetBc: prtExchRetBcList?.map((prtAllRetBc) => ({
      value: convertToFloat(prtAllRetBc.exchRetBc),
      type: prtAllRetBc.type,
    })),
    closedCandle: prtClosedCandle && {
      color:
        convertToFloat(prtClosedCandle.maxTdyTradingRetBc) ===
        convertToFloat(prtClosedCandle.minTdyTradingRetBc)
          ? 'silver'
          : convertToFloat(prtClosedCandle.maxTdyTradingRetBc) >
            convertToFloat(prtClosedCandle.minTdyTradingRetBc)
          ? colors.red
          : colors.positiveGreen,
      date: formatSigmaDate(prtClosedCandle.startTs),
      endTime: formatSigmaDate(prtClosedCandle.endTs),
      high: convertToFloat(prtClosedCandle.maxTdyTradingRetBc),
      low: convertToFloat(prtClosedCandle.minTdyTradingRetBc),
      open: convertToFloat(prtClosedCandle.maxTdyTradingRetBc),
      close: convertToFloat(prtClosedCandle.minTdyTradingRetBc),
      startTime: formatSigmaDate(prtClosedCandle.startTs),
    },
    openCandle: prtOpenCandle && {
      color:
        convertToFloat(prtOpenCandle.maxTdyTradingRetBc) === convertToFloat(prtOpenCandle.minTdyTradingRetBc)
          ? 'silver'
          : convertToFloat(prtOpenCandle.maxTdyTradingRetBc) >
            convertToFloat(prtOpenCandle.minTdyTradingRetBc)
          ? colors.red
          : colors.positiveGreen,
      date: formatSigmaDate(prtOpenCandle.startTs),
      endTime: formatSigmaDate(prtOpenCandle.endTs),
      high: convertToFloat(prtOpenCandle.maxTdyTradingRetBc),
      low: convertToFloat(prtOpenCandle.minTdyTradingRetBc),
      open: convertToFloat(prtOpenCandle.maxTdyTradingRetBc),
      close: convertToFloat(prtOpenCandle.minTdyTradingRetBc),
      startTime: formatSigmaDate(prtOpenCandle.startTs),
    },
  } as Store.PortfolioSigmas
}
