import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AppContentCard from 'components/AppContentCard'
import { Modal, Row, Col, Space, Button, Typography, Avatar, DatePicker, Form, Radio } from 'antd'
import moment from 'moment'
import styles from './styles.module.scss'
import CloseIcon from 'assets/icon/close-icon.svg'

const { Title } = Typography
import classNames from 'classnames'
import { themes } from './themes'
import { useTheme } from 'providers/ThemeProvider'
import { concat, range } from 'lodash'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'

interface Props {
  visible: boolean
  onClose: () => void
  onOk: (start: moment.Moment, end: moment.Moment) => void
  timeRange: [moment.Moment, moment.Moment]
}

const SelectTimeRangeModal: React.FC<Props> = ({ onClose, visible, timeRange, onOk }) => {
  const { t } = useTranslation()
  const [startTime, setStartTime] = useState(timeRange[0])
  const [endTime, setEndTime] = useState(timeRange[1])
  const [selectedValue, setSelectedValue] = useState(null)
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const frameModalClass = classNames(styles['time-range-modal'], themeStyles['theme'])
  const session = useSelector(selectOperativeSessionSession)

  useEffect(() => {
    setStartTime(timeRange[0])
    setEndTime(timeRange[1])
  }, [timeRange])

  const intervalTimeStart = (date: moment.Moment, startTime: Date, endTime: Date) => {
    const isStartDate = date.clone().startOf('day').isSame(moment(startTime).startOf('day'))
    const isEndDate = date.clone().startOf('day').isSame(moment(endTime).startOf('day'))

    if (isStartDate) {
      if (isEndDate)
        return {
          disabledHours: () =>
            concat(range(0, moment(startTime).hour()), range(moment(endTime).hour() + 1, 24)),
          disabledMinutes: () =>
            date.hour() === moment(startTime).hour() && date.hour() === moment(endTime).hour()
              ? concat(range(0, moment(startTime).minute()), range(moment(endTime).minute() + 1, 60))
              : date.hour() === moment(startTime).hour()
              ? range(0, moment(startTime).minute())
              : date.hour() === moment(endTime).hour()
              ? range(moment(endTime).minute() + 1, 60)
              : [],
        }

      return {
        disabledHours: () => range(0, moment(startTime).hour()),
        disabledMinutes: () =>
          date.hour() === moment(startTime).hour() ? range(0, moment(startTime).minute()) : [],
      }
    }

    if (isEndDate) {
      return {
        disabledHours: () => range(moment(startTime).hour() + 1, 24),
        disabledMinutes: () =>
          date.hour() === moment(startTime).hour() ? range(moment(startTime).minute(), 60) : [],
      }
    }
    return {}
  }

  const disabledRangeTimeStart = (date: moment.Moment) => {
    if (!date) {
      return {
        disabledHours: () => range(0, 24),
        disabledMinutes: () => range(0, 60),
      }
    }

    if (session.isHistorical) {
      return intervalTimeStart(date, session.startTime, session.endTime)
    }

    return intervalTimeStart(date, session.startTime, moment().toDate())
  }

  const disabledRangeTimeEnd = (date: moment.Moment) => {
    if (!date || !startTime) {
      return {
        disabledHours: () => range(0, 24),
        disabledMinutes: () => range(0, 60),
      }
    }

    if (session.isHistorical) {
      return intervalTimeStart(date, startTime.toDate(), session.endTime)
    }

    return intervalTimeStart(date, startTime.toDate(), moment().toDate())
  }

  const selectRelativeTimeRange = (startTime: moment.Moment) => {
    setStartTime(startTime)
    const endTime = session.isHistorical ? moment(session.endTime) : null
    setEndTime(endTime)
  }

  const handleRadioCheck = (e) => {
    const start = moment(session.startTime)
    const date = session.isHistorical ? moment(session.endTime) : moment()
    date.subtract(e.target.value, 'minutes')

    selectRelativeTimeRange(date.isBefore(start) ? start : date)
    setSelectedValue(e.target.value)
  }

  return (
    <section>
      <Modal
        closable={false}
        title={null}
        width="30%"
        centered={true}
        open={visible}
        onOk={onClose}
        className={frameModalClass}
        footer={null}
        destroyOnClose
        wrapClassName="nonDraggable"
      >
        <AppContentCard>
          <div className="close-btn-wrapper">
            <Button className="col-close" type="ghost" onClick={onClose}>
              <Avatar shape="square" src={CloseIcon} size={24} />
            </Button>
          </div>

          <Title level={2} className="mb-16">
            {t('settings.time')}
          </Title>

          <Row>
            <Col span={24}>
              <Title level={3} className="primary-color mt-30 mb-16">
                {t('settings.absoluteTimeRange')}
              </Title>

              <Row gutter={[12, 0]}>
                <Col span={12}>
                  <Form.Item label={t('general.from')}>
                    <DatePicker
                      showTime={{ format: 'HH:mm', hideDisabledOptions: true }}
                      format="YYYY/MM/DD HH:mm"
                      placeholder={t('operativeSession.startDate')}
                      showNow={!session.isHistorical}
                      disabledDate={(date) => {
                        if (session.isHistorical) {
                          return (
                            date.clone().startOf('day').isBefore(moment(session.startTime).startOf('day')) ||
                            date.clone().endOf('day').isAfter(moment(session.endTime).endOf('day'))
                          )
                        }
                        return (
                          date.clone().startOf('day').isAfter(moment()) ||
                          date.clone().startOf('day').isBefore(moment(session.startTime).startOf('day'))
                        )
                      }}
                      onChange={(value) => {
                        setStartTime(value)
                        if (value === null) {
                          setEndTime(null)
                        }
                      }}
                      disabledTime={disabledRangeTimeStart}
                      value={startTime}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('general.to')}>
                    <DatePicker
                      disabled={!startTime}
                      showTime={{ format: 'HH:mm', hideDisabledOptions: true }}
                      format="YYYY/MM/DD HH:mm"
                      placeholder={t('operativeSession.now')}
                      value={endTime}
                      disabledDate={(date) => {
                        if (!date || !startTime) {
                          return false
                        }

                        return (
                          date.clone().startOf('day').isAfter(moment()) ||
                          date.clone().endOf('day').isBefore(startTime.clone().startOf('day')) ||
                          (session.endTime &&
                            date.clone().endOf('day').isAfter(moment(session.endTime).endOf('day')))
                        )
                      }}
                      onChange={(value) => setEndTime(value)}
                      disabledTime={disabledRangeTimeEnd}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Title level={3} className="primary-color mt-30 mb-16">
                {t('settings.relativeTimeRanges')}
              </Title>

              <Radio.Group
                onChange={(e) => handleRadioCheck(e)}
                value={endTime === timeRange[1] ? selectedValue : null}
              >
                <Radio.Button value="5">{t('settings.lastMinutes', { num: 5 })}</Radio.Button>
                <Radio.Button value="15">{t('settings.lastMinutes', { num: 15 })}</Radio.Button>
                <Radio.Button value="30">{t('settings.lastMinutes', { num: 30 })}</Radio.Button>
                <Radio.Button value="60">{t('settings.lastHour', { num: 1 })}</Radio.Button>
                <Radio.Button value={3 * 60}>{t('settings.lastHours', { num: 3 })}</Radio.Button>
                <Radio.Button value={6 * 60}>{t('settings.lastHours', { num: 6 })}</Radio.Button>
                <Radio.Button value={12 * 60}>{t('settings.lastHours', { num: 12 })}</Radio.Button>
                <Radio.Button value={24 * 60}>{t('settings.lastHours', { num: 24 })}</Radio.Button>
                <Radio.Button value={48 * 60}>{t('settings.lastDays', { num: 2 })}</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>

          <Row justify="end" className="mt-30">
            <Space>
              <Button type="primary" disabled={!startTime} onClick={() => onOk(startTime, endTime)}>
                {t('general.ok')}
              </Button>
            </Space>
          </Row>
        </AppContentCard>
      </Modal>
    </section>
  )
}

export default SelectTimeRangeModal
