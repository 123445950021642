import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'

import { Table, Typography, Tag } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { OpenOperationType } from 'types/enums'

import colors from 'config/colors'
import { FULL_DATE_FORMAT } from 'core/formats'
import { translateInstruments, translateOrderSign } from 'helpers/common'
import { compareByDate } from 'helpers/tableHelpers'
import { withVisible } from 'components/hoc'

const TOP_OFFSET = 105

interface Props {
  data: Array<Store.SignalsData>
  height: number
}

const SignalsTable: React.FC<Props> = ({ data, height }) => {
  const { t } = useTranslation()
  const tableHeight = height - TOP_OFFSET

  const getSignal = (status: OpenOperationType) => {
    switch (status) {
      case OpenOperationType.Flat: {
        return <Tag color={colors.standByFlat}>{t('assets.flat')}</Tag>
      }
      case OpenOperationType.Long: {
        return <Tag color={colors.standByLong}>{t('assets.long')}</Tag>
      }
      case OpenOperationType.Short: {
        return <Tag color={colors.standByShort}>{t('assets.short')}</Tag>
      }
    }
  }

  // const customizeRenderEmpty = () => (
  //   <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('error.noData')} />
  // )

  const TABLE_COLUMNS: ColumnsType<Store.SignalsData> = [
    {
      title: '',
      children: [
        {
          title: t('table.column.assetName'),
          dataIndex: 'name',
          width: 199,
          fixed: 'left',
        },
      ],
    },
    {
      title: t('table.column.modelAlgoSignal'),
      className: 'signal-col',

      children: [
        {
          title: t('table.column.sign'),
          dataIndex: ['modelSignal', 'sign'],
          width: 140,
          render: (signal) => getSignal(signal),
        },
        {
          title: t('table.column.quantity'),
          dataIndex: ['modelSignal', 'quantity'],
          width: 140,
        },
        {
          title: t('table.column.price'),
          dataIndex: ['modelSignal', 'price'],
          width: 140,
          render: (price) => price,
        },
        {
          title: t('table.column.date'),
          dataIndex: ['modelSignal', 'time'],
          width: 230,
          defaultSortOrder: 'descend',
          sorter: (a, b) => compareByDate(a.modelSignal?.time, b.modelSignal?.time),
          // eslint-disable-next-line react/display-name
          render: (signalDate) => (
            <Typography.Text>{signalDate && moment(signalDate).format(FULL_DATE_FORMAT)}</Typography.Text>
          ),
        },
      ],
    },
    {
      title: t('table.column.theoreticalOrder'),
      className: 'order-col',
      children: [
        {
          title: t('table.column.sign'),
          dataIndex: ['theoreticalOrder', 'sign'],
          width: 140,
          render: (text) => translateOrderSign(text),
        },
        {
          title: t('table.column.quantity'),
          dataIndex: ['theoreticalOrder', 'quantity'],
          width: 140,
        },
        {
          title: t('table.column.price'),
          dataIndex: ['theoreticalOrder', 'price'],
          width: 140,
          render: (value: number) => value,
        },
        {
          title: t('table.column.date'),
          dataIndex: ['theoreticalOrder', 'time'],
          width: 230,
          // eslint-disable-next-line react/display-name
          render: (signalDate) => (
            <Typography.Text>{signalDate && moment(signalDate).format(FULL_DATE_FORMAT)}</Typography.Text>
          ),
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: t('table.column.isinCode'),
          dataIndex: ['securityRefData', 'instrumentIsin'],
          width: 230,
        },
        {
          title: t('table.column.currency'),
          dataIndex: ['securityRefData', 'tradingCurrency'],
          width: 140,
        },
        {
          title: t('table.column.market'),
          dataIndex: ['securityRefData', 'marketCode'],
          width: 140,
        },
        {
          title: t('table.column.assetClass'),
          dataIndex: ['securityRefData', 'instrumentClass'],
          width: 140,
          render: (text) => translateInstruments(text),
        },
      ],
    },
  ]

  return (
    <section className="signals-table nonDraggable">
      <Table
        dataSource={data}
        columns={TABLE_COLUMNS}
        pagination={false}
        scroll={{ x: 200, y: tableHeight }}
      />
    </section>
  )
}

export default withVisible(SignalsTable)
