import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from 'store/user/selectors'
import { useNavigate } from 'react-router-dom'
import Profile from 'components/user-profile/Profile'
import { logout } from 'store/user/actions'
import routes from 'config/routes'

const ProfileContainer: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(selectUser)

  const onLogout = () => {
    localStorage.removeItem('AUTH_USER')
    dispatch(logout())
    navigate(routes.login.url)
  }
  return <Profile user={user} onLogout={onLogout} />
}

export default ProfileContainer
