import { Button, Modal, Row, Space, Typography } from 'antd'
import React, { useState } from 'react'
import Markdown from 'react-markdown'

import { useTranslation } from 'react-i18next'
import AppContentCard from 'components/AppContentCard'
const { Title, Text } = Typography
interface Props {
  text: string
}

const Help: React.FC<Props> = ({ text }) => {
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      <Button className="help-btn" onClick={() => setShowModal(true)}>
        <span className="help-icon help-icon--active"></span>
        <Text>{t('general.help')}</Text>
      </Button>

      <Modal
        centered
        title={null}
        width={'50%'}
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        closable={false}
      >
        <AppContentCard>
          <Title level={2} className="mb-16">
            {t('general.help')}
          </Title>
          <Markdown source={text} />
          <Row justify="end" className="mt-30">
            <Space>
              <Button type="primary" key="back" onClick={() => setShowModal(false)}>
                {t('general.ok')}
              </Button>
            </Space>
          </Row>
        </AppContentCard>
      </Modal>
    </>
  )
}

export default Help
