import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SessionType } from 'types/enums'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import { Button, Typography, Drawer } from 'antd'
import { useDrag } from './useDrag'
const { Title, Text } = Typography
import classNames from 'classnames'
import DrawerCard from './DrawerCard'
import styles from './styles.module.scss'
import { LeftArrow, RightArrow } from './Arrows'
import { getDrawerFrames } from 'core/frameHelper'
import { themes } from './themes'
import { useTheme } from 'providers/ThemeProvider'

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>

interface Props {
  sessionType: SessionType
}

const FramesDrawer: React.FC<Props> = ({ sessionType }) => {
  const { t } = useTranslation()
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const [showDrawer, setShowDrawer] = useState(false)

  const { dragStart, dragStop, dragMove } = useDrag()

  const onClose = () => {
    setShowDrawer(false)
  }
  const handleDrag =
    ({ scrollContainer }: scrollVisibilityApiType) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff
        }
      })

  const frames = getDrawerFrames(sessionType)

  const drawerClass = classNames(styles['frames-drawer'], themeStyles['theme'])

  return (
    <div onMouseLeave={dragStop}>
      <Button
        className="dashboard-actions-btn dashboard-actions-btn--add-frames"
        onClick={() => setShowDrawer(true)}
      >
        <span className="action-icon action-icon--active"></span>
        <Text>{t('operativeSession.addFrame')}</Text>
      </Button>
      <Drawer
        height={275}
        className={drawerClass}
        title={
          <>
            <Title level={3}>{t('operativeSession.frames')}</Title>
            <Button type="dashed" className="add-btn" onClick={onClose}>
              <Text>{t('general.close')}</Text>
            </Button>
          </>
        }
        placement={'bottom'}
        closable={false}
        onClose={onClose}
        open={showDrawer}
        key={'bottom'}
        mask={false}
      >
        <ScrollMenu
          onMouseDown={() => dragStart}
          onMouseUp={() => dragStop}
          onMouseMove={handleDrag}
          RightArrow={RightArrow}
          LeftArrow={LeftArrow}
        >
          {frames.map((frameType) => (
            <DrawerCard itemId={frameType} key={frameType} />
          ))}
        </ScrollMenu>
      </Drawer>
    </div>
  )
}

export default FramesDrawer
