import React from 'react'

import OperativeSession from 'containers/OperativeSession'
import AppContent from 'components/AppContent'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearLayout } from 'store/dashboard/actions'
import { useMount } from 'react-use'
import { selectApp } from 'store/App/selectors'
import { LoadingState } from 'types/enums'
import { loadSessionById } from 'store/pages/operative-session/actions'
import { selectOperativeSessionState } from 'store/pages/selectors'
import NotificationManager from './NotificationManager'

const OperativeSessionPage: React.FC = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const app = useSelector(selectApp)
  const state = useSelector(selectOperativeSessionState)
  const sessionId = parseInt(id)

  useMount(() => {
    dispatch(loadSessionById(sessionId))
    dispatch(clearLayout())
  })

  if (state === LoadingState.Loading || app.loadingState === LoadingState.Loading) {
    return null
  }

  return (
    <AppContent isDisplayFlex={true} fullHeight={false} className={'operative-session-wrapper version-2'}>
      <NotificationManager />
      <OperativeSession />
    </AppContent>
  )
}

export default OperativeSessionPage
