import { update_snapshot, update_ticker } from 'sockets/assets/asset_principal_pb'
import * as Store from 'types/store'

export const mapAssetPrincipalSigmas = (
  feed: update_ticker.AsObject | update_snapshot.AsObject,
): Store.AssetSigmas => {
  const { sigmas } = feed as update_ticker.AsObject

  if (!sigmas) {
    return null
  }

  return {
    // Unknown
  } as Store.AssetSigmas
}
