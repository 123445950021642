import React from 'react'
import DetailsView from 'v2/containers/frames/AssetCandleStick/DetailsView'
import HistoricalDetailsView from 'v2/containers/frames/Historical/AssetCandleStick/DetailsView'
import * as Store from 'types/store'

interface Props {
  session: Store.Session
}

const DetailsViewBySession: React.FC<Props> = ({ session }) => {
  if (session.isHistorical) {
    return <HistoricalDetailsView />
  }

  return <DetailsView />
}

export default DetailsViewBySession
