import { update_snapshot, update_ticker } from 'sockets/portfolios/portfolio_position_pb'
import * as Store from 'types/store'
import { convertToFloat } from '../common'

export const mapPortfolioPositionSigmas = (
  feed: update_snapshot.AsObject | update_ticker.AsObject,
): Store.PortfolioSigmas => {
  const { sigmas } = feed
  if (!sigmas) {
    return null
  }

  const { prtCartesianPosition } = sigmas

  return {
    position: prtCartesianPosition && {
      valueLong: convertToFloat(prtCartesianPosition.countervalueBcLong) || 0,
      valueShort: convertToFloat(prtCartesianPosition.countervalueBcShort) || 0,
      value: convertToFloat(prtCartesianPosition.countervalueBc) || 0,
      netHedging: convertToFloat(prtCartesianPosition.netHedging) || 0,
    },
  } as Store.PortfolioSigmas
}
