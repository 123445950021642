import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { saveResolutions } from 'store/dashboard/actions'
import { selectDashboardResolutions } from 'store/dashboard/selectors'
import { Resolution } from 'types/ui'
import GuidesTable from 'v2/components/settings/GuidesTable'

const GuidesTableContainer: React.FC = () => {
  const dispatch = useDispatch()
  const resolutions: Resolution[] = useSelector(selectDashboardResolutions)

  const saveChanges = (selectedRowKeys: number[]) => {
    const changed = resolutions.map((resolution) => {
      return {
        ...resolution,
        active: selectedRowKeys.includes(resolution.key),
      }
    })
    dispatch(saveResolutions(changed))
  }

  const editName = (name: string, key: number) => {
    const changed = resolutions.map((resolution) => {
      if (resolution.key === key) {
        return { ...resolution, name }
      }
      return resolution
    })
    dispatch(saveResolutions(changed))
  }

  return <GuidesTable resolutions={resolutions} saveChanges={saveChanges} editName={editName} />
}

export default GuidesTableContainer
