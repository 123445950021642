import React from 'react'
import { OpenOperationType } from 'types/enums'
import * as Store from 'types/store'

interface Props {
  openOperation: Store.Operation
}

const OpenOperationChart: React.FC<Props> = ({ openOperation }) => {
  const rightColor = () => {
    if (!openOperation) {
      return 'color-gray'
    }

    const { totalTradingReturn } = openOperation
    if (totalTradingReturn > 0) {
      return 'color-green'
    } else if (totalTradingReturn < 0) {
      return 'color-red'
    } else {
      return 'color-gray'
    }
  }

  const leftColor = () => {
    if (!openOperation) {
      return 'color-gray'
    }

    switch (openOperation.sign) {
      case OpenOperationType.Long:
        return 'color-blue'
      case OpenOperationType.Short:
        return 'color-orange'
      default:
        return 'color-gray'
    }
  }

  return (
    <section className="open-operation-chart">
      <svg viewBox="0 0 43 42">
        <circle
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          className={leftColor()}
          strokeWidth="9"
          strokeDasharray="50 50"
          strokeDashoffset="-25"
        ></circle>
        <circle
          cx="22"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          className={rightColor()}
          strokeWidth="9"
          strokeDasharray="50 50"
          strokeDashoffset="25"
        ></circle>
      </svg>
    </section>
  )
}

export default OpenOperationChart
