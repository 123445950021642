import * as K from './constants'
import produce from 'immer'
import { LoadingState } from 'types/enums'
import { AnyAction } from 'redux'
import * as Store from 'types/store'
import { ResponseCode } from 'types/enums.api'

export const initialState: Store.UserState = {
  state: LoadingState.Unset,
  user: null,
  error: null,
}

const reducer = (state = initialState, action: AnyAction) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case K.SET_STATUS:
        draft.state = action.payload as LoadingState
        break
      case K.FETCH_USER_SUCCESS:
        draft.user = action.payload as Store.SystemUser
        break
      case K.SET_LOADING_STATE:
        const loadingState = action.payload as LoadingState
        draft.state = loadingState
        break
      case K.SET_ERROR:
        draft.error = action.payload as ResponseCode
        break
    }
  })
}

export default reducer
