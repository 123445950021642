import { Instruments, SessionType, OrderSign as OrderSign, MetricType } from 'types/enums'
import { t } from 'core/i18n'

/* the key returned from proto socket for the session metrics has a following structure
 * <SERVICE_NAME>.<COMPONENT_NAME>-<COMPONENT_INSTANCE_ID>.<SESSION_TYPE>-<SESSION_ID>
 * session key example: ji-aws.session.virtual-3
 * asset key example: ji-aws.model-instance-1234.backtest-4
 */
const SESSION_METRICS_KEY_REGEX =
  /^(?<service>[\w-]+)\.(?<component>[a-zA-Z-]+)(?<componentId>[\d]+)?\.(?<sessionType>\w+)-(?<sessionId>\d+)/
const SESSION_COMPONENT_NAME = 'session'

export const getSessionTypeLabel = (type: SessionType): string => {
  switch (type) {
    case SessionType.BackTest:
      return t('sessionType.backtest')
    case SessionType.Signals:
      return t('sessionType.signals')
    case SessionType.Trading:
      return t('sessionType.trading')
    case SessionType.Virtual:
      return t('sessionType.virtual')
  }
}

export const translateInstruments = (instrument: Instruments) => {
  if (!instrument) {
    return
  }
  const tag = `instruments.$${instrument}`
  return t(tag)
}

export const translateOrderSign = (sign: OrderSign) => {
  switch (sign) {
    case OrderSign.Buy:
      return t('orderSign.buy')
    case OrderSign.Sell:
      return t('orderSign.sell')
    default:
      return sign
  }
}

export const NanToZero = (value: number): number => {
  if (!value || isNaN(value)) {
    return 0
  }
  return value
}

export const sessionMetricsTypeSelector = (key: string): MetricType => {
  const regExpExecArray = SESSION_METRICS_KEY_REGEX.exec(key)

  // if there is no match with regex result will be null
  if (regExpExecArray) {
    const { groups } = regExpExecArray
    if (groups.component === SESSION_COMPONENT_NAME) {
      return MetricType.Session
    }
    if (groups.componentId) {
      return MetricType.Asset
    }
  }

  return null
}
