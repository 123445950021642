import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import { ChartChild } from 'types/chart'

const TICK_HEIGHT = 35

interface Props {
  data: number[]
  right?: number
  ticks?: number[]
  tickFormat?: (value: number) => string
  colorizer?: (text: d3.Selection<d3.BaseType, unknown, any, unknown>) => void
}

const YAxisRight: React.FC<Props & ChartChild> = ({
  data,
  yDomain,
  chartHeight: height,
  chartWidth: width,
  ticks,
  colorizer,
  tickFormat,
}) => {
  const element = useRef(null)

  const getYDomain = () => {
    if (yDomain) {
      return yDomain
    }
    return [0, d3.max(data)]
  }

  useEffect(() => {
    const yScale = d3.scaleLinear().domain(getYDomain()).range([height, 0])

    const rightAxis = d3.axisRight(yScale)

    if (ticks) {
      rightAxis.tickValues(ticks)
    }
    rightAxis.ticks(Math.round(height / TICK_HEIGHT))

    if (tickFormat) {
      rightAxis.tickFormat(tickFormat)
    }

    const selection = d3
      .select(element.current)
      .attr('class', 'axis y-axis')
      .attr('transform', `translate(${width}, 0)`)

    rightAxis.tickFormat

    selection.call(rightAxis)
    colorizer && selection.selectAll('.tick text').call(colorizer)
  })

  return <g ref={element} />
}

export default YAxisRight
