import React, { useEffect, useState } from 'react'
import Sessions from 'components/Sessions'
import { useDispatch, useSelector } from 'react-redux'
import { cleanUpSession, deleteSession, setLoadingState } from 'store/sessions/actions'
import { LoadingState } from 'types/enums'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { selectSessions, selectSessionsState } from 'store/sessions/selectors'
import NoSessions from 'components/Sessions/NoSessions'
import { selectEnabledSessionTypes } from 'store/platform/selectors'
import { Session } from 'types/store'

const SessionsContainer: React.FC = () => {
  const sessions = useSelector(selectSessions)
  const loadingState = useSelector(selectSessionsState)
  const enabledSessionTypes = useSelector(selectEnabledSessionTypes)
  
  let fullSessionsList: Session[] = sessions.map(session => {
    return {
      ...session,
      isDisabled: false,
    }
  })

  const [mappedSessions, setMappedSessions] = useState<Session[]>(fullSessionsList)

  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    switch (loadingState) {
      case LoadingState.Deleted:
        notification.success({
          message: t('notification.type.success'),
          description: t('notification.message.deletionSuccess'),
          duration: 5,
        })
        dispatch(setLoadingState(LoadingState.Loaded))
        break
      case LoadingState.DeleteFailed:
        notification.error({
          message: t('notification.type.error'),
          description: t('notification.message.somethingWentWrong'),
          duration: 5,
        })
        dispatch(setLoadingState(LoadingState.Loaded))
        break
    }
  }, [loadingState])

  useEffect(() => {
    if (!enabledSessionTypes) {
      return
    }
    const mappedSessionTypes = enabledSessionTypes.map((type) => type.toLowerCase())
    // Disable menu items that are not enabled by the orchestrator
    mappedSessions.map((session) => {
      if (!mappedSessionTypes.includes(session.type)) {
        session.isDisabled = true
      }
    })

    setMappedSessions(mappedSessions)
  }, [enabledSessionTypes])

  const onDelete = (id: number) => {
    dispatch(deleteSession(id))
  }
  const onCleanUp = (id: number) => {
    dispatch(cleanUpSession(id))
  }

  if (!sessions.length) {
    return <NoSessions />
  }

  return <Sessions sessions={mappedSessions} onDelete={onDelete} onClean={onCleanUp} />
}

export default SessionsContainer
