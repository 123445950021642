import React, { useEffect, useState, useCallback } from 'react'
import { ThemeType } from 'themes/types'
import { ThemeContext } from './ThemeContext'

const THEME_STORAGE = 'app-theme'

interface Props {
  initialTheme: ThemeType
  children: JSX.Element | JSX.Element[]
}

const ThemeProvider: React.FC<Props> = ({ initialTheme, children }) => {
  const [currentTheme, setCurrentTheme] = useState<ThemeType>(initialTheme)
  useEffect(() => {
    //Set current theme
    const theme = localStorage.getItem(THEME_STORAGE) as ThemeType
    if (theme) {
      setTheme(theme)
    } else {
      setBodyClass(initialTheme)
    }
  }, [])

  const setBodyClass = useCallback((theme: ThemeType) => {
    //remove old
    document.body.classList.remove(currentTheme)
    //add new
    document.body.classList.add(theme)
  }, [])

  const setTheme = useCallback((newTheme: ThemeType) => {
    localStorage.setItem(THEME_STORAGE, newTheme)
    setBodyClass(newTheme)
    setCurrentTheme(newTheme)
  }, [])

  const getStyles = useCallback((themes: Json[]): Json => {
    if (!themes || themes.length === 0) {
      console.log(`no themes found`)
      return {}
    }

    let theme = themes.find((t) => t.id === currentTheme)
    if (!theme) {
      console.log(`theme ${currentTheme} not found`)
      theme = themes.find((t) => t.id === initialTheme)
    }
    return theme.styles
  }, [])

  return (
    <ThemeContext.Provider value={{ theme: currentTheme, setTheme, getStyles }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
