import React from 'react'

import AppContent from 'components/AppContent'
import LeftRail from 'components/LeftRail'
import AssetTransfer from 'containers/transfer/AssetTransfer'

import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSessionAssets, setCurrentNode } from 'store/pages/setup-session/actions'
import { selectSetupSessionPage } from 'store/pages/selectors'
import { StrategyViewerContainer } from 'containers/StrategyViewer'
import Breadcrumb from 'containers/Breadcrumb'
import SessionHeader from 'components/Sessions/SessionHeader'
import { selectSessions } from 'store/sessions/selectors'
import styles from './styles.module.scss'
import { useMount } from 'react-use'
import { LoadingState, SessionType } from 'types/enums'
import { selectAppLoadingState } from 'store/App/selectors'
import BrokenEntityReporter from 'containers/SessionSetup/BrokenEntityReporter'

const SessionSetupPage: React.FC = () => {
  const { id } = useParams()
  const sessionId = parseInt(id)
  const dispatch = useDispatch()
  const page = useSelector(selectSetupSessionPage)
  const appLoadingState = useSelector(selectAppLoadingState)
  const { currentNode, assets } = page
  const sessions = useSelector(selectSessions)
  const session = sessions.find((s) => s.id === sessionId)

  const isEnterEnabled = () => {
    if (assets?.length > 0) {
      return true
    }

    //rif: #718
    if (session.type === SessionType.Signals || session.type === SessionType.Trading) {
      return true
    }

    return false
  }

  useMount(() => {
    //Clean previous selection
    dispatch(setCurrentNode({ node: null, operation: null }))
    //Fetch new resources
    dispatch(fetchSessionAssets(sessionId))
  })

  if (appLoadingState === LoadingState.Loading) {
    return null
  }

  return (
    <>
      <LeftRail>
        <AssetTransfer session={session} />
      </LeftRail>

      <AppContent fullHeight isDisplayFlex className={styles['session-setup-page']}>
        <Breadcrumb node={currentNode && currentNode.node} />
        <SessionHeader session={session} loadingState={page.loadingState} disabled={!isEnterEnabled()} />
        {currentNode && <StrategyViewerContainer userAction={currentNode} isReadOnly={true} />}
      </AppContent>

      <BrokenEntityReporter session={session} />
    </>
  )
}

export default SessionSetupPage
