import React from 'react'
import { Row, Col, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { renderAvailableData } from 'core/helpers'
import { formatNumber } from 'core/formats'
import * as Store from 'types/store'
import moment from 'moment'
import { OrderSign } from 'types/enums'

interface Props {
  jiTrade: Store.JITrade
}

const LastJITrade: React.FC<Props> = ({ jiTrade }) => {
  const { t } = useTranslation()

  const getColor = () => {
    const tradeSignal = renderAvailableData(jiTrade?.sign)
    switch (tradeSignal) {
      case OrderSign.Buy:
        return 'green'
      case OrderSign.Sell:
        return 'red'
      default:
        return ''
    }
  }

  return (
    <Col className="last-trade-col">
      <div className="trade-card last-trade trade-typography">
        <div className="last-trade__title-wrapper">
          <Typography.Title level={2}>{t('chart.lastJITrade')}</Typography.Title>
        </div>

        <Row align="middle">
          <Col span={10}>
            <Typography.Title level={4} className="align-left">
              {t('chart.sign')}
            </Typography.Title>
          </Col>
          <Col span={14}>
            <Typography.Title level={4} className="align-right">
              <Typography.Text className={getColor()}>{renderAvailableData(jiTrade?.sign)}</Typography.Text>
            </Typography.Title>
          </Col>

          <Col span={10}>
            <Typography.Title level={4} className="align-left">
              {t('chart.price')}
            </Typography.Title>
          </Col>
          <Col span={14}>
            <Typography.Title level={4} className="align-right">
              {renderAvailableData(jiTrade?.price)}
            </Typography.Title>
          </Col>

          <Col span={10}>
            <Typography.Title level={4} className="align-left">
              {t('chart.size')}
            </Typography.Title>
          </Col>
          <Col span={14}>
            <Typography.Title level={4} className="align-right">
              {renderAvailableData(formatNumber(jiTrade?.quantity))}
            </Typography.Title>
          </Col>

          <Col span={10}>
            <Typography.Title level={4} className="align-left">
              {t('chart.time')}
            </Typography.Title>
          </Col>
          <Col span={14}>
            <Typography.Title level={4} className="align-right">
              {renderAvailableData(jiTrade && moment(jiTrade?.time).format('HH:mm:ss'))}
            </Typography.Title>
          </Col>
        </Row>
      </div>
    </Col>
  )
}

export default LastJITrade
