import React from 'react'
import Breadcrumb from 'components/common/Breadcrumb'
import { ExtendedDataNode, NodeType } from 'types/ui'
import { useSelector } from 'react-redux'
import * as Store from 'types/store'
import { selectResources } from 'store/resources/selectors'
import { useTranslation } from 'react-i18next'

interface Props {
  node: ExtendedDataNode
}

const BreadcrumbContainer: React.FC<Props> = ({ node }) => {
  const data = useSelector(selectResources)
  const { t } = useTranslation()
  const { portfolios, strategies } = data
  let slices = []

  if (!node) {
    return null
  }

  const createTag = (tag: 'strategy' | 'portfolio', name: string) => {
    switch (tag) {
      case 'strategy':
        return `${t('general.strategyShort')}: ${name}`
      case 'portfolio':
        return `${t('general.portfolioShort')}: ${name}`
    }
  }

  const strategySlices = (id: number) => {
    const strategy = strategies.data.find((item) => item.id === id)

    if (!strategy) {
      return null
    }

    return [createTag('strategy', strategy.name)]
  }

  const portfolioSlices = (id: number) => {
    const portfolio = portfolios.data.find((item) => item.id === id)

    if (!portfolio) {
      return null
    }

    const strategy = strategies.data.find((item) => item.id === portfolio.strategyId)

    if (!strategy) {
      return null
    }

    return [createTag('strategy', strategy.name), createTag('portfolio', portfolio.name)]
  }

  const assetSlices = (asset: Store.Asset) => {
    const slices = portfolioSlices(asset.portfolioId)

    if (!slices) {
      return null
    }
    // FH - 16/09/2022: for now we decided not to render the last node, as we would need to implement selected asset from Assets panel as well (currently it works only from tree selector).
    // return [...slices, asset.name]
    return [...slices]
  }

  switch (node.type) {
    case NodeType.Strategy:
      slices = strategySlices(node.resource.id)
      break
    case NodeType.Portfolio:
      slices = portfolioSlices(node.resource.id)
      break
    case NodeType.Asset:
      slices = assetSlices(node.resource as Store.Asset)
      break
  }

  if (!slices) {
    return null
  }

  return <Breadcrumb slices={slices} />
}

export default React.memo(BreadcrumbContainer)
