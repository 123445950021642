import React from 'react'

import AppContent from 'components/AppContent'
import LeftRail from 'components/LeftRail'
import HistoricalAssetTreeView from 'containers/HistoricalAssetTreeView'

import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { selectSetupSessionPage } from 'store/pages/selectors'
import StrategyViewer from 'containers/HistoricalStrategyViewer'
import Breadcrumb from 'containers/Breadcrumb'
import SessionHeader from 'components/Sessions/SessionHeader'
import { LoadingState } from 'types/enums'
import { selectSessions } from 'store/sessions/selectors'
import styles from './styles.module.scss'
import { useMount } from 'react-use'
import { fetchHistoricalResources } from 'store/App/actions'
import { selectAppLoadingState } from 'store/App/selectors'
import { setCurrentNode } from 'store/pages/setup-session/actions'

const HistoricalSetupPage: React.FC = () => {
  const { id } = useParams()
  const sessionId = parseInt(id)
  const dispatch = useDispatch()
  const page = useSelector(selectSetupSessionPage)
  const appLoadingState = useSelector(selectAppLoadingState)
  const { currentNode, loadingState } = page
  const sessions = useSelector(selectSessions)
  const session = sessions.find((s) => s.id === sessionId)

  const isEnterEnabled = () => {
    //It's historical, so assets must be already selected
    return true
  }

  useMount(() => {
    //Clean previous selection
    dispatch(setCurrentNode({ node: null, operation: null }))
    //Fetch new resources
    dispatch(fetchHistoricalResources(sessionId))
  })

  if (appLoadingState === LoadingState.Loading) {
    return null
  }

  return (
    <>
      <LeftRail>
        <HistoricalAssetTreeView sessionId={sessionId} />
      </LeftRail>

      <AppContent fullHeight isDisplayFlex className={styles['historical-setup-page']}>
        <Breadcrumb node={currentNode && currentNode.node} />
        <SessionHeader session={session} loadingState={loadingState} disabled={!isEnterEnabled()} />
        {currentNode && <StrategyViewer userAction={currentNode} />}
      </AppContent>
    </>
  )
}

export default HistoricalSetupPage
