import React from 'react'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'
import { sumBy } from 'core/helpers'
import { formatGliphNumber, formatNumber } from 'core/formats'
import { AnalysisWindowTabType, Dimension } from 'types/enums'
import { getDimensionTitle, groupByDimensions } from 'services/sunburstService'
import { SunburstData } from 'types/chart'

interface Props {
  data: Store.PositionMonitoring[]
  secondDimension: Dimension
  tabType: AnalysisWindowTabType
  baseCurrency: string
}

const LongShortDimensionTable: React.FC<Props> = ({ data, secondDimension, tabType, baseCurrency }) => {
  const { t } = useTranslation()

  const grouped = groupByDimensions(data, Dimension.LongShort, secondDimension)

  const renderValue = (value, percent) => {
    if (tabType === AnalysisWindowTabType.PositionMonitoring) {
      return value && `${formatGliphNumber(value)} ${formatNumber(percent)}`
    }
    return value && `${formatGliphNumber(value)}`
  }

  const columns = [
    {
      dataIndex: 'name',
      title: t('dimensions.longShort'),
      render: (name, row) => {
        if (row.columnLabel !== Dimension.LongShort) {
          return ''
        }
        return name
      },
    },
    secondDimension && {
      dataIndex: 'columnLabel',
      title: getDimensionTitle(secondDimension),
      render: (columnLabel, row: SunburstData) => {
        if (row.children) {
          return row.children.map((item) => item.name).join(', ')
        }
        return row.name
      },
    },
    {
      dataIndex: 'value',
      title: () => {
        if (tabType === AnalysisWindowTabType.PositionMonitoring) {
          return t('table.column.countervalue', { baseCurrency })
        }
        return t('table.column.return', { baseCurrency })
      },
      render: (value, row: SunburstData) => {
        if (value || value === 0) {
          return renderValue(value, row.percent)
        }
        return row.children && renderValue(sumBy('value')(row.children), row.percent)
      },
    },
  ].filter((item) => item)

  return (
    <Table
      columns={columns}
      dataSource={grouped}
      pagination={false}
      expandable={{
        expandIconColumnIndex: 1,
        expandedRowRender: () => null,
        rowExpandable: (row: SunburstData) => row.children && row.children.length > 1,
      }}
      rowKey="name"
      className="table-assets"
    />
  )
}

export default LongShortDimensionTable
