export const STATE_KEY = 'layouts'

export const FETCH_LAYOUTS = `${STATE_KEY}/FETCH_LAYOUTS`
export const FETCH_LAYOUTS_SUCCESS = `${STATE_KEY}/FETCH_LAYOUTS_SUCCESS`
export const CREATE_LAYOUT = `${STATE_KEY}/CREATE_LAYOUT`
export const CREATE_LAYOUT_SUCCESS = `${STATE_KEY}/CREATE_LAYOUT_SUCCESS`
export const UPDATE_LAYOUT = `${STATE_KEY}/UPDATE_LAYOUT`
export const UPDATE_LAYOUT_SUCCESS = `${STATE_KEY}/UPDATE_LAYOUT_SUCCESS`
export const DELETE_LAYOUT = `${STATE_KEY}/DELETE_LAYOUT`
export const DELETE_LAYOUT_SUCCESS = `${STATE_KEY}/DELETE_LAYOUT_SUCCESS`
export const SET_STATUS = `${STATE_KEY}/SET_STATUS`
