import { config } from 'core'
import axios from 'axios'
import { getApiError, getAuthenticationHeader } from './common'
import * as Api from 'types/api'
import { TSInterval } from 'types/enums'
import { Feeds, Sigma } from 'types/enums.api'
import sigmaServices from 'config/sigmaServices'

const { Operations } = sigmaServices.Assets

export const getDataPoints = async (
  entityId: number,
  sigma: Sigma,
  feed: Feeds,
  interval: TSInterval,
  from: Date,
  to: Date,
  sessionId: number,
  headers?: any,
  tsUrl?: string,
): Promise<Api.DataPointFeed | ApiError> => {
  // This function is used both inside react and webworkers (that does not have access to the headers and config)
  const header = headers ?? await getAuthenticationHeader()
  const date = from.toISOString()
  const endDate = to.toISOString()

  try {
    const url = config.socket.timeSeries(
      feed,
      entityId,
      sigma,
      date,
      sigma !== Operations.ClosedOperation && interval,
      endDate,
      sessionId,
      tsUrl,
    )
    const response = await axios.get(url, header)
    return response.data
  } catch (error) {
    return getApiError(error)
  }
}

export const getAssetInstanceId = async (
  operativeSession: number,
  assetId: number,
): Promise<Api.AssetModelInstanceResponse | ApiError> => {
  const header = await getAuthenticationHeader()
  const query = `query ($operativeSession: Int , $assetId: Int) {
    application_asset_model_instance(where: {operative_session_id: {_eq: $operativeSession}, asset_id: {_eq: $assetId}}) {
      instance_id
    }
  }`

  const variables = {
    operativeSession,
    assetId,
  }

  const data: GraphQlQuery = {
    query,
    variables: variables,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}
