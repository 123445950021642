import React, { useCallback, useEffect, useState } from 'react'
import * as Store from 'types/store'

import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { LoadingState, MetricType, SessionStatus, SessionType } from 'types/enums'
import * as api from 'api/liveSession'
import { mapUint8ArrayMessage } from 'services/store/mapMetrics'
import { useDispatch } from 'react-redux'
import { updateSessionStatus } from 'store/sessions/actions'
import { getSocketSessionStatus } from 'services/enumConverter'
import { SocketSessionStatus } from 'types/enums.api'
import Debug from 'debug'
import debounce from 'debounce'
import { fetchSessionSetupUpdate } from 'store/pages/setup-session/actions'
import { sessionMetricsTypeSelector } from 'helpers/common'

const debug = new Debug('frontend')

const updateSession = async (id: number, type: SessionType) => {
  const response = await api.updateSession(id, type)
  if (response.status == 201) {
    return true
  }
  return false
}

interface Props {
  session: Store.Session
  loadingState: LoadingState
  disabled: boolean
}

const SessionUpdateButton: React.FC<Props> = ({ session, loadingState, disabled }) => {
  const { t } = useTranslation()
  const loading = loadingState === LoadingState.Updating || loadingState === LoadingState.Deleting
  const { status } = session
  const [updating, setUpdating] = useState(false)
  const dispatch = useDispatch()

  const isHidden =
    status === SessionStatus.Off ||
    session.isHistorical ||
    session.type === SessionType.BackTest ||
    session.type === SessionType.Virtual

  const isLoading =
    loading || updating || status === SessionStatus.Terminating || status === SessionStatus.Updating

  const updateAssets = useCallback(
    debounce(() => {
      dispatch(fetchSessionSetupUpdate(session.id))
    }, 500),
    [dispatch],
  )

  const sessionCallback = (status: SocketSessionStatus) => {
    const sessionStatus = getSocketSessionStatus(status as SocketSessionStatus)
    dispatch(updateSessionStatus({ sessionId: session.id, status: sessionStatus }))
  }

  const assetCallback = () => {
    updateAssets()
  }

  const onMessage = async (message: MessageEvent<Blob>) => {
    const bytes = new Uint8Array(await message.data.arrayBuffer())
    const protoMessage = mapUint8ArrayMessage(bytes)
    if (protoMessage.metric) {
      const { metric } = protoMessage
      if (metric) {
        const { key, status } = metric
        const metricType = sessionMetricsTypeSelector(key)

        switch (metricType) {
          case MetricType.Asset:
            assetCallback()
            break
          case MetricType.Session:
            sessionCallback(status as SocketSessionStatus)
            break
        }
      }
      debug('Session Update proto message', protoMessage)
    }
  }

  useEffect(() => {
    if (!session.isHistorical) {
      const unsubscribe = api.subscribeToSessionMetrics(session.id, onMessage)
      return () => unsubscribe && unsubscribe()
    }
  }, [])

  const getButtonLabel = () => {
    switch (status) {
      case SessionStatus.Created:
        return t('session.run')
      case SessionStatus.Terminating:
        return t('status.terminating')
      case SessionStatus.Updating:
        return t('status.updating')
      default:
        return t('session.update')
    }
  }

  const onClick = async () => {
    setUpdating(true)
    await updateSession(session.id, session.type)
    setUpdating(false)
  }

  if (isHidden) {
    return null
  }

  return (
    <Button
      block
      type="primary"
      className="primary"
      loading={isLoading}
      disabled={disabled}
      onClick={onClick}
    >
      {getButtonLabel()}
    </Button>
  )
}

export default SessionUpdateButton
