import { useEffect, useRef, useState } from 'react'
import * as Store from 'types/store'
import Debug from 'debug'
import { subscribeToSnapshots } from 'api/socket'
import { PortfolioFeed, Sigma } from 'types/enums.api'
import { BUFFERS, config } from 'core'
import { FlowControlSpeed } from 'types/enums'
import { useInterval } from 'react-use'
import { isOffline } from './common'
import { deserializePortfolioBuffer } from 'helpers/protoHelpers'
import { removeUndefinedFields } from 'services/store/mapService'

const debug = Debug('sockets')

const { isAlive } = config.socketOptions

export const usePortfolioSigmaSnapshotState = (
  portfolioID: number,
  sessionId: number,
  sigmas: Sigma[],
  service: PortfolioFeed,
  bufferTimespan = BUFFERS.Small,
): [Store.PortfolioSigmas, boolean, Date] => {
  //those ref are used to buffer data
  const dataRef = useRef<Store.PortfolioSigmas>(null)

  const [data, setData] = useState<Store.PortfolioSigmas>(null)

  const [lastTick, setLastTick] = useState<Date>(new Date())

  const messageReceived = () => {
    setLastTick(new Date())
  }

  const onMessage = (message: SocketMessage) => {
    const { data } = message

    if (typeof data === "string") {
      // Skip messages that does not contains data
      return
    }

    try {
      data.arrayBuffer().then((buffer) => {
        //trigger messageReceived as first line
        messageReceived()

        const deserialized = deserializePortfolioBuffer(buffer, service, 'snapshot')
        if (!deserialized) {
          return
        }
        const [key, mappedSigmas] = deserialized

        dataRef.current = { ...dataRef.current, ...removeUndefinedFields(mappedSigmas) }

        debug('socket received Portfolio Sigmas', key, mappedSigmas)
      })
    } catch (error) {
      debug('couldnt parse Portfolio Sigmas', error)
    }
  }

  useEffect(() => {
    dataRef.current = null

    const unsubscribe = subscribeToSnapshots(
      portfolioID,
      onMessage,
      sessionId,
      portfolioID,
      service,
      sigmas,
      FlowControlSpeed.High,
    )

    return () => {
      unsubscribe && unsubscribe()
    }
  }, [portfolioID])

  //Update with buffered data
  useInterval(() => setData(dataRef.current), bufferTimespan)

  useInterval(() => {
    //This trigger a notification to context
    setLastTick(new Date(lastTick))
  }, isAlive)

  return [data, isOffline(lastTick), lastTick]
}
