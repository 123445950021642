import React, { useContext } from 'react'
import { GridItemActions } from 'types/ui'
import { Modal, Popover, Spin, Typography } from 'antd'
import { useWindowSize } from 'react-use'
import { CELL_HEIGHT, CELL_WIDTH, getGridItemClass } from 'services/dashboardService'
import { useTranslation } from 'react-i18next'
import ContextMenu from '../ContextMenu'
import * as Store from 'types/store'
import classNames from 'classnames'
import generalStyles from './styles.module.scss'
import smallStyles from './small.module.scss'
import mediumStyles from './medium.module.scss'
import largeStyles from './large.module.scss'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
import { useSelector } from 'react-redux'
import { selectDragging } from 'store/dashboard/selectors'
import { getSpinnerMessage, renderAvailableData } from 'core/helpers'
import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { SessionStatus, SessionType } from 'types/enums'
import colors from 'config/colors'
import PanelContentViewBySession from 'v2/containers/Wrapper/StrategyCandleStickFrame/PanelContentBySession'
import { themes } from './themes'
import { useTheme } from 'providers/ThemeProvider'

interface Props {
  onItemClick: (action: GridItemActions) => void
  sigmas: Store.StrategySigmas
  session: Store.Session
}

const StrategyCandleStickFrame: React.FC<Props> = ({ onItemClick, sigmas, session }) => {
  const { t } = useTranslation()
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const context = useContext(CandleStickFrameContext)
  const { squares, isFullscreen, setFullScreen, isOffline } = context

  const { width: windowWidth, height: windowHeight } = useWindowSize()
  const isDragging = useSelector(selectDragging)

  const candlestickClass = classNames(
    generalStyles['strategy-candlestick'],
    smallStyles['strategy-candlestick'],
    mediumStyles['strategy-candlestick'],
    largeStyles['strategy-candlestick'],
    themeStyles['theme'],
    {
      'offline-frame': isOffline(),
    },
  )

  const { status, type: sessionType } = session

  const spinning =
    (status === SessionStatus.Running ||
      status === SessionStatus.Updating ||
      status === SessionStatus.Terminating) &&
    sessionType === SessionType.BackTest

  const [width, height] = isFullscreen
    ? [windowWidth, windowHeight]
    : [squares.x * CELL_WIDTH, squares.y * CELL_HEIGHT]

  const strategyName = renderAvailableData(sigmas?.refData?.strategyName)
  const strategyTarget = renderAvailableData(sigmas?.refData?.strategyTarget)

  const toggleFullscreen = () => {
    setFullScreen(!isFullscreen)
  }

  const onMenuItemClick = (action: GridItemActions) => {
    if (action === GridItemActions.FullScreen) {
      toggleFullscreen()
      return
    }
    onItemClick(action)
  }

  const popover = (
    <>
      <div>{strategyName}</div>
      <div>{strategyTarget}</div>
    </>
  )

  const renderTitle = () => {
    const troubleIcon = isOffline() && (
      <Popover content={t('error.technicalProblems')} trigger="hover">
        <ExclamationCircleOutlined style={{ color: colors.red }} className="mr-5" />
      </Popover>
    )

    if (isDragging || squares.x > 10) {
      return (
        <>
          {troubleIcon}
          <Typography.Title level={3} className="title" ellipsis={true}>
            {strategyName}
            <span className="expanded-title">{strategyTarget}</span>
          </Typography.Title>
        </>
      )
    }

    return (
      <>
        {troubleIcon}
        <Popover
          overlayClassName="options-popover"
          placement="top"
          content={popover}
          trigger="hover"
          zIndex={50}
        >
          <Typography.Title level={3} className="title" ellipsis={true}>
            {strategyName}
            <span className="expanded-title">{strategyTarget}</span>
          </Typography.Title>
        </Popover>
      </>
    )
  }

  const panelContent = spinning ? (
    <section className="running-session-spin-container">
      <ContextMenu
        isFullscreen={isFullscreen}
        onMenuItemClick={onMenuItemClick}
        items={[{ key: GridItemActions.Close, label: t('contextMenu.close') }]}
      />
      <Spin
        tip={getSpinnerMessage(status)}
        spinning={spinning}
        size="large"
        wrapperClassName="session-running"
      ></Spin>
    </section>
  ) : (
    <section className={candlestickClass}>
      <div className={classNames(getGridItemClass(width, height), { 'full-screen': isFullscreen })}>
        <div className="frame-header">
          <div className="frame-header__top-row">
            {renderTitle()}
            <ContextMenu
              isFullscreen={isFullscreen}
              onMenuItemClick={onMenuItemClick}
              items={[
                { key: GridItemActions.Edit, label: t('operativeSession.editFrame') },
                {
                  key: GridItemActions.FullScreen,
                  label: t('contextMenu.fullscreen'),
                  icon: isFullscreen ? <CheckOutlined /> : null,
                },
                !isFullscreen && { key: GridItemActions.Close, label: t('contextMenu.close') },
              ]}
            />
          </div>
        </div>

        <div className="frame-content">
          <PanelContentViewBySession session={session} />
        </div>
      </div>
    </section>
  )

  if (isFullscreen) {
    return (
      <Modal
        open={true}
        centered
        width={'100%'}
        wrapClassName={'candlestick-fullscreen nonDraggable'}
        footer={null}
        closable={false}
      >
        {panelContent}
      </Modal>
    )
  }

  return panelContent
}

export default StrategyCandleStickFrame
