export const STATE_KEY = 'sessions'

export const FETCH_SESSIONS = `${STATE_KEY}/FETCH_SESSIONS`
export const FETCH_SESSIONS_SUCCESS = `${STATE_KEY}/FETCH_SESSIONS_SUCCESS`
export const SET_STATUS = `${STATE_KEY}/SET_STATUS`
export const SET_ERROR = `${STATE_KEY}/SET_ERROR`
export const SESSION_DELETE = `${STATE_KEY}/SESSION_DELETE`
export const SESSION_DELETE_SUCCESS = `${STATE_KEY}/SESSION_DELETE_SUCCESS`
export const SESSION_CLEAN_UP = `${STATE_KEY}/SESSION_CLEAN_UP`
export const UPDATE_SESSION_STATUS = `${STATE_KEY}/UPDATE_SESSION_STATUS`
