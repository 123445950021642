import { createContext } from 'react'
import * as Store from 'types/store'
import * as UI from 'types/ui'
import { TimeFrame } from 'types/enums'

export interface CandleStickFrameContextInterface {
  id: string
  squares: {
    x: number
    y: number
  }
  isFullscreen: boolean
  trace: boolean
  timeFrame: TimeFrame
  viewType: UI.ViewType
  resource: Store.Resource
  isOffline: () => boolean
  setFullScreen: (isFullscreen: boolean) => void
  setTimeFrame: (timeFrame: TimeFrame) => void
  setViewType: (viewType: UI.ViewType) => void
  setTrace: (trace: boolean) => void
  setOffline: (goOffline: boolean) => void
}

export const CandleStickFrameContext = createContext<CandleStickFrameContextInterface>({
  id: null,
  squares: {
    x: 0,
    y: 0,
  },
  isFullscreen: false,
  trace: false,
  timeFrame: TimeFrame.TDY,
  viewType: UI.ViewType.Details,
  resource: null,
  isOffline: null,
  setFullScreen: null,
  setTimeFrame: null,
  setViewType: null,
  setTrace: null,
  setOffline: null,
})
