import React from 'react'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import Dashboard from 'v2/components/Dashboard'

const OperativeSessionContainer: React.FC = () => {
  const session = useSelector(selectOperativeSessionSession)

  if (!session) {
    return null
  }

  return <Dashboard />
}

export default OperativeSessionContainer
