import React from 'react'
import ReadStrategy from 'components/StrategyViewer/ReadStrategy'
import * as Api from 'types/api'
import { useDispatch, useSelector } from 'react-redux'
import { selectStrategies, selectStrategiesState } from 'store/resources/selectors'
import { editStrategyAction } from 'store/resources/strategies/actions'
import { CrudOperation } from 'types/enums'
import * as Store from 'types/store'
import { setUserAction } from 'store/pages/strategy/actions'

interface Props {
  userAction: Store.UserAction
}

const ResourceStrategyContainer: React.FC<Props> = ({ userAction }) => {
  const loadingState = useSelector(selectStrategiesState)
  const strategies = useSelector(selectStrategies)
  const dispatch = useDispatch()

  const { node, operation } = userAction

  const strategy = node.resource as Store.Strategy

  const onFinish = (data: Api.StrategyRest) => {
    const postData: Api.StrategyRest = {
      ...data,
      baseCurrency: 'EUR',
      id: strategy.id,
    }

    dispatch(editStrategyAction(postData))
  }

  const onCancel = () => {
    dispatch(setUserAction(userAction.node, CrudOperation.Read))
  }

  const strategyInStore = strategies.find((item) => item.id === strategy.id)
  if (!strategyInStore) {
    return null
  }

  return (
    <ReadStrategy
      strategy={strategyInStore}
      onFinish={onFinish}
      loadingState={loadingState}
      operation={operation}
      onCancel={onCancel}
    />
  )
}

export default ResourceStrategyContainer
