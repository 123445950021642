import React from 'react'
import { ChartChild } from 'types/chart'

interface Props {
  color: string
}

const ChartBackground: React.FC<Props & ChartChild> = ({ chartWidth: width, chartHeight: height, color }) => {
  return <rect width={width} height={height} fill={color} style={{ pointerEvents: 'all' }} />
}

export default ChartBackground
