import Users from 'components/Admin/Users'
import Breadcrumb from 'components/common/Breadcrumb'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { deleteUserAction, editUserAction, loadUsersAction, newUserAction } from 'store/pages/admin/actions'
import { CrudOperation, LoadingState } from 'types/enums'
import AppContentCard from 'components/AppContentCard'
import { Typography } from 'antd'
import { DeleteItem } from 'components/common/modals'
import { selectSessions } from 'store/sessions/selectors'
import * as Store from 'types/store'
import UserForm from 'components/Admin/UserForm'
import { selectUsers, selectLoadingState } from 'store/pages/admin/selectors'
import { AdminUserAction } from 'components/Admin/Users/Users'
const { Title } = Typography

const UsersContainer: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [operation, setOperation] = useState(CrudOperation.List)
  const [userId, setUserID] = useState<number>(null)
  const users = useSelector(selectUsers)
  const sessions = useSelector(selectSessions)
  const loadingState = useSelector(selectLoadingState)

  useEffect(() => {
    dispatch(loadUsersAction())
  }, [])

  useEffect(() => {
    if (loadingState === LoadingState.Created || loadingState === LoadingState.Updated) {
      setOperation(CrudOperation.List)
    }
  }, [loadingState])

  const onUserClick = (action: AdminUserAction) => {
    setUserID(action.id)
    setOperation(action.operation)
  }

  const onCancel = () => {
    setOperation(CrudOperation.List)
  }

  const getUser = () => users.find((user) => user.id === userId)

  if (loadingState === LoadingState.LoadFailure) {
    return (
      <AppContentCard full clipped>
        <Title className="select-item-title">{t('error.fetch')}</Title>
      </AppContentCard>
    )
  }

  const onUserUpdate = (userData: Store.SystemUserForm) => {
    const { id, blocked } = getUser()
    dispatch(editUserAction({ ...userData, id, blocked }))
  }

  const onUserCreate = (user: Store.SystemUserForm) => {
    dispatch(newUserAction({ ...user, confirmation: user.password, blocked: false }))
  }

  const render = () => {
    switch (operation) {
      case CrudOperation.List:
        return (
          <Users
            users={users}
            loading={loadingState === LoadingState.Loading}
            onClick={onUserClick}
            sessions={sessions}
          />
        )

      case CrudOperation.Delete:
        return (
          <DeleteItem
            itemsName={getUser()?.name}
            loadingState={loadingState}
            onClose={() => setOperation(CrudOperation.List)}
            onOk={() => {
              dispatch(deleteUserAction(userId))
              setOperation(CrudOperation.List)
            }}
            visible={operation === CrudOperation.Delete}
          />
        )
      case CrudOperation.Update:
        return (
          <UserForm
            user={getUser()}
            loading={loadingState === LoadingState.Updating}
            onSubmit={onUserUpdate}
            onCancel={onCancel}
            isNewUser={false}
          />
        )
      case CrudOperation.Create:
        return (
          <UserForm
            user={null}
            loading={loadingState === LoadingState.Creating}
            onSubmit={onUserCreate}
            onCancel={onCancel}
            isNewUser={true}
          />
        )
      default:
        return null
    }
  }

  const getSecondSlice = () => {
    switch (operation) {
      case CrudOperation.Create:
        return t('admin.addNewUser')
      case CrudOperation.Update:
        return getUser().name
      default:
        return null
    }
  }

  const getSlices = () => {
    const secondSlice = getSecondSlice()
    const slices = [t('admin.users'), secondSlice]
    return slices.filter((value) => value)
  }

  return (
    <>
      <Breadcrumb slices={getSlices()} className="mb-10 ml-10" />
      {render()}
    </>
  )
}

export default UsersContainer
