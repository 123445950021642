import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import { ChartChild } from 'types/chart'

const TICK_HEIGHT = 35
interface Props {
  xData: Date[] | number[]
  yData: number[]
}

const ChartGrid: React.FC<Props & ChartChild> = ({
  chartWidth: width,
  chartHeight: height,
  d3Transform: transform,
  xData,
  yData,
  yDomain,
  xDomain,
  transitionTime,
}) => {
  const gridX = useRef(null)
  const gridY = useRef(null)

  const getYDomain = () => {
    if (yDomain) {
      return yDomain
    }

    return [0, d3.max(yData)]
  }

  const getXDomain = () => {
    if (xDomain) {
      return xDomain
    }

    return [d3.min(xData as number[]), d3.max(xData as number[])]
  }

  useEffect(() => {
    const xScale = transform.rescaleX(d3.scaleTime().domain(getXDomain()).range([0, width]))

    const yScale = d3.scaleLinear().range([height, 0]).domain(getYDomain())

    const xAxisGrid = d3
      .axisBottom(xScale)
      .tickSize(-height)
      .tickFormat(() => '')

    if (!xData.length) {
      xAxisGrid.ticks(0)
    }

    const yAxisGrid = d3
      .axisLeft(yScale)
      .tickSize(-width)
      .tickFormat(() => '')

    yAxisGrid.ticks(Math.round(height / TICK_HEIGHT))

    const gridXAxis = d3.select(gridX.current)
    const gridYAxis = d3.select(gridY.current)

    gridXAxis.transition().duration(transitionTime).ease(d3.easeExpOut).call(xAxisGrid)
    gridYAxis.transition().duration(transitionTime).ease(d3.easeExpOut).call(yAxisGrid)
  })

  return (
    <>
      <g className="axis-grid" transform={`translate(0, ${height})`} ref={gridX} />
      <g className="axis-grid" ref={gridY} />
    </>
  )
}

export default ChartGrid
