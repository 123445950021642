import * as Api from 'types/api'
import * as Store from 'types/store'

import Debug from 'debug'
import { parseNumber } from 'core/helpers'
import colors from 'config/colors'
import { convertTime } from './mapTimeSeries'
import { LongShort } from 'types/enums'
const debug = Debug('Frontend')

export function jsonToStrategies(json: Array<Api.HistoricalStrategy>): Array<Store.Strategy> {
  return json.map((strategy) => ({
    id: strategy.strategy_id,
    baseCurrency: strategy.base_currency,
    // creationUserid: strategy.creation_userid,
    // lastModUserid: strategy.last_mod_userid,
    creationUserid: null,
    lastModUserid: null,
    ownerId: null,
    target: strategy.target,
    name: strategy.name,
    // lastModDate: new Date(strategy.last_mod_date),
    // creationDate: new Date(strategy.creation_date),
    lastModDate: null,
    creationDate: null,
  }))
}

export function jsonToPortfolios(json: Array<Api.HistoricalPortfolio>): Array<Store.Portfolio> {
  return json.map((portfolio) => ({
    id: portfolio.portfolio_id,
    strategyId: portfolio.strategy_id,
    name: portfolio.name,
    // creationDate: new Date(portfolio.creation_date),
    // lastModDate: new Date(portfolio.last_mod_date),
    creationDate: null,
    creationUserId: null,
    lastModDate: null,
    maxLong: parseNumber(portfolio.max_long),
    maxNetHedging: parseNumber(portfolio.max_net_hedging),
    minNetHedging: parseNumber(portfolio.min_net_hedging),
    maxShort: parseNumber(portfolio.max_short),
  }))
}

export function jsonToGroupedHistoricalAssets(json: Api.HistoricalGroup[]) {
  let mappedGroups = []
  let mappedAssets = []

  json.map(group => {
    const groupAssets = group.asset_group.map(obj => obj.asset)
    const groupWithMappedAssets = {
      ...group,
      assets: jsonToHistoricalAssets(groupAssets)
    }

    mappedGroups = [...mappedGroups, groupWithMappedAssets]

    mappedAssets = mappedAssets.concat(groupAssets)
  })

  return {
    groups: jsonToHistoricalGroups(mappedGroups),
    assets: jsonToHistoricalAssets(mappedAssets),
  }
}

export function jsonToHistoricalGroups(json: Array<Api.HistoricalGroup>): Array<Store.Group> {
  return json.map(group => {
    try {
      return {
        id: group.group_id,
        name: group.name,
        modelId: group?.analysis_model?.model_class_id ?? null,
        modelClass: {
          id: group?.analysis_model?.model_class_id ?? null,
          modelName: group?.analysis_model?.model_name ?? null,
          modelType: group?.analysis_model?.model_type ?? null,
          modelDescription: group?.analysis_model?.model_description ?? null,
          version: group?.analysis_model?.version_number ?? null,
        },
        executionModelId: group?.execution_model_id ?? null,
        portfolioId: group.portfolio_id,
        userId: null,
        assets: group.asset_group.map(obj => {
          return {
            id: obj.asset.asset_id,
            name: obj.asset.name
          }
        }) ?? null,
        assetIds: group.asset_group.map(obj => obj.asset.asset_id) ?? null
      }
    } catch (err) {
      console.log('error mapping historical groups', err)
      return null
    }

  })
}

export function jsonToHistoricalAssets(json: Array<Api.HistoricalAsset>): Array<Store.Asset> {
  return json
    .map((asset) => {
      try {
        return {
          bankAccount: asset.bank_account,
          lastModUserid: asset.last_mod_userid,
          longShort: asset.e_long_short.code as LongShort,
          longShortText: asset.e_long_short.description,
          name: asset.name,
          id: asset.asset_id,
          // associationUserId: asset.association_userid,
          securityId: asset.security_id,
          portfolioId: asset.portfolio_id,
          qtyFCountervalue: asset.qty_f_countervalue === 'true',
          sizeVariable: asset.size_variable === 'true',
          modelClassId: asset.model_class_id,
          lastModDate: new Date(asset.last_mod_date),
          minOrderQty: parseNumber(asset.min_order_qty),
          minOrderCountervalue: parseNumber(asset.min_order_countervalue),
          mic: asset.mic,
          status: asset.status,
          brokerAccounts: asset.asset_broker_accounts.map((broker) => ({
            name: broker.broker.broker_name,
            account: broker.broker_account,
            priority: broker.rank_broker_account,
          })),
          // brokerName: asset?.broker_name,
          refCounterValue: asset.ref_countervalue,
          refCounterValueMax: asset.ref_countervalue_max,
          refCounterValueMin: asset.ref_countervalue_min,
          refQuantity: asset.ref_qty,
          refQuantityMax: asset.ref_qty_max,
          refQuantityMin: asset.ref_qty_min,
          symbol: asset.symbol,
          market: asset.custom_code,
          currency: asset.trading_currency,
          baseCurrency: asset.portfolio.strategy.base_currency,
          group: {
            id: asset?.group_id ?? null,
            name: asset?.group_name ?? null,
          },
          instrumentCode: asset.instrument_code,
          description: {
            contractSize: asset.contract_size,
            descriptionField: asset.description,
            instrumentClass: asset.instrument_class,
            lotMultiplier: asset.lot_multiplier,
          },
          modelClass: {
            name: asset.model_name,
            description: asset.model_description,
            language: asset.model_language,
            type: asset.model_type,
            version: asset.version_number,
          },
        }
      } catch (error) {
        console.log(`error mapping asset ${asset.asset_id}-${asset.name}`, error)
        return null
      }
    })
    .filter((el) => el !== null)
}

export const mapHistoricalAssetSecurityTrade = (
  json: Api.HistoricalAssetCartesianGraphIndicators,
): Store.SecurityTrade => {
  return {
    price: json?.price,
    size: json?.size,
    time: new Date(json?.trade_ts),
  }
}

export const mapHistoricalAssetSecurityBidAsk = (
  json: Api.HistoricalAssetCartesianGraphIndicators,
): Store.SecurityBidAsk => {
  return {
    askPrice: json?.ask_price,
    askTotalSize: json?.ask_total_size,
    bidPrice: json?.bid_price,
    bidTotalSize: json?.bid_total_size,
  }
}

export const mapHistoricalOpenOperation = (json: Api.HistoricalOpenOperation): Store.Operation => {
  return {
    avgClosePrice: json?.avg_close_price,
    avgOpenPrice: json?.avg_open_price,
    endTime: new Date(json?.end_ts),
    maxPrice: json?.max_price,
    minPrice: json?.min_price,
    sign: json?.sign,
    size: json?.size,
    startTime: new Date(json?.start_ts),
    tdyPercReturn: json?.tdy_perc_ret,
    tdyTradingReturn: json?.tdy_trading_ret,
    tdyTradingReturnBc: json?.tdy_trading_ret_bc,
    totalPercReturn: json?.tot_perc_ret,
    totalTradingReturn: json?.tot_trading_ret,
    totalTradingReturnBc: json?.tot_trading_ret_bc,
    ytdPercReturn: json?.ytd_perc_ret,
    ytdTradingReturn: json?.ytd_trading_ret,
    ytdTradingReturnBc: json?.ytd_trading_ret_bc,
  }
}

export const mapHistoricalJITrade = (json: Api.HistoricalAssetCartesianGraphIndicators): Store.JITrade => {
  return {
    price: json?.jt_price,
    quantity: json?.jt_quantity,
    sign: json?.jt_sign,
    time: new Date(json?.jt_time),
  }
}

export const mapHistoricalAssetCartesianLastReturn = (
  json: Api.AssetCartesianLastReturn[],
): Store.AssetCartesianLastReturn[] => {
  return json.map(({ trading_ret, trading_ret_bc, type }) => {
    return {
      tradingRet: trading_ret,
      tradingRetBc: trading_ret_bc,
      type,
    }
  })
}

export const mapHistoricalAssetCartesianOperations = (
  json: Api.AssetCartesianOperations[],
): Store.AssetCartesianOperationsList[] => {
  return json.map(({ negative_op, positive_op, type }) => {
    return {
      negativeOp: negative_op,
      positiveOp: positive_op,
      total: null, //TBD
      type,
    }
  })
}

export const mapHistoricalAssetRefData = (json: Api.HistoricalAssetRefData): Store.AssetRefData => {
  return {
    assetName: json?.asset_name,
    baseCurrency: json?.base_currency,
    portfolioName: json?.portfolio_name,
    strategyName: json?.strategy_name,
    strategyTarget: json?.strategy_target,
  }
}

export const mapHistoricalAssetSecurityRefData = (
  json: Api.HistoricalAssetSecurityRefData,
): Store.SecurityRefData => {
  return {
    description: json?.description,
    instrumentClass: json?.instrument_class,
    instrumentCode: json?.instrument_code,
    instrumentIsin: json?.instrument_isin,
    key: json?.asset_id,
    marketCode: json?.market_code,
    symbol: json?.symbol,
    tradingCurrency: json?.trading_currency,
  }
}

export const mapHistoricalOpenReturn = (json: Api.HistoricalOpenReturn): Store.UnitPerformance => {
  return {
    close: json?.unit_ret_close,
    open: json?.unit_ret_open,
    date: new Date(json?.start_ts),
    endTime: new Date(json?.end_ts),
    startTime: new Date(json?.start_ts),
  }
}

export const mapHistoricalAssetOpenCandle = (json: Api.HistoricalAssetOpenCandle): Store.CandleStick => {
  return {
    close: json?.close,
    open: json?.open,
    high: json?.high,
    low: json?.low,
    color:
      json?.open === json?.close
        ? colors.silver
        : json?.open > json?.close
        ? colors.candleBlack
        : colors.candleWhite,
    date: new Date(json?.start_ts),
    endTime: new Date(json?.end_ts),
    startTime: new Date(json?.start_ts),
  }
}

export const mapHistoricalOpenCandle = (
  json: Api.HistoricalOpenCandle[],
  last: Store.CandleStick,
): Store.CandleStick[] => {
  return json.map((candle) => {
    const [min, max] = !last
      ? [candle.max_tdy_trading_ret_bc, candle.min_tdy_trading_ret_bc]
      : [last.low, last.close]
    return {
      close: candle.min_tdy_trading_ret_bc,
      high: candle.max_tdy_trading_ret_bc,
      low: candle.min_tdy_trading_ret_bc,
      open: candle.max_tdy_trading_ret_bc,
      date: convertTime(candle.start_ts),
      startTime: convertTime(candle.start_ts),
      endTime: convertTime(candle.end_ts),
      color:
        candle.max_tdy_trading_ret_bc === max && candle.min_tdy_trading_ret_bc === min
          ? colors.silver
          : candle.min_tdy_trading_ret_bc < min
          ? colors.red
          : colors.positiveGreen,
    }
  })
}

export const mapHistoricalStrategySecurityRefData = (
  json: Api.HistoricalGraphIndicators,
): Store.StrategyRefData => {
  return {
    strategyName: json.strategy_name,
    strategyTarget: json.strategy_target,
  }
}

export const mapHistoricalStrategyCartesianHeadingData = (
  json: Api.HistoricalGraphIndicators,
): Store.StrategyCartesianHeading => {
  return {
    activatedModelsNum: json.active_models_num,
    assetsNum: json.assets_num,
    baseCurrency: json.base_currency,
    portfoliosNum: json.portfolios_num,
  }
}
export const mapHistoricalOperations = (
  json: Api.HistoricalCartesianOperation[],
): Store.CartesianOperations[] => {
  return json.map((operation) => {
    return {
      closeNegativeOp: operation.close_negative_op,
      closeOp: operation.close_op,
      closePositiveOp: operation.close_positive_op,
      negativeOp: operation.negative_op,
      openNegativeOp: operation.open_negative_op,
      openOp: operation.open_op,
      openPositiveOp: operation.open_positive_op,
      positiveOp: operation.positive_op,
      type: operation.type,
      total: null, //TBD
    }
  })
}

export const mapHistoricalCartesianPosition = (
  json: Api.PortfoliCartesianGraphIndicatorsRefData | Api.HistoricalGraphIndicators,
): Store.CartesianPosition => {
  return {
    netHedging: json.net_hedging,
    value: json.countervalue_bc,
    valueLong: json.countervalue_bc_long,
    valueShort: json.countervalue_bc_short,
  }
}

export const mapHistoricalCartesianReturn = (
  json: Api.HistoricalCartesianReturn[],
): Store.CartesianReturn[] => {
  return json.map((item) => {
    return {
      lastEvtTs: convertTime(item.last_evt_ts),
      tradingRetBc: item.trading_ret_bc,
      type: item.type,
    }
  })
}

export const mapHistoricaPortfolioSecurityRefData = (
  json: Api.PortfoliCartesianGraphIndicatorsRefData,
): Store.PortfolioRefData => {
  return {
    strategyName: json.strategy_name,
    portfolioName: json.portfolio_name,
  }
}

export const mapHistoricalPortfolioCartesianHeadingData = (
  json: Api.PortfolioHistoricalHeading,
): Store.PortfolioCartesianHeading => {
  return {
    activatedModelsNum: json.active_models_num,
    assetsNum: json.assets_num,
    baseCurrency: json.base_currency,
  }
}
