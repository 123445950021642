import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import { ChartChild } from 'types/chart'
import { HistogramType } from 'types/chartEnums'

import * as Store from 'types/store'
import colors from 'config/colors'

interface Props {
  binWidth?: number
  data: Store.Barchart[]
  type: HistogramType
}

const BarChart: React.FC<Props & ChartChild> = ({
  data,
  chartWidth: width,
  chartHeight: height,
  yDomain,
  xDomain,
}) => {
  const element = useRef(null)
  const xValues = data.map((bar) => [bar.x0, bar.x1]).flat()
  const yValues = data.map((bar) => bar.height)

  const getYDomain = () => {
    if (yDomain) {
      return yDomain
    }

    return [0, d3.max(yValues)]
  }

  const getXDomain = () => {
    if (xDomain) {
      return xDomain
    }

    return d3.extent(xValues)
  }

  const xScale = d3.scaleLinear().domain(getXDomain()).range([0, width])

  const yScale = d3.scaleLinear().domain(getYDomain()).range([height, 0])

  const setAttributes = (selection: d3.Selection<any, Store.Barchart, null, unknown>) => {
    return selection
      .attr('class', 'bins')
      .attr('fill', colors.lightgreen)
      .attr('x', (d) => xScale(d.x0) + 1)
      .attr('width', (d) => xScale(d.x1) - xScale(d.x0))
      .attr('y', (d) => yScale(d.height))
      .attr('height', (d) => yScale(0) - yScale(d.height))
  }

  const enter = (selection: d3.Selection<d3.EnterElement, Store.Barchart, any, unknown>) => {
    return setAttributes(selection.append('rect'))
  }

  const update = (selection: d3.Selection<d3.BaseType, Store.Barchart, any, unknown>) => {
    return setAttributes(selection)
  }

  const exit = (selection: d3.Selection<d3.BaseType, Store.Barchart, any, unknown>) => {
    return selection.exit().remove()
  }

  useEffect(() => {
    const svg = d3.select(element.current)

    svg.selectAll('.bins').data(data).join(enter, update, exit)
  }, [data, width, height])

  return (
    <>
      <g ref={element} />
    </>
  )
}

export default BarChart
