import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Typography } from 'antd'

const { Text } = Typography

interface Props {
  onClick: () => void
}

const SaveLayout: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <>
      <Button className="dashboard-actions-btn dashboard-actions-btn--save-layout" onClick={() => onClick()}>
        <span className="action-icon action-icon--active"></span>
        <Text>{t('layout.save')}</Text>
      </Button>
    </>
  )
}

export default SaveLayout
