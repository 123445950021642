import React, { useCallback, useContext, useState } from 'react'
import * as Store from 'types/store'

import { useSelector } from 'react-redux'
import { PortfolioFeed } from 'types/enums.api'
import CandleStick from 'v2/components/CandleStick'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import { filterEmptyData, getDateLimits } from 'core/helpers'
import Spinner from 'components/Spinner'
import CandlestickWrapper from 'v2/components/CandlestickWrapper'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
import { TimeFrame, TSInterval } from 'types/enums'
import { isApiError } from 'core'
import * as historicalApi from 'api/historicalResources'
import * as api from 'api/candleStick'
import * as Api from 'types/api'

import Debug from 'debug'
import { jsonToPortfolioCandleStick } from 'services/store/mapTimeSeries'
import { mapHistoricalOpenCandle } from 'services/store/mapHistoricals'
import { getExtendedDates, getInterval } from 'services/chartsService'
import sigmaServices from 'config/sigmaServices'

const { Return } = sigmaServices.Portfolios

const debug = Debug('sockets')

const getTimeSeries = async (id: number, from: Date, to: Date, sessionId: number, interval: TSInterval) => {
  const response = await api.getDataPoints(
    id,
    Return.PrtClosedCandle,
    PortfolioFeed.Return,
    interval,
    from,
    to,
    sessionId,
  )

  if (isApiError(response)) {
    debug('ERROR getDataPoints')
    return []
  }

  const data = response as Api.DataPointFeed

  const mappedData = jsonToPortfolioCandleStick(data.datapoints)

  return mappedData
}

const getOpenChartData = async (
  id: number,
  sessionId: number,
  interval: TSInterval,
  last: Store.CandleStick,
): Promise<Store.CandleStick[]> => {
  const response = await historicalApi.getPortfolioOpenChartData(id, sessionId, interval)

  if (isApiError(response)) {
    debug('ERROR Portfolio HistoricalgetDataPoints')
    return null
  }

  const data = response as Api.HistoricalPortfolioOpenChartDataResponse
  const openCandleStick = mapHistoricalOpenCandle(data.data.historical_data_prt_open_candle_v, last)

  return openCandleStick
}

interface Props {
  portfolio: Store.Portfolio
  width: number
  height: number
}

const PortfolioCandleStickContainer: React.FC<Props> = ({ portfolio, width, height }) => {
  const context = useContext(CandleStickFrameContext)
  const { timeFrame } = context
  const [candleStickData, setCandleStickData] = useState<Store.CandleStick[]>([])

  const session = useSelector(selectOperativeSessionSession)
  const sessionId = session.id
  const [openData, setOpenData] = useState<Store.CandleStick[]>([])

  const { id } = portfolio

  const getData = useCallback(
    async (id: number, from: Date, to: Date, sessionId: number, interval: TSInterval) => {
      const data = await getTimeSeries(id, from, to, sessionId, interval)
      const last = data.length > 0 ? data[data.length - 1] : null

      const returnData = data.length > 0 ? data : ([{ empty: true }] as Store.ChartData[])

      setCandleStickData(returnData as Store.CandleStick[])
      const openData = await getOpenChartData(id, sessionId, interval, last)

      if (openData) {
        setOpenData(openData)
      }
    },
    [],
  )

  const getFromTo = async (from: Date, to: Date) => {
    const [startTime, endTime] = getDateLimits(timeFrame, session)

    if (timeFrame === TimeFrame.TOT) {
      const interval = TSInterval.Day
      getData(id, startTime, endTime, sessionId, interval)
    } else {
      const interval = timeFrame === TimeFrame.TDY ? getInterval(from, to, null) : TSInterval.Day
      const [startFrom, endTo] = getExtendedDates(from, to, startTime, endTime)
      getData(id, startFrom, endTo, sessionId, interval)
    }
  }

  const noData = () => {
    if ((candleStickData && candleStickData.length > 0) || (openData && openData.length > 0)) {
      return false
    }
    return true
  }

  return (
    <CandlestickWrapper>
      {noData() && <Spinner />}
      <CandleStick
        loading={noData()}
        timeFrame={timeFrame}
        session={session}
        candleStickData={filterEmptyData([...candleStickData, ...openData]) as Store.CandleStick[]}
        lineChartData={[]}
        operationsChartData={[]}
        currency={null}
        getFromTo={getFromTo}
        getXDomain={() => getDateLimits(timeFrame, session)}
        width={width}
        height={height}
      />
    </CandlestickWrapper>
  )
}

export default PortfolioCandleStickContainer
