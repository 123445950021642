import React from 'react'
import * as Store from 'types/store'

import { Typography } from 'antd'

import SessionAnalysisTable from './SessionAnalysisTable'
import { useTranslation } from 'react-i18next'
import { withVisible } from 'components/hoc'
import ConsumptionChart from '../ConsumptionChart'

interface Props {
  metrics: Store.SessionMetrics
  setTimeInterval: (interval: [Date, Date]) => void
}

const SessionAnalysis: React.FC<Props> = ({ metrics, setTimeInterval }) => {
  const { t } = useTranslation()

  const data = metrics?.aggregateMetrics || []
  const ramData: [Date, number][] = data.map(({ timestamp, resource: { ram } }) => [
    new Date(timestamp),
    ram || 0,
  ])

  const cpuData: [Date, number][] = data.map(({ timestamp, resource: { cpu } }) => [
    new Date(timestamp),
    cpu || 0,
  ])

  // console.log('dcata', cpuData)
  return (
    <section className="table-container">
      <Typography.Title level={2}>{t('operativeSession.sessionStatus')}</Typography.Title>
      <SessionAnalysisTable metrics={metrics} />
      <div className="chart-container">
        <ConsumptionChart cpuData={cpuData} ramData={ramData} setTimeInterval={setTimeInterval} />
      </div>
    </section>
  )
}

export default withVisible(SessionAnalysis)
