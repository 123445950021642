import * as K from './constants'
import { put, call, takeLatest, all } from 'redux-saga/effects'
import Debug from 'debug'
import resolutionsData from 'data/resolutions.json'
import * as resolutionsLocalStorage from 'core/resolutions'
import { AnyAction } from 'redux'
import * as UI from 'types/ui'

const debug = new Debug('Frontend')

export function* loadResolutions(action: AnyAction) {
  try {
    const reset = action.payload as boolean
    let resolutions = resolutionsData
    if (reset) {
      yield call(resolutionsLocalStorage.setResolutions, resolutionsData)
    } else {
      const resolutionsLocal = yield call(resolutionsLocalStorage.getResolutions)

      if (resolutionsLocal) {
        resolutions = resolutionsLocal
      } else {
        yield call(resolutionsLocalStorage.setResolutions, resolutionsData)
      }
    }
    yield put({ type: K.SAVE_RESOLUTIONS_SUCCESS, payload: resolutions })
  } catch (error) {
    debug('Error setResolutions')
  }
}

export function* saveResolutions(action: AnyAction) {
  try {
    const data = action.payload as UI.Resolution[]
    yield call(resolutionsLocalStorage.setResolutions, data)

    yield put({ type: K.SAVE_RESOLUTIONS_SUCCESS, payload: data })
  } catch (error) {
    debug('Error setResolutions')
  }
}

function* watch() {
  yield all([
    takeLatest(K.LOAD_RESOLUTIONS, loadResolutions),
    takeLatest(K.SAVE_RESOLUTIONS, saveResolutions),
  ])
}

export default watch
