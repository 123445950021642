import './wdyr'

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './containers/App'
import { Provider } from 'react-redux'
import configureStore from 'store'
import Debug from 'debug'

import reportWebVitals from './reportWebVitals'

import '../node_modules/react-grid-layout/css/styles.css'
import '../node_modules/react-resizable/css/styles.css'
import './index.less'
import 'scss/index.scss'
import { FlagsProvider } from 'flagged'
import ThemeProvider from 'providers/ThemeProvider'

const debug = Debug('Application')

import { config, featureFlags, initLanguage } from 'core'
import { ConfigProvider } from 'antd'
import { isAlive } from 'api/ping'
import ServerNotAvailablePage from 'pages/ServerNotAvailablePage'
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'
import { ThemeType } from 'themes/types'

import * as Sentry from '@sentry/react'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://4b78338d20077ea818abd83c153c40c3@o4506100529299456.ingest.sentry.io/4506100536836096',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          // 'localhost',
          // /^https:\/\/test-aws.ji.sparklingrocks\.it\/jagged-island/,
          // /^https:\/\/atsapp178.atscom\.it\/jagged-island/,
          /^https:\/\/functest.ji.sparklingrocks\.it\/jagged-island/,
          // /^https:\/\/uat01.ji.sparklingrocks\.it\/jagged-island/,
          /^https:\/\/trial01.ji.sparklingrocks\.it\/jagged-island/,
        ],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
      new Sentry.BrowserProfilingIntegration()
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const store = configureStore()

isAlive()
  .then(() => initLanguage(process.env.REACT_APP_LABELS_SOURCE))
  .then(() => {
    //after language init, set language in config
    //config.setLanguage(i18n.language)

    debug('*** CONFIG ***', config)

    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <ThemeProvider initialTheme={ThemeType.Sandybrown}>
            <Suspense fallback=""> {/* TODO: this is placed here only bcs of a very bad handling of component lifecycle, needs heavy refactor */}
              <BrowserRouter>
                <FlagsProvider features={featureFlags}>
                  <ConfigProvider renderEmpty={() => <></>}>
                    <App />
                  </ConfigProvider>
                </FlagsProvider>
              </BrowserRouter>
            </Suspense>
          </ThemeProvider>
        </Provider>
      </React.StrictMode>,
      document.getElementById('root'),
    )

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals()
  })
  .catch(() => {
    //API down, add a static error page
    ReactDOM.render(<ServerNotAvailablePage />, document.getElementById('root'))
  })
