import React from 'react'
import { Col, Form, Row, Select, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'
const { Paragraph } = Typography

const { Option } = Select

interface Props {
  data: Store.TypeOfPositionEnabled[]
}

const LongShortField: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation()

  return (
    <Row align="middle" gutter={[5, 0]}>
      <Col span={12}>
        <Paragraph>{t('assets.longShortField')}</Paragraph>
      </Col>
      <Col span={12}>
        <Form.Item name="longShort" rules={[{ required: true, message: t('error.requiredField') }]}>
          <Select getPopupContainer={(trigger) => trigger.parentElement}>
            {data.map((item) => (
              <Option value={item.code} key={item.code}>
                {item.description}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={24}>
        <Typography.Text className="field-description">
          {t('assets.description.longShortField')}
        </Typography.Text>
      </Col>
    </Row>
  )
}

export default LongShortField
