import React, { useMemo, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { selectOperativeSessionAssets, selectOperativeSessionSession } from 'store/pages/selectors'
import { ExtendedDataNode, GridItemActions } from 'types/ui'
import MapView from 'v2/components/frames/MapView'
import * as Store from 'types/store'
import { removeFrame } from 'store/dashboard/actions'
import { selectResources } from 'store/resources/selectors'
import { selectDashboardEntityFilter } from 'store/dashboard/selectors'
import { filteredResourceTree, getFilteredTree, isFilteredLayout } from 'services/dashboardService'
import ChangeAssetStatus from 'containers/ChangeAssetStatus/ChangeAssetStatusModal'
import { containsSomeId } from 'core/helpers'

interface Props {
  frame: Store.Frame
}

const MapViewContainer: React.FC<Props> = ({ frame }) => {
  const dispatch = useDispatch()
  const session = useSelector(selectOperativeSessionSession)
  const { portfolios, strategies, groups, assets } = useSelector(selectResources)
  const sessionAssets = useSelector(selectOperativeSessionAssets)
  const entityFilter = useSelector(selectDashboardEntityFilter)
  const [isModalVisible, setModalVisible] = useState(false)

  const userAssets = useMemo(
    () =>
      assets.data.filter((asset) => !asset.associationUserId || asset.associationUserId === session?.ownerId),
    [session, assets],
  )

  const userAssetsIds = userAssets.map(a => a.id)
  
  // const userGroups = useMemo(() => {
  //   return groups.data.filter(group => {
  //     if (containsSomeId(userAssetsIds, group.assetIds)) {
  //       return group
  //     }
  //   })
  // }, [session, groups])

  const userPortfolios = useMemo(
    () =>
      portfolios.data.filter(
        (portfolio) => !portfolio.creationUserId || portfolio.creationUserId === session?.ownerId,
      ),
    [session, portfolios],
  )

  const userStrategies = useMemo(
    () =>
      strategies.data.filter(
        (strategy) => !strategy.creationUserid || strategy.creationUserid === session?.ownerId,
      ),
    [session, strategies],
  )

  const tree: ExtendedDataNode[] = filteredResourceTree(
    isFilteredLayout(entityFilter, session.type),
    userStrategies,
    userPortfolios,
    [], // Empty groups bcs we don't want to show them
    userAssets,
    // session.isHistorical ? assets.data : sessionAssets,
    // Take session assets disregarding the type of session
    sessionAssets,
  )

  const onItemClick = (action: GridItemActions) => {
    switch (action) {
      case GridItemActions.Close:
        dispatch(removeFrame(frame))
        break
      case GridItemActions.Edit:
        setModalVisible(true)
    }
  }

  return (
    <>
      <ChangeAssetStatus visible={isModalVisible} onClose={() => setModalVisible(false)} />
      <MapView tree={tree} onItemClick={onItemClick} session={session} />
    </>
  )
}

export default MapViewContainer
