import { Key } from 'react'
import { ExtendedDataNode, NodeType, TransferType } from 'types/ui'
import { getEmptyData, wrapData } from './core'
import { t } from 'core/i18n'
import { TransferContext } from 'types/enums'
import * as Store from 'types/store'

const addToKeys = (keys: Key[], key: Key): void => {
  if (!keys.includes(key)) {
    keys.push(key)
  }
}

export const removeFromKeys = (keys: Key[], key: Key): void => {
  const index = keys.findIndex((el) => el === key)
  if (index > -1) {
    keys.splice(index, 1)
  }
}

const addItemsToKeys = (
  keys: Key[],
  node: ExtendedDataNode,
  type: NodeType,
  context: TransferContext,
): void => {
  const data = node.children
  if (!data) {
    return
  }

  data.forEach((item) => {
    if (item.type === type) {
      if (item.type === NodeType.Asset && context === TransferContext.AtSessionLaunch) {
        const asset = item.resource as Store.Asset
        if (asset.modelClassId) {
          addToKeys(keys, item.key)
        }
      } else {
        addToKeys(keys, item.key)
      }
    }
    addItemsToKeys(keys, item, type, context)
  })
}

const removeItemsFromKeys = (keys: Key[], node: ExtendedDataNode, type: NodeType): void => {
  const data = node.children
  if (!data) {
    return
  }

  data.forEach((item) => {
    if (item.type === type) {
      removeFromKeys(keys, item.key)
    }
    removeItemsFromKeys(keys, item, type)
  })
}

export const transferItem = (
  node: ExtendedDataNode,
  type: TransferType,
  keys: Key[],
  context: TransferContext,
) => {
  switch (type) {
    case TransferType.Add:
      addToKeys(keys, node.key)
      break
    case TransferType.Remove:
      removeFromKeys(keys, node.key)
      break
    case TransferType.AddAssets:
      addItemsToKeys(keys, node, NodeType.Asset, context)
      break
    case TransferType.AddGroups:
      addItemsToKeys(keys, node, NodeType.Group, context)
      break
    case TransferType.AddPortfolios:
      addItemsToKeys(keys, node, NodeType.Portfolio, context)
      break
    case TransferType.AddStrategies:
      addItemsToKeys(keys, node, NodeType.Strategy, context)
      break
    case TransferType.RemoveAssets:
      removeItemsFromKeys(keys, node, NodeType.Asset)
      break
    case TransferType.RemoveGroups:
      removeItemsFromKeys(keys, node, NodeType.Group)
      break
    case TransferType.RemovePortfolios:
      removeItemsFromKeys(keys, node, NodeType.Portfolio)
      break
    case TransferType.RemoveStrategies:
      removeItemsFromKeys(keys, node, NodeType.Strategy)
      break
    case TransferType.RemoveAll:
      //remove all items without changing the object pointer
      keys.splice(0, keys.length)
      break
    default:
      break
  }
}

export const updateTreeFrom = (data: ExtendedDataNode[], keys: Key[]): ExtendedDataNode[] => {
  if (!data) {
    return
  }

  return data.map((item) => {
    const selected = keys.includes(item.key)
    const copy = { ...item, selected }
    const children = updateTreeFrom(copy.children, keys)
    const res = {...copy, children}
    // console.log('res', res, data)
    return res
  })
}

export const buildTree = (data: ExtendedDataNode[], keys: Key[]): ExtendedDataNode[] => {
  if (!data) {
    return
  }

  const dataOutput: ExtendedDataNode[] = []
  data.forEach((item) => {
    const itemOutput = { ...item }
    itemOutput.children = buildTree(item.children, keys)

    const hasChildren = itemOutput.children && itemOutput.children.length

    if (!keys.includes(item.key) && !hasChildren) {
      return
    }
    dataOutput.push(itemOutput)
  })

  return dataOutput
}

const wrapTo = (data: ExtendedDataNode[]): ExtendedDataNode[] => {
  return wrapData(t('transfer.selectedItems'), data)
}

//Check if empty and add empty item. Also assign correct root
export const normalizeTree = (selectedData: ExtendedDataNode[]): ExtendedDataNode[] => {
  if (!selectedData || selectedData.length === 0) {
    return wrapTo(getEmptyData())
  }

  const rootFrom = selectedData[0]
  if (rootFrom.children) {
    return wrapTo(rootFrom.children)
  } else {
    return wrapTo(getEmptyData())
  }
}

//Ensure the parent of the selected node is expanded
export const expandNodes = (
  data: ExtendedDataNode[],
  keys: Key[],
  node: ExtendedDataNode,
  expandChildren: boolean,
): void => {
  if (!data) {
    return
  }

  data.forEach((item) => {
    expandNodes(item.children, keys, node, expandChildren)

    if (item.key === node.key) {
      keys.push(item.key)
      if (expandChildren) {
        item.children?.forEach((child) => {
          if (!keys.includes(child.key)) {
            keys.push(child.key)
          }
        })
      }
      return
    }

    const hasChild = item.children?.filter((el: ExtendedDataNode) => el.key === node.key).length > 0
    if (hasChild && !keys.includes(node.key)) {
      keys.push(item.key)
      return
    }
  })
}
