import React, { useEffect } from 'react'
import { LoadingState } from 'types/enums'
import { useDispatch, useSelector } from 'react-redux'
import { setLoadingState } from 'store/pages/operative-session/actions'
import { selectOperativeSessionState } from 'store/pages/selectors'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { displayNotification } from 'core/helpers'
import { selectErrorCode, selectLayoutsState } from 'store/layouts/selectors'

const NotificationManager: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const watchAssetStatus = () => {
    const loadingState = useSelector(selectOperativeSessionState)
    useEffect(() => {
      switch (loadingState) {
        case LoadingState.Updated:
          notification.success({
            message: t('notification.type.success'),
            description: t('operativeSession.notification.statusUpdated'),
            duration: 5,
          })
          dispatch(setLoadingState(LoadingState.Loaded))

          break
        case LoadingState.UpdateFailure:
          displayNotification(loadingState, null)
          dispatch(setLoadingState(LoadingState.Loaded))
          break
      }
    }, [dispatch, t, loadingState])
  }

  const watchLayouts = () => {
    const loadingState = useSelector(selectLayoutsState)
    const errorCode = useSelector(selectErrorCode)
    useEffect(() => {
      displayNotification(loadingState, errorCode)
      if (
        loadingState !== LoadingState.Unset &&
        loadingState !== LoadingState.LoadFailure &&
        loadingState !== LoadingState.UpdateFailure
      ) {
        dispatch(setLoadingState(LoadingState.Unset))
      }
    }, [dispatch, t, loadingState])
  }

  watchLayouts()

  watchAssetStatus()

  return null
}

export default NotificationManager
