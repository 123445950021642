import React from 'react'
import { useTranslation } from 'react-i18next'
import { AssetStatus } from 'types/enums'
import AppContentCard from 'components/AppContentCard'
import FreezeIcon from 'assets/icon/assetStatus/freeze.png'
import AutomaticIcon from 'assets/icon/assetStatus/automatic.png'
import StandByFlatIcon from 'assets/icon/assetStatus/standByFlat.png'
import StandByLongIcon from 'assets/icon/assetStatus/standByLong.png'
import StandByShortIcon from 'assets/icon/assetStatus/standByShort.png'

import { Radio, RadioChangeEvent, Typography, Image } from 'antd'
const { Title } = Typography

interface Props {
  onChange?: (e: RadioChangeEvent) => void
}

const StatusButtons: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation()

  return (
    <Radio.Group onChange={onChange} optionType="button" buttonStyle="solid">
      <Radio.Button value={AssetStatus.Freeze}>
        <AppContentCard>
          <Image src={FreezeIcon} preview={false} className="mb-10" width={50} />
          <Title level={3}>{t('assets.status.freeze')}</Title>
        </AppContentCard>
      </Radio.Button>
      <Radio.Button value={AssetStatus.Automatic}>
        <AppContentCard>
          <Image src={AutomaticIcon} preview={false} className="mb-10" width={50} />
          <Title level={3}>{t('assets.status.automatic')}</Title>
        </AppContentCard>
      </Radio.Button>
      <Radio.Button value={AssetStatus.StandByLong}>
        <AppContentCard>
          <Image src={StandByLongIcon} preview={false} className="mb-10" width={50} />
          <Title level={3}>{t('assets.status.standByLong')}</Title>
        </AppContentCard>
      </Radio.Button>
      <Radio.Button value={AssetStatus.StandByShort}>
        <AppContentCard>
          <Image src={StandByShortIcon} preview={false} className="mb-10" width={50} />
          <Title level={3}>{t('assets.status.standByShort')}</Title>
        </AppContentCard>
      </Radio.Button>
      <Radio.Button value={AssetStatus.StandByFlat}>
        <AppContentCard>
          <Image src={StandByFlatIcon} preview={false} className="mb-10" width={50} />
          <Title level={3}>{t('assets.status.standByFlat')}</Title>
        </AppContentCard>
      </Radio.Button>
    </Radio.Group>
  )
}

export default StatusButtons
