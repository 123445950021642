import { update_snapshot, update_ticker } from 'sockets/strategies/strategy_reference_data_pb'
import * as Store from 'types/store'

export const mapStrategyReferenceDataSigmas = (
  feed: update_snapshot.AsObject | update_ticker.AsObject,
): Store.StrategySigmas => {
  const { sigmas } = feed
  if (!sigmas) {
    return null
  }

  const { stgCartesianHeading, stgRefData } = sigmas

  return {
    refData: stgRefData && {
      strategyName: stgRefData.strategyName,
      strategyTarget: stgRefData.strategyTarget,
    },
    cartesianHeading: stgCartesianHeading && {
      activatedModelsNum: stgCartesianHeading.activeModelsNum,
      assetsNum: stgCartesianHeading.assetsNum || 0,
      baseCurrency: stgCartesianHeading.baseCurrency,
      portfoliosNum: stgCartesianHeading.portfoliosNum,
    },
  } as Store.StrategySigmas
}
