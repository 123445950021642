import React from 'react'
import { Col, Row, Typography } from 'antd'
import * as Store from 'types/store'
import LeftTable from './LeftTable'
import RightTable from './RightTable'
import { useTranslation } from 'react-i18next'
import { renderAvailableData } from 'core/helpers'
import { formatGliphNumber } from 'core/formats'
import styles from './tables.module.scss'
interface Props {
  data: Store.PositionMonitoring[]
}

const PositionMonitoringTable: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation()

  const sum = data.reduce(
    (initial, current) => {
      initial.currency = current.currency || t('general.noData')
      initial.counterValue += current?.value || 0
      initial.short += current.valueShort || 0
      initial.long += current.valueLong || 0
      initial.net += current.netHedging || 0
      return initial
    },
    {
      currency: t('general.noData'),
      counterValue: 0,
      short: 0,
      long: 0,
      net: 0,
    },
  )
  
  return (
    <Row className={styles['tables']}>
      <Col span={24}>
        <Row className="tables-top-row nonDraggable">
          <Col span={12} className="trading-return-card">
            <div className="title-wrapper">
              <Typography.Title level={2}>{t('table.column.counterValue')}</Typography.Title>
            </div>

            <Typography.Title level={3}>{`${renderAvailableData(
              formatGliphNumber(sum?.counterValue),
            )} ${sum?.currency}`}</Typography.Title>
          </Col>
        </Row>
      </Col>
      <Col className="tables-container">
        <LeftTable data={data} />
        <RightTable data={data} />
      </Col>
    </Row>
  )
}

export default PositionMonitoringTable
