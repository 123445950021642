import React, { useState } from 'react'
import { GridItemActions } from 'types/ui'

import styles from './styles.module.scss'
import { Modal, Popover, Typography } from 'antd'

import { useMeasure, useWindowSize } from 'react-use'
import { getGridItemClass } from 'services/dashboardService'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'
import PortfolioTable from 'v2/components/tables/PortfolioTable'
import ContextMenu from '../ContextMenu'
import classNames from 'classnames'
import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { ColumnKeys } from 'types/store'
import colors from 'config/colors'
import StrategyTable from 'v2/components/tables/StrategyTable'
import { themes } from './themes'
import { useTheme } from 'providers/ThemeProvider'

interface Props {
  portfolioData: Store.PortfolioTable[]
  strategyData: Store.StrategyTable[]
  columnKeys: ColumnKeys[]
  isOffline: boolean
  onItemClick: (action: GridItemActions) => void
}

const StrategyTableFrame: React.FC<Props> = ({
  portfolioData,
  strategyData,
  onItemClick,
  columnKeys = [],
  isOffline,
}) => {
  const { t } = useTranslation()
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const [isFullScreen, setFullScreen] = useState(false)
  const [ref, { width: frameWidth, height: frameHeight }] = useMeasure()
  const { width: windowWidth, height: windowHeight } = useWindowSize()
  const tableClass = classNames(styles['table-panel'], themeStyles['theme'], {
    'offline-frame': isOffline,
  })
  const [width, height] = isFullScreen ? [windowWidth, windowHeight] : [frameWidth, frameHeight]

  const portfolioColumns = columnKeys.filter((el) => el.table === 'strategy-portfolios')[0]?.keys
  const strategyColumns = columnKeys.filter((el) => el.table === 'strategy')[0]?.keys

  const toggleFullscreen = () => {
    setFullScreen((state) => !state)
  }

  const onMenuItemClick = (action: GridItemActions) => {
    if (action === GridItemActions.FullScreen) {
      toggleFullscreen()
      return
    }
    onItemClick(action)
  }
  const troubleIcon = isOffline && (
    <Popover content={t('error.technicalProblems')} trigger="hover">
      <ExclamationCircleOutlined style={{ color: colors.red }} className="mr-5" />
    </Popover>
  )

  const panelContent = (
    <section className={tableClass} ref={ref}>
      <div className={classNames(getGridItemClass(width, height), { 'full-screen': isFullScreen })}>
        <div className="frame-header">
          <div className="frame-header__top-row">
            {troubleIcon}
            <Typography.Title level={3} className="title">
              {t('frameType.strategyTable')}
            </Typography.Title>
            <ContextMenu
              isFullscreen={isFullScreen}
              onMenuItemClick={onMenuItemClick}
              items={[
                { key: GridItemActions.ColumnSelect, label: t('contextMenu.selectColumns') },
                {
                  key: GridItemActions.FullScreen,
                  label: t('contextMenu.fullscreen'),
                  icon: isFullScreen ? <CheckOutlined /> : null,
                },
                { key: GridItemActions.Edit, label: t('operativeSession.editFrame') },
                !isFullScreen && { key: GridItemActions.Close, label: t('contextMenu.close') },
              ]}
            />
          </div>
        </div>
        <div className="frame-content">
          <div className="table-panel-wrapper">
            {strategyData[0] &&
              <>
                <StrategyTable
                  data={strategyData}
                  columnKeys={strategyColumns}
                  baseCurrency={strategyData[0].baseCurrency}
                />
                <PortfolioTable
                  baseCurrency={strategyData[0].baseCurrency}
                  isStrategyTable
                  height={height}
                  data={portfolioData}
                  columnKeys={portfolioColumns}
                />
              </>
            }
          </div>
        </div>
      </div>
    </section>
  )

  if (isFullScreen) {
    return (
      <Modal
        open={true}
        centered
        width={'100%'}
        wrapClassName={'strategy-table-fullscreen nonDraggable'}
        footer={null}
        closable={false}
      >
        {panelContent}
      </Modal>
    )
  }

  return panelContent
}

export default StrategyTableFrame
