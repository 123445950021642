import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { createBrowserHistory } from 'history'
import createSagaMiddleware from 'redux-saga'

import RootReducer from './reducers'
import RootSaga from './saga'

declare let window

export const history = createBrowserHistory()

const enableReduxExtension = () => {
  const forceEnable = typeof window === 'object' && window.localStorage.getItem('ENABLE_REDUX') !== null

  if (forceEnable) {
    return true
  }
  return (
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  )
}

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware()

  const composeEnhancers = enableReduxExtension() ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose

  const reducers = combineReducers({
    root: RootReducer,
  })

  const enhancers = composeEnhancers(applyMiddleware(sagaMiddleware))

  const store = createStore(reducers, enhancers)

  sagaMiddleware.run(RootSaga)
  return store
}

export default configureStore
