import { FlowControlSpeed } from 'types/enums'

const FC_CONFIG = 'FC_CONFIG'
const DEFAULT_VALUE = 100

const getFlowControlJson = (): Json<number> => {
  const flowControlConfig = typeof localStorage !== 'undefined' && localStorage.getItem(FC_CONFIG)
  if (flowControlConfig) {
    return JSON.parse(flowControlConfig)
  }
  return {}
}

export const setFlowControl = (key: string, value: number) => {
  const json = getFlowControlJson()
  json[key] = value
  localStorage.setItem(FC_CONFIG, JSON.stringify(json))
}

export const resetFlowControl = () => {
  localStorage.removeItem(FC_CONFIG)
}

export const getFlowControl = (
  flowControl: Json<number>,
): { data: Json<number>; getValue: (speed: FlowControlSpeed) => number } => {
  const json = getFlowControlJson()

  const modifiedFlowControl = Object.entries(flowControl).reduce((flowControl, [key, value]) => {
    flowControl[key] = key in json ? json[key] : value
    return flowControl
  }, {})

  const getValue = (speed: FlowControlSpeed): number => {
    return modifiedFlowControl[speed] || DEFAULT_VALUE
  }

  return {
    data: modifiedFlowControl,
    getValue,
  }
}
