// @ts-ignore
import { update_snapshot, update_ticker } from 'sockets/strategies/strategy_analysis_indicators_pb'
import * as Store from 'types/store'

export const mapStrategyAnalysisIndicatorsSigmas = (
  feed: update_snapshot.AsObject | update_ticker.AsObject,
): Store.StrategySigmas => {
  const { sigmas } = feed
  if (!sigmas) {
    return null
  }

  return {} as Store.StrategySigmas
}
