import React, { useState } from 'react'
import { Button, Input, Row, Space, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { Resolution } from 'types/ui'
import { ColumnsType, TableRowSelection } from 'antd/lib/table/interface'
import { Controller, useForm } from 'react-hook-form'
import FormField from 'components/FormField'

interface Props {
  resolutions: Resolution[]
  saveChanges: (selectedRowKeys: number[]) => void
  editName: (name: string, key: number) => void
}

const GuidesTable: React.FC<Props> = ({ resolutions, saveChanges, editName }) => {
  const { t } = useTranslation()
  const [editKey, setEditKey] = useState()
  const { handleSubmit, control, setValue } = useForm()
  const selectedKeys = resolutions
    .filter((resolution) => resolution.active)
    .map((resolution) => resolution.key)

  const rowSelection: TableRowSelection<Resolution> = {
    onChange: saveChanges,
    defaultSelectedRowKeys: selectedKeys,
    getCheckboxProps: (record) => ({
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  const onSubmit = ({ name }) => {
    editName(name, editKey)
    setEditKey(null)
  }

  const TABLE_COLUMNS: ColumnsType<Resolution> = [
    {
      title: t('table.column.name'),
      dataIndex: 'name',
      width: 300,
      render: function renderName(name, resolution) {
        if (resolution.key !== editKey) {
          return name
        }
        return (
          <Controller
            name="name"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormField error={error}>
                  <Input onChange={onChange} value={value} />
                </FormField>
              )
            }}
          />
        )
      },
    },
    {
      title: t('table.column.width'),
      dataIndex: 'width',
      width: 120,
    },
    {
      title: t('table.column.height'),
      dataIndex: 'height',
      width: 120,
    },
    {
      title: t('table.column.orientation'),
      dataIndex: 'orientation',
      width: 200,
    },
    {
      key: 'action',
      dataIndex: 'key',
      render: function renderEdit(key, resolution) {
        const edit = () => {
          setEditKey(key)
          setValue('name', resolution.name)
        }

        return key !== editKey ? (
          <Button className="secondary action-btn" type="dashed" onClick={edit}>
            {t('buttons.edit')}
          </Button>
        ) : (
          <Row justify="end">
            <Space>
              <Button type="dashed" className="secondary edit-btn" onClick={() => setEditKey(null)}>
                {t('general.cancel')}
              </Button>
              <Button type="primary" className="secondary edit-btn" onClick={handleSubmit(onSubmit)}>
                {t('general.ok')}
              </Button>
            </Space>
          </Row>
        )
      },
    },
  ]

  return (
    <section className="guides-table">
      <Table
        dataSource={resolutions}
        columns={TABLE_COLUMNS}
        pagination={false}
        scroll={{ y: 220 }}
        rowSelection={rowSelection}
      />
    </section>
  )
}

export default GuidesTable
