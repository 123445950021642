import { Button, Col, Form, Input, Row, Typography, Switch, Space, Avatar } from 'antd'
import AppContentCard from 'components/AppContentCard'
import BankAccount from 'containers/StrategyViewer/AssetForm/BankAccount'
import BrokerAccount from 'containers/StrategyViewer/AssetForm/BrokerAccount'
import BrokerName from 'containers/StrategyViewer/AssetForm/BrokerName'
import CurrencyField from 'containers/StrategyViewer/AssetForm/CurrencyField'
import MICFieldContainer from 'containers/StrategyViewer/AssetForm/MICField'
import ModelFieldContainer from 'containers/StrategyViewer/AssetForm/ModelField'
import SymbolFieldContainer from 'containers/StrategyViewer/AssetForm/SymbolField'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import LongShortField from 'containers/StrategyViewer/AssetForm/LongShortField'
import QtyParametrs from './QtyParametrs'
import CloseIcon from 'assets/icon/close-icon.svg'

import MarketFieldContainer from 'containers/StrategyViewer/AssetForm/MarketField'
import DescriptionFieldContainer from 'containers/StrategyViewer/AssetForm/DescriptionField'
import DependsOn from 'components/DependsOn'
import ModelParameters from 'containers/StrategyViewer/AssetForm/ModelParameters'
import { CrudOperation } from 'types/enums'
import * as Store from 'types/store'
import styles from './styles.module.scss'
import { checkSubmitButtonBeDisabled } from 'core/helpers'
import { useForm } from 'antd/lib/form/Form'
import { mapAssetToAssetForm } from 'services/store/mapService'
import { selectAssetsErrorCode } from 'store/resources/selectors'
import { ResponseCode } from 'types/enums.api'

const { Title, Paragraph } = Typography

interface Props {
  asset: Store.Asset
  loading: boolean
  onFinish: (data) => void
  onClose: () => void
  mode: CrudOperation
}

const AssetForm: React.FC<Props> = ({ asset, loading, onFinish, onClose, mode }) => {
  const { t } = useTranslation()
  const [form] = useForm()

  const errorCode = useSelector(selectAssetsErrorCode)
  
  useEffect(() => {
    if (errorCode.errorDescription.includes('asset already exists')) {
      form.scrollToField('name')
      form.getFieldInstance('name').focus()
      form.setFields([{ name: 'name', errors: [errorCode.errorDescription] }])
    }
  }, [errorCode])

  useEffect(() => {
    if (mode !== CrudOperation.Update) {
      return
    }
    const formValues: Store.AssetForm = mapAssetToAssetForm(asset)
    form.setFieldsValue(formValues)
  }, [asset])

  const assetBelongToGroup = !!asset?.group?.id

  return (
    <AppContentCard fullHeight>
      <Form form={form} onFinish={onFinish} className={styles['asset-form']}>
        <Row align="middle" className="col-title">
          <Col span={14}>
            <Title>{mode === CrudOperation.Create ? 'Add' : 'Update'} Asset</Title> {/* JI-TRANSLATE */}
          </Col>

          <Col span={10}>
            <Row justify="end">
              <Space>
                <Form.Item className="mb-0">
                  <Button
                    type="dashed"
                    className="secondary"
                    loading={loading}
                    disabled={loading}
                    onClick={onClose}
                  >
                    {t('general.cancel')}
                  </Button>
                </Form.Item>

                <Form.Item className="mb-0" shouldUpdate={true}>
                  {() => (
                    <Button
                      type="primary"
                      className="primary"
                      htmlType="submit"
                      loading={loading}
                      disabled={loading || checkSubmitButtonBeDisabled(form)}
                    >
                      {t('general.save')}
                    </Button>
                  )}
                </Form.Item>
              </Space>
            </Row>
          </Col>

          {mode === CrudOperation.Read && (
            <Col span={4} className="align-right">
              <Button className="col-close" type="ghost" onClick={onClose}>
                <Avatar shape="square" src={CloseIcon} size={24} />
              </Button>
            </Col>
          )}
        </Row>

        <section className="inner-content">
          <Row align="middle" gutter={[5, 0]}>
            <Col span={24}>
              <Title level={2} className="primary-color mb-10">
                {t('general.description')}
              </Title>
            </Col>
            <Col span={12}>
              <Paragraph>{t('general.name')}</Paragraph>
            </Col>
            <Col span={12}>
              <Form.Item name="name" rules={[{ required: true, message: t('error.requiredField') }]}>
                <Input maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Typography.Text className="field-description">{t('assets.description.name')}</Typography.Text>
            </Col>
          </Row>
          <SymbolFieldContainer form={form} mode={mode} />
          <MICFieldContainer form={form} mode={mode} />
          <MarketFieldContainer mode={mode} form={form} />
          <CurrencyField mode={mode} form={form} />
          <DescriptionFieldContainer form={form} />
          <BankAccount mode={mode} />
          <Title level={2} className="primary-color mb-16">
            {t('general.management')}
          </Title>
          {/* <BrokerName /> */}
          <BrokerAccount form={form} mode={mode} />
          <ModelFieldContainer form={form} model={asset?.modelClassId} mode={mode} assetBelongToGroup={assetBelongToGroup} />
          <DependsOn fields={['model']}>
            {(values) => {
              const { model } = values

              if (!model) {
                return null
              }

              return (
                <ModelParameters
                  modelClassId={model}
                  assetModel={asset?.modelClassId}
                  mode={mode}
                  params={asset?.modelAssetParameters}
                />
              )
            }}
          </DependsOn>
          {
            assetBelongToGroup &&
              <Row align="middle" gutter={[5, 0]}>
                <Col span={12}>
                  <Paragraph>Group</Paragraph> {/* JI-TRANSLATE */}
                </Col>
                <Col span={12} className="group-name">
                  <Paragraph>{asset.group.name}</Paragraph>
                </Col>
              </Row>
          }
          <LongShortField />
          <Row align="middle" gutter={[5, 0]}>
            <Col span={12}>
              <Paragraph>{t('assets.qtyFCounterValue')}</Paragraph>
            </Col>
            <Col span={12} className="switch-input">
              <DependsOn fields={['qtyFCounterValue']}>
                {(fields) => {
                  const { qtyFCounterValue } = fields

                  return (
                    <Form.Item name="qtyFCounterValue">
                      <Switch checked={qtyFCounterValue} />
                    </Form.Item>
                  )
                }}
              </DependsOn>
            </Col>
            <Col span={24}>
              <Typography.Text className="field-description">
                {t('assets.description.qtyFCounterValue')}
              </Typography.Text>
            </Col>
          </Row>
          <Row align="middle" gutter={[5, 0]}>
            <Col span={12}>
              <Paragraph>{t('assets.sizeVariable')}</Paragraph>
            </Col>
            <Col span={12} className="switch-input">
              <DependsOn fields={['sizeVariable']}>
                {(fields) => {
                  const { sizeVariable } = fields

                  return (
                    <Form.Item name="sizeVariable">
                      <Switch checked={sizeVariable} />
                    </Form.Item>
                  )
                }}
              </DependsOn>
            </Col>
            <Col span={24}>
              <Typography.Text className="field-description">
                {t('assets.description.sizeVariable')}
              </Typography.Text>
            </Col>
          </Row>
          <QtyParametrs form={form} />
        </section>
      </Form>
    </AppContentCard>
  )
}

export default AssetForm
