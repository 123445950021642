import { Col, Row } from 'antd'
import React, { useContext } from 'react'
import { AnalysisWindowTabType, Dimension } from 'types/enums'
import styles from './table-contents.module.scss'
import * as Store from 'types/store'
import LongShortDimensionTable from './LongShortDimensionTable'
import DimensionTable from './DimensionTable'
import EmptySunburstView from 'components/charts/Sunburst/EmptySunburst'
import PositionMonitoringTable from 'v2/components/frames/PositionMonitoring/tables/PositionMonitorigTable'
import SunburstChart from 'components/charts/Sunburst'
import ReturnAnalysisTable from 'v2/components/frames/ReturnAnalysis/tables/ReturnAnalysisTable'
import { AnalysisFrameContext } from 'contexts/AnalysisFrameContext'
import { ViewType } from 'types/ui'

interface Props {
  returnAnalysisData: Store.ReturnAnalysis[][]
  data: Store.PositionMonitoring[]
  tabType: AnalysisWindowTabType
  baseCurrency: string
  isLarge: boolean
}

const TableContents: React.FC<Props> = ({ data, tabType, returnAnalysisData, baseCurrency, isLarge }) => {
  const context = useContext(AnalysisFrameContext)
  const { viewType, firstDimension, secondDimension, isFullscreen } = context

  const renderTable = () => {
    if (firstDimension && data.length) {
      if (firstDimension === Dimension.LongShort) {
        return (
          <LongShortDimensionTable
            data={data}
            secondDimension={secondDimension}
            tabType={tabType}
            baseCurrency={baseCurrency}
          />
        )
      } else {
        return (
          <DimensionTable
            data={data}
            dimension={firstDimension}
            secondDimension={secondDimension}
            tabType={tabType}
            baseCurrency={baseCurrency}
          />
        )
      }
    }

    return null
  }

  const table =
    tabType === AnalysisWindowTabType.PositionMonitoring ? (
      <PositionMonitoringTable data={data} />
    ) : (
      <ReturnAnalysisTable data={returnAnalysisData} />
    )

  const hasData = data && data.length > 0 && data.some((item) => item.value !== 0)

  return (
    <div className={styles['container']}>
      {viewType === ViewType.Graph || isFullscreen || isLarge ? (
        <Row className="main-row">
          <Col className="graph-container">
            <div className="sunburst-chart-container nonDraggable">
              <div className="sunburst-chart-wrapper">
                {hasData ? (
                  <SunburstChart
                    data={data}
                    firstDimension={firstDimension}
                    secondDimension={secondDimension}
                    tabType={tabType}
                  />
                ) : (
                  <EmptySunburstView />
                )}
              </div>
            </div>

            <div className="table-assets-container nonDraggable">{renderTable()}</div>
          </Col>
          <Col className="tables-with-graph">{table}</Col>
        </Row>
      ) : (
        table
      )}
    </div>
  )
}

export default TableContents
