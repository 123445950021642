import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { GridItemActions } from 'types/ui'
import * as Store from 'types/store'
import { removeFrame } from 'store/dashboard/actions'
import SignalsTable from 'v2/components/frames/SignalsPanel'
import { useSelector } from 'react-redux'
import { selectOperativeSessionAssets, selectOperativeSessionSession } from 'store/pages/selectors'
import { FlowControlSpeed, SessionStatus } from 'types/enums'
import sigmaServices from 'config/sigmaServices'
import { AssetFeed } from 'types/enums.api'
import { isOffline } from 'hooks/common'
import { config } from 'core'

const { Models, SecurityData } = sigmaServices.Assets

interface Props {
  frame: Store.Frame
}

const SignalsPanelFrame: React.FC<Props> = ({ frame }) => {
  const dispatch = useDispatch()
  const session = useSelector(selectOperativeSessionSession)
  const assets = useSelector(selectOperativeSessionAssets)

  const webWorkerRef = useRef<Worker | null>(null)

  const [data, setData] = useState<Store.SignalsData[]>([] as Store.SignalsData[])

  const [lastTick, setLastTick] = useState<Date>(new Date())

  const messageReceived = () => {
    setLastTick(new Date())
  }

  useEffect(() => {
    webWorkerRef.current = new Worker(new URL('../../../../workers/assetsSockets.web.worker.ts', import.meta.url))

    webWorkerRef.current.onmessage = (event: MessageEvent) => {
      const { type, data: workerData, error } = event.data
      if (type === 'data') {
        setData(workerData)
        messageReceived()
      } else if (type === 'error') {
        console.error('Worker error:', error)
      }
    }

    const initMsg = {
      type: 'init',
      payload: {
        assets: assets,
        sessionId: session.id,
        sockets: [
          { service: AssetFeed.Models, sigmas: [Models.ModelSignal, Models.TheoreticalOrder] },
          { service: AssetFeed.SecurityData, sigmas: [SecurityData.AssetSecurityRefData] },
        ],
        flowControlSpeed: FlowControlSpeed.Slow,
        webSocketUrl: config.socketBaseUrl,
      },
    }

    // Send startup message (with all the setup params) to the worker
    webWorkerRef.current.postMessage(initMsg)

    return () => {
      webWorkerRef.current.postMessage({ type: 'terminate' })
      webWorkerRef.current.terminate()
    }
  }, [])

  const onItemClick = (action: GridItemActions) => {
    switch (action) {
      case GridItemActions.Close:
        dispatch(removeFrame(frame))
        break
    }
  }

  const isFrameOffline = (): boolean => {
    if (session.status !== SessionStatus.Running) {
      return false
    }
    return isOffline(lastTick)
  }

  return <SignalsTable data={data.length ? data : []} onItemClick={onItemClick} isOffline={isFrameOffline()} />
}

export default SignalsPanelFrame
