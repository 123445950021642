import React, { useCallback, useEffect, useState } from 'react'
import { selectOperativeSessionSession, selectOperativeSessionAssets } from 'store/pages/selectors'
import { selectDashboardEntityFilter } from 'store/dashboard/selectors'
import { useDispatch, useSelector } from 'react-redux'
import * as Store from 'types/store'
import { Dimension } from 'types/enums'
import { isFilteredLayout } from 'services/dashboardService'
import { selectAssets } from 'store/resources/selectors'
import PositionMonitoring from 'v2/components/frames/PositionMonitoring'
import { GridItemActions, ViewType } from 'types/ui'
import { removeFrame, updateFrameOptions } from 'store/dashboard/actions'
import SelectAssetsModal from 'v2/components/SelectAssetsModal'
import { AnalysisFrameContext, AnalysisFrameContextInterface } from 'contexts/AnalysisFrameContext'
import * as api from 'api/historicalAnalysis'
import { isApiError, isGraphQlError } from 'core'
import * as Api from 'types/api'
import { jsonToPositionMonitoring } from 'services/store/mapHistoricalAnalysis'

const getPositionMonitoringData = async (sessionId: number) => {
  const response = await api.getHistoricalPositionMonitoringData(sessionId)

  if (isApiError(response) || isGraphQlError(response)) {
    return null
  }

  const { data } = response as Api.HistoricalPositionMonitoringResponse
  return jsonToPositionMonitoring(data.historical_data_position_analysis_v)
}

interface Props {
  frame: Store.Frame
}

const PositionMonitoringContainer: React.FC<Props> = ({ frame }) => {
  const allAssets = useSelector(selectAssets)
  const session = useSelector(selectOperativeSessionSession)
  const filtered = isFilteredLayout(useSelector(selectDashboardEntityFilter), session.type)
  const operativeSessionAsset = useSelector(selectOperativeSessionAssets)
  const { options } = frame
  const { viewType } = options
  const [isFullscreen, setFullscreen] = useState(false)
  const [firstDimension, setFirstDimention] = useState<Dimension>(Dimension.Market)
  const [secondDimension, setSecondDimention] = useState<Dimension>(null)
  const [isModalVisible, setModalVisible] = useState(false)
  const assets = filtered ? operativeSessionAsset : allAssets

  const [selectedAssets, setSelectedAssets] = useState<Store.Asset[]>(assets)
  const [data, setData] = useState<Store.PositionMonitoring[]>([])
  const baseCurrency = data[0]?.currency || ''

  const dispatch = useDispatch()

  const onDimensionClick = (dimension: number, value: Dimension): void => {
    switch (dimension) {
      case 1:
        setFirstDimention(value)
        setSecondDimention(null)
        break
      case 2:
        setSecondDimention(value)
        break
      default:
        break
    }
  }

  const onContextMenuItemClick = (action: GridItemActions) => {
    switch (action) {
      case GridItemActions.Close:
        dispatch(removeFrame(frame))
        break
      case GridItemActions.Edit:
        setModalVisible(true)
        break
    }
  }

  const onUpdateOptions = useCallback(
    (options: Store.FrameOptions) => {
      dispatch(updateFrameOptions({ id: frame.id, options }))
    },
    [dispatch, frame],
  )

  const setViewType = (viewType: ViewType) => {
    onUpdateOptions({ ...frame.options, viewType })
  }

  const context: AnalysisFrameContextInterface = {
    id: frame.id,
    squares: {
      x: frame.layout.w,
      y: frame.layout.h,
    },
    isFullscreen,
    timeFrame: null,
    viewType,
    isOffline: false,
    firstDimension,
    secondDimension,
    selectedAssets,
    setTimeFrame: null,
    setViewType,
    setFullscreen,
    setFirstDimention,
    setSecondDimention,
    setSelectedAssets,
  }

  useEffect(() => {
    const getData = async () => {
      const data = await getPositionMonitoringData(session.id)
      if (data) {
        setData(data)
      }
    }
    getData()
  }, [])

  const filteredData = data.filter((item) => {
    return selectedAssets.find((asset) => asset.id === item.id)
  })

  return (
    <>
      {isModalVisible && (
        <SelectAssetsModal
          onClose={() => setModalVisible(false)}
          onSelect={(assets) => setSelectedAssets(assets)}
          selectedAssets={selectedAssets}
        />
      )}
      <AnalysisFrameContext.Provider value={context}>
        <PositionMonitoring
          data={filteredData}
          onDimensionClick={onDimensionClick}
          baseCurrency={baseCurrency}
          onContextMenuItemClick={onContextMenuItemClick}
        />
      </AnalysisFrameContext.Provider>
    </>
  )
}

export default PositionMonitoringContainer
