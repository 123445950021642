import { getAxios } from './apiService'
import { config } from 'core'
import { getApiError, getAuthenticationHeader } from './common'
import { AxiosResponse } from 'axios'
import { SessionStatus, SessionType } from 'types/enums'
import SocketConnection from './socketConnection'
import * as Api from 'types/api'
import * as Store from 'types/store'
import { ApiSessionStatus } from 'types/enums.api'

const axios = getAxios()

export async function updateSession(id: number, type: SessionType): Promise<AxiosResponse | ApiError> {
  const header = await getAuthenticationHeader()
  return axios
    .put(config.api.sessionUpdate(id, type), null, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export const subscribeToSessionMetrics = (sessionId: number, onMessage: (message: MessageEvent) => void) => {
  const { close } = new SocketConnection(config.api.sessionMetricsSocket(sessionId), () => null, onMessage)

  const unsubscribe = () => {
    close()
  }

  return unsubscribe
}

export const subscribeToGetBrokenAssets = (sessionId: number, onMessage: (message: MessageEvent) => void) => {
  const { close } = new SocketConnection(config.api.getBroken(sessionId), () => null, onMessage)

  const unsubscribe = () => {
    close()
  }

  return unsubscribe
}

export async function getOperativeSessionError(
  id: number,
  limit: number,
): Promise<Api.OperativeSessionErrorResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `query ($id: Int, $status:String, $limit:Int) {
      historical_data_operative_session_status_log_v(
          where: {
            error_code: {_is_null: false},
            operative_session_id: {_eq: $id},
          },
          order_by: {timestamp: desc},
          limit: $limit
        ) {
        operative_session_id
        error_description
        timestamp
      }
    }`,
    variables: {
      id,
      limit,
    },
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export const getSessionErrors = async (session: Store.Session): Promise<Store.SessionError[]> => {
  const { id, type } = session

  const isSessionVirtualOrBacktest = type === SessionType.Virtual || type === SessionType.BackTest

  const limit: number = isSessionVirtualOrBacktest ? null : 1
  const response = await getOperativeSessionError(id, limit)

  const error = response as ApiError
  if (error.status) {
    return null
  }
  const {
    data: { historical_data_operative_session_status_log_v: logs },
  } = response as Api.OperativeSessionErrorResponse

  if (logs.length > 0) {
    const errorsList = logs.map((log) => {
      return {
        errorDescription: log.error_description,
        time: new Date(log.timestamp),
      }
    })

    return errorsList
  }

  return null
}
