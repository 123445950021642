import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { t } from 'core'
import { TimeFrame, SessionType } from 'types/enums'
import { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'

import AssetStatusDropdown from 'v2/containers/CandleStick/Asset/AssetStatusDropdown'

import { selectOperativeSessionAssets, selectOperativeSessionSession } from 'store/pages/selectors'

import { FrameSize, ViewType } from 'types/ui'
import ViewTypeSelector from '../ViewTypeSelector'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
import CandlestickView from 'v2/components/frames/AssetCandleStick/CandlestickView'
import DetailsViewBySession from 'v2/containers/Wrapper/AssetCandleStickFrame/DetailsViewBySession'
import { CELL_HEIGHT, CELL_WIDTH, confirmFrameSize } from 'services/dashboardService'
import Trace from 'v2/containers/CandleStick/Asset/Trace'
import { selectPopupContainer } from 'core/helpers'
import * as Store from 'types/store'


const PanelContent: React.FC = () => {
  const context = useContext(CandleStickFrameContext)
  const { resource, viewType, timeFrame, isFullscreen, squares, trace } = context

  const isMultiAsset = (resource as Store.Asset).modelClass.type === 'Multi'
  const assetId = resource.id

  const session = useSelector(selectOperativeSessionSession)
  const assets = useSelector(selectOperativeSessionAssets)
  const asset = assets.find((asset) => asset.id === assetId)
  const isBacktest = session.type === SessionType.BackTest

  const [width, height] = [squares.x * CELL_WIDTH, squares.y * CELL_HEIGHT]
  const isLarge = confirmFrameSize(width, height, FrameSize.Large)

  const onChangeView = (viewType: ViewType) => {
    context.setViewType(viewType)
  }

  const onChangeTimeFrame = (timeFrame: TimeFrame) => {
    context.setTimeFrame(timeFrame)
  }

  const timeFrameMenu = [
    { key: TimeFrame.TDY, label: t('general.TDY'), onClick: (e) => onChangeTimeFrame(e.key) },
    { key: TimeFrame.YTD, label: t('general.YTD'), onClick: (e) => onChangeTimeFrame(e.key) },
  ]

  const renderContent = () => {
    if (trace) {
      return <Trace visible={true} asset={resource as Store.Asset} isMulti={isMultiAsset} />
    }

    if (viewType === ViewType.Details || isFullscreen || isLarge) {
      return <DetailsViewBySession session={session} />
    }

    return <CandlestickView />
  }

  return (
    <>
      <div className="asset-config-row nonDraggable JI-RENDER-panel-content-component">
        <Dropdown
          trigger={['click']}
          menu={{items: timeFrameMenu}}
          getPopupContainer={(trigger) => selectPopupContainer(trigger, isFullscreen)}
          className="dropdown dropdown--time-horizon"
          disabled={isBacktest}
        >
          <Button type="dashed" className='JI-RENDER-timeframe-button'>
            {timeFrame} <DownOutlined />
          </Button>
        </Dropdown>

        <AssetStatusDropdown asset={asset} disabled={isBacktest} />

        <ViewTypeSelector value={viewType} onChange={onChangeView} />
      </div>

      {renderContent()}
    </>
  )
}

export default PanelContent
