import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import * as Store from 'types/store'
import { useSelector } from 'react-redux'

import DetailsView from 'v2/components/frames/AssetCandleStick/DetailsView'
import { selectPortfolios, selectStrategies } from 'store/resources/selectors'
import { BUFFERS, config } from 'core'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
// import { useAssetSigmaSnapshotState } from 'hooks'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import sigmaServices from 'config/sigmaServices'
import { AssetFeed } from 'types/enums.api'
import { FlowControlSpeed } from 'types/enums'
import { isOffline } from 'hooks/common'

const { SecurityData, Operations, Return } = sigmaServices.Assets

const DetailsViewContainer: React.FC = () => {
  const context = useContext(CandleStickFrameContext)
  const { resource, setOffline, timeFrame } = context
  const asset = resource as Store.Asset
  const portfolios = useSelector(selectPortfolios)
  const strategies = useSelector(selectStrategies)
  const portfolio = portfolios.find((portfolio) => portfolio.id === asset.portfolioId)
  const strategy = strategies.find((strategy) => strategy.id === portfolio.strategyId)
  const session = useSelector(selectOperativeSessionSession)

  // fix for not updating Frame
  const assetArray = useMemo(() => [asset], [asset])

  // const [{ [asset.id]: sigmas1 }, isOffline1, lastTick1] = useAssetSigmaSnapshotState(
  //   assetArray,
  //   session.id,
  //   [SecurityData.AssetSecurityTrade, SecurityData.AssetSecurityBidAsk, SecurityData.JiTrade],
  //   AssetFeed.SecurityData,
  //   BUFFERS.Large,
  // )

  // const [{ [asset.id]: sigmas2 }, isOffline2, lastTick2] = useAssetSigmaSnapshotState(
  //   assetArray,
  //   session.id,
  //   // [Operations.OpenOperation, Operations.AssetCartesianOperations, Operations.ClosedOperation],
  //   [Operations.OpenOperation, Operations.AssetCartesianOperations],
  //   AssetFeed.Operations,
  //   BUFFERS.Large,
  // )

  // const [{ [asset.id]: sigmas3 }, isOffline3, lastTick3] = useAssetSigmaSnapshotState(
  //   assetArray,
  //   session.id,
  //   [Return.AssetTradRetList],
  //   AssetFeed.Return,
  //   BUFFERS.Large,
  // )

  // const sigmas = { ...sigmas1, ...sigmas2, ...sigmas3 }
  // const isOffline = isOffline1 || isOffline2 || isOffline3

  const [data, setData] = useState<Store.AssetSigmas>({} as Store.AssetSigmas)

  const webWorkerRef = useRef<Worker | null>(null)

  const [lastTick, setLastTick] = useState<Date>(new Date())

  const messageReceived = () => {
    setLastTick(new Date())
  }

  useEffect(() => {
    webWorkerRef.current = new Worker(new URL('../../../../workers/assetDetailsSockets.web.worker.ts', import.meta.url))

    webWorkerRef.current.onmessage = (event: MessageEvent) => {
      const { type, data: workerData, error } = event.data
      if (type === 'data') {
        setData(workerData)
        messageReceived()
      } else if (type === 'error') {
        console.error('Worker error:', error)
      }
    }

    const initMsg = {
      type: 'init',
      payload: {
        assets: assetArray,
        sessionId: session.id,
        sockets: [
          { service: AssetFeed.Return, sigmas: [Return.AssetTradRetList] },
          { service: AssetFeed.Operations, sigmas: [Operations.OpenOperation, Operations.AssetCartesianOperations] },
          { service: AssetFeed.SecurityData, sigmas: [SecurityData.AssetSecurityTrade, SecurityData.AssetSecurityBidAsk, SecurityData.JiTrade] },
          // { service: AssetFeed.SecurityData, sigmas: [SecurityData.AssetSecurityRefData] },
        ],
        flowControlSpeed: FlowControlSpeed.Slow,
        webSocketUrl: config.socketBaseUrl,
      },
    }

    // Send startup message (with all the setup params) to the worker
    webWorkerRef.current.postMessage(initMsg)

    return () => {
      webWorkerRef.current.postMessage({ type: 'terminate' })
      webWorkerRef.current.terminate()
    }
  }, [])

  //notify to the context that we are offline
  // useEffect(() => {
  //   setOffline(isOffline)
  // }, [lastTick1, lastTick2, lastTick3, isOffline])

  useEffect(() => {
    setOffline(isOffline(lastTick))
  }, [lastTick])

  return <DetailsView key={`left-frame`} sigmas={data} strategy={strategy} portfolio={portfolio} />
}

export default DetailsViewContainer
