import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setEntityFilter } from 'store/dashboard/actions'
import { selectDashboardEntityFilter } from 'store/dashboard/selectors'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import Filters from 'v2/components/settings/Filters'

const FiltersContainer: React.FC = () => {
  const dispatch = useDispatch()
  const session = useSelector(selectOperativeSessionSession)

  const filtered = useSelector(selectDashboardEntityFilter)

  const onSetEntityFilter = (display: boolean) => {
    dispatch(setEntityFilter(display))
  }

  return <Filters sessionType={session.type} onSetEntityFilter={onSetEntityFilter} entityFilter={filtered} />
}

export default FiltersContainer
