import { Button, Col, Form, Input, Row, Space, Typography } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { Store } from 'antd/lib/form/interface'
import AppContentCard from 'components/AppContentCard'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectStrategiesErrorCode } from 'store/resources/selectors'

const { Title, Paragraph } = Typography

interface Props {
  loading: boolean
  form: FormInstance
  onFinish: (data: Store) => void
  onCancel: () => void
}

const StrategyForm: React.FC<Props> = ({ form, loading, onFinish, onCancel }) => {
  const { t } = useTranslation()

  const errorCode = useSelector(selectStrategiesErrorCode)

  useEffect(() => {
    if (errorCode.errorDescription.includes('strategy already exists')) {
      form.scrollToField('name')
      form.getFieldInstance('name').focus()
      form.setFields([{ name: 'name', errors: [errorCode.errorDescription] }])
    }
  }, [errorCode])

  return (
    <Col span={8} className="full-height">
      <AppContentCard fullHeight>
        <Form onFinish={onFinish} form={form} layout="vertical" requiredMark={false}>
          <Row align="middle" className="col-title">
            <Col span={12}>
              <Title>{t('general.strategy')}</Title>
            </Col>
            <Col span={12}>
              <Row justify="end">
                <Space>
                  <Form.Item className="mb-0">
                    <Button type="dashed" loading={loading} disabled={loading} onClick={onCancel}>
                      {t('general.cancel')}
                    </Button>
                  </Form.Item>

                  <Form.Item className="mb-0">
                    <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                      {t('general.save')}
                    </Button>
                  </Form.Item>
                </Space>
              </Row>
            </Col>
          </Row>

          <Row align="middle" gutter={[5, 0]}>
            <Col span={12}>
              <Paragraph>{t('general.name')}</Paragraph>
            </Col>
            <Col span={12}>
              <Form.Item name="name" rules={[{ required: true, message: t('error.requiredField') }]}>
                <Input placeholder={t('general.name')} />
              </Form.Item>
            </Col>
          </Row>

          <Row align="middle" gutter={[5, 0]}>
            <Col span={12}>
              <Paragraph>{t('strategies.target')}</Paragraph>
            </Col>
            <Col span={12}>
              <Form.Item name="target">
                <Input placeholder={t('strategies.target')} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AppContentCard>
    </Col>
  )
}

export default StrategyForm
