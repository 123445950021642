import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AppContentCard from 'components/AppContentCard'

import { Modal, Row, Space, Button, Typography, Avatar } from 'antd'

import SessionAssetsTransfer from 'containers/transfer/SessionAssetsTransfer'

import styles from './frame.module.scss'
import CloseIcon from 'assets/icon/close-icon.svg'
import * as Store from 'types/store'
const { Title } = Typography

interface Props {
  selectedAssets: Store.Asset[]
  onClose: () => void
  onSelect: (assets: Store.Asset[]) => void
}

const SelectAssetsModal: React.FC<Props> = ({ selectedAssets, onClose, onSelect }) => {
  const { t } = useTranslation()
  const [assets, setAssets] = useState<Store.Asset[]>([])

  const disabled = assets.length > 0
  const onSave = () => {
    onSelect(assets)
    onClose()
  }

  return (
    <Modal
      closable={false}
      width={'50%'}
      title={null}
      centered={true}
      className={styles['frame-modal']}
      footer={null}
      open={true}
      destroyOnClose
      wrapClassName="nonDraggable"
    >
      <AppContentCard>
        <div className="close-btn-wrapper">
          <Button className="col-close" type="ghost" onClick={onClose}>
            <Avatar shape="square" src={CloseIcon} size={24} />
          </Button>
        </div>

        <Title level={2} className="mb-16">
          {t('operativeSession.chooseAsset')}
        </Title>

        <SessionAssetsTransfer
          setAssets={setAssets}
          selectedAssets={selectedAssets}
          shouldFilterAssets={true}
        />

        <Row justify="end" className="mt-30">
          <Space>
            <Button type="dashed" onClick={onClose}>
              {t('general.close')}
            </Button>
            <Button type="primary" disabled={!disabled} onClick={onSave}>
              {t('general.ok')}
            </Button>
          </Space>
        </Row>
      </AppContentCard>
    </Modal>
  )
}

SelectAssetsModal.displayName = 'SelectAssetsModal'

export default SelectAssetsModal
