import CircleChart from 'v2/components/CircleChart'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createFrame } from 'store/dashboard/actions'
import { FrameType, SessionType, TimeFrame } from 'types/enums'
import * as Store from 'types/store'
import { v4 as uuidv4 } from 'uuid'
import { generateLayout } from 'core/layoutHelper'
import { getViewTypeByFrameType } from 'core/frameHelper'

interface Props {
  data: Store.DataTree
  width: number
  height: number
}

const CircleChartContainer: React.FC<Props> = ({ data, width, height }) => {
  const dispatch = useDispatch()
  const { type: sessionType, status } = useSelector(selectOperativeSessionSession)
  const timeFrame = sessionType === SessionType.BackTest ? TimeFrame.TOT : TimeFrame.TDY
  const addFrame = (selectedResource: Store.SelectedResource, type: FrameType) => {
    const id = uuidv4() as string
    const frame: Store.Frame = {
      selectedResources: [selectedResource],
      frameType: type,
      sessionType: sessionType,
      options: {
        viewType: getViewTypeByFrameType(type),
        timeFrame,
      },
      id,
      layout: generateLayout(id, type, 0, Infinity),
    }

    dispatch(createFrame(frame))
  }

  return (
    <CircleChart
      data={data}
      addFrame={addFrame}
      sessionType={sessionType}
      sessionStatus={status}
      width={width}
      height={height}
    />
  )
}

export default CircleChartContainer
