import React, { useContext } from 'react'
import { Avatar, Button, Dropdown, Menu } from 'antd'
import { AssetStatus } from 'types/enums'
import { DownOutlined } from '@ant-design/icons'
import { getStatusIcon, selectPopupContainer } from 'core/helpers'
import IconFont from 'core/IconFont'
import { useDispatch, useSelector } from 'react-redux'
import { selectOperativeSessionAssets, selectOperativeSessionSession } from 'store/pages/selectors'
import * as Store from 'types/store'
import { changeSessionAssets } from 'store/pages/operative-session/actions'
import ChangeAllIcon from 'assets/icon/change-all-icon.svg'
import { selectPortfolios } from 'store/resources/selectors'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'

const values = [
  AssetStatus.Automatic,
  AssetStatus.Freeze,
  AssetStatus.StandByFlat,
  AssetStatus.StandByLong,
  AssetStatus.StandByShort,
]

interface Props {
  strategy: Store.Strategy
}

const AssetStatusDropdownContainer: React.FC<Props> = ({ strategy }) => {
  const dispatch = useDispatch()

  const context = useContext(CandleStickFrameContext)
  const { isFullscreen } = context

  const session = useSelector(selectOperativeSessionSession)
  const assets = useSelector(selectOperativeSessionAssets)
  const portfolios = useSelector(selectPortfolios)
  const { id: sessionId } = session
  const portfolioIds = portfolios
    .filter((item) => item.strategyId === strategy.id)
    .map((portfolio) => portfolio.id)

  const strategyAssets = assets.filter((item) => portfolioIds.includes(item.portfolioId))

  const menu = (
    <Menu
      className="nonDraggable"
      onClick={(value) => {
        dispatch(changeSessionAssets({ assets: strategyAssets, sessionId, status: value.key as AssetStatus }))
      }}
    >
      {values.map((item) => (
        <Menu.Item key={item}>
          <IconFont type={getStatusIcon(item)} />
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      getPopupContainer={(trigger) => selectPopupContainer(trigger, isFullscreen)}
      disabled={strategyAssets.length === 0}
      className="dropdown dropdown--models-status"
    >
      <Button type="dashed">
        <Avatar src={ChangeAllIcon} shape="square" /> <DownOutlined />
      </Button>
    </Dropdown>
  )
}

export default AssetStatusDropdownContainer
