import { update_snapshot, update_ticker } from 'sockets/portfolios/portfolio_reference_data_pb'
import * as Store from 'types/store'

export const mapPortfolioReferenceDataSigmas = (
  feed: update_snapshot.AsObject | update_ticker.AsObject,
): Store.PortfolioSigmas => {
  const { sigmas } = feed

  if (!sigmas) {
    return null
  }

  const { prtCartesianHeading, prtRefData } = sigmas

  return {
    refData: prtRefData && {
      portfolioName: prtRefData.portfolioName,
      strategyName: prtRefData.strategyName,
    },
    cartesianHeading: prtCartesianHeading && {
      activatedModelsNum: prtCartesianHeading.activeModelsNum,
      assetsNum: prtCartesianHeading.assetsNum || 0,
      baseCurrency: prtCartesianHeading.baseCurrency,
    },
  } as Store.PortfolioSigmas
}
