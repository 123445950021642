import { AnyAction } from 'redux'
import * as K from './constants'
import * as Store from 'types/store'
import { LoadingState } from 'types/enums'

export function loadUsersAction(): AnyAction {
  return {
    type: K.FETCH_USERS,
    payload: null,
  }
}
export function editUserAction(userForm: Store.SystemUserForm): AnyAction {
  return {
    type: K.EDIT_USER,
    payload: userForm,
  }
}
export function newUserAction(user: Store.SystemUserForm): AnyAction {
  return {
    type: K.NEW_USER,
    payload: user,
  }
}
export function deleteUserAction(id: number): AnyAction {
  return {
    type: K.DELETE_USER,
    payload: id,
  }
}
export function setLoadingState(state: LoadingState): AnyAction {
  return {
    type: K.SET_LOADING_STATE,
    payload: state,
  }
}
