import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AppContentCard from 'components/AppContentCard'
import classNames from 'classnames'

import { Modal, Row, Button, Typography, Space, Avatar } from 'antd'
import CloseIcon from 'assets/icon/close-icon.svg'
import styles from './styles.module.scss'
import { useDispatch } from 'react-redux'
import { ColumnKeys, TableType } from 'types/store'
import * as Store from 'types/store'
import { updateFrameOptions } from 'store/dashboard/actions'
import ColumnTransferTable from './ColumnTransferTable'
import * as _ from 'lodash'
const { Title } = Typography
import { themes } from './themes'
import { useTheme } from 'providers/ThemeProvider'

interface Props {
  frame: Store.Frame
  visible: boolean
  onClose: () => void
  tableTypes: TableType[]
}

const SelectColumnsModal: React.FC<Props> = ({ frame, visible, onClose, tableTypes }) => {
  const { t } = useTranslation()
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const dispatch = useDispatch()
  const storeColumnKeys = frame.options.columnKeys || []
  const tableClass = classNames(styles['select-columns-modal'], themeStyles['theme'])
  const [columnKeys, setSelectedColums] = useState<ColumnKeys[]>(storeColumnKeys)

  const isDisabled = _.isEqual(columnKeys, storeColumnKeys)

  const save = useCallback(
    (columnKeys: ColumnKeys[]) => {
      const options: Store.FrameOptions = { ...frame.options, columnKeys }
      dispatch(updateFrameOptions({ id: frame.id, options }))
      onClose()
    },
    [dispatch, frame],
  )

  const onSetSelectedKeys = (selectedKeys: string[], tableType: TableType) => {
    const filtered: ColumnKeys[] = columnKeys.filter((item) => item.table !== tableType)
    if (selectedKeys.length > 0) {
      const current = {
        table: tableType,
        keys: selectedKeys,
      }
      setSelectedColums([...filtered, current])
    } else {
      setSelectedColums(filtered)
    }
  }

  return (
    <Modal
      width={'50%'}
      title={null}
      centered={true}
      open={visible}
      className={tableClass}
      footer={null}
      destroyOnClose={true}
      closable={false}
      wrapClassName="nonDraggable"
    >
      <AppContentCard>
        <div className="close-btn-wrapper">
          <Button className="col-close" type="ghost" onClick={onClose}>
            <Avatar shape="square" src={CloseIcon} size={24} />
          </Button>
        </div>

        <Title level={2} className="mb-16">
          {t('table.selectColumns')}
        </Title>

        <Row>
          {tableTypes.includes('strategy') && (
            <ColumnTransferTable
              tableName={t('table.strategy')}
              titles={[t('table.allStrategyColumns'), t('table.selectedStrategyColumns')]}
              tableType="strategy"
              onSetSelectedKeys={onSetSelectedKeys}
              columnKeys={columnKeys}
            />
          )}
          {tableTypes.includes('portfolio') && (
            <ColumnTransferTable
              tableName={t('table.portfolio')}
              titles={[t('table.allPortfolioColumns'), t('table.selectedPortfolioColumns')]}
              tableType="portfolio"
              onSetSelectedKeys={onSetSelectedKeys}
              columnKeys={columnKeys}
            />
          )}
          {tableTypes.includes('strategy-portfolios') && (
            <ColumnTransferTable
              tableName={t('table.portfolio')}
              titles={[t('table.allPortfolioColumns'), t('table.selectedPortfolioColumns')]}
              tableType="strategy-portfolios"
              onSetSelectedKeys={onSetSelectedKeys}
              columnKeys={columnKeys}
            />
          )}
          {tableTypes.includes('asset') && (
            <ColumnTransferTable
              tableName={t('table.assets')}
              titles={[t('table.allAssetColumns'), t('table.selectedAssetColumns')]}
              tableType="asset"
              onSetSelectedKeys={onSetSelectedKeys}
              columnKeys={columnKeys}
            />
          )}
        </Row>

        <Row justify="end" className="mt-30">
          <Space>
            <Button type="dashed" onClick={onClose}>
              {t('general.close')}
            </Button>
            <Button type="primary" disabled={isDisabled} onClick={() => save(columnKeys)}>
              {t('general.ok')}
            </Button>
          </Space>
        </Row>
      </AppContentCard>
    </Modal>
  )
}

export default SelectColumnsModal
