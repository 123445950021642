import React from 'react'
import * as Store from 'types/store'

import { Button, Col, Row, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'
import routes from 'config/routes'
import { getSessionTypeLabel } from 'helpers/common'
import { LoadingState } from 'types/enums'
import SessionUpdateButton from 'containers/SessionSetup/SessionUpdateButton'

const { Paragraph, Text } = Typography

interface Props {
  session: Store.Session
  loadingState: LoadingState
  disabled: boolean
}

const SessionHeader: React.FC<Props> = ({ session, loadingState, disabled }) => {
  const { t } = useTranslation()
  const url = generatePath(routes.operativeSession.url, { id: session.id.toString() })
  const loading = loadingState === LoadingState.Updating || loadingState === LoadingState.Deleting
  return (
    <Row gutter={[16, 0]} className="mt-16 mb-16" align="middle">
      <Col flex="auto">
        <Paragraph>
          {session.isHistorical ? t('sessionSetup.historicalSession') : t('sessionSetup.sessionType')}{' '}
          <Text strong>{getSessionTypeLabel(session.type)}</Text>
        </Paragraph>
      </Col>

      <Col flex="0 0 100px">
        <Space>
          <SessionUpdateButton session={session} loadingState={loadingState} disabled={disabled} />
          <Button block type="primary" className="primary" loading={loading} disabled={disabled}>
            <Link to={url}>{t('general.enter')}</Link>
          </Button>
        </Space>
      </Col>
    </Row>
  )
}

export default SessionHeader
