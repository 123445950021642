export const STATE_KEY = 'strategies'

export const STRATEGIES_FETCH_SUCCESS = `${STATE_KEY}/STRATEGIES_LOAD_SUCCESS`

export const HISTORICAL_STRATEGIES_FETCH = `${STATE_KEY}/HISTORICAL_STRATEGIES_FETCH`
export const HISTORICAL_STRATEGIES_FETCH_SUCCESS = `${STATE_KEY}/HISTORICAL_STRATEGIES_FETCH_SUCCESS`

export const STRATEGY_EDIT = `${STATE_KEY}/EDIT_STRATEGY`
export const STRATEGY_EDIT_SUCCESS = `${STATE_KEY}/STRATEGY_EDIT_SUCCESS`

export const STRATEGY_UPDATE = `${STATE_KEY}/STRATEGY_UPDATE`
export const STRATEGY_UPDATE_SUCCESS = `${STATE_KEY}/STRATEGY_UPDATE_SUCCESS`

export const STRATEGY_DELETE = `${STATE_KEY}/STRATEGY_DELETE`
export const STRATEGY_DELETE_SUCCESS = `${STATE_KEY}/STRATEGY_DELETE_SUCCESS`

export const STRATEGY_CREATE = `${STATE_KEY}/STRATEGY_CREATE`
export const STRATEGY_CREATE_SUCCESS = `${STATE_KEY}/STRATEGY_CREATE_SUCCESS`

export const SET_STATUS = `${STATE_KEY}/SET_STATUS`
export const SET_ERROR = `${STATE_KEY}/SET_ERROR`
