import React, { lazy } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { Layout } from 'antd'
// import { useSelector } from 'react-redux'
import Seo from 'components/common/Seo'
// import { Beforeunload } from 'react-beforeunload'
import 'core/i18n'
import ErrorBoundary from 'components/errors/ErrorBoundary'
import { getCurrentRoute } from 'core/helpers'
import routes from 'config/routes'
// import { features } from 'config/features'

import Header from 'v2/components/Header'

import { notification } from 'antd'

// pages
import Strategies from 'pages/StrategiesPage'
import Sessions from 'pages/SessionsPage'
import SessionSetup from 'pages/SessionSetupPage'
import HistoricalSetup from 'pages/HistoricalSetupPage'
import Launch from 'pages/LaunchPage'
import OperativeSession from 'pages/OperativeSessionPage'
import Profile from 'pages/ProfilePage'
import Admin from 'pages/AdminPage'
// import { selectSessions } from 'store/sessions/selectors'
// import { selectUser } from 'store/user/selectors'
// import { hasSessionOpen } from 'services/sessionService'
import UserProvider from 'containers/UserProvider'

// Error pages

// ** Lazy pages **
// Error pages
const NotFoundPage = lazy(() => import('pages/NotFoundPage'))
const ErrorPage = lazy(() => import('pages/ErrorPage'))
const LoginPage = lazy(() => import('pages/LoginPage'))
const ResetPasswordPage = lazy(() => import('pages/ResetPasswordPage'))
const ResetPasswordRequestPage = lazy(() => import('pages/ResetPasswordRequestPage'))
const SystemInfo = lazy(() => import('pages/SystemInfoPage'))
const LabelEditor = lazy(() => import('pages/LabelEditorPage'))
const Playground = lazy(() => import('pages/PlaygroundPage'))
const PlaygroundTheme = lazy(() => import('pages/PlaygroundThemePage'))

notification.config({
  placement: 'bottomRight',
  bottom: 30,
  duration: 0,
})

const App: React.FC = () => {
  const location = useLocation()
  // const sessions = useSelector(selectSessions)
  // const user = useSelector(selectUser)

  const currentRoute = getCurrentRoute(location.pathname)
  const isPrivatePage = currentRoute?.private || false
  const isPublicPage = !isPrivatePage

  // const handleBeforeunload = useCallback((event) => {
  //   if (features.noWarningOnExit) {
  //     return
  //   }

  //   if (hasSessionOpen(user, sessions)) {
  //     //Display a message before closing browser
  //     event.preventDefault()
  //   }
  // }, [])

  // console.log('render App')

  //All public pages, not logged users
  if (isPublicPage) {
    return (
      <ErrorBoundary>
        <Seo />
        <Layout style={{ minHeight: '100vh' }}>
          <section data-augmented-ui className="app-container">
            <Layout>
              <Routes>
                {/* Login Pages */}
                <Route path={routes.login.url} element={<LoginPage />} />
                <Route path={routes.resetPasswordRequest.url} element={<ResetPasswordRequestPage />} />
                <Route path={routes.resetPassword.url} element={<ResetPasswordPage />} />
                {/* System Info */}
                <Route path={routes.systemInfo.url} element={<SystemInfo />} />
                {/* Playground */}
                <Route path={routes.playground.url} element={<Playground />} />
                <Route path={routes.playgroundTheme.url} element={<PlaygroundTheme />} />
                {/* Fallback is not found page */}
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Layout>
          </section>
        </Layout>
      </ErrorBoundary>
    )
  }

  //All private
  return (
    <ErrorBoundary>
      <Seo />

      <Layout style={{ minHeight: '100vh' }}>
        <UserProvider>
          <Header />
          {/* <Beforeunload onBeforeunload={handleBeforeunload}> */}
            <Layout>
              <Routes>
                <Route path={routes.sessions.url} element={<Sessions />} />
                <Route path={routes.sessionSetup.url} element={<SessionSetup />} />
                <Route path={routes.historicalSetup.url} element={<HistoricalSetup />} />
                <Route path={routes.strategies.url} element={<Strategies />} />
                <Route path={routes.launch.url} element={<Launch />} />
                <Route path={routes.operativeSession.url} element={<OperativeSession />} />
                <Route path={routes.profile.url} element={<Profile />} />
                <Route path={routes.admin.url} element={<Admin />} />
                <Route path={routes.labelEditor.url} element={<LabelEditor />} />
                {/* Errors Pages */}
                <Route path={routes.errorPage.url} element={<ErrorPage />} />
                {/* Fallback is not found page */}
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Layout>
          {/* </Beforeunload> */}
        </UserProvider>
      </Layout>
    </ErrorBoundary>
  )
}

export default App
