import React from 'react'
import { ExtendedDataNode, GridItemActions } from 'types/ui'

import styles from './styles.module.scss'
import { createDataTreeFromTree } from 'services/treeService'
import { Typography } from 'antd'

import CircleChartContainer from 'v2/containers/CircleChart'

import { useMeasure } from 'react-use'
import { getGridItemClass } from 'services/dashboardService'
import { useTranslation } from 'react-i18next'
import LegendPopover from './LegendPopover'
import ContextMenu from '../ContextMenu'
import * as Store from 'types/store'
import { SessionStatus, SessionType } from 'types/enums'
interface Props {
  tree: ExtendedDataNode[]
  onItemClick: (action: GridItemActions) => void
  session: Store.Session
}

const MapView: React.FC<Props> = ({ tree, onItemClick, session }) => {
  const data = createDataTreeFromTree(tree)
  const [ref, { width, height }] = useMeasure()

  const { t } = useTranslation()

  const isModelStatusChangeDisabled =
    session.type === SessionType.BackTest ||
    session.endTime ||
    session.status === SessionStatus.Broken ||
    session.status !== SessionStatus.Running

  if (data === null) {
    return null
  }

  const panelContent = (
    <div className="map-view__container nonDraggable">
      <div className="map-view__body">
        <CircleChartContainer data={data} width={width} height={height} />
      </div>
    </div>
  )

  return (
    <section className={styles['map-view']} ref={ref}>
      <div className={getGridItemClass(width, height)}>
        <div className="frame-header">
          <div className="frame-header__top-row">
            <Typography.Title level={3} className="title">
              {t('frameType.mapView')}
            </Typography.Title>
            <LegendPopover />

            <ContextMenu
              isFullscreen={false}
              onMenuItemClick={onItemClick}
              items={[
                !isModelStatusChangeDisabled && {
                  key: GridItemActions.Edit,
                  label: t('operativeSession.manageModelStatus'),
                },
                { key: GridItemActions.Close, label: t('contextMenu.close') },
              ]}
            />
          </div>
        </div>
        <div className="frame-content">{panelContent}</div>
      </div>
    </section>
  )
}

export default MapView
