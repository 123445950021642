// import proper moment translation for each new language
import 'moment/locale/it'

import { apiProvider } from 'config/api'
import { getFeatureFlags } from './featureFlags'
import { getFlowControl } from './flowControl'

const variables: Json = (typeof window !== 'undefined' && window['jaggedIsland']) || {}
const apiBaseUrl = variables.apiBaseUrl || process.env.REACT_APP_API_BASE_URL
const homepage = process.env.REACT_APP_HOMEPAGE ?? "/jagged-island"
const socketBaseUrl = variables.socketsBaseUrl || process.env.REACT_APP_SOCKETS_BASE_URL
const timeSeriesBaseUrl = variables.timeSeriesBaseUrl || process.env.REACT_APP_TIME_SERIES_BASE_URL
const language = variables.currentLanguage || process.env.REACT_APP_LOCALE
const version = process.env.REACT_APP_VERSION ?? "0.0.0."
const flowControlKeyFreq = variables.flowControlKeyFreq || process.env.REACT_APP_FLOWCONTROL_KEY_FREQ || 0
const features = variables.features || {}
const flowControlServiceFreq = variables.flowControlServiceFreq || {}

/* eslint-disable no-useless-escape */
export const featureFlags = getFeatureFlags(features)
export const flowControl = getFlowControl(flowControlServiceFreq)

export const VALIDATE_PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/g
export const EMPTY_DATE = '0001-01-01T00:00:00.000Z'
export const BUFFERS = {
  Small: 500,
  Medium: 1000,
  Large: 2000,
  XLarge: 5000,
}

const calculateGroupSeparator = (locale: string): string => {
  return Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, '')
}
const calculateDecimalSeparator = (locale: string): string => {
  return Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, '')
}

export const config = (function config() {
  const provider = apiProvider(apiBaseUrl, socketBaseUrl, timeSeriesBaseUrl)

  const socketOptions = {
    timeout: variables.sockets?.timeout || 10000,
    isAlive: variables.sockets?.isAlive || 15000,
  }

  let _groupSeparator = calculateGroupSeparator(language)
  let _decimalSeparator = calculateDecimalSeparator(language)
  let _language: string = language
  return {
    appVersion: version,
    homepage,
    socket: provider.socket,
    socketOptions: socketOptions,
    api: provider.api,
    getLanguage: () => _language,
    getDateTimeFormat: () => 'DD/MM/YYYY',
    locale: {
      getGroupSeparator: () => _groupSeparator,
      getDecimalSeparator: () => _decimalSeparator,
    },
    setLanguage: (lng: string) => {
      provider.setLanguage(lng)
      _groupSeparator = calculateGroupSeparator(lng)
      _decimalSeparator = calculateDecimalSeparator(lng)
      _language = lng
    },
    apiBaseUrl,
    socketBaseUrl,
    timeSeriesBaseUrl,
    language,
    flowControl: {
      serviceFreq: parseInt(flowControlServiceFreq),
      keyFreq: parseInt(flowControlKeyFreq),
    },
  }
})()
