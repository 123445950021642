import React, { useContext, useEffect, useState } from 'react'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
import * as Store from 'types/store'
import PanelContent from 'v2/components/frames/PortfolioCandleStick/PanelContent'
import { isApiError, isGraphQlError } from 'core'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import * as Api from 'types/api'
import * as api from 'api/historicalResources'
import {
  mapHistoricalCartesianPosition,
  mapHistoricalCartesianReturn,
  mapHistoricalOperations,
  mapHistoricalPortfolioCartesianHeadingData,
} from 'services/store/mapHistoricals'

const getCartesianPanelContentData = async (
  strategyId: number,
  sessionId: number,
): Promise<Partial<Store.PortfolioSigmas>> => {
  const response = await api.getPortfolioPanelContentData(strategyId, sessionId)
  if (isApiError(response) || isGraphQlError(response)) {
    return null
  }

  const json = response as Api.PortfolioHistoricalPanelContentDataResponse

  const cartesianOperations = json.data.historical_data_prt_cartesian_operations_v
  const cartesianPosition = json.data.historical_data_prt_cartesian_graph_indicators_v[0]
  const cartesianReturn = json.data.historical_data_prt_trad_ret_bc_v

  const cartesianOperationsList = cartesianOperations && mapHistoricalOperations(cartesianOperations)
  const position = cartesianPosition && mapHistoricalCartesianPosition(cartesianPosition)
  const returnList = cartesianReturn && mapHistoricalCartesianReturn(cartesianReturn)

  const cartesianHeading = cartesianPosition && mapHistoricalPortfolioCartesianHeadingData(cartesianPosition)
  return {
    cartesianOperationsList,
    position,
    returnList,
    cartesianHeading,
  }
}

const PanelContentContainer: React.FC = () => {
  const context = useContext(CandleStickFrameContext)
  const { resource } = context
  const portfolio = resource as Store.Portfolio
  const session = useSelector(selectOperativeSessionSession)
  const { id: sessionId } = session

  const [data, setData] = useState<Store.PortfolioSigmas>()

  const getData = async () => {
    const data = await getCartesianPanelContentData(portfolio.id, sessionId)
    if (data) {
      setData(data as Store.PortfolioSigmas)
    }
  }
  useEffect(() => {
    getData()
  }, [resource])

  return <PanelContent sigmas={data} />
}

export default PanelContentContainer
