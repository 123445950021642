import React, { useCallback, useContext, useRef } from 'react'
import ChartD3 from 'components/ChartD3'
import Line from 'components/ChartD3/Line'
import { useInterval, useMeasure } from 'react-use'
import ChartGroup from 'components/ChartD3/ChartGroup'
import colors from 'config/colors'
import ChartBackground from 'components/ChartD3/ChartBackground'
import ChartGrid from 'components/ChartD3/ChartGrid'
import YAxisRight from 'components/ChartD3/YAxisRight'
import XAxisDate from 'components/ChartD3/XAxisDate'
import { TimeFrame } from 'types/enums'
import Clipper from 'components/ChartD3/Clipper'
import { max } from 'lodash'
import * as d3 from 'd3'
import debounce from 'debounce'
import { TechnicalFrameContext } from 'contexts/TechnicalFrameContext'

const DEFAULT_MAX_PERC = 1.2
const DEBOUNCE_INTERVAL = 500

interface Props {
  ramData: [Date, number][]
  cpuData: [Date, number][]
  setTimeInterval: (interval: [Date, Date]) => void
}

const ConsumptionChart: React.FC<Props> = ({ ramData, cpuData, setTimeInterval }) => {
  const lastTransform = useRef<d3.ZoomTransform>()
  const [ref, { width, height }] = useMeasure()

  const { timeRange } = useContext(TechnicalFrameContext)

  const resolution = {
    width: width,
    height: height,
    margin: { bottom: 35, top: 10, right: 40, left: 20 },
    defaultScale: 1,
  }

  const xData = ramData.map((d) => d[0])
  const yRamData = ramData.map((d) => d[1])
  const yCpuData = cpuData.map((d) => d[1])
  const maxRamValue = Math.ceil(max(yRamData) * DEFAULT_MAX_PERC)
  const maxCpuValue = Math.ceil(max(yCpuData) * DEFAULT_MAX_PERC)

  const update = useCallback(
    debounce((from: Date, to: Date) => {
      setTimeInterval([from, to])
    }, DEBOUNCE_INTERVAL),
    [setTimeInterval],
  )

  const getDomainInDate = (transform: d3.ZoomTransform) => {
    const scale = transform.rescaleX(
      d3
        .scaleTime()
        .domain(getDateLimits())
        .range([0, resolution.width - resolution.margin.left - resolution.margin.right]),
    )

    return scale.domain()
  }

  useInterval(() => {
    if (lastTransform.current) {
      const [from, to] = getDomainInDate(lastTransform.current)
      update(from, to)
    }
  }, 5000)

  const getDateLimits = (): [Date, Date] => {
    const [startTime, endTime] = timeRange
    if (endTime) {
      if (startTime && endTime) {
        return [startTime.toDate(), endTime.toDate()]
      }

      return [
        new Date(startTime.clone().startOf('year').utc().format('YYYY-MM-DDT00:00:00')),
        endTime?.toDate(),
      ]
    }

    return [startTime.toDate(), endTime?.toDate() || new Date()]
  }

  const onZoomEnded = (transform: d3.ZoomTransform) => {
    lastTransform.current = transform
    const [from, to] = getDomainInDate(transform)
    update(from, to)
  }

  const clipId = `${width}x${height}`

  return (
    <section ref={ref} key={`consumption-chart ${lastTransform}`} className='JI-RENDER-consumption-chart'>
      <ChartD3
        height={resolution.height}
        width={resolution.width}
        defaultScale={resolution.defaultScale}
        margin={resolution.margin}
        isZoomEnabled
        onZoomEnded={onZoomEnded}
        clipId={clipId}
        xDomain={getDateLimits()}
      >
        <ChartGroup height={0.35} width={1} x={0} y={0} yDomain={[0, maxRamValue]}>
          {/* <ChartBackground color={colors.midnight} /> */}
          <ChartGrid xData={xData} yData={yRamData} />
          <YAxisRight data={yRamData || []} />
          <Line data={ramData} color={colors.red} thickness={1} />
          <XAxisDate data={xData} timeFrame={TimeFrame.TDY} />
          <g transform="translate(5,5)">
            <rect className="text" width="37" height="25" rx="1" ry="1" strokeWidth="0.2" />
            <text transform={`translate(5,16)`} fill={colors.azure}>
              Ram
            </text>
          </g>
        </ChartGroup>
        <ChartGroup
          height={0.35}
          width={1}
          x={0}
          y={0.6}
          yDomain={[0, maxCpuValue < 100 ? maxCpuValue : 100]}
        >
          {/* <ChartBackground color={colors.midnight} /> */}
          <ChartGrid xData={xData} yData={yCpuData} />
          <YAxisRight data={yCpuData || []} />
          <Line data={cpuData} color={colors.blue} thickness={1} />
          <XAxisDate data={xData} timeFrame={TimeFrame.TDY} />
          <g transform="translate(5,5)">
            <rect className="text" width="37" height="25" rx="1" ry="1" strokeWidth="0.2" />
            <text transform={`translate(5,16)`} fill={colors.azure}>
              Cpu
            </text>
          </g>
        </ChartGroup>
        <Clipper clipId={clipId} />
      </ChartD3>
    </section>
  )
}

export default ConsumptionChart
