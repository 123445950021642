import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import { ChartChild } from 'types/chart'
import * as Store from 'types/store'
import { TSInterval } from 'types/enums'
import colors from 'config/colors'
import { formatNumber, toSeconds } from 'core/formats'
import { useTranslation } from 'react-i18next'
import { clamp } from 'lodash'

const DAY = TSInterval.Day * 1000

interface Props {
  data: Store.OperationBarchart[]
  currency: string
  color?: string
  thickness?: number
}

const CUT_PERCENTAGE = 0.2

const OperationsBarChart: React.FC<Props & ChartChild> = ({
  data,
  currency,
  yDomain,
  chartWidth: width,
  chartHeight: height,
  d3Transform: transform,
  clipId,
  xDomain,
  // transitionTime,
}) => {
  const element = useRef(null)
  const x = data.map((item) => item.date)
  const { t } = useTranslation()

  const getYDomain = () => {
    if (yDomain) {
      return yDomain
    }

    const ymin = d3.min(data.map((r) => r.negOp))
    const ymax = d3.max(data.map((r) => r.posOp))
    return [ymin, ymax]
  }

  const getXDomain = () => {
    if (xDomain) {
      return xDomain
    }

    return [d3.min(x), d3.max(x)]
  }

  const generateTable = (d: Store.OperationBarchart) => {
    const { indicator } = d

    const table = `<table>
    <tbody>
      <tr>
        <td colspan="2"></td>
        <td>${t('table.column.barchart.positive')}</td>
        <td>${t('table.column.barchart.negative')}</td>
        <td>${t('table.column.barchart.total')}</td>
      </tr>
      <tr>
        <td rowspan="5">${t('table.column.long')}</td>
        <td>${t('table.column.num')}</td>
        <td>${d.lngPosOp}</td>
        <td>${d.lngNegOp}</td>
        <td>${d.lngPosOp + d.lngNegOp}</td>
      </tr>
      <tr>
        <td>${t('table.column.barchart.tdyTradingReturn', { currency: currency })}</td>
        <td>${formatNumber(indicator.tradRetLngPos)}</td>
        <td>${formatNumber(indicator.tradRetLngNeg)}</td>
        <td>${formatNumber(indicator.tradRetLngPos + indicator.tradRetLngNeg)}</td>
      </tr>
      <tr>
        <td>${t('table.column.barchart.avgDuration')}</td>
        <td>${formatNumber(toSeconds(indicator.dtPrincLngPos))}</td>
        <td>${formatNumber(toSeconds(indicator.dtPrincLngNeg))}</td>
        <td>${formatNumber(toSeconds(indicator.dtPrincLngPos + indicator.dtPrincLngNeg))}</td>
      </tr>
      <tr>
        <td>${t('table.column.barchart.invested', { currency: currency })}</td>
        <td>${formatNumber(indicator.avgPrincLngPos)}</td>
        <td>${formatNumber(indicator.avgPrincLngNeg)}</td>
        <td>${formatNumber(indicator.avgPrincLng)}</td>
      </tr>
      <tr>
        <td>${t('table.column.barchart.std')}</td>
        <td>${formatNumber(indicator.stdDevLngPos)}</td>
        <td>${formatNumber(indicator.stdDevLngNeg)}</td>
        <td>${formatNumber(indicator.stdDevLng)}</td>
      </tr>
      <tr>
        <td rowspan=" 5">${t('table.column.short')}</td>
        <td>${t('table.column.num')}</td>
        <td>${d.shrPosOp}</td>
        <td>${d.shrNegOp}</td>
        <td>${d.shrNegOp + d.shrPosOp}</td>
      </tr>
      <tr>
        <td>${t('table.column.barchart.tdyTradingReturn', { currency: currency })}</td>
        <td>${formatNumber(indicator.tradRetShrPos)}</td>
        <td>${formatNumber(indicator.tradRetShrNeg)}</td>
        <td>${formatNumber(indicator.tradRetShrPos + indicator.tradRetShrNeg)}</td>
      </tr>
      <tr>
        <td>${t('table.column.barchart.avgDuration')}</td>
        <td>${formatNumber(toSeconds(indicator.dtPrincShrPos))}</td>
        <td>${formatNumber(toSeconds(indicator.dtPrincShrNeg))}</td>
        <td>${formatNumber(toSeconds(indicator.dtPrincShrPos + indicator.dtPrincShrNeg))}</td>
      </tr>
      <tr>
        <td>${t('table.column.barchart.invested', { currency: currency })}</td>
        <td>${formatNumber(indicator.avgPrincShrPos)}</td>
        <td>${formatNumber(indicator.avgPrincShrNeg)}</td>
        <td>${formatNumber(indicator.avgPrincShr)}</td>
      </tr>
      <tr>
        <td>${t('table.column.barchart.std')}</td>
        <td>${formatNumber(indicator.stdDevShrPos)}</td>
        <td>${formatNumber(indicator.stdDevShrNeg)}</td>
        <td>${formatNumber(indicator.stdDevShr)}</td>
      </tr>
      <tr>
        <td rowspan="5">${t('table.column.barchart.total')}</td>
        <td>${t('table.column.num')}</td>
        <td>${d.posOp}</td>
        <td>${d.negOp}</td>
        <td>${d.posOp + d.negOp}</td>
      </tr>
      <tr>
        <td>${t('table.column.barchart.tdyTradingReturn', { currency: currency })}</td>
        <td>${formatNumber(indicator.tradRetLngPos + indicator.tradRetShrPos)}</td>
        <td>${formatNumber(indicator.tradRetLngNeg + indicator.tradRetShrNeg)}</td>
        <td>${formatNumber(indicator.tradRet)}</td>
      </tr>
      <tr>
        <td>${t('table.column.barchart.avgDuration')}</td>
        <td>${formatNumber(toSeconds(indicator.dtPrincLngPos + indicator.dtPrincShrPos))}</td>
        <td>${formatNumber(toSeconds(indicator.dtPrincLngNeg + indicator.dtPrincShrNeg))}</td>
        <td>${formatNumber(toSeconds(indicator.dtPrinc))}</td>
      </tr>
      <tr>
        <td>${t('table.column.barchart.invested', { currency: currency })}</td>
        <td>${formatNumber(indicator.avgPrincPos)}</td>
        <td>${formatNumber(indicator.avgPrincNeg)}</td>
        <td>${formatNumber(indicator.avgPrinc)}</td>
      </tr>
      <tr>
        <td>${t('table.column.barchart.std')}</td>
        <td>${formatNumber(indicator.stdDevPos)}</td>
        <td>${formatNumber(indicator.stdDevNeg)}</td>
        <td>${formatNumber(indicator.stdDev)}</td>
      </tr>
    </tbody>
  </table>
    `

    return table
  }

  const getDiv = () => {
    let div = d3.select('.chart-tooltip-operation-bar')
    if (div.empty()) {
      div = d3
        .select('body')
        .append('div')
        .attr('class', 'chart-tooltip-operation-bar')
        .style('z-index', 5000)
    }
    return div
  }

  const render = (dataChanged: boolean) => {
    // const transition = dataChanged ? 0 : transitionTime

    const xScale = transform.rescaleX(d3.scaleTime().domain(getXDomain()).range([0, width]))
    const yScale = d3.scaleLinear().domain(getYDomain()).range([height, 0])

    const barChartBody = d3.select(element.current)
    barChartBody.attr('clip-path', `url(#${clipId})`)

    const getDifference = () => {
      return xScale(DAY + xScale.domain()[0].getTime())
    }

    const getWidth = () => {
      return getDifference() * (1 - CUT_PERCENTAGE)
    }

    const getBarX = (d: Store.OperationBarchart) => {
      const difference = getDifference()
      return xScale(d.date.getTime() - DAY / 2) + difference * (CUT_PERCENTAGE / 2)
    }

    const getHeight = (value: number) => yScale(0) - yScale(value)

    //#region posetiveShorts
    const posetiveShorts = barChartBody.selectAll('.positive-shorts').data(data)

    posetiveShorts
      // .transition()
      // .duration(transition)
      // .ease(d3.easeExpOut)
      .attr('x', getBarX)
      .attr('y', (d) => yScale(d.posOp))
      .attr('width', getWidth)
      .attr('height', (d) => getHeight(d.shrPosOp))

    posetiveShorts.exit().remove()

    posetiveShorts
      .enter()
      .append('rect')
      .attr('class', 'positive-shorts')
      .attr('x', getBarX)
      .attr('y', (d) => yScale(d.posOp))
      .attr('width', getWidth)
      .attr('height', (d) => getHeight(d.shrPosOp))

    //#endregion
    //#region negativeShorts
    const negativeShorts = barChartBody.selectAll('.negative-shorts').data(data)
    negativeShorts
      // .transition()
      // .duration(transition)
      // .ease(d3.easeExpOut)
      .attr('x', getBarX)
      .attr('y', (d) => getHeight(d.lngNegOp) + yScale(0))
      .attr('width', getWidth)
      .attr('height', (d) => getHeight(d.shrNegOp))

    negativeShorts.exit().remove()

    negativeShorts
      .enter()
      .append('rect')
      .attr('class', 'negative-shorts')
      .attr('x', getBarX)
      .attr('y', (d) => getHeight(d.lngNegOp) + yScale(0))
      .attr('width', getWidth)
      .attr('height', (d) => getHeight(d.shrNegOp))
    //#endregion
    //#region posetiveLong
    const posetiveLongs = barChartBody.selectAll('.positive-longs').data(data)
    posetiveLongs
      // .transition()
      // .duration(transition)
      // .ease(d3.easeExpOut)
      .attr('x', getBarX)
      .attr('y', (d) => yScale(d.lngPosOp))
      .attr('width', getWidth)
      .attr('height', (d) => getHeight(d.lngPosOp))

    posetiveLongs.exit().remove()

    posetiveLongs
      .enter()
      .append('rect')
      .attr('class', 'positive-longs')
      .attr('x', getBarX)
      .attr('y', (d) => yScale(d.lngPosOp))
      .attr('width', getWidth)
      .attr('height', (d) => getHeight(d.lngPosOp))
    //#endregion
    //#region negativeLongs
    const negativeLongs = barChartBody.selectAll('.negative-longs').data(data)
    negativeLongs
      // .transition()
      // .duration(transition)
      // .ease(d3.easeExpOut)
      .attr('x', getBarX)
      .attr('y', () => yScale(0))
      .attr('width', getWidth)
      .attr('height', (d) => getHeight(d.lngNegOp))

    negativeLongs.exit().remove()

    negativeLongs
      .enter()
      .append('rect')
      .attr('class', 'negative-longs')
      .attr('x', getBarX)
      .attr('y', () => yScale(0))
      .attr('width', getWidth)
      .attr('height', (d) => getHeight(d.lngNegOp))
    //#endregion

    const operations = barChartBody.selectAll('.connected-bars').data(data)
    operations
      // .transition()
      // .duration(transition)
      // .ease(d3.easeExpOut)
      .attr('x', getBarX)
      .attr('y', (d) => yScale(d.posOp))
      .attr('width', getWidth)
      .attr('fill', () => '#000000'.concat('00'))
      .attr(
        'height',
        (d) => getHeight(d.lngNegOp) + getHeight(d.lngPosOp) + getHeight(d.shrNegOp) + getHeight(d.shrPosOp),
      )

    operations.exit().remove()

    operations
      .enter()
      .append('rect')
      .attr('class', 'connected-bars')
      .attr('x', getBarX)
      .attr('y', (d) => yScale(d.posOp))
      .attr('width', getWidth)
      .attr(
        'height',
        (d) => getHeight(d.lngNegOp) + getHeight(d.lngPosOp) + getHeight(d.shrNegOp) + getHeight(d.shrPosOp),
      )
      .attr('fill', () => '#000000'.concat('00'))
      .on('mousemove', function (event, d) {
        if (d.indicator) {
          const div = getDiv()
          div.classed('active', true)
          div
            .html(generateTable(d))
            .style('left', () => {
              const width = Number((div.node() as HTMLElement).getBoundingClientRect().width)
              if (event.pageX + width > window.screen.width) {
                return `${event.pageX - width}px`
              }
              return event.pageX + 'px'
            })
            .style('top', () => {
              const height = Number((div.node() as HTMLElement).getBoundingClientRect().height)
              const offsetTop = 15

              return clamp(event.pageY - height, offsetTop, window.screen.height) + 'px'
            })
        }
      })
      .on('mouseout', function () {
        const div = getDiv()
        div.classed('active', false)
      })
  }

  // useEffect(() => {
  //   render(true)
  // }, [data])

  // useEffect(() => {
  //   render(false)
  // }, [transform, yDomain])

  useEffect(() => {
    render(true)
  }, [data])

  useEffect(() => {
    return () => {
      getDiv().remove()
    }
  }, [])

  return (
    <>
      <g ref={element}></g>
      {data.length > 0 && (
        <line stroke={colors.white} strokeWidth={1} x1={0} x2={width} y1={height / 2} y2={height / 2} />
      )}
    </>
  )
}

export default React.memo(OperationsBarChart, () => {
  const hidden = document.visibilityState !== 'visible'
  return hidden
})
