import React, { useEffect, useMemo, useState } from 'react'

import * as Store from 'types/store'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import ChartGroup from 'components/ChartD3/ChartGroup'
// import ChartBackground from 'components/ChartD3/ChartBackground'
// import ChartGrid from 'components/ChartD3/ChartGrid'
import OperationsBarChart from 'components/ChartD3/OperationsBarChart'
// import Clipper from 'components/ChartD3/Clipper'
// import YAxisRight from 'components/ChartD3/YAxisRight'
// import colors from 'config/colors'
import { ChartChild } from 'types/chart'
import * as api from 'api/candleStick'
import { BUFFERS, isApiError } from 'core'
import { jsonToOperationsBarchart, jsonToOperationsBarchartIndicators } from 'services/store/mapTimeSeries'
import * as Api from 'types/api'
// import { colorizer } from 'components/ChartD3/common'
import { useAssetSigmaSnapshotState } from 'hooks'
import sigmaServices from 'config/sigmaServices'
import { AssetFeed, Feeds, Sigma } from 'types/enums.api'
import moment from 'moment'
import * as d3 from 'd3'

const { Operations, Return } = sigmaServices.Assets

const getBarchartIndicatorsData = async (
  sigma: Sigma,
  feed: Feeds,
  assetId: number,
  sessionId: number,
  startIso: Date,
  endIso: Date,
) => {
  const response = await api.getDataPoints(assetId, sigma, feed, null, startIso, endIso, sessionId)
  if (isApiError(response)) {
    return null
  }
  return jsonToOperationsBarchartIndicators(response as Api.OperationBarsChartIndicatorsResponse)
}

const getBarchartData = async (
  sigma: Sigma,
  feed: Feeds,
  assetId: number,
  sessionId: number,
  startIso: Date,
  endIso: Date,
) => {
  const response = await api.getDataPoints(assetId, sigma, feed, null, startIso, endIso, sessionId)
  if (isApiError(response)) {
    return null
  }
  return jsonToOperationsBarchart(response as Api.OperationBarsChartResponse)
}

interface Props {
  asset: Store.Asset
}

const BarchartContainer: React.FC<Props & ChartChild> = ({
  asset,
  chartHeight,
  chartWidth,
  clipId,
  d3Transform,
  transitionTime,
  xDomain,
  yDomain,
}) => {
  const { id: sessionId } = useSelector(selectOperativeSessionSession)
  const [barCharts, setBarCharts] = useState<Store.OperationBarchart[]>([])
  const [indicators, setIndicators] = useState<Store.OperationBarchartIndicator[]>([])

  const assets = useMemo(() => [asset], [asset])

  const dates = useMemo(
    () => d3Transform.rescaleX(d3.scaleTime().domain(xDomain).range([0, chartWidth])),
    [],
    // [d3Transform],
  )

  const [{ [asset.id]: liveData1 }] = useAssetSigmaSnapshotState(
    assets,
    sessionId,
    [Operations.AssetNumTdyOp],
    AssetFeed.Operations,
    BUFFERS.XLarge,
  )
  const [{ [asset.id]: liveData2 }] = useAssetSigmaSnapshotState(
    assets,
    sessionId,
    [Return.AssetTdyOpIndicators],
    AssetFeed.Return,
    BUFFERS.XLarge,
  )

  const liveData = { ...liveData1, ...liveData2 }

  const getIndicatorData = async () => {
    const [start, end] = dates.domain()

    const data = await getBarchartIndicatorsData(
      Return.AssetTdyOpIndicators,
      AssetFeed.Return,
      asset.id,
      sessionId,
      start,
      end,
    )

    if (data) {
      setIndicators(data)
    }
  }

  const getTimeseriesData = async () => {
    const [start, end] = dates.domain()
    const data = await getBarchartData(
      Operations.AssetNumTdyOp,
      AssetFeed.Operations,
      asset.id,
      sessionId,
      start,
      end,
    )

    if (data) {
      // console.log('ts data', data)
      setBarCharts(data)
    }
  }

  useEffect(() => {
    getTimeseriesData()
    getIndicatorData()

    return () => {
      // TODO: check for subscriptions to close
      // on component unmount
    }
  }, [dates])

  const chartChildProps: ChartChild = {
    chartHeight,
    chartWidth,
    clipId,
    d3Transform,
    transitionTime,
    xDomain,
    yDomain,
  }

  const data = liveData?.assetNumTdyOp ? [...barCharts, liveData.assetNumTdyOp] : barCharts

  const YDomainBars = (): number[] => {
    let max = data.reduce((largest, { negOp, posOp }) => {
      const larger = negOp > posOp ? negOp : posOp

      return larger > largest ? larger : largest
    }, 0)

    max *= 1.25

    return [-max, max]
  }

  const combinedData: Store.OperationBarchart[] = data.map((item) => {
    // This is running in loop with socket data
    let indicator
    if (item.sequence === null) {
      indicator = liveData?.assetTdyOpIndicators
    } else {
      indicator = indicators.find((indicator) => moment(indicator.date).isSame(item.date, 'date'))
    }
    return {
      ...item,
      indicator,
    }
  })

  return (
    <ChartGroup {...chartChildProps} height={0.18} width={1} x={0} y={0.81} yDomain={YDomainBars()}>
      {/* <ChartBackground color={colors.midnight} />
      <ChartGrid xData={[]} yData={[]} /> */}
      <OperationsBarChart data={combinedData} currency={asset.currency} />
      {/* <Clipper />
      <YAxisRight data={[]} tickFormat={(value) => Math.abs(value).toString()} colorizer={colorizer} /> */}
    </ChartGroup>
  )
}

export default BarchartContainer
