import { all } from 'redux-saga/effects'
import strategies from 'store/resources/strategies/sagas'
import portfolios from 'store/resources/portfolios/sagas'
import assets from 'store/resources/assets/sagas'
import groups from 'store/resources/groups/sagas'
import historicalStrategies from 'store/resources/strategies/helpers.historical'
import historicalPortfolios from 'store/resources/portfolios/helpers.historical'
import historicalGroupedAssets from 'store/resources/assets/helpers.historical'
import layouts from 'store/layouts/sagas'
import sessions from 'store/sessions/sagas'
import operativeSessionPage from 'store/pages/operative-session/sagas'
import setupSessionPage from 'store/pages/setup-session/sagas'
import adminPage from 'store/pages/admin/sagas'
import strategyPage from 'store/pages/strategy/sagas'
import app from 'store/App/sagas'
import user from 'store/user/sagas'
import dashboard from 'store/dashboard/sagas'
import enabledSessionsTypes from 'store/platform/sagas'

export default function* rootSaga() {
  yield all([
    strategies(),
    portfolios(),
    assets(),
    groups(),
    app(),
    sessions(),
    enabledSessionsTypes(),
    layouts(),
    operativeSessionPage(),
    setupSessionPage(),
    historicalGroupedAssets(),
    historicalStrategies(),
    historicalPortfolios(),
    strategyPage(),
    adminPage(),
    user(),
    dashboard(),
  ])
}
