declare let screen, window

const HEIGHT_4K = 1400
const HEIGHT_FULL_HD = 1040
const MAX_WIDTH = 2485

const ROW_HEIGHTS_WIDE = [0, 0, 720, 520, 380]

const ROW_HEIGHTS_4K = [0, 0, 720, 520, 380]
const ROW_HEIGHTS_FULL_HD = [0, 0, 540, 400, 300]
const ROW_HEIGHTS_LOW_RES = [0, 0, 420, 310, 230]

const TABLE_HEIGHTS_WIDE = [0, 0, 424, 309, 181]

const TABLE_HEIGHTS_4K = [0, 0, 424, 309, 181]
const TABLE_HEIGHTS_FULL_HD = [0, 0, 216, 157, 101]
const TABLE_HEIGHTS_LOW_RES = [0, 0, 97, 67, 31]

import Debug from 'debug'
const debug = new Debug('Frontend')
export const getRowHeights = () => {
  const { height } = screen
  const width = window.innerWidth

  debug('SCREEN', screen)

  if (width > MAX_WIDTH) {
    return ROW_HEIGHTS_WIDE
  }

  if (height < HEIGHT_FULL_HD) {
    //fallback
    return ROW_HEIGHTS_LOW_RES
  }

  if (height < HEIGHT_4K) {
    return ROW_HEIGHTS_FULL_HD
  }

  return ROW_HEIGHTS_4K
}

export const getTableHeights = () => {
  const { height } = screen
  const width = window.innerWidth

  if (width > MAX_WIDTH) {
    return TABLE_HEIGHTS_WIDE
  }

  if (height < HEIGHT_FULL_HD) {
    //fallback
    return TABLE_HEIGHTS_LOW_RES
  }

  if (height < HEIGHT_4K) {
    return TABLE_HEIGHTS_FULL_HD
  }

  return TABLE_HEIGHTS_4K
}
