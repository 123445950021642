import React, { useEffect, useState } from 'react'
import * as Api from 'types/api'
import * as Store from 'types/store'
import * as api from 'api/assets'
import { jsonToModelClass } from 'services/store/mapService'
import { FormInstance } from 'antd/lib/form'
import { isApiError } from 'core'
import { MultiModelForm } from 'components/StrategyViewer/forms/AssetForm/MultiModelForm'

const getMultiModelClasses = async (): Promise<Store.ModelClass[]> => {
  const response = await api.getMultiModelClasses()
  if (isApiError(response)) {
    return null
  }

  const masterSecurityResponse = response as Api.ModelClassResponse

  const modelClasses = jsonToModelClass(masterSecurityResponse.data.application_model_class)

  return modelClasses
}

interface Props {
  form: FormInstance
  formRef?: any
  setModelHasChanged: React.Dispatch<React.SetStateAction<boolean>>
}

export const MultiModelFormContainer: React.FC<Props> = ({ form, formRef, setModelHasChanged }) => {
  const [multiModelsList, setMultiModelsList] = useState<Store.ModelClass[]>([])
  const [modelClass, setModelClass] = useState<Store.ModelClass>(null)

  const getData = async () => {
    const multiModelsList = await getMultiModelClasses()
    
    const modelId = form.getFieldValue('modelId')
    
    setMultiModelsList(multiModelsList)
    setModelClass(multiModelsList.find(model => model.id === modelId))
    formRef && formRef.current &&
    formRef.current.setFieldsValue({modelId: modelId})
  }

  const onSelect = (id: number, modelClasses: Store.ModelClass[]) => {
    if (id && modelClasses) {
      const selected = modelClasses.find((item) => item.id === id)
      const prevModelId = formRef.current.getFieldValue('modelId')

      if (prevModelId !== selected.id) {
        setModelHasChanged(true)
      }

      setModelClass(selected)

      formRef.current.setFieldsValue({modelId: selected.id})
    } else {
      formRef.current.setFieldsValue({})
      setModelClass(null)
    }
  }

  
  useEffect(() => {
    getData()
  }, [])

  return (
    <MultiModelForm
      data={multiModelsList}
      modelClass={modelClass}
      onSelect={(id: number) => onSelect(id, multiModelsList)}
    />
  )
}
