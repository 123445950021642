import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import { ChartChild } from 'types/chart'
import * as Store from 'types/store'
import { TimeFrame, TSInterval } from 'types/enums'

const DAY = TSInterval.Day * 1000

interface Props {
  data: Store.CandleStick[]
  color?: string
  thickness?: number
  timeFrame?: TimeFrame
}

const CUT_PERCENTAGE = 0.2

const CandleStickChart: React.FC<Props & ChartChild> = ({
  data,
  yDomain,
  chartWidth: width,
  chartHeight: height,
  d3Transform: transform,
  clipId,
  xDomain,
  // transitionTime,
  timeFrame,
}) => {
  const candleChartEl = useRef(null)
  const x = data.map((item) => item.startTime)

  const getYDomain = () => {
    if (yDomain) {
      return yDomain
    }

    const ymin = d3.min(data.map((r) => r.low))
    const ymax = d3.max(data.map((r) => r.high))
    return [ymin, ymax]
  }

  const getXDomain = () => {
    if (xDomain) {
      return xDomain
    }

    return [d3.min(x), d3.max(x)]
  }
  const render = (dataChanged: boolean) => {
    // const transition = dataChanged ? 0 : transitionTime

    const xScale = transform.rescaleX(d3.scaleTime().domain(getXDomain()).range([0, width]))
    const yScale = d3.scaleLinear().domain(getYDomain()).range([height, 0])

    const candleChartBody = d3.select(candleChartEl.current)
    candleChartBody.attr('clip-path', `url(#${clipId})`)
    candleChartBody.attr('class', 'd3-candles-chart')

    const candles = candleChartBody.selectAll('.candle').data(data)

    const getDifference = (d: Store.CandleStick) => {
      let difference = d.endTime.getTime() - d.startTime.getTime()
      if (timeFrame === TimeFrame.YTD || timeFrame === TimeFrame.TOT) {
        difference = DAY
      }
      return xScale(difference + xScale.domain()[0].getTime())
    }

    const getWidth = (d: Store.CandleStick) => {
      return getDifference(d) * (1 - CUT_PERCENTAGE)
    }

    const getStemX = (d: Store.CandleStick) => {
      if (timeFrame === TimeFrame.YTD || timeFrame === TimeFrame.TOT) {
        return xScale(d.startTime)
      }
      return xScale(new Date((d.startTime.getTime() + d.endTime.getTime()) / 2))
    }

    const stems = candleChartBody.selectAll('.stem').data(data)

    // stems
      // .transition()
      // .duration(transition)
      // .ease(d3.easeExpOut)
      // .attr('x1', getStemX)
      // .attr('x2', getStemX)
      // .attr('y1', (d) => yScale(d.high))
      // .attr('y2', (d) => yScale(d.low))
      // .attr('stroke', (d) => d.color)

    // stems.exit().remove()

    stems
      // .enter()
      // .append('line')
      .join('line')
      .attr('class', 'stem')
      .attr('stroke', (d) => d.color)

      .attr('x1', getStemX)
      .attr('x2', getStemX)
      .attr('y1', (d) => yScale(d.high))
      .attr('y2', (d) => yScale(d.low))

    const getCandleX = (d: Store.CandleStick) => {
      const difference = getDifference(d)
      if (timeFrame === TimeFrame.YTD || timeFrame === TimeFrame.TOT) {
        return xScale(d.startTime.getTime() - DAY / 2) + difference * (CUT_PERCENTAGE / 2)
      }
      return xScale(d.startTime) + difference * (CUT_PERCENTAGE / 2)
    }

    // candles
    //   // .transition()
    //   // .duration(transition)
    //   // .ease(d3.easeExpOut)
    //   .attr('x', getCandleX)
    //   .attr('y', (d) => yScale(Math.max(d.open, d.close)))
    //   .attr('width', getWidth)
    //   .attr('height', (d) =>
    //     d.open === d.close ? 1 : yScale(Math.min(d.open, d.close)) - yScale(Math.max(d.open, d.close)),
    //   )
    //   // .attr('stroke', colors.candleWhite)
    //   // .attr('stroke-width', '2')
    //   .attr('stroke', (d) => d.color)

    // candles.exit().remove()

    candles
      // .enter()
      // .append('rect')
      .join('rect')
      .attr('fill', (d) => d.color)
      .attr('class', 'candle')
      .attr('x', getCandleX)
      .attr('y', (d) => yScale(Math.max(d.open, d.close)))
      .attr('width', getWidth)
      .attr('height', (d) =>
        d.open === d.close ? 1 : yScale(Math.min(d.open, d.close)) - yScale(Math.max(d.open, d.close)),
      )
  }

  useEffect(() => {
    render(true)
  }, [data, transform])

  // useEffect(() => {
  //   render(false)
  // }, [transform, yDomain])

  return <g ref={candleChartEl} />
}

export default CandleStickChart
