import * as Api from 'types/api'
import React, { useState } from 'react'
import * as api from 'api/assets'
import { isApiError } from 'core'
import { FormInstance } from 'antd/lib/form'
import DescriptionField from 'components/StrategyViewer/forms/AssetForm/DescriptionField'
import * as Store from 'types/store'

const getDescriptionRequest = async (
  symbol: string,
  mic: string,
  currency,
): Promise<Store.MasterSecurity> => {
  const response = await api.getDescription(mic, symbol, currency)

  if (isApiError(response)) {
    return null
  }
  const successResponse = response as Api.MasterSecurityResponse
  if (successResponse.data.application_master_security.length === 0) {
    return null
  }

  const data = successResponse.data.application_master_security[0]

  return {
    contractSize: data.contract_size,
    description: data.description,
    instrumentClass: data.e_instrument_class.description,
    lotMultiplier: data.lot_multiplier,
  }
}
interface Props {
  form: FormInstance
}

const DescriptionFieldContainer: React.FC<Props> = ({ form }) => {
  const [descField, setDescField] = useState('')

  const getDescription = async (symbol: string, mic: string, currency: string) => {
    const description = await getDescriptionRequest(symbol, mic, currency)
    if (description) {
      form.setFieldsValue(description)
      setDescField(form.getFieldValue('description'))
    }
  }

  return <DescriptionField getDescription={getDescription} descriptionFieldValue={descField} />
}

export default DescriptionFieldContainer
