export const STATE_KEY = 'portfolios'

export const PORTFOLIOS_FETCH_SUCCESS = `${STATE_KEY}/PORTFOLIOS_FETCH_SUCCESS`

export const PORTFOLIO_UPDATE = `${STATE_KEY}/PORTFOLIO_UPDATE`
export const PORTFOLIO_UPDATE_SUCCESS = `${STATE_KEY}/PORTFOLIO_UPDATE_SUCCESS`

export const PORTFOLIO_CREATE = `${STATE_KEY}/PORTFOLIO_CREATE`
export const PORTFOLIO_CREATE_SUCCESS = `${STATE_KEY}/PORTFOLIO_CREATE_SUCCESS`

export const PORTFOLIO_DELETE = `${STATE_KEY}/PORTFOLIO_DELETE`
export const PORTFOLIO_DELETE_SUCCESS = `${STATE_KEY}/PORTFOLIO_DELETE_SUCCESS`

export const HISTORICAL_PORTFOLIOS_FETCH = `${STATE_KEY}/PORTFOLIOS_FETCH`
export const HISTORICAL_PORTFOLIOS_FETCH_SUCCESS = `${STATE_KEY}/PORTFOLIOS_FETCH_SUCCESS`

export const SET_STATUS = `${STATE_KEY}/SET_STATUS`
export const SET_ERROR = `${STATE_KEY}/SET_ERROR`
