import { config } from 'core'
import axios, { AxiosResponse } from 'axios'

// export const isAliveFake = async () => {
//   return Promise.resolve('MyReturnValue')
// }

// This is used to check that the api server is alive, before starting the app
// this endpoint should not require the Authorization header
export const isAlive = async (): Promise<AxiosResponse<any>> => {
  const graphqlHeader = {
    headers: {
      'Content-Type': 'application/json',
      'x-hasura-admin-secret': process.env.REACT_APP_GRAPHQL_SECRET,
    },
  }

  const query = `query IsAlive {
    config_fe_text_resources(limit: 1) {
      id
    }
  }
  `

  return axios.post(config.api.getGraphQl(), { query: query }, graphqlHeader)
}
