import React from 'react'
import styles from './forward-analysis.module.scss'
import HistogramWithNormalDistribution from 'containers/charts/HistogramWithNormalDistribution'
import { Table, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { GridItemActions } from 'types/ui'
import { getGridItemClass } from 'services/dashboardService'
import ContextMenu from '../ContextMenu'
import { useMeasure } from 'react-use'

interface Props {
  data: Json[]
  onContextMenuItemClick: (action: GridItemActions) => void
}

const ForwardAnalysis: React.FC<Props> = ({ data, onContextMenuItemClick }) => {
  const { t } = useTranslation()
  const [ref, { width, height }] = useMeasure()
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
  ]

  return (
    <section ref={ref}>
      <div className={getGridItemClass(width, height)}>
        <div className="frame-header">
          <div className="frame-header__top-row">
            <Typography.Title level={3} className="title">
              {t('frameType.forwardAnalysis')}
            </Typography.Title>
            <ContextMenu
              isFullscreen={false}
              onMenuItemClick={onContextMenuItemClick}
              items={[{ key: GridItemActions.Close, label: t('contextMenu.close') }]}
            />
          </div>
        </div>
        <div className={styles['container']}>
          <HistogramWithNormalDistribution />
          <Table
            size="middle"
            columns={columns}
            dataSource={data}
            showHeader={false}
            pagination={false}
            className={styles['table']}
          />
        </div>
      </div>
    </section>
  )
}

export default ForwardAnalysis
