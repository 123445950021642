import React from 'react'
import { ViewType } from 'types/ui'

import { Radio } from 'antd'
import { useTranslation } from 'react-i18next'

interface Props {
  value: ViewType
  onChange: (view: ViewType) => void
}

const ViewTypeSelector: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation()

  return (
    <div className="viewtype-selector nonDraggable">
      <Radio.Group
        onChange={(e) => {
          onChange(e.target.value)
        }}
        value={value}
      >
        <Radio.Button className="radio-details-view" value={ViewType.Details}>
          {t('operativeSession.buttonData')}
        </Radio.Button>
        <Radio.Button className="radio-candlestick-view" value={ViewType.Graph}>
          {t('operativeSession.buttonChart')}
        </Radio.Button>
      </Radio.Group>
    </div>
  )
}

export default ViewTypeSelector
