import React, { useContext, useEffect, useState } from 'react'
import * as Store from 'types/store'
import { useSelector } from 'react-redux'
import DetailsView from 'v2/components/frames/AssetCandleStick/DetailsView'
import { selectPortfolios, selectStrategies } from 'store/resources/selectors'
import { isApiError, isGraphQlError } from 'core'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import * as Api from 'types/api'
import * as api from 'api/historicalResources'
import {
  mapHistoricalAssetCartesianLastReturn,
  mapHistoricalAssetCartesianOperations,
  mapHistoricalAssetSecurityBidAsk,
  mapHistoricalAssetSecurityTrade,
  mapHistoricalJITrade,
  mapHistoricalOpenOperation,
} from 'services/store/mapHistoricals'

type DetailsViewData = Partial<Store.AssetSigmas>

const getDetailsViewData = async (assetId: number, sessionId: number): Promise<DetailsViewData> => {
  const response = await api.getDetailsViewData(assetId, sessionId)
  if (isApiError(response) || isGraphQlError(response)) {
    return null
  }

  const json = response as Api.HistoricalDetailsViewDataResponse

  const indicators = json.data.historical_data_asset_cartesian_graph_indicators_v[0]
  const openOperation = json.data.historical_data_open_operation_v[0]
  const lastReturnList = json.data.historical_data_asset_trad_ret_v
  const operationsList = json.data.historical_data_asset_cartesian_operations_v

  const data: DetailsViewData = {
    securityTrade: indicators && mapHistoricalAssetSecurityTrade(indicators),
    securityBidAsk: indicators && mapHistoricalAssetSecurityBidAsk(indicators),
    jiTrade: indicators && mapHistoricalJITrade(indicators),
    openOperation: openOperation && mapHistoricalOpenOperation(openOperation),
    assetCartesianLastReturnList: mapHistoricalAssetCartesianLastReturn(lastReturnList),
    assetCartesianOperationsList: mapHistoricalAssetCartesianOperations(operationsList),
  }

  return data
}

const DetailsViewContainer: React.FC = () => {
  const context = useContext(CandleStickFrameContext)
  const { resource } = context
  const asset = resource as Store.Asset
  const portfolios = useSelector(selectPortfolios)
  const strategies = useSelector(selectStrategies)
  const portfolio = portfolios.find((portfolio) => portfolio.id === asset.portfolioId)
  const strategy = strategies.find((strategy) => strategy.id === portfolio.strategyId)
  const session = useSelector(selectOperativeSessionSession)
  const [sigmas, setSigmas] = useState<Store.AssetSigmas>()

  useEffect(() => {
    const getData = async () => {
      const data = await getDetailsViewData(asset.id, session.id)
      if (data) {
        setSigmas(data as Store.AssetSigmas)
      }
    }
    getData()
  }, [asset.id])

  return <DetailsView key={`left-frame`} sigmas={sigmas} strategy={strategy} portfolio={portfolio} />
}

export default DetailsViewContainer
