import React, { useState } from 'react'
import * as Api from 'types/api'
import * as api from 'api/assets'
import CurrencyField from 'components/StrategyViewer/forms/AssetForm/CurrencyField'
import { isApiError } from 'core'
import { CrudOperation } from 'types/enums'
import { FormInstance } from 'antd'

const getCurrenciesRequest = async (symbol: string, mic: string) => {
  const response = await api.getCurrencies(mic, symbol)

  if (isApiError(response)) {
    return null
  }
  const successResponse = response as Api.MasterSecurityResponse
  const currencies = successResponse.data.application_master_security.map((item) => item.trading_currency)

  return currencies
}
interface Props {
  mode: CrudOperation
  form: FormInstance
}

const CurrencyFieldContainer: React.FC<Props> = ({ mode, form }) => {
  const [currencies, setCurrencies] = useState<string[]>([])

  const getCurrencies = async (symbol: string, mic: string) => {
    const masterSecurities = await getCurrenciesRequest(symbol, mic)
    if (masterSecurities) {
      setCurrencies(masterSecurities)
    }
    if (masterSecurities.length === 1) {
      form.setFieldsValue({
        currency: masterSecurities[0],
      })
    }
  }

  return <CurrencyField data={currencies} getCurrencies={getCurrencies} mode={mode} />
}

export default CurrencyFieldContainer
