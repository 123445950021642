export const STATE_KEY = 'app'

export const APP_BOOTSTRAP = `${STATE_KEY}/BOOTSTRAP`
export const APP_BOOTSTRAP_LOADED = `${STATE_KEY}/BOOTSTRAP_LOADED`

export const FETCH_RESOURCES = `${STATE_KEY}/FETCH_RESOURCES`
export const FETCH_HISTORICAL_RESOURCES = `${STATE_KEY}/FETCH_HISTORICAL_PAGE`

export const SET_SOCKET_ALIVE = `${STATE_KEY}/SET_SOCKET_ALIVE`
export const LOGOUT = `${STATE_KEY}/LOGOUT` as const
export const APP_SAVE_USER = `${STATE_KEY}/SAVE_USER`
export const SET_LOADING_STATE = `${STATE_KEY}/SET_LOADING_STATE`
