import { update_snapshot, update_ticker } from 'sockets/assets/asset_security_data_pb'
import * as Store from 'types/store'
import { convertToFloat, formatSigmaDate } from '../common'
import colors from 'config/colors'

export const mapAssetSecurityDataSigmas = (
  feed: update_ticker.AsObject | update_snapshot.AsObject,
): Store.AssetSigmas => {
  const { sigmas, key } = feed as update_ticker.AsObject

  if (!sigmas) {
    return null
  }

  const {
    assetClosedCandle,
    assetOpenCandle,
    assetRefData,
    assetSecurityBidAsk,
    assetSecurityRefData,
    assetSecurityTrade,
    jiTrade,
  } = sigmas

  return {
    securityTrade: assetSecurityTrade && {
      price: convertToFloat(assetSecurityTrade?.price),
      size: convertToFloat(assetSecurityTrade?.size),
      time: formatSigmaDate((assetSecurityTrade as any)?.tradeTs),
    },
    securityBidAsk: assetSecurityBidAsk && {
      askPrice: convertToFloat(assetSecurityBidAsk?.askPrice),
      askTotalSize: convertToFloat(assetSecurityBidAsk?.askTotalSize),
      bidPrice: convertToFloat(assetSecurityBidAsk?.bidPrice),
      bidTotalSize: convertToFloat(assetSecurityBidAsk?.bidTotalSize),
    },
    jiTrade: jiTrade && {
      price: convertToFloat(jiTrade?.price),
      quantity: convertToFloat(jiTrade?.quantity) || 0,
      sign: jiTrade?.sign,
      time: formatSigmaDate(jiTrade?.time),
    },
    assetClosedCandle: assetClosedCandle && {
      close: convertToFloat(assetClosedCandle.close),
      open: convertToFloat(assetClosedCandle.open),
      date: formatSigmaDate(assetClosedCandle.startTs),
      high: convertToFloat(assetClosedCandle.high),
      low: convertToFloat(assetClosedCandle.low),
      startTime: formatSigmaDate(assetClosedCandle.startTs),
      endTime: formatSigmaDate(assetClosedCandle.endTs),
      color:
        convertToFloat(assetClosedCandle.open) === convertToFloat(assetClosedCandle.close)
          ? 'silver'
          : convertToFloat(assetClosedCandle.open) > convertToFloat(assetClosedCandle.close)
          ? colors.candleBlack
          : colors.candleWhite,
    },
    assetOpenCandle: assetOpenCandle && {
      close: convertToFloat(assetOpenCandle.close),
      open: convertToFloat(assetOpenCandle.open),
      date: formatSigmaDate(assetOpenCandle.startTs),
      high: convertToFloat(assetOpenCandle.high),
      low: convertToFloat(assetOpenCandle.low),
      startTime: formatSigmaDate(assetOpenCandle.startTs),
      endTime: formatSigmaDate(assetOpenCandle.endTs),
      color:
        convertToFloat(assetOpenCandle.open) === convertToFloat(assetOpenCandle.close)
          ? 'silver'
          : convertToFloat(assetOpenCandle.open) > convertToFloat(assetOpenCandle.close)
          ? colors.candleBlack
          : colors.candleWhite,
    },
    assetRefData: assetRefData && {
      assetName: assetRefData.assetName,
      baseCurrency: assetRefData.baseCurrency,
      portfolioName: assetRefData.portfolioName,
      strategyName: assetRefData.strategyName,
      strategyTarget: assetRefData.strategyTarget,
    },
    securityRefData: assetSecurityRefData && {
      key: parseInt(key),
      description: assetSecurityRefData.description,
      instrumentClass: assetSecurityRefData.instrumentClass,
      instrumentCode: assetSecurityRefData.instrumentCode,
      instrumentIsin: assetSecurityRefData.instrumentIsin,
      marketCode: assetSecurityRefData.marketCode,
      symbol: assetSecurityRefData.symbol,
      tradingCurrency: assetSecurityRefData.tradingCurrency,
    },
  } as Store.AssetSigmas
}
