import * as Api from 'types/api'
import * as Store from 'types/store'
import { getAxios } from './apiService'
import { config } from 'core'
import { getApiError, getAuthenticationHeader } from './common'

const axios = getAxios()

export const getUsers = async (): Promise<Api.UsersResponse | ApiError> => {
  const header = await getAuthenticationHeader()
  return axios
    .get(config.api.getUsers(), header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export const createUser = async (data: Store.SystemUserForm): Promise<Api.CreateUserResponse | ApiError> => {
  const header = await getAuthenticationHeader()
  return axios
    .post(config.api.createUser(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export const editUser = async (
  data: Store.SystemUserForm,
  id: number,
): Promise<Api.RESTResponse | ApiError> => {
  const header = await getAuthenticationHeader()
  return axios
    .put(config.api.user(id), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export const deleteUser = async (id: number): Promise<Api.RESTResponse | ApiError> => {
  const header = await getAuthenticationHeader()
  return axios
    .delete(config.api.user(id), header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}
