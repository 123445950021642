import React, { useContext } from 'react'
import { Button, Dropdown, Menu } from 'antd'
import { AssetStatus } from 'types/enums'
import { getStatusIcon, selectPopupContainer } from 'core/helpers'
import IconFont from 'core/IconFont'
import { useDispatch, useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import * as Store from 'types/store'
import { changeSessionAssets } from 'store/pages/operative-session/actions'
import { DownOutlined } from '@ant-design/icons'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
import classNames from 'classnames'

const values = [
  AssetStatus.Automatic,
  AssetStatus.Freeze,
  AssetStatus.StandByFlat,
  AssetStatus.StandByLong,
  AssetStatus.StandByShort,
]

interface Props {
  asset: Store.Asset
  disabled: boolean
}

const AssetStatusDropdownContainer: React.FC<Props> = ({ asset, disabled }) => {
  const dispatch = useDispatch()

  const context = useContext(CandleStickFrameContext)
  const { isFullscreen } = context

  const session = useSelector(selectOperativeSessionSession)
  const { id: sessionId, isHistorical } = session

  const menu = values.map(item => {
    return {
      key: item,
      label: (
        <IconFont type={getStatusIcon(item)} />
      ),
      onClick: ({key}) => {
        dispatch(
          dispatch(changeSessionAssets({ assets: [asset], sessionId, status: key as AssetStatus }))
        )
      }
    }
  })

  const getStatusClass = () => {
    switch (asset?.status) {
      case AssetStatus.Terminating:
        return 'status status--terminating'
      case AssetStatus.Off:
        return 'status status--off'
      case AssetStatus.Updating:
        return 'status status--updating'
      case AssetStatus.Broken:
        return 'status status--broken'
      case AssetStatus.Quit:
        return 'status status--quit'
      default:
        return ''
    }
  }

  if (!asset || !asset.status) {
    return null
  }

  const isDisabled =
    disabled ||
    asset.status === AssetStatus.Broken ||
    asset.status === AssetStatus.Updating ||
    asset.status === AssetStatus.Terminating ||
    asset.status === AssetStatus.Off ||
    isHistorical

  return (
    <Dropdown
      menu={{items: menu}}
      trigger={['click']}
      getPopupContainer={(trigger) => selectPopupContainer(trigger, isFullscreen)}
      className={classNames('dropdown', 'dropdown--asset-status', getStatusClass())}
      disabled={isDisabled}
    >
      <Button type="dashed">
        <IconFont type={getStatusIcon(asset?.status)} />
        <DownOutlined />
      </Button>
    </Dropdown>
  )
}

export default AssetStatusDropdownContainer
