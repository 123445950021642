// @ts-ignore
import { update_snapshot, update_ticker } from 'sockets/assets/asset_analysis_statistics_pb'
import * as Store from 'types/store'
import { convertToFloat } from '../common'

export const mapAssetAnalysisStatisticsSigmas = (
  feed: update_ticker.AsObject | update_snapshot.AsObject,
): Store.AssetSigmas => {
  const { sigmas } = feed as update_ticker.AsObject

  if (!sigmas) {
    return null
  }

  const { assetPercRetList } = sigmas

  return {
    assetPercRet: assetPercRetList?.map((ret) => ({
      type: ret.type,
      value: convertToFloat(ret.percRet),
    })),
  } as Store.AssetSigmas
}
