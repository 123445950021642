import React from 'react'
import { Row, Col, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'
import { renderAvailableData } from 'core/helpers'
import { formatGliphNumber } from 'core/formats'
import colors from 'config/colors'

interface Props {
  portfolioPosition: Store.CartesianPosition
}

const PortfolioPosition: React.FC<Props> = ({ portfolioPosition }) => {
  const { t } = useTranslation()
  const { netHedging = 0, valueLong = 0, value = 0 } = portfolioPosition || {}

  const sum = value + Math.abs(netHedging)

  const valueLongPerc = valueLong !== 0 ? (valueLong / sum) * 100 : 0
  const netHedgingPerc = Math.abs(netHedging / sum) * 100 + valueLongPerc

  const noCountervalueBar = (
    <div className="ant-progress-outer">
      <div className="ant-progress-inner">
        <div
          className="ant-progress-bg"
          style={{
            width: `100%`,
            borderRadius: '0px',
            background: colors.grey,
          }}
        ></div>
      </div>
    </div>
  )

  const stackbar = (
    <div className="ant-progress-outer">
      <div className="ant-progress-inner" style={{ backgroundColor: 'rgb(255, 138, 59)' }}>
        <div
          className="ant-progress-bg"
          style={{
            width: `${netHedgingPerc}%`,
            borderRadius: '0px',
            background: 'rgb(0, 98, 255)',
          }}
        ></div>
        <div
          className="ant-progress-success-bg"
          style={{
            width: `${valueLongPerc}%`,
            borderRadius: '0px',
            backgroundColor: 'rgb(67, 161, 255)',
          }}
        ></div>
      </div>
    </div>
  )

  return (
    <div className="stacked-bar">
      {value === 0 ? noCountervalueBar : stackbar}

      <div className="stacked-bar-values">
        <Row gutter={[6, 0]}>
          <Col span={12}>
            <Typography.Title level={4}>{t('chart.long')}</Typography.Title>
            <span>{renderAvailableData(formatGliphNumber(portfolioPosition?.valueLong))}</span>
          </Col>
          <Col span={12}>
            <Typography.Title level={4}>{t('chart.netHedging')}</Typography.Title>
            <span>{renderAvailableData(formatGliphNumber(portfolioPosition?.netHedging))}</span>
          </Col>
          <Col span={12}>
            <Typography.Title level={4}>{t('chart.short')}</Typography.Title>
            <span>{renderAvailableData(formatGliphNumber(portfolioPosition?.valueShort))}</span>
          </Col>
          <Col span={12}>
            <Typography.Title level={4}>{t('chart.absolute')}</Typography.Title>
            <span>{renderAvailableData(formatGliphNumber(portfolioPosition?.value))}</span>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default PortfolioPosition
