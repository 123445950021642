import React, { useState } from 'react'
import { Button, Col, Form, Input, Row, Space, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'
import { useForm } from 'antd/lib/form/Form'
import Debug from 'debug'
import IconFont from 'core/IconFont'
import { compareByDate, renderDate, sortString } from 'helpers/tableHelpers'

import ContextMenu from './ContextMenu'
import LayoutCell from './LayoutCell'
import { LayoutAction } from 'types/ui'
import { CrudOperation } from 'types/enums'

const debug = Debug('Frontend')

interface Props {
  layouts: Store.Layout[]
  onRowClick: (action: LayoutAction) => void
}

const LayoutsTable: React.FC<Props> = ({ layouts, onRowClick }) => {
  const { t } = useTranslation()
  const [form] = useForm()
  const [selectedLayout, setSelectedLayout] = useState<Store.Layout>(null)
  const [filter, setFilter] = useState('')

  const isEditing = (layout: Store.Layout) => layout.id === selectedLayout?.id

  const onAddLayout = () => {
    onRowClick({
      operation: CrudOperation.Create,
      resource: null,
    })
  }

  const onContextMenuItemClick = (rowAction: LayoutAction) => {
    const { resource: layout } = rowAction
    switch (rowAction.operation) {
      case CrudOperation.Update:
        form.setFieldsValue(layout)
        setSelectedLayout(layout)
        break
      case CrudOperation.Delete:
        onRowClick(rowAction)
        break
      default:
        debug('Unmanaged action')
        break
    }
  }

  const onCancel = () => {
    form.setFieldsValue({
      name: '',
    })
    setSelectedLayout(null)
  }

  //GG-2022-02-03: row selection and deviceType property on hold. Maybe not required anymore
  // const rowSelection = {
  //   getCheckboxProps: (record: Store.Layout) => ({
  //     // Column configuration not to be checked
  //     name: record.name,
  //   }),
  // }

  // const renderDeviceType = (deviceType) => {
  //   if (!deviceType) {
  //     return <Tag color={'red'}>ALL</Tag>
  //   } else {
  //     deviceType.map((tag: string) => {
  //       const color = 'green'

  //       return (
  //         <Tag color={color} key={tag}>
  //           {tag}
  //         </Tag>
  //       )
  //     })
  //   }
  // }

  const onFinish = (data: Json) => {
    const layout = { ...selectedLayout, ...{ name: data.name } }
    onRowClick({
      operation: CrudOperation.Update,
      resource: layout,
    })
    onCancel()
  }

  const TABLE_COLUMNS = [
    {
      title: t('table.column.layoutName'),
      dataIndex: 'name',
      sorter: (a: Store.Layout, b: Store.Layout) => sortString(a.name, b.name),
      width: '20%',
      editable: true,
    },
    {
      title: t('table.column.creationUser'),
      dataIndex: 'creationUser',
      sorter: (a: Store.Layout, b: Store.Layout) => sortString(a.creationUser, b.creationUser),
      width: '20%',
      editable: false,
    },
    {
      title: t('table.column.creationDate'),
      dataIndex: 'creationDate',
      width: '15%',
      sorter: (a: Store.Layout, b: Store.Layout) => compareByDate(a.creationDate, b.creationDate),
      render: (date: Date) => renderDate(date),
      editable: false,
    },
    {
      title: t('table.column.updateUser'),
      dataIndex: 'lastUpdateUser',
      sorter: (a: Store.Layout, b: Store.Layout) => sortString(a.lastUpdateUser, b.lastUpdateUser),
      width: '20%',
      editable: false,
    },
    {
      title: t('table.column.updateDate'),
      render: (date: Date) => renderDate(date),
      sorter: (a: Store.Layout, b: Store.Layout) => compareByDate(a.creationDate, b.creationDate),
      dataIndex: 'lastUpdateDate',
      width: '15%',
      editable: false,
    },
    // {
    //   title: t('table.column.deviceType'),
    //   dataIndex: 'deviceTypes',
    //   render: (deviceType) => renderDeviceType(deviceType),
    //   editable: false,
    // },
    {
      key: 'action',
      dataIndex: 'id',
      width: 200,
      // eslint-disable-next-line react/display-name
      render: (id, layout: Store.Layout) => {
        const editable = isEditing(layout)

        return id && editable ? (
          <Row justify="end">
            <Space>
              <Button type="dashed" className="secondary edit-btn" onClick={onCancel}>
                {t('general.cancel')}
              </Button>
              <Button type="primary" className="secondary edit-btn" htmlType="submit">
                {t('general.ok')}
              </Button>
            </Space>
          </Row>
        ) : (
          <ContextMenu layout={layout} onMenuItemClick={onContextMenuItemClick} />
        )
      },
    },
  ]

  const handleFilterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currValue = e.target.value
    setFilter(currValue)
  }

  const filterBySearch = (data: Store.Layout[]) => {
    const filtered = data.filter(
      (entry) =>
        entry.name.toLowerCase().includes(filter.toLowerCase()) ||
        entry.creationUser.toLowerCase().includes(filter.toLowerCase()) ||
        entry.lastUpdateUser.toLowerCase().includes(filter.toLowerCase()),
    )

    return filtered
  }

  const onSelectLayout = (id: number) => {
    const layout = layouts.find((item) => item.id === id)
    onRowClick({ operation: CrudOperation.Read, resource: layout })
  }

  const mergedColumns = TABLE_COLUMNS.map((col) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (layout) => ({
        layout,
        form: form,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(layout),
      }),
    }
  })

  const data = filterBySearch(layouts)

  return (
    <div className="layout-panel">
      <Row justify="space-between">
        <Col>
          <Input
            placeholder={t('table.filter.search')}
            suffix={<IconFont type="icon-magnifier" />}
            onChange={(e) => handleFilterSearch(e)}
          />
        </Col>
      </Row>
      <section className="layout-table">
        <Form form={form} onFinish={onFinish}>
          <Table
            components={{
              body: {
                cell: LayoutCell,
              },
            }}
            dataSource={data}
            columns={mergedColumns}
            pagination={false}
            scroll={{ y: 220 }}
            rowClassName={() => 'editable-row'}
            rowKey={'id'}
            onRow={(record) => {
              return {
                onClick: () => onSelectLayout(record.id),
              }
            }}
          />
        </Form>
        <Button onClick={onAddLayout} className="add-layout" type="primary">
          {t('operativeSession.addNewDashboard')}
        </Button>
      </section>
    </div>
  )
}

export default LayoutsTable
