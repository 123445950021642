import React, { useState } from 'react'
import DependsOn from 'components/DependsOn/DependsOn'
import { Col, Form, Row, Select, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { CrudOperation } from 'types/enums'
const { Paragraph } = Typography

interface Props {
  data: string[]
  filter: (symbol: string) => void
  onMicChange: () => void
  mode: CrudOperation
}

const { Option } = Select

const MICField: React.FC<Props> = ({ data, filter, onMicChange, mode }) => {
  const { t } = useTranslation()
  const [symbol, setSymbol] = useState(null)

  const setSymbolIfDifferent = (newSymbol: string) => {
    if (symbol !== newSymbol) {
      setSymbol(newSymbol)

      filter(newSymbol)
    }
  }
  const readonly = mode === CrudOperation.Update

  return (
    <DependsOn fields={['symbol']}>
      {(fields) => {
        const { symbol } = fields

        if (symbol) {
          setSymbolIfDifferent(symbol)
        }

        return (
          <Row align="middle" gutter={[5, 0]}>
            <Col span={12}>
              <Paragraph>{t('assets.mic')}</Paragraph>
            </Col>
            <Col span={12}>
              <Form.Item
                name="mic"
                rules={[{ required: true, message: t('error.requiredField') }]}
                className={readonly && 'readonly'}
              >
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  showSearch
                  optionFilterProp="children"
                  showArrow={false}
                  disabled={!symbol || readonly}
                  onChange={onMicChange}
                >
                  {data.map((mic) => {
                    return (
                      <Option value={mic} key={mic}>
                        {mic}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )
      }}
    </DependsOn>
  )
}

export default MICField
