import * as Api from 'types/api'
import { getAxios } from './apiService'
import { config } from 'core'
import { getApiError, getAuthenticationHeader } from './common'
import { AxiosResponse } from 'axios'
const axios = getAxios()

export async function getStrategies(): Promise<Api.StrategiesResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `{
      application_strategy (where: {deletion_timestamp: {_is_null: true}}) {
        id
        base_currency
        creation_date
        creation_userid
        last_mod_date
        last_mod_userid
        name
        owner_id
        target
      }
    }`,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getUsedResource(): Promise<Api.UsedResourcesResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `{
      application_asset(where: {asset_model_instances: {operative_session: {status: {_in: ["CRT","RUN","BRK","UPD","TRM"]}}}}) {
        id
        asset_groups {
          group_id
        }
        portfolio_id
        portfolio {
          strategy_id
        }
      }
    }`,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function updateStrategy(data: Api.StrategyRest): Promise<AxiosResponse | ApiError> {
  const header = await getAuthenticationHeader()
  return axios
    .put(config.api.updateStrategy(data.id), data, header)
    .then((response) => response)
    .catch((error) => getApiError(error))
}

export async function createStrategy(data: Api.StrategyRest): Promise<Api.StrategyCreateFeed | ApiError> {
  const header = await getAuthenticationHeader()
  return axios
    .post(config.api.createStrategy(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function deleteStrategy(id: number): Promise<AxiosResponse | ApiError> {
  const header = await getAuthenticationHeader()
  return axios
    .delete(config.api.deleteStrategy(id), header)
    .then((response) => response)
    .catch((error) => getApiError(error))
}
