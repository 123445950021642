import { AssetStatus, SessionStatus, SessionType } from 'types/enums'
import * as Store from 'types/store'
import i18n from 'i18next'

export const getAssetsFromIds = (
  sessionAssets: Array<Store.SessionAsset>,
  assets: Array<Store.Asset>,
): Array<Store.Asset> => {
  const ids = sessionAssets.map((sessionAsset) => sessionAsset.id)
  return assets
    .filter((asset) => ids.includes(asset.id))
    .map((asset) => {
      const sessionAssetStatus = sessionAssets.find((sessionAsset) => sessionAsset.id === asset.id).status
      return {
        ...asset,
        status: sessionAssetStatus as AssetStatus,
      }
    })
}

export const getSessionName = (session: Store.Session) => {
  if (!session) {
    return null
  }

  switch (session.type) {
    case SessionType.BackTest:
      return i18n.t('sessionType.backtest')
    case SessionType.Signals:
      return i18n.t('sessionType.signals')
    case SessionType.Trading:
      return i18n.t('sessionType.trading')
    case SessionType.Virtual:
      return i18n.t('sessionType.virtual')
    case SessionType.Unknown:
      return i18n.t('sessionType.unknown')
    default:
      return i18n.t('sessionType.unknown')
  }
}

export const hasSessionOpen = (user: Store.SystemUser, sessions: Store.Session[]): boolean => {
  //TBD: GG-2022-01-05 at the moment we don't have userId: check if current userId has running sessions
  const runnningSessions = sessions.find((item) => item.status === SessionStatus.Running)
  if (!runnningSessions) {
    return false
  }

  return true
}
