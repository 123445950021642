import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingState, SessionType } from 'types/enums'
import { createLayout } from 'store/layouts/actions'
import { selectLayoutsState } from 'store/layouts/selectors'
import { createEmptyLayout } from 'services/dashboardService'
import CreateLayout from 'v2/components/settings/modals/CreateLayout'
import { selectOperativeSessionSession } from 'store/pages/selectors'

interface Props {
  onClose: () => void
}

const CreateEmptyLayoutContainer: React.FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch()
  const loadingState = useSelector(selectLayoutsState)
  const session = useSelector(selectOperativeSessionSession)
  const creating = loadingState === LoadingState.Creating

  const onCancel = () => {
    onClose()
  }

  const onConfirm = (layoutName: string, sessionType: SessionType) => {
    const layout = createEmptyLayout(layoutName, sessionType)
    dispatch(createLayout(layout))
    onClose()
  }

  return (
    <CreateLayout onCancel={onCancel} onConfirm={onConfirm} isLoading={creating} sessionType={session.type} />
  )
}

export default CreateEmptyLayoutContainer
