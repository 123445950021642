import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SaveLayout from 'v2/components/SaveLayout'
import { createLayout, updateLayout } from 'store/layouts/actions'
import { selectLayouts, selectLayoutsState } from 'store/layouts/selectors'
import * as dashboardService from 'services/dashboardService'
import { selectDashboard } from 'store/dashboard/selectors'
import * as Store from 'types/store'
import { CrudOperation, LoadingState } from 'types/enums'
import CreateLayout from 'v2/components/settings/modals/CreateLayout'
import { selectOperativeSessionSession } from 'store/pages/selectors'

const SaveLayoutContainer: React.FC = () => {
  const dispatch = useDispatch()
  const loadingState = useSelector(selectLayoutsState)
  const creating = loadingState === LoadingState.Creating

  const { data: layouts } = useSelector(selectLayouts)
  const dashboard = useSelector(selectDashboard)
  const session = useSelector(selectOperativeSessionSession)
  const [operation, setOperation] = useState<CrudOperation>(CrudOperation.None)

  useEffect(() => {
    if (operation === CrudOperation.Create && loadingState === LoadingState.Created) {
      setOperation(CrudOperation.None)
    }
  }, [loadingState, operation])

  const onSaveLayout = () => {
    let layout: Store.Layout = null

    layout = layouts.find((item) => item.id === dashboard.id)
    if (!layout) {
      setOperation(CrudOperation.Create)
      return
    }

    layout = dashboardService.updateLayout(layout, dashboard, session.type)
    dispatch(updateLayout(layout))
  }

  const onCancel = () => {
    setOperation(CrudOperation.None)
  }

  const onConfirm = (layoutName: string) => {
    const layout = dashboardService.createLayout(layoutName, dashboard, session.type)
    dispatch(createLayout(layout))
  }

  if (operation === CrudOperation.Create) {
    return (
      <CreateLayout
        onCancel={onCancel}
        onConfirm={onConfirm}
        isLoading={creating}
        sessionType={session.type}
      />
    )
  }

  if (!dashboard.isDirty) {
    //Show save only if something to save
    return null
  }

  return <SaveLayout onClick={onSaveLayout} />
}

export default SaveLayoutContainer
