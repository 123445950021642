import React from 'react'
import * as Store from 'types/store'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import HistoricalPortfolioCandleStickContainer from 'v2/containers/frames/Historical/PortfolioCandleStick'
import PortfolioCandleStickContainer from 'v2/containers/frames/PortfolioCandleStick'

interface Props {
  frame: Store.Frame
}

const FrameView: React.FC<Props> = ({ frame }) => {
  const session = useSelector(selectOperativeSessionSession)
  if (session.isHistorical) {
    return <HistoricalPortfolioCandleStickContainer frame={frame} />
  }

  return <PortfolioCandleStickContainer frame={frame} />
}

export default FrameView
