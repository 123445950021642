import React, { useState } from 'react'
import { Dropdown, Menu, Popover } from 'antd'
import { ExtendedDataNode, TransferType } from 'types/ui'
import { useTranslation } from 'react-i18next'
import { CheckOutlined, CheckCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons'
import { getAllAssets, getAllGroups, getMainItem } from 'helpers/transfer/itemAddHelper'
import { AssetStatus, TransferContext } from 'types/enums'
import styles from '../styles.module.scss'
import * as Store from 'types/store'
import colors from 'config/colors'

interface Props {
  node: ExtendedDataNode
  onClick: (node: ExtendedDataNode, type: TransferType) => void
  context: TransferContext
  disableAddGroup?: boolean
}

const AddGroup: React.FC<Props> = ({ node, onClick, context, disableAddGroup }) => {
  const { t } = useTranslation()

  const everyAssetsSelected = node.children.length > 0 && node.children.every((asset) => asset.selected)
  const someAssetsSelected = node.children.length > 0 && node.children.some((asset) => asset.selected)
  const isBroken =
    node.children.length > 0 &&
    node.children.some((asset) => (asset.resource as Store.Asset).status === AssetStatus.Broken)

  const trigger: any =
    context === TransferContext.AtSessionLaunch ? ['contextMenu'] : ['click', 'contextMenu']

  const [menu, setMenu] = useState([])

  const getItems = () => {
    if (node.children.length === 0) {
      return []
    }
    switch (context) {
      case TransferContext.All:
        if (disableAddGroup) {
          return [getAllAssets(node)]
        }
        return [getMainItem(node),getAllAssets(node)]
      case TransferContext.AtBacktest:
        return [getMainItem(node)]
      default:
        return [getAllAssets(node)]
    }
  }

  const getErrorMessage = () => {
    return someAssetsSelected ? t('error.selectedBrokenAsset') : t('error.nonSelectedBrokenPortfolio')
  }

  const onVisibleChange = (visible: boolean) => {
    if (visible) {
      setMenu(getItems())
    } else {
      setMenu([])
    }
  }

  const dropDownMenu = menu.map(item => {
    return {
      key: item.key,
      label: t(item.label),
      onClick: () => {onClick(node, item.transferType)}
    }
  })

  const getStatusIcon = () => {
    if (everyAssetsSelected) {
      return <CheckCircleFilled className={styles['menu-icon-full-selected']} />
    }

    if (someAssetsSelected) {
      return <CheckCircleFilled className={styles['menu-icon-partial-selected']} />
    }

    return null
  }

  return (
    <Dropdown
      menu={{items: dropDownMenu}}
      trigger={trigger}
      getPopupContainer={(trigger) => trigger.parentElement}
      onOpenChange={onVisibleChange}
    >
      <span className={node.selected && 'selected-item'}>
        {node.selected && <CheckOutlined style={{ marginRight: 5 }} />}
        {getStatusIcon()}
        {isBroken && (
          <Popover content={getErrorMessage()} trigger="hover">
            <ExclamationCircleOutlined style={{ color: colors.red }} className="mr-5" />
          </Popover>
        )}
        {node.title}
      </span>
    </Dropdown>
  )
}

export default AddGroup
