import * as K from './constants'
import produce from 'immer'
import { LoadingState } from 'types/enums'
import { AnyAction } from 'redux'
import * as Store from 'types/store'
import { ResponseCode } from 'types/enums.api'

export const initialState: Store.SessionState = {
  data: [],
  state: LoadingState.Unset,
  errorCode: null,
}

const reducer = (state = initialState, action: AnyAction) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case K.FETCH_SESSIONS_SUCCESS:
        draft.data = action.payload as Array<Store.Session>
        break
      case K.UPDATE_SESSION_STATUS:
        const { sessionId, status } = action.payload as Store.SessionUpdate
        const index = draft.data.findIndex((session) => {
          return session.id === sessionId
        })
        if (index !== -1) {
          draft.data[index].status = status
        }
        break
      case K.SET_STATUS:
        draft.state = action.payload as LoadingState
        break
      case K.SET_ERROR:
        draft.errorCode = action.payload as ResponseCode
        break
    }
  })
}

export default reducer
