import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import IconFont from 'core/IconFont'
import { Table, Input, Row, Col, Select, Form, DatePicker } from 'antd'
import * as Store from 'types/store'

import { SessionHistoryStatus, SessionStatus, SessionType } from 'types/enums'
import { ColumnsType } from 'antd/lib/table'
import { getSessionTypeLabel } from 'helpers/common'
import Buttons from './Buttons'
import { compareByDate, getColumnSearchProps, renderDate, sortString } from 'helpers/tableHelpers'
import AppContentCard from 'components/AppContentCard'
import styles from './sessions.module.scss'
import SessionStatusRendererContainer from 'containers/Sessions/SessionStatusRenderer'

interface Props {
  sessions: Store.Session[]
  onDelete: (id: number) => void
  onClean: (id: number) => void
}

const Sessions: React.FC<Props> = ({ sessions: initialSessions, onClean, onDelete }) => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState('')
  const [historyStatus, setHistoryStatus] = useState<SessionHistoryStatus>(SessionHistoryStatus.Live)
  const [dateFilter, setDateFilter] = useState<[moment.Moment, moment.Moment]>(null)
  const [sessions, setSessions] = useState(initialSessions)

  useEffect(() => {
    setSessions(initialSessions)
  }, [initialSessions])

  const onPress = (id: number, isCleanUp: boolean) => {
    if (isCleanUp) {
      onClean(id)
    } else {
      onDelete(id)
    }
  }

  const renderStatus = (_, session: Store.Session) => <SessionStatusRendererContainer session={session} />

  const handleFilterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currValue = e.target.value
    setFilter(currValue)
  }

  const filterByDate = (data: Store.Session[]) => {
    if (!dateFilter) {
      return data
    }

    const [from, to] = dateFilter
    let filteredData: Store.Session[] = data

    if (from) {
      filteredData = filteredData.filter((entry) => {
        if (!entry.startTime) {
          return false
        }
        //from midnight
        const fromDate = from.clone().startOf('day')
        const startTime = entry.startTime.getTime()
        return startTime >= fromDate.unix() * 1000
      })
    }

    if (to) {
      filteredData = filteredData.filter((entry) => {
        if (!entry.endTime) {
          return false
        }
        //to midnight
        const toDate = to.clone().endOf('day')
        const endTime = entry.startTime.getTime()
        return endTime <= toDate.unix() * 1000
      })
    }

    return filteredData
  }

  const filterBySearch = (data: Store.Session[]) => {
    const filtered = data.filter(
      (entry) =>
        entry.ownerEmail.toLowerCase().includes(filter.toLowerCase()) ||
        entry.author.toLowerCase().includes(filter.toLowerCase()) ||
        entry.name.toLowerCase().includes(filter.toLowerCase()) ||
        entry.type.toLowerCase().includes(filter.toLowerCase()) ||
        SessionStatus[entry.status.toString()].toLowerCase().includes(filter.toLowerCase()),
    )

    return filterByDate(filtered)
  }

  const filterBySession = (data: Store.Session[]) => {
    let filtered: Store.Session[]
    switch (historyStatus) {
      case SessionHistoryStatus.Live:
        filtered = data.filter((entry) => !entry.isHistorical)
        break
      case SessionHistoryStatus.Historical:
        filtered = data.filter((entry) => entry.isHistorical)
        break
      default:
        filtered = data
        break
    }

    return filterBySearch(filtered)
  }

  const onSessionChange = (value: SessionHistoryStatus) => {
    setHistoryStatus(value)
  }

  const data = filterBySession(sessions)
  const minPageSize = 10

  const TABLE_COLUMNS: ColumnsType<Store.Session> = [
    {
      key: 'session-type',
      title: t('table.column.type'),
      dataIndex: 'type',
      width: 150,
      filters: [
        { text: t('table.newSession.trading'), value: SessionType.Trading },
        { text: t('table.newSession.virtual'), value: SessionType.Virtual },
        { text: t('table.newSession.signals'), value: SessionType.Signals },
        { text: t('table.newSession.backtest'), value: SessionType.BackTest },
      ],
      render: (type) => getSessionTypeLabel(type),
      onFilter: (value, record) => record.type.includes(value as string),
    },
    {
      key: 'session-status',
      title: t('table.column.status'),
      dataIndex: 'status',
      width: 150,
      filters: [
        { text: t('status.created'), value: SessionStatus.Created },
        { text: t('status.updating'), value: SessionStatus.Updating },
        { text: t('status.running'), value: SessionStatus.Running },
        { text: t('status.terminating'), value: SessionStatus.Terminating },
        { text: t('status.completed'), value: SessionStatus.Completed },
        { text: t('status.stopped'), value: SessionStatus.Stopped },
        { text: t('status.broken'), value: SessionStatus.Broken },
        { text: t('status.off'), value: SessionStatus.Off },
      ],
      render: renderStatus,
      onFilter: (value, record) => record.status === value,
    },
    {
      key: 'session-name',
      title: t('menu.strategies'),
      dataIndex: 'name',
    },
    {
      key: 'session-owner',
      title: t('table.column.owner'),
      dataIndex: 'ownerEmail',
      sorter: (a: Store.Session, b: Store.Session) => sortString(a.ownerEmail, b.ownerEmail),
      ...(data.length > 1 ? getColumnSearchProps('ownerEmail') : {}),
    },
    {
      key: 'session-start-time',
      title: t('table.column.startTime'),
      dataIndex: 'startTime',
      sorter: (a: Store.Session, b: Store.Session) => compareByDate(a.startTime, b.startTime),
      render: (date) => renderDate(date),
    },
    {
      key: 'session-end-time',
      title: t('table.column.endTime'),
      dataIndex: 'endTime',
      render: (date) => renderDate(date),
      sorter: {
        compare: (a: Store.Session, b: Store.Session) => compareByDate(a.endTime, b.endTime),
        multiple: 1,
      },
    },
    {
      key: 'session-author',
      title: t('table.column.executionUser'),
      dataIndex: 'author',
      sorter: (a: Store.Session, b: Store.Session) => sortString(a.author, b.author),
      ...(data.length > 1 ? getColumnSearchProps('author') : {}),
    },
    {
      key: 'action',
      // eslint-disable-next-line react/display-name
      render: (record: Store.Session) => <Buttons record={record} onPress={onPress} />,
    },
  ]

  return (
    <AppContentCard clipped className={styles.sessions}>
      <section className="app-table">
        <Row gutter={[70, 0]} className="mt-20 mb-20">
          <Col span={6}>
            <Input
              placeholder={t('table.filter.search')}
              value={filter}
              onChange={(e) => handleFilterSearch(e)}
              suffix={<IconFont type="icon-magnifier" />}
            />
          </Col>

          <Col span={5}>
            <Form>
              <Form.Item label={t('table.filter.session')}>
                <Select
                  defaultValue={historyStatus}
                  onChange={onSessionChange}
                  className="app-select"
                  getPopupContainer={(trigger) => trigger.parentElement}
                >
                  <Select.Option value={SessionHistoryStatus.All}>{t('table.filter.all')}</Select.Option>
                  <Select.Option value={SessionHistoryStatus.Live}>{t('status.live')}</Select.Option>
                  <Select.Option value={SessionHistoryStatus.Historical}>
                    {t('status.historical')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Form>
          </Col>

          <Col span={8}>
            <Form>
              <Form.Item label={t('table.filter.executionDates')}>
                <DatePicker.RangePicker
                  picker="date"
                  onChange={(value) => setDateFilter(value)}
                  allowEmpty={[true, true]}
                  value={dateFilter}
                  placeholder={[t('general.from'), t('general.to')]}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>

        <Table
          dataSource={data}
          columns={TABLE_COLUMNS}
          pagination={{
            hideOnSinglePage: data.length <= minPageSize,
            defaultPageSize: minPageSize,
          }}
          rowClassName={(record) => (record.isDisabled && 'row-disabled')}
          rowKey="id"
        />
      </section>
    </AppContentCard>
  )
}

export default Sessions
