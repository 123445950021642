import React, { useContext, useEffect, useRef, useState } from 'react'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
import * as Store from 'types/store'
import PanelContent from 'v2/components/frames/PortfolioCandleStick/PanelContent'
import { useSelector } from 'react-redux'
import { config } from 'core'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import sigmaServices from 'config/sigmaServices'
import { PortfolioFeed } from 'types/enums.api'
import { FlowControlSpeed } from 'types/enums'
import { isOffline } from 'hooks/common'

const {
  Portfolios: { Position, Return, Operations, ReferenceData },
} = sigmaServices

const PanelContentContainer: React.FC = () => {
  const context = useContext(CandleStickFrameContext)
  const { resource, setOffline } = context
  const portfolio = resource as Store.Portfolio
  const session = useSelector(selectOperativeSessionSession)
  const { id: sessionId } = session

  const webWorkerRef = useRef<Worker | null>(null)

  const [data, setData] = useState<Store.PortfolioSigmas>({} as Store.PortfolioSigmas)

  const [lastTick, setLastTick] = useState<Date>(new Date())

  const messageReceived = () => {
    setLastTick(new Date())
  }

  useEffect(() => {
    webWorkerRef.current = new Worker(new URL('../../../../workers/portfolioGraphLeftTableSockets.web.worker.ts', import.meta.url))

    webWorkerRef.current.onmessage = (event: MessageEvent) => {
      const { type, data: workerData, error } = event.data
      if (type === 'data') {
        setData(workerData)
        messageReceived()
      } else if (type === 'error') {
        console.error('Worker error:', error)
      }
    }

    const initMsg = {
      type: 'init',
      payload: {
        portfolioId: portfolio.id,
        sessionId,
        sigmas: [
          { service: PortfolioFeed.Position, sigmas: [Position.PrtCartesianPosition] },
          { service: PortfolioFeed.Return, sigmas: [Return.PrtTradRetBcList] },
          { service: PortfolioFeed.Operations, sigmas: [Operations.PrtCartesianOperationsList] },
          { service: PortfolioFeed.ReferenceData, sigmas: [ReferenceData.PrtCartesianHeading] },
        ],
        flowControlSpeed: FlowControlSpeed.High,
        webSocketUrl: config.socketBaseUrl,
      },
    }

    // Send startup message (with all the setup params) to the worker
    webWorkerRef.current.postMessage(initMsg)

    return () => {
      webWorkerRef.current.postMessage({ type: 'terminate' })
      webWorkerRef.current.terminate()
    }
  }, [])

  //notify to the context that we are offline
  // useEffect(() => {
  //   setOffline(isOffline)
  // }, [lastTick1, lastTick2, lastTick3, lastTick4, isOffline])

  useEffect(() => {
    setOffline(isOffline(lastTick))
  }, [lastTick])

  return <PanelContent sigmas={data} />
}

export default PanelContentContainer
