// @ts-ignore
import { update_snapshot, update_ticker } from 'sockets/assets/asset_return_pb'
import * as Store from 'types/store'
import { convertToFloat, formatSigmaDate } from '../common'
import { TimeFrame } from 'types/enums'
import moment from 'moment'

export const mapAssetReturnSigmas = (
  feed: update_ticker.AsObject | update_snapshot.AsObject,
): Store.AssetSigmas => {
  const { sigmas, key } = feed

  if (!sigmas) {
    return null
  }

  const {
    assetAllRetBcList,
    assetExchRetBcList,
    assetTradRetList,
    returnAnalysisList,
    assetClosedUnitRet,
    assetOpenUnitRet,
    assetTdyOpIndicators,
  } = sigmas

  return {
    assetClosedReturn: assetClosedUnitRet && {
      close: convertToFloat(assetClosedUnitRet.unitRetClose),
      open: convertToFloat(assetClosedUnitRet.unitRetOpen),
      date: formatSigmaDate(assetClosedUnitRet.startTs),
      endTime: formatSigmaDate(assetClosedUnitRet.endTs),
      startTime: formatSigmaDate(assetClosedUnitRet.startTs),
    },
    assetOpenReturn: assetOpenUnitRet && {
      close: convertToFloat(assetOpenUnitRet.unitRetClose),
      open: convertToFloat(assetOpenUnitRet.unitRetOpen),
      date: formatSigmaDate(assetOpenUnitRet.startTs),
      endTime: formatSigmaDate(assetOpenUnitRet.endTs),
      startTime: formatSigmaDate(assetOpenUnitRet.startTs),
    },
    assetCartesianLastReturnList: assetTradRetList?.map((item) => ({
      tradingRet: convertToFloat(item.tradingRet) || 0,
      tradingRetBc: convertToFloat(item.tradingRetBc) || 0,
      type: item.type as TimeFrame,
    })),
    returnAnalysisList: returnAnalysisList?.map((returnAnalysis) => ({
      id: parseInt(key),
      type: returnAnalysis.type as TimeFrame,
      assetClass: returnAnalysis.assetClass,
      closeTradingRetBc: convertToFloat(returnAnalysis.closeTradingRetBc) || 0,
      currency: returnAnalysis.currency,
      market: returnAnalysis.market,
      openTradingRetBc: convertToFloat(returnAnalysis.openTradingRetBc) || 0,
      valueLong: convertToFloat(returnAnalysis.longTradingRetBc) || 0,
      valueShort: convertToFloat(returnAnalysis.shortTradingRetBc) || 0,
      value: convertToFloat(returnAnalysis.tradingRetBc) || 0,
      netHedging:
        (convertToFloat(returnAnalysis.longTradingRetBc) || 0) -
        (convertToFloat(returnAnalysis.shortTradingRetBc) || 0),
      quantity: null,
      sign: null,
    })),
    allRetBc: assetAllRetBcList?.map(({ allRetBc, type }) => ({ value: convertToFloat(allRetBc), type })),
    allRetBcFees: assetAllRetBcList?.map(({ allRetBcFees, type }) => ({ value: convertToFloat(allRetBcFees), type })),
    exchRetBc: assetExchRetBcList?.map(({ exchRetBc, type }) => ({ value: convertToFloat(exchRetBc), type })),

    assetTdyOpIndicators: assetTdyOpIndicators && {
      avgPrinc: convertToFloat(assetTdyOpIndicators.assetTdyAvgPrinc),
      avgPrincLng: convertToFloat(assetTdyOpIndicators.assetTdyAvgPrincLng),
      avgPrincLngNeg: convertToFloat(assetTdyOpIndicators.assetTdyAvgPrincLngNeg),
      avgPrincLngPos: convertToFloat(assetTdyOpIndicators.assetTdyAvgPrincLngPos),
      avgPrincNeg: convertToFloat(assetTdyOpIndicators.assetTdyAvgPrincNeg),
      avgPrincPos: convertToFloat(assetTdyOpIndicators.assetTdyAvgPrincPos),
      avgPrincShr: convertToFloat(assetTdyOpIndicators.assetTdyAvgPrincShr),
      avgPrincShrNeg: convertToFloat(assetTdyOpIndicators.assetTdyAvgPrincShrNeg),
      avgPrincShrPos: convertToFloat(assetTdyOpIndicators.assetTdyAvgPrincShrPos),
      date: moment().hour(0).minute(0).toDate(),
      dtPrinc: assetTdyOpIndicators.assetTdyDtPrinc,
      dtPrincLngNeg: assetTdyOpIndicators.assetTdyDtPrincLngNeg,
      dtPrincLngPos: assetTdyOpIndicators.assetTdyDtPrincLngPos,
      dtPrincShrNeg: assetTdyOpIndicators.assetTdyDtPrincShrNeg,
      dtPrincShrPos: assetTdyOpIndicators.assetTdyDtPrincShrPos,
      insertionTime: null,
      mean: convertToFloat(assetTdyOpIndicators.assetTdyMean),
      mean2: convertToFloat(assetTdyOpIndicators.assetTdyMean2),
      meanLng: convertToFloat(assetTdyOpIndicators.assetTdyMeanLng),
      meanLng2: convertToFloat(assetTdyOpIndicators.assetTdyMeanLng2),
      meanLngNeg: convertToFloat(assetTdyOpIndicators.assetTdyMeanLngNeg),
      meanLngNeg2: convertToFloat(assetTdyOpIndicators.assetTdyMeanLngNeg2),
      meanLngPos: convertToFloat(assetTdyOpIndicators.assetTdyMeanLngPos),
      meanLngPos2: convertToFloat(assetTdyOpIndicators.assetTdyMeanLngPos2),
      meanNeg: convertToFloat(assetTdyOpIndicators.assetTdyMeanNeg),
      meanNeg2: convertToFloat(assetTdyOpIndicators.assetTdyMeanNeg2),
      meanPos: convertToFloat(assetTdyOpIndicators.assetTdyMeanPos),
      meanPos2: convertToFloat(assetTdyOpIndicators.assetTdyMeanPos2),
      meanShr: convertToFloat(assetTdyOpIndicators.assetTdyMeanShr),
      meanShr2: convertToFloat(assetTdyOpIndicators.assetTdyMeanShr2),
      meanShrNeg: convertToFloat(assetTdyOpIndicators.assetTdyMeanShrNeg),
      meanShrNeg2: convertToFloat(assetTdyOpIndicators.assetTdyMeanShrNeg2),
      meanShrPos: convertToFloat(assetTdyOpIndicators.assetTdyMeanShrPos),
      meanShrPos2: convertToFloat(assetTdyOpIndicators.assetTdyMeanShrPos2),
      sequence: null,
      stdDev: convertToFloat(assetTdyOpIndicators.assetTdyStdDev),
      stdDevLng: convertToFloat(assetTdyOpIndicators.assetTdyStdDevLng),
      stdDevLngNeg: convertToFloat(assetTdyOpIndicators.assetTdyStdDevLngNeg),
      stdDevLngPos: convertToFloat(assetTdyOpIndicators.assetTdyStdDevLngPos),
      stdDevNeg: convertToFloat(assetTdyOpIndicators.assetTdyStdDevNeg),
      stdDevPos: convertToFloat(assetTdyOpIndicators.assetTdyStdDevPos),
      stdDevShr: convertToFloat(assetTdyOpIndicators.assetTdyStdDevShr),
      stdDevShrNeg: convertToFloat(assetTdyOpIndicators.assetTdyStdDevShrNeg),
      stdDevShrPos: convertToFloat(assetTdyOpIndicators.assetTdyStdDevShrPos),

      tradRet: convertToFloat(assetTdyOpIndicators.assetTdyTradRet),
      tradRetLngNeg: convertToFloat(assetTdyOpIndicators.assetTdyTradRetLngNeg),
      tradRetLngPos: convertToFloat(assetTdyOpIndicators.assetTdyTradRetLngPos),
      tradRetShrNeg: convertToFloat(assetTdyOpIndicators.assetTdyTradRetShrNeg),
      tradRetShrPos: convertToFloat(assetTdyOpIndicators.assetTdyTradRetShrPos),
    },
  } as Store.AssetSigmas
}
