import { isApiError } from 'core'
import React, { useEffect, useState } from 'react'
import * as Store from 'types/store'
import * as api from 'api/assets'
import * as Api from 'types/api'
import LongShortField from 'components/StrategyViewer/forms/AssetForm/LongShortField'

const getBankAccounts = async (): Promise<Store.TypeOfPositionEnabled[]> => {
  const response = await api.getTypeOfPosition()
  if (isApiError(response)) {
    return null
  }

  const masterSecurityResponse = response as Api.TypeOfPostionsEnabledResponse

  return masterSecurityResponse.data.application_e_long_short
}

const LongShortFieldContainer: React.FC = () => {
  const [data, setData] = useState<Store.TypeOfPositionEnabled[]>([])

  useEffect(() => {
    const getData = async () => {
      const data = await getBankAccounts()
      if (data) {
        setData(data)
      }
    }
    getData()
  }, [])

  return <LongShortField data={data} />
}

export default LongShortFieldContainer
