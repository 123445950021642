import React, { useCallback } from 'react'
import * as Store from 'types/store'
import { Avatar, Button, Select } from 'antd'
import styles from './styles.module.scss'
import { useSelector } from 'react-redux'
import { selectDashboardId } from 'store/dashboard/selectors'
import { useTranslation } from 'react-i18next'
import ChevronIcon from 'assets/icon/chevron-icon.svg'

const { Option } = Select

const findNextLayout = (layouts: Store.Layout[], currentId: number): Store.Layout => {
  if (!layouts) {
    return null
  }

  if (!currentId) {
    //return first
    return layouts[0]
  }

  const { length } = layouts
  const currentIndex = layouts.findIndex((item) => item.id === currentId)
  if (currentIndex === length - 1) {
    //return first
    return layouts[0]
  }

  return layouts[currentIndex + 1]
}

const findPreviousLayout = (layouts: Store.Layout[], currentId: number): Store.Layout => {
  if (!layouts) {
    return null
  }

  const currentIndex = layouts.findIndex((item) => item.id === currentId)
  const { length } = layouts
  if (currentIndex === 0) {
    //return last
    return layouts[length - 1]
  }

  return layouts[currentIndex - 1]
}

interface Props {
  layouts: Store.Layout[]
  onSelect: (layout: Store.Layout) => void
}

const DashboardSelector: React.FC<Props> = ({ layouts, onSelect }) => {
  const { t } = useTranslation()
  const dashboardId = useSelector(selectDashboardId)

  const onDahsboardSelected = useCallback((id: string | number) => {
    const layout = layouts.find((item) => item.id === id)
    onSelect(layout)
  }, [])

  const onPreviousClick = useCallback(() => {
    const layout = findPreviousLayout(layouts, dashboardId)
    if (!layout) {
      return
    }
    onSelect(layout)
  }, [])

  const onNextClick = useCallback(() => {
    const layout = findNextLayout(layouts, dashboardId)
    if (!layout) {
      return
    }
    onSelect(layout)
  }, [])

  const onClear = useCallback(() => {
    onSelect(null)
  }, [])

  return (
    <div className={`${styles['dashboard-selector']} JI-RENDER-dashboard-component`}>
      <Button type="ghost" className="chevron-btn chevron-btn--left" onClick={onPreviousClick}>
        <Avatar shape="square" src={ChevronIcon} size={12} />
      </Button>
      <Select
        allowClear
        clearIcon={false}
        showArrow={false}
        value={dashboardId}
        placeholder={t('layout.myDashboard')}
        onSelect={onDahsboardSelected}
        onClear={onClear}
      >
        {layouts.map((layout) => (
          <Option key={layout.id} value={layout.id}>
            {layout.name}
          </Option>
        ))}
      </Select>
      <Button type="ghost" className="chevron-btn chevron-btn--right" onClick={onNextClick}>
        <Avatar shape="square" src={ChevronIcon} size={12} />
      </Button>
    </div>
  )
}

export default DashboardSelector
