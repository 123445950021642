import * as Api from 'types/api'
import * as Store from 'types/store'
declare let window
let localStorage = {} as Storage
// Web workers does not have access to Local
if (typeof window !== 'undefined') {
  localStorage = window.localStorage
}

const AUTH_USER = 'AUTH_USER'

export const clearUser = async (): Promise<void> => {
  localStorage.removeItem(AUTH_USER)
}

export const getTokens = async (): Promise<Store.Tokens> => {
  const json = localStorage.getItem(AUTH_USER)
  if (!json) {
    return null
  }

  return JSON.parse(json) as Store.Tokens
}

export const getAuthToken = async (): Promise<string> => {
  const user = await getTokens()
  return user?.token
}

export const getRefreshToken = async (): Promise<string> => {
  const user = await getTokens()
  return user?.refreshToken
}

export const saveUser = async (response: Api.LoginResponse): Promise<Store.Tokens> => {
  const token: string = response.access_token
  const { refresh_token: refreshToken } = response

  const tokens = {
    token,
    refreshToken,
  }

  //Save in storage
  localStorage.setItem(AUTH_USER, JSON.stringify(tokens))

  return tokens
}
