import React, { useState } from 'react'
import RightMenu from 'components/Admin/RightMenu'
import NotificationManager from 'pages/AdminPage/NotificationManager'
import { LeftMenuItem, LeftMenuPageContext } from 'contexts/LeftMenuContext'
import UsersContainer from 'containers/Admin/Users'
import AppContentCard from 'components/AppContentCard'
import AppContent from 'components/AppContent'
import { Typography } from 'antd'
const { Title } = Typography

const AdminPage: React.FC = () => {
  const [currentItem, setCurrent] = useState(LeftMenuItem.Users)

  const renderItem = () => {
    switch (currentItem) {
      case LeftMenuItem.Users:
        return <UsersContainer />
      default:
        return (
          <AppContentCard full clipped>
            <Title className="select-item-title">Feature not yet avaliable</Title>
          </AppContentCard>
        )
    }
  }

  return (
    <LeftMenuPageContext.Provider value={{ currentItem, setCurrent }}>
      <NotificationManager />
      <RightMenu />
      <AppContent>{renderItem()}</AppContent>
    </LeftMenuPageContext.Provider>
  )
}

export default AdminPage
