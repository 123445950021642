export const STATE_KEY = 'operativeSession'

export const FETCH_OPERATIVE_SESSION = `${STATE_KEY}/FETCH_OPERATIVE_SESSION`
export const FETCH_FINISHED_OPERATIVE_SESSION = `${STATE_KEY}/FETCH_FINISHED_OPERATIVE_SESSION`
export const REFRESH_OPERATIVE_SESSION = `${STATE_KEY}/REFRESH_OPERATIVE_SESSION`
export const FETCH_OPERATIVE_SESSION_SUCCESS = `${STATE_KEY}/FETCH_OPERATIVE_SESSION_SUCCESS`
export const SET_STATUS = `${STATE_KEY}/SET_STATUS`

export const UPDATE_ASSETS_STATUS = `${STATE_KEY}/UPDATE_ASSETS_STATUS`
export const UPDATE_ASSETS_STATUS_SUCCESS = `${STATE_KEY}/UPDATE_ASSETS_STATUS_SUCCESS`
export const UPDATE_POSITION_MONITORING_ASSETS = `${STATE_KEY}/UPDATE_POSITION_MONITORING_ASSETS`
export const UPDATE_RETURN_ANALYSIS_ASSETS = `${STATE_KEY}/UPDATE_RETURN_ANALYSIS_ASSETS`

export const LAUNCH_VIRTUAL_SESSION = `${STATE_KEY}/LAUNCH_VIRTUAL_SESSION`
export const LAUNCH_BACKTEST_OPERATION = `${STATE_KEY}/LAUNCH_BACKTEST_OPERATION`
export const STOP_BACKTEST_OPERATION = `${STATE_KEY}/STOP_BACKTEST_OPERATION`
export const STOP_VIRTUAL_SESSION = `${STATE_KEY}/STOP_VIRTUAL_SESSION`
export const FREEZE_BACKTEST_OPERATION = `${STATE_KEY}/FREEZE_BACKTEST_OPERATION`
export const CHANGE_SESSION_STATUS = `${STATE_KEY}/CHANGE_SESSION_STATUS`

export const SET_SESSION_PERIOD_PARAMS = `${STATE_KEY}/SET_SESSION_PERIOD_PARAMS`

export const SET_VIEW_TYPE = `${STATE_KEY}/SET_VIEW_TYPE`
