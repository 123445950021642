import React from 'react'
import { SessionStatus } from 'types/enums'
import * as Forms from 'types/form'
import { Control } from 'react-hook-form'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'

import styles from './styles.module.scss'
import { Button, Avatar } from 'antd'
import ExpandIcon from 'assets/icon/expand-icon.svg'
import CollapseIcon from 'assets/icon/collapse-icon.svg'
import Parameters from './Parameters'
import VirtualPlayerControls from './VirtualPlayerControls'
import { themes } from './themes'
import { useTheme } from 'providers/ThemeProvider'

interface Props {
  showParams: boolean
  control: Control<Forms.VirtualSessionPlayer>
  onClick: (status: SessionStatus) => void
  toggleParamsVisibility: () => void
  sessionEndTimer: moment.Moment
  loading: boolean
  reset: () => void
}

const VirtualPlayerContainer: React.FC<Props> = ({
  showParams,
  control,
  onClick,
  toggleParamsVisibility,
  loading,
  sessionEndTimer,
  reset,
}) => {
  const session = useSelector(selectOperativeSessionSession)
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const playerClass = classNames(styles['dashboard-player'], 'hello', themeStyles['theme'])

  return (
    <section className={playerClass}>
      {showParams && (
        <>
          <Parameters
            session={session}
            control={control}
            loading={loading}
            sessionEndTimer={sessionEndTimer}
            reset={reset}
          />
        </>
      )}

      <div className="controllers-container">
        <VirtualPlayerControls onClick={onClick} session={session} loading={loading} />
        <Button
          className={classNames('toggle-btn', { 'toggle-btn--expanded': showParams })}
          onClick={toggleParamsVisibility}
        >
          <Avatar shape="square" src={showParams ? CollapseIcon : ExpandIcon} size={15} />
        </Button>
      </div>
    </section>
  )
}

export default VirtualPlayerContainer
