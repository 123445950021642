import { config } from 'core'

const { isAlive } = config.socketOptions

export const isOffline = (lastTick: Date): boolean => {
  const now = new Date()
  const difference = now.getTime() - lastTick.getTime()
  if (difference > isAlive) {
    return true
  }
  return false
}
