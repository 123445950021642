import { createSelector } from 'reselect'
import * as Store from 'types/store'

const pages = (state: { root: { pages } }) => state.root.pages as Json

// Page Selectors
export const selectSetupSessionPage = createSelector(
  pages,
  (slice) => slice.setupSession as Store.SetupSessionPageState,
)

// Strategy Page
export const selectStrategyPage = createSelector(pages, (slice) => slice.strategy as Store.StrategyPageState)

export const selectUsedResources = createSelector(selectStrategyPage, (slice) => slice.usedResources)

// Operative Session: don't use the full session Selector for performancy issue, so: DON'T export it
const selectOperativeSession = createSelector(
  pages,
  (slice) => slice.operativeSession as Store.OperativeSessionPageState,
)

export const selectOperativeSessionSession = createSelector(selectOperativeSession, (slice) => slice.session)
export const selectOperativeSessionAssets = createSelector(selectOperativeSession, (slice) => slice.assets)
export const selectOperativeSessionState = createSelector(selectOperativeSession, (slice) => slice.state)
export const selectPositionMonitoringAssets = createSelector(
  selectOperativeSession,
  (slice) => slice.positionMonitoring.assets,
)
export const selectReturnAnalysisAssets = createSelector(
  selectOperativeSession,
  (slice) => slice.returnAnalysis.assets,
)

export const selectOperativeSessionAssetById = (id: number) => {
  return createSelector(selectOperativeSessionAssets, (items) => items.find((item) => item.id === id))
}

export const selectPositionMonitoringDimensions = createSelector(
  pages,
  (slice) => slice.operativeSession.positionMonitoring.dimensions,
)

export const selectAnalysisReturnDimensions = createSelector(
  pages,
  (slice) => slice.operativeSession.returnAnalysis.dimensions,
)
export const selectViewType = createSelector(pages, (slice) => slice.operativeSession.viewType)
