import React from 'react'
import { Typography } from 'antd'

import { useTranslation } from 'react-i18next'
import AppContentCard from 'components/AppContentCard'

const { Title } = Typography

interface Props {
  noStrategies: boolean
}

const NoItemSelected: React.FC<Props> = ({ noStrategies }) => {
  const { t } = useTranslation()
  return (
    <>
      <AppContentCard className="empty-card" fullHeight full clipped>
        <Title className="select-item-title">
          {noStrategies ? t('strategies.emptyPageMessage') : t('general.selectItem')}
        </Title>
      </AppContentCard>
    </>
  )
}

export default NoItemSelected
