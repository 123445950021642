import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row, Switch, Typography } from 'antd'
import { SessionType } from 'types/enums'

const { Title } = Typography

interface Props {
  sessionType: SessionType
  onSetEntityFilter: (display: boolean) => void
  entityFilter: boolean
}

const Filters: React.FC<Props> = ({ sessionType, onSetEntityFilter, entityFilter }) => {
  const { t } = useTranslation()
  const isBacktest = sessionType === SessionType.BackTest
  const isVirtual = sessionType === SessionType.Virtual

  const disabled = isBacktest || isVirtual

  return (
    <>
      <Title level={2} className="primary-color mb-20">
        {t('operativeSession.filterOnEntities')}
      </Title>

      <Row className="radio-input-row mb-40">
        <Col span={14}>{t('operativeSession.viewEntitiesDescription')}</Col>
        <Col span={10}>
          <Switch checked={entityFilter} onChange={(e) => onSetEntityFilter(e)} disabled={disabled} />
        </Col>
      </Row>
    </>
  )
}

export default Filters
