import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import { ChartChild } from 'types/chart'
import { wrap } from '../common'

interface Props {
  data: number[]
  bottom?: number
  ticks?: number[]
  tickFormat?: (value: number) => string
  wrapTickValues?: boolean
}

const XAxis: React.FC<Props & ChartChild> = ({
  data,
  bottom = 0,
  chartWidth: width,
  chartHeight: height,
  d3Transform: transform,
  xDomain,
  ticks,
  tickFormat,
  wrapTickValues,
}) => {
  const element = useRef(null)

  const getXDomain = () => {
    if (xDomain) {
      return xDomain
    }

    return [d3.min(data), d3.max(data)]
  }

  const xScale = transform.rescaleX(d3.scaleLinear().domain(getXDomain()).range([0, width]))
  useEffect(() => {
    const bottomAxis = d3.axisBottom(xScale)

    if (ticks) {
      bottomAxis.tickValues(ticks)
    }
    if (tickFormat) {
      bottomAxis.tickFormat(tickFormat)
    }
    const selection = d3
      .select(element.current)
      .attr('class', 'x axis')
      .attr('transform', `translate(0, ${height - bottom})`)

    selection.call(bottomAxis)

    if (wrapTickValues) {
      const numberOfTicks = bottomAxis.tickValues().length
      selection.selectAll('.tick text').call(wrap, (width / numberOfTicks) * 0.9)
    }
  })

  return <g ref={element} />
}

export default XAxis
