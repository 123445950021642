import produce from 'immer'
import { LoadingState } from 'types/enums'
import * as K from './constants'
import { AnyAction } from 'redux'
import * as Store from 'types/store'

export const initialState: Store.LayoutState = {
  data: [],
  state: LoadingState.Unset,
  errorCode: null,
}

const reducer = (state = initialState, action: AnyAction) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case K.CREATE_LAYOUT_SUCCESS:
        const layout = action.payload as Store.Layout
        draft.data.push(layout)
        break
      case K.UPDATE_LAYOUT_SUCCESS:
        {
          const layout = action.payload as Store.Layout
          const index = draft.data.findIndex((item) => item.id === layout.id)
          draft.data[index] = layout
        }
        break
      case K.FETCH_LAYOUTS_SUCCESS:
        const layouts = action.payload as Store.Layout[]
        draft.data = layouts
        break
      case K.DELETE_LAYOUT_SUCCESS:
        {
          const id = action.payload as number
          const index = draft.data.findIndex((item) => item.id === id)
          if (index > -1) {
            draft.data.splice(index, 1)
          }
        }
        break

      case K.SET_STATUS:
        draft.state = action.payload as LoadingState
        break
    }
  })
}

export default reducer
