import React from 'react'
import { Col, Form, Row, Select, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'
import { CrudOperation } from 'types/enums'

const { Paragraph } = Typography

interface Props {
  data: Store.MasterSecurity[]
  filter: (symbol: string) => void
  onSymbolChange: () => void
  mode: CrudOperation
  loading: boolean
}

const { Option } = Select

const SymbolField: React.FC<Props> = ({ data, filter, onSymbolChange, mode, loading }) => {
  const { t } = useTranslation()

  const search = (value: string) => {
    if (value && value.length > 0) {
      filter(value)
    }
  }
  const readonly = mode === CrudOperation.Update

  return (
    <Row align="middle" gutter={[5, 0]}>
      <Col span={12}>
        <Paragraph>{t('assets.symbol')}</Paragraph>
      </Col>
      <Col span={12}>
        <Form.Item
          name="symbol"
          rules={[{ required: true, message: t('error.requiredField') }]}
          className={readonly && 'readonly'}
        >
          <Select
            getPopupContainer={(trigger) => trigger.parentElement}
            showSearch
            loading={loading}
            optionFilterProp="children"
            onSearch={search}
            showArrow={false}
            filterOption={false}
            disabled={readonly}
            onSelect={onSymbolChange}
            placeholder="Start typing to get suggestions..."
          >
            {data.map((item) => {
              return (
                <Option value={item.id} key={item.id}>
                  {item.symbol}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  )
}

export default SymbolField
