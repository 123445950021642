import React, { useEffect, useState } from 'react'
import * as Store from 'types/store'

import { Typography } from 'antd'

import { Metric } from 'sockets/metrics_report_pb'
import AnalysisModelTable from './AnalysisModelTable'
import { useTranslation } from 'react-i18next'
import { withVisible } from 'components/hoc'
import ConsumptionChart from '../ConsumptionChart'
import { groupBy, sumBy } from 'lodash'

interface Props {
  assets: Store.AssetMetric[]
  metrics: Metric.AsObject[]
  setTimeInterval: (interval: [Date, Date]) => void
  filteredValues: Json
  setFilteredValues: (values: Json) => void
}

const AnalysisModelStatusTable: React.FC<Props> = ({
  assets,
  metrics,
  setTimeInterval,
  filteredValues,
  setFilteredValues,
}) => {
  const { t } = useTranslation()
  const [isFiltered, setIsFiltered] = useState(false)
  const [filteredAssets, setAssets] = useState(assets)
  useEffect(() => {
    if (!isFiltered) {
      setAssets(assets)
    }
  }, [assets])

  const filteredMetrics = metrics.filter(({ key }) => filteredAssets.find(({ cmpKey }) => key === cmpKey))

  const data = groupBy(filteredMetrics, 'timestamp')

  const ramData: [Date, number][] = Object.keys(data).map((timestamp) => [
    new Date(timestamp),
    sumBy(data[timestamp], (d) => d.resource.ram),
  ])

  const cpuData: [Date, number][] = Object.keys(data).map((timestamp) => [
    new Date(timestamp),
    sumBy(data[timestamp], (d) => d.resource.cpu),
  ])

  return (
    <section className="table-container">
      <Typography.Title level={2}>{t('operativeSession.analysisModelStatus')}</Typography.Title>
      <AnalysisModelTable
        assets={assets}
        setFilteredAssets={(assets) => {
          setAssets(assets)
          setIsFiltered(true)
        }}
        filteredValues={filteredValues}
        setFilteredValues={setFilteredValues}
      />
      <div className="chart-container">
        <ConsumptionChart cpuData={cpuData} ramData={ramData} setTimeInterval={setTimeInterval} />
      </div>
    </section>
  )
}

export default withVisible(AnalysisModelStatusTable)
