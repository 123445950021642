import { FrameType, SessionType } from 'types/enums'
import { ViewType } from 'types/ui'
import { featureFlags } from './config'

export const getViewTypeByFrameType = (type: FrameType): ViewType => {
  switch (type) {
    case FrameType.Chart:
    case FrameType.Table:
    case FrameType.MapView:
      return ViewType.Details
    case FrameType.ReturnAnalysis:
    case FrameType.PositionMonitoring:
      return ViewType.Graph
    case FrameType.TechnicalChart:
      return ViewType.AnalysisModel
    default:
      return ViewType.Details
  }
}

export const getDrawerFrames = (sessionType: SessionType): FrameType[] => {
  const defaultFrameTypes = [
    FrameType.MapView,
    FrameType.SignalsTable,
    FrameType.ReturnAnalysis,
    // it was requested to remove the frame - FH: 29/07/2022
    // FrameType.ForwardAnalysis,
    FrameType.PositionMonitoring,
    FrameType.Custom,
  ]

  switch (sessionType) {
    case SessionType.BackTest:
      if (featureFlags.backtestChartlessStatistics) {
        return [FrameType.StatisticsChartless, FrameType.MapView, FrameType.Custom]
      }
      return [FrameType.Statistics, FrameType.MapView, FrameType.Custom]
    case SessionType.Virtual:
      return [FrameType.TechnicalChart, ...defaultFrameTypes]
    default:
      return defaultFrameTypes
  }
}
