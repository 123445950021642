import React from 'react'
import * as Store from 'types/store'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import HistoricalReturnAnalysis from 'v2/containers/frames/Historical/ReturnAnalysis'
import ReturnAnalysis from 'v2/containers/frames/ReturnAnalysis'

interface Props {
  frame: Store.Frame
}

const ReturnAnalysisFrame: React.FC<Props> = ({ frame }) => {
  const session = useSelector(selectOperativeSessionSession)
  if (session.isHistorical) {
    return <HistoricalReturnAnalysis frame={frame} />
  }

  return <ReturnAnalysis frame={frame} />
}

export default ReturnAnalysisFrame
