import * as UI from 'types/ui'

declare let window
const { localStorage } = window

const LOCAL_RESOLUTIONS = 'RESOLUTIONS'

export const getResolutions = async (): Promise<UI.Resolution[]> => {
  const json = localStorage.getItem(LOCAL_RESOLUTIONS)
  if (!json) {
    return null
  }

  return JSON.parse(json) as UI.Resolution[]
}

export const setResolutions = async (resolutions: UI.Resolution[]): Promise<void> => {
  //Save in storage
  localStorage.setItem(LOCAL_RESOLUTIONS, JSON.stringify(resolutions))
}
