import { SessionStatus, SessionType } from 'types/enums'
import { ApiSessionStatus, ApiSessionType, SocketSessionStatus } from 'types/enums.api'

export const getSessionType = (value: ApiSessionType): SessionType => {
  switch (value) {
    case ApiSessionType.BackTest:
      return SessionType.BackTest
    case ApiSessionType.Signals:
      return SessionType.Signals
    case ApiSessionType.Trading:
      return SessionType.Trading
    case ApiSessionType.Virtual:
      return SessionType.Virtual
    default:
      return SessionType.Unknown
  }
}

export const getSessionStatus = (value: ApiSessionStatus): SessionStatus => {
  switch (value) {
    case ApiSessionStatus.Broken:
      return SessionStatus.Broken
    case ApiSessionStatus.Completed:
      return SessionStatus.Completed
    case ApiSessionStatus.Off:
      return SessionStatus.Off
    case ApiSessionStatus.Running:
      return SessionStatus.Running
    case ApiSessionStatus.Created:
      return SessionStatus.Created
    case ApiSessionStatus.StandBy:
      return SessionStatus.StandBy
    case ApiSessionStatus.Stopped:
      return SessionStatus.Stopped
    case ApiSessionStatus.Updating:
      return SessionStatus.Updating
    case ApiSessionStatus.Terminating:
      return SessionStatus.Terminating
    default:
      return SessionStatus.Unknown
  }
}

export const getSocketSessionStatus = (value: SocketSessionStatus): SessionStatus => {
  switch (value) {
    case SocketSessionStatus.Broken:
      return SessionStatus.Broken
    case SocketSessionStatus.Completed:
      return SessionStatus.Completed
    case SocketSessionStatus.Off:
      return SessionStatus.Off
    case SocketSessionStatus.Running:
      return SessionStatus.Running
    case SocketSessionStatus.Created:
      return SessionStatus.Created
    case SocketSessionStatus.StandBy:
      return SessionStatus.StandBy
    case SocketSessionStatus.Stopped:
      return SessionStatus.Stopped
    case SocketSessionStatus.Updating:
      return SessionStatus.Updating
    case SocketSessionStatus.Terminating:
      return SessionStatus.Terminating
    default:
      return SessionStatus.Unknown
  }
}
