// IconFont https://www.iconfont.cn/

import { createFromIconfontCN } from '@ant-design/icons'

const homepage = process.env.REACT_APP_HOMEPAGE
const IconFont = createFromIconfontCN({
  scriptUrl: `${homepage}/iconfonts/iconfonts.js`,
})

export default IconFont
