import React, { useContext, useEffect } from 'react'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
import * as Store from 'types/store'
import { BUFFERS } from 'core'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import { useStrategySigmaSnapshotState } from 'hooks'
import PanelContent from 'v2/components/frames/StrategyCandleStick/PanelContent'
import sigmaServices from 'config/sigmaServices'
import { StrategyFeed } from 'types/enums.api'

const { Position, Return, Operations, ReferenceData } = sigmaServices.Strategies

const PanelContentContainer: React.FC = () => {
  const context = useContext(CandleStickFrameContext)
  const { resource, setOffline } = context
  const strategy = resource as Store.Strategy
  const session = useSelector(selectOperativeSessionSession)
  const { id: sessionId } = session

  const [data1, isOffline1, lastTick1] = useStrategySigmaSnapshotState(
    strategy.id,
    sessionId,
    [Position.StgCartesianPosition],
    StrategyFeed.Position,
    BUFFERS.Small,
  )

  const [data2, isOffline2, lastTick2] = useStrategySigmaSnapshotState(
    strategy.id,
    sessionId,
    [Return.StgTradRetBcList],
    StrategyFeed.Return,
    BUFFERS.Small,
  )
  const [data3, isOffline3, lastTick3] = useStrategySigmaSnapshotState(
    strategy.id,
    sessionId,
    [Operations.StgCartesianOperationsList],
    StrategyFeed.Operations,
    BUFFERS.Small,
  )
  const [data4, isOffline4, lastTick4] = useStrategySigmaSnapshotState(
    strategy.id,
    sessionId,
    [ReferenceData.StgCartesianHeading],
    StrategyFeed.ReferenceData,
    BUFFERS.Small,
  )

  const data = { ...data1, ...data2, ...data3, ...data4 }
  const isOffline = isOffline1 || isOffline2 || isOffline3 || isOffline4

  //notify to the context that we are offline
  useEffect(() => {
    setOffline(isOffline)
  }, [lastTick1, lastTick2, lastTick3, lastTick4, isOffline])

  return <PanelContent sigmas={data} />
}

export default PanelContentContainer
