import React from 'react'

const isWdyrEnabled = window.localStorage.getItem('EnableWdyr')
if (isWdyrEnabled) {
  // eslint-disable-next-line
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  })
}
