import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateLayout } from 'store/layouts/actions'
import { selectLayoutsData } from 'store/layouts/selectors'
import LayoutsTable from 'v2/components/settings/LayoutsTable'
import { CrudOperation, Versions } from 'types/enums'
import Debug from 'debug'
import { loadLayout } from 'store/dashboard/actions'
import DeleteLayout from '../modals/DeleteLayout'
import { LayoutAction } from 'types/ui'
import CreateLayout from '../modals/CreateLayout'
import { filterbySessionType, filterByVersion } from 'services/dashboardService'
import { selectOperativeSessionSession } from 'store/pages/selectors'

const debug = new Debug('Frontend')

const LayoutsTableContainer: React.FC = () => {
  const dispatch = useDispatch()
  const [action, setAction] = useState<LayoutAction>(null)
  const layouts = useSelector(selectLayoutsData)
  const session = useSelector(selectOperativeSessionSession)

  if (!session) {
    return null
  }

  //Ensure display only layout for current version
  const filteredLayoutsbyVersion = filterByVersion(layouts, Versions.V2)
  //Ensure display only layout for current session type
  const filteredLayoutsbySessionType = filterbySessionType(filteredLayoutsbyVersion, session.type)

  const onRowClick = (rowAction: LayoutAction) => {
    const { resource: layout } = rowAction
    switch (rowAction.operation) {
      case CrudOperation.Create:
        setAction(rowAction)
        break
      case CrudOperation.Read:
        dispatch(loadLayout(layout))
        break
      case CrudOperation.Update:
        dispatch(updateLayout(layout))
        break
      case CrudOperation.Delete:
        setAction(rowAction)
        break
      default:
        debug('onSelectLayout', 'Unknown action')
    }
  }

  return (
    <>
      <LayoutsTable layouts={filteredLayoutsbySessionType} onRowClick={onRowClick} />
      {action && action.operation === CrudOperation.Delete && (
        <DeleteLayout layout={action.resource} onClose={() => setAction(null)} />
      )}
      {action && action.operation === CrudOperation.Create && (
        <CreateLayout onClose={() => setAction(null)} />
      )}
    </>
  )
}

export default LayoutsTableContainer
