import { Button, Col, Form, Input, Row, Typography, Space, Avatar, Table, Select, Popover, Tag, Alert } from 'antd'
import type { SelectProps } from 'antd'
import AppContentCard from 'components/AppContentCard'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CloseIcon from 'assets/icon/close-icon.svg'
import DependsOn from 'components/DependsOn'
import { CrudOperation } from 'types/enums'
import * as Store from 'types/store'
import styles from '../AssetForm/styles.module.scss'
import { checkSubmitButtonBeDisabled } from 'core/helpers'
import { useForm } from 'antd/lib/form/Form'
import { selectGroupsErrorCode } from 'store/resources/selectors'
import { MultiModelFormContainer } from 'containers/StrategyViewer/AssetForm/MultiModelForm'
import { MultiModelParametersContainer } from 'containers/StrategyViewer/AssetForm/MultiModelParameters'
// import { TableRowSelection } from 'antd/lib/table/interface'
import { fetchAssetsToOverride } from 'api/groups'
import { isApiError } from 'core'
import * as Api from 'types/api'
import { OverrideModal } from 'containers/StrategyViewer/modals/OverrideModal'
import { getAssetsAlreadyInvolvedIntoSessions } from 'api/sessions'
import { QuestionCircleOutlined } from '@ant-design/icons'

const { Title, Paragraph } = Typography

// const { Search } = Input;

interface Props {
  group: Store.Group
  assets: Store.Asset[]
  model?: any
  loading: boolean
  onSave: (data) => void
  onClose: () => void
  mode: CrudOperation
  overrideParams: boolean
  modelHasChanged: boolean
  setModelHasChanged: React.Dispatch<React.SetStateAction<boolean>>
  setOverrideParams: React.Dispatch<React.SetStateAction<boolean>>
}

const paramDisplayName = (input: string): string | null => {
  // Param name can be found after the first dot and before the next dot
  const regex = /(?<=\.)[^.]+(?=\.)/
  const match = input.match(regex)
  return match ? match[0] : null
}

const AssetItem = ({ name, disabled, singleModel, tagColor, hasGroup }) => {

  if (!disabled) {
    return (
      <div className='asset-item'>
        {name} {singleModel && <Tag color={tagColor}>Single</Tag>}
      </div>
    )
  }

  const popoverTitle = hasGroup ? 'This asset is already associated with a group' : 'This asset is already involved in a session'

  return (
    <Popover content={name} title={popoverTitle} trigger="hover">
      <div className='asset-item'>
        {name} {singleModel && <Tag>Single</Tag>} &nbsp;<QuestionCircleOutlined />
      </div>
    </Popover>
  )

}

export const GroupForm: React.FC<Props> = ({
  group,
  assets,
  loading,
  onSave,
  onClose,
  mode,
  overrideParams,
  setOverrideParams,
  modelHasChanged,
  setModelHasChanged,
}) => {
  const { t } = useTranslation()
  const [form] = useForm()

  const formRef = useRef(null)

  let formDisabled = false

  const errorCode = useSelector(selectGroupsErrorCode)

  const [tableAssets, setSelectOptionsAssets] = useState<SelectProps['options']>([])
  const [selectedAssets, setSelectedAssets] = useState<SelectProps['options']>([])
  const [overrideModalOpen, setOverrideModalOpen] = useState<boolean>(false)
  const [singleModelWarning, setSingleModelWarning] = useState<boolean>(false)
  const [selectWarningStatus, setSelectWarningStatus] = useState<boolean>(false)
  const [groupHasModelAssigned, setGroupHasModelAssigned] = useState<boolean>(false)
  const [assetsToOverride, setAssetsToOverride] = useState<string[]>([])
  const [paramsModified, setParamsModified] = useState<string[]>([])
  const [formDataForModal, setFormDataForModal] = useState(null)
  const [unavailableAssets, setUnavailableAssets] = useState<number[]>([])


  // useEffect(() => {
  //   formDisabled = checkSubmitButtonBeDisabled(form)
  //   setSelectOptionsAssets(mappedAssetsForComboBox)
  //   console.log('FORM', form.getFieldsValue())
  // }, [form])

  const checkForParametersToOverride = async (groupId: number): Promise<string[]> => {
    const res = await fetchAssetsToOverride(groupId)

    if (isApiError(res)) {
      console.error('Error: Unable to fetch assets params to override')
      return []
    }
  
    return (res as Api.AssetsToOverride).content
  }

  useEffect(() => {
    const checkUnavailableAssets = async () => {
      const response = await getAssetsAlreadyInvolvedIntoSessions()

      let unavailableAssets: number[] = []

      response?.data?.application_asset_model_instance.forEach((res) => {
        if (unavailableAssets.length === 0 || !unavailableAssets.includes(res.asset.id)) {
          unavailableAssets.push(res.asset.id)
        }
      })

      assets.forEach((asset) => {
        if (asset?.group && group && asset?.group?.id !== group.id || asset.group && !group) {
          if (unavailableAssets.length === 0 || !unavailableAssets.includes(asset.id)) {
            unavailableAssets.push(asset.id)
          }
        }
      })

      setUnavailableAssets(unavailableAssets)
    }

    checkUnavailableAssets()
  }, [])

  useEffect(() => {
    const mappedAssetsForComboBox: SelectProps['options'] = assets.map((a, index) => {
      const dis = unavailableAssets.includes(a.id)
      const singleModel = a.modelClass && a.modelClass?.type === 'Single'

      return {
        value: a.id,
        displayName: a.name,
        singleModel: singleModel,
        label: <AssetItem name={a.name} disabled={dis} hasGroup={a.group} singleModel={singleModel} tagColor="none" />,
        symbol: a.symbol,
        group: a.group,
        disabled: dis,
        key: `${a.securityId}_${index + 1}`,
      }
    })

    setSelectOptionsAssets(mappedAssetsForComboBox)

    if (mode === CrudOperation.Update) {
      const alreadySelectedAssets = mappedAssetsForComboBox.filter((a: any) => group.assetIds.includes(a.value))

      setSelectedAssets(alreadySelectedAssets)

      setGroupHasModelAssigned(!!group.modelId)

    }

    if (group?.id) {
      const getOverrideAssetList = async () => {
        const data = await checkForParametersToOverride(group.id)
  
        if (data) {
          setAssetsToOverride(data)
        }
      }

      getOverrideAssetList()


      setGroupHasModelAssigned(!!group.modelId)
    }
  }, [unavailableAssets])

  useEffect(() => {
    setGroupHasModelAssigned(true)
  }, [modelHasChanged])

  useEffect(() => {
    form.setFieldsValue({assetIds: selectedAssets.map(a => a.value)})
    checkSingleModelAssociation()
  }, [selectedAssets])

  useEffect(() => {
    // TODO: handle other errors
    if (errorCode.errorDescription.includes('group already exists')) {
      form.scrollToField('name')
      form.getFieldInstance('name').focus()
      form.setFields([{ name: 'name', errors: [errorCode.errorDescription] }])
    }
  }, [errorCode])

  useEffect(() => {
    if (mode !== CrudOperation.Update) {
      return
    }

    form.setFieldsValue({
      id: group.id,
      name: group.name,
      modelId: group.modelId,
      assetIds: group.assetIds,
      // analysisModel: {parameters: group.multiAssetModelParameters},
      deleteAssets: group.deleteAssets,
    })
    // const formValues: Store.GroupForm = mapAssetToAssetForm(group)
    // form.setFieldsValue(formValues)
  }, [group])

  const checkforTouchedMultiAssetFields = (changedValue, allFields) => {
    changedValue.forEach((field) => {
      if (field.touched) {
        // The complete field name is generally the 2nd one
        field.name.forEach(name => {
          if (name.includes('multiAssetModelParam')) {
            const displayName = paramDisplayName(name)
            if (!paramsModified.includes(displayName)) {
              setParamsModified([...paramsModified, displayName])
            }
          }
        })
      }
    })
  }

  const checkAndSave = (data) => {
    if (mode === CrudOperation.Update && paramsModified.length > 0 && assetsToOverride.length > 0) {
      setOverrideModalOpen(true)
      setFormDataForModal(data)
      return
    } else if (mode === CrudOperation.Create && assetsToOverride.length > 0) {
      setOverrideModalOpen(true)
      setFormDataForModal(data)
      // setOverrideParams(true)
      return
    }
    onSave(data)
  }

  const handleChange = (val: any[]) => {
    const currentSelectedIds = Object.values(val)

    const filteredAssets = tableAssets.filter(asset => 
      currentSelectedIds.includes(asset.value)
    )

    setSelectedAssets(filteredAssets)

  }

  const checkSingleModelAssociation = () => {
    if (selectedAssets.length === 0) {
      setSingleModelWarning(false)
      return
    }

    setSingleModelWarning(false)

    selectedAssets.some((selectedAsset) => {
      if (selectedAsset.singleModel) {
        setSingleModelWarning(true)
        setSelectWarningStatus(true)
        return
      }
    })
  }

  return (
    <AppContentCard fullHeight>
      <Form
        form={form}
        ref={formRef}
        onFinish={checkAndSave}
        className={`${styles['asset-form']} group-form`}
        onFieldsChange={checkforTouchedMultiAssetFields}
      >
        <Row align="middle" className="col-title">
          <Col span={14}>
            <Title>{mode === CrudOperation.Create ? 'Add' : 'Update'} Group</Title> {/* JI-TRANSLATE */}
          </Col>

          <Col span={10}>
            <Row justify="end">
              <Space>
                <Form.Item className="mb-0">
                  <Button
                    type="dashed"
                    className="secondary"
                    loading={loading}
                    disabled={loading}
                    onClick={onClose}
                  >
                    {t('general.cancel')}
                  </Button>
                </Form.Item>

                <Form.Item className="mb-0" shouldUpdate={true}>
                  <Button
                    type="primary"
                    className="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={loading || formDisabled}
                  >
                    {t('general.save')}
                  </Button>
                </Form.Item>
              </Space>
            </Row>
          </Col>

          {mode === CrudOperation.Read && (
            <Col span={4} className="align-right">
              <Button className="col-close" type="ghost" onClick={onClose}>
                <Avatar shape="square" src={CloseIcon} size={24} />
              </Button>
            </Col>
          )}
        </Row>

        <section className="inner-content">
          <Row align="middle" gutter={[5, 0]}>

            {/* HIDDEN FIELD FOR ASSETS IDS */}
            <Form.Item name="assetIds" noStyle>
              <Input type="hidden" name="assetIds" />
            </Form.Item>
            {/* END HIDDEN FIELD FOR ASSETS IDS */}

            {/* HIDDEN FIELD FOR DELETE ASSETS */}
            <Form.Item name="deleteAssets" noStyle>
              <Input type="hidden" name="deleteAssets" />
            </Form.Item>
            {/* END HIDDEN FIELD FOR DELETE ASSETS */}

            {/* HIDDEN FIELD FOR GROUP ID */}
            <Form.Item name="id" noStyle>
              <Input type="hidden" name="id" />
            </Form.Item>
            {/* END HIDDEN FIELD FOR GROUP ID */}

            {/* HIDDEN FIELD FOR MODEL ID */}
            <Form.Item name="modelId" noStyle>
              <Input type="hidden" name="modelId" />
            </Form.Item>
            {/* END HIDDEN FIELD FOR MODEL ID */}

            <Col span={12}>
              <Paragraph>Group Name</Paragraph>
            </Col>
            <Col span={12}>
              <Form.Item
                name="name"
                className="name"
                rules={[{ required: true, message: t('error.requiredField') }]}>
                <Input name="name" maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Typography.Text className="field-description" type="secondary">
                Group Name defined by the User (unique for each group in the system)
              </Typography.Text>
            </Col>
          </Row>

          <MultiModelFormContainer
            form={form}
            formRef={formRef}
            setModelHasChanged={setModelHasChanged}
          />
          <DependsOn fields={['modelId']}>
            {(values) => {
              const { modelId } = values
              // console.log('model value', values)
              
              if (!modelId) {
                return null
              }

              return (
                <>
                  {overrideModalOpen &&
                    <OverrideModal
                      overrideModalOpen={overrideModalOpen}
                      setOverrideModalOpen={setOverrideModalOpen}
                      listOfAssets={assetsToOverride}
                      modifiedParamsList={paramsModified}
                      overrideParams={overrideParams}
                      setOverrideParams={setOverrideParams}
                      onSave={() => onSave(formDataForModal)}
                    />
                  }
                  <MultiModelParametersContainer
                    modelId={modelId}
                    groupId={group?.id}
                    mode={mode}
                    modelHasChanged={modelHasChanged}
                  />
                </>
              )
            }}
          </DependsOn>

          <Row align="bottom">
            <Title level={2}>Assets</Title>
          </Row>
          <Row className="assets-select-messages-container" justify="space-between" align="top">
            <Col span={7}>
            <Paragraph>{`${assets.length ?? 'no'} assets available`}</Paragraph>
            </Col>
            <Col span={17}>
            {singleModelWarning && <Alert message={`Assets with single model will be overridden with the new multi asset model when you ${groupHasModelAssigned ? 'save' : 'will associate one'}`} type="warning" showIcon />}
            </Col>
          </Row>
          <Select
            allowClear
            mode="multiple"
            optionFilterProp="displayName"
            popupClassName="assets-select-popup"
            placeholder={tableAssets.length > 0 ? "Click to add assets..." : 'No assets available, create some assets first'}
            disabled={tableAssets.length === 0}
            value={selectedAssets}
            onChange={handleChange}
            options={tableAssets}
            status={selectWarningStatus && 'warning'}
            onBlur={() => setSelectWarningStatus(false)}
          />
          {/* <Search style={{ margin: '8px 0' }} placeholder="Search..." onChange={onSearch} /> */}
          {/* <Row style={{ height: 300, overflow: 'scroll' }}>
            <Table
              size="small"
              rowSelection={rowSelection as any}
              columns={[
                {title: 'Asset Name', dataIndex: 'name', key: 'name'},
                // {title: 'Asset Symbol', dataIndex: 'symbol', key: 'symbol'},
              ]}
              dataSource={tableAssets}
              pagination={false}
              className="table-assets-list"
            />
          </Row> */}
        </section>
      </Form>
    </AppContentCard>
  )
}
