import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

const Seo: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Helmet>
      <link rel="icon" href="/favicon.ico" type="image/x-icon" />
      <link rel="apple-touch-icon" href="/logo512.png" />
      <title>{t('general.headTitle')}</title>
    </Helmet>
  )
}

export default Seo
