import { areAllItemsSelected } from 'helpers/transfer/core'
import { ExtendedDataNode, NodeType, TransferContextData, TransferType } from 'types/ui'

export const getAllAssets = (node: ExtendedDataNode): TransferContextData => {
  let transferType: TransferType
  let label: string
  if (areAllItemsSelected(node.children, NodeType.Asset)) {
    transferType = TransferType.RemoveAssets
    label = 'transfer.removeAllAssets'
  } else {
    transferType = TransferType.AddAssets
    label = 'transfer.addAllAssets'
  }

  return {
    key: 'all-assets',
    label,
    transferType,
  }
}
// TODO: localization
export const getAllGroups = (node: ExtendedDataNode): TransferContextData => {
  let transferType: TransferType
  let label: string
  if (areAllItemsSelected(node.children, NodeType.Group)) {
    transferType = TransferType.RemoveGroups
    // label = 'transfer.removeAllGroups'
    label = 'Remove All Groups'
  } else {
    transferType = TransferType.AddGroups
    // label = 'transfer.addAllGroups'
    label = 'Add All Groups'
  }

  return {
    key: 'all-groups',
    label,
    transferType,
  }
}

export const getAllPortfolios = (node: ExtendedDataNode): TransferContextData => {
  let transferType: TransferType
  let label: string
  if (areAllItemsSelected(node.children, NodeType.Portfolio)) {
    transferType = TransferType.RemovePortfolios
    label = 'transfer.removeAllPortfolios'
  } else {
    transferType = TransferType.AddPortfolios
    label = 'transfer.addAllPortfolios'
  }

  return {
    key: 'all-portfolios',
    label,
    transferType,
  }
}

export const getAllStrategies = (node: ExtendedDataNode): TransferContextData => {
  let transferType: TransferType
  let label: string
  if (areAllItemsSelected(node.children, NodeType.Strategy)) {
    transferType = TransferType.RemoveStrategies
    label = 'transfer.removeAllStrategies'
  } else {
    transferType = TransferType.AddStrategies
    label = 'transfer.addAllStrategies'
  }

  return {
    key: 'all-strategies',
    label,
    transferType,
  }
}

const getMainLabel = (node: ExtendedDataNode): string => {
  const transferType: TransferType = node.selected ? TransferType.Remove : TransferType.Add
  if (transferType === TransferType.Add) {
    switch (node.type) {
      case NodeType.Portfolio:
        return 'transfer.addPortfolio'
      case NodeType.Strategy:
        return 'transfer.addStrategy'
      case NodeType.Group:
        // return 'transfer.addGroup'
        return 'Add Group'
      case NodeType.Asset:
        return 'transfer.addAsset'
      default:
        return 'undefined'
    }
  } else {
    switch (node.type) {
      case NodeType.Portfolio:
        return 'transfer.removePortfolio'
      case NodeType.Strategy:
        return 'transfer.removeStrategy'
      case NodeType.Group:
        // return 'transfer.removeGroup'
        return 'Remove Group'
      case NodeType.Asset:
        return 'transfer.removeAsset'
      default:
        return 'undefined'
    }
  }
}

export const getMainItem = (node: ExtendedDataNode): TransferContextData => {
  const transferType: TransferType = node.selected ? TransferType.Remove : TransferType.Add
  const label: string = getMainLabel(node)

  return {
    key: 'main-item',
    label,
    transferType,
  }
}
