import { getAxios } from './apiService'
import { config } from 'core'
import { getApiError, getAuthenticationHeader } from './common'
import * as Api from 'types/api'
import SocketConnection from './socketConnection'

const axios = getAxios()

export async function getAssetsMetrics(
  session: number,
  interval: number,
  startTime: string,
  endTime: string,
): Promise<Api.AssetsMetricsResponse | ApiError> {
  const header = await getAuthenticationHeader()
  try {
    const response = await axios.post(
      config.api.getAssetsMetrics(session, interval, startTime, endTime),
      header,
    )
    return response.data
  } catch (error) {
    return getApiError(error)
  }
}

export async function getSessionMetrics(
  session: number,
  interval: number,
  startTime: string,
  endTime: string,
): Promise<Api.SessionMetricsResponse | ApiError> {
  const header = await getAuthenticationHeader()
  try {
    const response = await axios.get(
      config.api.getSessionMetrics(session, interval, startTime, endTime),
      header,
    )
    return response.data
  } catch (error) {
    return getApiError(error)
  }
}

export const subscribeToAssetsMetrics = (sessionId: number, onMessage: (message: MessageEvent) => void) => {
  const { close } = new SocketConnection(
    config.api.getAssetsMetricsRealtime(sessionId),
    () => null,
    onMessage,
  )

  const unsubscribe = () => {
    close()
  }

  return unsubscribe
}

export const subscribeToSessionMetrics = (sessionId: number, onMessage: (message: MessageEvent) => void) => {
  const { close } = new SocketConnection(
    config.api.getSessionMetricsRealtime(sessionId),
    () => null,
    onMessage,
  )

  const unsubscribe = () => {
    close()
  }

  return unsubscribe
}
