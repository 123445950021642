import { createContext } from 'react'
import * as UI from 'types/ui'

export interface TechnicalFrameContextInterface {
  id: string
  squares: {
    x: number
    y: number
  }
  isFullscreen: boolean
  viewType: UI.ViewType
  timeRange: [moment.Moment, moment.Moment]
  setFullScreen: (isFullscreen: boolean) => void
  setViewType: (viewType: UI.ViewType) => void
}

export const TechnicalFrameContext = createContext<TechnicalFrameContextInterface>({
  id: null,
  squares: {
    x: 0,
    y: 0,
  },
  isFullscreen: false,
  viewType: UI.ViewType.AnalysisModel,
  setFullScreen: null,
  setViewType: null,
  timeRange: [null, null],
})
