// source: portfolio_reference_data.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.portfolios.reference.alive', null, global);
goog.exportSymbol('proto.portfolios.reference.decimal', null, global);
goog.exportSymbol('proto.portfolios.reference.end_snapshot', null, global);
goog.exportSymbol('proto.portfolios.reference.feed_event', null, global);
goog.exportSymbol('proto.portfolios.reference.feed_event.EventTypeCase', null, global);
goog.exportSymbol('proto.portfolios.reference.prt_cartesian_heading', null, global);
goog.exportSymbol('proto.portfolios.reference.prt_ref_data', null, global);
goog.exportSymbol('proto.portfolios.reference.svc_status', null, global);
goog.exportSymbol('proto.portfolios.reference.tstamp', null, global);
goog.exportSymbol('proto.portfolios.reference.update_payload', null, global);
goog.exportSymbol('proto.portfolios.reference.update_snapshot', null, global);
goog.exportSymbol('proto.portfolios.reference.update_ticker', null, global);
goog.exportSymbol('proto.portfolios.reference.update_type', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.reference.tstamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.reference.tstamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.reference.tstamp.displayName = 'proto.portfolios.reference.tstamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.reference.decimal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.reference.decimal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.reference.decimal.displayName = 'proto.portfolios.reference.decimal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.reference.prt_ref_data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.reference.prt_ref_data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.reference.prt_ref_data.displayName = 'proto.portfolios.reference.prt_ref_data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.reference.prt_cartesian_heading = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.reference.prt_cartesian_heading, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.reference.prt_cartesian_heading.displayName = 'proto.portfolios.reference.prt_cartesian_heading';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.reference.update_payload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.reference.update_payload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.reference.update_payload.displayName = 'proto.portfolios.reference.update_payload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.reference.update_snapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.reference.update_snapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.reference.update_snapshot.displayName = 'proto.portfolios.reference.update_snapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.reference.end_snapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.reference.end_snapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.reference.end_snapshot.displayName = 'proto.portfolios.reference.end_snapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.reference.update_ticker = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.reference.update_ticker, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.reference.update_ticker.displayName = 'proto.portfolios.reference.update_ticker';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.reference.alive = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.reference.alive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.reference.alive.displayName = 'proto.portfolios.reference.alive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.reference.feed_event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.portfolios.reference.feed_event.oneofGroups_);
};
goog.inherits(proto.portfolios.reference.feed_event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.reference.feed_event.displayName = 'proto.portfolios.reference.feed_event';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.reference.tstamp.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.reference.tstamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.reference.tstamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.reference.tstamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    offset: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    code: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.reference.tstamp}
 */
proto.portfolios.reference.tstamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.reference.tstamp;
  return proto.portfolios.reference.tstamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.reference.tstamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.reference.tstamp}
 */
proto.portfolios.reference.tstamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.reference.tstamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.reference.tstamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.reference.tstamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.reference.tstamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * required sint64 time = 1;
 * @return {number}
 */
proto.portfolios.reference.tstamp.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.reference.tstamp} returns this
 */
proto.portfolios.reference.tstamp.prototype.setTime = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.tstamp} returns this
 */
proto.portfolios.reference.tstamp.prototype.clearTime = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.tstamp.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required sint32 offset = 2;
 * @return {number}
 */
proto.portfolios.reference.tstamp.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.reference.tstamp} returns this
 */
proto.portfolios.reference.tstamp.prototype.setOffset = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.tstamp} returns this
 */
proto.portfolios.reference.tstamp.prototype.clearOffset = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.tstamp.prototype.hasOffset = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required uint32 code = 3;
 * @return {number}
 */
proto.portfolios.reference.tstamp.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.reference.tstamp} returns this
 */
proto.portfolios.reference.tstamp.prototype.setCode = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.tstamp} returns this
 */
proto.portfolios.reference.tstamp.prototype.clearCode = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.tstamp.prototype.hasCode = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.reference.decimal.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.reference.decimal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.reference.decimal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.reference.decimal.toObject = function(includeInstance, msg) {
  var f, obj = {
    coeff: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    exp: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.reference.decimal}
 */
proto.portfolios.reference.decimal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.reference.decimal;
  return proto.portfolios.reference.decimal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.reference.decimal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.reference.decimal}
 */
proto.portfolios.reference.decimal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setCoeff(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.reference.decimal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.reference.decimal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.reference.decimal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.reference.decimal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeSint32(
      2,
      f
    );
  }
};


/**
 * required sint64 coeff = 1;
 * @return {number}
 */
proto.portfolios.reference.decimal.prototype.getCoeff = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.reference.decimal} returns this
 */
proto.portfolios.reference.decimal.prototype.setCoeff = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.decimal} returns this
 */
proto.portfolios.reference.decimal.prototype.clearCoeff = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.decimal.prototype.hasCoeff = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required sint32 exp = 2;
 * @return {number}
 */
proto.portfolios.reference.decimal.prototype.getExp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.reference.decimal} returns this
 */
proto.portfolios.reference.decimal.prototype.setExp = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.decimal} returns this
 */
proto.portfolios.reference.decimal.prototype.clearExp = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.decimal.prototype.hasExp = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.reference.prt_ref_data.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.reference.prt_ref_data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.reference.prt_ref_data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.reference.prt_ref_data.toObject = function(includeInstance, msg) {
  var f, obj = {
    portfolioName: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    strategyName: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    strategyTarget: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.reference.prt_ref_data}
 */
proto.portfolios.reference.prt_ref_data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.reference.prt_ref_data;
  return proto.portfolios.reference.prt_ref_data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.reference.prt_ref_data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.reference.prt_ref_data}
 */
proto.portfolios.reference.prt_ref_data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPortfolioName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStrategyName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStrategyTarget(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.reference.prt_ref_data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.reference.prt_ref_data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.reference.prt_ref_data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.reference.prt_ref_data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * required string portfolio_name = 1;
 * @return {string}
 */
proto.portfolios.reference.prt_ref_data.prototype.getPortfolioName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.portfolios.reference.prt_ref_data} returns this
 */
proto.portfolios.reference.prt_ref_data.prototype.setPortfolioName = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.prt_ref_data} returns this
 */
proto.portfolios.reference.prt_ref_data.prototype.clearPortfolioName = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.prt_ref_data.prototype.hasPortfolioName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required string strategy_name = 2;
 * @return {string}
 */
proto.portfolios.reference.prt_ref_data.prototype.getStrategyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.portfolios.reference.prt_ref_data} returns this
 */
proto.portfolios.reference.prt_ref_data.prototype.setStrategyName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.prt_ref_data} returns this
 */
proto.portfolios.reference.prt_ref_data.prototype.clearStrategyName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.prt_ref_data.prototype.hasStrategyName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string strategy_target = 3;
 * @return {string}
 */
proto.portfolios.reference.prt_ref_data.prototype.getStrategyTarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.portfolios.reference.prt_ref_data} returns this
 */
proto.portfolios.reference.prt_ref_data.prototype.setStrategyTarget = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.prt_ref_data} returns this
 */
proto.portfolios.reference.prt_ref_data.prototype.clearStrategyTarget = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.prt_ref_data.prototype.hasStrategyTarget = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.reference.prt_cartesian_heading.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.reference.prt_cartesian_heading.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.reference.prt_cartesian_heading} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.reference.prt_cartesian_heading.toObject = function(includeInstance, msg) {
  var f, obj = {
    activeModelsNum: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    assetsNum: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    baseCurrency: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.reference.prt_cartesian_heading}
 */
proto.portfolios.reference.prt_cartesian_heading.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.reference.prt_cartesian_heading;
  return proto.portfolios.reference.prt_cartesian_heading.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.reference.prt_cartesian_heading} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.reference.prt_cartesian_heading}
 */
proto.portfolios.reference.prt_cartesian_heading.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActiveModelsNum(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetsNum(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.reference.prt_cartesian_heading.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.reference.prt_cartesian_heading.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.reference.prt_cartesian_heading} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.reference.prt_cartesian_heading.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * required uint32 active_models_num = 1;
 * @return {number}
 */
proto.portfolios.reference.prt_cartesian_heading.prototype.getActiveModelsNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.reference.prt_cartesian_heading} returns this
 */
proto.portfolios.reference.prt_cartesian_heading.prototype.setActiveModelsNum = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.prt_cartesian_heading} returns this
 */
proto.portfolios.reference.prt_cartesian_heading.prototype.clearActiveModelsNum = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.prt_cartesian_heading.prototype.hasActiveModelsNum = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required uint32 assets_num = 2;
 * @return {number}
 */
proto.portfolios.reference.prt_cartesian_heading.prototype.getAssetsNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.reference.prt_cartesian_heading} returns this
 */
proto.portfolios.reference.prt_cartesian_heading.prototype.setAssetsNum = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.prt_cartesian_heading} returns this
 */
proto.portfolios.reference.prt_cartesian_heading.prototype.clearAssetsNum = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.prt_cartesian_heading.prototype.hasAssetsNum = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required string base_currency = 3;
 * @return {string}
 */
proto.portfolios.reference.prt_cartesian_heading.prototype.getBaseCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.portfolios.reference.prt_cartesian_heading} returns this
 */
proto.portfolios.reference.prt_cartesian_heading.prototype.setBaseCurrency = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.prt_cartesian_heading} returns this
 */
proto.portfolios.reference.prt_cartesian_heading.prototype.clearBaseCurrency = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.prt_cartesian_heading.prototype.hasBaseCurrency = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.reference.update_payload.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.reference.update_payload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.reference.update_payload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.reference.update_payload.toObject = function(includeInstance, msg) {
  var f, obj = {
    prtRefData: (f = msg.getPrtRefData()) && proto.portfolios.reference.prt_ref_data.toObject(includeInstance, f),
    prtCartesianHeading: (f = msg.getPrtCartesianHeading()) && proto.portfolios.reference.prt_cartesian_heading.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.reference.update_payload}
 */
proto.portfolios.reference.update_payload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.reference.update_payload;
  return proto.portfolios.reference.update_payload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.reference.update_payload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.reference.update_payload}
 */
proto.portfolios.reference.update_payload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 47:
      var value = new proto.portfolios.reference.prt_ref_data;
      reader.readMessage(value,proto.portfolios.reference.prt_ref_data.deserializeBinaryFromReader);
      msg.setPrtRefData(value);
      break;
    case 48:
      var value = new proto.portfolios.reference.prt_cartesian_heading;
      reader.readMessage(value,proto.portfolios.reference.prt_cartesian_heading.deserializeBinaryFromReader);
      msg.setPrtCartesianHeading(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.reference.update_payload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.reference.update_payload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.reference.update_payload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.reference.update_payload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrtRefData();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      proto.portfolios.reference.prt_ref_data.serializeBinaryToWriter
    );
  }
  f = message.getPrtCartesianHeading();
  if (f != null) {
    writer.writeMessage(
      48,
      f,
      proto.portfolios.reference.prt_cartesian_heading.serializeBinaryToWriter
    );
  }
};


/**
 * optional prt_ref_data prt_ref_data_ = 47;
 * @return {?proto.portfolios.reference.prt_ref_data}
 */
proto.portfolios.reference.update_payload.prototype.getPrtRefData = function() {
  return /** @type{?proto.portfolios.reference.prt_ref_data} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.reference.prt_ref_data, 47));
};


/**
 * @param {?proto.portfolios.reference.prt_ref_data|undefined} value
 * @return {!proto.portfolios.reference.update_payload} returns this
*/
proto.portfolios.reference.update_payload.prototype.setPrtRefData = function(value) {
  return jspb.Message.setWrapperField(this, 47, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.reference.update_payload} returns this
 */
proto.portfolios.reference.update_payload.prototype.clearPrtRefData = function() {
  return this.setPrtRefData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.update_payload.prototype.hasPrtRefData = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional prt_cartesian_heading prt_cartesian_heading_ = 48;
 * @return {?proto.portfolios.reference.prt_cartesian_heading}
 */
proto.portfolios.reference.update_payload.prototype.getPrtCartesianHeading = function() {
  return /** @type{?proto.portfolios.reference.prt_cartesian_heading} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.reference.prt_cartesian_heading, 48));
};


/**
 * @param {?proto.portfolios.reference.prt_cartesian_heading|undefined} value
 * @return {!proto.portfolios.reference.update_payload} returns this
*/
proto.portfolios.reference.update_payload.prototype.setPrtCartesianHeading = function(value) {
  return jspb.Message.setWrapperField(this, 48, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.reference.update_payload} returns this
 */
proto.portfolios.reference.update_payload.prototype.clearPrtCartesianHeading = function() {
  return this.setPrtCartesianHeading(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.update_payload.prototype.hasPrtCartesianHeading = function() {
  return jspb.Message.getField(this, 48) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.reference.update_snapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.reference.update_snapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.reference.update_snapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.reference.update_snapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    updateType: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    key: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    eventTs: (f = msg.getEventTs()) && proto.portfolios.reference.tstamp.toObject(includeInstance, f),
    sigmas: (f = msg.getSigmas()) && proto.portfolios.reference.update_payload.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.reference.update_snapshot}
 */
proto.portfolios.reference.update_snapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.reference.update_snapshot;
  return proto.portfolios.reference.update_snapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.reference.update_snapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.reference.update_snapshot}
 */
proto.portfolios.reference.update_snapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {!proto.portfolios.reference.update_type} */ (reader.readEnum());
      msg.setUpdateType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 5:
      var value = new proto.portfolios.reference.tstamp;
      reader.readMessage(value,proto.portfolios.reference.tstamp.deserializeBinaryFromReader);
      msg.setEventTs(value);
      break;
    case 6:
      var value = new proto.portfolios.reference.update_payload;
      reader.readMessage(value,proto.portfolios.reference.update_payload.deserializeBinaryFromReader);
      msg.setSigmas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.reference.update_snapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.reference.update_snapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.reference.update_snapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.reference.update_snapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!proto.portfolios.reference.update_type} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEventTs();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.portfolios.reference.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getSigmas();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.portfolios.reference.update_payload.serializeBinaryToWriter
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.portfolios.reference.update_snapshot.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.reference.update_snapshot} returns this
 */
proto.portfolios.reference.update_snapshot.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.update_snapshot} returns this
 */
proto.portfolios.reference.update_snapshot.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.update_snapshot.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required update_type update_type = 2;
 * @return {!proto.portfolios.reference.update_type}
 */
proto.portfolios.reference.update_snapshot.prototype.getUpdateType = function() {
  return /** @type {!proto.portfolios.reference.update_type} */ (jspb.Message.getFieldWithDefault(this, 2, 1));
};


/**
 * @param {!proto.portfolios.reference.update_type} value
 * @return {!proto.portfolios.reference.update_snapshot} returns this
 */
proto.portfolios.reference.update_snapshot.prototype.setUpdateType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.update_snapshot} returns this
 */
proto.portfolios.reference.update_snapshot.prototype.clearUpdateType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.update_snapshot.prototype.hasUpdateType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required string service = 3;
 * @return {string}
 */
proto.portfolios.reference.update_snapshot.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.portfolios.reference.update_snapshot} returns this
 */
proto.portfolios.reference.update_snapshot.prototype.setService = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.update_snapshot} returns this
 */
proto.portfolios.reference.update_snapshot.prototype.clearService = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.update_snapshot.prototype.hasService = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required string key = 4;
 * @return {string}
 */
proto.portfolios.reference.update_snapshot.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.portfolios.reference.update_snapshot} returns this
 */
proto.portfolios.reference.update_snapshot.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.update_snapshot} returns this
 */
proto.portfolios.reference.update_snapshot.prototype.clearKey = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.update_snapshot.prototype.hasKey = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required tstamp event_ts = 5;
 * @return {!proto.portfolios.reference.tstamp}
 */
proto.portfolios.reference.update_snapshot.prototype.getEventTs = function() {
  return /** @type{!proto.portfolios.reference.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.reference.tstamp, 5, 1));
};


/**
 * @param {!proto.portfolios.reference.tstamp} value
 * @return {!proto.portfolios.reference.update_snapshot} returns this
*/
proto.portfolios.reference.update_snapshot.prototype.setEventTs = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.update_snapshot} returns this
 */
proto.portfolios.reference.update_snapshot.prototype.clearEventTs = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.update_snapshot.prototype.hasEventTs = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional update_payload sigmas = 6;
 * @return {?proto.portfolios.reference.update_payload}
 */
proto.portfolios.reference.update_snapshot.prototype.getSigmas = function() {
  return /** @type{?proto.portfolios.reference.update_payload} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.reference.update_payload, 6));
};


/**
 * @param {?proto.portfolios.reference.update_payload|undefined} value
 * @return {!proto.portfolios.reference.update_snapshot} returns this
*/
proto.portfolios.reference.update_snapshot.prototype.setSigmas = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.reference.update_snapshot} returns this
 */
proto.portfolios.reference.update_snapshot.prototype.clearSigmas = function() {
  return this.setSigmas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.update_snapshot.prototype.hasSigmas = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.reference.end_snapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.reference.end_snapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.reference.end_snapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.reference.end_snapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.reference.end_snapshot}
 */
proto.portfolios.reference.end_snapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.reference.end_snapshot;
  return proto.portfolios.reference.end_snapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.reference.end_snapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.reference.end_snapshot}
 */
proto.portfolios.reference.end_snapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.reference.end_snapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.reference.end_snapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.reference.end_snapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.reference.end_snapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.portfolios.reference.end_snapshot.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.reference.end_snapshot} returns this
 */
proto.portfolios.reference.end_snapshot.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.end_snapshot} returns this
 */
proto.portfolios.reference.end_snapshot.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.end_snapshot.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required string service = 2;
 * @return {string}
 */
proto.portfolios.reference.end_snapshot.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.portfolios.reference.end_snapshot} returns this
 */
proto.portfolios.reference.end_snapshot.prototype.setService = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.end_snapshot} returns this
 */
proto.portfolios.reference.end_snapshot.prototype.clearService = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.end_snapshot.prototype.hasService = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.reference.update_ticker.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.reference.update_ticker.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.reference.update_ticker} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.reference.update_ticker.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    updateType: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    sequence: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    key: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    eventTs: (f = msg.getEventTs()) && proto.portfolios.reference.tstamp.toObject(includeInstance, f),
    sigmas: (f = msg.getSigmas()) && proto.portfolios.reference.update_payload.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.reference.update_ticker}
 */
proto.portfolios.reference.update_ticker.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.reference.update_ticker;
  return proto.portfolios.reference.update_ticker.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.reference.update_ticker} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.reference.update_ticker}
 */
proto.portfolios.reference.update_ticker.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {!proto.portfolios.reference.update_type} */ (reader.readEnum());
      msg.setUpdateType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setSequence(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 6:
      var value = new proto.portfolios.reference.tstamp;
      reader.readMessage(value,proto.portfolios.reference.tstamp.deserializeBinaryFromReader);
      msg.setEventTs(value);
      break;
    case 7:
      var value = new proto.portfolios.reference.update_payload;
      reader.readMessage(value,proto.portfolios.reference.update_payload.deserializeBinaryFromReader);
      msg.setSigmas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.reference.update_ticker.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.reference.update_ticker.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.reference.update_ticker} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.reference.update_ticker.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!proto.portfolios.reference.update_type} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeSint64(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEventTs();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.portfolios.reference.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getSigmas();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.portfolios.reference.update_payload.serializeBinaryToWriter
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.portfolios.reference.update_ticker.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.reference.update_ticker} returns this
 */
proto.portfolios.reference.update_ticker.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.update_ticker} returns this
 */
proto.portfolios.reference.update_ticker.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.update_ticker.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required update_type update_type = 2;
 * @return {!proto.portfolios.reference.update_type}
 */
proto.portfolios.reference.update_ticker.prototype.getUpdateType = function() {
  return /** @type {!proto.portfolios.reference.update_type} */ (jspb.Message.getFieldWithDefault(this, 2, 1));
};


/**
 * @param {!proto.portfolios.reference.update_type} value
 * @return {!proto.portfolios.reference.update_ticker} returns this
 */
proto.portfolios.reference.update_ticker.prototype.setUpdateType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.update_ticker} returns this
 */
proto.portfolios.reference.update_ticker.prototype.clearUpdateType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.update_ticker.prototype.hasUpdateType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required string service = 3;
 * @return {string}
 */
proto.portfolios.reference.update_ticker.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.portfolios.reference.update_ticker} returns this
 */
proto.portfolios.reference.update_ticker.prototype.setService = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.update_ticker} returns this
 */
proto.portfolios.reference.update_ticker.prototype.clearService = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.update_ticker.prototype.hasService = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required sint64 sequence = 4;
 * @return {number}
 */
proto.portfolios.reference.update_ticker.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.reference.update_ticker} returns this
 */
proto.portfolios.reference.update_ticker.prototype.setSequence = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.update_ticker} returns this
 */
proto.portfolios.reference.update_ticker.prototype.clearSequence = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.update_ticker.prototype.hasSequence = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required string key = 5;
 * @return {string}
 */
proto.portfolios.reference.update_ticker.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.portfolios.reference.update_ticker} returns this
 */
proto.portfolios.reference.update_ticker.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.update_ticker} returns this
 */
proto.portfolios.reference.update_ticker.prototype.clearKey = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.update_ticker.prototype.hasKey = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * required tstamp event_ts = 6;
 * @return {!proto.portfolios.reference.tstamp}
 */
proto.portfolios.reference.update_ticker.prototype.getEventTs = function() {
  return /** @type{!proto.portfolios.reference.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.reference.tstamp, 6, 1));
};


/**
 * @param {!proto.portfolios.reference.tstamp} value
 * @return {!proto.portfolios.reference.update_ticker} returns this
*/
proto.portfolios.reference.update_ticker.prototype.setEventTs = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.update_ticker} returns this
 */
proto.portfolios.reference.update_ticker.prototype.clearEventTs = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.update_ticker.prototype.hasEventTs = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional update_payload sigmas = 7;
 * @return {?proto.portfolios.reference.update_payload}
 */
proto.portfolios.reference.update_ticker.prototype.getSigmas = function() {
  return /** @type{?proto.portfolios.reference.update_payload} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.reference.update_payload, 7));
};


/**
 * @param {?proto.portfolios.reference.update_payload|undefined} value
 * @return {!proto.portfolios.reference.update_ticker} returns this
*/
proto.portfolios.reference.update_ticker.prototype.setSigmas = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.reference.update_ticker} returns this
 */
proto.portfolios.reference.update_ticker.prototype.clearSigmas = function() {
  return this.setSigmas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.update_ticker.prototype.hasSigmas = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.reference.alive.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.reference.alive.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.reference.alive} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.reference.alive.toObject = function(includeInstance, msg) {
  var f, obj = {
    aliveId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.reference.alive}
 */
proto.portfolios.reference.alive.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.reference.alive;
  return proto.portfolios.reference.alive.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.reference.alive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.reference.alive}
 */
proto.portfolios.reference.alive.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setAliveId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.reference.alive.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.reference.alive.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.reference.alive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.reference.alive.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
};


/**
 * required sint64 alive_id = 1;
 * @return {number}
 */
proto.portfolios.reference.alive.prototype.getAliveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.reference.alive} returns this
 */
proto.portfolios.reference.alive.prototype.setAliveId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.reference.alive} returns this
 */
proto.portfolios.reference.alive.prototype.clearAliveId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.alive.prototype.hasAliveId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.portfolios.reference.feed_event.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.portfolios.reference.feed_event.EventTypeCase = {
  EVENT_TYPE_NOT_SET: 0,
  SNAPSHOT: 1,
  TICKER: 2,
  END_SNAPSHOT: 3,
  ALIVE: 4
};

/**
 * @return {proto.portfolios.reference.feed_event.EventTypeCase}
 */
proto.portfolios.reference.feed_event.prototype.getEventTypeCase = function() {
  return /** @type {proto.portfolios.reference.feed_event.EventTypeCase} */(jspb.Message.computeOneofCase(this, proto.portfolios.reference.feed_event.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.reference.feed_event.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.reference.feed_event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.reference.feed_event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.reference.feed_event.toObject = function(includeInstance, msg) {
  var f, obj = {
    snapshot: (f = msg.getSnapshot()) && proto.portfolios.reference.update_snapshot.toObject(includeInstance, f),
    ticker: (f = msg.getTicker()) && proto.portfolios.reference.update_ticker.toObject(includeInstance, f),
    endSnapshot: (f = msg.getEndSnapshot()) && proto.portfolios.reference.end_snapshot.toObject(includeInstance, f),
    alive: (f = msg.getAlive()) && proto.portfolios.reference.alive.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.reference.feed_event}
 */
proto.portfolios.reference.feed_event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.reference.feed_event;
  return proto.portfolios.reference.feed_event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.reference.feed_event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.reference.feed_event}
 */
proto.portfolios.reference.feed_event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.portfolios.reference.update_snapshot;
      reader.readMessage(value,proto.portfolios.reference.update_snapshot.deserializeBinaryFromReader);
      msg.setSnapshot(value);
      break;
    case 2:
      var value = new proto.portfolios.reference.update_ticker;
      reader.readMessage(value,proto.portfolios.reference.update_ticker.deserializeBinaryFromReader);
      msg.setTicker(value);
      break;
    case 3:
      var value = new proto.portfolios.reference.end_snapshot;
      reader.readMessage(value,proto.portfolios.reference.end_snapshot.deserializeBinaryFromReader);
      msg.setEndSnapshot(value);
      break;
    case 4:
      var value = new proto.portfolios.reference.alive;
      reader.readMessage(value,proto.portfolios.reference.alive.deserializeBinaryFromReader);
      msg.setAlive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.reference.feed_event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.reference.feed_event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.reference.feed_event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.reference.feed_event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSnapshot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.portfolios.reference.update_snapshot.serializeBinaryToWriter
    );
  }
  f = message.getTicker();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.portfolios.reference.update_ticker.serializeBinaryToWriter
    );
  }
  f = message.getEndSnapshot();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.portfolios.reference.end_snapshot.serializeBinaryToWriter
    );
  }
  f = message.getAlive();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.portfolios.reference.alive.serializeBinaryToWriter
    );
  }
};


/**
 * optional update_snapshot snapshot = 1;
 * @return {?proto.portfolios.reference.update_snapshot}
 */
proto.portfolios.reference.feed_event.prototype.getSnapshot = function() {
  return /** @type{?proto.portfolios.reference.update_snapshot} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.reference.update_snapshot, 1));
};


/**
 * @param {?proto.portfolios.reference.update_snapshot|undefined} value
 * @return {!proto.portfolios.reference.feed_event} returns this
*/
proto.portfolios.reference.feed_event.prototype.setSnapshot = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.portfolios.reference.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.reference.feed_event} returns this
 */
proto.portfolios.reference.feed_event.prototype.clearSnapshot = function() {
  return this.setSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.feed_event.prototype.hasSnapshot = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional update_ticker ticker = 2;
 * @return {?proto.portfolios.reference.update_ticker}
 */
proto.portfolios.reference.feed_event.prototype.getTicker = function() {
  return /** @type{?proto.portfolios.reference.update_ticker} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.reference.update_ticker, 2));
};


/**
 * @param {?proto.portfolios.reference.update_ticker|undefined} value
 * @return {!proto.portfolios.reference.feed_event} returns this
*/
proto.portfolios.reference.feed_event.prototype.setTicker = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.portfolios.reference.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.reference.feed_event} returns this
 */
proto.portfolios.reference.feed_event.prototype.clearTicker = function() {
  return this.setTicker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.feed_event.prototype.hasTicker = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional end_snapshot end_snapshot = 3;
 * @return {?proto.portfolios.reference.end_snapshot}
 */
proto.portfolios.reference.feed_event.prototype.getEndSnapshot = function() {
  return /** @type{?proto.portfolios.reference.end_snapshot} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.reference.end_snapshot, 3));
};


/**
 * @param {?proto.portfolios.reference.end_snapshot|undefined} value
 * @return {!proto.portfolios.reference.feed_event} returns this
*/
proto.portfolios.reference.feed_event.prototype.setEndSnapshot = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.portfolios.reference.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.reference.feed_event} returns this
 */
proto.portfolios.reference.feed_event.prototype.clearEndSnapshot = function() {
  return this.setEndSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.feed_event.prototype.hasEndSnapshot = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional alive alive = 4;
 * @return {?proto.portfolios.reference.alive}
 */
proto.portfolios.reference.feed_event.prototype.getAlive = function() {
  return /** @type{?proto.portfolios.reference.alive} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.reference.alive, 4));
};


/**
 * @param {?proto.portfolios.reference.alive|undefined} value
 * @return {!proto.portfolios.reference.feed_event} returns this
*/
proto.portfolios.reference.feed_event.prototype.setAlive = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.portfolios.reference.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.reference.feed_event} returns this
 */
proto.portfolios.reference.feed_event.prototype.clearAlive = function() {
  return this.setAlive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.reference.feed_event.prototype.hasAlive = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * @enum {number}
 */
proto.portfolios.reference.update_type = {
  SNAPSHOT: 1,
  VARIATIONS: 2
};

/**
 * @enum {number}
 */
proto.portfolios.reference.svc_status = {
  ONLINE: 0,
  OFFLINE: 1,
  BROKEN: 2
};

goog.object.extend(exports, proto.portfolios.reference);
