import React from 'react'
import IconFont from 'core/IconFont'
import { Row, Col, Typography, Button, Layout } from 'antd'
import AppContent from 'components/AppContent'
import AppContentCard from 'components/AppContentCard'
import style from './styles.module.scss'
import routes from 'config/routes'

const Error500: React.FC = () => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <section data-augmented-ui className="app-container">
        <AppContent fullHeight>
          <AppContentCard fullHeight>
            <Row className={style['error-page']}>
              <Col xs={24} className="align-center">
                <div className="icon-container">
                  <IconFont type="icon-error-icon" />
                </div>
                <Typography.Title>Si è verificato un problema</Typography.Title>
                <Typography.Paragraph>
                  <Typography.Text>Ci scusiamo per l’incoveniente, si prega di provare a </Typography.Text>
                  <Typography.Text strong>tornare in homepage</Typography.Text>.
                </Typography.Paragraph>
              </Col>
              <Col xs={6} className="align-center">
                <Button
                  block
                  size="large"
                  type="primary"
                  shape="round"
                  className="app-bg-secondary-color app-secondary-color-invert mt-20"
                >
                  <a href={routes.login.url}>Torna al login</a>
                </Button>
              </Col>
            </Row>
          </AppContentCard>
        </AppContent>
      </section>
    </Layout>
  )
}

Error500.displayName = 'Error500'

export default Error500
