import { update_snapshot, update_ticker } from 'sockets/strategies/strategy_return_pb'
import * as Store from 'types/store'
import { convertToFloat, formatSigmaDate } from '../common'
import { TimeFrame } from 'types/enums'
import colors from 'config/colors'

export const mapStrategyReturnsSigmas = (
  feed: update_snapshot.AsObject | update_ticker.AsObject,
): Store.StrategySigmas => {
  const { sigmas } = feed
  if (!sigmas) {
    return null
  }

  const { stgAllRetBcList, stgExchRetBcList, stgTradRetBcList, stgClosedCandle, stgOpenCandle } = sigmas

  return {
    returnList: stgTradRetBcList?.map((item) => ({
      tradingRetBc: convertToFloat(item.tradingRetBc) || 0,
      type: item.type as TimeFrame,
      lastEvtTs: formatSigmaDate(item?.lastEvtTs),
    })),
    closedCandle: stgClosedCandle && {
      color:
        convertToFloat(stgClosedCandle.maxTdyTradingRetBc) ===
        convertToFloat(stgClosedCandle.minTdyTradingRetBc)
          ? 'silver'
          : convertToFloat(stgClosedCandle.maxTdyTradingRetBc) >
            convertToFloat(stgClosedCandle.minTdyTradingRetBc)
          ? colors.red
          : colors.positiveGreen,
      date: formatSigmaDate(stgClosedCandle.startTs),
      endTime: formatSigmaDate(stgClosedCandle.endTs),
      high: convertToFloat(stgClosedCandle.maxTdyTradingRetBc),
      low: convertToFloat(stgClosedCandle.minTdyTradingRetBc),
      open: convertToFloat(stgClosedCandle.maxTdyTradingRetBc),
      close: convertToFloat(stgClosedCandle.minTdyTradingRetBc),
      startTime: formatSigmaDate(stgClosedCandle.startTs),
    },
    openCandle: stgOpenCandle && {
      color: '',
        // convertToFloat(stgOpenCandle.maxTdyTradingRetBc) === convertToFloat(stgOpenCandle.minTdyTradingRetBc)
        //   ? 'silver'
        //   : convertToFloat(stgOpenCandle.maxTdyTradingRetBc) >
        //     convertToFloat(lastCandle.next().value.maxTdyTradingRetBc)
        //     ? colors.positiveGreen
        //     : colors.red,
      date: formatSigmaDate(stgOpenCandle.startTs),
      endTime: formatSigmaDate(stgOpenCandle.endTs),
      high: convertToFloat(stgOpenCandle.maxTdyTradingRetBc),
      low: convertToFloat(stgOpenCandle.minTdyTradingRetBc),
      open: convertToFloat(stgOpenCandle.maxTdyTradingRetBc),
      close: convertToFloat(stgOpenCandle.minTdyTradingRetBc),
      startTime: formatSigmaDate(stgOpenCandle.startTs),
    },
    exchRetBc:
      stgExchRetBcList &&
      stgExchRetBcList.map((stgExchRetBc) => ({
        value: convertToFloat(stgExchRetBc.exchRetBc),
        type: stgExchRetBc.type,
      })),
    allRetBc:
      stgAllRetBcList &&
      stgAllRetBcList.map((stgAllRetBc) => ({
        value: convertToFloat(stgAllRetBc.allRetBc),
        type: stgAllRetBc.type,
      })),
  } as Store.StrategySigmas
}
