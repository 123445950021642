import React, { useState } from 'react'
import * as Api from 'types/api'
import * as api from 'api/assets'
import BankAccount from 'components/StrategyViewer/forms/AssetForm/BankAccount'
import { isApiError } from 'core'
import * as Store from 'types/store'
import { CrudOperation } from 'types/enums'
import DependsOn from 'components/DependsOn'

const getBankAccounts = async (
  currency: string,
  mic: string,
  symbol: string,
  market: string,
): Promise<Store.BankAccount[]> => {
  const response = await api.getBankAccounts(currency, mic, symbol, market)
  if (isApiError(response)) {
    return null
  }

  const masterSecurityResponse = response as Api.BankAccountResponse

  return masterSecurityResponse.data.application_bank_account_security.map((item) => ({
    account: item.bank_account,
  }))
}

interface Props {
  mode: CrudOperation
}

const BankAccountContainer: React.FC<Props> = ({ mode }) => {
  const [data, setData] = useState<Store.BankAccount[]>(null)
  const [currency, setCurrency] = useState(null)

  const readonly = mode === CrudOperation.Update || !currency

  const getData = async (currency: string, mic: string, symbol: string, market: string) => {
    const data = await getBankAccounts(currency, mic, symbol, market)
    setData(data)
  }

  const setCurrencyIfDifferent = (newCurrency: string, mic: string, symbol: string, market: string) => {
    if (!newCurrency) {
      return
    }
    if (newCurrency !== currency) {
      setCurrency(newCurrency)
      getData(newCurrency, mic, symbol, market)
    }
  }

  return (
    <DependsOn fields={['mic', 'symbol', 'market', 'currency']}>
      {(fields) => {
        const { mic, symbol, market, currency: newCurrency } = fields
        setCurrencyIfDifferent(newCurrency, mic, symbol, market)

        return <BankAccount data={data} readonly={readonly} />
      }}
    </DependsOn>
  )
}

export default BankAccountContainer
