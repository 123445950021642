import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { removeFrame } from 'store/dashboard/actions'
import { selectOperativeSessionAssets, selectOperativeSessionSession } from 'store/pages/selectors'
import { Modal } from 'antd'
import * as Store from 'types/store'
import { GridItemActions } from 'types/ui'
import { SessionStatus } from 'types/enums'
import * as api from 'api/backtest'
import * as Api from 'types/api'
import { isApiError } from 'core'
import Debug from 'debug'
import { jsonToIndicatorStatistics } from 'services/store/mapService'
import SelectAssetsModal from 'v2/components/SelectAssetsModal'
import Statistics from 'v2/short-term/components/Statistics'
import { selectAssets } from 'store/resources/selectors'
import { showError } from 'core/helpers'
import FileDownload from 'js-file-download'
import Spinner from 'components/Spinner'

const debug = new Debug('frontend')

const getIndicatorStatistics = async (
  sessionId: number,
  assetIds: number[],
): Promise<Store.IndicatorStatistics> => {
  const response = await api.historicalIndicatorsStatistics(sessionId, assetIds)
  if (isApiError(response)) {
    debug('ERROR getDistributionStatistics')
    return null
  }

  const data = jsonToIndicatorStatistics(response as Api.IndicatorStatistics)
  return data
}

const generateReport = async (sessionId: number, assetIds: number[]) => {
  const response = await api.generateBackTestReport(sessionId, assetIds) as Api.BackTestReportResponse
  if (isApiError(response)) {
    debug('ERROR getDistributionStatistics')
    return null
  }

  return {
    data: response.data,
    filename: response.filename
  }
}

interface Props {
  frame: Store.Frame
}

const StatisticsContainer: React.FC<Props> = ({ frame }) => {
  const dispatch = useDispatch()
  const session = useSelector(selectOperativeSessionSession)
  // const assets = session.isHistorical ? useSelector(selectAssets) : useSelector(selectOperativeSessionAssets)
  // Retrieve session assets disregarding the type of session
  const assets = useSelector(selectOperativeSessionAssets)
  const isSessionRunning =
    session.status === SessionStatus.Running ||
    session.status === SessionStatus.Updating ||
    session.status === SessionStatus.Terminating

  const [indicatorData, setindicatorData] = useState<Store.IndicatorStatistics>(null)

  const [isFullScreen, setFullScreen] = useState(false)
  const [isDownloading, setDownloading] = useState(false)

  const [selectedAssets, setSelectedAssets] = useState<Store.Asset[]>(assets)
  const [isModalVisible, setModalVisible] = useState(false)

  const getIndicatorData = async () => {
    const assetIds = selectedAssets.map((asset) => asset.id)
    const data = await getIndicatorStatistics(session.id, assetIds)
    debug('Data statistics', data)
    if (data) {
      setindicatorData(data)
    }
  }

  useEffect(() => {
    if (isSessionRunning) {
      //data are not required
      return
    }

    getIndicatorData()
  }, [selectedAssets, isSessionRunning])

  const toggleFullscreen = () => {
    setFullScreen((state) => !state)
  }

  const downloadReport = async () => {
    setDownloading(true)
    const assetIds = selectedAssets.map((asset) => asset.id)
    const assetPayload = assetIds.length === assets.length ? null : assetIds
    const res = await generateReport(session.id, assetPayload)
    debug('res', res)
    if (!res) {
      showError(null)
      debug('The response resulted with error. The pdf is generated from null')
      return
    }
    FileDownload(res.data as Blob, res.filename)
    setDownloading(false)
  }

  const openSummaryLink = async () => {
    const HTML = await api.getSummaryReportHTML(session.id)

    if (isApiError(HTML)) {
      throw new Error('Unable to retrieve summary page data')
    }

    // Open a new window
    const newWindow = window.open('', '_blank')

    if (newWindow) {
      // Insert the HTML content
      newWindow.document.write(HTML as string)
      newWindow.document.close()
    } else {
      alert('Failed to open summary window')
    }
  }

  const openOperationsLink = async () => {
    const HTML = await api.getOperationsReportHTML(session.id)

    if (isApiError(HTML)) {
      throw new Error('Unable to retrieve operations page data')
    }

    // Open a new window
    const newWindow = window.open('', '_blank')

    if (newWindow) {
      // Insert the HTML content
      newWindow.document.write(HTML as string)
      newWindow.document.close()
    } else {
      alert('Failed to open operation window')
    }
  }

  const onItemClick = (action: GridItemActions) => {
    switch (action) {
      case GridItemActions.Close:
        dispatch(removeFrame(frame))
        break
      case GridItemActions.Edit:
        setModalVisible(true)
        break
      case GridItemActions.FullScreen:
        toggleFullscreen()
        break
      case GridItemActions.Download:
        downloadReport()
        break
      case GridItemActions.OpenSummaryReportPage:
        openSummaryLink()
        break
      case GridItemActions.OpenOperationsReportPage:
        openOperationsLink()
        break
    }
  }

  const panelContent = (
    <>
      {isModalVisible && (
        <SelectAssetsModal
          onClose={() => setModalVisible(false)}
          onSelect={(assets) => setSelectedAssets(assets)}
          selectedAssets={selectedAssets}
        />
      )}
      <Statistics
        isSessionRunning={isSessionRunning}
        onClick={onItemClick}
        selectedAssets={selectedAssets}
        isFullScreen={isFullScreen}
        indicatorData={indicatorData}
        session={session}
      />
    </>
  )

  if (isDownloading) {
    return <Spinner />
  }

  if (isFullScreen) {
    return (
      <Modal
        open={true}
        centered
        width={'80%'}
        wrapClassName={'statistics-fullscreen nonDraggable'}
        footer={null}
        closable={false}
      >
        {panelContent}
      </Modal>
    )
  }

  return panelContent
}

export default StatisticsContainer
