import React from 'react'
import * as Store from 'types/store'
import { Menu, Typography } from 'antd'
import { NodeType } from 'types/ui'
import { useTranslation } from 'react-i18next'
import { FrameType } from 'types/enums'
import styles from './styles.module.scss'

interface Props {
  selectedResource: Store.SelectedResource
  onItemClick: (type: FrameType) => void
}

const BacktestContextMenu: React.FC<Props> = ({ selectedResource, onItemClick }) => {
  const { t } = useTranslation()

  if (!selectedResource) {
    return null
  }

  const { resource, type } = selectedResource
  const name = resource ? resource.name : ''

  const getMenu = (title: string) => (
    <>
      <Typography.Title>{title}</Typography.Title>
      <Typography.Paragraph>{name}</Typography.Paragraph>
      <Menu>
        <Menu.Item key="add" onClick={() => onItemClick(FrameType.Chart)}>
          {t('chart.addGraph')}
        </Menu.Item>
      </Menu>
    </>
  )

  let menu
  switch (type) {
    case NodeType.Asset:
      menu = getMenu(t('general.asset'))
      break
    case NodeType.Portfolio:
      menu = getMenu(t('general.portfolio'))
      break
    case NodeType.Strategy:
      menu = getMenu(t('general.strategy'))
      break
    default:
      menu = null
      break
  }
  if (!menu) {
    return null
  }

  return (
    <div onContextMenu={(e) => e.preventDefault()} className={styles['context-menu']}>
      {menu}
    </div>
  )
}

export default BacktestContextMenu
