import React from 'react'
import * as Store from 'types/store'
import { Col, Form, Row, Select, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
const { Paragraph } = Typography

interface Props {
  data: Store.BankAccount[]
  readonly: boolean
}

const { Option } = Select

const BankAccount: React.FC<Props> = ({ data, readonly }) => {
  const { t } = useTranslation()

  // console.log('BankAccountField', data)

  return (
    <Row align="middle" gutter={[5, 0]}>
      <Col span={12}>
        <Paragraph>{t('assets.bankAccount')}</Paragraph>
      </Col>
      <Col span={12}>
        <Form.Item
          name="bankAccount"
          rules={[{ required: true, message: t('error.requiredField') }]}
          className={classnames({ ['readonly']: readonly })}
        >
          {data && (
            <Select getPopupContainer={(trigger) => trigger.parentElement} disabled={readonly} placeholder="Select banck account...">
              {data.map((item) => {
                return (
                  <Option value={item.account} key={item.account}>
                    {item.account}
                  </Option>
                )
              })}
            </Select>
          )}
        </Form.Item>
      </Col>
    </Row>
  )
}

export default BankAccount
