import React from 'react'
import * as Store from 'types/store'
import { Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'

interface Props {
  layout: Store.Layout
  editing: boolean
  dataIndex: string
  title: string
}

const LayoutCell: React.FC<Props> = ({ layout, editing, dataIndex, title, children, ...restProps }) => {
  const { t } = useTranslation()
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          className="layout-input"
          name={dataIndex}
          initialValue={layout.name}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: t('settings.missingInput', { p0: title }),
            },
          ]}
        >
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

export default LayoutCell
