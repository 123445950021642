import React from 'react'
import * as Store from 'types/store'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import HistoricalPortfolioTable from 'v2/containers/frames/Historical/PortfolioTable'
import PortfolioTable from 'v2/containers/frames/PortfolioTable'

interface Props {
  frame: Store.Frame
}

const PortfolioTableFrame: React.FC<Props> = ({ frame }) => {
  const session = useSelector(selectOperativeSessionSession)
  if (session.isHistorical) {
    return <HistoricalPortfolioTable frame={frame} />
  }

  return <PortfolioTable frame={frame} />
}

export default PortfolioTableFrame
