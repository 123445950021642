import * as d3 from 'd3'
import React, { JSXElementConstructor, ReactElement } from 'react'
import { ChartChild } from 'types/chart'
import Debug from 'debug'
import colors from 'config/colors'

const debug = new Debug('Chart')

export function wrap(text: d3.Selection<d3.BaseType, unknown, any, unknown>, textWidth: number) {
  text.each(function () {
    const text = d3.select(this)
    const words = text.text().split(/\s+/)
    const lineHeight = 1.1 // ems
    const y = text.attr('y')
    const dy = parseFloat(text.attr('dy'))

    let line = [],
      tspan = text
        .text(null)
        .append('tspan')
        .attr('x', 0)
        .attr('y', y)
        .attr('dy', dy + 'em'),
      lineNumber = 0

    words.forEach((word) => {
      line.push(word)
      tspan.text(line.join(' '))
      if (tspan.node().getComputedTextLength() > textWidth) {
        line.pop()
        tspan.text(line.join(' '))
        line = [word]
        tspan = text
          .append('tspan')
          .attr('x', 0)
          .attr('y', y)
          .attr('dy', ++lineNumber * lineHeight + dy + 'em')
          .text(word)
      }
    })
  })
}

export function colorizer(text: d3.Selection<d3.BaseType, unknown, any, unknown>) {
  let color = colors.red
  text.each(function () {
    const text = d3.select(this)
    const value = text.text()

    if (value == '0') {
      color = colors.positiveGreen
    } else {
      text.attr('fill', color)
    }
  })
}

export const renderChildren = (children: React.ReactNode, props: ChartChild) => {
  return React.Children.map(children, (child) => {
    let childsProps = {}
    try {
      childsProps = (child as ReactElement<any, string | JSXElementConstructor<any>>).props
    } catch (e) {
      debug('error accured while getting childs props', e)
    }

    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...props, ...childsProps })
    }
    return child
  })
}
