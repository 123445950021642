import { Modal, Typography, Button, Row, Space } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import AppContentCard from 'components/AppContentCard'
import Markdown from 'react-markdown'
import styles from './styles.module.scss'

const { Title } = Typography

interface Props {
  onClose: () => void
  error: string
}

const CsvModalError: React.FC<Props> = ({ onClose, error }) => {
  const { t } = useTranslation()

  const getErrorLines = (error: string) => {
    return error?.replaceAll(', ', '\r\n\r\n')
  }

  return (
    <Modal
      centered
      title={null}
      width={'50%'}
      open={true}
      onCancel={onClose}
      footer={null}
      closable={false}
    >
      <AppContentCard>
        <Title level={2} className="mb-16">
          {t('notification.type.error')}
        </Title>
        <section className={styles['error-message']}>
          <Markdown>{getErrorLines(error)}</Markdown>
        </section>
        <Row justify="end" className="mt-30">
          <Space>
            <Button type="primary" key="back" onClick={onClose}>
              {t('general.ok')}
            </Button>
          </Space>
        </Row>
      </AppContentCard>
    </Modal>
  )
}

export default CsvModalError
