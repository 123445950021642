import * as K from './constants'
import * as Api from 'types/api'
import { put, call, takeLatest, all } from 'redux-saga/effects'
import Debug from 'debug'
import * as api from 'api/strategies'
import * as Store from 'types/store'
import { CrudOperation, ErrorCode, LoadingState } from 'types/enums'
import { ApplicationError, isApiError, isGraphQlError } from 'core'
import { jsonToStrategies } from 'services/store/mapService'
import { ExtendedDataNode, NodeType } from 'types/ui'
import { setUserAction } from 'store/pages/strategy/actions'
import { AnyAction } from 'redux'

const debug = Debug('Frontend')

export function* fetchStrategies() {
  debug('Saga Strategies')
  const response = yield call(api.getStrategies)
  if (isApiError(response) || isGraphQlError(response)) {
    throw new ApplicationError(ErrorCode.Api, 'fetch getStrategies failed')
  }

  const json = (response as Api.StrategiesResponse).data.application_strategy

  const strategies = jsonToStrategies(json)
  debug('strategies', strategies, response)

  // save in redux
  yield put({ type: K.STRATEGIES_FETCH_SUCCESS, payload: strategies })
}

export function* deleteStrategy(action: AnyAction) {
  debug('Delete Strategy')
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Deleting })
    const id = action.payload as number

    const response = yield call(api.deleteStrategy, id)

    if (isApiError(response as ApiError)) {
      throw new ApplicationError(ErrorCode.Api, 'delete strategy failed')
    }

    yield put({ type: K.STRATEGY_DELETE_SUCCESS, payload: id })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Deleted })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.DeleteFailed })
    debug('delete Strategy error', error)
  }
}

export function* updateStrategy(action: AnyAction) {
  debug('Update Strategy')
  yield put({ type: K.SET_STATUS, payload: LoadingState.Updating })
  yield put({ type: K.SET_ERROR, payload: null })
  try {
    const postData = action.payload as Api.StrategyRest

    const response = yield call(api.updateStrategy, postData)

    if (isApiError(response as ApiError)) {
      yield put({ type: K.SET_ERROR, payload: (response.data as Api.RESTResponse)?.result?.responseEnum })
      throw new ApplicationError(ErrorCode.Api, 'updateStrategies failed')
    }

    yield put({ type: K.STRATEGY_UPDATE_SUCCESS, payload: postData })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Updated })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.UpdateFailure })
    debug('updateStrategies error', error)
  }
}

export function* createStrategy(action: AnyAction) {
  debug('create Strategy')
  yield put({ type: K.SET_STATUS, payload: LoadingState.Creating })
  yield put({ type: K.SET_ERROR, payload: null })
  try {
    const postData = action.payload as Api.StrategyRest

    const response = yield call(api.createStrategy, postData)

    if (isApiError(response as ApiError)) {
      debug('create Strategy failed', response, response as ApiError)
      yield put({ type: K.SET_ERROR, payload: (response.data as Api.RESTResponse)?.result?.responseEnum })
      throw new ApplicationError(ErrorCode.Api, 'create strategy failed')
    }

    const { content } = response as Api.StrategyCreateFeed

    const strategy: Store.Strategy = {
      ...postData,
      id: content.strategyId,
      creationDate: new Date(),
      lastModDate: new Date(),
      creationUserid: null, //TBD
      lastModUserid: null, //TBD
      ownerId: null, //TBD
    }

    //New strategy just created: select it
    const selectedNode: ExtendedDataNode = {
      key: 'new strategy',
      resource: strategy,
      type: NodeType.Strategy,
      isDeletable: true,
    }
    yield put(setUserAction(selectedNode, CrudOperation.Read))

    yield put({ type: K.STRATEGY_CREATE_SUCCESS, payload: strategy })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Created })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.CreateFailure })
    debug('create Strategy error', error)
  }
}

function* watch() {
  yield all([
    takeLatest(K.STRATEGY_EDIT, updateStrategy),
    takeLatest(K.STRATEGY_DELETE, deleteStrategy),
    takeLatest(K.STRATEGY_CREATE, createStrategy),
  ])
}

export default watch
