import moment from 'moment'
import { getTimeDifference } from 'services/chartsService'

export const getIntervalSeconds = (minuteDifference: number) => {
  if (minuteDifference < 5) {
    return 5
  }
  if (minuteDifference < 15) {
    return 10
  }
  if (minuteDifference < 6 * 60) {
    return 15
  }
  if (minuteDifference < 12 * 60) {
    return 30
  }
  if (minuteDifference < 24 * 60) {
    return 60
  }
  return 120
}

export const getPoolInterval = (minuteDifference) => {
  if (minuteDifference < 5) {
    return 2
  }
  if (minuteDifference < 15) {
    return 5
  }
  if (minuteDifference < 6 * 60) {
    return 7
  }
  if (minuteDifference < 12 * 60) {
    return 15
  }
  if (minuteDifference < 24 * 60) {
    return 30
  }
  return 60
}

export const getPoolingParams = (interval: [Date, Date]) => {
  if (interval) {
    const [from, to] = interval
    const diff = getTimeDifference(from, to)

    const range = Math.floor(diff / 1000 / 60)
    const seconds = getIntervalSeconds(range)
    const poolInterval = getPoolInterval(range)

    const extraRange = diff * 1.5

    const fromWithExtraRange = new Date(from.getTime() - extraRange)
    const toWithExtraRange = new Date(to.getTime() + extraRange)

    return { seconds, fromWithExtraRange, toWithExtraRange, poolInterval }
  }
}

export const getLastIntervalTime = (from: Date, to: Date, seconds: number) => {
  let lastTO = moment(from)

  const lastAvaliableTime = moment(to).subtract(1, 'second')

  while (lastTO.clone().add(seconds, 'seconds').isBefore(lastAvaliableTime)) {
    lastTO = lastTO.clone().add(seconds, 'seconds')
  }

  return lastTO
}
