import React from 'react'
import AppContent from 'components/AppContent'
import Sessions from 'containers/Sessions'
import { Breadcrumb } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useMount } from 'react-use'
import { LoadingState } from 'types/enums'
import { fetchSessions } from 'store/sessions/actions'
import { useTranslation } from 'react-i18next'
import { selectSessions, selectSessionsState } from 'store/sessions/selectors'
import NoSessions from 'components/Sessions/NoSessions'

const SessionsPage: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const loadingState = useSelector(selectSessionsState)
  // const sessions = useSelector(selectSessions)

  useMount(() => {
    dispatch(fetchSessions())
  })

  if (loadingState === LoadingState.Loading) {
    return null
  }

  // if (!sessions.length) {
  //   return <NoSessions />
  // }

  return (
    <>
      <AppContent>
        <Breadcrumb>
          <Breadcrumb.Item>{t('general.sessions')}</Breadcrumb.Item>
        </Breadcrumb>
        <Sessions />
      </AppContent>
    </>
  )
}

export default SessionsPage
