import React from 'react'
import * as Store from 'types/store'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import HistoricalPositionMonitoring from 'v2/containers/frames/Historical/PositionMonitoring'
import PositionMonitoring from 'v2/containers/frames/PositionMonitoring'

interface Props {
  frame: Store.Frame
}

const PositionMonitoringFrame: React.FC<Props> = ({ frame }) => {
  const session = useSelector(selectOperativeSessionSession)
  if (session.isHistorical) {
    return <HistoricalPositionMonitoring frame={frame} />
  }

  return <PositionMonitoring frame={frame} />
}

export default PositionMonitoringFrame
