const FF_CONFIG = 'FF_CONFIG'

const getFeatureFlagsJson = (): Json<boolean> => {
  const featureFlagConfig = typeof localStorage !== 'undefined' && localStorage.getItem(FF_CONFIG)
  if (featureFlagConfig) {
    return JSON.parse(featureFlagConfig)
  }
  return {}
}

export const setFeatureFlag = (feature: string, value: boolean) => {
  const json = getFeatureFlagsJson()
  json[feature] = value
  localStorage.setItem(FF_CONFIG, JSON.stringify(json))
}

export const resetFeatureFlags = () => {
  localStorage.removeItem(FF_CONFIG)
}

export const getFeatureFlags = (featureFlags: Json<boolean>): Json<boolean> => {
  const json = getFeatureFlagsJson()

  const modifiedFeatureFlags = Object.entries(featureFlags).reduce((featureFlags, [key, value]) => {
    featureFlags[key] = key in json ? json[key] : value
    return featureFlags
  }, {})

  return modifiedFeatureFlags
}
