import { getAxios } from './apiService'
import { config } from 'core'
import { getApiError, getAuthenticationHeader } from './common'
import * as Api from 'types/api'
const axios = getAxios()

export async function getHistogram(): Promise<Array<number> | ApiError> {
  const header = await getAuthenticationHeader()
  try {
    const response = await axios.get(config.api.getHistogramData(), header)
    return response.data
  } catch (error) {
    return getApiError(error)
  }
}

export async function getOperationsBarchartIndicators(
  assetId: number,
  sessionId: number,
  startIso: string,
  endIso: string,
): Promise<Api.OperationBarsChartIndicatorsResponse | ApiError> {
  const header = await getAuthenticationHeader()

  try {
    const response = await axios.get(
      config.api.getOperationsBarchartIndicators(assetId, sessionId, startIso, endIso),
      header,
    )
    return response.data
  } catch (error) {
    return getApiError(error)
  }
}

export async function getOperationsBarcharts(
  assetId: number,
  sessionId: number,
  startIso: string,
  endIso: string,
): Promise<Api.OperationBarsChartResponse | ApiError> {
  const header = await getAuthenticationHeader()

  try {
    const response = await axios.get(
      config.api.getOperationsBarchart(assetId, sessionId, startIso, endIso),
      header,
    )
    return response.data
  } catch (error) {
    return getApiError(error)
  }
}

export async function getHistoricalBarChartData(
  assetId: number,
  sessionId: number,
): Promise<Api.OperationBarsChartHistoricalResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `query ($sessionId: Int, $assetId: Int){
      historical_data_asset_tdy_op_indicators_v(where: { operative_session_id: { _eq: $sessionId }, asset_id: { _eq: $assetId } }) {
        asset_tdy_lng_pos_op
        asset_tdy_lng_neg_op
        asset_tdy_trad_ret_lng_pos
        asset_tdy_trad_ret_lng_neg
        asset_tdy_dt_princ_lng_pos
        asset_tdy_dt_princ_lng_neg
        asset_tdy_avg_princ_lng_pos
        asset_tdy_avg_princ_lng_neg
        asset_tdy_avg_princ_lng
        asset_tdy_std_dev_lng_neg
        asset_tdy_std_dev_lng_pos
        asset_tdy_std_dev_lng
        asset_tdy_shr_pos_op
        asset_tdy_shr_neg_op
        asset_tdy_trad_ret_shr_pos
        asset_tdy_trad_ret_shr_neg
        asset_tdy_dt_princ_shr_pos
        asset_tdy_trad_ret
        asset_tdy_dt_princ_shr_neg
        asset_tdy_avg_princ_shr_pos
        asset_tdy_avg_princ_shr_neg
        asset_tdy_avg_princ_shr
        asset_tdy_std_dev_shr_pos
        asset_tdy_std_dev_shr_neg
        asset_tdy_std_dev_shr
        asset_tdy_pos_op
        asset_tdy_neg_op
        asset_tdy_avg_princ_pos
        asset_tdy_avg_princ_neg
        asset_tdy_avg_princ
        asset_tdy_std_dev_pos
        asset_tdy_std_dev_neg
        asset_tdy_std_dev
        asset_tdy_dt_princ
        end_time
        operative_session_id
        asset_id
        asset_tdy_mean
        asset_tdy_mean2
        asset_tdy_mean_lng
        asset_tdy_mean_lng2
        asset_tdy_mean_lng_neg
        asset_tdy_mean_lng_neg2
        asset_tdy_mean_lng_pos
        asset_tdy_mean_lng_pos2
        asset_tdy_mean_neg
        asset_tdy_mean_neg2
        asset_tdy_mean_pos
        asset_tdy_mean_pos2
        asset_tdy_mean_shr
        asset_tdy_mean_shr2
        asset_tdy_mean_shr_neg
        asset_tdy_mean_shr_neg2
        asset_tdy_mean_shr_pos
        asset_tdy_mean_shr_pos2
      }
      }`,
    variables: {
      sessionId,
      assetId,
    },
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}
