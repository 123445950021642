import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import { generatePath, Link, matchPath, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AppContentCard from 'components/AppContentCard'
import SettingsModal from 'v2/components/settings/modals/SettingsModal'
import MainLogo from 'assets/img/logo.png'
import classNames from 'classnames'

import IconFont from 'core/IconFont'
import { Layout, Menu, Row, Col, Typography, Avatar, Space } from 'antd'
import styles from './styles.module.scss'
import { SessionType } from 'types/enums'

import { getSessionName } from 'services/sessionService'
import DashboardSelector from 'v2/containers/DashboardSelector'
import ProfileIcon from 'containers/user-profile/ProfileIcon/ProfileIcon'
import { useSelector } from 'react-redux'
import { selectUser } from 'store/user/selectors'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import { getCurrentRoute } from 'core/helpers'
import { themes } from './themes'
import { useTheme } from 'providers/ThemeProvider'
import { useFeature } from 'flagged'
import { features } from 'config/features'
import SessionStatusIndicator from 'v2/containers/Header/SessionStatusIndicator'
import { UserRoles } from 'types/enums.api'
import { selectEnabledSessionTypes } from 'store/platform/selectors'

const menuItems = [
  { key: routes.strategies.key, url: routes.strategies.url },
  { key: routes.sessions.key, url: routes.sessions.url },
  { key: routes.launch.key, url: routes.launch.url },
  { key: routes.marketData.key, url: routes.marketData.url },
]

const Header: React.FC = () => {
  const { t } = useTranslation()
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const location = useLocation()
  const hideLogo = useFeature(features.hideLogo) as boolean
  const displayLogo = !hideLogo
  const session = useSelector(selectOperativeSessionSession)
  const user = useSelector(selectUser)
  const enabledSessionTypes = useSelector(selectEnabledSessionTypes)

  const sessionName = getSessionName(session)

  type LaunchMenuType = {
    name: SessionType
    key: string
    label: JSX.Element
    disabled?: boolean
  }

  const [launchMenuItems, setLaunchMenuItems] = useState<LaunchMenuType[]>([
    {
      name: SessionType.Trading,
      key: 'sessionType.trading',
      label: (
        <Link to={generatePath(routes.launch.url, { type: SessionType.Trading.toLowerCase() })}>
          {t('sessionType.trading')}
        </Link>
      ),
    },
    {
      name: SessionType.Signals,
      key: 'sessionType.signals',
      label: (
        <Link to={generatePath(routes.launch.url, { type: SessionType.Signals.toLowerCase() })}>
          {t('sessionType.signals')}
        </Link>
      ),
    },
    {
      name: SessionType.Virtual,
      key: 'sessionType.virtual',
      label: (
        <Link to={generatePath(routes.launch.url, { type: SessionType.Virtual.toLowerCase() })}>
          {t('sessionType.virtual')}
        </Link>
      ),
    },
    {
      name: SessionType.BackTest,
      key: 'sessionType.backtest',
      label: (
        <Link to={generatePath(routes.launch.url, { type: SessionType.BackTest.toLowerCase() })}>
          {t('sessionType.backtest')}
        </Link>
      ),
    },
  ])

  useEffect(() => {
    if (!enabledSessionTypes) {
      return
    }
    const mappedSessionTypes = enabledSessionTypes.map((type) => type.toLowerCase())
    // Disable menu items that are not enabled
    const updatedLaunchMenuItems = launchMenuItems.map((item) => {
      return {
        ...item,
        disabled: !mappedSessionTypes.includes(item.name),
      }
    })
    setLaunchMenuItems(updatedLaunchMenuItems)
  }, [enabledSessionTypes])

  const headerClass = classNames(styles['header'], themeStyles['theme'])

  const isAdminOrSuperAdmin = user?.roles?.some(
    (role) => role === UserRoles.Admin || role === UserRoles.SuperAdmin,
  )
  // if not matched returns null
  const match = matchPath(routes.operativeSession.url, location.pathname)

  const getSelectItem = (): Array<string> => {
    const route = getCurrentRoute(location.pathname)
    const menuItem = menuItems.find((item) => item.key === route.menu)

    if (!menuItem) {
      return null
    }
    return [menuItem?.key]
  }

  let menu = [
    {
      key: 'strategies',
      label: (<Link to={routes.strategies.url}>{t('menu.strategies')}</Link>)
    },
    {
      key: 'sessions',
      label: (<Link to={routes.sessions.url}>{t('menu.sessions')}</Link>)
    },
    {
      key: routes.launch.key,
      label: t('menu.launch'),
      children: launchMenuItems,
    }
  ]

  const adminMenuItem = {
    key: 'admin',
    label: (<Link to={routes.admin.url}>{t('menu.admin')}</Link>)
  }

  isAdminOrSuperAdmin && menu.push(adminMenuItem)

  return (
    <Layout.Header className={headerClass}>
      <AppContentCard>
        <Row align="middle" className="header-wrapper">
          <Col flex="1 1 50%">
            <div className="title-wrapper">
              {displayLogo && <Avatar src={MainLogo} className="app-logo" shape="square" />}
              {match && (
                <Space>
                  <Typography.Title className="session-title">
                    {sessionName} {t('operativeSession.session')}
                  </Typography.Title>

                  <SessionStatusIndicator />

                  <DashboardSelector />
                </Space>
              )}
            </div>
          </Col>

          <Col flex="1 1 auto">
            <Menu
              selectedKeys={getSelectItem()}
              defaultSelectedKeys={getSelectItem()}
              mode="horizontal"
              className="app-main-menu"
              items={menu}
            >
            </Menu>
          </Col>

          <Col>
            <div className="config-profile-wrapper">
              {match && <SettingsModal />}

              <ProfileIcon user={user} />
            </div>
          </Col>
        </Row>
      </AppContentCard>
    </Layout.Header>
  )
}

export default React.memo(Header)
