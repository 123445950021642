import React, { useContext } from 'react'
import { Dropdown, Button, Menu, Typography, Modal, Popover } from 'antd'
import { CheckOutlined, DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { AnalysisWindowTabType, Dimension, TimeFrame } from 'types/enums'
import classnames from 'classnames'
import * as Store from 'types/store'
import { FrameSize, GridItemActions, ViewType } from 'types/ui'
import ContextMenu from '../ContextMenu'
import { CELL_HEIGHT, CELL_WIDTH, confirmFrameSize, getGridItemClass } from 'services/dashboardService'
import { useWindowSize } from 'react-use'
import TableContents from 'v2/components/TableContents'
import generalStyles from './styles.module.scss'
import smallStyles from './small.module.scss'
import mediumStyles from './medium.module.scss'
import largeStyles from './large.module.scss'
import TableTitle from 'v2/containers/AnalysisWindow/TableTitle'
import ViewTypeSelector from '../ViewTypeSelector'
import colors from 'config/colors'
import { AnalysisFrameContext } from 'contexts/AnalysisFrameContext'
import { selectPopupContainer } from 'core/helpers'
interface Props {
  data: Store.ReturnAnalysis[][]
  onDimensionClick: (dimension: number, value: Dimension) => void
  onContextMenuItemClick: (action: GridItemActions) => void
  baseCurrency: string
}

const ReturnAnalysis: React.FC<Props> = ({
  data,
  onDimensionClick,
  onContextMenuItemClick,
  baseCurrency,
}) => {
  const { t } = useTranslation()
  const context = useContext(AnalysisFrameContext)
  const {
    squares,
    viewType,
    timeFrame,
    setViewType,
    setTimeFrame,
    firstDimension,
    secondDimension,
    isFullscreen,
    setFullscreen,
    isOffline,
  } = context

  const { width: windowWidth, height: windowHeight } = useWindowSize()

  const [width, height] = isFullscreen
    ? [windowWidth, windowHeight]
    : [squares.x * CELL_WIDTH, squares.y * CELL_HEIGHT]

  const isLarge = confirmFrameSize(width, height, FrameSize.Large)

  const filteredData = data.map((item) => {
    return item.find((returnAnalysis) => returnAnalysis?.type === timeFrame)
  })

  const toggleFullscreen = () => {
    setFullscreen(!isFullscreen)
  }

  const onMenuItemClick = (action: GridItemActions) => {
    if (action === GridItemActions.FullScreen) {
      toggleFullscreen()
      return
    }
    onContextMenuItemClick(action)
  }

  const menu = (
    <Menu className="nonDraggable" onClick={({ key }) => setTimeFrame(key as TimeFrame)}>
      <Menu.Item key={TimeFrame.TDY}>{t('general.TDY')}</Menu.Item>
      <Menu.Item key={TimeFrame.YTD}>{t('general.YTD')}</Menu.Item>
    </Menu>
  )

  const dimensions = [
    {
      key: Dimension.Market,
      name: t('dimensions.market'),
    },
    {
      key: Dimension.Currency,
      name: t('dimensions.currency'),
    },
    {
      key: Dimension.AssetClass,
      name: t('dimensions.assetClass'),
    },
    {
      key: Dimension.LongShort,
      name: t('dimensions.longShort'),
    },
  ]

  const firstDimensionMenu = (
    <Menu className="nonDraggable" onClick={({ key }) => handleFirstDimensionClick(key as Dimension)}>
      {dimensions.map(({ key, name }) => (
        <Menu.Item key={key}>{name}</Menu.Item>
      ))}
    </Menu>
  )

  const secondDimensionMenu = (
    <Menu className="nonDraggable" onClick={({ key }) => handleSecondDimensionClick(key as Dimension)}>
      {dimensions.map(({ key, name }) => {
        const hasContent = key !== firstDimension
        return (
          <Menu.Item
            key={key}
            className={classnames({
              'empty-cell--dimension': !hasContent,
            })}
          >
            {hasContent && name}
          </Menu.Item>
        )
      })}
    </Menu>
  )

  const handleFirstDimensionClick = (name: string) => {
    onDimensionClick(1, name as Dimension)
  }

  const handleSecondDimensionClick = (name: string) => {
    onDimensionClick(2, name as Dimension)
  }

  const troubleIcon = isOffline && (
    <Popover content={t('error.technicalProblems')} trigger="hover">
      <ExclamationCircleOutlined style={{ color: colors.red }} className="mr-5" />
    </Popover>
  )

  const panelContent = (
    <section
      className={classnames(
        generalStyles['return-analysis'],
        smallStyles['return-analysis'],
        mediumStyles['return-analysis'],
        largeStyles['return-analysis'],
        {
          'offline-frame': isOffline,
        },
      )}
    >
      <div className={classnames(getGridItemClass(width, height), { 'full-screen': isFullscreen })}>
        <div className="frame-header">
          <div className="frame-header__top-row">
            {troubleIcon}
            <Typography.Title level={3} className="title">
              {t('frameType.returnAnalysis')}
            </Typography.Title>

            <ContextMenu
              isFullscreen={isFullscreen}
              onMenuItemClick={onMenuItemClick}
              items={[
                {
                  key: GridItemActions.FullScreen,
                  label: t('contextMenu.fullscreen'),
                  icon: isFullscreen ? <CheckOutlined /> : null,
                },
                { key: GridItemActions.Edit, label: t('contextMenu.edit') },
                !isFullscreen && { key: GridItemActions.Close, label: t('contextMenu.close') },
              ]}
            />
          </div>
        </div>

        <div className="frame-content">
          <div className="top-row-wrapper">
            <div className="analysis-config-row nonDraggable">
              {viewType === ViewType.Graph || isFullscreen || isLarge ? (
                <div className="dimensions-selector">
                  <Dropdown
                    overlay={firstDimensionMenu}
                    trigger={['click']}
                    getPopupContainer={(trigger) => selectPopupContainer(trigger, isFullscreen)}
                    className="dropdown dropdown--dimension"
                  >
                    <Button type="dashed">
                      {dimensions.find((obj) => obj.key === firstDimension).name} <DownOutlined />
                    </Button>
                  </Dropdown>

                  <Dropdown
                    overlay={secondDimensionMenu}
                    trigger={['click']}
                    getPopupContainer={(trigger) => selectPopupContainer(trigger, isFullscreen)}
                    className="dropdown dropdown--dimension"
                  >
                    <Button type="dashed">
                      {secondDimension
                        ? dimensions.find((obj) => obj.key === secondDimension).name
                        : t('dimensions.second')}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
              ) : (
                <TableTitle tabType={AnalysisWindowTabType.ReturnAnalysis} />
              )}

              <ViewTypeSelector value={viewType} onChange={setViewType} />
            </div>

            {viewType === ViewType.Graph && (
              <Dropdown
                overlay={menu}
                trigger={['click']}
                getPopupContainer={(trigger) => selectPopupContainer(trigger, isFullscreen)}
                className="dropdown dropdown--time-horizon nonDraggable"
              >
                <Button type="dashed">
                  {timeFrame} <DownOutlined />
                </Button>
              </Dropdown>
            )}
          </div>

          {/* <SelectAssetsModalContainer tabType={AnalysisWindowTabType.ReturnAnalysis} /> */}

          <TableContents
            data={filteredData}
            returnAnalysisData={data}
            tabType={AnalysisWindowTabType.ReturnAnalysis}
            baseCurrency={baseCurrency}
            isLarge={isLarge}
          />
        </div>
      </div>
    </section>
  )

  if (isFullscreen) {
    return (
      <Modal
        open={true}
        centered
        width={'100%'}
        wrapClassName={'analysis-fullscreen nonDraggable'}
        footer={null}
        closable={false}
      >
        {panelContent}
      </Modal>
    )
  }

  return panelContent
}

export default ReturnAnalysis
