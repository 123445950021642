import { debug } from "debug"
import { all, call, put, takeLatest } from "redux-saga/effects"
import * as api from 'api/sessions'
import * as K from './constants'
import { ApplicationError, isApiError } from "core"
import { ErrorCode, SessionType } from "types/enums"


export function* fetchEnabledSessionsTypes() {
  debug('Saga Enables Sessions')
  try {
    const response = yield call(api.getEnabledSessionTypes)
    if (isApiError(response)) {
      debug('fetch getSessions failed', response)
      throw new ApplicationError(ErrorCode.Api, 'fetch enabled sessions types failed')
    }

    debug('Enabled Sessions Types', response)
    // save in redux
    yield put({ type: K.SET_ERROR, payload: null })
    yield put({ type: K.FETCH_ENABLED_SESSION_SUCCESS, payload: response as SessionType[] })
  } catch (error) {
    yield put({ type: K.SET_ERROR, payload: 'Error retrieving enabled session types' })
    debug('getSessions Error', error)
  }
}

function* watch() {
  yield all([
    takeLatest(K.FETCH_ENABLED_SESSION_TYPES, fetchEnabledSessionsTypes),
  ])
}

export default watch
