import * as Store from 'types/store'

import { sumBy, groupBy } from 'core/helpers'
import { formatNumber } from 'core/formats'
import { SunburstData } from 'types/chart'
import { Dimension, Instruments } from 'types/enums'
import { NanToZero, translateInstruments } from 'helpers/common'
import { PositionMonitoring } from 'types/store'
import { t } from 'core/i18n'

type ReduceOnValue = 'valueLong' | 'value' | 'valueShort'

const getColorPercent = (item: SunburstData, positive: number, negative: number) => {
  const { value } = item

  return (value / (value > 0 ? positive : negative)) * 100
}

const addPercent = (items: SunburstData[]): SunburstData[] => {
  const [positive, negative] = items.reduce(
    (sum, { value }) => {
      if (value > 0) {
        sum[0] += value
      } else {
        sum[1] += value
      }
      return sum
    },
    [0, 0],
  )
  const total: number = sumBy('value')(items.map((item) => ({ value: Math.abs(item.value) })))

  items.forEach((item) => {
    item.percent = `${formatNumber((Math.abs(item.value) / total) * 100)}%`
    item.colorPercent = getColorPercent(item, positive, negative)
  })
  return items
}

const nameRenderer = (name: string, dimension: Dimension) => {
  if (dimension === Dimension.AssetClass) {
    return translateInstruments(name as Instruments)
  }
  return name
}

export const getDimensionTitle = (dimention: Dimension) => {
  switch (dimention) {
    case Dimension.AssetClass:
      return t('dimensions.assetClass')
    case Dimension.Currency:
      return t('dimensions.currency')
    case Dimension.Market:
      return t('dimensions.market')
  }
}

export const groupByDimensions = (
  items: Store.PositionMonitoring[],
  dimension1: Dimension,
  dimension2: Dimension,
  reduceOn: ReduceOnValue = 'value',
): SunburstData[] => {
  let chartData: SunburstData[]

  if (dimension1 === Dimension.LongShort) {
    chartData = groupByDimensionsLongShort(items, dimension2)
  } else {
    chartData = groupByDimensionsDefault(items, dimension1, dimension2, reduceOn)
  }

  //add percents
  addPercent(chartData)

  //Remove value if 2nd dimension (but we require in advance to calculate percent%)
  if (dimension2) {
    chartData.forEach((element) => {
      delete element['value']
    })
  }

  return chartData
}

const isReturnAnalysis = (items: Store.PositionMonitoring[]): boolean => {
  if (items.length === 0) {
    return false
  }
  const item = items[0]
  if ((item as Store.ReturnAnalysis).type) {
    return true
  }
}

const groupByDimensionsLongShort = (
  items: Store.PositionMonitoring[],
  dimension2: Dimension,
): SunburstData[] => {
  //GG: better to refactor properly if it will become more complicated
  let longPositions: PositionMonitoring[]
  let shortPositions: PositionMonitoring[]

  if (isReturnAnalysis(items)) {
    longPositions = items.filter((item) => item.valueLong)
    shortPositions = items.filter((item) => item.valueShort)
  } else {
    longPositions = items.filter((item) => item.valueLong && item.valueLong > 0)
    shortPositions = items.filter((item) => item.valueShort && item.valueShort > 0)
  }

  const output: SunburstData[] = [
    {
      name: t('chart.long'),
      value: sumBy('valueLong')(longPositions),
      children: dimension2 ? groupByDimensions(longPositions, dimension2, null, 'valueLong') : null,
      columnLabel: Dimension.LongShort,
    },
    {
      name: t('chart.short'),
      value: sumBy('valueShort')(shortPositions),
      children: dimension2 ? groupByDimensions(shortPositions, dimension2, null, 'valueShort') : null,
      columnLabel: Dimension.LongShort,
    },
  ]
  return output
}

const groupByDimensionsDefault = (
  items: Store.PositionMonitoring[],
  dimension1: Dimension,
  dimension2: Dimension,
  reduceOn: ReduceOnValue,
): SunburstData[] => {
  const groupedData: Json<PositionMonitoring[]> = groupBy(dimension1)(items)

  const chartData: SunburstData[] = []

  const values = Object.values(groupedData)
  for (let i = 0; i < values.length; i++) {
    //removed undefined, if any
    const elements = values[i]
    const item: SunburstData = {
      name: nameRenderer(elements[0][dimension1], dimension1),
      value: elements.reduce((v, item) => v + NanToZero(item[reduceOn]), 0),
      columnLabel: dimension1,
    }

    if (dimension2) {
      item.children = groupByDimensions(elements, dimension2, null)
    }

    chartData.push(item)
  }

  return chartData
}
