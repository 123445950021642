import React, { useState } from 'react'
import * as Api from 'types/api'
import * as api from 'api/assets'
import MICField from 'components/StrategyViewer/forms/AssetForm/MICField'
import { FormInstance } from 'antd/lib/form'
import { isApiError } from 'core'
import { mapJsonToExchanges } from 'services/store/mapService'
import { CrudOperation } from 'types/enums'

const getMICs = async (symbol: string) => {
  const response = await api.getMICs(symbol)
  if (isApiError(response)) {
    return null
  }

  const masterSecurityResponse = response as Api.ExchangeResponse

  const mappedData = mapJsonToExchanges(masterSecurityResponse.data.application_exchange)

  return mappedData
}
interface Props {
  form: FormInstance
  mode: CrudOperation
}

const MICFieldContainer: React.FC<Props> = ({ form, mode }) => {
  const [MICs, setMICs] = useState<string[]>([])

  const filterMICs = async (symbol: string) => {
    const masterSecurities = await getMICs(symbol)
    if (masterSecurities) {
      const mics: string[] = masterSecurities.reduce((array, item) => {
        if (item.mic && !array.includes(item.mic)) {
          array.push(item.mic)
        }
        return array
      }, [])

      if (mics.length === 1) {
        form.setFieldsValue({
          mic: mics[0],
        })
      }

      setMICs(mics)
    }
  }

  const onMicChange = () => {
    form.resetFields(['currency', 'market'])
  }

  return <MICField data={MICs} filter={filterMICs} onMicChange={onMicChange} mode={mode} />
}

export default MICFieldContainer
