import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AppContentCard from 'components/AppContentCard'

import { Modal, Row, Col, Space, Button, Typography, RadioChangeEvent, Avatar } from 'antd'

import SessionAssetsTransfer from 'containers/transfer/SessionAssetsTransfer'

import styles from './styles.module.scss'
import CloseIcon from 'assets/icon/close-icon.svg'
import StatusButtons from './StatusButtons'
import * as Store from 'types/store'
import { AssetStatus } from 'types/enums'
const { Title } = Typography
import classNames from 'classnames'
import { themes } from './themes'
import { useTheme } from 'providers/ThemeProvider'

interface Props {
  onFinish: (assets, status) => void
  loading: boolean
  visible: boolean
  onClose: () => void
}

const ChangeAssetStatusModal: React.FC<Props> = ({ onFinish, loading, onClose, visible }) => {
  const { t } = useTranslation()
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const frameModalClass = classNames(styles['frame-modal'], themeStyles['theme'])

  const [status, setStatus] = useState<AssetStatus>(null)
  const [assets, setAssets] = useState<Store.Asset[]>([])

  const disabled = status && assets.length > 0

  const close = () => {
    setStatus(null)
    setAssets([])
    onClose()
  }

  const save = () => {
    onFinish(assets, status)
  }

  return (
    <section>
      <Modal
        closable={false}
        width={'50%'}
        title={null}
        centered={true}
        open={visible}
        onOk={onClose}
        className={frameModalClass}
        wrapClassName="nonDraggable"
        footer={null}
        destroyOnClose
      >
        <AppContentCard>
          <div className="close-btn-wrapper">
            <Button className="col-close" type="ghost" onClick={onClose}>
              <Avatar shape="square" src={CloseIcon} size={24} />
            </Button>
          </div>

          <Title level={2} className="mb-16">
            {t('operativeSession.chooseAsset')}
          </Title>

          <SessionAssetsTransfer setAssets={setAssets} selectedAssets={assets} />

          <Row>
            <Col span={24}>
              <Title level={2} className="primary-color mt-30 mb-16">
                {t('operativeSession.chooseStatus')}
              </Title>
            </Col>

            <Col span={24}>
              <StatusButtons
                onChange={(e: RadioChangeEvent) => {
                  setStatus(e.target.value)
                }}
              />
            </Col>
          </Row>

          <Row justify="end" className="mt-30">
            <Space>
              <Button type="dashed" onClick={close} loading={loading}>
                {t('general.close')}
              </Button>
              <Button type="primary" disabled={!disabled} loading={loading} onClick={save}>
                {t('general.ok')}
              </Button>
            </Space>
          </Row>
        </AppContentCard>
      </Modal>
    </section>
  )
}

ChangeAssetStatusModal.displayName = 'ChangeAssetStatusModal'

export default ChangeAssetStatusModal
