import * as Api from 'types/api'
import { getAxios } from './apiService'
import { config } from 'core'
import { getAuthenticationHeader, getApiError } from './common'
const axios = getAxios()

export async function createLayout(json: Api.LayoutPayload): Promise<Api.SessionAssetsResponse | ApiError> {
  const header = await getAuthenticationHeader()
  return axios
    .post(config.api.layout(), json, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function updateLayout(json: Api.LayoutPayload): Promise<Api.SessionAssetsResponse | ApiError> {
  const header = await getAuthenticationHeader()
  return axios
    .put(config.api.layout(), json, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function deleteLayout(json: Json): Promise<Api.SessionAssetsResponse | ApiError> {
  const header = await getAuthenticationHeader()

  //https://stackoverflow.com/questions/51069552/axios-delete-request-with-body-and-headers
  const payload = {
    headers: header.headers,
    data: json,
  }

  return axios
    .delete(config.api.layout(), payload)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getLayouts(): Promise<Api.FetchLayoutsResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `{
      config_layout_config (order_by: {creation_date: desc}) {
        layout_name
        layout_id
        last_update_date
        creation_date
        configuration_json
        userByCreationUser {
          email_address
        }
        user {
          email_address
        }
      }
    }
    `,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}
