import React, { useState } from 'react'
import { Col, Form, Input, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import DependsOn from 'components/DependsOn'
import * as _ from 'lodash'

const { Paragraph } = Typography

interface State {
  symbol: string
  mic: string
  currency: string
}
interface Props {
  getDescription: (symbol: string, mic: string, currency: string) => void
  descriptionFieldValue: string
}

const DescriptionField: React.FC<Props> = ({ getDescription, descriptionFieldValue }) => {
  const { t } = useTranslation()

  const [options, setOptions] = useState<State>()

  const checkAndGetDescription = (fields: State) => {
    const { mic, symbol, currency } = fields
    if (mic && symbol && currency) {
      if (!options || !_.isEqual(options, fields)) {
        getDescription(symbol, mic, currency)
        setOptions({ mic, symbol, currency })
      }
    }
  }

  return (
    <DependsOn fields={['mic', 'symbol', 'currency']}>
      {(fields) => {
        checkAndGetDescription(fields as State)

        return (
          <>
            <Row align="middle" gutter={[5, 0]}>
              <Col span={12}>
                <Paragraph>{t('assets.instrumentClass')}</Paragraph>
              </Col>
              <Col span={12}>
                <Form.Item name="instrumentClass">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" gutter={[5, 0]}>
              <Col span={12}>
                <Paragraph>{t('assets.descriptionField')}</Paragraph>
              </Col>
              <Col span={12} className="instrument-name-value">
                <Paragraph ellipsis={{ rows: 1, tooltip: descriptionFieldValue }}>
                  {descriptionFieldValue}
                </Paragraph>
                <Form.Item name="description" className="desc-input">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" gutter={[5, 0]}>
              <Col span={12}>
                <Paragraph>{t('assets.contractSize')}</Paragraph>
              </Col>
              <Col span={12}>
                <Form.Item name="contractSize">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Typography.Text className="field-description">
                  {t('assets.description.contractSize')}
                </Typography.Text>
              </Col>
            </Row>
            <Row align="middle" gutter={[5, 0]}>
              <Col span={12}>
                <Paragraph>{t('assets.lotMultiplier')}</Paragraph>
              </Col>
              <Col span={12}>
                <Form.Item name="lotMultiplier">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Typography.Text className="field-description">
                  {t('assets.description.lotMultiplier')}
                </Typography.Text>
              </Col>
            </Row>
          </>
        )
      }}
    </DependsOn>
  )
}

export default DescriptionField
