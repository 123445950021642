import * as Store from 'types/store'
import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import Sunburst from 'charts/Sunburst'
import { AnalysisWindowTabType, Dimension } from 'types/enums'
import { groupByDimensions } from 'services/sunburstService'

interface Props {
  data?: Store.PositionMonitoring[]
  firstDimension?: Dimension
  secondDimension?: Dimension
  tabType: AnalysisWindowTabType
}

const SunburstView: React.FC<Props> = ({ data, firstDimension, secondDimension, tabType }) => {
  const container = useRef(null)
  const [chart, setChart] = useState(null)

  const createChart = (data): void => {
    const theChart = Sunburst(container.current, { tabType })
    theChart.enter(data)
    setChart(theChart)
  }

  useEffect(() => {
    if (chart) chart.selectDimension({ first: !!firstDimension, second: !!secondDimension })
  }, [firstDimension, secondDimension])

  const getFirstDimension = () => {
    const output = {
      name: 'Chart',
      children: groupByDimensions(data, firstDimension, null),
    }

    return output
  }

  const getSecondDimension = () => {
    const output = {
      name: 'Chart',
      children: groupByDimensions(data, firstDimension, secondDimension),
    }

    return output
  }

  const generateData = () => {
    if (secondDimension) {
      return getSecondDimension()
    } else {
      return getFirstDimension()
    }
  }

  useLayoutEffect(() => {
    const data = generateData()
    if (!chart) {
      createChart(data)
    } else {
      chart.enter(data)
    }
  }, [data, firstDimension, secondDimension])

  return <svg ref={container} />
}

export default SunburstView
