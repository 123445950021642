import React, { useEffect, memo } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'
import FramesPanel from 'v2/containers/FramesPanel'
import FramesDrawer from 'v2/components/FramesDrawer'
import SaveLayout from 'v2/containers/SaveLayout'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectDashboardGuidesActive,
  selectDashboardResolutions,
  selectDashboardSessionFrames,
} from 'store/dashboard/selectors'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import { loadResolutions, setDashboardHeight } from 'store/dashboard/actions'
import cn from 'classnames'
import DashboardPlayerContainer from 'v2/containers/dashboard-players/BacktestPlayer'
import { SessionType } from 'types/enums'
import { useMeasure } from 'react-use'
import DashboardPlayerVirtualSessionContainer from 'v2/containers/dashboard-players/VirtualPlayer'
import { themes } from './themes'
import { useTheme } from 'providers/ThemeProvider'

const Dashboard: React.FC = () => {
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const allFrames = useSelector(selectDashboardSessionFrames)
  const session = useSelector(selectOperativeSessionSession)
  const resolutions = useSelector(selectDashboardResolutions)
  const guidesActive = useSelector(selectDashboardGuidesActive)
  const frames = allFrames.filter((f) => f.sessionType === session.type)
  const [ref, { height }] = useMeasure()
  const dispatch = useDispatch()

  const dashboardClass = classNames(styles['dashboard'], themeStyles['theme'])

  // useEffect(() => {
  //   if (!resolutions) {
  //     dispatch(loadResolutions(false))
  //   }
  // }, [resolutions])

  useEffect(() => {
    window.requestAnimationFrame(() => {
      dispatch(setDashboardHeight(height))
    })
  }, [height])

  // const renderGuideLines = () => {
  //   if (!resolutions || !guidesActive) {
  //     return null
  //   }

  //   return (
  //     <section className="resolution-guides-wrapper">
  //       {resolutions
  //         .filter((resolution) => resolution.active)
  //         .map((resolution) => (
  //           <div
  //             key={resolution.key}
  //             className={cn('resolution-guide', `resolution-guide--${resolution.width}x${resolution.height}`)}
  //           ></div>
  //         ))}
  //     </section>
  //   )
  // }

  return (
    <section className={dashboardClass} ref={ref}>
      {/* {renderGuideLines()} */}

      <FramesPanel frames={frames} />

      {session.type === SessionType.BackTest && <DashboardPlayerContainer />}
      {session.type === SessionType.Virtual && <DashboardPlayerVirtualSessionContainer />}
      <div className="dashboard-actions-wrapper">
        <SaveLayout />
        <FramesDrawer sessionType={session.type} />
      </div>
    </section>
  )
}

export default memo(Dashboard)
