import { update_snapshot, update_ticker } from 'sockets/assets/asset_operations_pb'
import * as Store from 'types/store'
import { convertToFloat, formatSigmaDate } from '../common'
import { TimeFrame } from 'types/enums'
import moment from 'moment'

export const mapAssetOperationsSigmas = (
  feed: update_snapshot.AsObject | update_ticker.AsObject,
): Store.AssetSigmas => {
  const { sigmas } = feed
  if (!sigmas) {
    return null
  }

  const { assetCartesianOperationsList, assetNumTdyOp, closedOperation, openOperation } = sigmas

  return {
    openOperation: openOperation && {
      startTime: formatSigmaDate(openOperation.startTs),
      endTime: formatSigmaDate(openOperation.endTs),
      avgOpenPrice: convertToFloat(openOperation.avgOpenPrice),
      avgClosePrice: convertToFloat(openOperation.avgClosePrice),
      sign: openOperation?.sign,
      totalTradingReturn: convertToFloat(openOperation.totTradingRet),
      totalTradingReturnBc: convertToFloat(openOperation.totTradingRetBc),
      maxPrice: convertToFloat(openOperation.maxPrice),
      minPrice: convertToFloat(openOperation.minPrice),
      size: convertToFloat(openOperation.size),
      tdyPercReturn: convertToFloat(openOperation.tdyPercRet) || 0,
      tdyTradingReturn: convertToFloat(openOperation.tdyTradingRet) || 0,
      tdyTradingReturnBc: convertToFloat(openOperation.tdyTradingRetBc) || 0,
      totalPercReturn: convertToFloat(openOperation.totPercRet),
      ytdPercReturn: convertToFloat(openOperation.ytdPercRet) || 0,
      ytdTradingReturn: convertToFloat(openOperation.ytdTradingRet) || 0,
      ytdTradingReturnBc: convertToFloat(openOperation.ytdTradingRetBc) || 0,
    },
    closedOperation: closedOperation && {
      startTime: formatSigmaDate(closedOperation.startTs),
      endTime: formatSigmaDate(closedOperation.endTs),
      avgOpenPrice: convertToFloat(closedOperation.avgOpenPrice),
      avgClosePrice: convertToFloat(closedOperation.avgClosePrice),
      sign: closedOperation?.sign,
      totalTradingReturn: convertToFloat(closedOperation.totTradingRet),
      totalTradingReturnBc: convertToFloat(closedOperation.totTradingRetBc),
      maxPrice: convertToFloat(closedOperation.maxPrice),
      minPrice: convertToFloat(closedOperation.minPrice),
      size: convertToFloat(closedOperation.size),
      tdyPercReturn: convertToFloat(closedOperation.tdyPercRet) || 0,
      tdyTradingReturn: convertToFloat(closedOperation.tdyTradingRet) || 0,
      tdyTradingReturnBc: convertToFloat(closedOperation.tdyTradingRetBc) || 0,
      totalPercReturn: convertToFloat(closedOperation.totPercRet),
      ytdPercReturn: convertToFloat(closedOperation.ytdPercRet) || 0,
      ytdTradingReturn: convertToFloat(closedOperation.ytdTradingRet) || 0,
      ytdTradingReturnBc: convertToFloat(closedOperation.ytdTradingRetBc) || 0,
    },
    assetCartesianOperationsList: assetCartesianOperationsList?.map((item) => ({
      negativeOp: item.negativeOp || 0,
      positiveOp: item.positiveOp || 0,
      type: item.type as TimeFrame,
      total: item.totalOp,
    })),
    assetNumTdyOp: assetNumTdyOp && {
      date: moment().hour(0).minute(0).toDate(),
      insertionTime: null,
      lngNegOp: assetNumTdyOp.assetTdyLngNegOp,
      lngPosOp: assetNumTdyOp.assetTdyLngPosOp,
      negOp: assetNumTdyOp.assetTdyNegOp,
      posOp: assetNumTdyOp.assetTdyPosOp,
      sequence: null,
      shrNegOp: assetNumTdyOp.assetTdyShrNegOp,
      shrPosOp: assetNumTdyOp.assetTdyShrPosOp,
      indicator: null,
    },
  } as Store.AssetSigmas
}
