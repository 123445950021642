import { getApiError, getAuthenticationHeader } from './common'
import * as Api from 'types/api'
import { getAxios } from './apiService'
import { config } from 'core'

const axios = getAxios()

export async function getHistoricalPositionMonitoringData(
  sessionId: number,
): Promise<Api.HistoricalPositionMonitoringResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `query ($sessionId: Int){
        historical_data_position_analysis_v(where: { operative_session_id: {_eq: $sessionId}}) {
          market
          currency
          asset_class
          countervalue_bc
          countervalue_bc_long
          countervalue_bc_short
          asset_id
          net_hedging
        }
      }`,
    variables: {
      sessionId,
    },
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getHistoricalReturnAnalysisData(
  sessionId: number,
): Promise<Api.HistoricalReturnAnalysisResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `query ($sessionId: Int){
        historical_data_return_analysis_v(where: {operative_session_id: {_eq: $sessionId}}) {
          type
          market
          currency
          asset_class
          trading_ret_bc
          long_trading_ret_bc
          short_trading_ret_bc
          close_trading_ret_bc
          asset_id
          open_trading_ret_bc
        }
      }`,
    variables: {
      sessionId,
    },
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}
