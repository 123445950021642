import React from 'react'
import { Button, Col, Form, Input, Row, Space, Switch } from 'antd'
import Title from 'antd/lib/typography/Title'
import AppContentCard from 'components/AppContentCard'
import FormField from 'components/FormField'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Store from 'types/store'
import * as yup from 'yup'
import styles from './styles.module.scss'

interface Props {
  user: Store.SystemUser
  loading: boolean
  onSubmit: (user: Store.SystemUserForm) => void
  onCancel: () => void
  isNewUser: boolean
}

const UserForm: React.FC<Props> = ({ user, loading, onSubmit, onCancel, isNewUser }) => {
  const { t } = useTranslation()

  const schema = yup.object().shape({
    email: yup.string().email().required(t('error.requiredField')).email(t('error.invalidEmail')),
    name: yup.string().required(t('error.requiredField')),
    surname: yup.string().required(t('error.requiredField')),
    password: yup.lazy(() => (isNewUser ? yup.string().required(t('error.requiredField')) : yup.string())),
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    trigger,
  } = useForm<Store.SystemUserForm>({
    mode: 'onChange',
    defaultValues: {
      name: user?.name,
      surname: user?.surname,
      email: user?.email,
      roles: user?.roles || [],
      active: user?.active || false,
    },
    resolver: yupResolver(schema),
  })

  const handleSetValue = (field: keyof Store.SystemUserForm, val: any) => {
    setValue(field, val)
    trigger(field)
  }

  return (
    <AppContentCard fullHeight>
      <form onSubmit={handleSubmit(onSubmit)} className={styles['user-form']}>
        <Row align="middle" className="col-title mb-20">
          <Col span={12}>
            <Title>{isNewUser ? t('admin.addNewUser') : t('admin.editUser')}</Title>
          </Col>

          <Col span={12}>
            <Row justify="end">
              <Space>
                <Form.Item className="mb-0">
                  <Button
                    type="dashed"
                    className="secondary"
                    loading={loading}
                    disabled={loading}
                    onClick={onCancel}
                  >
                    {t('general.cancel')}
                  </Button>
                </Form.Item>

                <Form.Item className="mb-0">
                  <Button type="primary" className="primary" htmlType="submit" loading={loading}>
                    {t('general.save')}
                  </Button>
                </Form.Item>
              </Space>
            </Row>
          </Col>
        </Row>

        <Row align="middle" gutter={[5, 0]}>
          <Col span={12}>{t('admin.name')}</Col>
          <Col span={12}>
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormField error={error}>
                  <Input onChange={onChange} value={value} />
                </FormField>
              )}
            />
          </Col>
        </Row>
        <Row align="middle" gutter={[5, 0]}>
          <Col span={12}>{t('admin.surname')}</Col>
          <Col span={12}>
            <Controller
              control={control}
              name="surname"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormField error={error}>
                  <Input onChange={onChange} value={value} />
                </FormField>
              )}
            />
          </Col>
        </Row>
        <Row align="middle" gutter={[5, 0]}>
          <Col span={12}>{t('admin.email')}</Col>
          <Col span={12}>
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormField error={error}>
                  <Input onChange={onChange} value={value} />
                </FormField>
              )}
            />
          </Col>
        </Row>
        <Row align="middle" gutter={[5, 0]}>
          <Col span={12}>{t('admin.active')}</Col>
          <Col span={12}>
            <Controller
              control={control}
              name="active"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormField error={error}>
                  <Switch onChange={onChange} checked={value} />
                </FormField>
              )}
            />
          </Col>
        </Row>
        {isNewUser && (
          <>
            <Row align="middle" gutter={[5, 0]}>
              <Col span={12}>{t('admin.password')}</Col>
              <Col span={12}>
                <FormField error={errors.password}>
                  <Input type="password" onChange={(e) => handleSetValue('password', e.target.value)} />
                </FormField>
              </Col>
            </Row>
          </>
        )}
      </form>
    </AppContentCard>
  )
}

export default UserForm
