import { AnyAction } from 'redux'
import { CrudOperation } from 'types/enums'
import { ExtendedDataNode } from 'types/ui'
import * as K from './constants'

export function setUserAction(node: ExtendedDataNode, operation: CrudOperation): AnyAction {
  return {
    type: K.SET_CURRENT,
    payload: { node, operation },
  }
}

export function resetUserAction(): AnyAction {
  return {
    type: K.RESET_CURRENT,
    payload: null,
  }
}

export function loadUsedResources(): AnyAction {
  return {
    type: K.FETCH_RUNNING_ASSETS,
    payload: null,
  }
}
