import * as K from './constants'
import { put, call, takeLatest, all } from 'redux-saga/effects'
import Debug from 'debug'
import * as api from 'api/sessions'
import { ErrorCode, LoadingState } from 'types/enums'
import { ApplicationError, isApiError, isGraphQlError } from 'core'
import { jsonToSessions } from 'services/store/mapService'
import * as Api from 'types/api'
import { AnyAction } from 'redux'

const debug = Debug('Frontend')

export function* fetchSessions() {
  debug('Saga Sessions')
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Loading })
    const response = yield call(api.getSessions)
    if (isApiError(response) || isGraphQlError(response)) {
      debug('fetch getSessions failed', response, response as ApiError)
      throw new ApplicationError(ErrorCode.Api, 'fetch getSessions failed')
    }

    const json = (response as Api.SessionsResponse).data.application_operative_session

    const sessions = jsonToSessions(json)
    debug('Sessions', sessions, response)
    // save in redux
    yield put({ type: K.FETCH_SESSIONS_SUCCESS, payload: sessions })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Loaded })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.LoadFailure })
    debug('getSessions Error', error)
  }
}

export function* deleteSession(action: AnyAction) {
  debug('Saga Sessions')
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Deleting })
    yield put({ type: K.SET_ERROR, payload: null })
    const sessionId = action.payload as number

    const response = yield call(api.deleteSession, sessionId)

    if (isApiError(response) || isGraphQlError(response)) {
      debug('fetch deleteSession failed', response, response as ApiError)
      yield put({ type: K.SET_ERROR, payload: (response.data as Api.RESTResponse)?.result?.responseEnum })
      throw new ApplicationError(ErrorCode.Api, 'fetch deleteSession failed')
    }

    yield put({ type: K.SET_STATUS, payload: LoadingState.Deleted })
    yield call(fetchSessions)
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.DeleteFailed })
    debug('deleteSession Error', error)
  }
}

export function* cleanUpSession(action: AnyAction) {
  debug('Saga Sessions')
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Deleting })
    yield put({ type: K.SET_ERROR, payload: null })
    const sessionId = action.payload as number

    const response = yield call(api.cleanUpSession, sessionId)

    if (isApiError(response) || isGraphQlError(response)) {
      debug('fetch cleanUpSession failed', response, response as ApiError)
      yield put({ type: K.SET_ERROR, payload: (response.data as Api.RESTResponse)?.result?.responseEnum })
      throw new ApplicationError(ErrorCode.Api, 'fetch cleanUpSession failed')
    }

    yield put({ type: K.SET_STATUS, payload: LoadingState.Deleted })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.DeleteFailed })
    debug('cleanUpSession Error', error)
  }
}

function* watch() {
  yield all([
    takeLatest(K.FETCH_SESSIONS, fetchSessions),
    takeLatest(K.SESSION_DELETE, deleteSession),
    takeLatest(K.SESSION_CLEAN_UP, cleanUpSession),
  ])
}

export default watch
