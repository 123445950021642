import { createContext } from 'react'

export enum LeftMenuItem {
  ChangePassword = 'change-password',
  Users = 'users',
  Roles = 'roles',
  Security = 'security',
  SystemInfo = 'system-info',
}

interface LeftMenu {
  currentItem: LeftMenuItem
  setCurrent: (item: LeftMenuItem) => void
}

export const LeftMenuPageContext = createContext<LeftMenu>({
  currentItem: null,
  setCurrent: null,
})
