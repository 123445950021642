import { createContext } from 'react'
import * as Store from 'types/store'
import * as UI from 'types/ui'
import { TimeFrame, Dimension } from 'types/enums'

export interface AnalysisFrameContextInterface {
  id: string
  squares: {
    x: number
    y: number
  }
  isFullscreen: boolean
  timeFrame: TimeFrame
  viewType: UI.ViewType
  isOffline: boolean
  firstDimension: Dimension
  secondDimension: Dimension
  selectedAssets: Store.Asset[]
  setFullscreen: (isFullscreen: boolean) => void
  setTimeFrame: (timeFrame: TimeFrame) => void
  setViewType: (viewType: UI.ViewType) => void
  setFirstDimention: (firstDimension: Dimension) => void
  setSecondDimention: (secondDimension: Dimension) => void
  setSelectedAssets: (selectedAssets: Store.Asset[]) => void
}

export const AnalysisFrameContext = createContext<AnalysisFrameContextInterface>({
  id: null,
  squares: {
    x: 0,
    y: 0,
  },
  isFullscreen: false,
  timeFrame: TimeFrame.TDY,
  viewType: UI.ViewType.Details,
  isOffline: false,
  firstDimension: Dimension.Market,
  secondDimension: null,
  selectedAssets: null,
  setFullscreen: null,
  setTimeFrame: null,
  setViewType: null,
  setFirstDimention: null,
  setSecondDimention: null,
  setSelectedAssets: null,
})
