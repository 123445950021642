import React, { Key, useState } from 'react'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'
import { AnalysisWindowTabType, Dimension } from 'types/enums'
import { sumBy } from 'core/helpers'
import { formatGliphNumber, formatNumber } from 'core/formats'
import { getDimensionTitle, groupByDimensions } from 'services/sunburstService'
import { SunburstData } from 'types/chart'

interface Props {
  data: Store.PositionMonitoring[]
  dimension: Dimension
  secondDimension: Dimension
  tabType: AnalysisWindowTabType
  baseCurrency: string
}

const DimensionTable: React.FC<Props> = ({ data, dimension, secondDimension, tabType, baseCurrency }) => {
  const [expandedRows, setExpandedRows] = useState<Key[]>(null)
  const { t } = useTranslation()

  const grouped = groupByDimensions(data, dimension, secondDimension)

  const renderValue = (value, percent) => {
    if (tabType === AnalysisWindowTabType.PositionMonitoring) {
      return value && `${formatGliphNumber(value)} ${formatNumber(percent)}`
    }
    return value && `${formatGliphNumber(value)}`
  }

  const isRowExpandable = (row: SunburstData) => {
    if (secondDimension === Dimension.LongShort) {
      return false
    }
    return row.children && row.children.length > 1
  }

  const secondDimensionColumns =
    (secondDimension && [
      secondDimension !== Dimension.LongShort && {
        dataIndex: 'columnLabel',
        title: getDimensionTitle(secondDimension),
        render: (columnLabel, row: SunburstData) => {
          if (row.children) {
            return row.children?.map((item) => item.name).join(', ')
          }
          return row.name
        },
      },
      secondDimension === Dimension.LongShort && {
        dataIndex: 'columnLabel',
        title: t('assets.long'),
        render: (columnLabel, row: SunburstData) => {
          const long = row.children?.find((child) => child.name === t('assets.long'))
          if (!long) {
            return ''
          }
          return renderValue(long.value, long.percent)
        },
      },
      secondDimension === Dimension.LongShort && {
        dataIndex: 'columnLabel',
        title: t('assets.short'),
        render: (columnLabel, row: SunburstData) => {
          const short = row.children?.find((child) => child.name === t('assets.short'))
          if (!short) {
            return ''
          }
          return renderValue(short.value, short.percent)
        },
      },
    ]) ||
    []

  const columns = [
    {
      dataIndex: 'name',
      title: getDimensionTitle(dimension),
      render: (name, row) => {
        if (row.columnLabel !== dimension) {
          return ''
        }
        return name
      },
    },
    ...secondDimensionColumns,
    {
      dataIndex: 'value',
      title: () => {
        if (tabType === AnalysisWindowTabType.PositionMonitoring) {
          return t('table.column.countervalue', { baseCurrency })
        }
        return t('table.column.return', { baseCurrency })
      },
      render: (value, row: SunburstData) => {
        if (value || value === 0) {
          return renderValue(value, row.percent)
        }
        return row.children && renderValue(sumBy('value')(row.children), row.percent)
      },
    },
  ].filter((item) => item)

  const expanded: Key[] = secondDimension === Dimension.LongShort ? [] : expandedRows

  return (
    <Table
      rowKey={(row: SunburstData) => `${row.name}-${dimension}`}
      columns={columns}
      dataSource={grouped}
      expandable={{
        expandIconColumnIndex: 1,
        expandedRowRender: () => null,
        rowExpandable: isRowExpandable,
        expandedRowKeys: expanded,
        onExpandedRowsChange: (expanded: Key[]) => {
          setExpandedRows(expanded)
        },
      }}
      pagination={false}
      className="table-assets"
    />
  )
}

export default DimensionTable
