import * as K from './constants'
import * as Api from 'types/api'
import { put, call, takeLatest, all } from 'redux-saga/effects'
import Debug from 'debug'
import * as api from 'api/historicalResources'

import { ErrorCode, LoadingState } from 'types/enums'
import { ApplicationError, isApiError, isGraphQlError } from 'core'
import { jsonToStrategies } from 'services/store/mapHistoricals'
import { AnyAction } from 'redux'

const debug = Debug('Frontend')

export function* fetchStrategies(action: AnyAction) {
  debug('Saga Historical Strategies')
  const sessionId = action.payload as number
  yield put({ type: K.SET_STATUS, payload: LoadingState.Loading })
  const response = yield call(api.getStrategies, sessionId)
  if (isApiError(response) || isGraphQlError(response)) {
    throw new ApplicationError(ErrorCode.Api, 'fetch getStrategies failed')
  }

  const json = (response as Api.HistoricalStrategiesResponse).data.historical_data_strategy_v

  const strategies = jsonToStrategies(json)
  debug('strategies', strategies, response)

  // save in redux
  yield put({ type: K.STRATEGIES_FETCH_SUCCESS, payload: strategies })
}

function* watch() {
  yield all([takeLatest(K.HISTORICAL_STRATEGIES_FETCH, fetchStrategies)])
}

export default watch
