import React, { useContext } from 'react'
import * as Store from 'types/store'
import { GridItemActions, ViewType } from 'types/ui'
import { useTranslation } from 'react-i18next'
import ContextMenu from '../ContextMenu'
import { Typography, Button, Avatar, Spin, Popover } from 'antd'
import StatisticsDetail from './StatisticsDetail'
import StatisticsChart from './StatisticsChart'
import ViewTypeSelector from '../ViewTypeSelector'
import * as UI from 'types/ui'
import { useWindowSize } from 'react-use'
import { CELL_HEIGHT, CELL_WIDTH, getGridItemClass } from 'services/dashboardService'
import SwitchIcon from 'assets/icon/switch-icon.png'
import generalStyles from './styles.module.scss'
import { CheckOutlined } from '@ant-design/icons'
import { SessionType } from 'types/enums'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
import { getSpinnerMessage, selectPopupContainer } from 'core/helpers'

enum DistributionChartTypes {
  NormalDistribution,
  Cumulative,
}
interface Props {
  isSessionRunning: boolean
  indicatorData: Store.IndicatorStatistics
  distributionData: Store.DistributionStatistics
  onClick: (action: GridItemActions) => void
  showMaxDrawdown: boolean
  viewType: ViewType
  setViewType: (viewType: UI.ViewType) => void
  isFullScreen: boolean
  session: Store.Session
}

const Statistics: React.FC<Props> = ({
  onClick,
  showMaxDrawdown,
  isSessionRunning,
  viewType,
  setViewType,
  distributionData,
  indicatorData,
  isFullScreen,
  session,
}) => {
  const { t } = useTranslation()
  const context = useContext(CandleStickFrameContext)
  const { squares } = context

  const [chartType, setChartType] = React.useState(DistributionChartTypes.NormalDistribution)
  const { width: windowWidth, height: windowHeight } = useWindowSize()
  const [width, height] = isFullScreen
    ? [windowWidth, windowHeight]
    : [squares.x * CELL_WIDTH, squares.y * CELL_HEIGHT]
  const std = distributionData?.synthesisTable?.stdDev
  const mean = distributionData?.synthesisTable?.mean

  const spinning = isSessionRunning && session.type === SessionType.BackTest

  const chartViewDate =
    chartType === DistributionChartTypes.NormalDistribution
      ? distributionData?.normalDistributionData
      : distributionData?.cumulativeDistributionData

  const toggleChart = () => {
    setChartType((type) => {
      if (type === DistributionChartTypes.NormalDistribution) {
        return DistributionChartTypes.Cumulative
      }
      return DistributionChartTypes.NormalDistribution
    })
  }

  const chartView = (
    <div className="toggle-chart-wrapper">
      <Popover
        placement="bottomLeft"
        content={t('chart.switch')}
        trigger="hover"
        getPopupContainer={(trigger) => selectPopupContainer(trigger, isFullScreen)}
      >
        <Button size="small" className="switch-charts-btn" onClick={toggleChart}>
          <Avatar src={SwitchIcon} shape="square" />
        </Button>
      </Popover>

      <div>
        <StatisticsChart data={chartViewDate} std={std} mean={mean} />
      </div>
    </div>
  )

  const panelContent = spinning ? (
    <section className="running-session-spin-container">
      <ContextMenu
        isFullscreen={isFullScreen}
        onMenuItemClick={onClick}
        items={[{ key: GridItemActions.Close, label: t('contextMenu.close') }]}
      />
      <Spin
        tip={getSpinnerMessage(session.status)}
        spinning={spinning}
        size="large"
        wrapperClassName="session-running"
      ></Spin>
    </section>
  ) : (
    <section className={generalStyles['statistic']}>
      <div className={getGridItemClass(width, height)}>
        <div className="frame-header">
          <div className="frame-header__top-row">
            <Typography.Title level={3} className="title" ellipsis={{ tooltip: true }}>
              {t('operativeSession.viewStatistics')}
            </Typography.Title>

            <ContextMenu
              isFullscreen={isFullScreen}
              onMenuItemClick={onClick}
              items={[
                { key: GridItemActions.Edit, label: t('contextMenu.edit') },
                {
                  key: GridItemActions.FullScreen,
                  label: t('contextMenu.fullscreen'),
                  icon: isFullScreen ? <CheckOutlined /> : null,
                },
                // { key: GridItemActions.Download, label: t('contextMenu.generateReport') }, JI-TRANSLATIONS
                { key: GridItemActions.Download, label: 'Generate reports' },
                !isFullScreen && { key: GridItemActions.Close, label: t('contextMenu.close') },
              ]}
            />
          </div>
        </div>
        <div className="frame-content">
          <ViewTypeSelector value={viewType} onChange={setViewType} />
          {viewType === ViewType.Details || isFullScreen ? (
            <div className="statistic-grid">
              <div className="grid-cell statistic-table">
                <StatisticsDetail data={indicatorData} showMaxDrawdown={showMaxDrawdown} />
              </div>
              {chartView}
              <div className="statistic-charts-wrapper">
                <div className="grid-cell statistic-chart--full">
                  <StatisticsChart data={distributionData?.normalDistributionData} std={std} mean={mean} />
                </div>
                <div className="grid-cell statistic-chart--half">
                  <StatisticsChart
                    data={distributionData?.cumulativeDistributionData}
                    std={std}
                    mean={mean}
                  />
                </div>
              </div>
            </div>
          ) : (
            chartView
          )}
        </div>
      </div>
    </section>
  )

  return panelContent
}

export default Statistics
