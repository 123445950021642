import React, { memo } from 'react'

import { Layout } from 'antd'

interface Props {
  fullHeight?: boolean
  className?: string
  isDisplayFlex?: boolean
}

const AppContent: React.FC<Props> = memo(({
  children,
  fullHeight = false,
  isDisplayFlex = false,
  className = '',
}) => {
  return (
    <Layout className={`app-content ${className}`}>
      <Layout.Content>
        <section
          data-augmented-ui-reset
          style={{
            height: fullHeight ? '100%' : 'auto',
            display: isDisplayFlex ? 'flex' : 'block',
            flexDirection: isDisplayFlex ? 'column' : 'unset',
          }}
        >
          {children}
        </section>
      </Layout.Content>
    </Layout>
  )
})

AppContent.displayName = "AppContent"

export default AppContent
