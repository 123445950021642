import ChangeAssetStatusModal from 'components/modals/ChangeAssetStatusModal'
import React from 'react'
import { AssetStatus, LoadingState } from 'types/enums'
import { useDispatch, useSelector } from 'react-redux'
import { changeSessionAssets } from 'store/pages/operative-session/actions'
import { selectOperativeSessionSession, selectOperativeSessionState } from 'store/pages/selectors'

interface Props {
  visible: boolean
  onClose: () => void
}

const ChangeAssetStatus: React.FC<Props> = ({ visible, onClose }) => {
  const session = useSelector(selectOperativeSessionSession)
  const state = useSelector(selectOperativeSessionState)
  const sessionId = session.id
  const dispatch = useDispatch()

  const loading = state === LoadingState.Updating

  const onFinish = (assets, status: AssetStatus) => {
    dispatch(changeSessionAssets({ assets, sessionId, status }))
  }

  return <ChangeAssetStatusModal onFinish={onFinish} loading={loading} visible={visible} onClose={onClose} />
}

export default ChangeAssetStatus
