import { Key } from 'react'
import React from 'react'

import { ExtendedDataNode, NodeType } from 'types/ui'
import { t } from 'core/i18n'
import { getStatusIcon } from 'core/helpers'
import colors from 'config/colors'
import { AssetStatus } from 'types/enums'
import { Badge } from 'antd'
import IconFont from 'core/IconFont'
const K_EMPTY_KEY = 'empty_9999'
const K_ROOT_KEY = 'root_0'

export const wrapData = (title: string, data: ExtendedDataNode[]) => {
  const output: ExtendedDataNode[] = [
    {
      title: title,
      key: K_ROOT_KEY,
      type: NodeType.AllStrategies,
      children: data,
      isDeletable: true,
    },
  ]

  return output
}

export const getEmptyData = (): ExtendedDataNode[] => {
  return [
    {
      title: t('transfer.noDataSelected'),
      key: K_EMPTY_KEY,
      type: NodeType.Undefined,
      isDeletable: true,
    },
  ]
}

export const unWrapData = (data: ExtendedDataNode[]) => {
  return data[0].children[0]
}

const getInitialExpansionRecursive = (data: ExtendedDataNode[], output: Key[]) => {
  if (!data) {
    return output
  }

  data.forEach((item) => {
    if (item.children && item.type !== NodeType.Portfolio) {
      output.push(item.key)
      getInitialExpansionRecursive(item.children, output)
    }
  })

  return output
}

export const getInitialExpansion = (data: ExtendedDataNode[]): Key[] => {
  const output: Key[] = []
  getInitialExpansionRecursive(data, output)
  return output
}

const getInitialHistoricalExpansionRecursive = (data: ExtendedDataNode[], output: Key[]) => {
  if (!data) {
    return output
  }

  data.forEach((item) => {
    if (item.children) {
      output.push(item.key)
      getInitialHistoricalExpansionRecursive(item.children, output)
    }
  })
  return output
}

export const getInitialHistoricalExpansion = (data: ExtendedDataNode[]): Key[] => {
  const output: Key[] = []
  getInitialHistoricalExpansionRecursive(data, output)
  return output
}

export const isLeaf = (item: ExtendedDataNode): boolean => {
  return item.children ? false : true
}

//Check if all node of the specified type are selected
export const areAllItemsSelected = (data: ExtendedDataNode[], type: NodeType, status = true): boolean => {
  if (status === false) {
    //some item in pipeline already failed
    return false
  }

  if (!data) {
    return false
  }

  let output = true
  for (const item of data) {
    if (!item.selected && item.type === type) {
      return false
    }
    if (item.selected && item.type === type) {
      return true
    }

    output = areAllItemsSelected(item.children, type, status)
    if (!output) {
      return output
    }
  }

  return output
}

export const isAnyItemsSelected = (data: ExtendedDataNode[], type: NodeType, status = false): boolean => {
  if (status === true) {
    //some item in pipeline already succeded
    return true
  }

  if (!data) {
    return status
  }

  let output = false

  for (const item of data) {
    if (item.selected && item.type === type) {
      return true
    }

    output = isAnyItemsSelected(item.children, type, status)
    if (output === true) {
      return true
    }
  }

  return output
}

export const getAssetStatusIcon = (assetStatus) => {
  if (assetStatus) {
    switch (assetStatus) {
      case AssetStatus.Broken:
        return <Badge color={colors.broken} />
      case AssetStatus.Quit:
        return <Badge color={colors.quit} />
      case AssetStatus.Updating:
        return <Badge color={colors.updating} />
      case AssetStatus.Terminating:
        return <Badge color={colors.terminating} />
      case AssetStatus.NotDefined:
        return <></>
      default:
        return <IconFont type={getStatusIcon(assetStatus)} style={{ marginRight: '5px' }} />
    }
  }
  return <></>
}
