import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectSetupSessionPage } from 'store/pages/selectors'
import { selectResources } from 'store/resources/selectors'
import { ExtendedDataNode } from 'types/ui'
import { StrategyTransfer } from 'components/StrategyTransfer'
import {
  addSessionAssets,
  fetchBrokenEntities,
  removeSessionAssets,
  setCurrentNode,
} from 'store/pages/setup-session/actions'
import {
  AssetStatus,
  CrudOperation,
  LoadingState,
  SessionStatus,
  SessionType,
  TransferContext,
  TransferLayout,
} from 'types/enums'
import { filterItems, generateResourceNewTree, generateResourceTree } from 'services/treeService'
import * as Store from 'types/store'
import Debug from 'debug'
import { useMount } from 'react-use'

const debug = new Debug('FrontEnd')

interface Props {
  session: Store.Session
}

const getSelectedItems = (array) => {
  return array.reduce((array, item) => {
    if (item.selected) {
      array = [...array, item.resource]
    }
    if (item.children) {
      array = [...array, ...getSelectedItems(item.children)]
    }
    return array
  }, [])
}

const AssetTransferContainer: React.FC<Props> = ({ session }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { assets, portfolios, strategies, groups } = useSelector(selectResources)
  const page = useSelector(selectSetupSessionPage)
  const { assets: sessionAssets, brokenEntities } = page
  const [tree, setTree] = useState<Array<ExtendedDataNode>>()
  const [filter, setFilter] = useState<string>(null)

  const userAssets = useMemo(() => {
    return assets.data.filter((asset) => !asset.associationUserId || asset.associationUserId === session?.ownerId)
  }, [session, assets])

  const userGroups = useMemo(() => {
    return groups.data.filter((group) => group.userId === session?.ownerId)
  }, [session, groups])

  const assetsWithStatus = useMemo(() => userAssets.map((asset) => {
    // Set assets status
    const defaultStatus = brokenEntities?.idAssetsBroken?.includes(asset.id)
      ? AssetStatus.Broken
      : AssetStatus.NotDefined

    const status =
      (sessionAssets && sessionAssets.find((sessionAsset) => asset.id === sessionAsset.id)?.status) ||
      defaultStatus

    return {
      ...asset,
      status,
    }
  }), [session, groups, assets])

  // JI-TRANSFER session launch

  const canGetBrokenEntities = !session.isHistorical

  useMount(() => {
    if (canGetBrokenEntities) {
      dispatch(fetchBrokenEntities(session.id))
    }
  })

  useEffect(() => {
    const tree = generateResourceNewTree(
      strategies.data,
      portfolios.data,
      userGroups,
      assetsWithStatus,
      t('transfer.allStrategies'),
      filter,
    )
    setTree(tree)
  }, [filter, brokenEntities])

  if (!tree) {
    return null
  }

  const onDataChanged = (data: Array<ExtendedDataNode>) => {
    const selected = getSelectedItems(data)

    const filteredSessionAssets = filterItems(sessionAssets, filter) as Store.Asset[]

    const removed = filteredSessionAssets.filter(
      (item) => !selected.find((selectedItem) => selectedItem.id === item.id),
    )

    const added = selected.filter(
      (item) => !sessionAssets.find((sessionAssetsItem) => sessionAssetsItem.id === item.id),
    )
    debug('Data tree', removed, added)
    // console.log('added', added.map(a => a.id), 'removed', removed.map(a => a.id), 'selected', selected.map(a => a.id), 'filtered', filteredSessionAssets.map(a => a.id))
    if (removed.length > 0) {
      dispatch(removeSessionAssets({ sessionId: session.id, assets: removed, sessionType: session.type }))
    }
    if (added.length > 0) {
      dispatch(addSessionAssets({ sessionId: session.id, assets: added, sessionType: session.type }))
    }
  }

  const onNodeClicked = (node: ExtendedDataNode) => {
    if (node.resource) {
      dispatch(setCurrentNode({ node: node, operation: CrudOperation.Read }))
    }
  }

  if (!sessionAssets) {
    return <div>No assets available for this session</div>
  }

  const defaultSelected = sessionAssets && sessionAssets.map((asset) => `asset-${asset.id}`)

  const onFilterChange = (newFilter: string) => {
    setFilter(newFilter)
  }

  const isBacktestOrVirtual = session.type === SessionType.BackTest || session.type === SessionType.Virtual

  const isReadOnly = isBacktestOrVirtual
    ? session.status !== SessionStatus.Created && session.status !== SessionStatus.Off
    : session.status === SessionStatus.Updating || session.status === SessionStatus.Terminating

  return (
    <StrategyTransfer
      treeData={tree}
      onDataChanged={onDataChanged}
      layout={TransferLayout.Vertical}
      context={TransferContext.AtSessionLaunch}
      defaultSelected={defaultSelected}
      onNodeClicked={onNodeClicked}
      isReadOnly={isReadOnly}
      onFilterChange={onFilterChange}
    />
  )
}

export default AssetTransferContainer
