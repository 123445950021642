import React, { useState } from 'react'

import Profile from 'containers/user-profile/Profile'
import RightMenu from 'components/user-profile/RightMenu'
import NotificationManager from 'pages/ProfilePage/NotificationManager'
import { LeftMenuItem, LeftMenuPageContext } from 'contexts/LeftMenuContext'
import ChangePassword from 'containers/user-profile/ChangePassword'
import AppContent from 'components/AppContent'

const ProfilePage: React.FC = () => {
  const [currentItem, setCurrent] = useState(LeftMenuItem.Users)

  const renderItem = () => {
    switch (currentItem) {
      case LeftMenuItem.Users:
        return <Profile />
      case LeftMenuItem.Security:
        return <ChangePassword />
      default:
        return null
    }
  }

  return (
    <LeftMenuPageContext.Provider value={{ currentItem, setCurrent }}>
      <NotificationManager />
      <RightMenu />
      <AppContent>{renderItem()}</AppContent>
    </LeftMenuPageContext.Provider>
  )
}

export default ProfilePage
