import React from 'react'
import { Row, Col, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'
import { renderAvailableData } from 'core/helpers'

interface Props {
  securityBidAsk: Store.SecurityBidAsk
}

const BestBidAsk: React.FC<Props> = ({ securityBidAsk }) => {
  const { t } = useTranslation()
  const { bidPrice, bidTotalSize, askPrice, askTotalSize } = { ...securityBidAsk }

  return (
    <Col span={24} className="trade-values-col">
      <Row className="trade-card">
        <Col className="trade-values" span={12}>
          <Typography.Title level={2}>{t('chart.bestBid')}</Typography.Title>
          <Typography.Title level={3}>{renderAvailableData(bidPrice)}</Typography.Title>
          <Typography.Title level={4}>{renderAvailableData(bidTotalSize)}</Typography.Title>
        </Col>
        <Col className="trade-values" span={12}>
          <Typography.Title level={2}>{t('chart.bestAsk')}</Typography.Title>
          <Typography.Title level={3}>{renderAvailableData(askPrice)}</Typography.Title>
          <Typography.Title level={4}>{renderAvailableData(askTotalSize)}</Typography.Title>
        </Col>
      </Row>
    </Col>
  )
}

export default BestBidAsk
