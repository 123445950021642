import React, { useContext, memo, useEffect, useState, useRef, useMemo } from 'react'

import * as Store from 'types/store'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'

import { useSelector } from 'react-redux'
import CandleStick from 'v2/components/CandleStick'
import { selectOperativeSessionSession } from 'store/pages/selectors'
// import { useCandleStickData } from '../hooks'
import { filterEmptyData, getDateLimits, hasEmptyData } from 'core/helpers'
import { TimeFrame } from 'types/enums'
import Spinner from 'components/Spinner'
import CandlestickWrapper from 'v2/components/CandlestickWrapper'
import sigmaServices from 'config/sigmaServices'
import { AssetFeed } from 'types/enums.api'
import { getAuthenticationHeader } from 'api/common'
import { BUFFERS, config } from 'core'

const { Return, SecurityData, Operations } = sigmaServices.Assets

interface Props {
  asset: Store.Asset
  width: number
  height: number
}

const AssetCandleStickContainer: React.FC<Props> = ({ asset, width, height }) => {
  const context = useContext(CandleStickFrameContext)
  const { timeFrame } = context
  const { id, portfolioId, baseCurrency, currency } = asset

  const session = useSelector(selectOperativeSessionSession)

  const sessionId = session.id

  const webWorkerRef = useRef<Worker | null>(null)

  const [data, setData] = useState<any>({
    candleStickData: [] as Store.CandleStick[],
    lineChartData: [] as Store.ChartData[],
    operationsChartData: [] as Store.ChartData[],
  })
  const [workerParams, setWorkerParams] = useState<any>({})
  const [headers, setHeaders] = useState<any>({})

  // const { data: candleStickData, setParams } = useCandleStickData(
  //   SecurityData.AssetClosedCandle,
  //   AssetFeed.SecurityData,
  //   timeFrame,
  // )
  // const { data: lineChartData, setParams: setLineChart } = useCandleStickData(
  //   Return.AssetClosedUnitRet,
  //   AssetFeed.Return,
  //   timeFrame,
  // )
  // const { data: operationsChartData, setParams: setOperationsChartParams } = useCandleStickData(
  //   Operations.ClosedOperation,
  //   AssetFeed.Operations,
  //   timeFrame,
  // )

  const initMsg = useMemo(() =>
    {
      return {
        type: 'process',
        payload: {
          sigmas: [
            { title: 'candleStickData', service: SecurityData.AssetClosedCandle, feed: AssetFeed.SecurityData },
            { title: 'lineChartData', service: Return.AssetClosedUnitRet, feed: AssetFeed.Return },
            { title: 'operationsChartData', service: Operations.ClosedOperation, feed: AssetFeed.Operations },
          ],
          timeFrame: timeFrame,
          bufferTimespan: BUFFERS.Large,
          session: session,
          params: workerParams,
          headers: headers,
          webSocketUrl: config.socketBaseUrl,
          tsUrl: config.timeSeriesBaseUrl,
        },
      }
    }, [id, timeFrame, workerParams, session, headers]
  )

  useEffect(() => {
    const getHeaders = async () => {
      const headers = await getAuthenticationHeader()
      setHeaders(headers)
    }
    getHeaders()
  }, [])
  
  useEffect(() => {
    if (!headers) {
      return
    }
    // Setup worker
    webWorkerRef.current = new Worker(new URL('../../../../workers/candleStickSockets.web.worker.ts', import.meta.url))

    webWorkerRef.current.onmessage = (event: MessageEvent) => {
      const { type, data: workerData, error } = event.data
      if (type === 'data') {
        setData(workerData)
      } else if (type === 'error') {
        console.error('Worker error:', error)
      }
    }

    return () => {
      webWorkerRef.current.postMessage({ type: 'terminate' })
      webWorkerRef.current.terminate()
    }
  }, [])

  useEffect(() => {
    // Send messages to worker
    const msg = {...initMsg}
    msg.payload.headers = headers
    msg.payload.params = workerParams

    webWorkerRef.current.postMessage(msg)
  }, [workerParams])

  const getFromTo = async (from: Date, to: Date) => {
    setWorkerParams({id, from, portfolioId, to})
    // setParams({ id, from, portfolioId, sessionId, to })
    // setLineChart({ id, from, portfolioId, sessionId, to })
    // if (timeFrame !== TimeFrame.YTD) {
    //   // TODO: JI-NOTE refactor seems right, but check operations request more in deep
    //   // setOperationsChartParams({ id, from: null, portfolioId, sessionId, to: new Date() })
    // }
  }

  const noData = () => {
    if (data.candleStickData && hasEmptyData(data.candleStickData)) {
      return false
    }
    if (data.lineChartData && hasEmptyData(data.lineChartData)) {
      return false
    }
    if (data.operationsChartData && hasEmptyData(data.operationsChartData)) {
      return false
    }
    return true
  }

  const hasSize = width > 0 && height > 0

  return (
    <div className='JI-RENDER-candlestick-container-wrapper'>
      <CandlestickWrapper>
        {noData() && <Spinner />}
        {hasSize && (
          <CandleStick
            loading={noData()}
            session={session}
            timeFrame={timeFrame}
            candleStickData={(data.candleStickData) as Store.CandleStick[]}
            lineChartData={(data.lineChartData) as Store.UnitPerformance[]}
            operationsChartData={(data.operationsChartData) as Store.Operation[]}
            currency={currency}
            baseCurrency={baseCurrency}
            getFromTo={getFromTo}
            getXDomain={() => getDateLimits(timeFrame, session)}
            width={width}
            height={height}
            asset={asset}
          />
        )}
      </CandlestickWrapper>
    </div>
  )
}

export default memo(AssetCandleStickContainer)
