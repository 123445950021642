import * as K from './constants'
import * as Store from 'types/store'
import * as Api from 'types/api'
import { put, call, takeLatest, all } from 'redux-saga/effects'
import * as api from 'api/layouts'
import { ErrorCode, LoadingState } from 'types/enums'
import { ApplicationError, isApiError, isGraphQlError } from 'core'
import { jsonToLayouts } from 'services/store/mapService'
import { AnyAction } from 'redux'
import { storeToLayoutPayload } from 'services/api/mapService'
import { loadLayout, setDirty } from 'store/dashboard/actions'

export function* fetchLayouts() {
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Loading })

    const response = yield call(api.getLayouts)
    if (isApiError(response) || isGraphQlError(response)) {
      throw new ApplicationError(ErrorCode.Api, 'fetch fetchLayouts failed')
    }

    const json = (response as Api.FetchLayoutsResponse).data.config_layout_config
    const layouts = jsonToLayouts(json)

    yield put({
      type: K.FETCH_LAYOUTS_SUCCESS,
      payload: layouts,
    })

    yield put({ type: K.SET_STATUS, payload: LoadingState.Loaded })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.LoadFailure })
  }
}

export function* updateLayout(action: AnyAction) {
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Updating })
    const layout = action.payload as Store.Layout
    const payload = storeToLayoutPayload(layout)

    const response = yield call(api.updateLayout, payload)
    if (isApiError(response)) {
      throw new ApplicationError(ErrorCode.Api, 'fetch updateLayout failed')
    }

    yield all([
      put(setDirty(false)),
      put({
        type: K.UPDATE_LAYOUT_SUCCESS,
        payload: layout,
      }),
      put({ type: K.SET_STATUS, payload: LoadingState.Updated }),
    ])
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.UpdateFailure })
  }
}

export function* createLayout(action: AnyAction) {
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Creating })
    const layout = action.payload as Store.Layout
    const payload = storeToLayoutPayload(layout)

    const response = yield call(api.createLayout, payload)
    if (isApiError(response)) {
      throw new ApplicationError(ErrorCode.Api, 'fetch createLayout failed')
    }

    layout.id = response.content.id
    yield all([
      put(setDirty(false)),
      put(loadLayout(layout)),
      put({
        type: K.CREATE_LAYOUT_SUCCESS,
        payload: layout,
      }),
      put({ type: K.SET_STATUS, payload: LoadingState.Created }),
    ])
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.CreateFailure })
  }
}

export function* deleteLayout(action: AnyAction) {
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Deleting })
    const layoutId = action.payload as number

    const json: Json = {
      layoutId,
    }

    const response = yield call(api.deleteLayout, json)
    if (isApiError(response)) {
      throw new ApplicationError(ErrorCode.Api, 'fetch createLayout failed')
    }

    yield put({
      type: K.DELETE_LAYOUT_SUCCESS,
      payload: layoutId,
    })

    yield put({ type: K.SET_STATUS, payload: LoadingState.Deleted })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.DeleteFailed })
  }
}

function* watch() {
  yield all([
    takeLatest(K.CREATE_LAYOUT, createLayout),
    takeLatest(K.UPDATE_LAYOUT, updateLayout),
    takeLatest(K.DELETE_LAYOUT, deleteLayout),
    takeLatest(K.FETCH_LAYOUTS, fetchLayouts),
  ])
}

export default watch
