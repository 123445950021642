import Axios, { AxiosInstance, AxiosError, AxiosResponse } from 'axios'

import { config } from 'core'
import Debug from 'debug'
import * as localStore from 'core/localStore'
import { GraphQlErrors } from 'types/enums.api'
import { Bearer } from './common'
import * as Api from 'types/api'

const debug = Debug('Frontend')
const axios: AxiosInstance = Axios.create()

//https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f
//https://stackoverflow.com/questions/57839551/how-to-have-custom-error-code-check-for-axios-response-interceptor

let isRefreshing = false
let failedQueue = []

const goToLogin = () => {
  //GG-keep this console.log
  console.log('Refresh Token failed: clear storage and redirect to login')
  localStore.clearUser()
  window.location.reload()
}

const processQueue = (error: AxiosError<any>, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

const hasGraphQlError = (response) => {
  const errors = response.data?.errors

  if (errors && errors.length > 0) {
    return true
  }

  return false
}

const graphQl2StatusError = (error) => {
  if (error.extensions) {
    const { extensions } = error
    switch (extensions?.code) {
      case GraphQlErrors.AccessDenied:
        return 401

      default:
        return 500
    }
  }

  return 500
}

const getTokens = (data: Api.AuthResponse): Api.LoginResponse => {
  return {
    access_token: data.content.access_token,
    refresh_token: data.content.refresh_token,
  }
}

const doRefreshRequest = async (request, refreshToken): Promise<AxiosResponse> => {
  try {
    //The request for refresh is different for each identity provider
    const params = new URLSearchParams()
    params.append('refresh_token', refreshToken)
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
    const response = await axios.post(config.api.refreshToken(), params, { headers })
    const { data } = response
    const tokens: Api.LoginResponse = await getTokens(data)
    await localStore.saveUser(tokens)
    processQueue(null, tokens.access_token)
    axios.defaults.headers.common['Authorization'] = `${Bearer} ${tokens.access_token}`
    request.headers['Authorization'] = `${Bearer} ${tokens.access_token}`
    return axios(request)
  } catch (error) {
    debug('refresh failed, remove token from storage')
    processQueue(error, null)
    //Impossible to refresh
    goToLogin()
    Promise.reject(error)
  } finally {
    debug('refresh finished')
    isRefreshing = false
  }
}

const doTokenRefresh = async (request): Promise<AxiosResponse> => {
  if (isRefreshing) {
    try {
      const token = await new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject })
      })
      request.headers['Authorization'] = `${Bearer} ${token}`
      return axios(request)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  request._retry = true
  isRefreshing = true

  const refreshToken = await localStore.getRefreshToken()
  if (!refreshToken) {
    //we don't have token: not possible to refresh
    goToLogin()
    return
  }

  debug('Refreshing Token', refreshToken)

  return doRefreshRequest(request, refreshToken)
}

const isValidStatus = (status) => {
  return status >= 200 && status < 300
}

const doRefreshWorkflow = (request, response): Promise<AxiosResponse> => {
  if (request._retry) {
    //we already tried to refresh, user is not authenticated
    goToLogin()
    return Promise.reject(response)
  }

  //try to do a token refresh
  return doTokenRefresh(request)
}

const onFullfilled = (response): AxiosResponse | Promise<AxiosResponse> => {
  const originalRequest = response.config
  debug('Axios Response:', response)

  //Check graphQL error
  if (hasGraphQlError(response)) {
    const error = response.data.errors[0]
    const status = graphQl2StatusError(error)
    response.status = status
    debug('GrapQl Api Error', status, originalRequest)

    //Authentication error and not yet retried
    if (status === 401) {
      return doRefreshWorkflow(originalRequest, response)
    }
  }

  //In this case, we have an http error
  if (!isValidStatus(response.status)) {
    return Promise.reject(response)
  }

  //If we are here, everything should be fine
  return Promise.resolve(response)
}

const onRejected = (error) => {
  const originalRequest = error.config
  debug('Axios Response:', error, error.response)

  //check if there are Rest API authentication error
  const { response } = error
  if (response.status === 401) {
    return doRefreshWorkflow(originalRequest, response)
  }

  return Promise.reject(error)
}

// Request interceptor for API calls
axios.interceptors.request.use((request) => {
  debug('Axios Request', request)
  return request
})
// Response interceptor for API calls
axios.interceptors.response.use(onFullfilled, onRejected)

export const getAxios = () => {
  return axios
}
