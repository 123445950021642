import React, { useContext } from 'react'
import * as Store from 'types/store'
import PortfolioPosition from '../common/PortfolioPosition'
import Trade from '../common/Trade'
import CartesianOperations from './CartesianOperations'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
import CandlestickView from './CandlestickView'

interface Props {
  sigmas: Store.StrategySigmas
}

const DetailsView: React.FC<Props> = ({ sigmas }) => {
  const context = useContext(CandleStickFrameContext)
  const { timeFrame } = context
  const cartesianReturn = sigmas?.returnList?.find((item) => item.type === timeFrame)
  const operations = sigmas?.cartesianOperationsList?.find((item) => item.type === timeFrame)

  return (
    <div className="details-candlestick-wrapper">
      <div className="details-view nonDraggable">
        <PortfolioPosition portfolioPosition={sigmas?.position} />
        <div className="operations-row">
          <Trade cartesianReturn={cartesianReturn} />
          <CartesianOperations operations={operations} timeFrame={timeFrame} />
        </div>
      </div>

      <CandlestickView />
    </div>
  )
}

export default DetailsView
