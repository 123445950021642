import { ThemeType } from 'themes/types'
import turquoise from './turquoise.module.scss'
import sandybrown from './sandybrown.module.scss'
import themeTest from './theme-test.module.scss'

export const themes: Json[] = [
  { id: ThemeType.Turquoise, styles: turquoise },
  { id: ThemeType.Sandybrown, styles: sandybrown },
  { id: ThemeType.Test, styles: themeTest },
]
