import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'
import { Avatar, Button, Popover } from 'antd'
import { SessionStatus } from 'types/enums'
import PlayIcon from 'assets/icon/play-icon.svg'
import StopIcon from 'assets/icon/stop-icon.svg'

interface Props {
  onClick: (status: SessionStatus) => void
  session: Store.Session
  loading: boolean
}

const VirtualPlayerControls: React.FC<Props> = ({ onClick, session, loading }) => {
  const { t } = useTranslation()
  const { status } = session
  const showStopButton =
    (status === SessionStatus.Running || status === SessionStatus.Broken) && !session.isHistorical

  return (
    <div className="player-controls-wrapper">
      <Popover placement="top" content={t('operativeSession.run')} trigger="hover">
        <Button
          onClick={() => onClick(SessionStatus.Running)}
          type="ghost"
          disabled={loading || status !== SessionStatus.Created}
        >
          <Avatar shape="square" src={PlayIcon} size={32} />
        </Button>
      </Popover>
      <Popover placement="top" content={t('operativeSession.stop')} trigger="hover">
        <Button
          onClick={() => onClick(SessionStatus.Stopped)}
          type="ghost"
          disabled={loading || !showStopButton}
        >
          <Avatar shape="square" src={StopIcon} size={32} />
        </Button>
      </Popover>
    </div>
  )
}

export default VirtualPlayerControls
