import { v4 as uuidv4 } from 'uuid'
import { Feeds } from 'types/enums.api'
import { flowControl } from 'core'
import { FlowControlSpeed } from 'types/enums'

export const getToken = () => {
  return `websocket.${uuidv4()}`
}

export const getHandshake = (token: string): string => {
  const command: Json = {
    '@type': 'login',
    token,
  }

  return JSON.stringify(command).concat('\n')
}

export const getLogout = (token: string) => {
  const command: Json = {
    '@type': 'logout',
    token,
  }

  return JSON.stringify(command).concat('\n')
}

export const getThrottling = (token: string, service: Feeds, speed: FlowControlSpeed) => {
  const command: Json = {
    '@type': 'throttling',
    token,
    throttling_details: {
      frequency: flowControl.getValue(speed),
      status: 'limited',
      feed_svc: service,
    },
  }

  return JSON.stringify(command).concat('\n')
}
