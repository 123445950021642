import { AssetFeed, Feeds, PortfolioFeed, StrategyFeed } from 'types/enums.api'

export const isStrategyFeed = (feed: Feeds) => {
  return Object.values(StrategyFeed).includes(feed as StrategyFeed)
}
export const isAssetFeed = (feed: Feeds) => {
  return Object.values(AssetFeed).includes(feed as AssetFeed)
}
export const isPortfolioFeed = (feed: Feeds) => {
  return Object.values(PortfolioFeed).includes(feed as PortfolioFeed)
}
