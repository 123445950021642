import { update_snapshot, update_ticker } from 'sockets/strategies/strategy_position_pb'
import * as Store from 'types/store'
import { convertToFloat } from '../common'

export const mapStrategyPositionSigmas = (
  feed: update_snapshot.AsObject | update_ticker.AsObject,
): Store.StrategySigmas => {
  const { sigmas } = feed
  if (!sigmas) {
    return null
  }

  const { stgCartesianPosition } = sigmas

  return {
    position: stgCartesianPosition && {
      valueLong: convertToFloat(stgCartesianPosition.countervalueBcLong) || 0,
      valueShort: convertToFloat(stgCartesianPosition.countervalueBcShort) || 0,
      value: convertToFloat(stgCartesianPosition.countervalueBc) || 0,
      netHedging: convertToFloat(stgCartesianPosition.netHedging) || 0,
    },
  } as Store.StrategySigmas
}
