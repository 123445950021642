import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import * as Store from 'types/store'

import { useSelector } from 'react-redux'
import { PortfolioFeed } from 'types/enums.api'
import CandleStick from 'v2/components/CandleStick'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import { useCandleStickData } from '../hooks'
import { filterEmptyData, getDateLimits, hasEmptyData } from 'core/helpers'
import Spinner from 'components/Spinner'
import CandlestickWrapper from 'v2/components/CandlestickWrapper'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
import sigmaServices from 'config/sigmaServices'
import { BUFFERS, config } from 'core'
import { getAuthenticationHeader } from 'api/common'

const { Return } = sigmaServices.Portfolios

interface Props {
  portfolio: Store.Portfolio
  width: number
  height: number
}

const PortfolioCandleStickContainer: React.FC<Props> = ({ portfolio, width, height }) => {
  const context = useContext(CandleStickFrameContext)
  const { timeFrame } = context
  const webWorkerRef = useRef<Worker | null>(null)

  const [data, setData] = useState<any>({
    candleStickData: [] as Store.CandleStick[],
  })
  const [workerParams, setWorkerParams] = useState<any>({})
  const [headers, setHeaders] = useState<any>({})
  
  const session = useSelector(selectOperativeSessionSession)
  const sessionId = session.id
  
  const { id } = portfolio
  
  // const { data: candleStickData, setParams } = useCandleStickData(
  //   Return.PrtClosedCandle,
  //   PortfolioFeed.Return,
  //   timeFrame,
  //   BUFFERS.Large
  // )

  const initMsg = useMemo(() =>
    {
      return {
        type: 'process',
        payload: {
          sigmas: [
            { title: 'candleStickData', service: Return.PrtClosedCandle, feed: PortfolioFeed.Return },
          ],
          timeFrame: timeFrame,
          bufferTimespan: BUFFERS.Large,
          session: session,
          params: workerParams,
          headers: headers,
          webSocketUrl: config.socketBaseUrl,
          tsUrl: config.timeSeriesBaseUrl,
        },
      }
    }, [id, timeFrame, workerParams, session, headers]
  )

  useEffect(() => {
    const getHeaders = async () => {
      const headers = await getAuthenticationHeader()
      setHeaders(headers)
    }
    getHeaders()
  }, [])
  
  useEffect(() => {
    if (!headers) {
      return
    }
    // Setup worker
    webWorkerRef.current = new Worker(new URL('../../../../workers/candleStickSockets.web.worker.ts', import.meta.url))

    webWorkerRef.current.onmessage = (event: MessageEvent) => {
      const { type, data: workerData, error } = event.data
      if (type === 'data') {
        setData(workerData)
      } else if (type === 'error') {
        console.error('Worker error:', error)
      }
    }

    return () => {
      webWorkerRef.current.postMessage({ type: 'terminate' })
      webWorkerRef.current.terminate()
    }
  }, [])

  useEffect(() => {
    // Send messages to worker
    const msg = {...initMsg}
    msg.payload.headers = headers
    msg.payload.params = workerParams

    webWorkerRef.current.postMessage(msg)
  }, [workerParams])

  const getFromTo = (from: Date, to: Date) => {
    console.log('getting from to')
    // setParams({ id, from, portfolioId: id, sessionId, to })
    setWorkerParams({ id, from, portfolioId: id, sessionId, to })
  }

  const noData = () => {
    if (data && hasEmptyData(data)) {
      return false
    }
    return true
  }

  // CHECK: performace gains on Assets CandleStick
  // - fix configuration initialization like other workers

  // TODO: reduce layout trashing
  // - use a single wrapper for all candlesticks??
  // - reduce data frequency
  // - reduce frames per seconds
  // - recude nodes in the DOM


  return (
    <CandlestickWrapper>
      {noData() && <Spinner />}
      <CandleStick
        loading={noData()}
        timeFrame={timeFrame}
        session={session}
        // candleStickData={filterEmptyData(candleStickData) as Store.CandleStick[]}
        candleStickData={data.candleStickData as Store.CandleStick[]}
        lineChartData={[]}
        operationsChartData={[]}
        currency={null}
        getFromTo={getFromTo}
        getXDomain={() => getDateLimits(timeFrame, session)}
        width={width}
        height={height}
      />
    </CandlestickWrapper>
  )
}

export default PortfolioCandleStickContainer
