const colors: Json = {
  primary: '#6bbffb',
  orange: '#fba76b',
  azure: '#43a1ff',
  green: '#6bfba7',
  darkGreen: '#08f168',
  purple: '#c9a4fd',
  white: '#ffffff',

  // orange: '#ff8a3b',
  // green: '#00ffb2',
  // darkGreen: '#009d3f',

  candleWhite: '#ffffff',
  candleBlack: '#787878',

  operationsShort: '#FFA200',
  operationsLong: '#3B9DFF',

  red: '#ff3200',
  blue: '#00ceff',
  positiveGreen: '#00ff87',
  grey: '#989eac',
  lightgreen: '#37a199',
  silver: 'silver',
  midnight: '#030611',

  // statusOrange: '#ffb302',
  // statusGreen: '#56f000',
  // statusDarkGreen: '#04e65d',
  // statusBlue: '#2dccff',

  // mapView
  standByFlat: '#ad6cff',
  standByShort: '#ff8a3b',
  standByLong: '#43a1ff',
  automatic: '#36db34',
  strategyCircle: '#8CACF1',
  portfolioCircle: '#0E2D71',
  freeze: '#a2e4e6',
  notActivable: '#414B65',

  //session status
  created: '#0cd1f8',
  updating: '#6be3fb',
  running: '#58fa9c',
  completed: '#08f168',
  stopped: '#fa9c58',
  quit: '#fc8a94',
  broken: '#f93242',
  terminating: '#b9bdc6',
  off: '#414B65',
}

export default colors
