import React from 'react'
import * as Store from 'types/store'
import { Menu, Typography } from 'antd'
import { NodeType } from 'types/ui'
import { useTranslation } from 'react-i18next'
import { FrameType } from 'types/enums'
import styles from './styles.module.scss'
import { Feature } from 'flagged'
import { features } from 'config/features'
import classNames from 'classnames'
import { themes } from './themes'
import { useTheme } from 'providers/ThemeProvider'

interface Props {
  selectedResource: Store.SelectedResource
  onItemClick: (type: FrameType) => void
}

const ContextMenu: React.FC<Props> = ({ selectedResource, onItemClick }) => {
  const { t } = useTranslation()
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const menuClass = classNames(styles['context-menu'], themeStyles['theme'])

  if (!selectedResource) {
    return null
  }

  const { resource, type } = selectedResource
  const name = resource ? resource.name : ''

  const getAssetMenu = () => (
    <>
      <Typography.Title>{t('general.asset')}</Typography.Title>
      <Typography.Paragraph>{name}</Typography.Paragraph>
      <Menu>
        <Menu.Item key="asset-key-1" onClick={() => onItemClick(FrameType.Chart)}>
          {t('chart.addGraph')}
        </Menu.Item>
      </Menu>
    </>
  )

  const getPortfolioMenu = () => (
    <>
      <Typography.Title>{t('general.portfolio')}</Typography.Title>
      <Typography.Paragraph>{name}</Typography.Paragraph>
      <Menu>
        <Menu.Item key="portfolio-key-1" onClick={() => onItemClick(FrameType.Chart)}>
          {t('chart.addGraph')}
        </Menu.Item>
        <Menu.Item key="portfolio-key-2" onClick={() => onItemClick(FrameType.Table)}>
          {t('chart.addTable')}
        </Menu.Item>
        <Feature name={features.enableAggregate}>
          <Menu.Item key="portfolio-key-3" disabled onClick={() => onItemClick(FrameType.Aggregate)}>
            {t('chart.addAggregate')}
          </Menu.Item>
        </Feature>
      </Menu>
    </>
  )

  const getStrategyMenu = () => (
    <>
      <Typography.Title>{t('general.strategy')}</Typography.Title>
      <Typography.Paragraph>{name}</Typography.Paragraph>
      <Menu>
        <Menu.Item key="strategy-key-1" onClick={() => onItemClick(FrameType.Chart)}>
          {t('chart.addGraph')}
        </Menu.Item>
        <Menu.Item key="strategy-key-2" onClick={() => onItemClick(FrameType.Table)}>
          {t('chart.addTable')}
        </Menu.Item>
        <Feature name={features.enableAggregate}>
          <Menu.Item key="strategy-key-3" disabled onClick={() => onItemClick(FrameType.Aggregate)}>
            {t('chart.addAggregate')}
          </Menu.Item>
        </Feature>
      </Menu>
    </>
  )

  const getAllStrategiesMenu = () => (
    <Menu>
      <Feature name={features.enableAggregate}>
        <Menu.Item key="all-strategy-key-1" onClick={() => onItemClick(FrameType.Aggregate)} disabled>
          {t('chart.addAggregate')}
        </Menu.Item>
      </Feature>
    </Menu>
  )

  let menu
  switch (type) {
    case NodeType.Asset:
      menu = getAssetMenu()
      break
    case NodeType.Portfolio:
      menu = getPortfolioMenu()
      break
    case NodeType.Strategy:
      menu = getStrategyMenu()
      break
    case NodeType.AllStrategies:
      menu = getAllStrategiesMenu()
      break
    default:
      menu = null
      break
  }
  if (!menu) {
    return null
  }

  return (
    <div onContextMenu={(e) => e.preventDefault()} className={menuClass}>
      {menu}
    </div>
  )
}

export default ContextMenu
