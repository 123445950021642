import * as K from './constants'
import { put, call } from 'redux-saga/effects'
import Debug from 'debug'
import * as Api from 'types/api'
import * as api from 'api/portfolios'
import { ErrorCode } from 'types/enums'
import { ApplicationError, isApiError, isGraphQlError } from 'core'
import { jsonToPortfolios } from 'services/store/mapService'

const debug = Debug('Frontend')

export function* fetchPortfolios() {
  debug('Fetch Portfolios')
  const response = yield call(api.getPortfolios)
  if (isApiError(response) || isGraphQlError(response)) {
    throw new ApplicationError(ErrorCode.Api, 'fetch getPortfolios failed')
  }

  const json = (response as Api.PortfoliosResponse).data.application_portfolio

  const portfolios = jsonToPortfolios(json)
  debug(`Loaded ${portfolios.length} Portfolios`)

  yield put({ type: K.PORTFOLIOS_FETCH_SUCCESS, payload: portfolios })
}
