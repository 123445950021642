import React from 'react'
import { Col, Form, InputNumber, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import DependsOn from 'components/DependsOn'
import { FormInstance } from 'antd/lib/form'
import { withCurrencyFormat } from 'components/hoc'
const { Paragraph } = Typography

interface Props {
  form: FormInstance
}

const QtyParametrs: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation()
  const CurrencyInput = withCurrencyFormat(InputNumber)

  const greaterThanZero = (value: number) => (value ? (value > 0 ? value : 1) : '')
  return (
    <>
      <DependsOn fields={['qtyFCounterValue']}>
        {(fields) => {
          const { qtyFCounterValue } = fields

          const required = !qtyFCounterValue

          if (!required) {
            form.resetFields(['refQuantity', 'refQuantityMax', 'refQuantityMin'])
          }

          return (
            <>
              <Row align="middle" gutter={[5, 0]}>
                <Col span={12}>
                  <Paragraph>{t('assets.refQuantity')}</Paragraph>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="refQuantity"
                    normalize={greaterThanZero}
                    rules={[{ required, message: t('error.requiredField') }]}
                  >
                    <CurrencyInput disabled={!required} />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Typography.Text className="field-description">
                    {t('assets.description.refQuantity')}
                  </Typography.Text>
                </Col>
              </Row>
              <Row align="middle" gutter={[5, 0]}>
                <Col span={12}>
                  <Paragraph>{t('assets.refQuantityMax')}</Paragraph>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="refQuantityMax"
                    normalize={greaterThanZero}
                    rules={[
                      { required, message: t('error.requiredField') },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (getFieldValue('refQuantity') <= value || !required) {
                            return Promise.resolve()
                          }
                          return Promise.reject(new Error(t('error.maxRefQty')))
                        },
                        message: t('error.maxRefQty'),
                        validateTrigger: 'onFinish',
                      }),
                    ]}
                  >
                    <CurrencyInput disabled={!required} />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Typography.Text className="field-description">
                    {t('assets.description.refQuantityMax')}
                  </Typography.Text>
                </Col>
              </Row>
              <Row align="middle" gutter={[5, 0]}>
                <Col span={12}>
                  <Paragraph>{t('assets.refQuantityMin')}</Paragraph>
                </Col>
                <Col span={12}>
                  {/*  */}
                  <Form.Item
                    name="refQuantityMin"
                    normalize={greaterThanZero}
                    rules={[
                      { required, message: t('error.requiredField') },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (getFieldValue('refQuantity') >= value || !required) {
                            return Promise.resolve()
                          }
                          return Promise.reject(new Error(t('error.minRefQty')))
                        },
                        message: t('error.minRefQty'),
                        validateTrigger: 'onFinish',
                      }),
                    ]}
                  >
                    <CurrencyInput disabled={!required} />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Typography.Text className="field-description">
                    {t('assets.description.refQuantityMin')}
                  </Typography.Text>
                </Col>
              </Row>
            </>
          )
        }}
      </DependsOn>
      <DependsOn fields={['minOrderCounterValue']}>
        {({ minOrderCounterValue }) => {
          const required = !minOrderCounterValue
          return (
            <Row align="middle" gutter={[5, 0]}>
              <Col span={12}>
                <Paragraph>{t('assets.minOrderQuantity')}</Paragraph>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="minOrderQuantity"
                  normalize={greaterThanZero}
                  rules={[{ required, message: t('error.requiredField') }]}
                >
                  <CurrencyInput disabled={!required} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Typography.Text className="field-description">
                  {t('assets.description.minOrderQuantity')}
                </Typography.Text>
              </Col>
            </Row>
          )
        }}
      </DependsOn>
      <DependsOn fields={['qtyFCounterValue']}>
        {(fields) => {
          const { qtyFCounterValue } = fields
          const required = qtyFCounterValue

          if (!required) {
            form.resetFields(['refCounterValue', 'refCounterValueMax', 'refCounterValueMin'])
          }

          return (
            <>
              <Row align="middle" gutter={[5, 0]}>
                <Col span={12}>
                  <Paragraph>{t('assets.refCounterValue')}</Paragraph>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="refCounterValue"
                    normalize={greaterThanZero}
                    rules={[{ required, message: t('error.requiredField') }]}
                  >
                    <CurrencyInput disabled={!required} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Typography.Text className="field-description">
                    {t('assets.description.refCounterValue')}
                  </Typography.Text>
                </Col>
              </Row>
              <Row align="middle" gutter={[5, 0]}>
                <Col span={12}>
                  <Paragraph>{t('assets.refCounterValueMax')}</Paragraph>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="refCounterValueMax"
                    normalize={greaterThanZero}
                    rules={[
                      { required, message: t('error.requiredField') },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (getFieldValue('refCounterValue') <= value || !required) {
                            return Promise.resolve()
                          }
                          return Promise.reject(new Error(t('error.refCounterValueMax')))
                        },
                        message: t('error.refCounterValueMax'),
                        validateTrigger: 'onFinish',
                      }),
                    ]}
                  >
                    <CurrencyInput disabled={!required} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Typography.Text className="field-description">
                    {t('assets.description.refCounterValueMax')}
                  </Typography.Text>
                </Col>
              </Row>
              <Row align="middle" gutter={[5, 0]}>
                <Col span={12}>
                  <Paragraph>{t('assets.refCounterValueMin')}</Paragraph>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="refCounterValueMin"
                    normalize={greaterThanZero}
                    rules={[
                      { required, message: t('error.requiredField') },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (getFieldValue('refCounterValue') >= value || !required) {
                            return Promise.resolve()
                          }
                          return Promise.reject(new Error(t('error.refCounterValueMin')))
                        },
                        message: t('error.refCounterValueMin'),
                        validateTrigger: 'onFinish',
                      }),
                    ]}
                  >
                    <CurrencyInput disabled={!required} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Typography.Text className="field-description">
                    {t('assets.description.refCounterValueMin')}
                  </Typography.Text>
                </Col>
              </Row>
            </>
          )
        }}
      </DependsOn>

      <DependsOn fields={['minOrderQuantity']}>
        {({ minOrderQuantity }) => {
          const required = !minOrderQuantity
          return (
            <Row align="middle" gutter={[5, 0]}>
              <Col span={12}>
                <Paragraph>{t('assets.minOrderCounterValue')}</Paragraph>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="minOrderCounterValue"
                  normalize={greaterThanZero}
                  rules={[{ required: required, message: t('error.requiredField') }]}
                >
                  <CurrencyInput disabled={!required} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Typography.Text className="field-description">
                  {t('assets.description.minOrderCounterValue')}
                </Typography.Text>
              </Col>
            </Row>
          )
        }}
      </DependsOn>
    </>
  )
}

export default QtyParametrs
