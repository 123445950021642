import React from 'react'
import { useTranslation } from 'react-i18next'
import { ExtendedDataNode } from 'types/ui'
import style from './styles.module.scss'
import { Typography, Collapse, Space, Button, Row, Col, Avatar } from 'antd'
import StrategyPanel from './StrategyPanel'
import { CrudOperation } from 'types/enums'
import * as Store from 'types/store'
import { isActiveElement } from 'services/treeService'
import EditIcon from 'assets/icon/edit-icon.svg'
import TrashIcon from 'assets/icon/trash-icon.svg'

const { Panel } = Collapse

interface Props {
  tree: Array<ExtendedDataNode>
  onItemClicked: (item: ExtendedDataNode, operation: CrudOperation) => void
  userAction: Store.UserAction
}

const TreeSelector: React.FC<Props> = ({ tree, onItemClicked: itemClicked, userAction }) => {
  const { t } = useTranslation()

  const addButtons = (item: ExtendedDataNode) => {
    return (
      <Space className="pr-24">
        <Button
          type="ghost"
          size="small"
          disabled={!item.isDeletable}
          onClick={(event) => {
            itemClicked(item, CrudOperation.Update)
            event.stopPropagation()
          }}
        >
          <Avatar shape="square" src={EditIcon} size={16} />
        </Button>
        <Button
          type="ghost"
          size="small"
          disabled={!item.isDeletable}
          onClick={(event) => {
            itemClicked(item, CrudOperation.Delete)
            event.stopPropagation()
          }}
        >
          <Avatar shape="square" src={TrashIcon} size={16} />
        </Button>
      </Space>
    )
  }

  const renderPanelHeader = (item: ExtendedDataNode) => {
    return (
      <Button onClick={() => itemClicked(item, CrudOperation.Read)} type="ghost" size="small">
        {item.title}
      </Button>
    )
  }

  const renderPanel = (item: ExtendedDataNode) => {
    return (
      <Panel
        key={item.key}
        header={renderPanelHeader(item)}
        showArrow={false}
        extra={addButtons(item)}
        className={isActiveElement(item, userAction) && 'active'}
      >
        <StrategyPanel itemClicked={itemClicked} nodes={item.children} userAction={userAction} />
        <Button
          type="dashed"
          className="mt-16"
          onClick={(event) => {
            itemClicked(item, CrudOperation.Create)
            event.stopPropagation()
          }}
        >
          {t('general.addPortfolio')}
        </Button>
      </Panel>
    )
  }

  return (
    <section className={style['tree-selector']}>
      <section className="strategies-menu">
        <Row align="middle" className="col-title">
          <Col span={14} className="title">
            <div className="col-trigger">
              <Typography.Title>{t('menu.strategies')}</Typography.Title>
            </div>
          </Col>
          <Col span={10} className="align-right add-btn">
            <Button
              type="dashed"
              onClick={(event) => {
                itemClicked(null, CrudOperation.Create)
                event.stopPropagation()
              }}
            >
              {t('general.addStrategy')}
            </Button>
          </Col>
        </Row>

        <Collapse defaultActiveKey={tree[0]?.key} ghost>
          {tree && tree.map((item) => renderPanel(item))}
        </Collapse>
      </section>
    </section>
  )
}

export default TreeSelector
