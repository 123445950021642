import produce from 'immer'
import { LoadingState } from 'types/enums'
import * as K from './constants'
import { AnyAction } from 'redux'
import * as Store from 'types/store'
import { sortResources } from 'core/helpers'
import { ResponseCode } from 'types/enums.api'

export const initialState: Store.AssetState = {
  data: [],
  state: LoadingState.Unset,
  error: null,
}

const reducer = (state = initialState, action: AnyAction) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case K.ASSETS_FETCH_SUCCESS:
        const data = action.payload as Array<Store.Asset>
        draft.data = sortResources(data) as Array<Store.Asset>
        break

      case K.ASSET_CREATE_SUCCESS:
        const asset = action.payload as Store.Asset
        draft.data.push(asset)
        draft.data = sortResources(draft.data) as Array<Store.Asset>
        break

      case K.ASSET_DELETE_SUCCESS:
        const id = action.payload as number
        const index = draft.data.findIndex((item) => item.id === id)
        if (index !== -1) {
          draft.data.splice(index, 1)
        }
        break

      case K.ASSET_UPDATE_SUCCESS:
        {
          const asset = action.payload as Store.Asset
          const index = draft.data.findIndex((item) => item.id === asset.id)
          draft.data[index] = asset
          draft.data = sortResources(draft.data) as Array<Store.Asset>
        }
        break

        switch (action.type) {
          case K.HISTORICAL_ASSETS_FETCH_SUCCESS:
            const data = action.payload as Array<Store.Asset>
            draft.data = sortResources(data) as Array<Store.Asset>
            break
        }

      case K.SET_STATUS:
        draft.state = action.payload as LoadingState
        break

      case K.SET_ERROR:
        draft.error = action.payload
        break
    }
  })
}

export default reducer
