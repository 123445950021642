import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AppContentCard from 'components/AppContentCard'

import GraphIcon from 'assets/icon/graph-icon.svg'
import TableIcon from 'assets/icon/table-icon.svg'
import AggregateIcon from 'assets/icon/aggregate-icon.svg'
import CloseIcon from 'assets/icon/close-icon.svg'
import { Modal, Row, Col, Space, Button, Typography, Radio, Image, Avatar } from 'antd'
import styles from './styles.module.scss'
import { StrategyTransferContainer } from 'containers/transfer/StrategyTransfer'
import * as Store from 'types/store'
import { CrudOperation, FrameType, SessionType } from 'types/enums'
import { NodeType } from 'types/ui'
import { useFeature } from 'flagged'
import { features } from 'config/features'
import { generateLayout } from 'core/layoutHelper'

const { Title } = Typography

interface Props {
  initialFrame?: Store.Frame
  onClose: () => void
  onAdd: (selectedResource: Store.SelectedResource[], type: FrameType) => void
  onEdit: (frame: Store.Frame) => void
  sessionType: SessionType
  visible: boolean
}

const AddFrameModal: React.FC<Props> = ({ initialFrame, onClose, onAdd, onEdit, sessionType, visible }) => {
  const { t } = useTranslation()
  const [selectedItems, setSelectedItems] = useState<Store.SelectedResource[]>([])

  useEffect(() => {
    setSelectedItems(initialFrame?.selectedResources || [])
  }, [initialFrame?.selectedResources, visible])

  const operation = initialFrame ? CrudOperation.Update : CrudOperation.Create
  const [frameType, setFrameType] = useState<FrameType>(initialFrame?.frameType)
  const multiple = selectedItems.length > 1
  const isAggregateEnabled = useFeature(features.enableAggregate) as boolean

  useEffect(() => {
    if (!visible) {
      setSelectedItems([])
    }
  }, [visible])

  const onDataChanged = (selectedItems: Store.SelectedResource[]) => {
    setSelectedItems(selectedItems)
    if (multiple) {
      if (selectedItems.length <= 1) {
        setFrameType(null)
      }
    } else {
      setFrameType(null)
    }
  }

  const addFrame = (frameType: FrameType) => {
    setFrameType(null)
    onAdd(selectedItems, frameType)
  }

  const editFrame = (frameType: FrameType) => {
    const { id, layout } = initialFrame
    const frame: Store.Frame = {
      ...initialFrame,
      selectedResources: selectedItems,
      frameType: frameType,
      layout: generateLayout(id, frameType, layout.x, layout.y),
    }

    onEdit(frame)
  }

  const onFinish = (frameType: FrameType) => {
    switch (operation) {
      case CrudOperation.Create:
        addFrame(frameType)
        break
      case CrudOperation.Update:
        editFrame(frameType)
        break
    }
    onClose()
  }

  const mapFrameDefaultKeys = () => {
    return initialFrame.selectedResources.map((item) => {
      switch (item.type) {
        case NodeType.Asset:
          return `asset-${item.resource.id}`
        case NodeType.Group:
          return `group-${item.resource.id}`
        case NodeType.Portfolio:
          return `portfolio-${item.resource.id}`
        case NodeType.Strategy:
          return `strategy-${item.resource.id}`
      }
    })
  }

  const isDisabled = (type: FrameType) => {
    if (selectedItems.length === 0) {
      return true
    }
    if (sessionType === SessionType.BackTest) {
      return multiple || type !== FrameType.Chart
    }

    if (multiple && type !== FrameType.Aggregate) {
      return true
    }

    if (selectedItems.length === 1) {
      switch (selectedItems[0].type) {
        case NodeType.Asset:
          return !(type === FrameType.Chart)
        default:
          return false
      }
    }

    return false
  }

  
  const defaultKeys = initialFrame ? mapFrameDefaultKeys() : []
  // JI-ADD-FRAME
  // console.log('adding a frame with keys: ', defaultKeys)
  return (
    <Modal
      width={'50%'}
      title={null}
      centered={true}
      open={visible}
      className={styles['frame-modal']}
      footer={null}
      destroyOnClose={true}
      closable={false}
      wrapClassName="nonDraggable"
    >
      <AppContentCard>
        <div className="close-btn-wrapper">
          <Button className="col-close" type="ghost" onClick={onClose}>
            <Avatar shape="square" src={CloseIcon} size={24} />
          </Button>
        </div>

        <Title level={2} className="mb-16">
          {initialFrame ? t('operativeSession.editFrame') : t('operativeSession.addNewFrame')}
        </Title>

        <Title level={2} className="primary-color mb-16">
          {t('operativeSession.chooseAsset')}
        </Title>

        <StrategyTransferContainer defaultKeys={defaultKeys} onDataChanged={onDataChanged} />

        <Row>
          <Col span={24}>
            <Title level={2} className="primary-color mt-30 mb-16">
              {t('operativeSession.chooseGraphType')}
            </Title>
          </Col>

          <Col span={24}>
            <Radio.Group
              onChange={(e) => {
                onFinish(e.target.value)
              }}
              value={frameType}
            >
              <Space>
                <Radio.Button value={FrameType.Chart} disabled={isDisabled(FrameType.Chart)}>
                  <AppContentCard>
                    <Image src={GraphIcon} preview={false} width={70} />
                    <Title level={3}>{t('operativeSession.graph')}</Title>
                  </AppContentCard>
                </Radio.Button>
                {sessionType !== SessionType.BackTest && (
                  <>
                    <Radio.Button value={FrameType.Table} disabled={isDisabled(FrameType.Table)}>
                      <AppContentCard>
                        <Image src={TableIcon} preview={false} width={70} />
                        <Title level={3}>{t('operativeSession.table')}</Title>
                      </AppContentCard>
                    </Radio.Button>

                    <Radio.Button
                      value={FrameType.Aggregate}
                      disabled={!isAggregateEnabled || isDisabled(FrameType.Aggregate)}
                    >
                      <AppContentCard>
                        <Image src={AggregateIcon} preview={false} width={70} />
                        <Title level={3}>{t('operativeSession.aggregate')}</Title>
                      </AppContentCard>
                    </Radio.Button>
                  </>
                )}
              </Space>
            </Radio.Group>
          </Col>
        </Row>
      </AppContentCard>
    </Modal>
  )
}

export default AddFrameModal
