import React, { useContext } from 'react'
import HistoricalAssetCandleStickContainer from 'v2/containers/CandleStick/historical/Asset'
import AssetCandleStickContainer from 'v2/containers/CandleStick/Asset'
import * as Store from 'types/store'
import { useMeasure } from 'react-use'

import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import { selectAssetById } from 'store/resources/selectors'

const CandlestickView: React.FC = () => {
  const context = useContext(CandleStickFrameContext)
  const session = useSelector(selectOperativeSessionSession)
  const [ref, { width, height }] = useMeasure()
  const { timeFrame, resource } = context
  const asset = resource as Store.Asset
  // TODO: check how assets are currently put into dashboard.frames[] Redux state
  // and see if it needs to be rafactored
  // (assets are in two different states as of now but only the resources.assets[] ones has the baseCurrency)
  const assetFromResourceState = useSelector(selectAssetById(asset.id))

  const getCandleStick = () => {
    if (session.isHistorical) {
      return (
        <HistoricalAssetCandleStickContainer asset={assetFromResourceState} key={timeFrame} width={width} height={height} />
      )
    }
    // Asset.tsx
    return <AssetCandleStickContainer asset={assetFromResourceState} key={timeFrame} width={width} height={height} />
  }

  const isSmall = () => {
    if (width < 50 || height < 50) {
      return true
    }

    return false
  }

  return (
    <section className="candlestick-view JI-RENDER-candlestick-view-component nonDraggable" ref={ref}>
      {!isSmall() && getCandleStick()}
    </section>
  )
}

export default CandlestickView
