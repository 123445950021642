import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GridItemActions } from 'types/ui'
import { removeFrame, updateFrameOptions } from 'store/dashboard/actions'
import * as UI from 'types/ui'
import * as Store from 'types/store'
import TechnicalChart from 'v2/components/frames/TechnicalChart'
import SelectTimeRangeModal from 'components/modals/SelectTimeRangeModal'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import moment from 'moment'
import { TechnicalFrameContext, TechnicalFrameContextInterface } from 'contexts/TechnicalFrameContext'

const getTimeRangeValue = (session: Store.Session): [moment.Moment, moment.Moment] => {
  if (session.isHistorical) {
    return [moment(session.startTime), moment(session.endTime)]
  }
  return [moment(session.startTime), null]
}

interface Props {
  frame: Store.Frame
}

const TechnicalChartContainer: React.FC<Props> = ({ frame }) => {
  const {
    options: { viewType },
  } = frame
  const [isModalVisible, setModalVisible] = useState(false)
  const [isFullScreen, setFullScreen] = useState(false)
  const session = useSelector(selectOperativeSessionSession)
  const [timeRange, setTimeRange] = useState<[moment.Moment, moment.Moment]>(null)

  useEffect(() => {
    if (session.startTime && !timeRange) {
      setTimeRange(getTimeRangeValue(session))
    }
  }, [session.startTime])

  const dispatch = useDispatch()
  const onItemClick = (action: GridItemActions) => {
    switch (action) {
      case GridItemActions.Close:
        dispatch(removeFrame(frame))
        break
      case GridItemActions.Configure:
        setModalVisible(true)
        break
    }
  }

  const onTimeRangeSelected = (start: moment.Moment, end: moment.Moment) => {
    if (session.startTime && start.isBefore(moment(session.startTime))) {
      setTimeRange([moment(session.startTime), end])
    } else {
      setTimeRange([start, end])
    }
    setModalVisible(false)
  }

  const setViewType = (viewType: UI.ViewType) => {
    const options = { ...frame.options, viewType }
    dispatch(updateFrameOptions({ id: frame.id, options }))
  }

  const context: TechnicalFrameContextInterface = {
    id: frame.id,
    squares: {
      x: frame.layout.w,
      y: frame.layout.h,
    },
    isFullscreen: isFullScreen,
    viewType,
    setViewType,
    setFullScreen,
    timeRange: timeRange,
  }

  useEffect(() => {
    if (timeRange && timeRange[0].isBefore(moment(session.startTime))) {
      setTimeRange((timeRange) => [moment(session.startTime), timeRange[1]])
    }
  }, [session.startTime])

  return (
    <>
      {timeRange && (
        <SelectTimeRangeModal
          visible={isModalVisible}
          onClose={() => setModalVisible(false)}
          timeRange={timeRange}
          onOk={onTimeRangeSelected}
        />
      )}
      <TechnicalFrameContext.Provider value={context}>
        <TechnicalChart key={timeRange && timeRange[0].toISOString()} onItemClick={onItemClick} />
      </TechnicalFrameContext.Provider>
    </>
  )
}

export default TechnicalChartContainer
