import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import { ChartChild } from 'types/chart'
import { TimeFrame } from 'types/enums'
import moment from 'moment'
import { AxisTimeInterval } from 'd3'

const YTD_TICK_SIZE = 60
const TDY_TICK_SIZE = 70

interface Props {
  data: Date[]
  bottom?: number
  timeFrame: TimeFrame
}

const XAxisDate: React.FC<Props & ChartChild> = ({
  data,
  bottom = 0,
  chartWidth: width,
  chartHeight: height,
  d3Transform: transform,
  timeFrame,
  xDomain,
}) => {
  const element = useRef(null)

  const getXDomain = () => {
    if (xDomain) {
      return xDomain
    }

    return [d3.min(data), d3.max(data)]
  }
  const xScale = transform.rescaleX(d3.scaleTime().domain(getXDomain()).range([0, width]))
  useEffect(() => {
    const bottomAxis = d3.axisBottom(xScale)
    if (timeFrame === TimeFrame.YTD || timeFrame === TimeFrame.TOT) {
      //show less ticks
      const axisInterval: AxisTimeInterval = {
        range: (start, stop) => {
          const stopDate = moment(stop).startOf('day')
          const startDate = moment(start)
          const difference = stopDate.diff(startDate, 'days')
          const size = Math.floor(width / YTD_TICK_SIZE)
          const step = Math.ceil(difference / size)

          const dates = [stopDate.toDate()]
          if (isNaN(step)) {
            console.log('step', step)
          }

          while (stopDate.subtract(step, 'day') > startDate && !isNaN(step) && step != 0) {
            dates.unshift(stopDate.toDate())
          }

          return dates
        },
      }

      bottomAxis.tickFormat((d: Date) => {
        // only show ticks that are the beginning of day
        const date = moment(d).unix() === moment(d).startOf('day').unix()
        if (date) {
          return moment(d).format('L')
        }
      })
      bottomAxis.ticks(axisInterval)
    } else {
      // show hour and minute for TDY

      bottomAxis.tickFormat((d: Date) => {
        // only show ticks that are the beginning of day
        const date = moment(d).seconds() === 0
        if (date) {
          return moment(d).format('HH:mm')
        }
      })
      bottomAxis.ticks(Math.round(width / TDY_TICK_SIZE))
    }
    const selection = d3
      .select(element.current)
      .attr('class', 'x axis')
      .attr('transform', `translate(0, ${height - bottom})`)
    selection.call(bottomAxis)

    selection.selectAll('.axis .tick text').each(function () {
      const text = this as HTMLElement
      if (text.innerHTML === '') {
        text.parentNode['style'].display = 'none'
      }
    })
  })

  return <g ref={element} />
}

export default XAxisDate
