import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import { ChartChild } from 'types/chart'
import * as Store from 'types/store'
import colors from 'config/colors'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { formatGliphNumber, formatPercent } from 'core/formats'
import { OpenOperationType } from 'types/enums'
import { clamp } from 'lodash'

interface Props {
  data: Store.Operation[]
  currency: string
  baseCurrency: string
}

const OperationsChart: React.FC<Props & ChartChild> = ({
  data,
  yDomain,
  chartWidth: width,
  chartHeight: height,
  d3Transform: transform,
  clipId,
  xDomain,
  currency,
  baseCurrency,
  // transitionTime,
}) => {
  const element = useRef(null)
  const { t } = useTranslation()
  const x = data.map((item) => item.startTime)

  const getColorBySignalType = (type) => {
    switch (type) {
      case 'LONG':
      case OpenOperationType.Long:
        return colors.operationsLong
      case 'SHORT':
      case OpenOperationType.Short:
        return colors.operationsShort
      case 'FLAT':
      case OpenOperationType.Flat:
        return colors.violet
      default:
        return colors.positiveGreen
    }
  }
  const getYDomain = () => {
    if (yDomain) {
      return yDomain
    }

    const ymin = d3.min(data.map((r) => r.minPrice))
    const ymax = d3.max(data.map((r) => r.maxPrice))

    return [ymin, ymax]
  }

  const getXDomain = () => {
    if (xDomain) {
      return xDomain
    }

    return [d3.min(x), d3.max(x)]
  }

  const generateTable = (d: Store.Operation) => {
    const defaultCurrency = baseCurrency
    const startTime = moment(d.startTime)
    const endTime = moment(d.endTime)

    const isSameDate = startTime.isSame(endTime, 'date')

    return `
      <table>
      <tr>
        <td>${t('chart.sign')}</td>
        <td>${d.sign}</td>
      </tr>
      <tr>
        <td>${t('chart.openPrice')}</td>
        <td>${d.avgOpenPrice}</td>
      </tr>
      <tr>
        <td>${t('chart.closePrice')}</td>
        <td>${d.avgClosePrice}</td>
      </tr>
      <tr>
        <td>${t('chart.size')}</td>
        <td>${d.size}</td>
      </tr>
      <tr>
        <td>${t('chart.startTime')}</td>
        <td>${startTime.format('LTS')}</td>
      </tr>
      <tr>
        <td>${t('chart.endTime')}</td>
        <td>${endTime.format('LTS')}</td>
      </tr>
      ${
        !isSameDate
          ? `<tr>
        <td>${t('chart.startDate')}</td>
        <td>${moment(d.startTime).format('L')}</td>
      </tr>
      <tr>
        <td>${t('chart.endDate')}</td>
        <td>${moment(d.endTime).format('L')}</td>
      </tr>`
          : ''
      }
      <tr>
        <td>${t('chart.maxPrice')}</td>
        <td>${d.maxPrice}</td>
      </tr>
      <tr>
        <td>${t('chart.minPrice')}</td>
        <td>${d.minPrice}</td>
      </tr>
      ${
        currency !== defaultCurrency
          ? `<tr>
            <td>${t('chart.tradingReturn')} ${currency}</td>
            <td>${formatGliphNumber(d.totalTradingReturn)}</td>
          </tr>`
          : ''
      }
        <tr>
        <td>${t('chart.tradingPercReturn')} ${currency}</td>
          <td>${formatPercent(d.totalPercReturn)}</td>
        </tr>
      <tr>
        <td>${t('chart.tradingReturn')} ${defaultCurrency}</td>
        <td>${formatGliphNumber(d.totalTradingReturnBc)}</td>
      </tr>
      ${
        currency !== defaultCurrency
          ? `<tr>
          <td>${t('general.TDY')} ${t('chart.tradingReturn')} ${currency}</td>
          <td>${formatGliphNumber(d.tdyTradingReturn)}</td>
        </tr>`
          : ''
      }
      <tr>
        <td>${t('general.TDY')} ${t('chart.tradingPercReturn')} ${currency}</td>
        <td>${formatPercent(d.tdyPercReturn)}</td>
      </tr>
      <tr>
        <td>${t('general.TDY')} ${t('chart.tradingReturn')} ${defaultCurrency}</td>
        <td>${formatGliphNumber(d.tdyTradingReturnBc)}</td>
      </tr>
      ${
        currency !== defaultCurrency
          ? `<tr>
          <td>${t('general.YTD')} ${t('chart.tradingReturn')} ${currency}</td>
          <td>${formatGliphNumber(d.ytdTradingReturn)}</td>
        </tr>`
          : ''
      }
      <tr>
        <td>${t('general.YTD')} ${t('chart.tradingPercReturn')} ${currency}</td>
        <td>${formatPercent(d.ytdPercReturn)}</td>
      </tr>
      <tr>
        <td>${t('general.YTD')} ${t('chart.tradingReturn')} ${defaultCurrency}</td>
        <td>${formatGliphNumber(d.ytdTradingReturnBc)}</td>
      </tr>
      </table>
    `
  }

  const getDiv = () => {
    let div = d3.select('.chart-tooltip')
    if (div.empty()) {
      div = d3.select('body').append('div').attr('class', 'chart-tooltip').style('z-index', 5000)
    }
    return div
  }

  const render = (dataChanged: boolean) => {
    // const transition = dataChanged ? 0 : transitionTime
    const xScale = transform.rescaleX(d3.scaleTime().domain(getXDomain()).range([0, width]))
    const yScale = d3.scaleLinear().domain(getYDomain()).range([height, 0])

    const operationsChartBody = d3.select(element.current)
    operationsChartBody.attr('clip-path', `url(#${clipId})`)
    operationsChartBody.attr('class', 'd3-operations-chart')

    const operations = operationsChartBody.selectAll('.closed-operation').data(data)

    // operations
    //   // .transition()
    //   // .duration(transition)
    //   // .ease(d3.easeExpOut)
    //   .attr('x', (d) => xScale(d.startTime))
    //   .attr('y', (d) => yScale(d.maxPrice))
    //   .attr('fill', (d) => getColorBySignalType(d.sign).concat('70'))
    //   .attr('width', (d) => {
    //     return xScale(new Date(d.endTime.getTime() - d.startTime.getTime() + xScale.domain()[0].getTime()))
    //   })
    //   .attr('height', (d) => {
    //     const n = Math.abs(yScale(d.maxPrice) - yScale(d.minPrice))
    //     return Number.isNaN(n) ? 0 : n
    //   })

    // operations.exit().remove()

    operations
      // .enter()
      // .append('rect')
      .join('rect')
      .attr('class', 'closed-operation')
      .attr('x', (d) => xScale(d.startTime))
      .attr('y', (d) => yScale(d.maxPrice))
      .attr('width', (d) => {
        return xScale(new Date(d.endTime.getTime() - d.startTime.getTime() + xScale.domain()[0].getTime()))
      })
      .attr('height', (d) => {
        const n = Math.abs(yScale(d.maxPrice) - yScale(d.minPrice))
        // if (Number.isNaN(n)) {
        //   console.log('broken data', d)
        // }
        // return Number.isNaN(n) ? 0 : n
        return n
      })
      .attr('fill', (d) => getColorBySignalType(d.sign).concat('70'))
      .attr('stroke', (d) => getColorBySignalType(d.sign).concat('90'))
      .on('mousemove', function (event, d) {
        const div = getDiv()
        div.classed('active', true)
        div
          .html(generateTable(d))
          .style('left', () => {
            const width = Number((div.node() as HTMLElement).getBoundingClientRect().width)
            if (event.pageX + width > window.screen.width) {
              return `${event.pageX - width}px`
            }
            return event.pageX + 'px'
          })
          .style('top', () => {
            const height = Number((div.node() as HTMLElement).getBoundingClientRect().height)
            const offsetTop = 15

            return clamp(event.pageY - height, offsetTop, window.screen.height) + 'px'
          })
      })
      .on('mouseout', function () {
        const div = getDiv()
        div.classed('active', false)
      })
  }

  useEffect(() => {
    render(true)
  }, [data])

  useEffect(() => {
    render(false)
  }, [transform, yDomain])

  useEffect(() => {
    return () => {
      getDiv().remove()
    }
  }, [])

  return <g ref={element} />
}

export default OperationsChart
