import React, { memo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import DashboardSelector from 'v2/components/DashboardSelector'
import { selectLayouts } from 'store/layouts/selectors'
import { Versions } from 'types/enums'
import { filterbySessionType, filterByVersion } from 'services/dashboardService'
import * as Store from 'types/store'
import { clearLayout, loadLayout } from 'store/dashboard/actions'
import { selectOperativeSessionSession } from 'store/pages/selectors'

const DashboardSelectorContainer: React.FC = () => {
  const dispatch = useDispatch()
  const { data: layouts } = useSelector(selectLayouts)
  const session = useSelector(selectOperativeSessionSession)

  if (!session) {
    return null
  }

  const onApplyLayout = (layout: Store.Layout) => {
    if (!layout) {
      dispatch(clearLayout())
      return
    }
    dispatch(loadLayout(layout))
  }

  //Ensure display only layout for current version
  const filteredLayoutsbyVersion = filterByVersion(layouts, Versions.V2)
  //Ensure display only layout for current session type
  const filteredLayoutsbySessionType = filterbySessionType(filteredLayoutsbyVersion, session.type)

  return <DashboardSelector layouts={filteredLayoutsbySessionType} onSelect={onApplyLayout} />
}

DashboardSelectorContainer.displayName = 'DashboardSelectorContainer'

export default memo(DashboardSelectorContainer)
