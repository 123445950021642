import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'
import Debug from 'debug'
import * as api from 'api/liveSession'
import { mapUint8ArrayMessage } from 'services/store/mapMetrics'
import { Button, Modal, Typography } from 'antd'
import AppContentCard from 'components/AppContentCard'
import moment from 'moment'

const debug = new Debug('FrontEnd')

interface Props {
  session: Store.Session
}

const BrokenEntityReporter: React.FC<Props> = ({ session }) => {
  const { t } = useTranslation()
  const [entityHasBroken, setHasEntityBroken] = useState(false)

  const now = useMemo(() => moment(), [])

  const onMessage = async (message: MessageEvent<Blob>) => {
    const bytes = new Uint8Array(await message.data.arrayBuffer())
    const protoMessage = mapUint8ArrayMessage(bytes)
    if (protoMessage.metric) {
      const { metric } = protoMessage
      if (metric) {
        const date = moment(metric.timestamp)

        if (now.isBefore(date)) {
          setHasEntityBroken(true)
        }
      }
      debug('Session new broken entity message', protoMessage)
    }
  }

  const canGetBrokenEntities = !session.isHistorical

  useEffect(() => {
    if (canGetBrokenEntities && !entityHasBroken) {
      const unsubscribe = api.subscribeToGetBrokenAssets(session.id, onMessage)
      return () => unsubscribe && unsubscribe()
    }
  }, [entityHasBroken])

  return (
    <Modal centered open={entityHasBroken} title={null} closable={false} footer={null}>
      <AppContentCard>
        <Typography.Title level={2} className="mb-16">
          {t('error.broken.modalTitle')}
        </Typography.Title>

        <Typography.Paragraph>{t('error.broken.modalText')}</Typography.Paragraph>

        <Button className="mt-30" type="primary" block onClick={() => window.location.reload()}>
          {t('buttons.reload')}
        </Button>
      </AppContentCard>
    </Modal>
  )
}

export default BrokenEntityReporter
