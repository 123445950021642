export type FrameSizeMapItem = {
  wMin: number
  wMax: number
  hMin: number
  hMax: number
}

export type FrameSizeMap = {
  [name: string]: FrameSizeMapItem
}

export const frameSizeMap: FrameSizeMap = {
  small: {
    wMin: 0,
    wMax: 9999,
    hMin: 0,
    hMax: 11,
  },
  'small-2': {
    wMin: 0,
    wMax: 9999,
    hMin: 8,
    hMax: 11,
  },
  medium: {
    wMin: 16,
    wMax: 9999,
    hMin: 0,
    hMax: 9999,
  },
  vertical: {
    wMin: 0,
    wMax: 9999,
    hMin: 12,
    hMax: 9999,
  },
  'vertical-2': {
    wMin: 12,
    wMax: 25,
    hMin: 16,
    hMax: 9999,
  },
  'with-candlestick-y': {
    wMin: 0,
    wMax: 9999,
    hMin: 16,
    hMax: 9999,
  },
  'with-candlestick-x': {
    wMin: 12,
    wMax: 9999,
    hMin: 0,
    hMax: 9999,
  },
  large: {
    wMin: 26,
    wMax: 9999,
    hMin: 16,
    hMax: 9999,
  },
  'large-2': {
    wMin: 0,
    wMax: 9999,
    hMin: 22,
    hMax: 9999,
  },
}
