import * as K from './constants'
import * as Store from 'types/store'
import { SessionType } from 'types/enums'
import * as UI from 'types/ui'

export function loadLayout(layout: Store.Layout) {
  return {
    type: K.LOAD_LAYOUT,
    payload: layout,
  }
}

export function clearLayout() {
  return {
    type: K.CLEAR_LAYOUT,
    payload: null,
  }
}

export function setDirty(isDirty: boolean) {
  return {
    type: K.SET_DIRTY,
    payload: isDirty,
  }
}

export function setDashboardHeight(height: number) {
  return {
    type: K.SET_DASHBOARD_HEIGHT,
    payload: height,
  }
}

export function createFrame(frame: Store.Frame) {
  return {
    type: K.CREATE_FRAME,
    payload: frame,
  }
}

export function updateFrameOptions(data: Store.FrameOptionsChange) {
  return {
    type: K.UPDATE_FRAME_OPTIONS,
    payload: data,
  }
}

export function removeFrame(frame: Store.Frame) {
  return {
    type: K.REMOVE_FRAME,
    payload: frame,
  }
}

export function removeFramesBySessionType(payload: SessionType) {
  return {
    type: K.REMOVE_FRAMES_BY_SESSION_TYPE,
    payload: payload,
  }
}

export function setDragging(draggingOn: boolean) {
  return {
    type: K.SET_DRAGGING,
    payload: draggingOn,
  }
}

export function setResizing(frameId: string) {
  return {
    type: K.SET_RESIZING,
    payload: frameId,
  }
}

export function setEntityFilter(filterOn: boolean) {
  return {
    type: K.SET_FILTER_ENTITIES,
    payload: filterOn,
  }
}

export function loadResolutions(reset: boolean) {
  return {
    type: K.LOAD_RESOLUTIONS,
    payload: reset,
  }
}
export function saveResolutions(resolutions: UI.Resolution[]) {
  return {
    type: K.SAVE_RESOLUTIONS,
    payload: resolutions,
  }
}
export function setGuidelineActive(active: boolean) {
  return {
    type: K.SET_GUIDELINE_ACTIVE,
    payload: active,
  }
}

export function layoutChanged(frames: Store.Frame[]) {
  return {
    type: K.LAYOUT_CHANGED,
    payload: frames,
  }
}
