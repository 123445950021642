/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux'
// Data
import strategies from 'store/resources/strategies/reducer'
import portfolios from 'store/resources/portfolios/reducer'
import assets from 'store/resources/assets/reducer'
import groups from 'store/resources/groups/reducer'
import sessions from 'store/sessions/reducer'
import layouts from 'store/layouts/reducer'
import user from 'store/user/reducer'

// Dashboard
import dashboard from 'store/dashboard/reducer'
// Pages
import setupSession from 'store/pages/setup-session/reducer'
import strategyPage from 'store/pages/strategy/reducer'
import operativeSession from 'store/pages/operative-session/reducer'
// App
import app from 'store/App/reducer'
import enabledSessionTypes from 'store/platform/reducers'
//Admin
import admin from 'store/pages/admin/reducer'

// Data
import { STATE_KEY as STRATEGY_KEY } from 'store/resources/strategies/constants'
import { STATE_KEY as PORTFOLIO_KEY } from 'store/resources/portfolios/constants'
import { STATE_KEY as ASSET_KEY } from 'store/resources/assets/constants'
import { STATE_KEY as GROUP_KEY } from 'store/resources/groups/constants'
import { STATE_KEY as SESSION_KEY } from 'store/sessions/constants'
import { STATE_KEY as LAYOUT_KEY } from 'store/layouts/constants'

// Dashboard
import { STATE_KEY as DASHBOARD_SESSION_KEY } from 'store/dashboard/constants'
// Pages
import { STATE_KEY as SETUP_SESSION_KEY } from 'store/pages/setup-session/constants'
import { STATE_KEY as STRATEGY_PAGE_KEY } from 'store/pages/strategy/constants'
import { STATE_KEY as OPERATIVE_SESSION_KEY } from 'store/pages/operative-session/constants'
import { STATE_KEY as ADMIN_PAGE_KEY } from 'store/pages/admin/constants'

import { STATE_KEY as USER_KEY } from 'store/user/constants'
// App
import { STATE_KEY as APP_KEY } from 'store/App/constants'
import { STATE_KEY as PLATFORM_KEY } from 'store/platform/constants'

const RootReducer = combineReducers({
  resources: combineReducers({
    [STRATEGY_KEY]: strategies,
    [PORTFOLIO_KEY]: portfolios,
    [ASSET_KEY]: assets,
    [GROUP_KEY]: groups,
  }),
  pages: combineReducers({
    [SETUP_SESSION_KEY]: setupSession,
    [STRATEGY_PAGE_KEY]: strategyPage,
    [OPERATIVE_SESSION_KEY]: operativeSession,
    [ADMIN_PAGE_KEY]: admin,
  }),
  [APP_KEY]: app,
  [PLATFORM_KEY]: enabledSessionTypes,
  [DASHBOARD_SESSION_KEY]: dashboard,
  [LAYOUT_KEY]: layouts,
  [SESSION_KEY]: sessions,
  [USER_KEY]: user,
})

export default RootReducer
