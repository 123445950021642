import React from 'react'
import { useDispatch } from 'react-redux'
import { removeFrame } from 'store/dashboard/actions'
import { GridItemActions } from 'types/ui'
import ForwardAnalysis from 'v2/components/frames/ForwardAnalysis'
import * as Store from 'types/store'

interface Props {
  frame: Store.Frame
}

const ForwardAnalysisContainer: React.FC<Props> = ({ frame }) => {
  const dispatch = useDispatch()

  const dataSource = [
    {
      key: '1',
      name: 'XXX',
    },
    {
      key: '2',
      name: 'XXX',
    },
    {
      key: '3',
      name: 'XXX',
    },
    {
      key: '4',
      name: 'XXX',
    },
    {
      key: '5',
      name: 'XXX',
    },
    {
      key: '6',
      name: 'XXX',
    },
    {
      key: '7',
      name: 'XXX',
    },
  ]

  const onContextMenuItemClick = (action: GridItemActions) => {
    switch (action) {
      case GridItemActions.Close:
        dispatch(removeFrame(frame))
        break
    }
  }

  return <ForwardAnalysis data={dataSource} onContextMenuItemClick={onContextMenuItemClick} />
}

export default ForwardAnalysisContainer
