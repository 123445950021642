import * as K from './constants'
import { put, call, takeLatest, all, select } from 'redux-saga/effects'
import Debug from 'debug'
import * as api from 'api/login'
import { ErrorCode, LoadingState } from 'types/enums'
import { ApplicationError, isApiError, isGraphQlError } from 'core'
import * as Api from 'types/api'
import * as localStore from 'core/localStore'
import { AnyAction } from 'redux'
import * as Store from 'types/store'
import { jsonUserToSystemUser } from 'services/store/mapService'

const debug = Debug('Frontend')

export const selectUser = (state) => state.root.user.user

export function* login(action: AnyAction) {
  debug('Saga login')
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Loading })
    const postData = action.payload as Store.Login
    const response = yield call(api.login, postData)

    if (isApiError(response)) {
      yield put({ type: K.SET_ERROR, payload: (response?.data as Api.RESTResponse)?.result?.responseEnum })
      throw new ApplicationError(ErrorCode.Api, 'login failed')
    }

    const data = response as Api.AuthResponse

    yield localStore.saveUser(data.content)
    yield put({ type: K.SET_STATUS, payload: LoadingState.Loaded })
  } catch (error) {
    yield localStore.clearUser()
    yield put({ type: K.SET_STATUS, payload: LoadingState.LoadFailure })
    debug('login Error', error)
  }
}

function* fetchUser() {
  debug('Saga fetchUser')
  try {
    const token = yield localStore.getAuthToken()
    if (!token) {
      //if not token, don't do request (it should not happen)
      yield put({ type: K.SET_STATUS, payload: LoadingState.LoadFailure })
      return
    }

    yield put({ type: K.SET_STATUS, payload: LoadingState.Loading })
    const response = yield call(api.getAuthenticatedUserDetails)
    if (isApiError(response)) {
      yield put({ type: K.SET_ERROR, payload: (response?.data as Api.RESTResponse)?.result?.responseEnum })
      throw new ApplicationError(ErrorCode.Api, 'fetchUser failed')
    }

    const user = jsonUserToSystemUser(response.content as Api.User)

    yield put({ type: K.FETCH_USER_SUCCESS, payload: user })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Loaded })
  } catch (error) {
    yield localStore.clearUser()
    yield put({ type: K.SET_STATUS, payload: LoadingState.LoadFailure })
    debug('fetchUser Error', error)
  }
}

export function* changePassword(action: AnyAction) {
  debug('Saga changePassword')
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Updating })

    const user: Store.SystemUser = yield select(selectUser)
    const data = action.payload as Json
    const response = yield call(api.changePassword, user.id, data)
    if (isApiError(response) || isGraphQlError(response)) {
      yield put({ type: K.SET_ERROR, payload: (response?.data as Api.RESTResponse)?.result?.responseEnum })
      throw new ApplicationError(ErrorCode.Api, 'changePassword failed')
    }
    yield put({ type: K.SET_STATUS, payload: LoadingState.Updated })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.UpdateFailure })
    debug('changePassword Error', error)
  }
}

export function* logout() {
  debug('Saga logout')
  yield localStore.clearUser()
}

function* watch() {
  yield all([
    takeLatest(K.LOGIN, login),
    takeLatest(K.FETCH_USER, fetchUser),
    takeLatest(K.CHANGE_PASSWORD, changePassword),
    takeLatest(K.LOGOUT, logout),
  ])
}

export default watch
