// @ts-ignore
import { update_snapshot, update_ticker } from 'sockets/portfolios/portfolio_analysis_indicators_pb'
import * as Store from 'types/store'

export const mapPortfolioAnalysisIndicatorsSigmas = (
  feed: update_snapshot.AsObject | update_ticker.AsObject,
): Store.PortfolioSigmas => {
  const { sigmas } = feed
  if (!sigmas) {
    return null
  }

  return {
    // TBD
  } as Store.PortfolioSigmas
}
