import React, { useCallback, useState } from 'react'
import { selectOperativeSessionSession, selectOperativeSessionAssets } from 'store/pages/selectors'
import { selectDashboardEntityFilter } from 'store/dashboard/selectors'
import { useDispatch, useSelector } from 'react-redux'
import * as Store from 'types/store'
import { AssetFeed } from 'types/enums.api'
import { Dimension, SessionStatus } from 'types/enums'
import { isFilteredLayout } from 'services/dashboardService'
import { selectAssets } from 'store/resources/selectors'
import PositionMonitoring from 'v2/components/frames/PositionMonitoring'
import { GridItemActions, ViewType } from 'types/ui'
import { removeFrame, updateFrameOptions } from 'store/dashboard/actions'
import { useAssetSigmaSnapshotState } from 'hooks'
import SelectAssetsModal from 'v2/components/SelectAssetsModal'
import { AnalysisFrameContext, AnalysisFrameContextInterface } from 'contexts/AnalysisFrameContext'
import sigmaServices from 'config/sigmaServices'
import { defaultsDeep } from 'lodash'
import { BUFFERS } from 'core'

const {
  Assets: { PositionAnalisys, SecurityData },
} = sigmaServices

interface Props {
  frame: Store.Frame
}

const PositionMonitoringContainer: React.FC<Props> = ({ frame }) => {
  const allAssets = useSelector(selectAssets)
  const session = useSelector(selectOperativeSessionSession)
  const filtered = isFilteredLayout(useSelector(selectDashboardEntityFilter), session.type)
  const operativeSessionAsset = useSelector(selectOperativeSessionAssets)
  const { options } = frame
  const { viewType } = options
  const [isFullscreen, setFullscreen] = useState(false)
  const [firstDimension, setFirstDimention] = useState<Dimension>(Dimension.Market)
  const [secondDimension, setSecondDimention] = useState<Dimension>(null)
  const [isModalVisible, setModalVisible] = useState(false)
  const assets = filtered ? operativeSessionAsset : allAssets

  const [selectedAssets, setSelectedAssets] = useState<Store.Asset[]>(assets)

  const [data1, isOffline1] = useAssetSigmaSnapshotState(
    selectedAssets,
    session.id,
    [PositionAnalisys.PositionAnalysis],
    AssetFeed.PositionAnalysis,
    BUFFERS.Large,
  )

  const [data2, isOffline2] = useAssetSigmaSnapshotState(
    selectedAssets,
    session.id,
    [SecurityData.AssetRefData],
    AssetFeed.SecurityData,
    BUFFERS.Large,
  )

  const data: Json<Store.AssetSigmas> = defaultsDeep({}, data1, data2)

  const isOffline = isOffline1 || isOffline2

  const assetIds = selectedAssets.map((asset) => asset.id)
  const dataArray = Object.entries(data).reduce((array, [key, value]) => {
    if (assetIds.includes(parseInt(key))) {
      array.push(value)
    }
    return array
  }, [])

  const positionMonitoringData = dataArray.map((item) => item.positionAnalysis).filter((data) => data)

  const refData = dataArray.map((item) => item.assetRefData).filter((data) => data)

  const dispatch = useDispatch()

  const onDimensionClick = (dimension: number, value: Dimension): void => {
    switch (dimension) {
      case 1:
        setFirstDimention(value)
        setSecondDimention(null)
        break
      case 2:
        setSecondDimention(value)
        break
      default:
        break
    }
  }

  const onContextMenuItemClick = (action: GridItemActions) => {
    switch (action) {
      case GridItemActions.Close:
        dispatch(removeFrame(frame))
        break
      case GridItemActions.Edit:
        setModalVisible(true)
        break
    }
  }

  const baseCurrency = refData[0]?.baseCurrency || ''

  const onUpdateOptions = useCallback(
    (options: Store.FrameOptions) => {
      dispatch(updateFrameOptions({ id: frame.id, options }))
    },
    [dispatch, frame],
  )

  const setViewType = (viewType: ViewType) => {
    onUpdateOptions({ ...frame.options, viewType })
  }

  const context: AnalysisFrameContextInterface = {
    id: frame.id,
    squares: {
      x: frame.layout.w,
      y: frame.layout.h,
    },
    isFullscreen,
    timeFrame: null,
    viewType,
    isOffline: isOffline && session.status === SessionStatus.Running,
    firstDimension,
    secondDimension,
    selectedAssets,
    setTimeFrame: null,
    setViewType,
    setFullscreen,
    setFirstDimention,
    setSecondDimention,
    setSelectedAssets,
  }

  return (
    <>
      {isModalVisible && (
        <SelectAssetsModal
          onClose={() => setModalVisible(false)}
          onSelect={(assets) => setSelectedAssets(assets)}
          selectedAssets={selectedAssets}
        />
      )}
      <AnalysisFrameContext.Provider value={context}>
        <PositionMonitoring
          data={positionMonitoringData}
          onDimensionClick={onDimensionClick}
          baseCurrency={baseCurrency}
          onContextMenuItemClick={onContextMenuItemClick}
        />
      </AnalysisFrameContext.Provider>
    </>
  )
}

export default PositionMonitoringContainer
