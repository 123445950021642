import React from 'react'
import { ExtendedDataNode, NodeType } from 'types/ui'
import PortfolioItem from './PortfolioItem'
import { CrudOperation } from 'types/enums'
import * as Store from 'types/store'
import { isActiveElement } from 'services/treeService'

interface Props {
  nodes: ExtendedDataNode[]
  itemClicked: (item: ExtendedDataNode, operation: CrudOperation, type: NodeType) => void
  userAction: Store.UserAction
}

const StrategyPanel: React.FC<Props> = ({ nodes, itemClicked, userAction }) => {
  if (!nodes) {
    return null
  }

  return (
    <ul>
      {nodes &&
        nodes.map((item) => (
          <PortfolioItem
            isActive={isActiveElement(item, userAction)}
            key={item.key}
            onClick={itemClicked}
            node={item}
            isDeletable={item.isDeletable}
          />
        ))}
    </ul>
  )
}

export default StrategyPanel
