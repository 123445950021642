import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import { ChartChild } from 'types/chart'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { renderChildren } from './common'
import { useInterval } from 'react-use'

// const FPS = 1000 / 30
// JI-NOTE: performance
// From 33,3 to 1 FPS bcs charts does not need to be updated like a video
const FPS = 1

interface HintStatus {
  counter: number
  timeout: any
}

interface Props {
  width: number
  height: number
  defaultScale?: number
  initialZoomDirection?: 'left' | 'right'
  margin?: {
    top?: number
    right?: number
    bottom?: number
    left?: number
  }
  clipId?: string
  yDomain?: number[]
  xDomain?: number[] | Date[]
  yPosition?: number
  isZoomEnabled?: boolean
  onZoomEnded?: (transform: d3.ZoomTransform) => void
  transitionTime?: number
}

const transformState = (defaultTransform, onZoomEnd) => {
  const [transform, setTransform] = useState(defaultTransform)
  const transformRef = useRef()

  useInterval(() => {
    if (transformRef.current) {
      setTransform(transformRef.current)
      onZoomEnd && onZoomEnd(transformRef.current)
      transformRef.current = null
    }
  }, FPS)

  return {
    transform,
    setTransform: (transform) => {
      transformRef.current = transform
    },
  }
}

const ChartD3: React.FC<Props> = ({
  width,
  height,
  defaultScale = 1,
  initialZoomDirection = 'right',
  margin = {},
  children,
  clipId,
  xDomain,
  yDomain,
  yPosition = 0,
  isZoomEnabled,
  onZoomEnded,
  transitionTime = 0,
}) => {
  const { t } = useTranslation()

  const { bottom = 0, left = 0, right = 0, top = 0 } = margin
  const defaultViewBox = `0, 0, ${width}, ${height}`
  const [isHintEnabled, setHintEnabled] = useState(false)
  const [hintStatus, setHintStatus] = useState<HintStatus>({
    counter: 0,
    timeout: null,
  })

  const { chartWidth, chartHeight } = {
    chartHeight: height - top - bottom,
    chartWidth: width - left - right,
  }

  const extent = [
    [0, 0],
    [chartWidth, chartHeight],
  ] as any

  const xView = initialZoomDirection === 'right' ? -chartWidth + chartWidth / defaultScale : 0

  const defaultTransform = d3.zoomIdentity
    .scale(defaultScale)
    .translate(xView, -(chartHeight - chartHeight / defaultScale / 2) * yPosition)

  const { transform, setTransform } = transformState(defaultTransform, onZoomEnded)
  const container = useRef(null)

  const zoomed = (event) => {
    const { transform } = event
    setTransform(transform)
  }
  const zoomend = () => {
    //Do nothing
  }

  const enableHint = (enabled: boolean) => {
    if (enabled && hintStatus.counter > 3) {
      //Avoid to display hint after a few times
      return
    }
    setHintEnabled(enabled)
  }

  useEffect(() => {
    if (!isHintEnabled) {
      clearTimeout(hintStatus.timeout)
      hintStatus.timeout = null
    } else {
      const timeout = setTimeout(() => enableHint(false), 2000)
      hintStatus.counter++
      hintStatus.timeout = timeout
    }

    setHintStatus(hintStatus)

    return () => {
      clearTimeout(hintStatus.timeout)
    }
  }, [isHintEnabled])

  useEffect(() => {
    if (isZoomEnabled) {
      const zoom = d3.zoom().scaleExtent([1, 100]).translateExtent(extent).extent(extent)

      //Allow to zoom with wheel only when shiftKey is pressed
      //https://github.com/d3/d3-zoom#zoom_filter
      zoom.filter((event) => {
        if (event.type === 'wheel') {
          if (event.shiftKey) {
            enableHint(false)
            return true
          }

          enableHint(true)
          //Filter away the event
          return false
        }

        enableHint(false)
        return true
      })

      zoom.on('zoom', zoomed)
      zoom.on('end', zoomend)
      const svg = d3.select(container.current)
      svg.call(zoom).call(zoom.transform, defaultTransform)
    }
  }, [])

  const props: ChartChild = {
    chartHeight,
    chartWidth,
    d3Transform: transform,
    clipId,
    yDomain,
    xDomain,
    transitionTime,
  }

  return (
    <>
      {isHintEnabled && <div className={styles['chart-hint']}>{t('chart.zoomHint')}</div>}
      <svg viewBox={defaultViewBox} ref={container} fill="none" className="cartesian-chart">
        <g transform={`translate(${left},${top})`} style={{height: height, width: width}}>{renderChildren(children, props)}</g>
      </svg>
    </>
  )
}

export default ChartD3
