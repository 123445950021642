export const STATE_KEY = 'assets'

export const ASSETS_FETCH = `${STATE_KEY}/ASSETS_FETCH`
export const ASSETS_FETCH_SUCCESS = `${STATE_KEY}/ASSETS_FETCH_SUCCESS`

export const ASSET_CREATE = `${STATE_KEY}/ASSET_CREATE`
export const ASSET_CREATE_SUCCESS = `${STATE_KEY}/ASSET_CREATE_SUCCESS`

export const ASSET_UPDATE = `${STATE_KEY}/ASSET_UPDATE`
export const ASSET_UPDATE_SUCCESS = `${STATE_KEY}/ASSET_UPDATE_SUCCESS`

export const ASSET_DELETE = `${STATE_KEY}/ASSET_REMOVE`
export const ASSET_DELETE_SUCCESS = `${STATE_KEY}/ASSET_REMOVE_SUCCESS`

export const HISTORICAL_ASSETS_FETCH = `${STATE_KEY}/HISTORICAL_ASSETS_FETCH`
export const HISTORICAL_ASSETS_FETCH_SUCCESS = `${STATE_KEY}/HISTORICAL_ASSETS_FETCH_SUCCESS`

export const SET_STATUS = `${STATE_KEY}/SET_STATUS`
export const SET_ERROR = `${STATE_KEY}/SET_ERROR`
