import React, { useState } from 'react'
import { Col, Typography, Transfer } from 'antd'
import { getColumns } from './columns'
import { ColumnKeys, TableType } from 'types/store'

const { Title } = Typography

interface Props {
  tableType: TableType
  tableName: string
  titles: React.ReactNode[]
  onSetSelectedKeys: (selectedKeys: string[], tableType: TableType) => void
  columnKeys: ColumnKeys[]
}

const ColumnTransferTable: React.FC<Props> = ({
  tableType,
  tableName,
  titles,
  onSetSelectedKeys,
  columnKeys,
}) => {
  const defaultTargets = columnKeys.find((item) => item.table === tableType)
  const [targetKeys, setTargetKeys] = useState<string[]>(defaultTargets?.keys || [])

  const onColsChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys)
    onSetSelectedKeys(nextTargetKeys, tableType)
  }

  const dataSource = getColumns(tableType)
  return (
    <Col span={24}>
      <Title level={2} className="primary-color mt-30 mb-16">
        {tableName}
      </Title>

      <Transfer
        dataSource={dataSource}
        titles={titles}
        targetKeys={targetKeys}
        onChange={onColsChange}
        render={(item) => item.title}
      />
    </Col>
  )
}

export default ColumnTransferTable
