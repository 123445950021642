import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { LoadingState, SessionStatus, TimeUnit } from 'types/enums'
import DashboardPlayer from 'v2/components/dashboard-players/VirtualPlayer/VirtualPlayer'
import * as Forms from 'types/form'
import * as Api from 'types/api'
import * as Store from 'types/store'
import * as api from 'api/sessions'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession, selectOperativeSessionState } from 'store/pages/selectors'
import moment from 'moment'
import {
  launchVirtualSession,
  loadFinishedById,
  stopVirtualSession,
} from 'store/pages/operative-session/actions'
import { useInterval } from 'react-use'
import { isApiError, isGraphQlError } from 'core'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const checkSessionHasEnded = async (id: number): Promise<boolean> => {
  const response = await api.getSessionEndTime(id)

  if (isApiError(response) || isGraphQlError(response)) {
    return false
  }

  const session = (response as Api.SessionsEndTimeResponse).data.application_operative_session[0]

  return session?.end_time ? true : false
}

const DELAY = 30000
const DEFAULT_MARKET_OPEN_TIME = '08:30:00'
const DEFAULT_MARKET_CLOSE_TIME = '17:30:00'
const TIME_FORMAT = 'HH:mm:ss'
const DEFAULT_MAX_VAR_TRADE_PRICE = 0.00005
const DEFAULT_MAX_SIZE = 10000
const DEFAULT_MIN_SIZE = 10
const DEFAULT_TRADES_CONFIRMATION_PERCENTAGE = 0.8

const VirtualPlayerContainer: React.FC = () => {
  const { t } = useTranslation()
  const [showParams, setShowParams] = useState(true)
  const session = useSelector(selectOperativeSessionSession)
  const loadingState = useSelector(selectOperativeSessionState)

  const schema = yup.object().shape({
    marketOpen: yup.date().required(t('error.requiredField')),
    marketClose: yup.date().min(yup.ref('marketOpen'), t('sessionParameters.errors.maxClose')),
    maxVarTradePrice: yup.number().required(t('error.requiredField')),
    minSize: yup.number().required(t('error.requiredField')),
    maxSize: yup
      .number()
      .min(yup.ref('minSize'), t('sessionParameters.errors.minSize'))
      .required(t('error.requiredField')),
    rate: yup.number().required(t('error.requiredField')),
    rateUnit: yup.string().required(t('error.requiredField')),
    tradesConfirmationPercentage: yup
      .number()
      .min(0, t('sessionParameters.errors.tradesConfirmationPercentage'))
      .max(1, t('sessionParameters.errors.tradesConfirmationPercentage'))
      .required(t('error.requiredField')),
  })

  const {
    marketClose,
    marketOpen,
    maxSize,
    maxVarTradePrice,
    minSize,
    rate,
    rateUnit,
    sessionEndTimer,
    tradesConfirmationPercentage,
  } = session.periodParams as Store.VirtualParams

  const dispatch = useDispatch()

  const defaultValues = {
    marketOpen: moment(marketOpen || DEFAULT_MARKET_OPEN_TIME, TIME_FORMAT).toDate(),
    marketClose: moment(marketClose || DEFAULT_MARKET_CLOSE_TIME, TIME_FORMAT).toDate(),
    rate: rate || 1,
    rateUnit: rateUnit || TimeUnit.Seconds,
    maxVarTradePrice: maxVarTradePrice || DEFAULT_MAX_VAR_TRADE_PRICE,
    maxSize: maxSize || DEFAULT_MAX_SIZE,
    minSize: minSize || DEFAULT_MIN_SIZE,
    tradesConfirmationPercentage: tradesConfirmationPercentage || DEFAULT_TRADES_CONFIRMATION_PERCENTAGE,
  }

  const { control, clearErrors, handleSubmit, reset } = useForm<Forms.VirtualSessionPlayer>({
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(schema),
  })

  const toggleParamsVisibility = () => setShowParams((state) => !state)

  const onValid = (data: Forms.VirtualSessionPlayer) => {
    const {
      marketClose,
      marketOpen,
      maxSize,
      maxVarTradePrice,
      minSize,
      rate,
      rateUnit,
      sessionEndTimer,
      sessionEndTimerUnit,
      tradesConfirmationPercentage,
    } = data
    const rateValue = `${rate}${rateUnit}`
    const timeEnding = sessionEndTimer && sessionEndTimerUnit && `${sessionEndTimer}${sessionEndTimerUnit}`
    const dateFormat = 'hh:mm:ss A'
    const postData: Api.VirtualSessionLaunch = {
      maxSize,
      minSize,
      rate: rateValue,
      marketOpenTime: moment(marketOpen).format(dateFormat),
      marketCloseTime: moment(marketClose).format(dateFormat),
      maxVarPercTradePrice: maxVarTradePrice,
      percTradesConfirm: tradesConfirmationPercentage,
      timeEnding: timeEnding,
    }

    dispatch(launchVirtualSession(postData))
  }

  const onClick = (status: SessionStatus) => {
    clearErrors()

    switch (status) {
      case SessionStatus.Running: {
        handleSubmit(onValid)()
        break
      }
      case SessionStatus.Stopped:
        dispatch(stopVirtualSession())
        break
    }
  }

  const checkIfSessionHasEnded = async () => {
    const isEnded = await checkSessionHasEnded(session.id)

    if (isEnded) {
      dispatch(loadFinishedById(session.id))
    }
  }

  useInterval(
    () => {
      checkIfSessionHasEnded()
    },
    session.status === SessionStatus.Running ? DELAY : null,
  )

  return (
    <DashboardPlayer
      control={control}
      onClick={onClick}
      showParams={showParams}
      toggleParamsVisibility={toggleParamsVisibility}
      loading={loadingState === LoadingState.Updating}
      sessionEndTimer={sessionEndTimer}
      reset={() => reset()}
    />
  )
}

export default VirtualPlayerContainer
