import { Form, FormItemProps } from 'antd'
import React from 'react'

//This component wrap AntD Form.Item to be easily used with React Hook Forms
//https://github.com/liuxinl/antd-react-hook-form-yup/blob/master/src/pages/index.tsx

interface Props {
  name?: string
  label?: string
  error: any
  children: any
}

const FormField: React.FC<Props & FormItemProps> = ({ name, label, error, children, ...restProps }) => {
  return (
    <Form.Item
      name={name}
      label={label}
      validateStatus={error ? 'error' : ''}
      help={error ? error.message : ''}
      hasFeedback
      {...restProps}
    >
      {children}
    </Form.Item>
  )
}

export default FormField
