export const STATE_KEY = 'admin'

export const FETCH_USERS = `${STATE_KEY}/FETCH_USERS`
export const FETCH_USERS_SUCCESS = `${STATE_KEY}/FETCH_USERS_SUCCESS`
export const EDIT_USER = `${STATE_KEY}/EDIT_USER`
export const EDIT_USER_SUCCESS = `${STATE_KEY}/EDIT_USER_SUCCESS`
export const NEW_USER = `${STATE_KEY}/NEW_USER`
export const NEW_USER_SUCCESS = `${STATE_KEY}/NEW_USER_SUCCESS`
export const DELETE_USER = `${STATE_KEY}/DELETE_USER`
export const DELETE_USER_SUCCESS = `${STATE_KEY}/DELETE_USER_SUCCESS`
export const SET_LOADING_STATE = `${STATE_KEY}/SET_LOADING_STATE`
export const SET_ERROR = `${STATE_KEY}/SET_ERROR`
