import React, { useEffect, useState } from 'react'
import { Col, Divider, Form, FormInstance, Modal, Rate, Row, Select, SelectProps, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { CrudOperation } from 'types/enums'
import { BrokerAccount } from 'types/store'
const { Paragraph, Text } = Typography

interface Props {
  data: BrokerAccount[]
  mode: CrudOperation
  form: FormInstance
  setBrokers: (brokers: any) => void
  setParams: (params: { symbol: string, mic: string, currency: string }) => void
}

const BrokerAccountField: React.FC<Props> = ({ data, setBrokers, setParams, mode, form }) => {
  const { t } = useTranslation()

  const readonly = mode === CrudOperation.Read

  const [options, setOptions] = useState<SelectProps['options']>([])
  const [selectedBrokers, setSelectedBrokers] = useState<BrokerAccount[]>([])
  const [loading, setLoading] = useState(false)

  const mapBrokersToOptions = (brokers: BrokerAccount[]): SelectProps['options'] => {
    return brokers.map((broker, i) => {
      const displayName = `${broker.name} (${broker.account})`
      return {
        disabled: false,
        name: broker.name,
        title: displayName,
        account: broker.account,
        displayName: displayName,
        value: broker.account,
        label: displayName,
        priority: broker?.priority,
        key: `${broker.name}-${broker.account}-option-${i}`,
      }
    })
  }

  const handleSelectChange = (values, opts) => {
    if (opts.length === 0) {
      setSelectedBrokers([])
      // We need to empty the form brokers value from here
      form.setFieldsValue({ brokerAccounts: [] })
    }
    // Prevent selecting more than 5 brokers
    if (opts.length > 5) {
      Modal.warning({
        title: 'Warning',
        content: 'You can only select up to 5 brokers',
        centered: true,
        style: { backgroundColor: '#000000' },
      })
      return
    }

    const brokersWithPriority = opts.map((broker, i) => {
      const automaticPriorityOnCreation = i+1
      const priority = broker?.priority ? broker?.priority : automaticPriorityOnCreation
      return { ...broker, priority }
    })

    setSelectedBrokers(brokersWithPriority)
  }

  const handleRankChange = (newRank, currentBroker) => {
    // Disable click on existing rank
    if (newRank === 0 || newRank === currentBroker.priority) {
      return
    }
    setSelectedBrokers((prevBrokers) => {
      // Find the broker that currently has the new rank
      const brokerWithSameRank = prevBrokers.find((broker) => broker.priority === newRank)
  
      // Update the rank of the current broker
      // and switch the rank of the broker that already has the selected rank
      const updatedBrokers = prevBrokers.map((broker) => {
        if (broker.account === currentBroker.account) {
          return { ...broker, priority: newRank }
        }
        if (brokerWithSameRank && broker.account === brokerWithSameRank.account) {
          return { ...broker, priority: currentBroker.priority }
        }
        return broker
      })
  
      return updatedBrokers
    })
  }

  useEffect(() => {
    if (mode === CrudOperation.Read) {
      return
    }
    setLoading(true)
  },[])

  useEffect(() => {
    if (data.length > 0) {
      setLoading(false)
      const mappedOptions = mapBrokersToOptions(data)
      setOptions(mappedOptions)
      if (mode === CrudOperation.Update) {
        const formBrokers = form.getFieldValue('brokerAccounts')
        // Brokers data from the FORM have the priority values, but they're missing the names,
        // so we need to restore them with the data from the graphQL
        const selectedBrokers = data.filter((broker) => formBrokers.some((b) => b.name === broker.account))

        const selectedBrokersWithPriority = selectedBrokers.map((broker) => {
          const formBroker = formBrokers.find((b) => b.name === broker.account)
          return { ...broker, priority: formBroker.priority }
        })

        setSelectedBrokers(selectedBrokersWithPriority)

      }
    }
  }, [data])

  useEffect(() => {
    // Send the selected brokers to the parent component
    setBrokers(selectedBrokers)
  }, [selectedBrokers])

  return (
    <>
      <Row align="top" gutter={[5, 0]} style={{ marginBottom: 16 }}>
        <Col span={6}>
          <Paragraph>Broker Accounts</Paragraph>
          <Text className="field-description" type="secondary">Select up to 5 brokers</Text>
        </Col>
        <Col span={18}>
          <Form.Item
            noStyle
            name="brokerAccounts"
            // dependencies={['symbol', 'mic', 'currency']}
            shouldUpdate={(prevValues: any, currentValues) => {
              const hasChanged = ['symbol', 'mic', 'currency'].some((field) => prevValues[field] !== currentValues[field])

              if (hasChanged) {
                setParams({
                  symbol: currentValues['symbol'],
                  mic: currentValues['mic'],
                  currency: currentValues['currency'],
                })

                setSelectedBrokers([])
              }
              return hasChanged
            }}
            // rules={[
            //   ({ getFieldsValue, getFieldValue }) => {
            //     // console.log('getFieldValue', getFieldsValue(['symbol', 'mic', 'currency']))
            //     return {
            //       // validator(field, value) {
            //       //   console.log('field', field)
            //       //   console.log('value', value)
            //       // }
            //       required: !!getFieldValue('symbol'),
            //       message: 'Please select at least a broker',
            //     }
            //   }
            // ]}
          >
            {/* HIDDEN FIELD FOR BROKER ACCOUNTS */}
          </Form.Item>
          <Select
            mode="multiple"
            maxTagCount={5}
            loading={loading}
            optionFilterProp="displayName"
            disabled={!data.length || readonly}
            options={options}
            placeholder={loading && !data.length
              ? 'You must fill Symbol, Market and Currency to load brokers'
              : 'Select broker by name or account...'
            }
            value={selectedBrokers.map(broker => broker.account)}
            onChange={handleSelectChange}
            getPopupContainer={triggerNode => triggerNode.parentElement}
          />
        </Col>
      </Row>
      <Row align="top" gutter={[5, 0]}>
        <Col span={6}>
          <Paragraph>Associated brokers</Paragraph>
        </Col>
        <Col span={18}>
          {selectedBrokers.length > 0 && (
            <Row align="middle" justify="space-between">
              <Col span={8}>
                <span>Name</span>
              </Col>
              <Col span={9}>
                <span>Account</span>
              </Col>
              <Col span={7}>
                <span>Rank</span>
              </Col>
            </Row>
          )}
          {selectedBrokers.map((broker, i) => {
            return (
              <Row key={`${broker.name}-${broker.account}-row-${i}`} align="middle" justify="space-between">
                <Col span={8}>
                  <Paragraph ellipsis>{broker.name}</Paragraph>
                </Col>
                <Col span={9}>
                  <Paragraph ellipsis>{broker.account}</Paragraph>
                </Col>
                <Col span={7}>
                  <Rate
                    value={broker.priority}
                    onChange={(value) => handleRankChange(value, broker)}
                    style={{
                      color: '#6bbffb',
                      fontSize: 16,
                      textAlign: 'right',
                    }}
                    character="◉"
                    // character={({ index }: { index: number }) => index + 1}
                    count={selectedBrokers.length}
                  />
                </Col>
              </Row>
            )
          })}
        </Col>
      </Row>
      <Divider style={{ marginBottom: 32 }} />
    </>
  )
}

export default BrokerAccountField
