// @ts-ignore
import { decimal } from 'sockets/assets/asset_models_pb'

export const formatSigmaDate = (sigmaTime: Json): Date => {
  return new Date(sigmaTime?.time / Math.pow(10, 6))
}

export const convertToFloat = (value: decimal.AsObject) => {
  try {
    const output = parseFloat(`${value.coeff}e${value.exp}`)
    return output
  } catch {
    return null
  }
}
