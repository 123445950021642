import React, { useCallback, useEffect, useState } from 'react'
import * as Store from 'types/store'
import ChartD3 from 'components/ChartD3'
import LineChart from 'components/ChartD3/LineChart'
import CandleStickChart from 'components/ChartD3/CandleStickChart'
import Clipper from 'components/ChartD3/Clipper'
import ChartBackground from 'components/ChartD3/ChartBackground'
import colors from 'config/colors'
import XAxis from 'components/ChartD3/XAxisDate'
import YAxisRight from 'components/ChartD3/YAxisRight'
import ChartGrid from 'components/ChartD3/ChartGrid'
import debounce from 'debounce'
import * as d3 from 'd3'
import * as _ from 'lodash'

import styles from './styles.module.scss'
import { SessionType, TimeFrame } from 'types/enums'
import { withVisible } from 'components/hoc'
import { v4 as uuidv4 } from 'uuid'
import ChartGroup from 'components/ChartD3/ChartGroup'

import OperationsChart from 'components/ChartD3/OperationsChart'
import BarchartContainer from 'v2/containers/CandleStick/Asset/Barchart'
import HistoricalBarchartContainer from 'v2/containers/CandleStick/historical/Asset/Barchart'
import { useDebounce } from 'react-use'
import { Spin } from 'antd'

const TRANSITION_TIME = 800

const LOADING_TIME = 500

interface Props {
  candleStickData: Store.CandleStick[]
  lineChartData: Store.UnitPerformance[]
  operationsChartData: Store.Operation[]
  currency: string
  baseCurrency?: string
  loading: boolean
  getFromTo: (from: Date, to: Date) => void
  timeFrame: TimeFrame
  getXDomain: () => Date[]
  width: number
  height: number
  session: Store.Session
  asset?: Store.Asset
}

const CandleStick: React.FC<Props> = ({
  candleStickData,
  operationsChartData,
  lineChartData,
  timeFrame,
  currency,
  baseCurrency,
  getFromTo,
  getXDomain,
  width,
  height,
  session,
  asset,
  loading,
}) => {
  const [transform, setTransform] = useState<d3.ZoomTransform>(d3.zoomIdentity)
  const [key, setKey] = useState(uuidv4())
  const [isResizing, setIsResizing] = useState(false)

  const resolution = {
    width: width,
    height: height,
    margin: { bottom: 25, top: 0, right: 40, left: 0 },
    defaultScale:
      session.isHistorical || (session.type === SessionType.Virtual && timeFrame === TimeFrame.YTD) ? 1 : 5,
  }

  const getResolution = (transform: d3.ZoomTransform) =>
    transform.rescaleX(
      d3
        .scaleTime()
        .domain(getXDomain())
        .range([0, resolution.width - resolution.margin.left - resolution.margin.right]),
    )

  useDebounce(
    () => {
      setIsResizing(false)
    },
    LOADING_TIME,
    [key],
  )

  useEffect(() => {
    // Force svg to redraw after resize
    setKey(uuidv4())

    setIsResizing(true)
  }, [width, height])

  const scale = getResolution(transform)
  const [from, to] = scale.domain()

  const getLineChartDataNormalized = () => {
    if (session.type === SessionType.BackTest || timeFrame === TimeFrame.YTD) {
      return []
    }

    const firstCandle = candleStickData.find((item) => item.startTime >= from)
    const firstLine = lineChartData.find((item) => item.startTime >= from)

    if (!firstLine || !firstCandle) {
      return lineChartData
    }

    const difference = firstCandle.open - firstLine.open

    return lineChartData.map((item) => ({
      ...item,
      open: item.open + difference,
      close: item.close + difference,
    }))
  }

  const normalizedLineChartData = getLineChartDataNormalized()

  const getYDomain = () => {
    const filteredCandles = candleStickData.filter((item) => item.endTime >= from && item.startTime <= to)
    const filteredOperations = operationsChartData.filter(
      (item) => item.endTime >= from && item.startTime <= to,
    )
    const filteredLineChart = normalizedLineChartData.filter(
      (item) => item.endTime >= from && item.startTime <= to,
    )

    const maxCandleStickY = Math.max(...filteredCandles.map((d) => d.high))
    const maxLineChartData = Math.max(...filteredLineChart.map((item) => item.close))
    const maxOpenOperationsChartData = Math.max(...filteredOperations.map((item) => item.maxPrice))

    const minCandleStickY = Math.min(...filteredCandles.map((d) => d.low))
    const minLineChartData = Math.min(...filteredLineChart.map((item) => item.close))
    const minOpenOperationsChartData = Math.min(...filteredOperations.map((item) => item.minPrice))

    const max =
      _.max(
        [maxCandleStickY, maxLineChartData, maxOpenOperationsChartData].filter((item) => isFinite(item)),
      ) || 0

    const min =
      _.min(
        [minCandleStickY, minLineChartData, minOpenOperationsChartData].filter((item) => isFinite(item)),
      ) || 0

    const addition = (max - min) * 0.25
    return [min - addition, max + addition]
  }

  const requestForUpdate = useCallback(
    debounce((from: Date, to: Date) => {
      getFromTo(from, to)
    }, TRANSITION_TIME),
    [setTransform],
  )

  const onZoomEnded = (transform: d3.ZoomTransform) => {
    const scale = getResolution(transform)
    const [from, to] = scale.domain()
    setTransform(transform)
    requestForUpdate(from, to)
  }

  const yDomain = getYDomain()
  const xDomain = getXDomain()
  const xData = candleStickData && candleStickData.map((item) => item.startTime)
  const yData = candleStickData && candleStickData.map((item) => item.open)

  const renderedChart = React.useMemo(() => {
    const renderBarChart = (timeFrame === TimeFrame.YTD || timeFrame === TimeFrame.TOT) && asset

    const onlyCandleStick = (
      <ChartGroup height={renderBarChart ? 0.8 : 1} width={1} x={0} y={0} key="candlestick">
        <ChartBackground color={colors.midnight} />
        <ChartGrid xData={xData} yData={yData} />
        <CandleStickChart data={candleStickData || []} timeFrame={timeFrame} />
        <LineChart data={normalizedLineChartData || []} />
        <OperationsChart data={operationsChartData || []} currency={currency} baseCurrency={baseCurrency} />
        <Clipper />
        <YAxisRight data={yData || []} />
      </ChartGroup>
    )

    const withBarchart = [
      onlyCandleStick,
      session.isHistorical ? (
        <HistoricalBarchartContainer asset={asset} key="barchart" />
      ) : (
        <BarchartContainer asset={asset} key="barchart" />
      ),
    ]
    return renderBarChart ? withBarchart : onlyCandleStick
  }, [candleStickData])

  return (
    <section className={`${styles['candlestick']} JI-RENDER-v2-components-candlestick`} style={loading ? { display: 'none' } : {}}>
      {isResizing && !loading ? (
        <Spin
          style={{
            width: resolution.width,
            height: resolution.height,
          }}
        ></Spin>
      ) : (
        <ChartD3
          height={resolution.height}
          width={resolution.width}
          defaultScale={resolution.defaultScale}
          margin={resolution.margin}
          initialZoomDirection={timeFrame === TimeFrame.TOT ? 'left' : 'right'}
          clipId={key}
          yDomain={yDomain}
          xDomain={xDomain as any}
          isZoomEnabled
          onZoomEnded={onZoomEnded}
          transitionTime={TRANSITION_TIME}
          key={key}
        >
          {renderedChart}
          <XAxis data={xData || []} timeFrame={timeFrame} />
        </ChartD3>
      )}
    </section>
  )
}

export default withVisible(CandleStick)
