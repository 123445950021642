import ChartD3 from 'components/ChartD3'
import XAxis from 'components/ChartD3/XAxis'
import YAxisRight from 'components/ChartD3/YAxisRight'
import React from 'react'
import { useMeasure } from 'react-use'
import { HistogramType } from 'types/chartEnums'
import * as Store from 'types/store'
import { useTranslation } from 'react-i18next'
import ChartGrid from 'components/ChartD3/ChartGrid'
import Line from 'components/ChartD3/Line'
import ChartBackground from 'components/ChartD3/ChartBackground'
import colors from 'config/colors'
import BarChart from 'components/ChartD3/BarChart'
interface Props {
  data: Store.FrequenceNormalDistribution[]
  std: number
  mean: number
}

const StatisticsChart: React.FC<Props> = ({ data, mean, std }) => {
  const [ref, { width, height }] = useMeasure()
  const { t } = useTranslation()
  const resolution = {
    width,
    height,
    margin: { bottom: 40, top: 20, right: 40, left: 0 },
    defaultScale: 5,
  }

  if (!data) {
    return null
  }

  const barChartData: Store.Barchart[] = data.map((value) => {
    return {
      height: value.frequence,
      label: `${value.frequence * 100}%`,
      x0: value.bin.lowerLimit,
      x1: value.bin.upperLimit,
    }
  })

  const xTicksMaps = new Map<number, [number, number]>()

  barChartData.forEach(({ x0, x1 }) => {
    const key = (x0 + x1) / 2
    xTicksMaps.set(key, [x0, x1])
  })

  const xTickFormatter = (value: number) => {
    const [from, to] = xTicksMaps.get(value)
    return t('chart.fromTo', { from, to })
  }

  const xValues = barChartData.map((bar) => [bar.x0, bar.x1]).flat()
  const xAxisValues = Array.from(xTicksMaps.keys())
  const lineData: [number, number][] = data.map((value, i) => [xAxisValues[i], value.normalDistribution])
  const yAxisValues = barChartData.map((bar) => bar.height)

  const frequenceValues = data.map((value) => value.frequence)

  const values = [...yAxisValues, ...frequenceValues]
  const max = Math.max(...values)
  const maxY = max + max * 0.2
  const yDomain = [0, maxY]

  const xDomain = [Math.min(...xValues), Math.max(...xValues)]

  return (
    <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} ref={ref}>
      <ChartD3
        height={resolution.height}
        width={resolution.width}
        margin={resolution.margin}
        xDomain={xDomain}
        yDomain={yDomain}
      >
        <ChartBackground color={colors.midnight} />
        <ChartGrid yData={yAxisValues} xData={[]} />
        <BarChart data={barChartData} type={HistogramType.NormalDistribution} />
        <YAxisRight data={yAxisValues} />
        <XAxis data={xValues} ticks={xAxisValues} tickFormat={xTickFormatter} wrapTickValues />
        <Line data={lineData} curved color={colors.positiveGreen} thickness={1} />

        <Line
          data={[
            [mean - std, 0],
            [mean - std, maxY],
          ]}
          color={colors.red}
          thickness={1}
        />
        <Line
          data={[
            [mean, 0],
            [mean, maxY],
          ]}
          color={colors.red}
          thickness={1}
        />
        <Line
          data={[
            [mean + std, 0],
            [mean + std, maxY],
          ]}
          color={colors.red}
          thickness={1}
        />
      </ChartD3>
    </div>
  )
}

export default StatisticsChart
