import { useEffect, useRef, useState } from 'react'
import * as Store from 'types/store'
import Debug from 'debug'
import { subscribeToSnapshotsWithInterval } from 'api/socket'
import { AssetFeed, Sigma } from 'types/enums.api'
import { groupBy } from 'core/helpers'
import { BUFFERS, config } from 'core'
import { FlowControlSpeed } from 'types/enums'
import { useInterval } from 'react-use'
import { isOffline } from './common'
import { deserializeAssetBuffer } from 'helpers/protoHelpers'
import { removeUndefinedFields } from 'services/store/mapService'

const debug = Debug('sockets')

const { isAlive } = config.socketOptions

export const useAssetSigmaSnapshotState = (
  assets: Store.Asset[],
  sessionId: number,
  sigmas: Sigma[],
  feed: AssetFeed,
  bufferTimespan = BUFFERS.Small,
): [Json<Store.AssetSigmas>, boolean, Date] => {
  //those ref are used to buffer data
  const dataRef = useRef<Json<Store.AssetSigmas>>({})

  const [lastTick, setLastTick] = useState<Date>(new Date())

  const [data, setData] = useState<Json<Store.AssetSigmas>>({})

  const messageReceived = () => {
    setLastTick(new Date())
  }
  const onMessage = (message: SocketMessage) => {
    try {
      const { data } = message
      data.arrayBuffer().then((buffer) => {
        //trigger messageReceived as first line
        messageReceived()

        const deserialized = deserializeAssetBuffer(buffer, feed, 'snapshot')
        if (!deserialized) {
          return
        }
        const [key, mappedSigmas] = deserialized

        dataRef.current[key] = { ...dataRef.current[key], ...removeUndefinedFields(mappedSigmas) }
        debug('socket received Asset Sigmas', mappedSigmas)
      }).catch(e => console.error('Err with SIGMA data buffer', e))
    } catch (error) {
      debug('Not binary message (JSON)', error)
    }
  }

  useEffect(() => {
    const assetIds = assets.map((asset) => asset.id)
    const grouped = groupBy('portfolioId')(assets)

    dataRef.current = Object.keys(dataRef.current).reduce((obj, key) => {
      if (assetIds.includes(parseInt(key))) {
        obj[key] = dataRef.current[key]
      }
      return obj
    }, {})

    const sockets = Object.keys(grouped).map((key) => {
      // console.log('subscribe to SIGMA sockets')
      const ids = grouped[key].map((asset) => asset.id).join('|')
      return subscribeToSnapshotsWithInterval(
        ids,
        onMessage,
        sessionId,
        parseInt(key),
        feed,
        sigmas,
        null,
        true,
        FlowControlSpeed.High,
      )
    })

    return () => {
      // console.log('unsubscribe SIGMA snapsh', sockets)
      sockets.map((unsubscribe) => unsubscribe && unsubscribe())
    }
  }, [assets])

  //Update with buffered data
  useInterval(() => setData({ ...dataRef.current }), bufferTimespan)

  useInterval(() => {
    //This trigger a notification to context
    setLastTick(new Date(lastTick))
  }, isAlive)

  return [data, isOffline(lastTick), lastTick]
}
