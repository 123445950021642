import React, { useEffect, useState } from 'react'
import Debug from 'debug'
import { Collapse, Form, Input, InputNumber, Table, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'
import { ColumnsType } from 'antd/lib/table'
import { CrudOperation } from 'types/enums'

const debug = new Debug('Frontend')
interface Props {
  params: Store.ModelParameter[]
  mode: CrudOperation
}

export const MultiModelParameters: React.FC<Props> = ({ params, mode }) => {
  const { t } = useTranslation()

  const mappedAndSortedParamsTable = params.map((p, index) => {
    return {
      ...p,
      key: `${p.name}_${p.type}_${index}`
    }
  }).sort((a,b) => a.name > b.name ? 1 : -1)

  const columns: ColumnsType<Store.ModelParameter> = [
    {
      title: t('table.column.type'),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: t('table.column.name'),
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      render: (_, {name}) => {
        return <Tooltip title={name}>{name}</Tooltip>
      }
    },
    {
      title: t('table.column.value'),
      dataIndex: 'value',
      key: 'value',
      ellipsis: true,
      render: (value, { type, name }) => {
        if (mode === CrudOperation.Read) {
          return <Tooltip title={value}>{value}</Tooltip>
        }
        if (type === 'Integer') {
          return (
            <Form.Item
              initialValue={Number(value)}
              rules={[{ type: 'number' }]}
              name={`multiAssetModelParam.${name}.${type}`}
            >
              <InputNumber type={type} />
            </Form.Item>
          )
        }
        if (type === 'List' || type === 'Dictionary') {
          return (
            <Form.Item name={`multiAssetModelParam.${name}.${type}`} initialValue={value}>
              <Input type='text' />
            </Form.Item>
          )
        }
        return (
          <Form.Item
            initialValue={value}
            name={`multiAssetModelParam.${name}.${type}`}
          >
            <Input type={type} />
          </Form.Item>
        )
      },
    },
  ]

  return (
    <Collapse expandIconPosition={'end'}>
      <Collapse.Panel header={'Group Model Parameters'} key="multi-asset-model-parameters" forceRender>
        <Table dataSource={mappedAndSortedParamsTable} columns={columns} pagination={false} />
      </Collapse.Panel>
    </Collapse>
  )
}
