import React, { useContext } from 'react'
import { Avatar, Button, Dropdown, Menu } from 'antd'
import { AssetStatus } from 'types/enums'
import { DownOutlined } from '@ant-design/icons'
import { getStatusIcon, selectPopupContainer } from 'core/helpers'
import IconFont from 'core/IconFont'
import { useDispatch, useSelector } from 'react-redux'
import { selectOperativeSessionAssets, selectOperativeSessionSession } from 'store/pages/selectors'
import * as Store from 'types/store'
import { changeSessionAssets } from 'store/pages/operative-session/actions'
import ChangeAllIcon from 'assets/icon/change-all-icon.svg'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'

const values = [
  AssetStatus.Automatic,
  AssetStatus.Freeze,
  AssetStatus.StandByFlat,
  AssetStatus.StandByLong,
  AssetStatus.StandByShort,
]

interface Props {
  portfolio: Store.Portfolio
}

const AssetStatusDropdownContainer: React.FC<Props> = ({ portfolio }) => {
  const dispatch = useDispatch()

  const context = useContext(CandleStickFrameContext)
  const { isFullscreen } = context

  const session = useSelector(selectOperativeSessionSession)
  const assets = useSelector(selectOperativeSessionAssets)
  const { id: sessionId } = session
  const portfoliosAssets = assets.filter((item) => item.portfolioId === portfolio.id)

  const menu = values.map(item => {
    return {
      key: item,
      label: (
        <IconFont type={getStatusIcon(item)} />
      ),
      onClick: (value) => {
        dispatch(
          changeSessionAssets({ assets: portfoliosAssets, sessionId, status: value.key as AssetStatus }),
        )
      }
    }
  })

  return (
    <Dropdown
      menu={{items: menu}}
      trigger={['click']}
      getPopupContainer={(trigger) => selectPopupContainer(trigger, isFullscreen)}
      disabled={portfoliosAssets.length === 0}
      className="dropdown dropdown--models-status"
    >
      <Button type="dashed">
        <Avatar src={ChangeAllIcon} shape="square" /> <DownOutlined />
      </Button>
    </Dropdown>
  )
}

export default AssetStatusDropdownContainer
