import React, { useState } from 'react'
import { Avatar, Button, Popover } from 'antd'
import Legend from './Legend'
import InfoBtnIcon from 'assets/icon/info-icon.svg'
import ActiveInfoBtnIcon from 'assets/icon/info-icon-active.svg'

const LegendPopover: React.FC = () => {
  const [isActive, setIsActive] = useState<boolean>(false)

  return (
    <Popover
      overlayClassName="map-view-popover nonDraggable"
      placement="bottomRight"
      trigger="click"
      getPopupContainer={() => document.querySelector('.draggable-grid-section')}
      onVisibleChange={() => setIsActive(!isActive)}
      content={<Legend />}
      zIndex={100}
    >
      <Button type="ghost" className="info-btn nonDraggable">
        <Avatar shape="square" src={isActive ? ActiveInfoBtnIcon : InfoBtnIcon} size={16} />
      </Button>
    </Popover>
  )
}

export default LegendPopover
