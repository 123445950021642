import React, { useContext } from 'react'
import { Button, Dropdown, Typography } from 'antd'
import CandlestickView from './common/CandlestickView'
import ViewTypeSelector from '../ViewTypeSelector'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
import { t } from 'core'
import * as Store from 'types/store'
import { FrameSize, ViewType } from 'types/ui'
import { SessionStatus, TimeFrame } from 'types/enums'
import { DownOutlined } from '@ant-design/icons'
import AssetStatusDropdownContainer from 'v2/containers/CandleStick/Portfolio/AssetStatusDropdown'
import { renderAvailableData, selectPopupContainer } from 'core/helpers'
import DetailsView from './common/DetailsView'
import { CELL_HEIGHT, CELL_WIDTH, confirmFrameSize } from 'services/dashboardService'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'

interface Props {
  sigmas: Store.PortfolioSigmas
}

const PanelContent: React.FC<Props> = ({ sigmas }) => {
  const context = useContext(CandleStickFrameContext)
  const { viewType, timeFrame, resource, isFullscreen, squares } = context
  const portfolio = resource as Store.Portfolio
  const session = useSelector(selectOperativeSessionSession)

  const isBackTest = timeFrame === TimeFrame.TOT
  const isAssetStatusChangeDisabled =
    isBackTest || session.status === SessionStatus.Broken || session.status !== SessionStatus.Running

  const [width, height] = [squares.x * CELL_WIDTH, squares.y * CELL_HEIGHT]
  const isLarge = confirmFrameSize(width, height, FrameSize.Large)

  const onChangeView = (viewType: ViewType) => {
    context.setViewType(viewType)
  }

  const onChangeTimeFrame = (timeFrame: TimeFrame) => {
    context.setTimeFrame(timeFrame)
  }

  const menu = [
    {
      key: TimeFrame.TDY,
      label: t('general.TDY'),
      onClick: ({ key }) => onChangeTimeFrame(key as TimeFrame),
    },
    {
      key: TimeFrame.YTD,
      label: t('general.YTD'),
      onClick: ({ key }) => onChangeTimeFrame(key as TimeFrame),
    },
  ]

  return (
    <>
      <div className="portfolio-description-row nonDraggable">
        <Typography.Paragraph className="title-value">
          {t('chart.currency')}
          <span>{renderAvailableData(sigmas?.cartesianHeading?.baseCurrency)}</span>
        </Typography.Paragraph>
        <Typography.Paragraph className="title-value">
          {t('chart.assetNum')} <span>{renderAvailableData(sigmas?.cartesianHeading?.assetsNum)}</span>
        </Typography.Paragraph>
        <Typography.Paragraph className="title-value">
          {t('chart.activatedModelsNum')}
          <span>{renderAvailableData(sigmas?.cartesianHeading?.activatedModelsNum)}</span>
        </Typography.Paragraph>
      </div>

      <div className="portfolio-config-row nonDraggable">
        <Dropdown
          // overlay={timeFrameMenu}
          menu={{items: menu}}
          trigger={['click']}
          getPopupContainer={(trigger) => selectPopupContainer(trigger, isFullscreen)}
          className="dropdown dropdown--time-horizon"
          disabled={isBackTest}
        >
          <Button type="dashed">
            {timeFrame} <DownOutlined />
          </Button>
        </Dropdown>

        {!isAssetStatusChangeDisabled && <AssetStatusDropdownContainer portfolio={portfolio} />}

        <ViewTypeSelector value={viewType} onChange={onChangeView} />
      </div>

      {viewType === ViewType.Details || isFullscreen || isLarge ? (
        <DetailsView sigmas={sigmas} />
      ) : (
        <CandlestickView />
      )}
    </>
  )
}

export default PanelContent
