import * as Api from 'types/api'
import { getAxios } from './apiService'
import { config } from 'core'
import { Bearer, getApiError } from './common'
import { getAuthToken } from 'core/localStore'

const axios = getAxios()

export async function uploadCsv(data: FormData): Promise<Api.CsvUploadResponse | ApiError> {
  const token = await getAuthToken()
  const header = {
    headers: {
      Authorization: `${Bearer} ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  }

  return axios
    .post(config.api.createAssetCsv(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}
