import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Modal, Row, Space, Table } from 'antd'
import * as Store from 'types/store'
import moment from 'moment'
import { compareByDate, getCheckBoxFilter, getColumnSearchProps, sortString } from 'helpers/tableHelpers'
import { ColumnsType } from 'antd/lib/table'
import AppContentCard from 'components/AppContentCard'
import styles from './styles.module.scss'

interface Props {
  data: Store.ModelClass[]
  visible: boolean
  onCancel: () => void
  onSelect: (id: number) => void
  resetButton?: boolean
}

const ModelsTable: React.FC<Props> = ({ data, visible, onCancel, onSelect, resetButton = true }) => {
  const { t } = useTranslation()

  const mappedTableData = data.map((model, index) => {
    return {
      ...model,
      key: `${model.id}_${index}`,
    }
  })

  const TABLE_COLUMNS: ColumnsType<any> = [
    {
      key: 'modelName',
      title: t('table.column.model'),
      dataIndex: 'modelName',
      defaultSortOrder: 'descend',
      sorter: (a, b) => sortString(a.modelName, b.modelName),
      ...getColumnSearchProps('modelName'),
    },
    {
      key: 'version',
      title: t('table.column.version'),
      dataIndex: 'version',
      ...getColumnSearchProps('version'),
    },
    {
      key: 'modelType',
      title: t('table.column.type'),
      dataIndex: 'modelType',
      ...getCheckBoxFilter('modelType', data),
    },
    {
      key: 'modelLanguage',
      title: t('assets.modelLanguage'),
      dataIndex: 'modelLanguage',
      ...getCheckBoxFilter('modelLanguage', data),
    },
    {
      key: 'modelDescription',
      title: t('assets.modelDescription'),
      dataIndex: 'modelDescription',
      ...getColumnSearchProps('modelDescription'),
    },
    {
      key: 'date',
      title: t('table.column.date'),
      dataIndex: 'date',
      sorter: (a, b) => compareByDate(a.date, b.date),
      render: (date: moment.MomentInput) => {
        return moment(date).format('L')
      },
    },
    {
      key: 'user',
      title: t('table.column.user'),
      dataIndex: 'user',
      ...getColumnSearchProps('user'),
    },
    {
      key: 'id',
      dataIndex: 'id',
      render: (id: any) => {
        return (
          <Button
            className="secondary"
            type="text"
            onClick={() => {
              onSelect(id) // Sends the model id to
              onCancel()
            }}
          >
            {t('general.select')}
          </Button>
        )
      },
    },
  ]

  return (
    <Modal
      open={visible}
      centered
      width={'65%'}
      onCancel={onCancel}
      footer={null}
      style={{ minWidth: '1150px' }}
      closable={false}
    >
      <AppContentCard>
        <section className={(styles['model-modal'], 'model-modal')}>
          <Table
            columns={TABLE_COLUMNS}
            dataSource={mappedTableData}
            pagination={false}
            scroll={{ x: 200, y: 296 }}
            getPopupContainer={() => document.querySelector('.model-modal')}
          />
        </section>

        <Row justify="end" className="mt-30">
          <Space>
            {
              resetButton &&
              (
                <Button
                  className="pl-20 pr-20"
                  type="link"
                  danger
                  onClick={() => {
                    onSelect(null)
                    onCancel()
                  }}
                >
                  {t('table.filter.reset')}
                </Button>
              )
            }
            <Button type="primary" onClick={onCancel}>
              {t('general.cancel')}
            </Button>
          </Space>
        </Row>
      </AppContentCard>
    </Modal>
  )
}

export default ModelsTable
