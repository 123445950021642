import React from 'react'
import { ChartChild } from 'types/chart'
import { renderChildren } from '../common'

interface Props {
  /**
   * Value should be in percentage between 0.0 and 1.0
   */
  x: number
  /**
   * Value should be in percentage between 0.0 and 1.0
   */
  y: number
  /**
   * Value should be in percentage between 0.0 and 1.0
   */
  width: number
  /**
   * Value should be in percentage between 0.0 and 1.0
   */
  height: number
}

const ChartGroup: React.FC<Props & ChartChild> = ({
  x,
  y,
  width,
  height,
  chartHeight,
  chartWidth,
  clipId,
  children,
  d3Transform,
  transitionTime,
  xDomain,
  yDomain,
}) => {
  const props: ChartChild = {
    chartHeight: chartHeight * height,
    chartWidth: chartWidth * width,
    d3Transform: d3Transform,
    clipId,
    yDomain,
    xDomain,
    transitionTime,
  }

  return (
    <g className='JI-RENDER-chart-group'
      transform={`translate(${chartWidth * x},${chartHeight * y})`}
    >
      {renderChildren(children, props)}
    </g>
  )
}

export default ChartGroup
