import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'
import React from 'react'
import { VisibilityContext } from 'react-horizontal-scrolling-menu'

function Arrow({
  children,
  disabled,
  onClick,
}: {
  children: React.ReactNode
  disabled: boolean
  onClick: VoidFunction
}) {
  const stopPropagation = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => e.stopPropagation()
  return (
    <button
      disabled={disabled}
      className="drawer-arrows"
      style={{ opacity: disabled ? 0 : 1 }}
      onClick={(e) => {
        onClick()
        stopPropagation(e)
      }}
      onMouseDown={stopPropagation}
    >
      {children}
    </button>
  )
}

export function LeftArrow() {
  const { isFirstItemVisible, scrollPrev, visibleItemsWithoutSeparators, initComplete } =
    React.useContext(VisibilityContext)

  const [disabled, setDisabled] = React.useState(!initComplete || (initComplete && isFirstItemVisible))
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible)
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators])

  return (
    <Arrow disabled={disabled} onClick={() => scrollPrev()}>
      <LeftCircleOutlined style={{ color: '#7FB4EB' }} />
    </Arrow>
  )
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext, visibleItemsWithoutSeparators } = React.useContext(VisibilityContext)

  const [disabled, setDisabled] = React.useState(!visibleItemsWithoutSeparators.length && isLastItemVisible)
  React.useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible)
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators])

  return (
    <Arrow disabled={disabled} onClick={() => scrollNext()}>
      <RightCircleOutlined style={{ color: '#7FB4EB' }} />
    </Arrow>
  )
}
