import * as Api from 'types/api'
import ReadPortfolio from 'components/StrategyViewer/ReadPortfolio'
import { selectUsedResources } from 'store/pages/selectors'
import { selectAssets, selectAssetsState, selectGroups, selectGroupsState, selectPortfolios, selectPortfoliosState } from 'store/resources/selectors'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updatePortfolio } from 'store/resources/portfolios/actions'
import { CrudOperation } from 'types/enums'
import * as Store from 'types/store'
import { setUserAction } from 'store/pages/strategy/actions'
import { NodeType } from 'types/ui'
import DeleteGroup from './modals/DeleteGroup'
import DeleteAsset from './modals/DeleteAsset'
import { ResourceAction } from 'types/store'
import NoItemSelected from 'components/StrategyViewer/NoItemSelected'

interface Props {
  userAction: Store.UserAction
  isReadOnly: boolean
}

export const ReadPortfolioContainer: React.FC<Props> = ({ userAction, isReadOnly }) => {
  const dispatch = useDispatch()
  const assets = useSelector(selectAssets)
  const groups = useSelector(selectGroups)
  const portfolios = useSelector(selectPortfolios)
  const usedResources = useSelector(selectUsedResources)

  const loadingState = useSelector(selectPortfoliosState)
  const groupsLoadingState = useSelector(selectGroupsState)
  const assetsLoadingState = useSelector(selectAssetsState)

  const { operation, node } = userAction
  const { type, resource } = node
  // Local action for groupedAssets column
  const [resourceAction, setResourceAction] = useState<ResourceAction>(null)
  const [asset, setAsset] = useState<Store.Asset>(null)
  const [group, setGroup] = useState<Store.Group>(null)
  const [portfolio, setPortfolio] = useState<Store.Portfolio>(null)

  useEffect(() => {
    switch (type) {
      case NodeType.Asset:
        setGroup(null)
        setAsset(assets.find((asset) => asset.id === resource.id))
        if ((resource as Store.Asset)?.portfolioId) {
          setPortfolio(portfolios.find((item) => item.id === (resource as Store.Asset).portfolioId))
        }
        break
      case NodeType.Group:
        setAsset(null)
        setGroup(groups.find((group) => group.id === resource.id))
        if ((resource as Store.Group)?.portfolioId) {
          setPortfolio(portfolios.find((item) => item.id === (resource as Store.Group).portfolioId))
        }
        break
      case NodeType.Portfolio:
        setAsset(null)
        setGroup(null)
        setPortfolio(portfolios.find((item) => item.id === resource.id))
        break
      default:
        break
    }
  }, [loadingState, assetsLoadingState, groupsLoadingState, type, resource])

  if (!portfolio) {
    return <NoItemSelected noStrategies={false} />
  }

  const portfolioAssets = assets.filter((asset) => asset.portfolioId === portfolio.id)
  const porftolioGroups = groups.filter((group) => group.portfolioId === portfolio.id)

  const onCancel = () => {
    dispatch(setUserAction(userAction.node, CrudOperation.Read))
  }

  const onPortfolioSave = (data: Json) => {
    const postData: Api.PortfolioRest = {
      id: portfolio.id,
      strategyId: portfolio.strategyId,
      name: data.name,
      maxLong: data.maxLong,
      maxNetHedging: data.maxNetHedging,
      maxShort: data.maxShort,
      minNetHedging: data.minNetHedging,
    }

    dispatch(updatePortfolio(postData))
  }

  const portfolioClick = (resource: Store.Asset | Store.Group, operation: CrudOperation, nodeType: NodeType.Asset | NodeType.Group) => {
    if (operation === CrudOperation.Delete) {
      setResourceAction({ resource: resource, type: nodeType, operation })
    }
  }

  const onClose = () => {
    setResourceAction(null)
  }

  return (
    <>
      {resourceAction?.resource && resourceAction.operation === CrudOperation.Delete &&
        resourceAction.type === NodeType.Group
          && (<DeleteGroup group={resourceAction.resource as Store.Group} onClose={onClose} />)
      }
      {resourceAction?.resource && resourceAction.operation === CrudOperation.Delete &&
        resourceAction.type === NodeType.Asset
          && (<DeleteAsset asset={resourceAction.resource as Store.Asset} onClose={onClose} />)
      }
      <ReadPortfolio
        asset={asset}
        group={group}
        portfolio={portfolio}
        assets={portfolioAssets}
        groups={porftolioGroups}
        usedResources={usedResources}

        loadingState={loadingState}
        isEditing={operation === CrudOperation.Update}
        isReadOnly={isReadOnly}

        onPortfolioSave={onPortfolioSave}
        onCancel={onCancel}
        portfolioAction={portfolioClick}
        key={`${portfolio.id}-${assets.length}-${groups.length}`} // Needed to force entire columns to re-render after resources deletion in particular
      />
    </>
  )
}
