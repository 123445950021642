import * as Api from 'types/api'
import { TimeFrame } from 'types/enums'
import * as Store from 'types/store'

export function jsonToPositionMonitoring(json: Api.PositionAnalysis[]): Store.PositionMonitoring[] {
  return json.map((data) => ({
    assetClass: data.asset_class,
    currency: data.currency,
    id: data.asset_id,
    market: data.market,
    netHedging: data.net_hedging | 0,
    value: data.countervalue_bc | 0,
    valueLong: data.countervalue_bc_long | 0,
    valueShort: data.countervalue_bc_short | 0,
    quantity: null, // TBD
    sign: null,
  }))
}

export function jsonToReturnAnalysis(json: Api.ReturnAnalysis[]): Store.ReturnAnalysis[] {
  return json.map((data) => ({
    assetClass: data.asset_class,
    closeTradingRetBc: data.close_trading_ret_bc,
    currency: data.currency,
    id: data.asset_id,
    market: data.market,
    value: data.trading_ret_bc,
    valueShort: data.short_trading_ret_bc,
    valueLong: data.long_trading_ret_bc,
    netHedging: (data.long_trading_ret_bc || 0) - (data.short_trading_ret_bc || 0),
    openTradingRetBc: data.open_trading_ret_bc,
    type: data.type as TimeFrame,
    quantity: null,
    sign: null,
  }))
}
