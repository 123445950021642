import * as Api from 'types/api'
import * as Store from 'types/store'
import { ApiSessionType } from 'types/enums.api'

export function storeToLayoutPayload(layout: Store.Layout): Api.LayoutPayload {
  const json: Api.LayoutPayload = {
    layoutName: layout.name,
    configurationJson: JSON.stringify(layout.configuration),
    sessionId: 3, //this should be not required anymore
    type: ApiSessionType.Trading, //this is not used anymore, any value is fine
  }

  if (layout.id) {
    json.layoutId = layout.id
  }

  return json
}
