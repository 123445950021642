import { getHistogram } from 'api/charts'
import React, { useState } from 'react'
import { useMount } from 'react-use'
import HistogramWithNormalDistribution from 'components/charts/HistogramWithNormalDistribution'

const HistogramWithNormalDistributionContainer: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Array<number>>(null)

  useMount(() => {
    const getData = async () => {
      const result = await getHistogram()
      //This is a demo: take only some
      setData(result as Array<number>)
      setLoading(false)
    }

    setLoading(true)
    getData()
  })

  if (loading || data === null) {
    return null
  }

  return <HistogramWithNormalDistribution data={data} />
}

export default HistogramWithNormalDistributionContainer
