import React, { useState } from 'react'
import * as api from 'api/assets'
import * as Api from 'types/api'
import { isApiError } from 'core'
import { mapJsonToExchanges } from 'services/store/mapService'
import MarketField from 'components/StrategyViewer/forms/AssetForm/MarketField'
import * as Store from 'types/store'
import { CrudOperation } from 'types/enums'
import { FormInstance } from 'antd'

const getMarket = async (symbol: string) => {
  const response = await api.getMarket(symbol)
  if (isApiError(response)) {
    return null
  }

  const marketsResponse = response as Api.ExchangeResponse
  const mappedData = mapJsonToExchanges(marketsResponse.data.application_exchange)

  return mappedData
}
interface Props {
  mode: CrudOperation
  form: FormInstance
}

const MarketFieldContainer: React.FC<Props> = ({ mode, form }) => {
  const [markets, setMarkets] = useState<Store.Exchange[]>([])

  const filterMarkets = async (symbol: string) => {
    const markets = await getMarket(symbol)
    if (markets) {
      setMarkets(markets)
    }

    if (markets.length === 1) {
      form.setFieldsValue({
        market: markets[0].customCode,
      })
    }
  }

  return <MarketField data={markets} filter={filterMarkets} mode={mode} />
}

export default MarketFieldContainer
