import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'
import { renderAvailableData } from 'core/helpers'
import { formatGliphNumber } from 'core/formats'
import moment from 'moment'
import { Typography } from 'antd'
import colors from 'config/colors'

interface Props {
  cartesianReturn: Store.CartesianReturn
}

const Trade: React.FC<Props> = ({ cartesianReturn }) => {
  const { t } = useTranslation()
  const value = cartesianReturn?.tradingRetBc && Math.round(cartesianReturn?.tradingRetBc * 100) / 100

  return (
    <div className="trade-container">
      <div className="trade__title-wrapper">
        <Typography.Title level={2}>
          <span>{renderAvailableData(cartesianReturn?.type)}</span> {t('chart.tradeReturn')}
        </Typography.Title>
      </div>

      <div className="content">
        <div className="trade-circle-graph">
          <div className="trade-circle-chart">
            <svg viewBox="0 0 35 35">
              <g className="trade-circle">
                <circle
                  cx="17.5"
                  cy="17.5"
                  r="15.3"
                  fill="transparent"
                  stroke={colors.primary}
                  strokeWidth="1.5"
                ></circle>
              </g>
            </svg>
          </div>
        </div>

        <div className="text">
          <Typography.Text className="text-in-circle">
            {renderAvailableData(cartesianReturn?.type)}
          </Typography.Text>
          <Typography.Text className="text-in-circle">{t('chart.tradeReturn')}</Typography.Text>
          <Typography.Text className="trade-data">
            {renderAvailableData(formatGliphNumber(value))}
          </Typography.Text>
          <Typography.Text>{moment(cartesianReturn?.lastEvtTs).format('HH:mm:ss')}</Typography.Text>
        </div>
      </div>
    </div>
  )
}

export default Trade
