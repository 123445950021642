import * as K from './constants'
import * as Api from 'types/api'
import * as Store from 'types/store'
import { LoadingState } from 'types/enums'
import { AnyAction } from 'redux'

export function fetchAssets(): AnyAction {
  return {
    type: K.ASSETS_FETCH,
    payload: null,
  }
}

export function fetchHistoricalAssets(sessionId: number): AnyAction {
  return {
    type: K.HISTORICAL_ASSETS_FETCH,
    payload: sessionId,
  }
}

export function createAsset(data: Api.AssetRest): AnyAction {
  return {
    type: K.ASSET_CREATE,
    payload: data,
  }
}

export function updateAsset(data: Api.AssetRest): AnyAction {
  return {
    type: K.ASSET_UPDATE,
    payload: data,
  }
}

export function addAsset(data: Store.Asset): AnyAction {
  return {
    type: K.ASSET_CREATE_SUCCESS,
    payload: data,
  }
}

export function deleteAsset(id: number): AnyAction {
  return {
    type: K.ASSET_DELETE,
    payload: id,
  }
}

export function setLoadingState(loadingState: LoadingState): AnyAction {
  return {
    type: K.SET_STATUS,
    payload: loadingState,
  }
}
