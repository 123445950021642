import { createSelector } from 'reselect'
import * as Store from 'types/store'

const dashboard = (state: { root: { dashboard } }) => state.root.dashboard as Store.DashboardState

// Data Selectors
export const selectDashboard = createSelector(dashboard, (slice) => slice)

export const selectDashboardEntityFilter = createSelector(selectDashboard, (slice) => slice.entityFilter)
export const selectDashboardSessionFrames = createSelector(selectDashboard, (slice) => slice.frames)
export const selectDashboardId = createSelector(selectDashboard, (slice) => slice.id)
export const selectDashboardResolutions = createSelector(
  selectDashboard,
  (slice) => slice.guidelines.resolutions,
)
export const selectDashboardGuidesActive = createSelector(selectDashboard, (slice) => slice.guidelines.active)
export const selectDragging = createSelector(selectDashboard, (slice) => slice.isDragging)
export const selectResizedFrame = createSelector(selectDashboard, (slice) => slice.resizedFrame)
export const selectHeight = createSelector(selectDashboard, (slice) => slice.height)
