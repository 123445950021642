import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import * as Store from 'types/store'

import { useSelector } from 'react-redux'
import CandleStick from 'v2/components/CandleStick'
import { selectOperativeSessionSession } from 'store/pages/selectors'
// import { useCandleStickData } from '../hooks'
import { filterEmptyData, getDateLimits, hasEmptyData } from 'core/helpers'
import Spinner from 'components/Spinner'
import CandlestickWrapper from 'v2/components/CandlestickWrapper'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
import sigmaServices from 'config/sigmaServices'
import { StrategyFeed } from 'types/enums.api'
import { getAuthenticationHeader } from 'api/common'
import { BUFFERS, config } from 'core'

const { Return } = sigmaServices.Strategies

interface Props {
  width: number
  height: number
}

const StrategyCandleStickContainer: React.FC<Props> = ({ width, height }) => {
  const context = useContext(CandleStickFrameContext)
  const { timeFrame, resource: strategy } = context

  const session = useSelector(selectOperativeSessionSession)
  const sessionId = session.id

  const { id } = strategy

  const [data, setData] = useState<any>({
    candleStickData: [] as Store.CandleStick[],
  })
  const [workerParams, setWorkerParams] = useState<any>({})
  const [headers, setHeaders] = useState<any>({})

  const webWorkerRef = useRef<Worker | null>(null)


  // const { data: candleStickData, setParams } = useCandleStickData(
  //   Return.StgClosedCandle,
  //   StrategyFeed.Return,
  //   timeFrame,
  // )


  const initMsg = useMemo(() =>
    {
      return {
        type: 'process',
        payload: {
          sigmas: [
            { title: 'candleStickData', service: Return.StgClosedCandle, feed: StrategyFeed.Return },
          ],
          timeFrame: timeFrame,
          bufferTimespan: BUFFERS.Large,
          session: session,
          params: workerParams,
          headers: headers,
          webSocketUrl: config.socketBaseUrl,
          tsUrl: config.timeSeriesBaseUrl,
        },
      }
    }, [id, timeFrame, workerParams, session, headers]
  )

  useEffect(() => {
    const getHeaders = async () => {
      const headers = await getAuthenticationHeader()
      setHeaders(headers)
    }
    getHeaders()
  }, [])
  
  useEffect(() => {
    if (!headers) {
      return
    }
    // Setup worker
    webWorkerRef.current = new Worker(new URL('../../../../workers/candleStickSockets.web.worker.ts', import.meta.url))

    webWorkerRef.current.onmessage = (event: MessageEvent) => {
      const { type, data: workerData, error } = event.data
      if (type === 'data') {
        setData(workerData)
      } else if (type === 'error') {
        console.error('Worker error:', error)
      }
    }

    return () => {
      webWorkerRef.current.postMessage({ type: 'terminate' })
      webWorkerRef.current.terminate()
    }
  }, [])

  useEffect(() => {
    // Send messages to worker
    const msg = {...initMsg}
    msg.payload.headers = headers
    msg.payload.params = workerParams

    webWorkerRef.current.postMessage(msg)
  }, [workerParams])

  const getFromTo = (from: Date, to: Date) => {
    // setParams({ id, from, portfolioId: id, sessionId, to })
    setWorkerParams({ id, from, portfolioId: id, sessionId, to })
  }

  const noData = () => {
    if (data.candleStickData && hasEmptyData(data.candleStickData)) {
      return false
    }
    return true
  }

  return (
    <CandlestickWrapper>
      {noData() && <Spinner />}
      <CandleStick
        loading={noData()}
        timeFrame={timeFrame}
        session={session}
        candleStickData={(data.candleStickData) as Store.CandleStick[]}
        lineChartData={[]}
        operationsChartData={[]}
        currency={null}
        getFromTo={getFromTo}
        getXDomain={() => getDateLimits(timeFrame, session)}
        width={width}
        height={height}
      />
    </CandlestickWrapper>
  )
}

export default StrategyCandleStickContainer
