import React, { useLayoutEffect, useRef, useState } from 'react'
import HistogramWithNormalDistribution from 'charts/HistogramWithNormalDistribution'

interface Props {
  data: Array<number>
}

const HistogramWithNormalDistributionView: React.FC<Props> = ({ data }) => {
  const container = useRef(null)
  const [chart, setChart] = useState(null)

  const createChart = (data: Array<number>): void => {
    const theChart = HistogramWithNormalDistribution(container.current)
    theChart.enter(data)
    setChart(theChart)
  }

  useLayoutEffect(() => {
    if (!chart) {
      createChart(data)
    } else {
      chart.update(data)
    }
  }, [data])

  return <svg ref={container} />
}

export default HistogramWithNormalDistributionView
