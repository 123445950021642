import * as K from './constants'
import * as Store from 'types/store'
import * as Api from 'types/api'
import { LoadingState, SessionStatus } from 'types/enums'
import { AnyAction } from 'redux'

export function loadSessionById(id: number): AnyAction {
  return {
    type: K.FETCH_OPERATIVE_SESSION,
    payload: id,
  }
}

export function loadFinishedById(id: number): AnyAction {
  return {
    type: K.FETCH_FINISHED_OPERATIVE_SESSION,
    payload: id,
  }
}

export function refreshSession(id: number): AnyAction {
  return {
    type: K.REFRESH_OPERATIVE_SESSION,
    payload: id,
  }
}

export function setViewType(type: string) {
  return {
    type: K.SET_VIEW_TYPE,
    payload: type,
  }
}

export function changeSessionAssets(payload: Store.UpdateSessionAssetsStatusPayload): AnyAction {
  return {
    type: K.UPDATE_ASSETS_STATUS,
    payload: payload,
  }
}

export function changePositionMonitoringAssets(payload: Store.Asset[]): AnyAction {
  return {
    type: K.UPDATE_POSITION_MONITORING_ASSETS,
    payload: payload,
  }
}

export function changeReturnAnalysisAssets(payload: Store.Asset[]): AnyAction {
  return {
    type: K.UPDATE_RETURN_ANALYSIS_ASSETS,
    payload: payload,
  }
}

export function setLoadingState(payload: LoadingState): AnyAction {
  return {
    type: K.SET_STATUS,
    payload: payload,
  }
}

export function launchBackTestOperation(payload: Store.LaunchBackTestOperationPayload): AnyAction {
  return {
    type: K.LAUNCH_BACKTEST_OPERATION,
    payload: payload,
  }
}

export function launchVirtualSession(payload: Api.VirtualSessionLaunch): AnyAction {
  return {
    type: K.LAUNCH_VIRTUAL_SESSION,
    payload: payload,
  }
}

export function stopVirtualSession(): AnyAction {
  return {
    type: K.STOP_VIRTUAL_SESSION,
    payload: null,
  }
}

export function stopBackTestOperation(): AnyAction {
  return {
    type: K.STOP_BACKTEST_OPERATION,
    payload: null,
  }
}

export function freezeBackTestOperation(): AnyAction {
  return {
    type: K.FREEZE_BACKTEST_OPERATION,
    payload: null,
  }
}

export function updateOperativeSessionStatus(payload: SessionStatus): AnyAction {
  return {
    type: K.CHANGE_SESSION_STATUS,
    payload: payload,
  }
}
