export const STATE_KEY = 'setupSession'

export const FETCH_SESSION_SETUP_UPDATE = `${STATE_KEY}/FETCH_SESSION_SETUP_UPDATE`
export const FETCH_SESSION_ASSETS = `${STATE_KEY}/FETCH_SESSION_ASSETS`
export const FETCH_SESSION_BROKEN_ENTITIES = `${STATE_KEY}/FETCH_SESSION_BROKEN_ENTITIES`
export const FETCH_SESSION_BROKEN_ENTITIES_SUCCESS = `${STATE_KEY}/FETCH_SESSION_BROKEN_ENTITIES_SUCCESS`
export const RESET_PAGE = `${STATE_KEY}/RESET_PAGE`

export const UPDATE_ADD_ASSETS = `${STATE_KEY}/UPDATE_ADD_ASSETS`
export const UPDATE_REMOVE_ASSETS = `${STATE_KEY}/UPDATE_REMOVE_ASSETS`
export const UPDATE_ASSETS_SUCCESFULL = `${STATE_KEY}/UPDATE_ASSETS_SUCCESFULL`

export const SET_CURRENT_NODE = `${STATE_KEY}/SET_CURRENT_NODE`
export const SET_STATUS = `${STATE_KEY}/SET_STATUS`
