import React, { useCallback, useEffect, useState } from 'react'
import { Input, Tree, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import AppContentCard from 'components/AppContentCard'
import styles from './styles.module.scss'
import IconFont from 'core/IconFont'
import ReadItem from './ReadItem'
import { getInitialExpansion, getInitialHistoricalExpansion } from 'helpers/transfer/core'
import { ExtendedDataNode } from 'types/ui'
const { Title } = Typography

import debounce from 'debounce'

interface Props {
  treeData: ExtendedDataNode[]
  onNodeClicked?: (node: ExtendedDataNode) => void
  onFilterChange: (value: string) => void
}

const HistoricalStrategyTreeView: React.FC<Props> = ({
  treeData,
  onNodeClicked = () => null,
  onFilterChange,
}) => {
  const initialExpanded = getInitialHistoricalExpansion(treeData)

  const { t } = useTranslation()

  const handleFilterChange = useCallback(
    debounce((filter: string) => onFilterChange(filter), 300),
    [onFilterChange],
  )

  const renderFilter = () => {
    return (
      <Input
        placeholder={t('table.filter.searchAssets')}
        onChange={(e) => {
          handleFilterChange(e.target.value)
        }}
        suffix={<IconFont type="icon-magnifier" />}
      />
    )
  }

  const getContextItem = (node: ExtendedDataNode) => {
    return <ReadItem node={node} />
  }

  return (
    <div className={styles['strategy-treeview']}>
      <Title className="title">{t('general.historicalSession')}</Title>

      {renderFilter()}

      <div data-augmented-ui-reset>
        <AppContentCard fullHeight>
          {/*
            * Ensure Tree has data on the very first render
            * otherwise defaultExpandedKeys will not work
          */}
          {initialExpanded[0] !== '0000-empty-data' ?
            <Tree
              blockNode
              autoExpandParent
              defaultExpandedKeys={initialExpanded}
              showLine={false}
              treeData={treeData}
              titleRender={getContextItem}
              className="tree-source"
              onSelect={(selectedKeys, { node }) => {
                onNodeClicked(node as any)
              }}
            />
            : <div>Empty data</div>
          }
        </AppContentCard>
      </div>
    </div>
  )
}

export default HistoricalStrategyTreeView
