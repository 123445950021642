import React, { useEffect, useState } from 'react'
import * as Api from 'types/api'
import * as api from 'api/assets'
import { jsonToBrokerAccounts, jsonToBrokerSecurity } from 'services/store/mapService'
import BrokerAccount from 'components/StrategyViewer/forms/AssetForm/BrokerAccount'
import { isApiError } from 'core'
import * as Store from 'types/store'
import { FormInstance } from 'antd'
import { CrudOperation } from 'types/enums'

// const getBrokerSecurities = async (
//   symbol: string,
//   mic: string,
//   currency: string,
// ): Promise<Store.BrokerSecurity[]> => {
//   const response = await api.getBrokerSecurities(symbol, mic, currency)
//   if (isApiError(response)) {
//     return null
//   }

//   console.log('broker security response', response)

//   const masterSecurityResponse = response as Api.BrokerSecurityResponse

//   return jsonToBrokerSecurity(masterSecurityResponse.data.application_broker_security)
// }

type Props = {
  form: FormInstance<any>
  mode: CrudOperation
}

const getBrokerAccounts = async (symbol: string, mic: string, currency: string): Promise<Store.BrokerAccount[]> => {
  const response = await api.getBrokerAccounts(symbol, mic, currency)
  if (isApiError(response)) {
    return null
  }

  const brokerAccountsResponse = response as Api.BrokerAccountResponse

  return jsonToBrokerAccounts(brokerAccountsResponse.data.application_broker_account)
}

const BrokerAccountContainer: React.FC<Props> = ({ form, mode }) => {
  const [data, setData] = useState<any[]>([])
  const [params, setParams] = useState({ symbol: '', mic: '', currency: '' })
  const [brokerAccounts, setBrokerAccounts] = useState<Store.BrokerAccount[]>([])

  useEffect(() => {
    const getData = async () => {
      if (!params.symbol || !params.mic || !params.currency) {
        return
      }

      const data = await getBrokerAccounts(params.symbol, params.mic, params.currency)

      if (data) {
        setData(data)
      }
    }
    getData()
  }, [params.symbol, params.mic, params.currency])

  useEffect(() => {
    // Set form values for broker accounts
    if (brokerAccounts && brokerAccounts.length > 0) {
      form.setFieldValue('brokerAccounts', brokerAccounts)
    }
  }, [brokerAccounts])

  return <BrokerAccount data={data} setParams={setParams} setBrokers={setBrokerAccounts} mode={mode} form={form} />
}

export default BrokerAccountContainer
