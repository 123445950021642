import { LoadingState } from 'types/enums'
import * as K from './constants'
import produce from 'immer'
import { AnyAction } from 'redux'
import * as Store from 'types/store'

// The initial state of the Reducer
export const initialState: Store.SetupSessionPageState = {
  currentNode: { node: null, operation: null },
  assets: null,
  loadingState: LoadingState.Unset,
  brokenEntities: null,
}

const reducer = (state = initialState, action: AnyAction): Store.SetupSessionPageState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case K.SET_CURRENT_NODE:
        draft.currentNode = action.payload as Store.UserAction
        break
      case K.RESET_PAGE:
        draft.currentNode = { node: null, operation: null }
        draft.assets = null
        draft.brokenEntities = null
        break
      case K.UPDATE_ASSETS_SUCCESFULL:
        draft.assets = action.payload as Store.Asset[]
        break
      case K.FETCH_SESSION_BROKEN_ENTITIES_SUCCESS:
        draft.brokenEntities = action.payload as Store.SessionBrokenEntities
        break

      case K.SET_STATUS:
        draft.loadingState = action.payload as LoadingState
        break
    }
  })
}

export default reducer
