import * as K from './constants'
import { put, call } from 'redux-saga/effects'
import Debug from 'debug'
import * as Api from 'types/api'
import * as api from 'api/groups'
import { ErrorCode } from 'types/enums'
import { ApplicationError, isApiError, isGraphQlError } from 'core'
import { jsonToGroups } from 'services/store/mapService'

const debug = Debug('Frontend')

export function* fetchGroups() {
  debug('Saga Fetch Groups')
  const response = yield call(api.getGroups)
  if (isApiError(response) || isGraphQlError(response)) {
    debug('fetchGroups failed', response, response as ApiError)
    throw new ApplicationError(ErrorCode.Api, 'fetchGroups failed')
  }
  
  const json = (response as Api.GroupsResponse).data.application_group
  
  const groups = jsonToGroups(json)
  debug(`Loaded ${groups.length} Groups`)

  // save in redux
  yield put({ type: K.GROUPS_FETCH_SUCCESS, payload: groups })
}
