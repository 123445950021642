import React, { useEffect, useMemo, useState } from 'react'
import { StrategyTransfer } from 'components/StrategyTransfer'
import { ExtendedDataNode } from 'types/ui'
import { useSelector } from 'react-redux'
import { selectOperativeSessionAssets, selectOperativeSessionSession } from 'store/pages/selectors'
import { selectAssets, selectResources } from 'store/resources/selectors'
import { selectDashboardEntityFilter } from 'store/dashboard/selectors'
import { useTranslation } from 'react-i18next'
import { AssetStatus, TransferContext } from 'types/enums'
import * as Store from 'types/store'
import { generateResourceNewTree, generateResourceTree } from 'services/treeService'
import { isFilteredLayout } from 'services/dashboardService'

interface Props {
  setAssets: (assets: Store.Asset[]) => void
  selectedAssets?: Store.Asset[]
  shouldFilterAssets?: boolean
}

const SessionAssetsTransfer: React.FC<Props> = ({ setAssets, selectedAssets, shouldFilterAssets }) => {
  const { t } = useTranslation()

  const { portfolios, strategies, groups } = useSelector(selectResources)
  const sessionAssets = useSelector(selectOperativeSessionAssets)
  const session = useSelector(selectOperativeSessionSession)
  const filtered =
    isFilteredLayout(useSelector(selectDashboardEntityFilter), session.type) || !shouldFilterAssets
  const assetsAll = useSelector(selectAssets)

  const userAssets = useMemo(
    () =>
      assetsAll.filter((asset) => !asset.associationUserId || asset.associationUserId === session?.ownerId),
    [session, assetsAll],
  )

  const userGroups = useMemo(() => {
    return groups.data.filter(group => !group.userId || group.userId === session?.ownerId)
  }, [session])


  const combinedAssets = userAssets.map((asset) => {
    const status =
      sessionAssets.find((sessionAsset) => asset.id === sessionAsset.id)?.status || AssetStatus.NotDefined

    return {
      ...asset,
      status,
    }
  })

  const filteredAssets = filtered ? sessionAssets : combinedAssets

  const filteredGroups = userGroups.filter((group) => {
    return filteredAssets.some((asset) => group.assetIds.includes(asset.id))
  })

  // const filteredPortfolios = portfolios.data.filter((portfolio) => {
  //   return filteredGroups.some((asset) => portfolio.id === asset.portfolioId)
  // })

  // const filteredStrategies = strategies.data.filter((strategy) => {
  //   return filteredPortfolios.some((portfolio) => portfolio.strategyId === strategy.id)
  // })

  // JI-TRANSFER MODEL STATUS TRANSFER (e.g. map view)
  // console.log('model status strategy transfer')

  const [filter, setFilter] = useState<string>(null)
  const [tree, setTree] = useState<Array<ExtendedDataNode>>()

  useEffect(() => {
    const tree = generateResourceNewTree(
      strategies.data,
      portfolios.data,
      filteredGroups,
      filteredAssets,
      t('transfer.allStrategies'),
      filter,
    )
    setTree(tree)
  }, [filter, sessionAssets])

  const getSelectedItems = (data: ExtendedDataNode[], selectedNodes: ExtendedDataNode[] = []) => {
    if (!data) {
      return
    }
    data.map((item) => {
      if (item.selected) {
        selectedNodes.push(item)
      }
      getSelectedItems(item?.children, selectedNodes)
    })

    return selectedNodes
  }

  const onDataChanged = (data: ExtendedDataNode[]) => {
    const selectedItems = getSelectedItems(data)
    const assets = selectedItems.map((item) => item.resource) as Store.Asset[]
    if (filter) {
      const filteredSelected = selectedAssets.filter(
        (item) => !item.name.toUpperCase().includes(filter.toUpperCase()),
      )
      const items = [...assets, ...filteredSelected]
      setAssets(items)
    } else {
      setAssets(assets)
    }
  }

  if (!tree) {
    return null
  }

  const onFilterChange = (newFilter: string) => {
    setFilter(newFilter)
  }

  const defaultKeys = selectedAssets ? selectedAssets.map((item) => `asset-${item.id}`) : []

  return (
    <StrategyTransfer
      treeData={tree}
      onDataChanged={onDataChanged}
      context={TransferContext.OnlyAssets}
      onFilterChange={onFilterChange}
      defaultSelected={defaultKeys}
    />
  )
}

export default SessionAssetsTransfer
