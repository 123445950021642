import React, { useContext } from 'react'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
import { useMeasure } from 'react-use'
import HistoricalCandleStickContainer from 'v2/containers/CandleStick/historical/Strategy'
import StrategyCandleStickContainer from 'v2/containers/CandleStick/Strategy'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import { useSelector } from 'react-redux'

const CandlestickView: React.FC = () => {
  const context = useContext(CandleStickFrameContext)
  const session = useSelector(selectOperativeSessionSession)
  const { timeFrame } = context
  const [ref, { width, height }] = useMeasure()

  const isSmall = () => {
    if (width < 50 || height < 50) {
      return true
    }

    return false
  }

  const getCandleStick = () => {
    if (session.isHistorical) {
      return <HistoricalCandleStickContainer key={timeFrame} width={width} height={height} />
    }
    return <StrategyCandleStickContainer key={timeFrame} width={width} height={height} />
  }

  return (
    <section className="candlestick-view nonDraggable" ref={ref}>
      {!isSmall() && getCandleStick()}
    </section>
  )
}

export default CandlestickView
