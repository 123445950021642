import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLoadingState } from 'store/pages/admin/actions'
import { LoadingState } from 'types/enums'
import { useTranslation } from 'react-i18next'
import { displayNotification } from 'core/helpers'
import { selectLoadingState, selectErrorCode } from 'store/pages/admin/selectors'

const NotificationManager: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const watchUsers = () => {
    const loadingState = useSelector(selectLoadingState)
    const errorCode = useSelector(selectErrorCode)
    useEffect(() => {
      displayNotification(loadingState, errorCode)
      if (loadingState !== LoadingState.Unset && loadingState !== LoadingState.LoadFailure) {
        dispatch(setLoadingState(LoadingState.Unset))
      }
    }, [dispatch, t, loadingState])
  }

  watchUsers()

  return null
}

export default NotificationManager
