import React from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { themes } from './themes'
import { useTheme } from 'providers/ThemeProvider'

const StrategyWrapper: React.FC = ({ children }) => {
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const strategyClass = classNames(styles['strategies'], 'strategies', themeStyles['theme'])

  return <section className={strategyClass}>{children}</section>
}

export default StrategyWrapper
