import ModelParameters from 'components/StrategyViewer/forms/AssetForm/ModelParameters'
import React, { useEffect, useState } from 'react'
import * as api from 'api/assets'
import * as Store from 'types/store'
import * as Api from 'types/api'
import { isApiError } from 'core'
import { CrudOperation } from 'types/enums'
import { jsonHistoricalToModelParameters, jsonToModelParameters } from 'services/store/mapService'
import { PathMatch, useMatch } from 'react-router-dom'
import routes from 'config/routes'

const getModelParameters = async (
  id: number,
  historicalMatch: PathMatch<string>,
): Promise<Store.ModelParameter[]> => {
  if (historicalMatch) {
    const { id } = historicalMatch.params
    const response = await api.getHistoricalModelParameters(parseInt(id))
    if (isApiError(response)) {
      return null
    }

    const modelParameters = response as Api.ModelParametersHistoricalResponse
    const parameters = modelParameters.data.historical_data_model_asset_parameter_v

    return jsonHistoricalToModelParameters(parameters)
  }
  const response = await api.getModelParameters(id)
  if (isApiError(response)) {
    return null
  }

  const modelParameters = response as Api.ModelParametersResponse
  const parameters = modelParameters.data.application_model_class_parameter

  return jsonToModelParameters(parameters)
}

interface Props {
  modelClassId: number
  assetModel: number
  mode: CrudOperation
  params: Store.ModelParameter[]
}

const ModelParametersContainer: React.FC<Props> = ({ modelClassId, assetModel, mode, params }) => {
  const [modelParams, setModelParams] = useState<Store.ModelParameter[]>([])
  const match = useMatch(routes.historicalSetup.url)

  useEffect(() => {
    const getData = async () => {
      const data = await getModelParameters(modelClassId, match)
      if (data) {
        setModelParams(data)
      }
    }

    if (params && params.length !== 0 && assetModel === modelClassId) {
      setModelParams(params)
    } else {
      getData()
    }
  }, [modelClassId, params])

  return <ModelParameters params={modelParams} mode={mode} />
}

export default ModelParametersContainer
