import React from 'react'
import routes from 'config/routes'
import { Link } from 'react-router-dom'
import { Typography } from 'antd'
import { getUserInitials } from 'core/profileHelpers'
import * as Store from 'types/store'

interface Props {
  user: Store.SystemUser
}

const ProfileIcon: React.FC<Props> = ({ user }) => {
  const initials = getUserInitials(user)
  return (
    <Link to={routes.profile.url}>
      <h1 className="username-item">
        <Typography.Text>{initials.toUpperCase()}</Typography.Text>
      </h1>
    </Link>
  )
}

export default React.memo(ProfileIcon)
