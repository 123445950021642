import { config } from 'core'

export const FULL_DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss'

export const numberFormatter = (value: string | number) => {
  const n = parseFloat(value as string)
  if (isNaN(n)) {
    return ''
  }

  return n.toLocaleString(config.language)
}

//https://codesandbox.io/s/currency-wrapper-antd-input-3ynzo
export const numberParser = (value: string): string => {
  try {
    // for when the input gets clears
    if (!value.length) {
      return ''
    }

    const group = config.locale.getGroupSeparator()
    const decimal = config.locale.getDecimalSeparator()
    const reversedVal = value
      .replace(new RegExp('\\' + group, 'g'), '')
      .replace(new RegExp('\\' + decimal, 'g'), '.')
    return reversedVal
  } catch (error) {
    return ''
  }
}

/**
 * Round half up ('round half towards positive infinity')
 * Uses exponential notation to avoid floating-point issues.
 * Negative numbers round differently than positive numbers.
 */
//https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
export function round(num: number, decimalPlaces = 0) {
  const p = Math.pow(10, decimalPlaces)
  const m = Number((Math.abs(num) * p).toPrecision(15))
  return (Math.round(m) / p) * Math.sign(num)
}

//Format number like:
//100000 -> '100,000.00'
//0.123 -> '0.1230'
export function formatNumber(value: number): string {
  if (!value && value !== 0) {
    return ''
  }

  //avoid round issues with very small values, for example 10e-14
  const value2 = Math.abs(value) < 50e-6 ? 0 : value

  if (value2 > -1 && value2 < 1) {
    return value2.toLocaleString(config.language, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 4,
    })
  }
  return value2.toLocaleString(config.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
}

export function toSeconds(microseconds) {
  if (typeof microseconds === 'number') {
    return microseconds * 1e-6
  }
  return microseconds
}

export function formatGliphNumber(value: number): string {
  let multiplier: number
  const absValue: number = Math.abs(value)

  multiplier = Math.pow(10, 9)
  if (absValue >= multiplier) {
    return `${formatNumber(value / multiplier)} B`
  }

  multiplier = Math.pow(10, 6)
  if (absValue >= multiplier) {
    return `${formatNumber(value / multiplier)} M`
  }

  multiplier = Math.pow(10, 3)
  if (absValue >= multiplier) {
    return `${formatNumber(value / multiplier)} K`
  }

  return formatNumber(value)
}

//95 -> '95.00%'
export function formatPercent(value: number): string {
  if (!value) {
    return ''
  }
  const percent = value * 100
  return percent.toLocaleString(config.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
}
