import * as K from './constants'
import { put, call, takeLatest, all } from 'redux-saga/effects'
import Debug from 'debug'
import * as Api from 'types/api'
import * as api from 'api/historicalResources'
import { ErrorCode, LoadingState } from 'types/enums'
import { ApplicationError, isApiError, isGraphQlError } from 'core'
import { jsonToPortfolios } from 'services/store/mapHistoricals'
import { AnyAction } from 'redux'

const debug = Debug('Frontend')

export const getPortfolios = (state) => state.root.resources.portfolios

export function* fetchPortfolios(action: AnyAction) {
  debug('Fetch Historical Portfolios')
  const sessionId = action.payload as number
  yield put({ type: K.SET_STATUS, payload: LoadingState.Loading })
  const response = yield call(api.getPortfolios, sessionId)
  if (isApiError(response) || isGraphQlError(response)) {
    throw new ApplicationError(ErrorCode.Api, 'fetch getPortfolios failed')
  }

  const json = (response as Api.HistoricalPortfoliosResponse).data.historical_data_portfolio_v

  const portfolios = jsonToPortfolios(json)
  debug(`Loaded ${portfolios.length} Portfolios`)

  yield put({ type: K.PORTFOLIOS_FETCH_SUCCESS, payload: portfolios })
}

function* watch() {
  yield all([takeLatest(K.HISTORICAL_PORTFOLIOS_FETCH, fetchPortfolios)])
}

export default watch
