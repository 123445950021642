import axiosBase from 'axios'
import { config } from 'core'
import { getAuthToken } from 'core/localStore'
import * as Api from 'types/api'
import { AuthResponse } from 'types/api'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'

//this axios has interceptors for refreshToken
const axios = getAxios()

export async function login({ username, password }: Api.LoginRequest): Promise<AuthResponse> {
  const data = new URLSearchParams()
  data.append('email', username)
  data.append('password', password)

  return axiosBase
    .post(config.api.login(), data, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export const getAuthenticatedUserDetails = async (): Promise<Api.FetchUserResponse> => {
  const header = await getAuthenticationHeader()

  const accessToken = await getAuthToken()
  header.headers['Content-Type'] = 'application/x-www-form-urlencoded'

  const data = new URLSearchParams()
  data.append('access_token', accessToken)

  return axios
    .post(config.api.getAuthenticatedUserDetails(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export const changePassword = async (id: number, data: Json): Promise<Api.FetchUserResponse> => {
  const header = await getAuthenticationHeader()

  return axios
    .put(config.api.changePassword(id), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export const resetPasswordRequest = async (email: string): Promise<Api.RESTResponse | ApiError> => {
  const header = {
    headers: {
      Origin: config.apiBaseUrl,
    },
  }

  return axiosBase
    .post(config.api.resetPassword(), { email }, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export const resetPasswordRequestStep2 = async (data: Json): Promise<Api.RESTResponse | ApiError> => {
  return axiosBase
    .put(config.api.resetPasswordStep2(), data)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}
