import { Modal, Upload, Typography, Button, Row, Spin, Avatar } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import AppContentCard from 'components/AppContentCard'
import UploadIcon from 'assets/icon/upload-icon.svg'
const { Paragraph } = Typography

interface Props {
  loading: boolean
  onClose: (visible: boolean) => void
  onUpload: (options: Json) => void
}

const CsvModal: React.FC<Props> = ({ loading, onClose, onUpload }) => {
  const { t } = useTranslation()
  return (
    <>
      <Modal open okButtonProps={{ style: { display: 'none' } }} closable={false} footer={null}>
        <AppContentCard>
          <Spin spinning={loading}>
            <Upload.Dragger
              showUploadList={false}
              name="file"
              multiple={false}
              accept=".csv"
              customRequest={onUpload}
              beforeUpload={(file) => file.name.endsWith('.csv')}
            >
              <Avatar src={UploadIcon} shape="square" size={70} />

              <Paragraph>{t(!loading ? 'marketData.dragAndDrop' : 'marketData.uploading')}</Paragraph>
            </Upload.Dragger>
          </Spin>
          <Row justify="end" className="mt-30">
            <Button type="primary" onClick={() => onClose(false)} loading={loading}>
              {t('general.ok')}
            </Button>
          </Row>
        </AppContentCard>
      </Modal>
    </>
  )
}

export default CsvModal
