import React, { useState } from 'react'
import * as Store from 'types/store'
import { Button, Col, Form, Input, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import ModelsTable from './ModelsTable'
import { CrudOperation } from 'types/enums'
const { Paragraph, Text } = Typography

interface Props {
  data: Store.ModelClass[]
  modelClass: Store.ModelClass
  onSelect: (id: number) => void
}

export const MultiModelForm: React.FC<Props> = ({ data, onSelect, modelClass }) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)

  // TODO: and add exeption to tell 'no model assosciated for this group'

  return (
    <>
      <ModelsTable visible={showModal} data={data} onCancel={() => setShowModal(false)} onSelect={onSelect} resetButton={false} />
      <Row align="middle" gutter={[5, 0]} justify="center">
        <Col className="mb-12">
          <Button block onClick={() => setShowModal(true)} type="primary">
            Multi-Asset Analysis Model Association
          </Button>
        </Col>
      </Row>

      <Row align="middle" gutter={[5, 0]}>
        <Col span={12}>
          <Paragraph type="secondary">{t('assets.modelName')}</Paragraph>
        </Col>
        <Col span={12}>
          <Paragraph ellipsis={{ rows: 1, tooltip: modelClass?.modelName }}>
            {modelClass?.modelName}
          </Paragraph>
        </Col>
      </Row>
      <Row align="middle" gutter={[5, 0]}>
        <Col span={12}>
          {/* <Paragraph>{t('table.column.version')}</Paragraph> JI-TRANSLATE */}
          <Paragraph type="secondary">Model Version</Paragraph>
        </Col>
        <Col span={12}>
          <Paragraph ellipsis={{ rows: 1, tooltip: modelClass?.version }}>{modelClass?.version}</Paragraph>
        </Col>
      </Row>
      <Row align="middle" gutter={[5, 0]}>
        <Col span={12}>
          <Paragraph type="secondary">{t('assets.modelType')}</Paragraph>
        </Col>
        <Col span={12}>
          <Paragraph ellipsis={{ rows: 1, tooltip: modelClass?.modelType }}>
            {modelClass?.modelType}
          </Paragraph>
        </Col>
      </Row>
      <Row align="middle" gutter={[5, 0]}>
        <Col span={12}>
          <Paragraph type="secondary">{t('assets.modelLanguage')}</Paragraph>
        </Col>
        <Col span={12}>
          <Paragraph ellipsis={{ rows: 1, tooltip: modelClass?.modelLanguage }}>
            {modelClass?.modelLanguage}
          </Paragraph>
        </Col>
      </Row>
      <Row align="middle" gutter={[5, 0]}>
        <Col span={12}>
          <Paragraph type="secondary">{t('assets.modelDescription')}</Paragraph>
        </Col>
        <Col span={12}>
          <Paragraph ellipsis={{ rows: 1, tooltip: modelClass?.modelDescription }}>
            {modelClass?.modelDescription}
          </Paragraph>
        </Col>
      </Row>
    </>
  )
}
