import { SessionType } from 'types/enums'
import * as K from './constants'
import * as Store from 'types/store'
import * as UI from 'types/ui'
import { AnyAction } from 'redux'

// R.M 27/01/2022
// https://github.com/react-grid-layout/react-grid-layout/pull/1156
/*
 * as immer freezes properties of the object by default we get error when we change object in grid was resulting error
 * workaround is to disable the freeze
 */

// The initial state of the Reducer
export const initialState: Store.DashboardState = {
  id: null,
  frames: [],
  isDirty: false,
  isDragging: false,
  resizedFrame: null,
  entityFilter: false,
  height: null,
  guidelines: {
    resolutions: null,
    active: true,
  },
}

const reducer = (state = initialState, action: AnyAction): Store.DashboardState => {
  switch (action.type) {
    case K.CLEAR_LAYOUT:
      return initialState

    case K.LOAD_LAYOUT: {
      const {
        id,
        configuration: { frames, entityFilter },
      } = action.payload as Store.Layout
      return { ...state, frames, id, entityFilter }
    }

    case K.SET_DIRTY: {
      const isDirty = action.payload as boolean
      return { ...state, isDirty }
    }

    case K.SET_DASHBOARD_HEIGHT: {
      const height = action.payload as number
      return { ...state, height }
    }

    case K.SET_DRAGGING: {
      const isDragging = action.payload as boolean
      return { ...state, isDragging }
    }

    case K.SET_RESIZING: {
      const resizedFrame = action.payload as string
      return { ...state, resizedFrame }
    }

    case K.CREATE_FRAME: {
      const frame = action.payload as Store.Frame
      const frames = [...state.frames, frame]
      return { ...state, isDirty: true, frames }
    }

    case K.UPDATE_FRAME_OPTIONS: {
      const { id, options } = action.payload as Store.FrameOptionsChange

      const f: Store.Frame = state.frames.find((item) => item.id === id)
      const frame = { ...f, options }

      //Delete previous
      const frames = state.frames.filter((item) => item.id !== id)

      //Add updated
      frames.push(frame)

      return { ...state, frames, isDirty: true }
    }
    case K.REMOVE_FRAME: {
      const { id } = action.payload as Store.Frame

      //Delete previous
      const frames = state.frames.filter((item) => item.id !== id)

      return { ...state, frames, isDirty: true }
    }
    case K.REMOVE_FRAMES_BY_SESSION_TYPE: {
      const sessionType = action.payload as SessionType
      const frames = state.frames.filter((item) => item.sessionType !== sessionType)

      return { ...state, frames }
    }
    case K.SET_FILTER_ENTITIES: {
      const entityFilter = action.payload as boolean
      return { ...state, entityFilter }
    }

    case K.LAYOUT_CHANGED: {
      const frames = action.payload as Store.Frame[]
      return { ...state, ...{ frames, isDirty: true } }
    }

    case K.SAVE_RESOLUTIONS_SUCCESS: {
      const resolutions = action.payload as UI.Resolution[]
      return { ...state, guidelines: { ...state.guidelines, resolutions } }
    }

    case K.SET_GUIDELINE_ACTIVE: {
      const active = action.payload as boolean

      return { ...state, guidelines: { ...state.guidelines, active } }
    }

    default:
      return state
  }
}

export default reducer
