import React from 'react'
import { SessionStatus } from 'types/enums'
import BacktestSessionParameters from 'v2/components/dashboard-players/BacktestPlayer/Parameters'
import DahsboardPlayerControls from 'v2/components/dashboard-players/BacktestPlayer/BacktestPlayerControls'
import * as Forms from 'types/form'
import { Control } from 'react-hook-form'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'

import styles from './styles.module.scss'
import { Progress, Button, Avatar, Tooltip, Typography } from 'antd'
import ExpandIcon from 'assets/icon/expand-icon.svg'
import CollapseIcon from 'assets/icon/collapse-icon.svg'
import { useTranslation } from 'react-i18next'
import { themes } from './themes'
import { useTheme } from 'providers/ThemeProvider'

interface Props {
  showParams: boolean
  control: Control<Forms.BacktestPlayer>
  onClick: (status: SessionStatus) => void
  toggleParamsVisibility: () => void
  loading: boolean
  progress: number
  backtestTime: string
}

const DashboardPlayerContainer: React.FC<Props> = ({
  showParams,
  control,
  onClick,
  toggleParamsVisibility,
  loading,
  progress,
  backtestTime,
}) => {
  const session = useSelector(selectOperativeSessionSession)
  const { t } = useTranslation()
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const playerClass = classNames(styles['dashboard-player'], themeStyles['theme'])

  return (
    <section className={playerClass}>
      {showParams && <BacktestSessionParameters session={session} control={control} loading={loading} />}

      <div className="controllers-container">
        {(session.status === SessionStatus.Running || session.isHistorical) && (
          <div className="progress-bar-wrapper">
            <Tooltip
              title={
                <>
                  <Typography.Paragraph>
                    {t('operativeSession.estimatedProgress', {
                      progress,
                    })}
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    {t('operativeSession.estimatedBacktestTime', {
                      backtestTime,
                    })}
                  </Typography.Paragraph>
                </>
              }
            >
              <Progress
                strokeWidth={5}
                strokeColor={'#00ffb1'}
                trailColor={'#989eac'}
                percent={progress}
                showInfo={false}
              />
            </Tooltip>
          </div>
        )}

        <DahsboardPlayerControls onClick={onClick} session={session} loading={loading} />

        <Button
          className={classNames('toggle-btn', { 'toggle-btn--expanded': showParams })}
          onClick={toggleParamsVisibility}
        >
          <Avatar shape="square" src={showParams ? CollapseIcon : ExpandIcon} size={15} />
        </Button>
      </div>
    </section>
  )
}

export default DashboardPlayerContainer
