import { useForm } from 'antd/lib/form/Form'
import { selectPortfoliosState } from 'store/resources/selectors'
import React from 'react'
import * as Api from 'types/api'
import * as Store from 'types/store'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingState } from 'types/enums'
import { createPortfolio, setLoadingState } from 'store/resources/portfolios/actions'
import PortfolioForm from 'components/StrategyViewer/forms/PortfolioForm'
import { resetUserAction } from 'store/pages/strategy/actions'

interface Props {
  strategy: Store.Strategy
}

const CreatePortfolio: React.FC<Props> = ({ strategy }) => {
  const dispatch = useDispatch()
  const loadingState = useSelector(selectPortfoliosState)
  const [form] = useForm()

  const onCancel = () => {
    dispatch(resetUserAction())
    dispatch(setLoadingState(LoadingState.Unset))
  }

  const onFinish = (data: Json) => {
    const postData: Api.PortfolioRest = {
      id: null,
      name: data.name,
      maxLong: parseInt(data.maxLong),
      maxNetHedging: parseInt(data.maxNetHedging),
      minNetHedging: parseInt(data.minNetHedging),
      maxShort: parseInt(data.maxShort),
      strategyId: strategy.id,
    }

    dispatch(createPortfolio(postData))
  }

  return (
    <PortfolioForm
      form={form}
      loading={loadingState === LoadingState.Creating}
      onFinish={onFinish}
      onCancel={onCancel}
    />
  )
}

export default CreatePortfolio
