import produce from 'immer'
import { LoadingState } from 'types/enums'
import * as K from './constants'
import { AnyAction } from 'redux'
import * as Store from 'types/store'
import { sortResources } from 'core/helpers'
import { ResponseCode } from 'types/enums.api'

export const initialState: Store.GroupState = {
  data: [],
  state: LoadingState.Unset,
  error: null,
}

const reducer = (state = initialState, action: AnyAction) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case K.GROUPS_FETCH_SUCCESS:
        const data = action.payload as Array<Store.Group>
        draft.data = sortResources(data) as Array<Store.Group>
        break

      case K.GROUP_CREATE_SUCCESS:
        {
          const group = action.payload as Store.Group
          draft.data.push(group)
          draft.data = sortResources(draft.data) as Array<Store.Group>
        }
        break

      case K.GROUP_DELETE_SUCCESS:
        {
          const group = action.payload as Store.Group
          const index = draft.data.findIndex((item) => item.id === group.id)

          if (index !== -1) {
            draft.data.splice(index, 1)
          }
        }
        break

      case K.GROUP_UPDATE_SUCCESS:
        {
          const group = action.payload as Store.Group
          const index = draft.data.findIndex((item) => item.id === group.id)
          draft.data[index] = group
          draft.data = sortResources(draft.data) as Array<Store.Group>
        }
        break

        // switch (action.type) {
        //   case K.HISTORICAL_GROUPS_FETCH_SUCCESS:
        //     const data = action.payload as Array<Store.Group>
        //     draft.data = sortResources(data) as Array<Store.Group>
        //     break
        // }

      case K.SET_STATUS:
        draft.state = action.payload as LoadingState
        break

      case K.SET_ERROR:
        draft.error = action.payload
        break
    }
  })
}

export default reducer
