import * as K from './constants'
import { AnyAction } from 'redux'
import * as Store from 'types/store'
import { LoadingState } from 'types/enums'
import produce from 'immer'

// The initial state of the Reducer
export const initialState: Store.AppState = {
  bootstrapLoading: true,
  loadingState: LoadingState.Unset,
  isSocketAlive: null,
}

const reducer = (state = initialState, action: AnyAction): Store.AppState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case K.APP_BOOTSTRAP_LOADED:
        draft.bootstrapLoading = false
        break

      case K.SET_LOADING_STATE:
        draft.loadingState = action.payload as LoadingState
        break
      case K.SET_SOCKET_ALIVE:
        const isSocketAlive = action.payload as boolean
        draft.isSocketAlive = isSocketAlive
        break
    }
  })
}

export default reducer
