import React, { useState } from 'react'
import * as Store from 'types/store'
import ChartD3 from 'components/ChartD3'

import CirclePack from 'components/ChartD3/CirclePack'
import { Dropdown } from 'antd'
import { NodeType } from 'types/ui'
import { SelectedResource } from 'types/store'
import { FrameType, SessionStatus, SessionType } from 'types/enums'
import ContextMenu from './ContextMenu'
import BacktestContextMenu from './BacktestContextMenu'

interface Props {
  data: Store.DataTree
  addFrame: (selectedResource: Store.SelectedResource, frameType: FrameType) => void
  sessionType: SessionType
  sessionStatus: SessionStatus
  width: number
  height: number
}

const CircleChart: React.FC<Props> = ({ data, addFrame, sessionType, sessionStatus, width, height }) => {
  const resolution = {
    width: 1000,
    height: 1000,
  }
  const [selectedResource, setSelectedResource] = useState<SelectedResource>()
  const [visible, setVisible] = useState(false)
  const disabledMenu = sessionType === SessionType.BackTest && sessionStatus === SessionStatus.Running
  const onRightClick = (resource: Store.Resource, nodeType: NodeType) => {
    setSelectedResource({
      resource,
      type: nodeType,
    })
  }

  const onEmptyRightClick = () => {
    setSelectedResource({
      resource: null,
      type: NodeType.AllStrategies,
    })
  }

  const handleVisibleChange = (visible: boolean) => {
    if (!disabledMenu) {
      setVisible(visible)
      if (!visible) {
        setSelectedResource(null)
      }
    }
  }

  const onItemClick = (type: FrameType): void => {
    addFrame(selectedResource, type)
    setSelectedResource(null)
    setVisible(false)
  }

  const menu =
    sessionType === SessionType.BackTest ? (
      <BacktestContextMenu selectedResource={selectedResource} onItemClick={onItemClick} />
    ) : (
      <ContextMenu selectedResource={selectedResource} onItemClick={onItemClick} />
    )

  //GG-2021: we must use "onContextMenuCapture" and check if event has been already catch.
  //Using "onContextMenu" will lead to unwanted behaviour since we cannot do a stopPropagation

  return (
    <Dropdown
      overlay={menu}
      trigger={['contextMenu']}
      getPopupContainer={() => document.querySelector('.draggable-grid-section')}
      onVisibleChange={handleVisibleChange}
      visible={visible}
    >
      <section
        onContextMenuCapture={(e) => {
          if (e.defaultPrevented) {
            return
          }
          onEmptyRightClick()
        }}
      >
        <ChartD3
          height={height ? height : resolution.height}
          width={width ? width : resolution.width}
          isZoomEnabled
        >
          <CirclePack data={data} padding={15} onRightClick={onRightClick} />
        </ChartD3>
      </section>
    </Dropdown>
  )
}

export default CircleChart
