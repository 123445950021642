import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import * as Store from 'types/store'
import * as UI from 'types/ui'
import { removeFrame, updateFrameOptions } from 'store/dashboard/actions'
import { GridItemActions } from 'types/ui'
import PortfolioCandleStick from 'v2/components/frames/PortfolioCandleStick'
import { CandleStickFrameContext, CandleStickFrameContextInterface } from 'contexts/CandleStickFrameContext'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import AddFrameModalContainer from 'v2/containers/settings/modals/AddFrameModal'
import { selectPortfolios, selectStrategies } from 'store/resources/selectors'
import Unavaliable from 'v2/components/frames/Unavailable'
import { isApiError, isGraphQlError } from 'core'
import * as Api from 'types/api'
import * as api from 'api/historicalResources'
import { mapHistoricaPortfolioSecurityRefData } from 'services/store/mapHistoricals'

interface Props {
  frame: Store.Frame
}

const getCartesianHeaderData = async (
  portfolioId: number,
  sessionId: number,
): Promise<Partial<Store.PortfolioSigmas>> => {
  const response = await api.getPortfolioHeaderData(portfolioId, sessionId)
  if (isApiError(response) || isGraphQlError(response)) {
    return null
  }

  const json = response as Api.HistoricalPortfolioHeaderDataResponse

  const portfolioRefData = json.data.historical_data_prt_cartesian_graph_indicators_v[0]

  const refData = portfolioRefData && mapHistoricaPortfolioSecurityRefData(portfolioRefData)

  return {
    refData,
  }
}

const getResource = (frame: Store.Frame): Store.Portfolio => {
  if (frame.selectedResources.length === 0) {
    return null
  }
  return frame.selectedResources[0].resource as Store.Portfolio
}

const PortfolioCandleStickContainer: React.FC<Props> = ({ frame }) => {
  const {
    options: { timeFrame, viewType },
  } = frame
  const dispatch = useDispatch()
  const [trace, setTrace] = useState(false)
  const [isFullScreen, setFullScreen] = useState(false)
  const [resource, setResource] = useState<Store.Portfolio>(getResource(frame))
  const [isModalVisible, setModalVisible] = useState(false)
  const strategies = useSelector(selectStrategies)

  const [sigmas, setSigmas] = useState<Store.PortfolioSigmas>(null)

  useEffect(() => {
    const getData = async () => {
      const data = await getCartesianHeaderData(resource.id, session.id)
      if (data) {
        setSigmas(data as Store.PortfolioSigmas)
      }
    }
    if (resource && session.endTime) {
      getData()
    }
  }, [resource])

  const portfolios = useSelector(selectPortfolios)

  useEffect(() => {
    const resource = getResource(frame)
    setResource(resource)
  }, [frame.selectedResources])

  const getHasMissingResource = () => {
    const portfolio = portfolios.find((portfolio) => portfolio.id === resource.id)
    const strategy = strategies.find((strategy) => strategy.id === portfolio?.strategyId)
    if (!portfolio || !strategy) {
      return true
    }

    return false
  }

  const onClose = () => {
    setModalVisible(false)
  }

  const session = useSelector(selectOperativeSessionSession)

  const _setTimeFrame = (timeFrame) => {
    const options = { ...frame.options, timeFrame }
    dispatch(updateFrameOptions({ id: frame.id, options }))
  }

  const setViewType = (viewType: UI.ViewType) => {
    const options = { ...frame.options, viewType }
    dispatch(updateFrameOptions({ id: frame.id, options }))
  }

  const context: CandleStickFrameContextInterface = {
    id: frame.id,
    squares: {
      x: frame.layout.w,
      y: frame.layout.h,
    },
    isFullscreen: isFullScreen,
    trace,
    timeFrame,
    viewType,
    resource,
    isOffline: () => false,
    setTimeFrame: _setTimeFrame,
    setTrace,
    setViewType,
    setFullScreen: setFullScreen,
    setOffline: () => null,
  }

  const onItemClick = (action: GridItemActions) => {
    switch (action) {
      case GridItemActions.Close:
        dispatch(removeFrame(frame))
        break
      case GridItemActions.Trace:
        setTrace(!context.trace)
        break
      case GridItemActions.Edit:
        setModalVisible(true)
        break
    }
  }

  if (getHasMissingResource()) {
    return <Unavaliable onItemClick={onItemClick} title={resource.name} />
  }

  return (
    <>
      <CandleStickFrameContext.Provider value={context}>
        <PortfolioCandleStick
          onItemClick={onItemClick}
          sigmas={sigmas}
          key={resource?.name}
          session={session}
        />
      </CandleStickFrameContext.Provider>
      <AddFrameModalContainer
        initialFrame={frame}
        visible={isModalVisible}
        onClose={onClose}
        initialX={0}
        initialY={0}
      />
    </>
  )
}

export default PortfolioCandleStickContainer
