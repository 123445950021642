import * as K from './constants'
import * as Groups_K from '../groups/constants'
import { put, call, takeLatest, all } from 'redux-saga/effects'
import Debug from 'debug'
import * as api from 'api/historicalResources'
import { ErrorCode } from 'types/enums'
import { ApplicationError, isApiError, isGraphQlError } from 'core'
import { jsonToGroupedHistoricalAssets, jsonToHistoricalAssets } from 'services/store/mapHistoricals'
import { AnyAction } from 'redux'

const debug = Debug('Frontend')

// export function* fetchHistoricalGroupedAssets(action: AnyAction) {
//   debug('Saga Fetch Historical Assets')

//   const sessionId = action.payload as number
//   const response = yield call(api.getHistoricalGroupedAssets, sessionId)
//   if (isApiError(response) || isGraphQlError(response)) {
//     debug('fetchHistoricalGroupedAssets failed', response, response as ApiError)
//     throw new ApplicationError(ErrorCode.Api, 'fetchHistoricalGroupedAssets failed')
//   }

//   const json = response.data.historical_data_group_v
  
//   const historicalResources = jsonToGroupedHistoricalAssets(json)

//   if (historicalResources.groups.length > 0) {
//     debug(`Loaded ${historicalResources} Resources`)
//     // save in redux
//     yield put({ type: K.ASSETS_FETCH_SUCCESS, payload: historicalResources.assets })
//     yield put({ type: Groups_K.GROUPS_FETCH_SUCCESS, payload: historicalResources.groups })

//   } else {
//     // Fetch assets only if there's no groups inside historical_data_group_v
//     const onlyAssetsRes = yield call(api.getHistoricalAssets, sessionId)

//     if (isApiError(response) || isGraphQlError(response)) {
//       debug('fetchHistoricalAssets failed', response, response as ApiError)
//       throw new ApplicationError(ErrorCode.Api, 'fetchHistoricalAssets failed')
//     }

//     const json = onlyAssetsRes.data.historical_data_asset_v

//     const historicalAssets = jsonToHistoricalAssets(json)
//     // Save in Redux
//     yield put({ type: K.ASSETS_FETCH_SUCCESS, payload: historicalAssets })
//   }
  
// }

export function* fetchHistoricalGroupedAndPlainAssets(action: AnyAction) {
  debug('Saga Fetch Historical Assets')

  const sessionId = action.payload as number
  // Fetch historical groups (actually groups and their assets)
  const historicalGroupsRes = yield call(api.getHistoricalGroupedAssets, sessionId)

  if (isApiError(historicalGroupsRes) || isGraphQlError(historicalGroupsRes)) {
    debug('fetchHistoricalGroupedAssets failed', historicalGroupsRes, historicalGroupsRes as ApiError)
    throw new ApplicationError(ErrorCode.Api, 'fetchHistoricalGroupedAssets failed')
  }

  // Fetch historical assets
  const historicalAssetsRes = yield call(api.getHistoricalAssets, sessionId)

  if (isApiError(historicalAssetsRes) || isGraphQlError(historicalAssetsRes)) {
    debug('fetchHistoricalGroupedAssets failed', historicalAssetsRes, historicalAssetsRes as ApiError)
    throw new ApplicationError(ErrorCode.Api, 'fetchHistoricalGroupedAssets failed')
  }

  const jsonGroups = historicalGroupsRes.data.historical_data_group_v
  const jsonAssets = historicalAssetsRes.data.historical_data_asset_v
  
  const historicalResources = jsonToGroupedHistoricalAssets(jsonGroups)
  const historicalAssets = jsonToHistoricalAssets(jsonAssets)

  debug(`Loaded ${historicalResources} Resources`)
  // Save in redux
  yield put({ type: K.ASSETS_FETCH_SUCCESS, payload: historicalAssets })
  // Save groups in redux if any
  if (historicalResources.groups.length > 0) {
    yield put({ type: Groups_K.GROUPS_FETCH_SUCCESS, payload: historicalResources.groups })
  }
}

function* watch() {
  yield all([
    // takeLatest(K.HISTORICAL_ASSETS_FETCH, fetchHistoricalGroupedAssets)
    takeLatest(K.HISTORICAL_ASSETS_FETCH, fetchHistoricalGroupedAndPlainAssets)
  ])
}

export default watch
