import { AnyAction } from 'redux'
import * as Api from 'types/api'
import { LoadingState } from 'types/enums'
import * as K from './constants'

export function editStrategyAction(form: Api.StrategyRest): AnyAction {
  return {
    type: K.STRATEGY_EDIT,
    payload: form,
  }
}

export function deleteStrategyAction(id: number): AnyAction {
  return {
    type: K.STRATEGY_DELETE,
    payload: id,
  }
}

export function createStrategyAction(form: Api.StrategyRest): AnyAction {
  return {
    type: K.STRATEGY_CREATE,
    payload: form,
  }
}

export function setLoadingState(loadingState: LoadingState): AnyAction {
  return {
    type: K.SET_STATUS,
    payload: loadingState,
  }
}
