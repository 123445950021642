import { Layout } from 'react-grid-layout'
import { FrameType } from 'types/enums'
import RGL from 'react-grid-layout'
import * as Store from 'types/store'

type Size = { width: number; height: number; maxW: number; maxY: number }

const MAX_VALUE = 9999

const defaultSize = {
  width: 9,
  height: 7,
  maxW: MAX_VALUE,
  maxY: MAX_VALUE,
}

export const generateFramesFromLayouts = (layouts: RGL.Layout[], frames: Store.Frame[]): Store.Frame[] => {
  return layouts.map((layout) => {
    const frame = frames.find((frame) => frame.id === layout.i)
    return { ...frame, layout }
  })
}

export const getSizeByFrameType = (type: FrameType): Size => {
  switch (type) {
    case FrameType.Chart:
      return { width: 6, height: 6, maxW: MAX_VALUE, maxY: MAX_VALUE }
    case FrameType.Statistics:
      return { width: 8, height: 9, maxW: MAX_VALUE, maxY: MAX_VALUE }
    case FrameType.StatisticsChartless:
      return { width: 8, height: 9, maxW: 15, maxY: 15 }
    case FrameType.MapView:
      return { width: 6, height: 6, maxW: MAX_VALUE, maxY: MAX_VALUE }
    case FrameType.Custom:
      return { width: 6, height: 6, maxW: MAX_VALUE, maxY: MAX_VALUE }
    case FrameType.ReturnAnalysis:
      return { width: 9, height: 9, maxW: MAX_VALUE, maxY: MAX_VALUE }
    case FrameType.PositionMonitoring:
      return { width: 9, height: 9, maxW: MAX_VALUE, maxY: MAX_VALUE }
    case FrameType.SignalsTable:
      return { width: 8, height: 4, maxW: MAX_VALUE, maxY: MAX_VALUE }
    case FrameType.TechnicalChart:
      return { width: 9, height: 9, maxW: MAX_VALUE, maxY: MAX_VALUE }
    case FrameType.Table:
      return { width: 8, height: 4, maxW: MAX_VALUE, maxY: MAX_VALUE }
    default:
      return defaultSize
  }
}

export const generateLayout = (id: string, type: FrameType, x: number, y: number): Layout => {
  const size = getSizeByFrameType(type)
  return {
    i: id,
    x,
    y,
    w: size.width,
    h: size.height,
    minW: size.width,
    minH: size.height,
    maxW: size.maxW,
    maxH: size.maxY,
    isDraggable: true,
    isResizable: true,
  }
}
