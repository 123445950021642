import React from 'react'
import { useTranslation } from 'react-i18next'

import { Table } from 'antd'
import styles from './styles.module.scss'
import * as Store from 'types/store'
import { formatNumber } from 'core/formats'
import { ColumnsType } from 'antd/lib/table'

interface Props {
  data: Store.StrategyTable[]
  columnKeys: string[]
  baseCurrency: string
}

const StrategyTable: React.FC<Props> = ({ data, columnKeys = [], baseCurrency = '' }) => {
  const { t } = useTranslation()

  // console.log('DATA', data)

  const TABLE_COLUMNS: ColumnsType<Store.StrategyTable> = [
    {
      title: t('table.column.strategyName'),
      dataIndex: 'strategyName',
      width: 199,
      fixed: true,
    },
    {
      title: t('table.column.baseCurrency'),
      dataIndex: 'baseCurrency',
      width: 140,
    },
    {
      title: t('table.column.assets'),
      dataIndex: 'assetsNum',
      width: 140,
    },
    {
      title: t('table.column.portfolios'),
      dataIndex: 'portfoliosNum',
      width: 140,
    },
    {
      title: t('table.column.countervalue', { baseCurrency: baseCurrency }),
      dataIndex: 'value',
      width: 140,
      render: (value: number) => {
        return formatNumber(value)
      },
    },
    {
      title: t('table.column.longCountervalue'),
      dataIndex: 'valueLong',
      width: 140,
      render: (value: number) => {
        return formatNumber(value)
      },
    },
    {
      title: t('table.column.shortCountervalue'),
      dataIndex: 'valueShort',
      width: 140,
      render: (value: number) => {
        return formatNumber(value)
      },
    },
    {
      title: t('table.column.tdyTradingReturn'),
      dataIndex: 'tdyTradingRetBc',
      width: 140,
      render: (value: number) => {
        return formatNumber(value)
      },
    },
    {
      title: t('table.column.tdyExchangeRatesReturn'),
      dataIndex: 'tdyExchRetBc',
      width: 140,
      render: (value: number) => {
        return formatNumber(value)
      },
    },
    {
      title: t('table.column.tdyTotalReturn'),
      dataIndex: 'tdyAllRetBc',
      width: 140,
      render: (value: number) => {
        return formatNumber(value)
      },
    },
    {
      title: t('table.column.ytdTradingReturn'),
      dataIndex: 'ytdTradingRetBc',
      width: 140,
      render: (value: number) => {
        return formatNumber(value)
      },
    },
    {
      title: t('table.column.ytdExchangeRatesReturn'),
      dataIndex: 'ytdExchRetBc',
      width: 140,
      render: (value: number) => {
        return formatNumber(value)
      },
    },
    {
      title: t('table.column.ytdTotalReturn'),
      dataIndex: 'ytdAllRetBc',
      width: 140,
      render: (value: number) => {
        return formatNumber(value)
      },
    },
    {
      title: t('table.column.tdyPositiveOperations'),
      dataIndex: 'tdyPositiveOp',
      width: 140,
    },
    {
      title: t('table.column.tdyNegativeOperations'),
      dataIndex: 'tdyNegativeOp',
      width: 140,
    },
    {
      title: t('table.column.ytdTotalOperations'),
      dataIndex: 'ytdTotalOp',
      width: 140,
    },
    {
      title: t('table.column.ytdPositiveOperations'),
      dataIndex: 'ytdPositiveOp',
      width: 140,
    },
    {
      title: t('table.column.ytdNegativeOperations'),
      dataIndex: 'ytdNegativeOp',
      width: 140,
    },
  ]
  /* eslint-enable */

  const getColumns = (
    defaultColumns: ColumnsType<Store.StrategyTable>,
    selectedColumnsKeys,
  ): ColumnsType<Store.StrategyTable> => {
    let generatedCols = []

    if (selectedColumnsKeys.length) {
      const selectedColumns = defaultColumns.filter((col: any) => selectedColumnsKeys.includes(col.dataIndex))

      generatedCols = [
        {
          title: t('table.column.strategyName'),
          dataIndex: 'strategyName',
          width: 199,
          fixed: true,
        },
        ...selectedColumns,
      ]
    } else {
      generatedCols = defaultColumns
    }

    return generatedCols
  }

  return (
    <section className={`${styles['strategy-table']} strategy-table nonDraggable`}>
      <Table
        rowKey="strategyName"
        columns={getColumns(TABLE_COLUMNS, columnKeys)}
        dataSource={data}
        pagination={false}
        scroll={{ x: 200, y: 60 }}
      />
    </section>
  )
}

export default StrategyTable
