import { Col, Typography, Tooltip } from 'antd'
import colors from 'config/colors'
import { renderAvailableData } from 'core/helpers'
import React from 'react'
import { TimeFrame } from 'types/enums'

import * as Store from 'types/store'

interface Props {
  operations: Store.AssetCartesianOperationsList[]
  timeFrame: TimeFrame
}

const Operations: React.FC<Props> = ({ operations, timeFrame }) => {
  const operation = operations?.find((item) => item.type === timeFrame)

  const isNullOperation = () =>
    !operation ||
    typeof operation.negativeOp === 'undefined' ||
    typeof operation.positiveOp === 'undefined' ||
    (operation.negativeOp === 0 && operation.positiveOp === 0)

  let color1: string
  let color2: string
  let negative: number
  let positive: number

  if (isNullOperation()) {
    color1 = colors.grey
    color2 = colors.grey
    negative = null
    positive = null
  } else {
    color1 = colors.positiveGreen
    color2 = colors.red
    negative = operation.negativeOp
    positive = operation.positiveOp
  }

  const negativeOpPerc = Math.round((negative / (negative + positive)) * 100)
  const positiveOpPerc = 100 - negativeOpPerc

  const svg = (
    <Tooltip
      placement="bottom"
      overlayClassName="tooltip-trades"
      title={
        <div>
          <div className="trade-icon trade-up">
            <Typography.Title level={4}>{renderAvailableData(positiveOpPerc)}%</Typography.Title>
          </div>
          <div className="trade-icon trade-down">
            <Typography.Title level={4}>{renderAvailableData(negativeOpPerc)}%</Typography.Title>
          </div>
        </div>
      }
    >
      <svg viewBox="0 0 36 36">
        <g className="trade-circle trade-circle-tooltip">
          <path
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
            fill="transparent"
            stroke={color2}
            strokeWidth="2.5"
          />

          <path
            d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
            fill="transparent"
            stroke={color1}
            strokeWidth="2.5"
            strokeDasharray={`${positiveOpPerc}, 100`}
          />
        </g>

        <g className="trade-progress">
          <g>
            <g className="trade-arrow" transform="scale(0.015 0.013)">
              <path
                d="M33.124 228.247c1.664 3.989 5.547 6.592 9.856 6.592h116.885v266.666c0 5.888 4.78 10.667 10.667 10.667H341.2c5.888 0 10.667-4.779 10.667-10.667V234.838H469.2c4.31 0 8.192-2.603 9.856-6.571 1.643-3.989.747-8.576-2.304-11.627L263.951 3.136C261.946 1.131 259.236 0 256.4 0s-5.547 1.131-7.552 3.115L35.428 216.619a10.645 10.645 0 00-2.304 11.628z"
                fill={color1}
                data-original="#000000"
                xmlns="http://www.w3.org/2000/svg"
              />
            </g>
            <g transform="translate(9,0)">
              <rect width="13" height="7" rx="2" ry="2" fill="#010522" stroke="#fff" strokeWidth="0.2" />
              <text>{renderAvailableData(positiveOpPerc)}%</text>
            </g>
          </g>

          <g transform="translate(0,10)">
            <g className="trade-arrow" transform="scale(0.015 0.013)">
              <path
                d="M479.046 283.925c-1.664-3.989-5.547-6.592-9.856-6.592H352.305V10.667C352.305 4.779 347.526 0 341.638 0H170.971c-5.888 0-10.667 4.779-10.667 10.667v266.667H42.971a10.702 10.702 0 00-9.856 6.571c-1.643 3.989-.747 8.576 2.304 11.627l212.8 213.504c2.005 2.005 4.715 3.136 7.552 3.136s5.547-1.131 7.552-3.115l213.419-213.504a10.645 10.645 0 002.304-11.628z"
                fill={color2}
                data-original="#000000"
                xmlns="http://www.w3.org/2000/svg"
              />
            </g>
            <g transform="translate(9,0)">
              <rect width="13" height="7" rx="2" ry="2" fill="#010522" stroke="#fff" strokeWidth="0.2" />
              <text>{renderAvailableData(negativeOpPerc)}%</text>
            </g>
          </g>
        </g>
      </svg>
    </Tooltip>
  )

  return (
    <Col className="circle-col">
      <section className="trade-typography tdy-chart-wrapper">
        <section className="tdy-chart">{svg}</section>
      </section>
    </Col>
  )
}

export default Operations
