import analysisIndicators from 'sockets/strategies/strategy_analysis_indicators_pb'
import operations from 'sockets/strategies/strategy_operations_pb'
import position from 'sockets/strategies/strategy_position_pb'
import returns from 'sockets/strategies/strategy_return_pb'
import referenceData from 'sockets/strategies/strategy_reference_data_pb'
import * as Store from 'types/store'
import { StrategyFeed } from 'types/enums.api'
import {
  mapStrategyAnalysisIndicatorsSigmas,
  mapStrategyOperationsSigmas,
  mapStrategyPositionSigmas,
  mapStrategyReferenceDataSigmas,
  mapStrategyReturnsSigmas,
} from 'services/protoMappers/strategy'

export const deserializeStrategyBuffer = (
  buffer: ArrayBuffer,
  service: StrategyFeed,
  subscriptionType: Store.SubscriptionType,
): [string, Store.StrategySigmas] => {
  const bytes = new Uint8Array(buffer)

  switch (service) {
    case StrategyFeed.AnalysisIndicators: {
      const { feed_event } = analysisIndicators as any
      const deserializeData = feed_event.deserializeBinary(bytes)
      const feed = feed_event.toObject(false, deserializeData)
      const data = feed[subscriptionType]
      if (feed.alive || !data) {
        return
      }
      const sigmas = mapStrategyAnalysisIndicatorsSigmas(data)

      return [data.key, sigmas]
    }

    case StrategyFeed.Return: {
      const { feed_event } = returns
      const deserializeData = feed_event.deserializeBinary(bytes)
      const feed = feed_event.toObject(false, deserializeData)
      const data = feed[subscriptionType]
      if (feed.alive || !data) {
        return
      }
      const sigmas = mapStrategyReturnsSigmas(data)
      return [data.key, sigmas]
    }

    case StrategyFeed.Operations: {
      const { feed_event } = operations
      const deserializeData = feed_event.deserializeBinary(bytes)
      const feed = feed_event.toObject(false, deserializeData)
      const data = feed[subscriptionType]
      if (feed.alive || !data) {
        return
      }
      const sigmas = mapStrategyOperationsSigmas(data)
      return [data.key, sigmas]
    }

    case StrategyFeed.Position: {
      const { feed_event } = position
      const deserializeData = feed_event.deserializeBinary(bytes)
      const feed = feed_event.toObject(false, deserializeData)
      const data = feed[subscriptionType]
      if (feed.alive || !data) {
        return
      }
      // Incomplete We don't use these
      const sigmas = mapStrategyPositionSigmas(data)
      return [data.key, sigmas]
    }

    case StrategyFeed.ReferenceData: {
      const { feed_event } = referenceData
      const deserializeData = feed_event.deserializeBinary(bytes)
      const feed = feed_event.toObject(false, deserializeData)
      const data = feed[subscriptionType]
      if (feed.alive || !data) {
        return
      }

      const sigmas = mapStrategyReferenceDataSigmas(data)
      return [data.key, sigmas]
    }
  }
}
