import React from 'react'
import * as Store from 'types/store'

import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { formatNumber } from 'core/formats'
import { useTranslation } from 'react-i18next'

interface Props {
  metrics: Store.SessionMetrics
}

const SessionAnalysisTable: React.FC<Props> = ({ metrics }) => {
  const { t } = useTranslation()
  const columns: ColumnsType<Store.SessionMetrics> = [
    {
      title: t('table.column.sessionName'),
      dataIndex: 'sessionName',
      width: 250,
      fixed: true,
    },
    {
      title: t('table.column.countStrategies'),
      dataIndex: 'countStrategies',
      width: 150,
    },
    {
      title: t('table.column.portfolios'),
      dataIndex: 'countPortfolios',
      width: 150,
    },
    {
      title: t('table.column.assets'),
      dataIndex: 'countAsset',
      width: 150,
    },
    {
      title: t('table.column.activeModels'),
      dataIndex: 'countActiveModels',
      width: 150,
    },
    {
      title: t('table.column.status'),
      dataIndex: 'status',
      width: 150,
    },
    {
      title: t('table.column.ram'),
      dataIndex: 'aggregateMetrics',
      width: 150,
      render: function (data) {
        if (data && data.length > 0) {
          const ramUsage = data[data.length - 1]?.resource?.ram
          return formatNumber(ramUsage) + ' mb'
        }
      },
    },
    {
      title: t('table.column.cpu'),
      dataIndex: 'aggregateMetrics',
      width: 150,
      render: function (data) {
        if (data && data.length > 0) {
          const cpuUsage = data[data.length - 1]?.resource?.cpu
          return formatNumber(cpuUsage) + ' %'
        }
      },
    },
  ]

  return (
    <div className="nonDraggable">
      <Table dataSource={[metrics]} columns={columns} pagination={false} scroll={{ x: 250, y: 73 }} rowKey={o => `${o.sessionName} ${o.countActiveModels}`} />
    </div>
  )
}

export default SessionAnalysisTable
