import { Col, Row, Typography } from 'antd'
import { getTimeFrameLabel, renderAvailableData } from 'core/helpers'
import { formatGliphNumber } from 'core/formats'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TimeFrame } from 'types/enums'
import * as Store from 'types/store'

interface Props {
  timeFrame: TimeFrame
  data: Store.AssetCartesianLastReturn[]
  currency: string
  baseCurrency: string
}

const AssetCartesianLastReturn: React.FC<Props> = ({ timeFrame, data, currency, baseCurrency }) => {
  const { t } = useTranslation()
  const timeFrameData = data && data.find((item) => item.type === timeFrame)
  const tradingRet = timeFrameData?.tradingRet
  const tradingRetBc = timeFrameData?.tradingRetBc

  return (
    <Col className="last-return-col">
      <Row className="last-return-row">
        <Col span={24}>
          <div className="last-return-values">
            <div className="title-wrapper">
              <Typography.Title level={2}>
                {getTimeFrameLabel(timeFrame)} {t('chart.return')}
              </Typography.Title>
            </div>
            <Row className="content-wrapper JI-RENDER-cartesian-last-return">
              <Col span={24}>
                <Typography.Title level={3}>
                  {renderAvailableData(formatGliphNumber(tradingRetBc))}
                  <Typography.Text style={{ paddingLeft: '5px' }}>{currency}</Typography.Text>
                </Typography.Title>
              </Col>
              {currency !== baseCurrency && (
                <Col span={24}>
                  <Typography.Title level={4}>
                    {renderAvailableData(formatGliphNumber(tradingRet))}
                    <Typography.Text style={{ paddingLeft: '5px' }}>{baseCurrency}</Typography.Text>
                  </Typography.Title>
                </Col>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    </Col>
  )
}

export default AssetCartesianLastReturn
