import { update_snapshot, update_ticker } from 'sockets/assets/asset_position_analysis_pb'
import * as Store from 'types/store'
import { convertToFloat } from '../common'

export const mapAssetPositionAnalysisSigmas = (
  feed: update_snapshot.AsObject | update_ticker.AsObject,
): Store.AssetSigmas => {
  const { sigmas, key } = feed
  if (!sigmas) {
    return null
  }

  const { positionAnalysis } = sigmas

  return {
    positionAnalysis: positionAnalysis && {
      id: parseInt(key),
      assetClass: positionAnalysis.assetClass,
      value: convertToFloat(positionAnalysis.countervalueBc) || 0,
      valueLong: convertToFloat(positionAnalysis.countervalueBcLong) || 0,
      valueShort: convertToFloat(positionAnalysis.countervalueBcShort) || 0,
      currency: positionAnalysis.currency,
      market: positionAnalysis.market,
      netHedging: convertToFloat(positionAnalysis.netHedging) || 0,
      quantity: convertToFloat(positionAnalysis.quantity),
      sign: positionAnalysis.sign,
    },
  } as Store.AssetSigmas
}
