import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Button, Menu, Popover } from 'antd'
import ActionBtnIcon from 'assets/icon/actions-icon.svg'
import ActionBtnActiveIcon from 'assets/icon/actions-icon-active.svg'
import { LayoutAction } from 'types/ui'
import * as Store from 'types/store'
import { CrudOperation } from 'types/enums'

interface Props {
  layout: Store.Layout
  onMenuItemClick: (action: LayoutAction) => void
}

const ContextMenu: React.FC<Props> = ({ layout, onMenuItemClick }) => {
  const { t } = useTranslation()
  const [isActive, setIsActive] = useState<boolean>(false)

  const OptionsList = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          onMenuItemClick({ operation: CrudOperation.Update, resource: layout })
        }}
      >
        {t('contextMenu.edit')}
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => {
          onMenuItemClick({ operation: CrudOperation.Delete, resource: layout })
        }}
      >
        {t('contextMenu.delete')}
      </Menu.Item>
    </Menu>
  )

  return (
    <Popover
      overlayClassName="options-popover"
      placement="rightTop"
      content={OptionsList}
      trigger="click"
      getPopupContainer={(trigger) => trigger.parentElement}
      onVisibleChange={() => setIsActive(!isActive)}
      zIndex={1000}
    >
      <Button type="ghost" className="action-btn">
        <Avatar shape="square" src={isActive ? ActionBtnActiveIcon : ActionBtnIcon} size={20} />
      </Button>
    </Popover>
  )
}
export default ContextMenu
