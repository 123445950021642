import { put, takeLatest, all, call, select } from 'redux-saga/effects'
import * as K from './constants'
import { ErrorCode, LoadingState, SessionStatus } from 'types/enums'
import * as api from 'api/sessions'
import * as backtestApi from 'api/backtest'
import Debug from 'debug'
import { ApplicationError, isApiError, isGraphQlError } from 'core'
import * as Store from 'types/store'
import * as Api from 'types/api'
import { AnyAction } from 'redux'
import { fetchOperativeSession } from './helpers'
import { loadFinishedById, refreshSession } from './actions'
import { fetchResources, fetchHistoricalResources } from 'store/App/sagas'
import { jsonToSessions } from 'services/store/mapService'
const debug = new Debug('Frontend')

const selectSessions = (state) => state.root.sessions.data
const selectSessionAssets = (state) => state.root.pages.operativeSession.assets
const selectOperativeSession = (state) => state.root.pages.operativeSession.session

interface OperativeSessionReturn {
  session: Store.Session
  assets: Store.Asset[]
}

export function* loadOperativeSession(action: AnyAction) {
  debug('Saga loadOperativeSession')
  try {
    const sessionId = action.payload as number
    yield put({ type: K.SET_STATUS, payload: LoadingState.Loading })
    const allSessions = yield select(selectSessions)
    const currentSession = allSessions.find((session) => session.id === sessionId)

    if (currentSession.isHistorical) {
      yield call(fetchHistoricalResources, action)
    } else {
      yield call(fetchResources)
    }

    const returnValue: OperativeSessionReturn = yield call(fetchOperativeSession, sessionId)
    // save in redux
    yield put({
      type: K.FETCH_OPERATIVE_SESSION_SUCCESS,
      payload: { session: returnValue.session, assets: returnValue.assets },
    })

    yield put({ type: K.SET_STATUS, payload: LoadingState.Loaded })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.LoadFailure })
    debug('loadOperativeSession Error', error)
  }
}

export function* loadFinishedOperativeSession(action: AnyAction) {
  debug('Saga loadOperativeSession')
  try {
    const sessionId = action.payload as number

    const sessionResponse = yield call(api.getSession, sessionId)

    const sessionJson = (sessionResponse as Api.SessionsResponse).data.application_operative_session
    if (isApiError(sessionResponse) || isGraphQlError(sessionResponse)) {
      throw new ApplicationError(ErrorCode.Api, 'fetch getSessionAssets failed')
    }

    const session = jsonToSessions(sessionJson)[0]

    if (!session.isHistorical) {
      return
    }

    yield call(fetchHistoricalResources, action)

    const returnValue: OperativeSessionReturn = yield call(fetchOperativeSession, sessionId)
    // save in redux
    yield put({
      type: K.FETCH_OPERATIVE_SESSION_SUCCESS,
      payload: { session: returnValue.session, assets: returnValue.assets },
    })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.LoadFailure })
    debug('loadOperativeSession Error', error)
  }
}

//This saga is required for polling
//It should not put loading in the state
export function* refreshOperativeSession(action: AnyAction) {
  debug('Saga refreshOperativeSession')
  try {
    const returnValue: OperativeSessionReturn = yield call(fetchOperativeSession, action.payload as number)
    // save in redux
    yield put({
      type: K.FETCH_OPERATIVE_SESSION_SUCCESS,
      payload: { session: returnValue.session, assets: returnValue.assets },
    })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.LoadFailure })
    debug('refreshOperativeSession Error', error)
  }
}

export function* changeSessionAssetsStatus(action: AnyAction) {
  debug('Saga changeSessionAssetsStatus')
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Updating })
    const { sessionId, assets, status } = action.payload as Store.UpdateSessionAssetsStatusPayload
    const assetIds = assets.map((asset) => asset.id)

    const response = yield call(api.changeSessionAssetsStatus, sessionId, assetIds, status)
    if (isApiError(response)) {
      debug('fetch changeSessionAssetsStatus failed', response, response as ApiError)
      throw new ApplicationError(ErrorCode.Api, 'fetch changeSessionAssetsStatus failed')
    }

    const sessionAssets: Array<Store.Asset> = yield select(selectSessionAssets)

    const updatedSessionAssets = sessionAssets.map((asset) => {
      if (assetIds.includes(asset.id)) {
        return { ...asset, status }
      }

      return asset
    })

    // // save in redux
    yield put({
      type: K.UPDATE_ASSETS_STATUS_SUCCESS,
      payload: updatedSessionAssets,
    })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Updated })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.UpdateFailure })
    debug('changeSessionAssetsStatus Error', error)
  }
}

export function* stopBackTestOperation() {
  debug('Saga dispatchBackTestOperation')
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Updating })
    const session: Store.Session = yield select(selectOperativeSession)
    const sessionId = session.id
    const response = yield call(backtestApi.backtestStop, sessionId)
    if (isApiError(response)) {
      debug('fetch dispatchBackTestOperation failed', response, response as ApiError)
      throw new ApplicationError(ErrorCode.Api, 'fetch dispatchBackTestOperation failed')
    }

    yield put(loadFinishedById(sessionId))
    yield put({ type: K.SET_STATUS, payload: LoadingState.Loaded })
  } catch (error) {
    debug('launchBackTestOperation Error', error)
    yield put({ type: K.SET_STATUS, payload: LoadingState.UpdateFailure })
  }
}

export function* freezeBackTestOperation() {
  debug('Saga dispatchBackTestOperation')
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Updating })
    const session: Store.Session = yield select(selectOperativeSession)
    const sessionId = session.id
    const response = yield call(backtestApi.backtestStandby, sessionId)

    if (isApiError(response)) {
      debug('fetch dispatchBackTestOperation failed', response, response as ApiError)
      throw new ApplicationError(ErrorCode.Api, 'fetch dispatchBackTestOperation failed')
    }

    yield put(refreshSession(sessionId))
    yield put({ type: K.CHANGE_SESSION_STATUS, payload: SessionStatus.StandBy })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Updated })
  } catch (error) {
    debug('launchBackTestOperation Error', error)
    yield put({ type: K.SET_STATUS, payload: LoadingState.UpdateFailure })
  }
}

export function* launchBackTestOperation(action: AnyAction) {
  debug('Saga dispatchBackTestOperation')
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Updating })
    const session: Store.Session = yield select(selectOperativeSession)
    const { start, stop, speedup, tickByTick, dataFrequency } = action.payload as Store.LaunchBackTestOperationPayload
    const sessionId = session.id
    const response = yield call(backtestApi.backtestRun, sessionId, start, stop, speedup, tickByTick, dataFrequency)

    if (isApiError(response)) {
      debug('fetch dispatchBackTestOperation failed', response, response as ApiError)
      throw new ApplicationError(ErrorCode.Api, 'fetch dispatchBackTestOperation failed')
    }

    yield put(refreshSession(sessionId))
    yield put({ type: K.SET_STATUS, payload: LoadingState.Loaded })
  } catch (error) {
    debug('launchBackTestOperation Error', error)
    yield put({ type: K.SET_STATUS, payload: LoadingState.UpdateFailure })
  }
}

export function* stopVirtualSession() {
  debug('Saga stopVirtualSession')
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Updating })
    const session: Store.Session = yield select(selectOperativeSession)
    const sessionId = session.id
    const response = yield call(api.stopVirtualSession, sessionId)
    if (isApiError(response)) {
      debug('fetch stopVirtualSession failed', response, response as ApiError)
      throw new ApplicationError(ErrorCode.Api, 'fetch stopVirtualSession failed')
    }

    yield put(loadFinishedById(sessionId))
    yield put({ type: K.SET_STATUS, payload: LoadingState.Loaded })
  } catch (error) {
    debug('stopVirtualSession Error', error)
    yield put({ type: K.SET_STATUS, payload: LoadingState.UpdateFailure })
  }
}

export function* launchVirtualSession(action: AnyAction) {
  debug('Saga launchVirtualSession')
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Updating })
    const session: Store.Session = yield select(selectOperativeSession)
    const payload = action.payload as Api.VirtualSessionLaunch
    const sessionId = session.id
    const response = yield call(api.runVirtualSession, sessionId, payload)

    if (isApiError(response)) {
      debug('fetch launchVirtualSession failed', response, response as ApiError)
      throw new ApplicationError(ErrorCode.Api, 'fetch launchVirtualSession failed')
    }

    yield put(refreshSession(sessionId))
    yield put({ type: K.SET_STATUS, payload: LoadingState.Loaded })
  } catch (error) {
    debug('launchVirtualSession Error', error)
    yield put({ type: K.SET_STATUS, payload: LoadingState.UpdateFailure })
  }
}

function* watch() {
  yield all([
    takeLatest(K.FETCH_OPERATIVE_SESSION, loadOperativeSession),
    takeLatest(K.FETCH_FINISHED_OPERATIVE_SESSION, loadFinishedOperativeSession),
    takeLatest(K.UPDATE_ASSETS_STATUS, changeSessionAssetsStatus),
    takeLatest(K.REFRESH_OPERATIVE_SESSION, refreshOperativeSession),
    takeLatest(K.LAUNCH_BACKTEST_OPERATION, launchBackTestOperation),
    takeLatest(K.LAUNCH_VIRTUAL_SESSION, launchVirtualSession),
    takeLatest(K.FREEZE_BACKTEST_OPERATION, freezeBackTestOperation),
    takeLatest(K.STOP_BACKTEST_OPERATION, stopBackTestOperation),
    takeLatest(K.STOP_VIRTUAL_SESSION, stopVirtualSession),
  ])
}

export default watch
