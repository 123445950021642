import * as Api from 'types/api'
import { getAxios } from './apiService'
import { config } from 'core'
import { getApiError, getAuthenticationHeader } from './common'
import { AxiosResponse } from 'axios'
import { AssetStatus, SessionType } from 'types/enums'
const axios = getAxios()

export async function getSessions(): Promise<Api.SessionsResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `{
      application_operative_session(order_by: {end_time: desc}) {
        end_time
        id
        name
        start_time
        last_evt_ts
        status
        type
        owner_id
        user {
          email_address
        }
        userByExecutionUserId {
          email_address
        }
        operative_session_parameters {
          name
          value
        }
      }
    }`,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getSession(id: number): Promise<Api.SessionsResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const variables = {
    id,
  }
  const data: GraphQlQuery = {
    query: `query ($id: Int){
      application_operative_session(where: {id:{_eq: $id}}) {
        end_time
        id
        name
        start_time
        last_evt_ts
        status
        type
        owner_id
        user {
          email_address
        }
        userByExecutionUserId {
          email_address
        }
        operative_session_parameters {
          name
          value
        }
      }
    }`,
    variables,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getSessionEndTime(id: number): Promise<Api.SessionsEndTimeResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const variables = {
    id,
  }
  const data: GraphQlQuery = {
    query: `query ($id: Int){
      application_operative_session(where: {id:{_eq: $id}}) {
        end_time
      }
    }`,
    variables,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getSessionAssets(sessionId: number): Promise<Api.SessionAssetsResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const variables = {
    id: sessionId,
  }
  const data: GraphQlQuery = {
    query: `query ($id: Int) {
      application_asset_model_instance(where: {operative_session_id: {_eq: $id}}) {
        asset_id
        status
        asset {
          asset_groups {
            group {
              id
              name
            }
          }
        }
      }
    }
    `,
    variables: variables,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getHistoricSessionAssets(
  sessionId: number,
): Promise<Api.HistoricalSessionAssetsResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const variables = {
    id: sessionId,
  }
  const data: GraphQlQuery = {
    query: `query ($id: Int) {
      historical_data_asset_model_instance_v(where: {operative_session_id: {_eq: $id}}) {
        asset_id
        status
      }
    }
    `,
    variables: variables,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function addSessionAssets(sessionId, assetsId: number[]): Promise<AxiosResponse | ApiError> {
  const header = await getAuthenticationHeader()
  return axios
    .post(config.api.sessionAssets(sessionId), { assetsId }, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getBrokenEntities(sessionId: number): Promise<Api.SessionBrokenEntities | ApiError> {
  const header = await getAuthenticationHeader()
  return axios
    .get(config.api.brokenEntities(sessionId), header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function removeSessionAssets(sessionId, assetsId: number[]): Promise<AxiosResponse | ApiError> {
  const header = await getAuthenticationHeader()

  //https://stackoverflow.com/questions/51069552/axios-delete-request-with-body-and-headers
  const payload = {
    headers: header.headers,
    data: { assetsId },
  }

  return axios
    .delete(config.api.sessionAssets(sessionId), payload)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function changeSessionAssetsStatus(
  sessionId,
  assetsIds: number[],
  status: AssetStatus,
): Promise<AxiosResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data = {
    assetsIds,
    status,
  }

  return axios
    .put(config.api.sessionAssetsChangeStatus(sessionId), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function deleteSession(sessionId): Promise<AxiosResponse | ApiError> {
  const header = await getAuthenticationHeader()
  return axios
    .delete(config.api.sessionDelete(sessionId), header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function cleanUpSession(sessionId): Promise<AxiosResponse | ApiError> {
  const header = await getAuthenticationHeader()
  return axios
    .delete(config.api.cleanUpSession(sessionId), header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function runVirtualSession(
  sessionId: number,
  data: Api.VirtualSessionLaunch,
): Promise<Api.AssetCreateFeed | ApiError> {
  const header = await getAuthenticationHeader()

  return axios
    .put(config.api.runVirtualSession(sessionId), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function stopVirtualSession(sessionId: number): Promise<Api.AssetCreateFeed | ApiError> {
  const header = await getAuthenticationHeader()
  return axios
    .put(config.api.stopVirtualSession(sessionId), null, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getEnabledSessionTypes(): Promise<SessionType[] | ApiError> {
  const header = await getAuthenticationHeader()
  return axios
    .get(config.api.enabledSessionTypes(), header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getAssetsAlreadyInvolvedIntoSessions(): Promise<any | ApiError> {
  const header = await getAuthenticationHeader()

  const data: GraphQlQuery = {
    query: `query {
      application_asset_model_instance {
        asset {
          id
          name
        }
      }
    }
    `
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}
