import * as K from './constants'
import { put, call, takeLatest, all } from 'redux-saga/effects'
import Debug from 'debug'
import * as helpers from './helpers'
import * as Api from 'types/api'
import * as api from 'api/groups'
import { ErrorCode, LoadingState } from 'types/enums'
import { ApplicationError, isApiError } from 'core'
import { jsonToGroups } from 'services/store/mapService'
import * as Store from 'types/store'
import { AnyAction } from 'redux'

const debug = Debug('Frontend')

export function* fetchGroups() {
  debug('Saga Fetch Groups')
  try {
    yield call(helpers.fetchGroups)
    yield put({ type: K.SET_STATUS, payload: LoadingState.Created })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.CreateFailure })
    debug('fetchGroups Error', error)
  }
}

export function* createGroup(action: AnyAction) {
  debug('Saga Create Groups')
  try {
    yield put({ type: K.SET_ERROR, payload: null })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Creating })
    const postData = action.payload as Api.GroupRest

    const response = yield call(api.createGroup, postData)
    if (isApiError(response)) {
      debug('fetch createGroup failed', response, response as ApiError)
      // Put detailed error for groups
      yield put({ type: K.SET_ERROR, payload: (response.data as Api.RESTResponse)?.result })
      throw new ApplicationError(ErrorCode.Api, `createGroup failed with error: ${(response.data as Api.RESTResponse)?.result?.description}`)
    }

    const { groupId } = (response as Api.GroupCreate).content

    const newGroup = yield call(api.getGroup, groupId)
    if ((newGroup as ApiError).status) {
      debug('fetch getGroup failed', newGroup)
      throw new ApplicationError(ErrorCode.Api, 'fetch getGroup failed')
    }

    const json = (newGroup as Api.GroupsResponse).data.application_group

    const groups = jsonToGroups(json)

    // save in redux
    yield put({ type: K.GROUP_CREATE_SUCCESS, payload: groups[0] })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Created })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.CreateFailure })
    debug('createGroup Error', error)
  }
}

export function* updateGroup(action: AnyAction) {
  debug('Saga Update Groups')
  try {
    yield put({ type: K.SET_ERROR, payload: null })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Updating })
    const group = action.payload as Api.GroupRest

    const response = yield call(api.updateGroup, group)

    if (isApiError(response)) {
      debug('updateGroup failed', response, response as ApiError)
      // Put detailed error for groups
      yield put({ type: K.SET_ERROR, payload: (response.data as Api.RESTResponse)?.result })
      throw new ApplicationError(ErrorCode.Api, `updateGroup failed with error: ${(response.data as Api.RESTResponse)?.result?.description}`)
    }

    // This is needed to refresh the grouped assets list in the central column
    const updatedGroup = yield call(api.getGroup, group.id)
    if ((updatedGroup as ApiError).status) {
      throw new ApplicationError(ErrorCode.Api, `fetch getGroup failed`)
    }
    const json = ((updatedGroup as Api.GroupsResponse).data.application_group)
    const groups = jsonToGroups(json)

    // save in redux
    yield put({ type: K.GROUP_UPDATE_SUCCESS, payload: groups[0] })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Updated })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.UpdateFailure })
    debug('createGroup Error', error)
  }
}

export function* deleteGroup(action: AnyAction) {
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Deleting })
    yield put({ type: K.SET_ERROR, payload: null })
    const group = action.payload as Store.Group

    const response = yield call(api.deleteGroup, group)

    // if (isApiError(response)) {
    if (response && response?.result?.responseEnum !== 'SUCCESS') {
      yield put({ type: K.SET_ERROR, payload: (response.data as Api.RESTResponse)?.result })
      throw new ApplicationError(ErrorCode.Api, `fetch updateGroup failed with error: ${(response.data as Api.RESTResponse)?.result?.description}`)
    }

    // Save in redux
    yield put({ type: K.GROUP_DELETE_SUCCESS, payload: group })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Deleted })

  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.DeleteFailed })
  }
}

function* watch() {
  yield all([
    takeLatest(K.GROUPS_FETCH, fetchGroups),
    takeLatest(K.GROUP_CREATE, createGroup),
    takeLatest(K.GROUP_UPDATE, updateGroup),
    takeLatest(K.GROUP_DELETE, deleteGroup),
  ])
}

export default watch
