import React from 'react'

const EmptySunburstView: React.FC = () => {
  return (
    <svg viewBox="0,0,300,300" style={{ display: 'block' }}>
      <g transform="translate(150,150)">
        <g>
          <path
            fill="#ccc"
            fillOpacity="1"
            d="M6.0620016557793985e-15,-99A99,99,0,1,1,-6.0620016557793985e-15,99A99,99,0,1,1,6.0620016557793985e-15,-99M3.522406999140111e-14,-50A50,50,0,1,0,-3.522406999140111e-14,50A50,50,0,1,0,3.522406999140111e-14,-50Z"
            style={{ cursor: 'pointer' }}
          ></path>
        </g>
        <circle r="50" fill="none" pointerEvents="all"></circle>
      </g>
    </svg>
  )
}

export default EmptySunburstView
