import React, { useContext, useEffect, useState } from 'react'

import * as Store from 'types/store'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import ChartGroup from 'components/ChartD3/ChartGroup'
import ChartBackground from 'components/ChartD3/ChartBackground'
import ChartGrid from 'components/ChartD3/ChartGrid'
import OperationsBarChart from 'components/ChartD3/OperationsBarChart'
import Clipper from 'components/ChartD3/Clipper'
import YAxisRight from 'components/ChartD3/YAxisRight'
import colors from 'config/colors'
import { ChartChild } from 'types/chart'
import * as api from 'api/charts'
import * as apiCandle from 'api/candleStick'
import { isApiError } from 'core'
import {
  jsonHistoricalToOperationsBarchart,
  jsonToOperationsBarchart,
  jsonToOperationsBarchartIndicators,
} from 'services/store/mapTimeSeries'
import * as Api from 'types/api'
import { colorizer } from 'components/ChartD3/common'
import { getDateLimits } from 'core/helpers'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
import { AssetFeed, Feeds, Sigma } from 'types/enums.api'
import sigmaServices from 'config/sigmaServices'
import moment from 'moment'

const getBarChartHistoricalData = async (assetId: number, sessionId: number) => {
  const response = await api.getHistoricalBarChartData(assetId, sessionId)

  if (isApiError(response)) {
    return null
  }

  return jsonHistoricalToOperationsBarchart(response as Api.OperationBarsChartHistoricalResponse)
}

const getBarchartIndicatorsData = async (
  sigma: Sigma,
  feed: Feeds,
  assetId: number,
  sessionId: number,
  startIso: Date,
  endIso: Date,
) => {
  const response = await apiCandle.getDataPoints(assetId, sigma, feed, null, startIso, endIso, sessionId)
  if (isApiError(response)) {
    return null
  }
  return jsonToOperationsBarchartIndicators(response as Api.OperationBarsChartIndicatorsResponse)
}

const getBarchartData = async (
  sigma: Sigma,
  feed: Feeds,
  assetId: number,
  sessionId: number,
  startIso: Date,
  endIso: Date,
) => {
  const response = await apiCandle.getDataPoints(assetId, sigma, feed, null, startIso, endIso, sessionId)
  if (isApiError(response)) {
    return null
  }
  return jsonToOperationsBarchart(response as Api.OperationBarsChartResponse)
}

const { Operations, Return } = sigmaServices.Assets

interface Props {
  asset: Store.Asset
}

const HistoricalBarchartContainer: React.FC<Props & ChartChild> = ({
  asset,
  chartHeight,
  chartWidth,
  clipId,
  d3Transform,
  transitionTime,
  xDomain,
  yDomain,
}) => {
  const { id: sessionId, endTime } = useSelector(selectOperativeSessionSession)
  const [barCharts, setBarCharts] = useState<Store.OperationBarchart[]>([])
  const [indicators, setIndicators] = useState<Store.OperationBarchartIndicator[]>([])
  const [liveData, setLiveData] = useState<Store.OperationBarchart[]>([])
  const context = useContext(CandleStickFrameContext)
  const { timeFrame } = context
  const session = useSelector(selectOperativeSessionSession)

  const getIndicatorData = async () => {
    const [startTime] = getDateLimits(timeFrame, session)
    const data = await getBarchartIndicatorsData(
      Return.AssetTdyOpIndicators,
      AssetFeed.Return,
      asset.id,
      sessionId,
      moment(startTime).startOf('day').toDate(),
      endTime,
    )

    if (data) {
      setIndicators(data)
    }
  }

  const getTimeseriesData = async () => {
    const [startTime] = getDateLimits(timeFrame, session)
    const data = await getBarchartData(
      Operations.AssetNumTdyOp,
      AssetFeed.Operations,
      asset.id,
      sessionId,
      moment(startTime).startOf('day').toDate(),
      endTime,
    )

    if (data) {
      setBarCharts(data)
    }
  }

  const getHistoricalData = async () => {
    getBarChartHistoricalData
    const data = await getBarChartHistoricalData(asset.id, sessionId)

    if (data) {
      setLiveData(data)
    }
  }

  useEffect(() => {
    getTimeseriesData()
    getIndicatorData()
    getHistoricalData()
  }, [])

  const chartChildProps: ChartChild = {
    chartHeight,
    chartWidth,
    clipId,
    d3Transform,
    transitionTime,
    xDomain,
    yDomain,
  }

  const data = [...barCharts, ...liveData]

  function YDomainBars(): number[] {
    let max = data.reduce((largest, { negOp, posOp }) => {
      const larger = negOp > posOp ? negOp : posOp

      return larger > largest ? larger : largest
    }, 0)

    max *= 1.25

    return [-max, max]
  }

  const conbinedData: Store.OperationBarchart[] = data.map((item) => {
    if (item.sequence !== null) {
      const indicator =
        indicators.find((indicator) => moment(indicator.date).isSame(item.date, 'date')) || null
      return {
        ...item,
        indicator,
      }
    }
    return item
  })

  return (
    <ChartGroup {...chartChildProps} height={0.18} width={1} x={0} y={0.81} yDomain={YDomainBars()}>
      <ChartBackground color={colors.midnight} />
      <ChartGrid xData={[]} yData={[]} />
      <OperationsBarChart data={conbinedData} currency={asset.currency} />
      <Clipper />
      <YAxisRight data={[]} tickFormat={(value) => Math.abs(value).toString()} colorizer={colorizer} />
    </ChartGroup>
  )
}

export default HistoricalBarchartContainer
