export const STATE_KEY = 'groups'

export const GROUPS_FETCH = `${STATE_KEY}/GROUPS_FETCH`
export const GROUPS_FETCH_SUCCESS = `${STATE_KEY}/GROUPS_FETCH_SUCCESS`

export const GROUP_CREATE = `${STATE_KEY}/GROUP_CREATE`
export const GROUP_CREATE_SUCCESS = `${STATE_KEY}/GROUP_CREATE_SUCCESS`

export const GROUP_UPDATE = `${STATE_KEY}/GROUP_UPDATE`
export const GROUP_UPDATE_SUCCESS = `${STATE_KEY}/GROUP_UPDATE_SUCCESS`

export const GROUP_DELETE = `${STATE_KEY}/GROUP_REMOVE`
export const GROUP_DELETE_SUCCESS = `${STATE_KEY}/GROUP_REMOVE_SUCCESS`

export const HISTORICAL_GROUPS_FETCH = `${STATE_KEY}/HISTORICAL_GROUPS_FETCH`
export const HISTORICAL_GROUPS_FETCH_SUCCESS = `${STATE_KEY}/HISTORICAL_GROUPS_FETCH_SUCCESS`

export const SET_STATUS = `${STATE_KEY}/SET_STATUS`
export const SET_ERROR = `${STATE_KEY}/SET_ERROR`
