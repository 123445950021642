import React, { ComponentType, useEffect, useRef, useState } from 'react'
import { numberFormatter, numberParser } from 'core/formats'

interface FormatNumberProps {
  formatter: (value: string | number) => string
  parser: (value: string) => string
}
interface VisibleProps {
  visible: boolean
}

// https://stackoverflow.com/questions/29255843/is-there-a-way-to-reverse-the-formatting-by-intl-numberformat-in-javascript
// https://maxrozen.com/2020/04/10/implement-higher-order-component-react-typescript
// https://stackoverflow.com/questions/43680786/writing-a-react-higher-order-component-with-typescript
export function withCurrencyFormat<Props>(
  WithCurrencyFormat: React.ComponentType<Props & FormatNumberProps>,
): ComponentType<Omit<Props, keyof FormatNumberProps>> {
  const hoc = (props: Props) => {
    return <WithCurrencyFormat {...props} formatter={numberFormatter} parser={numberParser} />
  }
  return hoc
}

export function withVisible<Props>(
  WithVisible: React.ComponentType<Props & VisibleProps>,
): ComponentType<Omit<Props, keyof VisibleProps>> {
  const hoc = (props: Props) => {
    // https://stackoverflow.com/questions/45514676/react-check-if-element-is-visible-in-dom
    const [isIntersecting, setIntersecting] = useState(false)
    const Element = useRef<any>(
      React.memo(WithVisible, (oldProps, newProps) => {
        const hidden = document.visibilityState !== 'visible'
        return hidden || !newProps.visible
      }),
    )
    const ref = useRef(null)

    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))

    useEffect(() => {
      observer.observe(ref.current)
      // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect()
      }
    }, [])

    return (
      <div className="visibility-wrapper" ref={ref}>
        <Element.current {...props} visible={isIntersecting} />
      </div>
    )
  }
  return hoc
}
