import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as K from './constants'
import Debug from 'debug'
import { ErrorCode, LoadingState } from 'types/enums'
import * as api from 'api/users'
import * as Api from 'types/api'
import { ApplicationError, isApiError, isGraphQlError } from 'core'
import { createUserFromResponse, jsonToSystemUsers } from 'services/store/mapService'
import { AnyAction } from 'redux'
const debug = new Debug('Frontend')

export function* fetchUsers() {
  debug('Saga fetchUsers')
  try {
    yield put({ type: K.SET_LOADING_STATE, payload: LoadingState.Loading })
    const response = yield call(api.getUsers)

    if (isApiError(response) || isGraphQlError(response)) {
      throw new ApplicationError(ErrorCode.Api, 'fetch fetchUsers failed')
    }

    const data = response as Api.UsersResponse
    const users = jsonToSystemUsers(data.content)

    yield put({ type: K.SET_LOADING_STATE, payload: LoadingState.Loaded })
    yield put({ type: K.FETCH_USERS_SUCCESS, payload: users })
  } catch (error) {
    yield put({ type: K.SET_LOADING_STATE, payload: LoadingState.LoadFailure })
    debug('fetchUsers Error', error)
  }
}

export function* editUser(action: AnyAction) {
  debug('Saga editUser')
  try {
    yield put({ type: K.SET_LOADING_STATE, payload: LoadingState.Updating })
    const userForm = action.payload
    const response = yield call(api.editUser, userForm, userForm.id)

    if (isApiError(response) || isGraphQlError(response)) {
      yield put({ type: K.SET_ERROR, payload: (response?.data as Api.RESTResponse)?.result?.responseEnum })
      throw new ApplicationError(ErrorCode.Api, 'editUser failed')
    }
    const user = createUserFromResponse(userForm)

    yield put({ type: K.SET_LOADING_STATE, payload: LoadingState.Updated })
    yield put({ type: K.EDIT_USER_SUCCESS, payload: user })
  } catch (error) {
    yield put({ type: K.SET_LOADING_STATE, payload: LoadingState.UpdateFailure })
    debug('editUser Error', error)
  }
}

export function* deleteUser(action: AnyAction) {
  debug('Saga deleteUser')
  try {
    yield put({ type: K.SET_LOADING_STATE, payload: LoadingState.Deleting })
    const id = action.payload
    const response = yield call(api.deleteUser, id)

    if (isApiError(response) || isGraphQlError(response)) {
      yield put({ type: K.SET_ERROR, payload: (response?.data as Api.RESTResponse)?.result?.responseEnum })
      throw new ApplicationError(ErrorCode.Api, 'deleteUser failed')
    }

    yield put({ type: K.SET_LOADING_STATE, payload: LoadingState.Deleted })
    yield put({ type: K.DELETE_USER_SUCCESS, payload: id })
  } catch (error) {
    yield put({ type: K.SET_LOADING_STATE, payload: LoadingState.DeleteFailed })
    debug('deleteUser Error', error)
  }
}

export function* newUser(action: AnyAction) {
  debug('Saga newUser')
  try {
    yield put({ type: K.SET_LOADING_STATE, payload: LoadingState.Loading })
    const formData = action.payload
    const response = yield call(api.createUser, formData)

    if (isApiError(response) || isGraphQlError(response)) {
      yield put({ type: K.SET_ERROR, payload: (response?.data as Api.RESTResponse)?.result?.responseEnum })
      throw new ApplicationError(ErrorCode.Api, 'newUser failed')
    }

    const data = response as Api.CreateUserResponse
    const id: number = data.content
    const user = createUserFromResponse({ ...formData, id })

    yield put({ type: K.SET_LOADING_STATE, payload: LoadingState.Created })
    yield put({ type: K.NEW_USER_SUCCESS, payload: user })
  } catch (error) {
    yield put({ type: K.SET_LOADING_STATE, payload: LoadingState.CreateFailure })
    debug('fetchUsers Error', error)
  }
}

function* watch() {
  yield all([
    takeLatest(K.FETCH_USERS, fetchUsers),
    takeLatest(K.NEW_USER, newUser),
    takeLatest(K.EDIT_USER, editUser),
    takeLatest(K.DELETE_USER, deleteUser),
  ])
}

export default watch
