export const features = (() => {
  return {
    enableAggregate: 'enableAggregate',
    noWarningOnExit: 'noWarningOnExit',
    hideLogo: 'hideLogo',
    backtestChartlessStatistics: 'backtestChartlessStatistics',
  }
})()

export const featureDescriptions = {
  enableAggregate: 'Enable Aggregate Charts',
  noWarningOnExit: 'Disable the warning popup on exit',
  hideLogo: "Enable stealth mode and don't allow user to see the logo",
  backtestChartlessStatistics: 'Backtest chartless statistics ',
}
