import { AnyAction } from 'redux'
import { LoadingState } from 'types/enums'
import * as K from './constants'
import * as Store from 'types/store'

export function fetchSessions(): AnyAction {
  return {
    type: K.FETCH_SESSIONS,
    payload: null,
  }
}

export function deleteSession(sessionId: number): AnyAction {
  return {
    type: K.SESSION_DELETE,
    payload: sessionId,
  }
}

export function cleanUpSession(sessionId: number): AnyAction {
  return {
    type: K.SESSION_CLEAN_UP,
    payload: sessionId,
  }
}

export function updateSessionStatus(payload: Store.SessionUpdate): AnyAction {
  return {
    type: K.UPDATE_SESSION_STATUS,
    payload: payload,
  }
}

export function setLoadingState(payload: LoadingState): AnyAction {
  return {
    type: K.SET_STATUS,
    payload: payload,
  }
}
