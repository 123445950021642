import React, { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'react-use'
import { Row, Col, Typography, Button, Dropdown, Divider } from 'antd'
const { Title, Paragraph } = Typography

import AppContentCard from 'components/AppContentCard'

import { CrudOperation, LoadingState } from 'types/enums'
import { useForm } from 'antd/lib/form/Form'
import PortfolioForm from './forms/PortfolioForm'
import { DownOutlined } from '@ant-design/icons'
import ReadAsset from './ReadAsset'
import AssetForm from 'containers/StrategyViewer/AssetForm'
import * as Store from 'types/store'
import { formatNumber } from 'core/formats'
import CsvModal from 'containers/StrategyViewer/CsvModal'
import { ResourceAction } from 'types/store'
import { ExtendedDataNode, NodeType } from 'types/ui'
import Toggle from 'assets/icon/menu-toggle.svg'
import { GroupFormContainer } from 'containers/StrategyViewer/AssetForm/GroupForm'
import { SearchableTree } from './SearchableTree'
import { mergeGroupsAndAssets } from 'services/store/mapService'
import { ReadGroup } from './ReadGroup'
import { useSelector } from 'react-redux'
import { selectAssetsState, selectGroupsState } from 'store/resources/selectors'

const WIDTH_FOR_EDITASSET = 1920

interface Props {
  portfolio: Store.Portfolio
  onPortfolioSave: (data: Json) => void
  assets: Array<Store.Asset>
  group?: Store.Group
  groups: Store.Group[]
  loadingState: LoadingState
  isEditing: boolean
  isReadOnly: boolean
  onCancel: () => void
  asset: Store.Asset
  usedResources: Store.UsedResources
  portfolioAction?: (
    resource: Store.Asset | Store.Group,
    type: CrudOperation,
    nodeType: NodeType.Group | NodeType.Asset
  ) => void
}

const ReadPortfolio: React.FC<Props> = ({
  assets,
  group,
  groups,
  portfolio,
  loadingState,
  onPortfolioSave,
  isEditing,
  isReadOnly,
  asset,
  onCancel,
  portfolioAction,
  usedResources,
}) => {
  const { t } = useTranslation()

  const groupsLoadingState = useSelector(selectGroupsState)
  const assetsLoadingState = useSelector(selectAssetsState)

  const [showPortfolio, setShowPortfolio] = useState<boolean>(true)
  const [componentResourceAction, setComponentResourceAction] = useState<ResourceAction>(null)
  // const [selectedAsset, setSelectedAsset] = useState(-1)
  const [groupedAssetsList, setGroupedAssetList] = useState<ExtendedDataNode[]>([])
  const [reRenderTree, setReRenderTree] = useState<boolean>(false)
  const [csvModalVisible, setCsvModalVisible] = useState<boolean>(false)
  const { width } = useWindowSize()

  const isFullEditAsset = width < WIDTH_FOR_EDITASSET

  const [form] = useForm()

  useEffect(() => {
    isEditing && form.setFieldsValue(portfolio)
  }, [portfolio, isEditing])

  useEffect(() => {
    if (groups && assets) {
      setGroupedAssetList(mergeGroupsAndAssets(groups, assets, usedResources))
    }

    setReRenderTree(!reRenderTree)
  }, [
    asset,
    group,
    loadingState,
    assets.length,
    groups.length,
    showPortfolio,
    componentResourceAction,
    groupsLoadingState,
    assetsLoadingState,
  ])

  const onDropdownClick = (option) => {
    if (option.key === 'add') {
      setComponentResourceAction({ resource: null, operation: CrudOperation.Create, type: NodeType.Asset })
    } else if (option.key === 'group') {
      setComponentResourceAction({ resource: null, operation: CrudOperation.Create, type: NodeType.Group })
    } else {
      setCsvModalVisible(true)
    }
  }

  const editResource = (resource: Store.Asset | Store.Group, nodeType: NodeType.Asset | NodeType.Group) => {
    setComponentResourceAction({ resource: resource, operation: CrudOperation.Update, type: nodeType })
  }

  const menu = [
    {label: 'Asset', key: 'add', onClick: (a) => onDropdownClick(a)},
    {label: 'Group', key: 'group', onClick: (a) => onDropdownClick(a)},
    {label: t('assets.uploadCsv'), key: 'csv', onClick: (a) => onDropdownClick(a)},
  ]

  const onResourceClose = () => {
    setComponentResourceAction(null)
    //Scroll to the top
    window.scrollTo(0, 0)
  }

  const onAssetCancel = (asset: Store.Asset) => {
    setComponentResourceAction({ resource: asset, operation: CrudOperation.Read, type: NodeType.Asset })
  }

  const onGroupCancel = (group: Store.Group) => {
    setComponentResourceAction({ resource: group, operation: CrudOperation.Read, type: NodeType.Group })
  }

  const renderAssetOrGroup = () => {
    if (!componentResourceAction && !group && !asset) {
      return null
    }

    let operation = null
    let resource = null
    let type = null

    if (componentResourceAction) {
      const { operation: currentOp, resource: currentRes, type: currentType } = componentResourceAction
      operation = currentOp
      resource = currentRes
      type = currentType
    }

    switch (operation) {
      case CrudOperation.Create: {
        if (type === NodeType.Group) {
          return (
            <GroupFormContainer
              portfolioId={portfolio.id}
              group={null}
              assets={assets}
              operation={CrudOperation.Create}
              onClose={onResourceClose}
            />
          )
        }

        if (type === NodeType.Asset) {
          return (
            <AssetForm
              portfolioId={portfolio.id}
              asset={null}
              operation={CrudOperation.Create}
              onClose={onResourceClose}
            />
          )
        }

        break
      }

      case CrudOperation.Update: {
        if (type === NodeType.Group) {
          return (
            <GroupFormContainer
              portfolioId={(resource as Store.Group).portfolioId}
              group={resource as Store.Group}
              assets={assets}
              operation={CrudOperation.Update}
              onClose={onGroupCancel}
            />
          )
        }

        if (type === NodeType.Asset) {
          return (
            <AssetForm
              portfolioId={(resource as Store.Asset).portfolioId}
              asset={resource as Store.Asset}
              operation={CrudOperation.Update}
              onClose={onAssetCancel}
            />
          )
        }

        break
      }

      default: {
        // When in read mode basically
        if (type === NodeType.Group || group) {
          return <ReadGroup group={(resource as Store.Group) ?? group} />
        }

        if (type === NodeType.Asset || asset) {
          return <ReadAsset asset={(resource as Store.Asset) ?? asset} />
        }

        break
      }
    }
  }

  const renderPortfolioAndResourcesColumns = (): ReactNode => {
    return (
      <>
        {isEditing && (
          <Col span={8} className="col-visible">
            <PortfolioForm
              form={form}
              loading={loadingState === LoadingState.Updating}
              onFinish={onPortfolioSave}
              onCancel={onCancel}
            />
          </Col>
        )}

        {!isEditing && (
          <Col span={8} className={showPortfolio ? 'col-visible full-height strategy-page-first-column' : 'col-hidden strategy-page-first-column'}>
            <AppContentCard fullHeight>
              <Row align="middle" className="col-title">
                <Col span={20} className="title">
                  <Button type="ghost" size="small" className="col-trigger">
                    <Title>{t('general.portfolio')}</Title>
                  </Button>
                </Col>
              </Row>

              <Button className="menu-toggle" type="ghost" onClick={() => setShowPortfolio(!showPortfolio)}>
                <img src={Toggle} width={32} height={32} alt="Toggle" />
              </Button>

              <section className="inner-content">
                <Title level={2} className="primary-color mb-10">
                  {t('general.description')}
                </Title>
                <Title level={3} className="mb-16">
                  {portfolio.name}
                </Title>
                <Title level={2} className="primary-color mt-24 mb-10">
                  {t('general.management')}
                </Title>

                <Row>
                  <Col span={9}>
                    <Paragraph>{t('portfolios.maxLong')}</Paragraph>
                  </Col>
                  <Col span={15} className="align-right">
                    <Paragraph>{formatNumber(portfolio.maxLong)}</Paragraph>
                  </Col>
                </Row>
                <Divider type="horizontal" />

                <Row>
                  <Col span={9}>
                    <Paragraph>{t('portfolios.maxShort')}</Paragraph>
                  </Col>
                  <Col span={15} className="align-right">
                    <Paragraph>{formatNumber(portfolio.maxShort)}</Paragraph>
                  </Col>
                </Row>
                <Divider type="horizontal" />

                <Row>
                  <Col span={9}>
                    <Paragraph>{t('portfolios.maxNetHedging')}</Paragraph>
                  </Col>
                  <Col span={15} className="align-right">
                    <Paragraph>{formatNumber(portfolio.maxNetHedging)}</Paragraph>
                  </Col>
                </Row>
                <Divider type="horizontal" />

                <Row>
                  <Col span={9}>
                    <Paragraph>{t('portfolios.minNetHedging')}</Paragraph>
                  </Col>
                  <Col span={15} className="align-right">
                    <Paragraph>{formatNumber(portfolio.minNetHedging)}</Paragraph>
                  </Col>
                </Row>
                <Divider type="horizontal" />
              </section>
            </AppContentCard>
          </Col>
        )}

        <Col
          // span={showPortfolio || isEditing ? 8 : 16}
          span={showPortfolio ? 16 : 22}
          className='full-height strategy-page-second-column'
          // className={`full-height ${showPortfolio || isEditing ? 'col-smaller' : 'col-bigger'}`}
        >
          <Row gutter={[16, 0]} className="full-height">
            <Col
              span={showPortfolio ? 12 : 8}
              className="col--assets full-height resource-tree-column"
            >
              <AppContentCard fullHeight>
                <Row align="middle" className="col-title">
                  <Col span={10}>
                    <Title level={1}>{t('assets.title')}</Title>
                  </Col>
                  <Col span={14} className="align-right">
                    {!isReadOnly && (
                      <Dropdown
                        menu={{items: menu}}
                        trigger={['click']}
                        getPopupContainer={(trigger) => trigger.parentElement}
                      >
                        <Button type="dashed">
                          {t('assets.addNew')} <DownOutlined />
                        </Button>
                      </Dropdown>
                    )}
                  </Col>
                </Row>
                <SearchableTree
                  // reRender={reRenderTree}
                  isReadOnly={isReadOnly}
                  setResourceAction={setComponentResourceAction}
                  groupedAssets={groupedAssetsList}
                  editResource={editResource}
                  portfolioAction={portfolioAction}
                  // onItemClicked={onItemClicked}
                />
              </AppContentCard>
            </Col>
            <Col span={showPortfolio ? 12 : 16} className={'col-visible full-height strategy-page-third-column'}>
              {renderAssetOrGroup()}
            </Col>
          </Row>
        </Col>
      </>
    )
  }

  const renderCols = () => {
    if (isFullEditAsset) {
      if (!componentResourceAction) {
        return renderPortfolioAndResourcesColumns()
      }

      const { operation, type } = componentResourceAction
      const curRes = componentResourceAction.resource as Store.Asset | Store.Group

      switch (operation) {
        case CrudOperation.Create:
          if (type === NodeType.Group) {
            return (
              <Col span={24} className={'col-visible full-height'}>
                <GroupFormContainer
                  portfolioId={portfolio.id}
                  group={null}
                  assets={assets}
                  operation={operation}
                  onClose={onResourceClose}
                />
              </Col>
            )
          } else {
            return (
              <Col span={24} className={'col-visible full-height'}>
                <AssetForm
                  portfolioId={portfolio.id}
                  asset={null}
                  operation={operation}
                  onClose={onResourceClose}
                />
              </Col>
            )
          }

        case CrudOperation.Update:
          if (type === NodeType.Group) {

            return (
              <Col span={24} className={'col-visible full-height'}>
                <GroupFormContainer
                  portfolioId={curRes.portfolioId}
                  group={curRes as Store.Group}
                  assets={assets}
                  operation={operation}
                  onClose={onGroupCancel}
                />
              </Col>
            )
          } else {
            return (
              <Col span={24} className={'col-visible full-height'}>
                <AssetForm
                  portfolioId={curRes.portfolioId}
                  asset={curRes as Store.Asset}
                  operation={operation}
                  onClose={onAssetCancel}
                />
              </Col>
            )
          }
        default:
          return renderPortfolioAndResourcesColumns()
      }
    }

    return renderPortfolioAndResourcesColumns()
  }

  const onCsvModalClose = () => setCsvModalVisible(false)

  return (
    <section className="full-height">
      <CsvModal visible={csvModalVisible} onClose={onCsvModalClose} />
      <Row gutter={[16, 0]} className="full-height">
        {renderCols()}
      </Row>
    </section>
  )
}

export default ReadPortfolio
