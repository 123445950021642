import * as Api from 'types/api'
import { getAxios } from './apiService'
import { config } from 'core'
import { getApiError, getAuthenticationHeader } from './common'
const axios = getAxios()

const portfolioTableFields = `{
  all_ret_bc_tdy
  all_ret_bc_ytd
  assets_num
  base_currency
  countervalue_bc
  countervalue_bc_long
  countervalue_bc_short
  exch_ret_bc_tdy
  exch_ret_bc_ytd
  negative_op_tdy
  negative_op_ytd
  operative_session_id
  portfolio_id
  portfolio_name
  positive_op_tdy
  positive_op_ytd
  strategy_name
  total_op_tdy
  total_op_ytd
  trading_ret_bc_tdy
  trading_ret_bc_ytd
}`

export async function getPortfolioTable(
  id: number,
  sessionId: number,
): Promise<Api.HistoricalPortfolioTableResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `query ($id: Int, $sessionId: Int) {
        historical_data_portfolio_table_prt_v(where: {portfolio_id: {_eq: $id}, _and: {operative_session_id: {_eq: $sessionId}}}) ${portfolioTableFields}
    }`,
    variables: {
      id,
      sessionId,
    },
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getAssetsTable(
  id: number,
  sessionId: number,
): Promise<Api.HistoricalAssetTableResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `query ($id: Int, $sessionId: Int){
        historical_data_portfolio_table_asset_v(where: { portfolio_id: {_eq: $id}, operative_session_id: {_eq: $sessionId}}) {
          all_ret_bc_ytd
          asset_id
          asset_name
          countervalue_bc
          exch_ret_bc_tdy
          exch_ret_bc_ytd
          group_name
          instrument_isin
          model_status
          model_name
          model_type
          model_version
          negative_op_tdy
          negative_op_ytd
          operative_session_id
          perc_ret_tdy
          perc_ret_ytd
          position_quantity
          position_sign
          positive_op_tdy
          positive_op_ytd
          price
          tdy_perc_ret
          tdy_trading_ret
          total_op_tdy
          total_op_ytd
          trading_ret_bc_tdy
          trading_ret_bc_ytd
        }
      }`,
    variables: {
      id,
      sessionId,
    },
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getStrategyTable(
  id: number,
  sessionId: number,
): Promise<Api.HistoricalStrategyTableResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `query ($id: Int, $sessionId: Int) {
      historical_data_strategy_table_stg_v(where: {operative_session_id: {_eq:$sessionId}, strategy_id: {_eq: $id}}) {
        strategy_name
        strategy_target
        base_currency
        portfolios_num
        assets_num
        countervalue_bc
        countervalue_bc_long
        countervalue_bc_short
        trading_ret_bc_tdy
        exch_ret_bc_tdy
        all_ret_bc_tdy
        trading_ret_bc_ytd
        exch_ret_bc_ytd
        all_ret_bc_ytd
        total_op_tdy
        positive_op_tdy
        negative_op__tdy
        total_op_ytd
        positive_op_ytd
        negative_op__ytd
      }
    }

    `,
    variables: {
      id,
      sessionId,
    },
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getStrategyTablePortfolios(
  id: number,
  sessionId: number,
): Promise<Api.HistoricalPortfolioTableResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `query ($id: Int, $sessionId: Int) {
      historical_data_portfolio_table_prt_v(where: {_and: {operative_session_id: {_eq: $sessionId}}, strategy_id: {_eq: $id}}) ${portfolioTableFields}
    }`,
    variables: {
      id,
      sessionId,
    },
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getSignalsTable(id: number): Promise<Api.HistoricalSignals | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `query ($id: Int){
      historical_data_asset_ref_data_v(where: {operative_session_id: {_eq: $id}}) {
        asset_name
        asset_id
      }
      historical_data_model_signal_v(where: {operative_session_id: {_eq: $id}}) {
        sign
        asset_id
        quantity
        price
        time
      }
      historical_data_theoretical_order_v(where: {operative_session_id: {_eq: $id}}) {
        time
        quantity
        price
        sign
        asset_id
      }

      historical_data_asset_security_ref_data_v(where: {operative_session_id: {_eq: $id}}) {
        asset_id
        description
        instrument_class
        instrument_code
        instrument_isin
        market_code
        symbol
        trading_currency
      }
    }`,
    variables: {
      id,
    },
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}
