import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLoadingState as setStrategyLoadingState } from 'store/resources/strategies/actions'
import { setLoadingState as setPortfolioLoadingState } from 'store/resources/portfolios/actions'
import { setLoadingState as setAssetLoadingState } from 'store/resources/assets/actions'
import { setLoadingState as setGroupsLoadingState } from 'store/resources/groups/actions'
import { LoadingState } from 'types/enums'
import { useTranslation } from 'react-i18next'
import {
  selectPortfoliosState,
  selectPortfoliosErrorcode,
  selectStrategiesState,
  selectStrategiesErrorCode,
  selectAssetsState,
  selectAssetsErrorCode,
  selectGroupsState,
  selectGroupsErrorCode,
} from 'store/resources/selectors'
import { displayNotification } from 'core/helpers'

const NotificationManager: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const watchStrategies = () => {
    const loadingState = useSelector(selectStrategiesState)
    const detailedError = useSelector(selectStrategiesErrorCode)
    useEffect(() => {
      displayNotification(loadingState, detailedError.erroCode, detailedError.errorDescription)
      dispatch(setStrategyLoadingState(LoadingState.Unset))
    }, [dispatch, t, loadingState])
  }

  const watchPortfolios = () => {
    const loadingState = useSelector(selectPortfoliosState)
    const detailedError = useSelector(selectPortfoliosErrorcode)

    useEffect(() => {
      displayNotification(loadingState, detailedError.erroCode, detailedError.errorDescription)
      dispatch(setPortfolioLoadingState(LoadingState.Unset))
    }, [dispatch, t, loadingState])
  }

  const watchAssets = () => {
    const loadingState = useSelector(selectAssetsState)
    const detailedError = useSelector(selectAssetsErrorCode)

    useEffect(() => {
      displayNotification(loadingState, detailedError.erroCode, detailedError.errorDescription)
      dispatch(setAssetLoadingState(LoadingState.Unset))
    }, [dispatch, t, loadingState])
  }

  const watchGroups = () => {
    const loadingState = useSelector(selectGroupsState)
    const detailedError = useSelector(selectGroupsErrorCode)

    useEffect(() => {
      displayNotification(loadingState, detailedError.erroCode, detailedError.errorDescription)
      dispatch(setGroupsLoadingState(LoadingState.Unset))
    }, [dispatch, t, loadingState])
  }

  watchStrategies()
  watchPortfolios()
  watchAssets()
  watchGroups()

  return null
}

export default NotificationManager
