import { createSelector } from 'reselect'
import * as Store from 'types/store'

const layouts = (state: { root: { layouts } }) => state.root.layouts as Store.LayoutState

// Data Selectors
export const selectLayouts = createSelector(layouts, (slice) => slice as Store.LayoutState)
export const selectLayoutsData = createSelector(layouts, (slice) => slice.data)
export const selectLayoutsState = createSelector(layouts, (slice) => slice.state)
export const selectErrorCode = createSelector(layouts, (slice) => slice.errorCode)
