// source: asset_analysis_statistics.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.assets.analysis.alive', null, global);
goog.exportSymbol('proto.assets.analysis.asset_cap_factor', null, global);
goog.exportSymbol('proto.assets.analysis.asset_max_drawdown', null, global);
goog.exportSymbol('proto.assets.analysis.asset_perc_ret', null, global);
goog.exportSymbol('proto.assets.analysis.asset_statistical_indicators', null, global);
goog.exportSymbol('proto.assets.analysis.decimal', null, global);
goog.exportSymbol('proto.assets.analysis.end_snapshot', null, global);
goog.exportSymbol('proto.assets.analysis.feed_event', null, global);
goog.exportSymbol('proto.assets.analysis.feed_event.EventTypeCase', null, global);
goog.exportSymbol('proto.assets.analysis.svc_status', null, global);
goog.exportSymbol('proto.assets.analysis.tstamp', null, global);
goog.exportSymbol('proto.assets.analysis.update_payload', null, global);
goog.exportSymbol('proto.assets.analysis.update_snapshot', null, global);
goog.exportSymbol('proto.assets.analysis.update_ticker', null, global);
goog.exportSymbol('proto.assets.analysis.update_type', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.analysis.tstamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.analysis.tstamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.analysis.tstamp.displayName = 'proto.assets.analysis.tstamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.analysis.decimal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.analysis.decimal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.analysis.decimal.displayName = 'proto.assets.analysis.decimal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.analysis.asset_perc_ret = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.analysis.asset_perc_ret, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.analysis.asset_perc_ret.displayName = 'proto.assets.analysis.asset_perc_ret';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.analysis.asset_statistical_indicators = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.analysis.asset_statistical_indicators, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.analysis.asset_statistical_indicators.displayName = 'proto.assets.analysis.asset_statistical_indicators';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.analysis.asset_max_drawdown = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.analysis.asset_max_drawdown, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.analysis.asset_max_drawdown.displayName = 'proto.assets.analysis.asset_max_drawdown';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.analysis.asset_cap_factor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.analysis.asset_cap_factor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.analysis.asset_cap_factor.displayName = 'proto.assets.analysis.asset_cap_factor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.analysis.update_payload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.assets.analysis.update_payload.repeatedFields_, null);
};
goog.inherits(proto.assets.analysis.update_payload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.analysis.update_payload.displayName = 'proto.assets.analysis.update_payload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.analysis.update_snapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.analysis.update_snapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.analysis.update_snapshot.displayName = 'proto.assets.analysis.update_snapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.analysis.end_snapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.analysis.end_snapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.analysis.end_snapshot.displayName = 'proto.assets.analysis.end_snapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.analysis.update_ticker = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.analysis.update_ticker, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.analysis.update_ticker.displayName = 'proto.assets.analysis.update_ticker';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.analysis.alive = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.analysis.alive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.analysis.alive.displayName = 'proto.assets.analysis.alive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.analysis.feed_event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.assets.analysis.feed_event.oneofGroups_);
};
goog.inherits(proto.assets.analysis.feed_event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.analysis.feed_event.displayName = 'proto.assets.analysis.feed_event';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.analysis.tstamp.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.analysis.tstamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.analysis.tstamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.tstamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    offset: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    code: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.analysis.tstamp}
 */
proto.assets.analysis.tstamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.analysis.tstamp;
  return proto.assets.analysis.tstamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.analysis.tstamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.analysis.tstamp}
 */
proto.assets.analysis.tstamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.analysis.tstamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.analysis.tstamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.analysis.tstamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.tstamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * required sint64 time = 1;
 * @return {number}
 */
proto.assets.analysis.tstamp.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.analysis.tstamp} returns this
 */
proto.assets.analysis.tstamp.prototype.setTime = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.analysis.tstamp} returns this
 */
proto.assets.analysis.tstamp.prototype.clearTime = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.tstamp.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required sint32 offset = 2;
 * @return {number}
 */
proto.assets.analysis.tstamp.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.analysis.tstamp} returns this
 */
proto.assets.analysis.tstamp.prototype.setOffset = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.analysis.tstamp} returns this
 */
proto.assets.analysis.tstamp.prototype.clearOffset = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.tstamp.prototype.hasOffset = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required uint32 code = 3;
 * @return {number}
 */
proto.assets.analysis.tstamp.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.analysis.tstamp} returns this
 */
proto.assets.analysis.tstamp.prototype.setCode = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.analysis.tstamp} returns this
 */
proto.assets.analysis.tstamp.prototype.clearCode = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.tstamp.prototype.hasCode = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.analysis.decimal.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.analysis.decimal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.analysis.decimal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.decimal.toObject = function(includeInstance, msg) {
  var f, obj = {
    coeff: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    exp: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.analysis.decimal}
 */
proto.assets.analysis.decimal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.analysis.decimal;
  return proto.assets.analysis.decimal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.analysis.decimal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.analysis.decimal}
 */
proto.assets.analysis.decimal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setCoeff(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.analysis.decimal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.analysis.decimal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.analysis.decimal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.decimal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeSint32(
      2,
      f
    );
  }
};


/**
 * required sint64 coeff = 1;
 * @return {number}
 */
proto.assets.analysis.decimal.prototype.getCoeff = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.analysis.decimal} returns this
 */
proto.assets.analysis.decimal.prototype.setCoeff = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.analysis.decimal} returns this
 */
proto.assets.analysis.decimal.prototype.clearCoeff = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.decimal.prototype.hasCoeff = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required sint32 exp = 2;
 * @return {number}
 */
proto.assets.analysis.decimal.prototype.getExp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.analysis.decimal} returns this
 */
proto.assets.analysis.decimal.prototype.setExp = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.analysis.decimal} returns this
 */
proto.assets.analysis.decimal.prototype.clearExp = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.decimal.prototype.hasExp = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.analysis.asset_perc_ret.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.analysis.asset_perc_ret.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.analysis.asset_perc_ret} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.asset_perc_ret.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    percRet: (f = msg.getPercRet()) && proto.assets.analysis.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.analysis.asset_perc_ret}
 */
proto.assets.analysis.asset_perc_ret.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.analysis.asset_perc_ret;
  return proto.assets.analysis.asset_perc_ret.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.analysis.asset_perc_ret} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.analysis.asset_perc_ret}
 */
proto.assets.analysis.asset_perc_ret.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setPercRet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.analysis.asset_perc_ret.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.analysis.asset_perc_ret.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.analysis.asset_perc_ret} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.asset_perc_ret.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPercRet();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * required string type = 1;
 * @return {string}
 */
proto.assets.analysis.asset_perc_ret.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.analysis.asset_perc_ret} returns this
 */
proto.assets.analysis.asset_perc_ret.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.analysis.asset_perc_ret} returns this
 */
proto.assets.analysis.asset_perc_ret.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_perc_ret.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal perc_ret = 2;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_perc_ret.prototype.getPercRet = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 2));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_perc_ret} returns this
*/
proto.assets.analysis.asset_perc_ret.prototype.setPercRet = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_perc_ret} returns this
 */
proto.assets.analysis.asset_perc_ret.prototype.clearPercRet = function() {
  return this.setPercRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_perc_ret.prototype.hasPercRet = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.analysis.asset_statistical_indicators.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.analysis.asset_statistical_indicators} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.asset_statistical_indicators.toObject = function(includeInstance, msg) {
  var f, obj = {
    tdyMean: (f = msg.getTdyMean()) && proto.assets.analysis.decimal.toObject(includeInstance, f),
    ytdMean: (f = msg.getYtdMean()) && proto.assets.analysis.decimal.toObject(includeInstance, f),
    totMean: (f = msg.getTotMean()) && proto.assets.analysis.decimal.toObject(includeInstance, f),
    tdyMean2: (f = msg.getTdyMean2()) && proto.assets.analysis.decimal.toObject(includeInstance, f),
    ytdMean2: (f = msg.getYtdMean2()) && proto.assets.analysis.decimal.toObject(includeInstance, f),
    totMean2: (f = msg.getTotMean2()) && proto.assets.analysis.decimal.toObject(includeInstance, f),
    tdyStdDev: (f = msg.getTdyStdDev()) && proto.assets.analysis.decimal.toObject(includeInstance, f),
    ytdStdDev: (f = msg.getYtdStdDev()) && proto.assets.analysis.decimal.toObject(includeInstance, f),
    totStdDev: (f = msg.getTotStdDev()) && proto.assets.analysis.decimal.toObject(includeInstance, f),
    totSharpeRatio: (f = msg.getTotSharpeRatio()) && proto.assets.analysis.decimal.toObject(includeInstance, f),
    totMean3: (f = msg.getTotMean3()) && proto.assets.analysis.decimal.toObject(includeInstance, f),
    totMean4: (f = msg.getTotMean4()) && proto.assets.analysis.decimal.toObject(includeInstance, f),
    totSkewnessIndex: (f = msg.getTotSkewnessIndex()) && proto.assets.analysis.decimal.toObject(includeInstance, f),
    totKurtosisIndex: (f = msg.getTotKurtosisIndex()) && proto.assets.analysis.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.analysis.asset_statistical_indicators}
 */
proto.assets.analysis.asset_statistical_indicators.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.analysis.asset_statistical_indicators;
  return proto.assets.analysis.asset_statistical_indicators.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.analysis.asset_statistical_indicators} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.analysis.asset_statistical_indicators}
 */
proto.assets.analysis.asset_statistical_indicators.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setTdyMean(value);
      break;
    case 2:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setYtdMean(value);
      break;
    case 3:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setTotMean(value);
      break;
    case 4:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setTdyMean2(value);
      break;
    case 5:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setYtdMean2(value);
      break;
    case 6:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setTotMean2(value);
      break;
    case 7:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setTdyStdDev(value);
      break;
    case 8:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setYtdStdDev(value);
      break;
    case 9:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setTotStdDev(value);
      break;
    case 10:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setTotSharpeRatio(value);
      break;
    case 11:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setTotMean3(value);
      break;
    case 12:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setTotMean4(value);
      break;
    case 13:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setTotSkewnessIndex(value);
      break;
    case 14:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setTotKurtosisIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.analysis.asset_statistical_indicators.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.analysis.asset_statistical_indicators} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.asset_statistical_indicators.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTdyMean();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
  f = message.getYtdMean();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTotMean();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTdyMean2();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
  f = message.getYtdMean2();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTotMean2();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTdyStdDev();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
  f = message.getYtdStdDev();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTotStdDev();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTotSharpeRatio();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTotMean3();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTotMean4();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTotSkewnessIndex();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTotKurtosisIndex();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal tdy_mean = 1;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.getTdyMean = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 1));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
*/
proto.assets.analysis.asset_statistical_indicators.prototype.setTdyMean = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
 */
proto.assets.analysis.asset_statistical_indicators.prototype.clearTdyMean = function() {
  return this.setTdyMean(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.hasTdyMean = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal ytd_mean = 2;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.getYtdMean = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 2));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
*/
proto.assets.analysis.asset_statistical_indicators.prototype.setYtdMean = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
 */
proto.assets.analysis.asset_statistical_indicators.prototype.clearYtdMean = function() {
  return this.setYtdMean(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.hasYtdMean = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional decimal tot_mean = 3;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.getTotMean = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 3));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
*/
proto.assets.analysis.asset_statistical_indicators.prototype.setTotMean = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
 */
proto.assets.analysis.asset_statistical_indicators.prototype.clearTotMean = function() {
  return this.setTotMean(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.hasTotMean = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional decimal tdy_mean2 = 4;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.getTdyMean2 = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 4));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
*/
proto.assets.analysis.asset_statistical_indicators.prototype.setTdyMean2 = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
 */
proto.assets.analysis.asset_statistical_indicators.prototype.clearTdyMean2 = function() {
  return this.setTdyMean2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.hasTdyMean2 = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional decimal ytd_mean2 = 5;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.getYtdMean2 = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 5));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
*/
proto.assets.analysis.asset_statistical_indicators.prototype.setYtdMean2 = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
 */
proto.assets.analysis.asset_statistical_indicators.prototype.clearYtdMean2 = function() {
  return this.setYtdMean2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.hasYtdMean2 = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional decimal tot_mean2 = 6;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.getTotMean2 = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 6));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
*/
proto.assets.analysis.asset_statistical_indicators.prototype.setTotMean2 = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
 */
proto.assets.analysis.asset_statistical_indicators.prototype.clearTotMean2 = function() {
  return this.setTotMean2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.hasTotMean2 = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional decimal tdy_std_dev = 7;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.getTdyStdDev = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 7));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
*/
proto.assets.analysis.asset_statistical_indicators.prototype.setTdyStdDev = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
 */
proto.assets.analysis.asset_statistical_indicators.prototype.clearTdyStdDev = function() {
  return this.setTdyStdDev(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.hasTdyStdDev = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional decimal ytd_std_dev = 8;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.getYtdStdDev = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 8));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
*/
proto.assets.analysis.asset_statistical_indicators.prototype.setYtdStdDev = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
 */
proto.assets.analysis.asset_statistical_indicators.prototype.clearYtdStdDev = function() {
  return this.setYtdStdDev(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.hasYtdStdDev = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional decimal tot_std_dev = 9;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.getTotStdDev = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 9));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
*/
proto.assets.analysis.asset_statistical_indicators.prototype.setTotStdDev = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
 */
proto.assets.analysis.asset_statistical_indicators.prototype.clearTotStdDev = function() {
  return this.setTotStdDev(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.hasTotStdDev = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional decimal tot_sharpe_ratio = 10;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.getTotSharpeRatio = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 10));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
*/
proto.assets.analysis.asset_statistical_indicators.prototype.setTotSharpeRatio = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
 */
proto.assets.analysis.asset_statistical_indicators.prototype.clearTotSharpeRatio = function() {
  return this.setTotSharpeRatio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.hasTotSharpeRatio = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional decimal tot_mean3 = 11;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.getTotMean3 = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 11));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
*/
proto.assets.analysis.asset_statistical_indicators.prototype.setTotMean3 = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
 */
proto.assets.analysis.asset_statistical_indicators.prototype.clearTotMean3 = function() {
  return this.setTotMean3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.hasTotMean3 = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional decimal tot_mean4 = 12;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.getTotMean4 = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 12));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
*/
proto.assets.analysis.asset_statistical_indicators.prototype.setTotMean4 = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
 */
proto.assets.analysis.asset_statistical_indicators.prototype.clearTotMean4 = function() {
  return this.setTotMean4(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.hasTotMean4 = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional decimal tot_skewness_index = 13;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.getTotSkewnessIndex = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 13));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
*/
proto.assets.analysis.asset_statistical_indicators.prototype.setTotSkewnessIndex = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
 */
proto.assets.analysis.asset_statistical_indicators.prototype.clearTotSkewnessIndex = function() {
  return this.setTotSkewnessIndex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.hasTotSkewnessIndex = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional decimal tot_kurtosis_index = 14;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.getTotKurtosisIndex = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 14));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
*/
proto.assets.analysis.asset_statistical_indicators.prototype.setTotKurtosisIndex = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_statistical_indicators} returns this
 */
proto.assets.analysis.asset_statistical_indicators.prototype.clearTotKurtosisIndex = function() {
  return this.setTotKurtosisIndex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_statistical_indicators.prototype.hasTotKurtosisIndex = function() {
  return jspb.Message.getField(this, 14) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.analysis.asset_max_drawdown.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.analysis.asset_max_drawdown.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.analysis.asset_max_drawdown} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.asset_max_drawdown.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    assetMaxDrawdown: (f = msg.getAssetMaxDrawdown()) && proto.assets.analysis.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.analysis.asset_max_drawdown}
 */
proto.assets.analysis.asset_max_drawdown.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.analysis.asset_max_drawdown;
  return proto.assets.analysis.asset_max_drawdown.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.analysis.asset_max_drawdown} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.analysis.asset_max_drawdown}
 */
proto.assets.analysis.asset_max_drawdown.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setAssetMaxDrawdown(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.analysis.asset_max_drawdown.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.analysis.asset_max_drawdown.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.analysis.asset_max_drawdown} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.asset_max_drawdown.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetMaxDrawdown();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * required string type = 1;
 * @return {string}
 */
proto.assets.analysis.asset_max_drawdown.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.analysis.asset_max_drawdown} returns this
 */
proto.assets.analysis.asset_max_drawdown.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.analysis.asset_max_drawdown} returns this
 */
proto.assets.analysis.asset_max_drawdown.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_max_drawdown.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal asset_max_drawdown = 2;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_max_drawdown.prototype.getAssetMaxDrawdown = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 2));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_max_drawdown} returns this
*/
proto.assets.analysis.asset_max_drawdown.prototype.setAssetMaxDrawdown = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_max_drawdown} returns this
 */
proto.assets.analysis.asset_max_drawdown.prototype.clearAssetMaxDrawdown = function() {
  return this.setAssetMaxDrawdown(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_max_drawdown.prototype.hasAssetMaxDrawdown = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.analysis.asset_cap_factor.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.analysis.asset_cap_factor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.analysis.asset_cap_factor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.asset_cap_factor.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetTdyCapFactor: (f = msg.getAssetTdyCapFactor()) && proto.assets.analysis.decimal.toObject(includeInstance, f),
    assetTdyMaxCapFactor: (f = msg.getAssetTdyMaxCapFactor()) && proto.assets.analysis.decimal.toObject(includeInstance, f),
    assetYtdCapFactor: (f = msg.getAssetYtdCapFactor()) && proto.assets.analysis.decimal.toObject(includeInstance, f),
    assetYtdMaxCapFactor: (f = msg.getAssetYtdMaxCapFactor()) && proto.assets.analysis.decimal.toObject(includeInstance, f),
    assetTotCapFactor: (f = msg.getAssetTotCapFactor()) && proto.assets.analysis.decimal.toObject(includeInstance, f),
    assetTotMaxCapFactor: (f = msg.getAssetTotMaxCapFactor()) && proto.assets.analysis.decimal.toObject(includeInstance, f),
    midnightTs: (f = msg.getMidnightTs()) && proto.assets.analysis.tstamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.analysis.asset_cap_factor}
 */
proto.assets.analysis.asset_cap_factor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.analysis.asset_cap_factor;
  return proto.assets.analysis.asset_cap_factor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.analysis.asset_cap_factor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.analysis.asset_cap_factor}
 */
proto.assets.analysis.asset_cap_factor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyCapFactor(value);
      break;
    case 2:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyMaxCapFactor(value);
      break;
    case 3:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setAssetYtdCapFactor(value);
      break;
    case 4:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setAssetYtdMaxCapFactor(value);
      break;
    case 5:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setAssetTotCapFactor(value);
      break;
    case 6:
      var value = new proto.assets.analysis.decimal;
      reader.readMessage(value,proto.assets.analysis.decimal.deserializeBinaryFromReader);
      msg.setAssetTotMaxCapFactor(value);
      break;
    case 7:
      var value = new proto.assets.analysis.tstamp;
      reader.readMessage(value,proto.assets.analysis.tstamp.deserializeBinaryFromReader);
      msg.setMidnightTs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.analysis.asset_cap_factor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.analysis.asset_cap_factor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.analysis.asset_cap_factor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.asset_cap_factor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetTdyCapFactor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyMaxCapFactor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetYtdCapFactor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetYtdMaxCapFactor();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTotCapFactor();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTotMaxCapFactor();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.analysis.decimal.serializeBinaryToWriter
    );
  }
  f = message.getMidnightTs();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.assets.analysis.tstamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal asset_tdy_cap_factor = 1;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_cap_factor.prototype.getAssetTdyCapFactor = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 1));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_cap_factor} returns this
*/
proto.assets.analysis.asset_cap_factor.prototype.setAssetTdyCapFactor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_cap_factor} returns this
 */
proto.assets.analysis.asset_cap_factor.prototype.clearAssetTdyCapFactor = function() {
  return this.setAssetTdyCapFactor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_cap_factor.prototype.hasAssetTdyCapFactor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal asset_tdy_max_cap_factor = 2;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_cap_factor.prototype.getAssetTdyMaxCapFactor = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 2));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_cap_factor} returns this
*/
proto.assets.analysis.asset_cap_factor.prototype.setAssetTdyMaxCapFactor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_cap_factor} returns this
 */
proto.assets.analysis.asset_cap_factor.prototype.clearAssetTdyMaxCapFactor = function() {
  return this.setAssetTdyMaxCapFactor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_cap_factor.prototype.hasAssetTdyMaxCapFactor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional decimal asset_ytd_cap_factor = 3;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_cap_factor.prototype.getAssetYtdCapFactor = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 3));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_cap_factor} returns this
*/
proto.assets.analysis.asset_cap_factor.prototype.setAssetYtdCapFactor = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_cap_factor} returns this
 */
proto.assets.analysis.asset_cap_factor.prototype.clearAssetYtdCapFactor = function() {
  return this.setAssetYtdCapFactor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_cap_factor.prototype.hasAssetYtdCapFactor = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional decimal asset_ytd_max_cap_factor = 4;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_cap_factor.prototype.getAssetYtdMaxCapFactor = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 4));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_cap_factor} returns this
*/
proto.assets.analysis.asset_cap_factor.prototype.setAssetYtdMaxCapFactor = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_cap_factor} returns this
 */
proto.assets.analysis.asset_cap_factor.prototype.clearAssetYtdMaxCapFactor = function() {
  return this.setAssetYtdMaxCapFactor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_cap_factor.prototype.hasAssetYtdMaxCapFactor = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional decimal asset_tot_cap_factor = 5;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_cap_factor.prototype.getAssetTotCapFactor = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 5));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_cap_factor} returns this
*/
proto.assets.analysis.asset_cap_factor.prototype.setAssetTotCapFactor = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_cap_factor} returns this
 */
proto.assets.analysis.asset_cap_factor.prototype.clearAssetTotCapFactor = function() {
  return this.setAssetTotCapFactor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_cap_factor.prototype.hasAssetTotCapFactor = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional decimal asset_tot_max_cap_factor = 6;
 * @return {?proto.assets.analysis.decimal}
 */
proto.assets.analysis.asset_cap_factor.prototype.getAssetTotMaxCapFactor = function() {
  return /** @type{?proto.assets.analysis.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.decimal, 6));
};


/**
 * @param {?proto.assets.analysis.decimal|undefined} value
 * @return {!proto.assets.analysis.asset_cap_factor} returns this
*/
proto.assets.analysis.asset_cap_factor.prototype.setAssetTotMaxCapFactor = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_cap_factor} returns this
 */
proto.assets.analysis.asset_cap_factor.prototype.clearAssetTotMaxCapFactor = function() {
  return this.setAssetTotMaxCapFactor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_cap_factor.prototype.hasAssetTotMaxCapFactor = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional tstamp midnight_ts = 7;
 * @return {?proto.assets.analysis.tstamp}
 */
proto.assets.analysis.asset_cap_factor.prototype.getMidnightTs = function() {
  return /** @type{?proto.assets.analysis.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.tstamp, 7));
};


/**
 * @param {?proto.assets.analysis.tstamp|undefined} value
 * @return {!proto.assets.analysis.asset_cap_factor} returns this
*/
proto.assets.analysis.asset_cap_factor.prototype.setMidnightTs = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.asset_cap_factor} returns this
 */
proto.assets.analysis.asset_cap_factor.prototype.clearMidnightTs = function() {
  return this.setMidnightTs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.asset_cap_factor.prototype.hasMidnightTs = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.assets.analysis.update_payload.repeatedFields_ = [42,44];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.analysis.update_payload.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.analysis.update_payload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.analysis.update_payload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.update_payload.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetPercRetList: jspb.Message.toObjectList(msg.getAssetPercRetList(),
    proto.assets.analysis.asset_perc_ret.toObject, includeInstance),
    assetStatisticalIndicators: (f = msg.getAssetStatisticalIndicators()) && proto.assets.analysis.asset_statistical_indicators.toObject(includeInstance, f),
    assetMaxDrawdownList: jspb.Message.toObjectList(msg.getAssetMaxDrawdownList(),
    proto.assets.analysis.asset_max_drawdown.toObject, includeInstance),
    assetCapFactor: (f = msg.getAssetCapFactor()) && proto.assets.analysis.asset_cap_factor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.analysis.update_payload}
 */
proto.assets.analysis.update_payload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.analysis.update_payload;
  return proto.assets.analysis.update_payload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.analysis.update_payload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.analysis.update_payload}
 */
proto.assets.analysis.update_payload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 42:
      var value = new proto.assets.analysis.asset_perc_ret;
      reader.readMessage(value,proto.assets.analysis.asset_perc_ret.deserializeBinaryFromReader);
      msg.addAssetPercRet(value);
      break;
    case 43:
      var value = new proto.assets.analysis.asset_statistical_indicators;
      reader.readMessage(value,proto.assets.analysis.asset_statistical_indicators.deserializeBinaryFromReader);
      msg.setAssetStatisticalIndicators(value);
      break;
    case 44:
      var value = new proto.assets.analysis.asset_max_drawdown;
      reader.readMessage(value,proto.assets.analysis.asset_max_drawdown.deserializeBinaryFromReader);
      msg.addAssetMaxDrawdown(value);
      break;
    case 45:
      var value = new proto.assets.analysis.asset_cap_factor;
      reader.readMessage(value,proto.assets.analysis.asset_cap_factor.deserializeBinaryFromReader);
      msg.setAssetCapFactor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.analysis.update_payload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.analysis.update_payload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.analysis.update_payload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.update_payload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetPercRetList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      42,
      f,
      proto.assets.analysis.asset_perc_ret.serializeBinaryToWriter
    );
  }
  f = message.getAssetStatisticalIndicators();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      proto.assets.analysis.asset_statistical_indicators.serializeBinaryToWriter
    );
  }
  f = message.getAssetMaxDrawdownList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      44,
      f,
      proto.assets.analysis.asset_max_drawdown.serializeBinaryToWriter
    );
  }
  f = message.getAssetCapFactor();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      proto.assets.analysis.asset_cap_factor.serializeBinaryToWriter
    );
  }
};


/**
 * repeated asset_perc_ret asset_perc_ret_ = 42;
 * @return {!Array<!proto.assets.analysis.asset_perc_ret>}
 */
proto.assets.analysis.update_payload.prototype.getAssetPercRetList = function() {
  return /** @type{!Array<!proto.assets.analysis.asset_perc_ret>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.assets.analysis.asset_perc_ret, 42));
};


/**
 * @param {!Array<!proto.assets.analysis.asset_perc_ret>} value
 * @return {!proto.assets.analysis.update_payload} returns this
*/
proto.assets.analysis.update_payload.prototype.setAssetPercRetList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 42, value);
};


/**
 * @param {!proto.assets.analysis.asset_perc_ret=} opt_value
 * @param {number=} opt_index
 * @return {!proto.assets.analysis.asset_perc_ret}
 */
proto.assets.analysis.update_payload.prototype.addAssetPercRet = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 42, opt_value, proto.assets.analysis.asset_perc_ret, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.assets.analysis.update_payload} returns this
 */
proto.assets.analysis.update_payload.prototype.clearAssetPercRetList = function() {
  return this.setAssetPercRetList([]);
};


/**
 * optional asset_statistical_indicators asset_statistical_indicators_ = 43;
 * @return {?proto.assets.analysis.asset_statistical_indicators}
 */
proto.assets.analysis.update_payload.prototype.getAssetStatisticalIndicators = function() {
  return /** @type{?proto.assets.analysis.asset_statistical_indicators} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.asset_statistical_indicators, 43));
};


/**
 * @param {?proto.assets.analysis.asset_statistical_indicators|undefined} value
 * @return {!proto.assets.analysis.update_payload} returns this
*/
proto.assets.analysis.update_payload.prototype.setAssetStatisticalIndicators = function(value) {
  return jspb.Message.setWrapperField(this, 43, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.update_payload} returns this
 */
proto.assets.analysis.update_payload.prototype.clearAssetStatisticalIndicators = function() {
  return this.setAssetStatisticalIndicators(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.update_payload.prototype.hasAssetStatisticalIndicators = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * repeated asset_max_drawdown asset_max_drawdown_ = 44;
 * @return {!Array<!proto.assets.analysis.asset_max_drawdown>}
 */
proto.assets.analysis.update_payload.prototype.getAssetMaxDrawdownList = function() {
  return /** @type{!Array<!proto.assets.analysis.asset_max_drawdown>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.assets.analysis.asset_max_drawdown, 44));
};


/**
 * @param {!Array<!proto.assets.analysis.asset_max_drawdown>} value
 * @return {!proto.assets.analysis.update_payload} returns this
*/
proto.assets.analysis.update_payload.prototype.setAssetMaxDrawdownList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 44, value);
};


/**
 * @param {!proto.assets.analysis.asset_max_drawdown=} opt_value
 * @param {number=} opt_index
 * @return {!proto.assets.analysis.asset_max_drawdown}
 */
proto.assets.analysis.update_payload.prototype.addAssetMaxDrawdown = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 44, opt_value, proto.assets.analysis.asset_max_drawdown, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.assets.analysis.update_payload} returns this
 */
proto.assets.analysis.update_payload.prototype.clearAssetMaxDrawdownList = function() {
  return this.setAssetMaxDrawdownList([]);
};


/**
 * optional asset_cap_factor asset_cap_factor_ = 45;
 * @return {?proto.assets.analysis.asset_cap_factor}
 */
proto.assets.analysis.update_payload.prototype.getAssetCapFactor = function() {
  return /** @type{?proto.assets.analysis.asset_cap_factor} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.asset_cap_factor, 45));
};


/**
 * @param {?proto.assets.analysis.asset_cap_factor|undefined} value
 * @return {!proto.assets.analysis.update_payload} returns this
*/
proto.assets.analysis.update_payload.prototype.setAssetCapFactor = function(value) {
  return jspb.Message.setWrapperField(this, 45, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.update_payload} returns this
 */
proto.assets.analysis.update_payload.prototype.clearAssetCapFactor = function() {
  return this.setAssetCapFactor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.update_payload.prototype.hasAssetCapFactor = function() {
  return jspb.Message.getField(this, 45) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.analysis.update_snapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.analysis.update_snapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.analysis.update_snapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.update_snapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    updateType: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    key: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    eventTs: (f = msg.getEventTs()) && proto.assets.analysis.tstamp.toObject(includeInstance, f),
    sigmas: (f = msg.getSigmas()) && proto.assets.analysis.update_payload.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.analysis.update_snapshot}
 */
proto.assets.analysis.update_snapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.analysis.update_snapshot;
  return proto.assets.analysis.update_snapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.analysis.update_snapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.analysis.update_snapshot}
 */
proto.assets.analysis.update_snapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {!proto.assets.analysis.update_type} */ (reader.readEnum());
      msg.setUpdateType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 5:
      var value = new proto.assets.analysis.tstamp;
      reader.readMessage(value,proto.assets.analysis.tstamp.deserializeBinaryFromReader);
      msg.setEventTs(value);
      break;
    case 6:
      var value = new proto.assets.analysis.update_payload;
      reader.readMessage(value,proto.assets.analysis.update_payload.deserializeBinaryFromReader);
      msg.setSigmas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.analysis.update_snapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.analysis.update_snapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.analysis.update_snapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.update_snapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!proto.assets.analysis.update_type} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEventTs();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.analysis.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getSigmas();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.analysis.update_payload.serializeBinaryToWriter
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.assets.analysis.update_snapshot.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.analysis.update_snapshot} returns this
 */
proto.assets.analysis.update_snapshot.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.analysis.update_snapshot} returns this
 */
proto.assets.analysis.update_snapshot.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.update_snapshot.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required update_type update_type = 2;
 * @return {!proto.assets.analysis.update_type}
 */
proto.assets.analysis.update_snapshot.prototype.getUpdateType = function() {
  return /** @type {!proto.assets.analysis.update_type} */ (jspb.Message.getFieldWithDefault(this, 2, 1));
};


/**
 * @param {!proto.assets.analysis.update_type} value
 * @return {!proto.assets.analysis.update_snapshot} returns this
 */
proto.assets.analysis.update_snapshot.prototype.setUpdateType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.analysis.update_snapshot} returns this
 */
proto.assets.analysis.update_snapshot.prototype.clearUpdateType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.update_snapshot.prototype.hasUpdateType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required string service = 3;
 * @return {string}
 */
proto.assets.analysis.update_snapshot.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.analysis.update_snapshot} returns this
 */
proto.assets.analysis.update_snapshot.prototype.setService = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.analysis.update_snapshot} returns this
 */
proto.assets.analysis.update_snapshot.prototype.clearService = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.update_snapshot.prototype.hasService = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required string key = 4;
 * @return {string}
 */
proto.assets.analysis.update_snapshot.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.analysis.update_snapshot} returns this
 */
proto.assets.analysis.update_snapshot.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.analysis.update_snapshot} returns this
 */
proto.assets.analysis.update_snapshot.prototype.clearKey = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.update_snapshot.prototype.hasKey = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required tstamp event_ts = 5;
 * @return {!proto.assets.analysis.tstamp}
 */
proto.assets.analysis.update_snapshot.prototype.getEventTs = function() {
  return /** @type{!proto.assets.analysis.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.tstamp, 5, 1));
};


/**
 * @param {!proto.assets.analysis.tstamp} value
 * @return {!proto.assets.analysis.update_snapshot} returns this
*/
proto.assets.analysis.update_snapshot.prototype.setEventTs = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.analysis.update_snapshot} returns this
 */
proto.assets.analysis.update_snapshot.prototype.clearEventTs = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.update_snapshot.prototype.hasEventTs = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional update_payload sigmas = 6;
 * @return {?proto.assets.analysis.update_payload}
 */
proto.assets.analysis.update_snapshot.prototype.getSigmas = function() {
  return /** @type{?proto.assets.analysis.update_payload} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.update_payload, 6));
};


/**
 * @param {?proto.assets.analysis.update_payload|undefined} value
 * @return {!proto.assets.analysis.update_snapshot} returns this
*/
proto.assets.analysis.update_snapshot.prototype.setSigmas = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.update_snapshot} returns this
 */
proto.assets.analysis.update_snapshot.prototype.clearSigmas = function() {
  return this.setSigmas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.update_snapshot.prototype.hasSigmas = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.analysis.end_snapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.analysis.end_snapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.analysis.end_snapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.end_snapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.analysis.end_snapshot}
 */
proto.assets.analysis.end_snapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.analysis.end_snapshot;
  return proto.assets.analysis.end_snapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.analysis.end_snapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.analysis.end_snapshot}
 */
proto.assets.analysis.end_snapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.analysis.end_snapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.analysis.end_snapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.analysis.end_snapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.end_snapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.assets.analysis.end_snapshot.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.analysis.end_snapshot} returns this
 */
proto.assets.analysis.end_snapshot.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.analysis.end_snapshot} returns this
 */
proto.assets.analysis.end_snapshot.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.end_snapshot.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required string service = 2;
 * @return {string}
 */
proto.assets.analysis.end_snapshot.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.analysis.end_snapshot} returns this
 */
proto.assets.analysis.end_snapshot.prototype.setService = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.analysis.end_snapshot} returns this
 */
proto.assets.analysis.end_snapshot.prototype.clearService = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.end_snapshot.prototype.hasService = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.analysis.update_ticker.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.analysis.update_ticker.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.analysis.update_ticker} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.update_ticker.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    updateType: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    sequence: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    key: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    eventTs: (f = msg.getEventTs()) && proto.assets.analysis.tstamp.toObject(includeInstance, f),
    sigmas: (f = msg.getSigmas()) && proto.assets.analysis.update_payload.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.analysis.update_ticker}
 */
proto.assets.analysis.update_ticker.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.analysis.update_ticker;
  return proto.assets.analysis.update_ticker.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.analysis.update_ticker} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.analysis.update_ticker}
 */
proto.assets.analysis.update_ticker.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {!proto.assets.analysis.update_type} */ (reader.readEnum());
      msg.setUpdateType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setSequence(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 6:
      var value = new proto.assets.analysis.tstamp;
      reader.readMessage(value,proto.assets.analysis.tstamp.deserializeBinaryFromReader);
      msg.setEventTs(value);
      break;
    case 7:
      var value = new proto.assets.analysis.update_payload;
      reader.readMessage(value,proto.assets.analysis.update_payload.deserializeBinaryFromReader);
      msg.setSigmas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.analysis.update_ticker.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.analysis.update_ticker.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.analysis.update_ticker} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.update_ticker.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!proto.assets.analysis.update_type} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeSint64(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEventTs();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.analysis.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getSigmas();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.assets.analysis.update_payload.serializeBinaryToWriter
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.assets.analysis.update_ticker.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.analysis.update_ticker} returns this
 */
proto.assets.analysis.update_ticker.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.analysis.update_ticker} returns this
 */
proto.assets.analysis.update_ticker.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.update_ticker.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required update_type update_type = 2;
 * @return {!proto.assets.analysis.update_type}
 */
proto.assets.analysis.update_ticker.prototype.getUpdateType = function() {
  return /** @type {!proto.assets.analysis.update_type} */ (jspb.Message.getFieldWithDefault(this, 2, 1));
};


/**
 * @param {!proto.assets.analysis.update_type} value
 * @return {!proto.assets.analysis.update_ticker} returns this
 */
proto.assets.analysis.update_ticker.prototype.setUpdateType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.analysis.update_ticker} returns this
 */
proto.assets.analysis.update_ticker.prototype.clearUpdateType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.update_ticker.prototype.hasUpdateType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required string service = 3;
 * @return {string}
 */
proto.assets.analysis.update_ticker.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.analysis.update_ticker} returns this
 */
proto.assets.analysis.update_ticker.prototype.setService = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.analysis.update_ticker} returns this
 */
proto.assets.analysis.update_ticker.prototype.clearService = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.update_ticker.prototype.hasService = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required sint64 sequence = 4;
 * @return {number}
 */
proto.assets.analysis.update_ticker.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.analysis.update_ticker} returns this
 */
proto.assets.analysis.update_ticker.prototype.setSequence = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.analysis.update_ticker} returns this
 */
proto.assets.analysis.update_ticker.prototype.clearSequence = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.update_ticker.prototype.hasSequence = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required string key = 5;
 * @return {string}
 */
proto.assets.analysis.update_ticker.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.analysis.update_ticker} returns this
 */
proto.assets.analysis.update_ticker.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.analysis.update_ticker} returns this
 */
proto.assets.analysis.update_ticker.prototype.clearKey = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.update_ticker.prototype.hasKey = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * required tstamp event_ts = 6;
 * @return {!proto.assets.analysis.tstamp}
 */
proto.assets.analysis.update_ticker.prototype.getEventTs = function() {
  return /** @type{!proto.assets.analysis.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.tstamp, 6, 1));
};


/**
 * @param {!proto.assets.analysis.tstamp} value
 * @return {!proto.assets.analysis.update_ticker} returns this
*/
proto.assets.analysis.update_ticker.prototype.setEventTs = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.analysis.update_ticker} returns this
 */
proto.assets.analysis.update_ticker.prototype.clearEventTs = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.update_ticker.prototype.hasEventTs = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional update_payload sigmas = 7;
 * @return {?proto.assets.analysis.update_payload}
 */
proto.assets.analysis.update_ticker.prototype.getSigmas = function() {
  return /** @type{?proto.assets.analysis.update_payload} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.update_payload, 7));
};


/**
 * @param {?proto.assets.analysis.update_payload|undefined} value
 * @return {!proto.assets.analysis.update_ticker} returns this
*/
proto.assets.analysis.update_ticker.prototype.setSigmas = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.update_ticker} returns this
 */
proto.assets.analysis.update_ticker.prototype.clearSigmas = function() {
  return this.setSigmas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.update_ticker.prototype.hasSigmas = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.analysis.alive.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.analysis.alive.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.analysis.alive} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.alive.toObject = function(includeInstance, msg) {
  var f, obj = {
    aliveId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.analysis.alive}
 */
proto.assets.analysis.alive.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.analysis.alive;
  return proto.assets.analysis.alive.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.analysis.alive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.analysis.alive}
 */
proto.assets.analysis.alive.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setAliveId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.analysis.alive.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.analysis.alive.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.analysis.alive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.alive.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
};


/**
 * required sint64 alive_id = 1;
 * @return {number}
 */
proto.assets.analysis.alive.prototype.getAliveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.analysis.alive} returns this
 */
proto.assets.analysis.alive.prototype.setAliveId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.analysis.alive} returns this
 */
proto.assets.analysis.alive.prototype.clearAliveId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.alive.prototype.hasAliveId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.assets.analysis.feed_event.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.assets.analysis.feed_event.EventTypeCase = {
  EVENT_TYPE_NOT_SET: 0,
  SNAPSHOT: 1,
  TICKER: 2,
  END_SNAPSHOT: 3,
  ALIVE: 4
};

/**
 * @return {proto.assets.analysis.feed_event.EventTypeCase}
 */
proto.assets.analysis.feed_event.prototype.getEventTypeCase = function() {
  return /** @type {proto.assets.analysis.feed_event.EventTypeCase} */(jspb.Message.computeOneofCase(this, proto.assets.analysis.feed_event.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.analysis.feed_event.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.analysis.feed_event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.analysis.feed_event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.feed_event.toObject = function(includeInstance, msg) {
  var f, obj = {
    snapshot: (f = msg.getSnapshot()) && proto.assets.analysis.update_snapshot.toObject(includeInstance, f),
    ticker: (f = msg.getTicker()) && proto.assets.analysis.update_ticker.toObject(includeInstance, f),
    endSnapshot: (f = msg.getEndSnapshot()) && proto.assets.analysis.end_snapshot.toObject(includeInstance, f),
    alive: (f = msg.getAlive()) && proto.assets.analysis.alive.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.analysis.feed_event}
 */
proto.assets.analysis.feed_event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.analysis.feed_event;
  return proto.assets.analysis.feed_event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.analysis.feed_event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.analysis.feed_event}
 */
proto.assets.analysis.feed_event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.analysis.update_snapshot;
      reader.readMessage(value,proto.assets.analysis.update_snapshot.deserializeBinaryFromReader);
      msg.setSnapshot(value);
      break;
    case 2:
      var value = new proto.assets.analysis.update_ticker;
      reader.readMessage(value,proto.assets.analysis.update_ticker.deserializeBinaryFromReader);
      msg.setTicker(value);
      break;
    case 3:
      var value = new proto.assets.analysis.end_snapshot;
      reader.readMessage(value,proto.assets.analysis.end_snapshot.deserializeBinaryFromReader);
      msg.setEndSnapshot(value);
      break;
    case 4:
      var value = new proto.assets.analysis.alive;
      reader.readMessage(value,proto.assets.analysis.alive.deserializeBinaryFromReader);
      msg.setAlive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.analysis.feed_event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.analysis.feed_event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.analysis.feed_event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.analysis.feed_event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSnapshot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.analysis.update_snapshot.serializeBinaryToWriter
    );
  }
  f = message.getTicker();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.analysis.update_ticker.serializeBinaryToWriter
    );
  }
  f = message.getEndSnapshot();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.analysis.end_snapshot.serializeBinaryToWriter
    );
  }
  f = message.getAlive();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.analysis.alive.serializeBinaryToWriter
    );
  }
};


/**
 * optional update_snapshot snapshot = 1;
 * @return {?proto.assets.analysis.update_snapshot}
 */
proto.assets.analysis.feed_event.prototype.getSnapshot = function() {
  return /** @type{?proto.assets.analysis.update_snapshot} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.update_snapshot, 1));
};


/**
 * @param {?proto.assets.analysis.update_snapshot|undefined} value
 * @return {!proto.assets.analysis.feed_event} returns this
*/
proto.assets.analysis.feed_event.prototype.setSnapshot = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.assets.analysis.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.feed_event} returns this
 */
proto.assets.analysis.feed_event.prototype.clearSnapshot = function() {
  return this.setSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.feed_event.prototype.hasSnapshot = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional update_ticker ticker = 2;
 * @return {?proto.assets.analysis.update_ticker}
 */
proto.assets.analysis.feed_event.prototype.getTicker = function() {
  return /** @type{?proto.assets.analysis.update_ticker} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.update_ticker, 2));
};


/**
 * @param {?proto.assets.analysis.update_ticker|undefined} value
 * @return {!proto.assets.analysis.feed_event} returns this
*/
proto.assets.analysis.feed_event.prototype.setTicker = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.assets.analysis.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.feed_event} returns this
 */
proto.assets.analysis.feed_event.prototype.clearTicker = function() {
  return this.setTicker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.feed_event.prototype.hasTicker = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional end_snapshot end_snapshot = 3;
 * @return {?proto.assets.analysis.end_snapshot}
 */
proto.assets.analysis.feed_event.prototype.getEndSnapshot = function() {
  return /** @type{?proto.assets.analysis.end_snapshot} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.end_snapshot, 3));
};


/**
 * @param {?proto.assets.analysis.end_snapshot|undefined} value
 * @return {!proto.assets.analysis.feed_event} returns this
*/
proto.assets.analysis.feed_event.prototype.setEndSnapshot = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.assets.analysis.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.feed_event} returns this
 */
proto.assets.analysis.feed_event.prototype.clearEndSnapshot = function() {
  return this.setEndSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.feed_event.prototype.hasEndSnapshot = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional alive alive = 4;
 * @return {?proto.assets.analysis.alive}
 */
proto.assets.analysis.feed_event.prototype.getAlive = function() {
  return /** @type{?proto.assets.analysis.alive} */ (
    jspb.Message.getWrapperField(this, proto.assets.analysis.alive, 4));
};


/**
 * @param {?proto.assets.analysis.alive|undefined} value
 * @return {!proto.assets.analysis.feed_event} returns this
*/
proto.assets.analysis.feed_event.prototype.setAlive = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.assets.analysis.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.analysis.feed_event} returns this
 */
proto.assets.analysis.feed_event.prototype.clearAlive = function() {
  return this.setAlive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.analysis.feed_event.prototype.hasAlive = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * @enum {number}
 */
proto.assets.analysis.update_type = {
  SNAPSHOT: 1,
  VARIATIONS: 2
};

/**
 * @enum {number}
 */
proto.assets.analysis.svc_status = {
  ONLINE: 0,
  OFFLINE: 1,
  BROKEN: 2
};

goog.object.extend(exports, proto.assets.analysis);
