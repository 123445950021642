import React, { useState, useEffect } from 'react'
import TreeSelector from 'components/TreeSelector'
import { useDispatch, useSelector } from 'react-redux'
import { createTreeFromResources } from 'services/treeService'
import { ExtendedDataNode, NodeType } from 'types/ui'
import { selectUsedResources } from 'store/pages/selectors'
import { selectResources } from 'store/resources/selectors'
import { CrudOperation } from 'types/enums'
import * as Store from 'types/store'
import { ResourceAction } from 'types/store'
import DeleteStrategy from './modals/DeleteStrategy'
import { setUserAction } from 'store/pages/strategy/actions'
import DeletePortfolio from './modals/DeletePortfolio'

interface Props {
  userAction: Store.UserAction
}

const TreeSelectorContainer: React.FC<Props> = ({ userAction }) => {
  const { portfolios, strategies } = useSelector(selectResources)
  const usedResources = useSelector(selectUsedResources)
  const [tree, setTree] = useState<ExtendedDataNode[]>([])
  const dispatch = useDispatch()

  const [resourceAction, setResourceAction] = useState<ResourceAction>(null)

  const onItemClicked = (item: ExtendedDataNode, operation: CrudOperation) => {
    if (operation === CrudOperation.Delete) {
      setResourceAction({ resource: item.resource, type: item.type, operation })
    } else {
      dispatch(setUserAction(item, operation))
    }
  }

  if (!strategies.data || !portfolios.data) {
    return null
  }

  useEffect(() => {
    setTree(createTreeFromResources(strategies.data, portfolios.data, [], usedResources))
  }, [portfolios, strategies, usedResources])

  const isDeleteStrategy = () => {
    return (
      resourceAction &&
      resourceAction.operation === CrudOperation.Delete &&
      resourceAction.type === NodeType.Strategy
    )
  }

  const isDeletePortfolio = () => {
    return (
      resourceAction &&
      resourceAction.operation === CrudOperation.Delete &&
      resourceAction.type === NodeType.Portfolio
    )
  }

  return (
    <>
      {isDeleteStrategy() && (
        <DeleteStrategy
          strategy={resourceAction.resource as Store.Strategy}
          onClose={() => setResourceAction(null)}
        />
      )}
      {isDeletePortfolio() && (
        <DeletePortfolio
          portfolio={resourceAction.resource as Store.Portfolio}
          onClose={() => setResourceAction(null)}
        />
      )}
      <TreeSelector tree={tree} onItemClicked={onItemClicked} userAction={userAction} />
    </>
  )
}

export default TreeSelectorContainer
