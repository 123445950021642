import React, { useState } from 'react'
import { FrameType } from 'types/enums'
import { getFrameTitle } from 'services/dashboardService'
import { Avatar, Typography } from 'antd'
import Debug from 'debug'
const { Title } = Typography

import MapViewIcon from 'assets/icon/map-view-icon.svg'
import SignalsTableIcon from 'assets/icon/signals-table-icon.svg'
import PositionMonitoringIcon from 'assets/icon/p-m-icon.svg'
import ReturnAnalysisIcon from 'assets/icon/r-a-icon.svg'
import ForwardAnalysisIcon from 'assets/icon/f-a-icon.svg'
import CustomIcon from 'assets/icon/custom-frame-icon.svg'
import PlaceholderIcon from 'assets/icon/placeholder-frame-icon.svg'

import { FrameTemplate } from 'types/ui'
import { generateLayout } from 'core/layoutHelper'
import cn from 'classnames'

const debug = new Debug('Frontend')

interface Props {
  itemId: FrameType
}

const DrawerCard: React.FC<Props> = ({ itemId: frameType }) => {
  const [selected, setSelected] = useState(false)
  const getFrameIcon = (frameType: FrameType) => {
    if (!frameType) {
      return null
    }

    switch (frameType) {
      case FrameType.Chart:
        return <Avatar src={PositionMonitoringIcon} shape="square" />
      case FrameType.MapView:
        return <Avatar src={MapViewIcon} shape="square" />
      case FrameType.SignalsTable:
        return <Avatar src={SignalsTableIcon} shape="square" />
      case FrameType.ReturnAnalysis:
        return <Avatar src={ReturnAnalysisIcon} shape="square" />
      case FrameType.PositionMonitoring:
        return <Avatar src={PositionMonitoringIcon} shape="square" />
      case FrameType.ForwardAnalysis:
      case FrameType.Statistics:
      case FrameType.StatisticsChartless:
        return <Avatar src={ForwardAnalysisIcon} shape="square" />
      case FrameType.Custom:
        return <Avatar src={CustomIcon} shape="square" />

      default:
        return <Avatar src={PlaceholderIcon} shape="square" />
    }
  }

  const isSelected = selected

  return (
    <div
      draggable={true}
      className={cn('frame', { 'frame--selected': isSelected })}
      onDragStart={(e) => {
        e.stopPropagation()
        const layout = generateLayout('dummy', frameType, 0, 0)
        const dummyFrame: FrameTemplate = {
          frameType,
          width: layout.minW,
          height: layout.minH,
        }
        debug('ON DRAG START', dummyFrame)

        //GG-2022-01-31: we need some information in the dragOver event. But there are constraints, and only keys can be read during the dragOver
        //Workaround: serialize all informations in the key
        //https://stackoverflow.com/questions/31915653/how-to-get-data-from-datatransfer-getdata-in-event-dragover-or-dragenter/31922258
        //https://html.spec.whatwg.org/multipage/dnd.html#the-drag-data-store
        e.dataTransfer.items.add('', JSON.stringify(dummyFrame))
      }}
      onMouseEnter={() => setSelected(true)}
      onMouseLeave={() => setSelected(false)}
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
    >
      {getFrameIcon(frameType)}
      <Title level={4}>{getFrameTitle(frameType)}</Title>
    </div>
  )
}

export default DrawerCard
