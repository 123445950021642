import React from 'react'
import AssetCandleStick from 'v2/containers/frames/AssetCandleStick'
import HistoricalAssetCandleStick from 'v2/containers/frames/Historical/AssetCandleStick'
import * as Store from 'types/store'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'

interface Props {
  frame: Store.Frame
}

const AssetCandleStickBySession: React.FC<Props> = ({ frame }) => {
  const session = useSelector(selectOperativeSessionSession)
  if (session.isHistorical) {
    return <HistoricalAssetCandleStick frame={frame} />
  }

  return <AssetCandleStick frame={frame} />
}

export default AssetCandleStickBySession
