import { useEffect, useRef, useState } from 'react'
import * as Store from 'types/store'
import Debug from 'debug'
import { subscribeToSnapshots } from 'api/socket'
import { Sigma, StrategyFeed } from 'types/enums.api'
import { BUFFERS, config } from 'core'
import { FlowControlSpeed } from 'types/enums'
import { useInterval } from 'react-use'
import { isOffline } from './common'
import { deserializeStrategyBuffer } from 'helpers/protoHelpers'
import { removeUndefinedFields } from 'services/store/mapService'

const debug = Debug('sockets')
const { isAlive } = config.socketOptions

export const useStrategySigmaSnapshotState = (
  strategy: number,
  sessionId: number,
  sigmas: Sigma[],
  service: StrategyFeed,
  bufferTimespan = BUFFERS.Small,
): [Store.StrategySigmas, boolean, Date] => {
  //those ref are used to buffer data
  const dataRef = useRef<Store.StrategySigmas>(null)

  const [data, setData] = useState<Store.StrategySigmas>(null)

  const [lastTick, setLastTick] = useState<Date>(new Date())

  const messageReceived = () => {
    setLastTick(new Date())
  }
  const onMessage = (message: SocketMessage) => {
    try {
      const { data } = message
      data.arrayBuffer().then((buffer) => {
        //trigger messageReceived as first line
        messageReceived()

        const deserialized = deserializeStrategyBuffer(buffer, service, 'snapshot')
        if (!deserialized) {
          return
        }
        const [key, mappedSigmas] = deserialized

        dataRef.current = { ...dataRef.current, ...removeUndefinedFields(mappedSigmas) }

        debug('socket received Strategy Sigmas', key, mappedSigmas)
      })
    } catch (error) {
      debug('couldnt parse Strategy Sigmas', error)
    }
  }

  useEffect(() => {
    dataRef.current = null

    const unsubscribe = subscribeToSnapshots(
      strategy,
      onMessage,
      sessionId,
      strategy,
      service,
      sigmas,
      FlowControlSpeed.High,
    )

    return () => {
      unsubscribe && unsubscribe()
    }
  }, [strategy])

  //Update with buffered data
  useInterval(() => setData(dataRef.current), bufferTimespan)

  useInterval(() => {
    //This trigger a notification to context
    setLastTick(new Date(lastTick))
  }, isAlive)

  return [data, isOffline(lastTick), lastTick]
}
