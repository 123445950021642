import React from 'react'
import { ExtendedDataNode, NodeType } from 'types/ui'
import { CrudOperation } from 'types/enums'

import { Button, Row, Col, Space, Typography, Avatar } from 'antd'
import EditIcon from 'assets/icon/edit-icon.svg'
import TrashIcon from 'assets/icon/trash-icon.svg'
const { Text } = Typography

interface Props {
  node: ExtendedDataNode
  onClick: (item: ExtendedDataNode, operation: CrudOperation, type: NodeType) => void
  isActive: boolean
  isDeletable: boolean
}

const PortfolioItem: React.FC<Props> = ({ node, onClick, isActive, isDeletable }) => {
  if (!node) {
    return null
  }

  return (
    <li key={node.key} className={isActive ? 'active' : ''}>
      <Row>
        <Col flex="auto">
          <Button type="ghost" onClick={() => onClick(node, CrudOperation.Read, node.type)}>
            <Text ellipsis={{ tooltip: node.title }}>{node.title}</Text>
          </Button>
        </Col>
        <Col flex="50px" className="align-right">
          <Space>
            <Button type="ghost" onClick={() => onClick(node, CrudOperation.Update, node.type)} disabled={!isDeletable}>
              <Avatar shape="square" src={EditIcon} size={16} />
            </Button>
            <Button
              type="ghost"
              disabled={!isDeletable}
              onClick={() => {
                onClick(node, CrudOperation.Delete, node.type)
              }}
            >
              <Avatar shape="square" src={TrashIcon} size={16} />
            </Button>
          </Space>
        </Col>
      </Row>
    </li>
  )
}

export default PortfolioItem
