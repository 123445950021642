import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar, Button, Modal, Typography, Tabs } from 'antd'

import ConfigBtnIcon from 'assets/icon/config-icon.svg'

import styles from './styles.module.scss'
import AppContentCard from 'components/AppContentCard'
import CloseIcon from 'assets/icon/close-icon.svg'

import FiltersContainer from 'v2/containers/settings/Filters'
import GuidesTable from 'v2/containers/settings/GuidesTable'
import LayoutsTable from 'v2/containers/settings/LayoutsTable'

const { Title } = Typography

const SettingsModal: React.FC = () => {
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      <Button type="ghost" className="config-btn" onClick={() => setShowModal(true)}>
        <Avatar shape="square" src={ConfigBtnIcon} size={30} />
      </Button>

      <Modal
        className={styles['settings-modal']}
        closable={false}
        width={'85%'}
        title={null}
        centered={true}
        open={showModal}
        footer={null}
        destroyOnClose={true}
      >
        <AppContentCard fullHeight>
          <div className="close-btn-wrapper">
            <Button className="col-close" type="ghost" onClick={() => setShowModal(false)}>
              <Avatar shape="square" src={CloseIcon} size={24} />
            </Button>
          </div>
          <Title level={2}>{t('settings.title')}</Title>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab={t('settings.panelLayouts')} key="1">
              <LayoutsTable />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('settings.panelGuides')} key="2">
              <GuidesTable />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('settings.filters')} key="3">
              <FiltersContainer />
            </Tabs.TabPane>
          </Tabs>
        </AppContentCard>
      </Modal>
    </>
  )
}

export default SettingsModal
