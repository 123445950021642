// source: asset_operations.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.assets.operations.alive', null, global);
goog.exportSymbol('proto.assets.operations.asset_cartesian_operations', null, global);
goog.exportSymbol('proto.assets.operations.asset_closed_op', null, global);
goog.exportSymbol('proto.assets.operations.asset_cum_perc_ret', null, global);
goog.exportSymbol('proto.assets.operations.asset_cum_perc_ret_close', null, global);
goog.exportSymbol('proto.assets.operations.asset_num_tdy_op', null, global);
goog.exportSymbol('proto.assets.operations.closed_operation', null, global);
goog.exportSymbol('proto.assets.operations.decimal', null, global);
goog.exportSymbol('proto.assets.operations.end_snapshot', null, global);
goog.exportSymbol('proto.assets.operations.feed_event', null, global);
goog.exportSymbol('proto.assets.operations.feed_event.EventTypeCase', null, global);
goog.exportSymbol('proto.assets.operations.fees', null, global);
goog.exportSymbol('proto.assets.operations.open_operation', null, global);
goog.exportSymbol('proto.assets.operations.operation_avg_princ', null, global);
goog.exportSymbol('proto.assets.operations.operation_trad_ret_end', null, global);
goog.exportSymbol('proto.assets.operations.position_status', null, global);
goog.exportSymbol('proto.assets.operations.qty_multiplier_close', null, global);
goog.exportSymbol('proto.assets.operations.svc_status', null, global);
goog.exportSymbol('proto.assets.operations.tstamp', null, global);
goog.exportSymbol('proto.assets.operations.update_payload', null, global);
goog.exportSymbol('proto.assets.operations.update_snapshot', null, global);
goog.exportSymbol('proto.assets.operations.update_ticker', null, global);
goog.exportSymbol('proto.assets.operations.update_type', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.operations.tstamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.operations.tstamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.operations.tstamp.displayName = 'proto.assets.operations.tstamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.operations.decimal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.operations.decimal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.operations.decimal.displayName = 'proto.assets.operations.decimal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.operations.open_operation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.operations.open_operation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.operations.open_operation.displayName = 'proto.assets.operations.open_operation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.operations.closed_operation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.operations.closed_operation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.operations.closed_operation.displayName = 'proto.assets.operations.closed_operation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.operations.asset_num_tdy_op = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.operations.asset_num_tdy_op, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.operations.asset_num_tdy_op.displayName = 'proto.assets.operations.asset_num_tdy_op';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.operations.asset_cartesian_operations = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.operations.asset_cartesian_operations, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.operations.asset_cartesian_operations.displayName = 'proto.assets.operations.asset_cartesian_operations';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.operations.operation_trad_ret_end = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.operations.operation_trad_ret_end, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.operations.operation_trad_ret_end.displayName = 'proto.assets.operations.operation_trad_ret_end';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.operations.position_status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.operations.position_status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.operations.position_status.displayName = 'proto.assets.operations.position_status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.operations.operation_avg_princ = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.operations.operation_avg_princ, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.operations.operation_avg_princ.displayName = 'proto.assets.operations.operation_avg_princ';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.operations.fees = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.operations.fees, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.operations.fees.displayName = 'proto.assets.operations.fees';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.operations.qty_multiplier_close = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.operations.qty_multiplier_close, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.operations.qty_multiplier_close.displayName = 'proto.assets.operations.qty_multiplier_close';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.operations.asset_cum_perc_ret_close = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.operations.asset_cum_perc_ret_close, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.operations.asset_cum_perc_ret_close.displayName = 'proto.assets.operations.asset_cum_perc_ret_close';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.operations.asset_cum_perc_ret = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.operations.asset_cum_perc_ret, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.operations.asset_cum_perc_ret.displayName = 'proto.assets.operations.asset_cum_perc_ret';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.operations.asset_closed_op = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.operations.asset_closed_op, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.operations.asset_closed_op.displayName = 'proto.assets.operations.asset_closed_op';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.operations.update_payload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.assets.operations.update_payload.repeatedFields_, null);
};
goog.inherits(proto.assets.operations.update_payload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.operations.update_payload.displayName = 'proto.assets.operations.update_payload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.operations.update_snapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.operations.update_snapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.operations.update_snapshot.displayName = 'proto.assets.operations.update_snapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.operations.end_snapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.operations.end_snapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.operations.end_snapshot.displayName = 'proto.assets.operations.end_snapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.operations.update_ticker = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.operations.update_ticker, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.operations.update_ticker.displayName = 'proto.assets.operations.update_ticker';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.operations.alive = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.operations.alive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.operations.alive.displayName = 'proto.assets.operations.alive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.operations.feed_event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.assets.operations.feed_event.oneofGroups_);
};
goog.inherits(proto.assets.operations.feed_event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.operations.feed_event.displayName = 'proto.assets.operations.feed_event';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.operations.tstamp.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.operations.tstamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.operations.tstamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.tstamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    offset: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    code: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.operations.tstamp}
 */
proto.assets.operations.tstamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.operations.tstamp;
  return proto.assets.operations.tstamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.operations.tstamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.operations.tstamp}
 */
proto.assets.operations.tstamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.operations.tstamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.operations.tstamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.operations.tstamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.tstamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * required sint64 time = 1;
 * @return {number}
 */
proto.assets.operations.tstamp.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.tstamp} returns this
 */
proto.assets.operations.tstamp.prototype.setTime = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.tstamp} returns this
 */
proto.assets.operations.tstamp.prototype.clearTime = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.tstamp.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required sint32 offset = 2;
 * @return {number}
 */
proto.assets.operations.tstamp.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.tstamp} returns this
 */
proto.assets.operations.tstamp.prototype.setOffset = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.tstamp} returns this
 */
proto.assets.operations.tstamp.prototype.clearOffset = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.tstamp.prototype.hasOffset = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required uint32 code = 3;
 * @return {number}
 */
proto.assets.operations.tstamp.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.tstamp} returns this
 */
proto.assets.operations.tstamp.prototype.setCode = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.tstamp} returns this
 */
proto.assets.operations.tstamp.prototype.clearCode = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.tstamp.prototype.hasCode = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.operations.decimal.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.operations.decimal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.operations.decimal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.decimal.toObject = function(includeInstance, msg) {
  var f, obj = {
    coeff: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    exp: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.operations.decimal}
 */
proto.assets.operations.decimal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.operations.decimal;
  return proto.assets.operations.decimal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.operations.decimal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.operations.decimal}
 */
proto.assets.operations.decimal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setCoeff(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.operations.decimal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.operations.decimal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.operations.decimal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.decimal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeSint32(
      2,
      f
    );
  }
};


/**
 * required sint64 coeff = 1;
 * @return {number}
 */
proto.assets.operations.decimal.prototype.getCoeff = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.decimal} returns this
 */
proto.assets.operations.decimal.prototype.setCoeff = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.decimal} returns this
 */
proto.assets.operations.decimal.prototype.clearCoeff = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.decimal.prototype.hasCoeff = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required sint32 exp = 2;
 * @return {number}
 */
proto.assets.operations.decimal.prototype.getExp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.decimal} returns this
 */
proto.assets.operations.decimal.prototype.setExp = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.decimal} returns this
 */
proto.assets.operations.decimal.prototype.clearExp = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.decimal.prototype.hasExp = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.operations.open_operation.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.operations.open_operation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.operations.open_operation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.open_operation.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTs: (f = msg.getStartTs()) && proto.assets.operations.tstamp.toObject(includeInstance, f),
    endTs: (f = msg.getEndTs()) && proto.assets.operations.tstamp.toObject(includeInstance, f),
    avgOpenPrice: (f = msg.getAvgOpenPrice()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    avgClosePrice: (f = msg.getAvgClosePrice()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    size: (f = msg.getSize()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    sign: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    totTradingRet: (f = msg.getTotTradingRet()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    totPercRet: (f = msg.getTotPercRet()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    totTradingRetBc: (f = msg.getTotTradingRetBc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    ytdTradingRet: (f = msg.getYtdTradingRet()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    ytdPercRet: (f = msg.getYtdPercRet()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    ytdTradingRetBc: (f = msg.getYtdTradingRetBc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    tdyTradingRet: (f = msg.getTdyTradingRet()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    tdyPercRet: (f = msg.getTdyPercRet()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    tdyTradingRetBc: (f = msg.getTdyTradingRetBc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTdyInvestedBc: (f = msg.getOperationTdyInvestedBc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    tdyAllRetBc: (f = msg.getTdyAllRetBc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    tdyAllRetBcFees: (f = msg.getTdyAllRetBcFees()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    minPrice: (f = msg.getMinPrice()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    maxPrice: (f = msg.getMaxPrice()) && proto.assets.operations.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.operations.open_operation}
 */
proto.assets.operations.open_operation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.operations.open_operation;
  return proto.assets.operations.open_operation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.operations.open_operation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.operations.open_operation}
 */
proto.assets.operations.open_operation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.operations.tstamp;
      reader.readMessage(value,proto.assets.operations.tstamp.deserializeBinaryFromReader);
      msg.setStartTs(value);
      break;
    case 2:
      var value = new proto.assets.operations.tstamp;
      reader.readMessage(value,proto.assets.operations.tstamp.deserializeBinaryFromReader);
      msg.setEndTs(value);
      break;
    case 3:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAvgOpenPrice(value);
      break;
    case 4:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAvgClosePrice(value);
      break;
    case 5:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setSize(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSign(value);
      break;
    case 7:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setTotTradingRet(value);
      break;
    case 8:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setTotPercRet(value);
      break;
    case 9:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setTotTradingRetBc(value);
      break;
    case 10:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setYtdTradingRet(value);
      break;
    case 11:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setYtdPercRet(value);
      break;
    case 12:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setYtdTradingRetBc(value);
      break;
    case 13:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setTdyTradingRet(value);
      break;
    case 14:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setTdyPercRet(value);
      break;
    case 15:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setTdyTradingRetBc(value);
      break;
    case 16:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTdyInvestedBc(value);
      break;
    case 17:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setTdyAllRetBc(value);
      break;
    case 18:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setTdyAllRetBcFees(value);
      break;
    case 19:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setMinPrice(value);
      break;
    case 20:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setMaxPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.operations.open_operation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.operations.open_operation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.operations.open_operation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.open_operation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.operations.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTs();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.operations.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getAvgOpenPrice();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAvgClosePrice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getSize();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTotTradingRet();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTotPercRet();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTotTradingRetBc();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getYtdTradingRet();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getYtdPercRet();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getYtdTradingRetBc();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTdyTradingRet();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTdyPercRet();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTdyTradingRetBc();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTdyInvestedBc();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTdyAllRetBc();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTdyAllRetBcFees();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getMinPrice();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getMaxPrice();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * required tstamp start_ts = 1;
 * @return {!proto.assets.operations.tstamp}
 */
proto.assets.operations.open_operation.prototype.getStartTs = function() {
  return /** @type{!proto.assets.operations.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.tstamp, 1, 1));
};


/**
 * @param {!proto.assets.operations.tstamp} value
 * @return {!proto.assets.operations.open_operation} returns this
*/
proto.assets.operations.open_operation.prototype.setStartTs = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.open_operation} returns this
 */
proto.assets.operations.open_operation.prototype.clearStartTs = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.open_operation.prototype.hasStartTs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required tstamp end_ts = 2;
 * @return {!proto.assets.operations.tstamp}
 */
proto.assets.operations.open_operation.prototype.getEndTs = function() {
  return /** @type{!proto.assets.operations.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.tstamp, 2, 1));
};


/**
 * @param {!proto.assets.operations.tstamp} value
 * @return {!proto.assets.operations.open_operation} returns this
*/
proto.assets.operations.open_operation.prototype.setEndTs = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.open_operation} returns this
 */
proto.assets.operations.open_operation.prototype.clearEndTs = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.open_operation.prototype.hasEndTs = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required decimal avg_open_price = 3;
 * @return {!proto.assets.operations.decimal}
 */
proto.assets.operations.open_operation.prototype.getAvgOpenPrice = function() {
  return /** @type{!proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 3, 1));
};


/**
 * @param {!proto.assets.operations.decimal} value
 * @return {!proto.assets.operations.open_operation} returns this
*/
proto.assets.operations.open_operation.prototype.setAvgOpenPrice = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.open_operation} returns this
 */
proto.assets.operations.open_operation.prototype.clearAvgOpenPrice = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.open_operation.prototype.hasAvgOpenPrice = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required decimal avg_close_price = 4;
 * @return {!proto.assets.operations.decimal}
 */
proto.assets.operations.open_operation.prototype.getAvgClosePrice = function() {
  return /** @type{!proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 4, 1));
};


/**
 * @param {!proto.assets.operations.decimal} value
 * @return {!proto.assets.operations.open_operation} returns this
*/
proto.assets.operations.open_operation.prototype.setAvgClosePrice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.open_operation} returns this
 */
proto.assets.operations.open_operation.prototype.clearAvgClosePrice = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.open_operation.prototype.hasAvgClosePrice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required decimal size = 5;
 * @return {!proto.assets.operations.decimal}
 */
proto.assets.operations.open_operation.prototype.getSize = function() {
  return /** @type{!proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 5, 1));
};


/**
 * @param {!proto.assets.operations.decimal} value
 * @return {!proto.assets.operations.open_operation} returns this
*/
proto.assets.operations.open_operation.prototype.setSize = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.open_operation} returns this
 */
proto.assets.operations.open_operation.prototype.clearSize = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.open_operation.prototype.hasSize = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * required string sign = 6;
 * @return {string}
 */
proto.assets.operations.open_operation.prototype.getSign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.operations.open_operation} returns this
 */
proto.assets.operations.open_operation.prototype.setSign = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.open_operation} returns this
 */
proto.assets.operations.open_operation.prototype.clearSign = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.open_operation.prototype.hasSign = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * required decimal tot_trading_ret = 7;
 * @return {!proto.assets.operations.decimal}
 */
proto.assets.operations.open_operation.prototype.getTotTradingRet = function() {
  return /** @type{!proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 7, 1));
};


/**
 * @param {!proto.assets.operations.decimal} value
 * @return {!proto.assets.operations.open_operation} returns this
*/
proto.assets.operations.open_operation.prototype.setTotTradingRet = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.open_operation} returns this
 */
proto.assets.operations.open_operation.prototype.clearTotTradingRet = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.open_operation.prototype.hasTotTradingRet = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional decimal tot_perc_ret = 8;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.open_operation.prototype.getTotPercRet = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 8));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.open_operation} returns this
*/
proto.assets.operations.open_operation.prototype.setTotPercRet = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.open_operation} returns this
 */
proto.assets.operations.open_operation.prototype.clearTotPercRet = function() {
  return this.setTotPercRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.open_operation.prototype.hasTotPercRet = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional decimal tot_trading_ret_bc = 9;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.open_operation.prototype.getTotTradingRetBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 9));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.open_operation} returns this
*/
proto.assets.operations.open_operation.prototype.setTotTradingRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.open_operation} returns this
 */
proto.assets.operations.open_operation.prototype.clearTotTradingRetBc = function() {
  return this.setTotTradingRetBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.open_operation.prototype.hasTotTradingRetBc = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional decimal ytd_trading_ret = 10;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.open_operation.prototype.getYtdTradingRet = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 10));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.open_operation} returns this
*/
proto.assets.operations.open_operation.prototype.setYtdTradingRet = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.open_operation} returns this
 */
proto.assets.operations.open_operation.prototype.clearYtdTradingRet = function() {
  return this.setYtdTradingRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.open_operation.prototype.hasYtdTradingRet = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional decimal ytd_perc_ret = 11;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.open_operation.prototype.getYtdPercRet = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 11));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.open_operation} returns this
*/
proto.assets.operations.open_operation.prototype.setYtdPercRet = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.open_operation} returns this
 */
proto.assets.operations.open_operation.prototype.clearYtdPercRet = function() {
  return this.setYtdPercRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.open_operation.prototype.hasYtdPercRet = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional decimal ytd_trading_ret_bc = 12;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.open_operation.prototype.getYtdTradingRetBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 12));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.open_operation} returns this
*/
proto.assets.operations.open_operation.prototype.setYtdTradingRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.open_operation} returns this
 */
proto.assets.operations.open_operation.prototype.clearYtdTradingRetBc = function() {
  return this.setYtdTradingRetBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.open_operation.prototype.hasYtdTradingRetBc = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional decimal tdy_trading_ret = 13;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.open_operation.prototype.getTdyTradingRet = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 13));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.open_operation} returns this
*/
proto.assets.operations.open_operation.prototype.setTdyTradingRet = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.open_operation} returns this
 */
proto.assets.operations.open_operation.prototype.clearTdyTradingRet = function() {
  return this.setTdyTradingRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.open_operation.prototype.hasTdyTradingRet = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional decimal tdy_perc_ret = 14;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.open_operation.prototype.getTdyPercRet = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 14));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.open_operation} returns this
*/
proto.assets.operations.open_operation.prototype.setTdyPercRet = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.open_operation} returns this
 */
proto.assets.operations.open_operation.prototype.clearTdyPercRet = function() {
  return this.setTdyPercRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.open_operation.prototype.hasTdyPercRet = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional decimal tdy_trading_ret_bc = 15;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.open_operation.prototype.getTdyTradingRetBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 15));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.open_operation} returns this
*/
proto.assets.operations.open_operation.prototype.setTdyTradingRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.open_operation} returns this
 */
proto.assets.operations.open_operation.prototype.clearTdyTradingRetBc = function() {
  return this.setTdyTradingRetBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.open_operation.prototype.hasTdyTradingRetBc = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional decimal operation_tdy_invested_bc = 16;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.open_operation.prototype.getOperationTdyInvestedBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 16));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.open_operation} returns this
*/
proto.assets.operations.open_operation.prototype.setOperationTdyInvestedBc = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.open_operation} returns this
 */
proto.assets.operations.open_operation.prototype.clearOperationTdyInvestedBc = function() {
  return this.setOperationTdyInvestedBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.open_operation.prototype.hasOperationTdyInvestedBc = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional decimal tdy_all_ret_bc = 17;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.open_operation.prototype.getTdyAllRetBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 17));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.open_operation} returns this
*/
proto.assets.operations.open_operation.prototype.setTdyAllRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.open_operation} returns this
 */
proto.assets.operations.open_operation.prototype.clearTdyAllRetBc = function() {
  return this.setTdyAllRetBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.open_operation.prototype.hasTdyAllRetBc = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional decimal tdy_all_ret_bc_fees = 18;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.open_operation.prototype.getTdyAllRetBcFees = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 18));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.open_operation} returns this
*/
proto.assets.operations.open_operation.prototype.setTdyAllRetBcFees = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.open_operation} returns this
 */
proto.assets.operations.open_operation.prototype.clearTdyAllRetBcFees = function() {
  return this.setTdyAllRetBcFees(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.open_operation.prototype.hasTdyAllRetBcFees = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * required decimal min_price = 19;
 * @return {!proto.assets.operations.decimal}
 */
proto.assets.operations.open_operation.prototype.getMinPrice = function() {
  return /** @type{!proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 19, 1));
};


/**
 * @param {!proto.assets.operations.decimal} value
 * @return {!proto.assets.operations.open_operation} returns this
*/
proto.assets.operations.open_operation.prototype.setMinPrice = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.open_operation} returns this
 */
proto.assets.operations.open_operation.prototype.clearMinPrice = function() {
  return jspb.Message.setField(this, 19, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.open_operation.prototype.hasMinPrice = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * required decimal max_price = 20;
 * @return {!proto.assets.operations.decimal}
 */
proto.assets.operations.open_operation.prototype.getMaxPrice = function() {
  return /** @type{!proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 20, 1));
};


/**
 * @param {!proto.assets.operations.decimal} value
 * @return {!proto.assets.operations.open_operation} returns this
*/
proto.assets.operations.open_operation.prototype.setMaxPrice = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.open_operation} returns this
 */
proto.assets.operations.open_operation.prototype.clearMaxPrice = function() {
  return jspb.Message.setField(this, 20, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.open_operation.prototype.hasMaxPrice = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.operations.closed_operation.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.operations.closed_operation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.operations.closed_operation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.closed_operation.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTs: (f = msg.getStartTs()) && proto.assets.operations.tstamp.toObject(includeInstance, f),
    endTs: (f = msg.getEndTs()) && proto.assets.operations.tstamp.toObject(includeInstance, f),
    avgOpenPrice: (f = msg.getAvgOpenPrice()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    avgClosePrice: (f = msg.getAvgClosePrice()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    size: (f = msg.getSize()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    sign: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    totTradingRet: (f = msg.getTotTradingRet()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    totPercRet: (f = msg.getTotPercRet()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    totTradingRetBc: (f = msg.getTotTradingRetBc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    ytdTradingRet: (f = msg.getYtdTradingRet()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    ytdPercRet: (f = msg.getYtdPercRet()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    ytdTradingRetBc: (f = msg.getYtdTradingRetBc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    tdyTradingRet: (f = msg.getTdyTradingRet()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    tdyPercRet: (f = msg.getTdyPercRet()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    tdyTradingRetBc: (f = msg.getTdyTradingRetBc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTdyInvestedBc: (f = msg.getOperationTdyInvestedBc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    tdyAllRetBc: (f = msg.getTdyAllRetBc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    tdyAllRetBcFees: (f = msg.getTdyAllRetBcFees()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    minPrice: (f = msg.getMinPrice()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    maxPrice: (f = msg.getMaxPrice()) && proto.assets.operations.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.operations.closed_operation}
 */
proto.assets.operations.closed_operation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.operations.closed_operation;
  return proto.assets.operations.closed_operation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.operations.closed_operation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.operations.closed_operation}
 */
proto.assets.operations.closed_operation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.operations.tstamp;
      reader.readMessage(value,proto.assets.operations.tstamp.deserializeBinaryFromReader);
      msg.setStartTs(value);
      break;
    case 2:
      var value = new proto.assets.operations.tstamp;
      reader.readMessage(value,proto.assets.operations.tstamp.deserializeBinaryFromReader);
      msg.setEndTs(value);
      break;
    case 3:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAvgOpenPrice(value);
      break;
    case 4:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAvgClosePrice(value);
      break;
    case 5:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setSize(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSign(value);
      break;
    case 7:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setTotTradingRet(value);
      break;
    case 8:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setTotPercRet(value);
      break;
    case 9:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setTotTradingRetBc(value);
      break;
    case 10:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setYtdTradingRet(value);
      break;
    case 11:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setYtdPercRet(value);
      break;
    case 12:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setYtdTradingRetBc(value);
      break;
    case 13:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setTdyTradingRet(value);
      break;
    case 14:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setTdyPercRet(value);
      break;
    case 15:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setTdyTradingRetBc(value);
      break;
    case 16:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTdyInvestedBc(value);
      break;
    case 17:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setTdyAllRetBc(value);
      break;
    case 18:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setTdyAllRetBcFees(value);
      break;
    case 19:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setMinPrice(value);
      break;
    case 20:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setMaxPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.operations.closed_operation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.operations.closed_operation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.operations.closed_operation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.closed_operation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.operations.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTs();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.operations.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getAvgOpenPrice();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAvgClosePrice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getSize();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTotTradingRet();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTotPercRet();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTotTradingRetBc();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getYtdTradingRet();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getYtdPercRet();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getYtdTradingRetBc();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTdyTradingRet();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTdyPercRet();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTdyTradingRetBc();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTdyInvestedBc();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTdyAllRetBc();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTdyAllRetBcFees();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getMinPrice();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getMaxPrice();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * required tstamp start_ts = 1;
 * @return {!proto.assets.operations.tstamp}
 */
proto.assets.operations.closed_operation.prototype.getStartTs = function() {
  return /** @type{!proto.assets.operations.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.tstamp, 1, 1));
};


/**
 * @param {!proto.assets.operations.tstamp} value
 * @return {!proto.assets.operations.closed_operation} returns this
*/
proto.assets.operations.closed_operation.prototype.setStartTs = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.closed_operation} returns this
 */
proto.assets.operations.closed_operation.prototype.clearStartTs = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.closed_operation.prototype.hasStartTs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required tstamp end_ts = 2;
 * @return {!proto.assets.operations.tstamp}
 */
proto.assets.operations.closed_operation.prototype.getEndTs = function() {
  return /** @type{!proto.assets.operations.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.tstamp, 2, 1));
};


/**
 * @param {!proto.assets.operations.tstamp} value
 * @return {!proto.assets.operations.closed_operation} returns this
*/
proto.assets.operations.closed_operation.prototype.setEndTs = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.closed_operation} returns this
 */
proto.assets.operations.closed_operation.prototype.clearEndTs = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.closed_operation.prototype.hasEndTs = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required decimal avg_open_price = 3;
 * @return {!proto.assets.operations.decimal}
 */
proto.assets.operations.closed_operation.prototype.getAvgOpenPrice = function() {
  return /** @type{!proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 3, 1));
};


/**
 * @param {!proto.assets.operations.decimal} value
 * @return {!proto.assets.operations.closed_operation} returns this
*/
proto.assets.operations.closed_operation.prototype.setAvgOpenPrice = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.closed_operation} returns this
 */
proto.assets.operations.closed_operation.prototype.clearAvgOpenPrice = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.closed_operation.prototype.hasAvgOpenPrice = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required decimal avg_close_price = 4;
 * @return {!proto.assets.operations.decimal}
 */
proto.assets.operations.closed_operation.prototype.getAvgClosePrice = function() {
  return /** @type{!proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 4, 1));
};


/**
 * @param {!proto.assets.operations.decimal} value
 * @return {!proto.assets.operations.closed_operation} returns this
*/
proto.assets.operations.closed_operation.prototype.setAvgClosePrice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.closed_operation} returns this
 */
proto.assets.operations.closed_operation.prototype.clearAvgClosePrice = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.closed_operation.prototype.hasAvgClosePrice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required decimal size = 5;
 * @return {!proto.assets.operations.decimal}
 */
proto.assets.operations.closed_operation.prototype.getSize = function() {
  return /** @type{!proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 5, 1));
};


/**
 * @param {!proto.assets.operations.decimal} value
 * @return {!proto.assets.operations.closed_operation} returns this
*/
proto.assets.operations.closed_operation.prototype.setSize = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.closed_operation} returns this
 */
proto.assets.operations.closed_operation.prototype.clearSize = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.closed_operation.prototype.hasSize = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * required string sign = 6;
 * @return {string}
 */
proto.assets.operations.closed_operation.prototype.getSign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.operations.closed_operation} returns this
 */
proto.assets.operations.closed_operation.prototype.setSign = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.closed_operation} returns this
 */
proto.assets.operations.closed_operation.prototype.clearSign = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.closed_operation.prototype.hasSign = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * required decimal tot_trading_ret = 7;
 * @return {!proto.assets.operations.decimal}
 */
proto.assets.operations.closed_operation.prototype.getTotTradingRet = function() {
  return /** @type{!proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 7, 1));
};


/**
 * @param {!proto.assets.operations.decimal} value
 * @return {!proto.assets.operations.closed_operation} returns this
*/
proto.assets.operations.closed_operation.prototype.setTotTradingRet = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.closed_operation} returns this
 */
proto.assets.operations.closed_operation.prototype.clearTotTradingRet = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.closed_operation.prototype.hasTotTradingRet = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional decimal tot_perc_ret = 8;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.closed_operation.prototype.getTotPercRet = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 8));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.closed_operation} returns this
*/
proto.assets.operations.closed_operation.prototype.setTotPercRet = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.closed_operation} returns this
 */
proto.assets.operations.closed_operation.prototype.clearTotPercRet = function() {
  return this.setTotPercRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.closed_operation.prototype.hasTotPercRet = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional decimal tot_trading_ret_bc = 9;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.closed_operation.prototype.getTotTradingRetBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 9));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.closed_operation} returns this
*/
proto.assets.operations.closed_operation.prototype.setTotTradingRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.closed_operation} returns this
 */
proto.assets.operations.closed_operation.prototype.clearTotTradingRetBc = function() {
  return this.setTotTradingRetBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.closed_operation.prototype.hasTotTradingRetBc = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional decimal ytd_trading_ret = 10;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.closed_operation.prototype.getYtdTradingRet = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 10));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.closed_operation} returns this
*/
proto.assets.operations.closed_operation.prototype.setYtdTradingRet = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.closed_operation} returns this
 */
proto.assets.operations.closed_operation.prototype.clearYtdTradingRet = function() {
  return this.setYtdTradingRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.closed_operation.prototype.hasYtdTradingRet = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional decimal ytd_perc_ret = 11;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.closed_operation.prototype.getYtdPercRet = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 11));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.closed_operation} returns this
*/
proto.assets.operations.closed_operation.prototype.setYtdPercRet = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.closed_operation} returns this
 */
proto.assets.operations.closed_operation.prototype.clearYtdPercRet = function() {
  return this.setYtdPercRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.closed_operation.prototype.hasYtdPercRet = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional decimal ytd_trading_ret_bc = 12;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.closed_operation.prototype.getYtdTradingRetBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 12));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.closed_operation} returns this
*/
proto.assets.operations.closed_operation.prototype.setYtdTradingRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.closed_operation} returns this
 */
proto.assets.operations.closed_operation.prototype.clearYtdTradingRetBc = function() {
  return this.setYtdTradingRetBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.closed_operation.prototype.hasYtdTradingRetBc = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional decimal tdy_trading_ret = 13;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.closed_operation.prototype.getTdyTradingRet = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 13));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.closed_operation} returns this
*/
proto.assets.operations.closed_operation.prototype.setTdyTradingRet = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.closed_operation} returns this
 */
proto.assets.operations.closed_operation.prototype.clearTdyTradingRet = function() {
  return this.setTdyTradingRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.closed_operation.prototype.hasTdyTradingRet = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional decimal tdy_perc_ret = 14;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.closed_operation.prototype.getTdyPercRet = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 14));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.closed_operation} returns this
*/
proto.assets.operations.closed_operation.prototype.setTdyPercRet = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.closed_operation} returns this
 */
proto.assets.operations.closed_operation.prototype.clearTdyPercRet = function() {
  return this.setTdyPercRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.closed_operation.prototype.hasTdyPercRet = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional decimal tdy_trading_ret_bc = 15;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.closed_operation.prototype.getTdyTradingRetBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 15));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.closed_operation} returns this
*/
proto.assets.operations.closed_operation.prototype.setTdyTradingRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.closed_operation} returns this
 */
proto.assets.operations.closed_operation.prototype.clearTdyTradingRetBc = function() {
  return this.setTdyTradingRetBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.closed_operation.prototype.hasTdyTradingRetBc = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional decimal operation_tdy_invested_bc = 16;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.closed_operation.prototype.getOperationTdyInvestedBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 16));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.closed_operation} returns this
*/
proto.assets.operations.closed_operation.prototype.setOperationTdyInvestedBc = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.closed_operation} returns this
 */
proto.assets.operations.closed_operation.prototype.clearOperationTdyInvestedBc = function() {
  return this.setOperationTdyInvestedBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.closed_operation.prototype.hasOperationTdyInvestedBc = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional decimal tdy_all_ret_bc = 17;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.closed_operation.prototype.getTdyAllRetBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 17));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.closed_operation} returns this
*/
proto.assets.operations.closed_operation.prototype.setTdyAllRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.closed_operation} returns this
 */
proto.assets.operations.closed_operation.prototype.clearTdyAllRetBc = function() {
  return this.setTdyAllRetBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.closed_operation.prototype.hasTdyAllRetBc = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional decimal tdy_all_ret_bc_fees = 18;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.closed_operation.prototype.getTdyAllRetBcFees = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 18));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.closed_operation} returns this
*/
proto.assets.operations.closed_operation.prototype.setTdyAllRetBcFees = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.closed_operation} returns this
 */
proto.assets.operations.closed_operation.prototype.clearTdyAllRetBcFees = function() {
  return this.setTdyAllRetBcFees(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.closed_operation.prototype.hasTdyAllRetBcFees = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * required decimal min_price = 19;
 * @return {!proto.assets.operations.decimal}
 */
proto.assets.operations.closed_operation.prototype.getMinPrice = function() {
  return /** @type{!proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 19, 1));
};


/**
 * @param {!proto.assets.operations.decimal} value
 * @return {!proto.assets.operations.closed_operation} returns this
*/
proto.assets.operations.closed_operation.prototype.setMinPrice = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.closed_operation} returns this
 */
proto.assets.operations.closed_operation.prototype.clearMinPrice = function() {
  return jspb.Message.setField(this, 19, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.closed_operation.prototype.hasMinPrice = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * required decimal max_price = 20;
 * @return {!proto.assets.operations.decimal}
 */
proto.assets.operations.closed_operation.prototype.getMaxPrice = function() {
  return /** @type{!proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 20, 1));
};


/**
 * @param {!proto.assets.operations.decimal} value
 * @return {!proto.assets.operations.closed_operation} returns this
*/
proto.assets.operations.closed_operation.prototype.setMaxPrice = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.closed_operation} returns this
 */
proto.assets.operations.closed_operation.prototype.clearMaxPrice = function() {
  return jspb.Message.setField(this, 20, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.closed_operation.prototype.hasMaxPrice = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.operations.asset_num_tdy_op.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.operations.asset_num_tdy_op.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.operations.asset_num_tdy_op} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.asset_num_tdy_op.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetTdyLngPosOp: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    assetTdyShrPosOp: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    assetTdyLngNegOp: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    assetTdyShrNegOp: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    assetTdyPosOp: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    assetTdyNegOp: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    midnightTs: (f = msg.getMidnightTs()) && proto.assets.operations.tstamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.operations.asset_num_tdy_op}
 */
proto.assets.operations.asset_num_tdy_op.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.operations.asset_num_tdy_op;
  return proto.assets.operations.asset_num_tdy_op.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.operations.asset_num_tdy_op} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.operations.asset_num_tdy_op}
 */
proto.assets.operations.asset_num_tdy_op.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetTdyLngPosOp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetTdyShrPosOp(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetTdyLngNegOp(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetTdyShrNegOp(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetTdyPosOp(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetTdyNegOp(value);
      break;
    case 7:
      var value = new proto.assets.operations.tstamp;
      reader.readMessage(value,proto.assets.operations.tstamp.deserializeBinaryFromReader);
      msg.setMidnightTs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.operations.asset_num_tdy_op.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.operations.asset_num_tdy_op.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.operations.asset_num_tdy_op} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.asset_num_tdy_op.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getMidnightTs();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.assets.operations.tstamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 asset_tdy_lng_pos_op = 1;
 * @return {number}
 */
proto.assets.operations.asset_num_tdy_op.prototype.getAssetTdyLngPosOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.asset_num_tdy_op} returns this
 */
proto.assets.operations.asset_num_tdy_op.prototype.setAssetTdyLngPosOp = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.asset_num_tdy_op} returns this
 */
proto.assets.operations.asset_num_tdy_op.prototype.clearAssetTdyLngPosOp = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_num_tdy_op.prototype.hasAssetTdyLngPosOp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 asset_tdy_shr_pos_op = 2;
 * @return {number}
 */
proto.assets.operations.asset_num_tdy_op.prototype.getAssetTdyShrPosOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.asset_num_tdy_op} returns this
 */
proto.assets.operations.asset_num_tdy_op.prototype.setAssetTdyShrPosOp = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.asset_num_tdy_op} returns this
 */
proto.assets.operations.asset_num_tdy_op.prototype.clearAssetTdyShrPosOp = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_num_tdy_op.prototype.hasAssetTdyShrPosOp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 asset_tdy_lng_neg_op = 3;
 * @return {number}
 */
proto.assets.operations.asset_num_tdy_op.prototype.getAssetTdyLngNegOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.asset_num_tdy_op} returns this
 */
proto.assets.operations.asset_num_tdy_op.prototype.setAssetTdyLngNegOp = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.asset_num_tdy_op} returns this
 */
proto.assets.operations.asset_num_tdy_op.prototype.clearAssetTdyLngNegOp = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_num_tdy_op.prototype.hasAssetTdyLngNegOp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 asset_tdy_shr_neg_op = 4;
 * @return {number}
 */
proto.assets.operations.asset_num_tdy_op.prototype.getAssetTdyShrNegOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.asset_num_tdy_op} returns this
 */
proto.assets.operations.asset_num_tdy_op.prototype.setAssetTdyShrNegOp = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.asset_num_tdy_op} returns this
 */
proto.assets.operations.asset_num_tdy_op.prototype.clearAssetTdyShrNegOp = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_num_tdy_op.prototype.hasAssetTdyShrNegOp = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 asset_tdy_pos_op = 5;
 * @return {number}
 */
proto.assets.operations.asset_num_tdy_op.prototype.getAssetTdyPosOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.asset_num_tdy_op} returns this
 */
proto.assets.operations.asset_num_tdy_op.prototype.setAssetTdyPosOp = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.asset_num_tdy_op} returns this
 */
proto.assets.operations.asset_num_tdy_op.prototype.clearAssetTdyPosOp = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_num_tdy_op.prototype.hasAssetTdyPosOp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 asset_tdy_neg_op = 6;
 * @return {number}
 */
proto.assets.operations.asset_num_tdy_op.prototype.getAssetTdyNegOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.asset_num_tdy_op} returns this
 */
proto.assets.operations.asset_num_tdy_op.prototype.setAssetTdyNegOp = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.asset_num_tdy_op} returns this
 */
proto.assets.operations.asset_num_tdy_op.prototype.clearAssetTdyNegOp = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_num_tdy_op.prototype.hasAssetTdyNegOp = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional tstamp midnight_ts = 7;
 * @return {?proto.assets.operations.tstamp}
 */
proto.assets.operations.asset_num_tdy_op.prototype.getMidnightTs = function() {
  return /** @type{?proto.assets.operations.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.tstamp, 7));
};


/**
 * @param {?proto.assets.operations.tstamp|undefined} value
 * @return {!proto.assets.operations.asset_num_tdy_op} returns this
*/
proto.assets.operations.asset_num_tdy_op.prototype.setMidnightTs = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_num_tdy_op} returns this
 */
proto.assets.operations.asset_num_tdy_op.prototype.clearMidnightTs = function() {
  return this.setMidnightTs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_num_tdy_op.prototype.hasMidnightTs = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.operations.asset_cartesian_operations.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.operations.asset_cartesian_operations.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.operations.asset_cartesian_operations} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.asset_cartesian_operations.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    positiveOp: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    negativeOp: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    totalOp: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.operations.asset_cartesian_operations}
 */
proto.assets.operations.asset_cartesian_operations.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.operations.asset_cartesian_operations;
  return proto.assets.operations.asset_cartesian_operations.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.operations.asset_cartesian_operations} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.operations.asset_cartesian_operations}
 */
proto.assets.operations.asset_cartesian_operations.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositiveOp(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNegativeOp(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalOp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.operations.asset_cartesian_operations.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.operations.asset_cartesian_operations.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.operations.asset_cartesian_operations} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.asset_cartesian_operations.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * required string type = 1;
 * @return {string}
 */
proto.assets.operations.asset_cartesian_operations.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.operations.asset_cartesian_operations} returns this
 */
proto.assets.operations.asset_cartesian_operations.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.asset_cartesian_operations} returns this
 */
proto.assets.operations.asset_cartesian_operations.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cartesian_operations.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 positive_op = 2;
 * @return {number}
 */
proto.assets.operations.asset_cartesian_operations.prototype.getPositiveOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.asset_cartesian_operations} returns this
 */
proto.assets.operations.asset_cartesian_operations.prototype.setPositiveOp = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.asset_cartesian_operations} returns this
 */
proto.assets.operations.asset_cartesian_operations.prototype.clearPositiveOp = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cartesian_operations.prototype.hasPositiveOp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 negative_op = 3;
 * @return {number}
 */
proto.assets.operations.asset_cartesian_operations.prototype.getNegativeOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.asset_cartesian_operations} returns this
 */
proto.assets.operations.asset_cartesian_operations.prototype.setNegativeOp = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.asset_cartesian_operations} returns this
 */
proto.assets.operations.asset_cartesian_operations.prototype.clearNegativeOp = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cartesian_operations.prototype.hasNegativeOp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 total_op = 4;
 * @return {number}
 */
proto.assets.operations.asset_cartesian_operations.prototype.getTotalOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.asset_cartesian_operations} returns this
 */
proto.assets.operations.asset_cartesian_operations.prototype.setTotalOp = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.asset_cartesian_operations} returns this
 */
proto.assets.operations.asset_cartesian_operations.prototype.clearTotalOp = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cartesian_operations.prototype.hasTotalOp = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.operations.operation_trad_ret_end.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.operations.operation_trad_ret_end.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.operations.operation_trad_ret_end} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.operation_trad_ret_end.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationCumTradingRetEnd: (f = msg.getOperationCumTradingRetEnd()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationCumTradingRetBcEnd: (f = msg.getOperationCumTradingRetBcEnd()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationYtdCumTradingRetEnd: (f = msg.getOperationYtdCumTradingRetEnd()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationYtdCumTradingRetBcEnd: (f = msg.getOperationYtdCumTradingRetBcEnd()) && proto.assets.operations.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.operations.operation_trad_ret_end}
 */
proto.assets.operations.operation_trad_ret_end.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.operations.operation_trad_ret_end;
  return proto.assets.operations.operation_trad_ret_end.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.operations.operation_trad_ret_end} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.operations.operation_trad_ret_end}
 */
proto.assets.operations.operation_trad_ret_end.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationCumTradingRetEnd(value);
      break;
    case 2:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationCumTradingRetBcEnd(value);
      break;
    case 3:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationYtdCumTradingRetEnd(value);
      break;
    case 4:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationYtdCumTradingRetBcEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.operations.operation_trad_ret_end.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.operations.operation_trad_ret_end.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.operations.operation_trad_ret_end} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.operation_trad_ret_end.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationCumTradingRetEnd();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationCumTradingRetBcEnd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationYtdCumTradingRetEnd();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationYtdCumTradingRetBcEnd();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal operation_cum_trading_ret_end = 1;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_trad_ret_end.prototype.getOperationCumTradingRetEnd = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 1));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_trad_ret_end} returns this
*/
proto.assets.operations.operation_trad_ret_end.prototype.setOperationCumTradingRetEnd = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_trad_ret_end} returns this
 */
proto.assets.operations.operation_trad_ret_end.prototype.clearOperationCumTradingRetEnd = function() {
  return this.setOperationCumTradingRetEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_trad_ret_end.prototype.hasOperationCumTradingRetEnd = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal operation_cum_trading_ret_bc_end = 2;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_trad_ret_end.prototype.getOperationCumTradingRetBcEnd = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 2));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_trad_ret_end} returns this
*/
proto.assets.operations.operation_trad_ret_end.prototype.setOperationCumTradingRetBcEnd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_trad_ret_end} returns this
 */
proto.assets.operations.operation_trad_ret_end.prototype.clearOperationCumTradingRetBcEnd = function() {
  return this.setOperationCumTradingRetBcEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_trad_ret_end.prototype.hasOperationCumTradingRetBcEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional decimal operation_ytd_cum_trading_ret_end = 3;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_trad_ret_end.prototype.getOperationYtdCumTradingRetEnd = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 3));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_trad_ret_end} returns this
*/
proto.assets.operations.operation_trad_ret_end.prototype.setOperationYtdCumTradingRetEnd = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_trad_ret_end} returns this
 */
proto.assets.operations.operation_trad_ret_end.prototype.clearOperationYtdCumTradingRetEnd = function() {
  return this.setOperationYtdCumTradingRetEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_trad_ret_end.prototype.hasOperationYtdCumTradingRetEnd = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional decimal operation_ytd_cum_trading_ret_bc_end = 4;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_trad_ret_end.prototype.getOperationYtdCumTradingRetBcEnd = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 4));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_trad_ret_end} returns this
*/
proto.assets.operations.operation_trad_ret_end.prototype.setOperationYtdCumTradingRetBcEnd = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_trad_ret_end} returns this
 */
proto.assets.operations.operation_trad_ret_end.prototype.clearOperationYtdCumTradingRetBcEnd = function() {
  return this.setOperationYtdCumTradingRetBcEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_trad_ret_end.prototype.hasOperationYtdCumTradingRetBcEnd = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.operations.position_status.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.operations.position_status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.operations.position_status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.position_status.toObject = function(includeInstance, msg) {
  var f, obj = {
    positionSign: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    operationCumOpenQty: (f = msg.getOperationCumOpenQty()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationCumCloseQty: (f = msg.getOperationCumCloseQty()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    initialPositionQuantity: (f = msg.getInitialPositionQuantity()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    initialPositionQtyMultiplier: (f = msg.getInitialPositionQtyMultiplier()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    initialPositionAmount: (f = msg.getInitialPositionAmount()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    initialPositionAmountBc: (f = msg.getInitialPositionAmountBc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    positionQuantity: (f = msg.getPositionQuantity()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationCumOpenQtyMultiplier: (f = msg.getOperationCumOpenQtyMultiplier()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationCumCloseQtyMultiplier: (f = msg.getOperationCumCloseQtyMultiplier()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    positionQtyMultiplier: (f = msg.getPositionQtyMultiplier()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    positionAmount: (f = msg.getPositionAmount()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    positionAmountBc: (f = msg.getPositionAmountBc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTotCumOpenQty: (f = msg.getOperationTotCumOpenQty()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationCumOpenAmount: (f = msg.getOperationCumOpenAmount()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationCumOpenAmountBc: (f = msg.getOperationCumOpenAmountBc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTotCumOpenAmount: (f = msg.getOperationTotCumOpenAmount()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTotCumCloseQty: (f = msg.getOperationTotCumCloseQty()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationCumCloseAmount: (f = msg.getOperationCumCloseAmount()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationCumCloseAmountBc: (f = msg.getOperationCumCloseAmountBc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTotCumCloseAmount: (f = msg.getOperationTotCumCloseAmount()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTdyCumQtyMultiplier: (f = msg.getAssetTdyCumQtyMultiplier()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTotCumOpenQtyEnd: (f = msg.getOperationTotCumOpenQtyEnd()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTotCumOpenAmountEnd: (f = msg.getOperationTotCumOpenAmountEnd()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTotCumCloseQtyEnd: (f = msg.getOperationTotCumCloseQtyEnd()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTotCumCloseAmountEnd: (f = msg.getOperationTotCumCloseAmountEnd()) && proto.assets.operations.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.operations.position_status}
 */
proto.assets.operations.position_status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.operations.position_status;
  return proto.assets.operations.position_status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.operations.position_status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.operations.position_status}
 */
proto.assets.operations.position_status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPositionSign(value);
      break;
    case 2:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationCumOpenQty(value);
      break;
    case 3:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationCumCloseQty(value);
      break;
    case 4:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setInitialPositionQuantity(value);
      break;
    case 5:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setInitialPositionQtyMultiplier(value);
      break;
    case 6:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setInitialPositionAmount(value);
      break;
    case 7:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setInitialPositionAmountBc(value);
      break;
    case 8:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setPositionQuantity(value);
      break;
    case 9:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationCumOpenQtyMultiplier(value);
      break;
    case 10:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationCumCloseQtyMultiplier(value);
      break;
    case 11:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setPositionQtyMultiplier(value);
      break;
    case 12:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setPositionAmount(value);
      break;
    case 13:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setPositionAmountBc(value);
      break;
    case 14:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTotCumOpenQty(value);
      break;
    case 15:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationCumOpenAmount(value);
      break;
    case 16:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationCumOpenAmountBc(value);
      break;
    case 17:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTotCumOpenAmount(value);
      break;
    case 18:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTotCumCloseQty(value);
      break;
    case 19:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationCumCloseAmount(value);
      break;
    case 20:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationCumCloseAmountBc(value);
      break;
    case 21:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTotCumCloseAmount(value);
      break;
    case 22:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyCumQtyMultiplier(value);
      break;
    case 23:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTotCumOpenQtyEnd(value);
      break;
    case 24:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTotCumOpenAmountEnd(value);
      break;
    case 25:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTotCumCloseQtyEnd(value);
      break;
    case 26:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTotCumCloseAmountEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.operations.position_status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.operations.position_status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.operations.position_status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.position_status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperationCumOpenQty();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationCumCloseQty();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getInitialPositionQuantity();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getInitialPositionQtyMultiplier();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getInitialPositionAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getInitialPositionAmountBc();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getPositionQuantity();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationCumOpenQtyMultiplier();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationCumCloseQtyMultiplier();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getPositionQtyMultiplier();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getPositionAmount();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getPositionAmountBc();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTotCumOpenQty();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationCumOpenAmount();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationCumOpenAmountBc();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTotCumOpenAmount();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTotCumCloseQty();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationCumCloseAmount();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationCumCloseAmountBc();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTotCumCloseAmount();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyCumQtyMultiplier();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTotCumOpenQtyEnd();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTotCumOpenAmountEnd();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTotCumCloseQtyEnd();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTotCumCloseAmountEnd();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional string position_sign = 1;
 * @return {string}
 */
proto.assets.operations.position_status.prototype.getPositionSign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.setPositionSign = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearPositionSign = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasPositionSign = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal operation_cum_open_qty = 2;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getOperationCumOpenQty = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 2));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setOperationCumOpenQty = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearOperationCumOpenQty = function() {
  return this.setOperationCumOpenQty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasOperationCumOpenQty = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional decimal operation_cum_close_qty = 3;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getOperationCumCloseQty = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 3));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setOperationCumCloseQty = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearOperationCumCloseQty = function() {
  return this.setOperationCumCloseQty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasOperationCumCloseQty = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional decimal initial_position_quantity = 4;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getInitialPositionQuantity = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 4));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setInitialPositionQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearInitialPositionQuantity = function() {
  return this.setInitialPositionQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasInitialPositionQuantity = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional decimal initial_position_qty_multiplier = 5;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getInitialPositionQtyMultiplier = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 5));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setInitialPositionQtyMultiplier = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearInitialPositionQtyMultiplier = function() {
  return this.setInitialPositionQtyMultiplier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasInitialPositionQtyMultiplier = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional decimal initial_position_amount = 6;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getInitialPositionAmount = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 6));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setInitialPositionAmount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearInitialPositionAmount = function() {
  return this.setInitialPositionAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasInitialPositionAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional decimal initial_position_amount_bc = 7;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getInitialPositionAmountBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 7));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setInitialPositionAmountBc = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearInitialPositionAmountBc = function() {
  return this.setInitialPositionAmountBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasInitialPositionAmountBc = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional decimal position_quantity = 8;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getPositionQuantity = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 8));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setPositionQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearPositionQuantity = function() {
  return this.setPositionQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasPositionQuantity = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional decimal operation_cum_open_qty_multiplier = 9;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getOperationCumOpenQtyMultiplier = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 9));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setOperationCumOpenQtyMultiplier = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearOperationCumOpenQtyMultiplier = function() {
  return this.setOperationCumOpenQtyMultiplier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasOperationCumOpenQtyMultiplier = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional decimal operation_cum_close_qty_multiplier = 10;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getOperationCumCloseQtyMultiplier = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 10));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setOperationCumCloseQtyMultiplier = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearOperationCumCloseQtyMultiplier = function() {
  return this.setOperationCumCloseQtyMultiplier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasOperationCumCloseQtyMultiplier = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional decimal position_qty_multiplier = 11;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getPositionQtyMultiplier = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 11));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setPositionQtyMultiplier = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearPositionQtyMultiplier = function() {
  return this.setPositionQtyMultiplier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasPositionQtyMultiplier = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional decimal position_amount = 12;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getPositionAmount = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 12));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setPositionAmount = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearPositionAmount = function() {
  return this.setPositionAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasPositionAmount = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional decimal position_amount_bc = 13;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getPositionAmountBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 13));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setPositionAmountBc = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearPositionAmountBc = function() {
  return this.setPositionAmountBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasPositionAmountBc = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional decimal operation_tot_cum_open_qty = 14;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getOperationTotCumOpenQty = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 14));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setOperationTotCumOpenQty = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearOperationTotCumOpenQty = function() {
  return this.setOperationTotCumOpenQty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasOperationTotCumOpenQty = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional decimal operation_cum_open_amount = 15;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getOperationCumOpenAmount = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 15));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setOperationCumOpenAmount = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearOperationCumOpenAmount = function() {
  return this.setOperationCumOpenAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasOperationCumOpenAmount = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional decimal operation_cum_open_amount_bc = 16;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getOperationCumOpenAmountBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 16));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setOperationCumOpenAmountBc = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearOperationCumOpenAmountBc = function() {
  return this.setOperationCumOpenAmountBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasOperationCumOpenAmountBc = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional decimal operation_tot_cum_open_amount = 17;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getOperationTotCumOpenAmount = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 17));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setOperationTotCumOpenAmount = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearOperationTotCumOpenAmount = function() {
  return this.setOperationTotCumOpenAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasOperationTotCumOpenAmount = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional decimal operation_tot_cum_close_qty = 18;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getOperationTotCumCloseQty = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 18));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setOperationTotCumCloseQty = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearOperationTotCumCloseQty = function() {
  return this.setOperationTotCumCloseQty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasOperationTotCumCloseQty = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional decimal operation_cum_close_amount = 19;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getOperationCumCloseAmount = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 19));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setOperationCumCloseAmount = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearOperationCumCloseAmount = function() {
  return this.setOperationCumCloseAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasOperationCumCloseAmount = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional decimal operation_cum_close_amount_bc = 20;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getOperationCumCloseAmountBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 20));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setOperationCumCloseAmountBc = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearOperationCumCloseAmountBc = function() {
  return this.setOperationCumCloseAmountBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasOperationCumCloseAmountBc = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional decimal operation_tot_cum_close_amount = 21;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getOperationTotCumCloseAmount = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 21));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setOperationTotCumCloseAmount = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearOperationTotCumCloseAmount = function() {
  return this.setOperationTotCumCloseAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasOperationTotCumCloseAmount = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional decimal asset_tdy_cum_qty_multiplier = 22;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getAssetTdyCumQtyMultiplier = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 22));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setAssetTdyCumQtyMultiplier = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearAssetTdyCumQtyMultiplier = function() {
  return this.setAssetTdyCumQtyMultiplier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasAssetTdyCumQtyMultiplier = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional decimal operation_tot_cum_open_qty_end = 23;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getOperationTotCumOpenQtyEnd = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 23));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setOperationTotCumOpenQtyEnd = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearOperationTotCumOpenQtyEnd = function() {
  return this.setOperationTotCumOpenQtyEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasOperationTotCumOpenQtyEnd = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional decimal operation_tot_cum_open_amount_end = 24;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getOperationTotCumOpenAmountEnd = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 24));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setOperationTotCumOpenAmountEnd = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearOperationTotCumOpenAmountEnd = function() {
  return this.setOperationTotCumOpenAmountEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasOperationTotCumOpenAmountEnd = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional decimal operation_tot_cum_close_qty_end = 25;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getOperationTotCumCloseQtyEnd = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 25));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setOperationTotCumCloseQtyEnd = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearOperationTotCumCloseQtyEnd = function() {
  return this.setOperationTotCumCloseQtyEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasOperationTotCumCloseQtyEnd = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional decimal operation_tot_cum_close_amount_end = 26;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.position_status.prototype.getOperationTotCumCloseAmountEnd = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 26));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.position_status} returns this
*/
proto.assets.operations.position_status.prototype.setOperationTotCumCloseAmountEnd = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.position_status} returns this
 */
proto.assets.operations.position_status.prototype.clearOperationTotCumCloseAmountEnd = function() {
  return this.setOperationTotCumCloseAmountEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.position_status.prototype.hasOperationTotCumCloseAmountEnd = function() {
  return jspb.Message.getField(this, 26) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.operations.operation_avg_princ.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.operations.operation_avg_princ.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.operations.operation_avg_princ} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.operation_avg_princ.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationYtdCumPrincWdtEnd: (f = msg.getOperationYtdCumPrincWdtEnd()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationYtdCumPrincWdtBcEnd: (f = msg.getOperationYtdCumPrincWdtBcEnd()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTotCumPrincWdtEnd: (f = msg.getOperationTotCumPrincWdtEnd()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTotCumPrincWdtBcEnd: (f = msg.getOperationTotCumPrincWdtBcEnd()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationOpenDatetime: (f = msg.getOperationOpenDatetime()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationYtdDatetime: (f = msg.getOperationYtdDatetime()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTdyPrincipal: (f = msg.getOperationTdyPrincipal()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTdyCumPrincWdt: (f = msg.getOperationTdyCumPrincWdt()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTdyPrincipalBc: (f = msg.getOperationTdyPrincipalBc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTdyCumPrincWdtBc: (f = msg.getOperationTdyCumPrincWdtBc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTdyAvgPrinc: (f = msg.getOperationTdyAvgPrinc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTdyAvgPrincBc: (f = msg.getOperationTdyAvgPrincBc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationYtdCumPrincWdt: (f = msg.getOperationYtdCumPrincWdt()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationYtdCumPrincWdtBc: (f = msg.getOperationYtdCumPrincWdtBc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationYtdAvgPrinc: (f = msg.getOperationYtdAvgPrinc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationYtdAvgPrincBc: (f = msg.getOperationYtdAvgPrincBc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTotCumPrincWdt: (f = msg.getOperationTotCumPrincWdt()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTotCumPrincWdtBc: (f = msg.getOperationTotCumPrincWdtBc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTotAvgPrinc: (f = msg.getOperationTotAvgPrinc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTotAvgPrincBc: (f = msg.getOperationTotAvgPrincBc()) && proto.assets.operations.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.operations.operation_avg_princ}
 */
proto.assets.operations.operation_avg_princ.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.operations.operation_avg_princ;
  return proto.assets.operations.operation_avg_princ.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.operations.operation_avg_princ} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.operations.operation_avg_princ}
 */
proto.assets.operations.operation_avg_princ.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationYtdCumPrincWdtEnd(value);
      break;
    case 2:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationYtdCumPrincWdtBcEnd(value);
      break;
    case 3:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTotCumPrincWdtEnd(value);
      break;
    case 4:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTotCumPrincWdtBcEnd(value);
      break;
    case 5:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationOpenDatetime(value);
      break;
    case 6:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationYtdDatetime(value);
      break;
    case 7:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTdyPrincipal(value);
      break;
    case 8:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTdyCumPrincWdt(value);
      break;
    case 9:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTdyPrincipalBc(value);
      break;
    case 10:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTdyCumPrincWdtBc(value);
      break;
    case 11:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTdyAvgPrinc(value);
      break;
    case 12:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTdyAvgPrincBc(value);
      break;
    case 13:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationYtdCumPrincWdt(value);
      break;
    case 14:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationYtdCumPrincWdtBc(value);
      break;
    case 15:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationYtdAvgPrinc(value);
      break;
    case 16:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationYtdAvgPrincBc(value);
      break;
    case 17:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTotCumPrincWdt(value);
      break;
    case 18:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTotCumPrincWdtBc(value);
      break;
    case 19:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTotAvgPrinc(value);
      break;
    case 20:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTotAvgPrincBc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.operations.operation_avg_princ.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.operations.operation_avg_princ.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.operations.operation_avg_princ} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.operation_avg_princ.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationYtdCumPrincWdtEnd();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationYtdCumPrincWdtBcEnd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTotCumPrincWdtEnd();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTotCumPrincWdtBcEnd();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationOpenDatetime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationYtdDatetime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTdyPrincipal();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTdyCumPrincWdt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTdyPrincipalBc();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTdyCumPrincWdtBc();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTdyAvgPrinc();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTdyAvgPrincBc();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationYtdCumPrincWdt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationYtdCumPrincWdtBc();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationYtdAvgPrinc();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationYtdAvgPrincBc();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTotCumPrincWdt();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTotCumPrincWdtBc();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTotAvgPrinc();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTotAvgPrincBc();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal operation_ytd_cum_princ_wdt_end = 1;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_avg_princ.prototype.getOperationYtdCumPrincWdtEnd = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 1));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_avg_princ} returns this
*/
proto.assets.operations.operation_avg_princ.prototype.setOperationYtdCumPrincWdtEnd = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_avg_princ} returns this
 */
proto.assets.operations.operation_avg_princ.prototype.clearOperationYtdCumPrincWdtEnd = function() {
  return this.setOperationYtdCumPrincWdtEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_avg_princ.prototype.hasOperationYtdCumPrincWdtEnd = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal operation_ytd_cum_princ_wdt_bc_end = 2;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_avg_princ.prototype.getOperationYtdCumPrincWdtBcEnd = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 2));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_avg_princ} returns this
*/
proto.assets.operations.operation_avg_princ.prototype.setOperationYtdCumPrincWdtBcEnd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_avg_princ} returns this
 */
proto.assets.operations.operation_avg_princ.prototype.clearOperationYtdCumPrincWdtBcEnd = function() {
  return this.setOperationYtdCumPrincWdtBcEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_avg_princ.prototype.hasOperationYtdCumPrincWdtBcEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional decimal operation_tot_cum_princ_wdt_end = 3;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_avg_princ.prototype.getOperationTotCumPrincWdtEnd = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 3));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_avg_princ} returns this
*/
proto.assets.operations.operation_avg_princ.prototype.setOperationTotCumPrincWdtEnd = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_avg_princ} returns this
 */
proto.assets.operations.operation_avg_princ.prototype.clearOperationTotCumPrincWdtEnd = function() {
  return this.setOperationTotCumPrincWdtEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_avg_princ.prototype.hasOperationTotCumPrincWdtEnd = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional decimal operation_tot_cum_princ_wdt_bc_end = 4;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_avg_princ.prototype.getOperationTotCumPrincWdtBcEnd = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 4));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_avg_princ} returns this
*/
proto.assets.operations.operation_avg_princ.prototype.setOperationTotCumPrincWdtBcEnd = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_avg_princ} returns this
 */
proto.assets.operations.operation_avg_princ.prototype.clearOperationTotCumPrincWdtBcEnd = function() {
  return this.setOperationTotCumPrincWdtBcEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_avg_princ.prototype.hasOperationTotCumPrincWdtBcEnd = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional decimal operation_open_datetime = 5;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_avg_princ.prototype.getOperationOpenDatetime = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 5));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_avg_princ} returns this
*/
proto.assets.operations.operation_avg_princ.prototype.setOperationOpenDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_avg_princ} returns this
 */
proto.assets.operations.operation_avg_princ.prototype.clearOperationOpenDatetime = function() {
  return this.setOperationOpenDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_avg_princ.prototype.hasOperationOpenDatetime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional decimal operation_ytd_datetime = 6;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_avg_princ.prototype.getOperationYtdDatetime = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 6));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_avg_princ} returns this
*/
proto.assets.operations.operation_avg_princ.prototype.setOperationYtdDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_avg_princ} returns this
 */
proto.assets.operations.operation_avg_princ.prototype.clearOperationYtdDatetime = function() {
  return this.setOperationYtdDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_avg_princ.prototype.hasOperationYtdDatetime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional decimal operation_tdy_principal = 7;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_avg_princ.prototype.getOperationTdyPrincipal = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 7));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_avg_princ} returns this
*/
proto.assets.operations.operation_avg_princ.prototype.setOperationTdyPrincipal = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_avg_princ} returns this
 */
proto.assets.operations.operation_avg_princ.prototype.clearOperationTdyPrincipal = function() {
  return this.setOperationTdyPrincipal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_avg_princ.prototype.hasOperationTdyPrincipal = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional decimal operation_tdy_cum_princ_wdt = 8;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_avg_princ.prototype.getOperationTdyCumPrincWdt = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 8));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_avg_princ} returns this
*/
proto.assets.operations.operation_avg_princ.prototype.setOperationTdyCumPrincWdt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_avg_princ} returns this
 */
proto.assets.operations.operation_avg_princ.prototype.clearOperationTdyCumPrincWdt = function() {
  return this.setOperationTdyCumPrincWdt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_avg_princ.prototype.hasOperationTdyCumPrincWdt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional decimal operation_tdy_principal_bc = 9;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_avg_princ.prototype.getOperationTdyPrincipalBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 9));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_avg_princ} returns this
*/
proto.assets.operations.operation_avg_princ.prototype.setOperationTdyPrincipalBc = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_avg_princ} returns this
 */
proto.assets.operations.operation_avg_princ.prototype.clearOperationTdyPrincipalBc = function() {
  return this.setOperationTdyPrincipalBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_avg_princ.prototype.hasOperationTdyPrincipalBc = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional decimal operation_tdy_cum_princ_wdt_bc = 10;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_avg_princ.prototype.getOperationTdyCumPrincWdtBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 10));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_avg_princ} returns this
*/
proto.assets.operations.operation_avg_princ.prototype.setOperationTdyCumPrincWdtBc = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_avg_princ} returns this
 */
proto.assets.operations.operation_avg_princ.prototype.clearOperationTdyCumPrincWdtBc = function() {
  return this.setOperationTdyCumPrincWdtBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_avg_princ.prototype.hasOperationTdyCumPrincWdtBc = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional decimal operation_tdy_avg_princ = 11;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_avg_princ.prototype.getOperationTdyAvgPrinc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 11));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_avg_princ} returns this
*/
proto.assets.operations.operation_avg_princ.prototype.setOperationTdyAvgPrinc = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_avg_princ} returns this
 */
proto.assets.operations.operation_avg_princ.prototype.clearOperationTdyAvgPrinc = function() {
  return this.setOperationTdyAvgPrinc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_avg_princ.prototype.hasOperationTdyAvgPrinc = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional decimal operation_tdy_avg_princ_bc = 12;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_avg_princ.prototype.getOperationTdyAvgPrincBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 12));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_avg_princ} returns this
*/
proto.assets.operations.operation_avg_princ.prototype.setOperationTdyAvgPrincBc = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_avg_princ} returns this
 */
proto.assets.operations.operation_avg_princ.prototype.clearOperationTdyAvgPrincBc = function() {
  return this.setOperationTdyAvgPrincBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_avg_princ.prototype.hasOperationTdyAvgPrincBc = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional decimal operation_ytd_cum_princ_wdt = 13;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_avg_princ.prototype.getOperationYtdCumPrincWdt = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 13));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_avg_princ} returns this
*/
proto.assets.operations.operation_avg_princ.prototype.setOperationYtdCumPrincWdt = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_avg_princ} returns this
 */
proto.assets.operations.operation_avg_princ.prototype.clearOperationYtdCumPrincWdt = function() {
  return this.setOperationYtdCumPrincWdt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_avg_princ.prototype.hasOperationYtdCumPrincWdt = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional decimal operation_ytd_cum_princ_wdt_bc = 14;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_avg_princ.prototype.getOperationYtdCumPrincWdtBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 14));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_avg_princ} returns this
*/
proto.assets.operations.operation_avg_princ.prototype.setOperationYtdCumPrincWdtBc = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_avg_princ} returns this
 */
proto.assets.operations.operation_avg_princ.prototype.clearOperationYtdCumPrincWdtBc = function() {
  return this.setOperationYtdCumPrincWdtBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_avg_princ.prototype.hasOperationYtdCumPrincWdtBc = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional decimal operation_ytd_avg_princ = 15;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_avg_princ.prototype.getOperationYtdAvgPrinc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 15));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_avg_princ} returns this
*/
proto.assets.operations.operation_avg_princ.prototype.setOperationYtdAvgPrinc = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_avg_princ} returns this
 */
proto.assets.operations.operation_avg_princ.prototype.clearOperationYtdAvgPrinc = function() {
  return this.setOperationYtdAvgPrinc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_avg_princ.prototype.hasOperationYtdAvgPrinc = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional decimal operation_ytd_avg_princ_bc = 16;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_avg_princ.prototype.getOperationYtdAvgPrincBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 16));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_avg_princ} returns this
*/
proto.assets.operations.operation_avg_princ.prototype.setOperationYtdAvgPrincBc = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_avg_princ} returns this
 */
proto.assets.operations.operation_avg_princ.prototype.clearOperationYtdAvgPrincBc = function() {
  return this.setOperationYtdAvgPrincBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_avg_princ.prototype.hasOperationYtdAvgPrincBc = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional decimal operation_tot_cum_princ_wdt = 17;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_avg_princ.prototype.getOperationTotCumPrincWdt = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 17));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_avg_princ} returns this
*/
proto.assets.operations.operation_avg_princ.prototype.setOperationTotCumPrincWdt = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_avg_princ} returns this
 */
proto.assets.operations.operation_avg_princ.prototype.clearOperationTotCumPrincWdt = function() {
  return this.setOperationTotCumPrincWdt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_avg_princ.prototype.hasOperationTotCumPrincWdt = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional decimal operation_tot_cum_princ_wdt_bc = 18;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_avg_princ.prototype.getOperationTotCumPrincWdtBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 18));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_avg_princ} returns this
*/
proto.assets.operations.operation_avg_princ.prototype.setOperationTotCumPrincWdtBc = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_avg_princ} returns this
 */
proto.assets.operations.operation_avg_princ.prototype.clearOperationTotCumPrincWdtBc = function() {
  return this.setOperationTotCumPrincWdtBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_avg_princ.prototype.hasOperationTotCumPrincWdtBc = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional decimal operation_tot_avg_princ = 19;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_avg_princ.prototype.getOperationTotAvgPrinc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 19));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_avg_princ} returns this
*/
proto.assets.operations.operation_avg_princ.prototype.setOperationTotAvgPrinc = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_avg_princ} returns this
 */
proto.assets.operations.operation_avg_princ.prototype.clearOperationTotAvgPrinc = function() {
  return this.setOperationTotAvgPrinc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_avg_princ.prototype.hasOperationTotAvgPrinc = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional decimal operation_tot_avg_princ_bc = 20;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.operation_avg_princ.prototype.getOperationTotAvgPrincBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 20));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.operation_avg_princ} returns this
*/
proto.assets.operations.operation_avg_princ.prototype.setOperationTotAvgPrincBc = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.operation_avg_princ} returns this
 */
proto.assets.operations.operation_avg_princ.prototype.clearOperationTotAvgPrincBc = function() {
  return this.setOperationTotAvgPrincBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.operation_avg_princ.prototype.hasOperationTotAvgPrincBc = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.operations.fees.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.operations.fees.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.operations.fees} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.fees.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradeBrokerVarFeesContract: (f = msg.getTradeBrokerVarFeesContract()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    tradeBrokerVarFeesPerc: (f = msg.getTradeBrokerVarFeesPerc()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    operationTdyLendingFeeAmount: (f = msg.getOperationTdyLendingFeeAmount()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    tradeTransactionTax: (f = msg.getTradeTransactionTax()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    cumFeesBc: (f = msg.getCumFeesBc()) && proto.assets.operations.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.operations.fees}
 */
proto.assets.operations.fees.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.operations.fees;
  return proto.assets.operations.fees.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.operations.fees} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.operations.fees}
 */
proto.assets.operations.fees.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setTradeBrokerVarFeesContract(value);
      break;
    case 2:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setTradeBrokerVarFeesPerc(value);
      break;
    case 3:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setOperationTdyLendingFeeAmount(value);
      break;
    case 4:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setTradeTransactionTax(value);
      break;
    case 5:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setCumFeesBc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.operations.fees.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.operations.fees.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.operations.fees} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.fees.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradeBrokerVarFeesContract();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTradeBrokerVarFeesPerc();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOperationTdyLendingFeeAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTradeTransactionTax();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getCumFeesBc();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal trade_broker_var_fees_contract = 1;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.fees.prototype.getTradeBrokerVarFeesContract = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 1));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.fees} returns this
*/
proto.assets.operations.fees.prototype.setTradeBrokerVarFeesContract = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.fees} returns this
 */
proto.assets.operations.fees.prototype.clearTradeBrokerVarFeesContract = function() {
  return this.setTradeBrokerVarFeesContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.fees.prototype.hasTradeBrokerVarFeesContract = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal trade_broker_var_fees_perc = 2;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.fees.prototype.getTradeBrokerVarFeesPerc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 2));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.fees} returns this
*/
proto.assets.operations.fees.prototype.setTradeBrokerVarFeesPerc = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.fees} returns this
 */
proto.assets.operations.fees.prototype.clearTradeBrokerVarFeesPerc = function() {
  return this.setTradeBrokerVarFeesPerc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.fees.prototype.hasTradeBrokerVarFeesPerc = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional decimal operation_tdy_lending_fee_amount = 3;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.fees.prototype.getOperationTdyLendingFeeAmount = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 3));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.fees} returns this
*/
proto.assets.operations.fees.prototype.setOperationTdyLendingFeeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.fees} returns this
 */
proto.assets.operations.fees.prototype.clearOperationTdyLendingFeeAmount = function() {
  return this.setOperationTdyLendingFeeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.fees.prototype.hasOperationTdyLendingFeeAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional decimal trade_transaction_tax = 4;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.fees.prototype.getTradeTransactionTax = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 4));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.fees} returns this
*/
proto.assets.operations.fees.prototype.setTradeTransactionTax = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.fees} returns this
 */
proto.assets.operations.fees.prototype.clearTradeTransactionTax = function() {
  return this.setTradeTransactionTax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.fees.prototype.hasTradeTransactionTax = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional decimal cum_fees_bc = 5;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.fees.prototype.getCumFeesBc = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 5));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.fees} returns this
*/
proto.assets.operations.fees.prototype.setCumFeesBc = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.fees} returns this
 */
proto.assets.operations.fees.prototype.clearCumFeesBc = function() {
  return this.setCumFeesBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.fees.prototype.hasCumFeesBc = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.operations.qty_multiplier_close.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.operations.qty_multiplier_close.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.operations.qty_multiplier_close} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.qty_multiplier_close.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetTdyCumQtyMultiplierClose: (f = msg.getAssetTdyCumQtyMultiplierClose()) && proto.assets.operations.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.operations.qty_multiplier_close}
 */
proto.assets.operations.qty_multiplier_close.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.operations.qty_multiplier_close;
  return proto.assets.operations.qty_multiplier_close.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.operations.qty_multiplier_close} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.operations.qty_multiplier_close}
 */
proto.assets.operations.qty_multiplier_close.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyCumQtyMultiplierClose(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.operations.qty_multiplier_close.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.operations.qty_multiplier_close.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.operations.qty_multiplier_close} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.qty_multiplier_close.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetTdyCumQtyMultiplierClose();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal asset_tdy_cum_qty_multiplier_close = 1;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.qty_multiplier_close.prototype.getAssetTdyCumQtyMultiplierClose = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 1));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.qty_multiplier_close} returns this
*/
proto.assets.operations.qty_multiplier_close.prototype.setAssetTdyCumQtyMultiplierClose = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.qty_multiplier_close} returns this
 */
proto.assets.operations.qty_multiplier_close.prototype.clearAssetTdyCumQtyMultiplierClose = function() {
  return this.setAssetTdyCumQtyMultiplierClose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.qty_multiplier_close.prototype.hasAssetTdyCumQtyMultiplierClose = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.operations.asset_cum_perc_ret_close.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.operations.asset_cum_perc_ret_close} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.asset_cum_perc_ret_close.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetTdyClosedCumPercRetLngPos: (f = msg.getAssetTdyClosedCumPercRetLngPos()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTdyClosedCumPercRetShrPos: (f = msg.getAssetTdyClosedCumPercRetShrPos()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTdyClosedCumPercRetLngNeg: (f = msg.getAssetTdyClosedCumPercRetLngNeg()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTdyClosedCumPercRetShrNeg: (f = msg.getAssetTdyClosedCumPercRetShrNeg()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTdyClosedCumPercRetLngPos2: (f = msg.getAssetTdyClosedCumPercRetLngPos2()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTdyClosedCumPercRetShrPos2: (f = msg.getAssetTdyClosedCumPercRetShrPos2()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTdyClosedCumPercRetLngNeg2: (f = msg.getAssetTdyClosedCumPercRetLngNeg2()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTdyClosedCumPercRetShrNeg2: (f = msg.getAssetTdyClosedCumPercRetShrNeg2()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetYtdCumPercentageRetClose: (f = msg.getAssetYtdCumPercentageRetClose()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetYtdCumPercentageRetClose2: (f = msg.getAssetYtdCumPercentageRetClose2()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTotCumPercentageRetClose: (f = msg.getAssetTotCumPercentageRetClose()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTotCumPercentageRetClose2: (f = msg.getAssetTotCumPercentageRetClose2()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTotCumPercentageRetClose3: (f = msg.getAssetTotCumPercentageRetClose3()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTotCumPercentageRetClose4: (f = msg.getAssetTotCumPercentageRetClose4()) && proto.assets.operations.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.operations.asset_cum_perc_ret_close}
 */
proto.assets.operations.asset_cum_perc_ret_close.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.operations.asset_cum_perc_ret_close;
  return proto.assets.operations.asset_cum_perc_ret_close.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.operations.asset_cum_perc_ret_close} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.operations.asset_cum_perc_ret_close}
 */
proto.assets.operations.asset_cum_perc_ret_close.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyClosedCumPercRetLngPos(value);
      break;
    case 2:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyClosedCumPercRetShrPos(value);
      break;
    case 3:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyClosedCumPercRetLngNeg(value);
      break;
    case 4:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyClosedCumPercRetShrNeg(value);
      break;
    case 5:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyClosedCumPercRetLngPos2(value);
      break;
    case 6:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyClosedCumPercRetShrPos2(value);
      break;
    case 7:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyClosedCumPercRetLngNeg2(value);
      break;
    case 8:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyClosedCumPercRetShrNeg2(value);
      break;
    case 9:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetYtdCumPercentageRetClose(value);
      break;
    case 10:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetYtdCumPercentageRetClose2(value);
      break;
    case 11:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTotCumPercentageRetClose(value);
      break;
    case 12:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTotCumPercentageRetClose2(value);
      break;
    case 13:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTotCumPercentageRetClose3(value);
      break;
    case 14:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTotCumPercentageRetClose4(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.operations.asset_cum_perc_ret_close.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.operations.asset_cum_perc_ret_close} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.asset_cum_perc_ret_close.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetTdyClosedCumPercRetLngPos();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyClosedCumPercRetShrPos();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyClosedCumPercRetLngNeg();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyClosedCumPercRetShrNeg();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyClosedCumPercRetLngPos2();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyClosedCumPercRetShrPos2();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyClosedCumPercRetLngNeg2();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyClosedCumPercRetShrNeg2();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetYtdCumPercentageRetClose();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetYtdCumPercentageRetClose2();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTotCumPercentageRetClose();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTotCumPercentageRetClose2();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTotCumPercentageRetClose3();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTotCumPercentageRetClose4();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal asset_tdy_closed_cum_perc_ret_lng_pos = 1;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.getAssetTdyClosedCumPercRetLngPos = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 1));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
*/
proto.assets.operations.asset_cum_perc_ret_close.prototype.setAssetTdyClosedCumPercRetLngPos = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.clearAssetTdyClosedCumPercRetLngPos = function() {
  return this.setAssetTdyClosedCumPercRetLngPos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.hasAssetTdyClosedCumPercRetLngPos = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal asset_tdy_closed_cum_perc_ret_shr_pos = 2;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.getAssetTdyClosedCumPercRetShrPos = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 2));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
*/
proto.assets.operations.asset_cum_perc_ret_close.prototype.setAssetTdyClosedCumPercRetShrPos = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.clearAssetTdyClosedCumPercRetShrPos = function() {
  return this.setAssetTdyClosedCumPercRetShrPos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.hasAssetTdyClosedCumPercRetShrPos = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional decimal asset_tdy_closed_cum_perc_ret_lng_neg = 3;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.getAssetTdyClosedCumPercRetLngNeg = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 3));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
*/
proto.assets.operations.asset_cum_perc_ret_close.prototype.setAssetTdyClosedCumPercRetLngNeg = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.clearAssetTdyClosedCumPercRetLngNeg = function() {
  return this.setAssetTdyClosedCumPercRetLngNeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.hasAssetTdyClosedCumPercRetLngNeg = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional decimal asset_tdy_closed_cum_perc_ret_shr_neg = 4;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.getAssetTdyClosedCumPercRetShrNeg = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 4));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
*/
proto.assets.operations.asset_cum_perc_ret_close.prototype.setAssetTdyClosedCumPercRetShrNeg = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.clearAssetTdyClosedCumPercRetShrNeg = function() {
  return this.setAssetTdyClosedCumPercRetShrNeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.hasAssetTdyClosedCumPercRetShrNeg = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional decimal asset_tdy_closed_cum_perc_ret_lng_pos2 = 5;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.getAssetTdyClosedCumPercRetLngPos2 = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 5));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
*/
proto.assets.operations.asset_cum_perc_ret_close.prototype.setAssetTdyClosedCumPercRetLngPos2 = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.clearAssetTdyClosedCumPercRetLngPos2 = function() {
  return this.setAssetTdyClosedCumPercRetLngPos2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.hasAssetTdyClosedCumPercRetLngPos2 = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional decimal asset_tdy_closed_cum_perc_ret_shr_pos2 = 6;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.getAssetTdyClosedCumPercRetShrPos2 = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 6));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
*/
proto.assets.operations.asset_cum_perc_ret_close.prototype.setAssetTdyClosedCumPercRetShrPos2 = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.clearAssetTdyClosedCumPercRetShrPos2 = function() {
  return this.setAssetTdyClosedCumPercRetShrPos2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.hasAssetTdyClosedCumPercRetShrPos2 = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional decimal asset_tdy_closed_cum_perc_ret_lng_neg2 = 7;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.getAssetTdyClosedCumPercRetLngNeg2 = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 7));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
*/
proto.assets.operations.asset_cum_perc_ret_close.prototype.setAssetTdyClosedCumPercRetLngNeg2 = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.clearAssetTdyClosedCumPercRetLngNeg2 = function() {
  return this.setAssetTdyClosedCumPercRetLngNeg2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.hasAssetTdyClosedCumPercRetLngNeg2 = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional decimal asset_tdy_closed_cum_perc_ret_shr_neg2 = 8;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.getAssetTdyClosedCumPercRetShrNeg2 = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 8));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
*/
proto.assets.operations.asset_cum_perc_ret_close.prototype.setAssetTdyClosedCumPercRetShrNeg2 = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.clearAssetTdyClosedCumPercRetShrNeg2 = function() {
  return this.setAssetTdyClosedCumPercRetShrNeg2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.hasAssetTdyClosedCumPercRetShrNeg2 = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional decimal asset_ytd_cum_percentage_ret_close = 9;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.getAssetYtdCumPercentageRetClose = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 9));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
*/
proto.assets.operations.asset_cum_perc_ret_close.prototype.setAssetYtdCumPercentageRetClose = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.clearAssetYtdCumPercentageRetClose = function() {
  return this.setAssetYtdCumPercentageRetClose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.hasAssetYtdCumPercentageRetClose = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional decimal asset_ytd_cum_percentage_ret_close2 = 10;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.getAssetYtdCumPercentageRetClose2 = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 10));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
*/
proto.assets.operations.asset_cum_perc_ret_close.prototype.setAssetYtdCumPercentageRetClose2 = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.clearAssetYtdCumPercentageRetClose2 = function() {
  return this.setAssetYtdCumPercentageRetClose2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.hasAssetYtdCumPercentageRetClose2 = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional decimal asset_tot_cum_percentage_ret_close = 11;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.getAssetTotCumPercentageRetClose = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 11));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
*/
proto.assets.operations.asset_cum_perc_ret_close.prototype.setAssetTotCumPercentageRetClose = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.clearAssetTotCumPercentageRetClose = function() {
  return this.setAssetTotCumPercentageRetClose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.hasAssetTotCumPercentageRetClose = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional decimal asset_tot_cum_percentage_ret_close2 = 12;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.getAssetTotCumPercentageRetClose2 = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 12));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
*/
proto.assets.operations.asset_cum_perc_ret_close.prototype.setAssetTotCumPercentageRetClose2 = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.clearAssetTotCumPercentageRetClose2 = function() {
  return this.setAssetTotCumPercentageRetClose2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.hasAssetTotCumPercentageRetClose2 = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional decimal asset_tot_cum_percentage_ret_close3 = 13;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.getAssetTotCumPercentageRetClose3 = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 13));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
*/
proto.assets.operations.asset_cum_perc_ret_close.prototype.setAssetTotCumPercentageRetClose3 = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.clearAssetTotCumPercentageRetClose3 = function() {
  return this.setAssetTotCumPercentageRetClose3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.hasAssetTotCumPercentageRetClose3 = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional decimal asset_tot_cum_percentage_ret_close4 = 14;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.getAssetTotCumPercentageRetClose4 = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 14));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
*/
proto.assets.operations.asset_cum_perc_ret_close.prototype.setAssetTotCumPercentageRetClose4 = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret_close} returns this
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.clearAssetTotCumPercentageRetClose4 = function() {
  return this.setAssetTotCumPercentageRetClose4(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret_close.prototype.hasAssetTotCumPercentageRetClose4 = function() {
  return jspb.Message.getField(this, 14) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.operations.asset_cum_perc_ret.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.operations.asset_cum_perc_ret} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.asset_cum_perc_ret.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetTdyCumPercRetLngPos: (f = msg.getAssetTdyCumPercRetLngPos()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTdyCumPercRetShrPos: (f = msg.getAssetTdyCumPercRetShrPos()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTdyCumPercRetLngNeg: (f = msg.getAssetTdyCumPercRetLngNeg()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTdyCumPercRetShrNeg: (f = msg.getAssetTdyCumPercRetShrNeg()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTdyCumPercRet: (f = msg.getAssetTdyCumPercRet()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTdyCumPercRetLngPos2: (f = msg.getAssetTdyCumPercRetLngPos2()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTdyCumPercRetShrPos2: (f = msg.getAssetTdyCumPercRetShrPos2()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTdyCumPercRetLngNeg2: (f = msg.getAssetTdyCumPercRetLngNeg2()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTdyCumPercRetShrNeg2: (f = msg.getAssetTdyCumPercRetShrNeg2()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTdyCumPercRet2: (f = msg.getAssetTdyCumPercRet2()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetYtdCumPercentageRet: (f = msg.getAssetYtdCumPercentageRet()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetYtdCumPercentageRet2: (f = msg.getAssetYtdCumPercentageRet2()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTotCumPercentageRet: (f = msg.getAssetTotCumPercentageRet()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTotCumPercentageRet2: (f = msg.getAssetTotCumPercentageRet2()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTotCumPercentageRet3: (f = msg.getAssetTotCumPercentageRet3()) && proto.assets.operations.decimal.toObject(includeInstance, f),
    assetTotCumPercentageRet4: (f = msg.getAssetTotCumPercentageRet4()) && proto.assets.operations.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.operations.asset_cum_perc_ret}
 */
proto.assets.operations.asset_cum_perc_ret.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.operations.asset_cum_perc_ret;
  return proto.assets.operations.asset_cum_perc_ret.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.operations.asset_cum_perc_ret} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.operations.asset_cum_perc_ret}
 */
proto.assets.operations.asset_cum_perc_ret.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyCumPercRetLngPos(value);
      break;
    case 2:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyCumPercRetShrPos(value);
      break;
    case 3:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyCumPercRetLngNeg(value);
      break;
    case 4:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyCumPercRetShrNeg(value);
      break;
    case 5:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyCumPercRet(value);
      break;
    case 6:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyCumPercRetLngPos2(value);
      break;
    case 7:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyCumPercRetShrPos2(value);
      break;
    case 8:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyCumPercRetLngNeg2(value);
      break;
    case 9:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyCumPercRetShrNeg2(value);
      break;
    case 10:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyCumPercRet2(value);
      break;
    case 11:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetYtdCumPercentageRet(value);
      break;
    case 12:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetYtdCumPercentageRet2(value);
      break;
    case 13:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTotCumPercentageRet(value);
      break;
    case 14:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTotCumPercentageRet2(value);
      break;
    case 15:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTotCumPercentageRet3(value);
      break;
    case 16:
      var value = new proto.assets.operations.decimal;
      reader.readMessage(value,proto.assets.operations.decimal.deserializeBinaryFromReader);
      msg.setAssetTotCumPercentageRet4(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.operations.asset_cum_perc_ret.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.operations.asset_cum_perc_ret} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.asset_cum_perc_ret.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetTdyCumPercRetLngPos();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyCumPercRetShrPos();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyCumPercRetLngNeg();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyCumPercRetShrNeg();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyCumPercRet();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyCumPercRetLngPos2();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyCumPercRetShrPos2();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyCumPercRetLngNeg2();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyCumPercRetShrNeg2();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyCumPercRet2();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetYtdCumPercentageRet();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetYtdCumPercentageRet2();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTotCumPercentageRet();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTotCumPercentageRet2();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTotCumPercentageRet3();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTotCumPercentageRet4();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.assets.operations.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal asset_tdy_cum_perc_ret_lng_pos = 1;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.getAssetTdyCumPercRetLngPos = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 1));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
*/
proto.assets.operations.asset_cum_perc_ret.prototype.setAssetTdyCumPercRetLngPos = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
 */
proto.assets.operations.asset_cum_perc_ret.prototype.clearAssetTdyCumPercRetLngPos = function() {
  return this.setAssetTdyCumPercRetLngPos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.hasAssetTdyCumPercRetLngPos = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal asset_tdy_cum_perc_ret_shr_pos = 2;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.getAssetTdyCumPercRetShrPos = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 2));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
*/
proto.assets.operations.asset_cum_perc_ret.prototype.setAssetTdyCumPercRetShrPos = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
 */
proto.assets.operations.asset_cum_perc_ret.prototype.clearAssetTdyCumPercRetShrPos = function() {
  return this.setAssetTdyCumPercRetShrPos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.hasAssetTdyCumPercRetShrPos = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional decimal asset_tdy_cum_perc_ret_lng_neg = 3;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.getAssetTdyCumPercRetLngNeg = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 3));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
*/
proto.assets.operations.asset_cum_perc_ret.prototype.setAssetTdyCumPercRetLngNeg = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
 */
proto.assets.operations.asset_cum_perc_ret.prototype.clearAssetTdyCumPercRetLngNeg = function() {
  return this.setAssetTdyCumPercRetLngNeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.hasAssetTdyCumPercRetLngNeg = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional decimal asset_tdy_cum_perc_ret_shr_neg = 4;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.getAssetTdyCumPercRetShrNeg = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 4));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
*/
proto.assets.operations.asset_cum_perc_ret.prototype.setAssetTdyCumPercRetShrNeg = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
 */
proto.assets.operations.asset_cum_perc_ret.prototype.clearAssetTdyCumPercRetShrNeg = function() {
  return this.setAssetTdyCumPercRetShrNeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.hasAssetTdyCumPercRetShrNeg = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional decimal asset_tdy_cum_perc_ret = 5;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.getAssetTdyCumPercRet = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 5));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
*/
proto.assets.operations.asset_cum_perc_ret.prototype.setAssetTdyCumPercRet = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
 */
proto.assets.operations.asset_cum_perc_ret.prototype.clearAssetTdyCumPercRet = function() {
  return this.setAssetTdyCumPercRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.hasAssetTdyCumPercRet = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional decimal asset_tdy_cum_perc_ret_lng_pos2 = 6;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.getAssetTdyCumPercRetLngPos2 = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 6));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
*/
proto.assets.operations.asset_cum_perc_ret.prototype.setAssetTdyCumPercRetLngPos2 = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
 */
proto.assets.operations.asset_cum_perc_ret.prototype.clearAssetTdyCumPercRetLngPos2 = function() {
  return this.setAssetTdyCumPercRetLngPos2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.hasAssetTdyCumPercRetLngPos2 = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional decimal asset_tdy_cum_perc_ret_shr_pos2 = 7;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.getAssetTdyCumPercRetShrPos2 = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 7));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
*/
proto.assets.operations.asset_cum_perc_ret.prototype.setAssetTdyCumPercRetShrPos2 = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
 */
proto.assets.operations.asset_cum_perc_ret.prototype.clearAssetTdyCumPercRetShrPos2 = function() {
  return this.setAssetTdyCumPercRetShrPos2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.hasAssetTdyCumPercRetShrPos2 = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional decimal asset_tdy_cum_perc_ret_lng_neg2 = 8;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.getAssetTdyCumPercRetLngNeg2 = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 8));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
*/
proto.assets.operations.asset_cum_perc_ret.prototype.setAssetTdyCumPercRetLngNeg2 = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
 */
proto.assets.operations.asset_cum_perc_ret.prototype.clearAssetTdyCumPercRetLngNeg2 = function() {
  return this.setAssetTdyCumPercRetLngNeg2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.hasAssetTdyCumPercRetLngNeg2 = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional decimal asset_tdy_cum_perc_ret_shr_neg2 = 9;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.getAssetTdyCumPercRetShrNeg2 = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 9));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
*/
proto.assets.operations.asset_cum_perc_ret.prototype.setAssetTdyCumPercRetShrNeg2 = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
 */
proto.assets.operations.asset_cum_perc_ret.prototype.clearAssetTdyCumPercRetShrNeg2 = function() {
  return this.setAssetTdyCumPercRetShrNeg2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.hasAssetTdyCumPercRetShrNeg2 = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional decimal asset_tdy_cum_perc_ret2 = 10;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.getAssetTdyCumPercRet2 = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 10));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
*/
proto.assets.operations.asset_cum_perc_ret.prototype.setAssetTdyCumPercRet2 = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
 */
proto.assets.operations.asset_cum_perc_ret.prototype.clearAssetTdyCumPercRet2 = function() {
  return this.setAssetTdyCumPercRet2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.hasAssetTdyCumPercRet2 = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional decimal asset_ytd_cum_percentage_ret = 11;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.getAssetYtdCumPercentageRet = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 11));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
*/
proto.assets.operations.asset_cum_perc_ret.prototype.setAssetYtdCumPercentageRet = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
 */
proto.assets.operations.asset_cum_perc_ret.prototype.clearAssetYtdCumPercentageRet = function() {
  return this.setAssetYtdCumPercentageRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.hasAssetYtdCumPercentageRet = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional decimal asset_ytd_cum_percentage_ret2 = 12;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.getAssetYtdCumPercentageRet2 = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 12));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
*/
proto.assets.operations.asset_cum_perc_ret.prototype.setAssetYtdCumPercentageRet2 = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
 */
proto.assets.operations.asset_cum_perc_ret.prototype.clearAssetYtdCumPercentageRet2 = function() {
  return this.setAssetYtdCumPercentageRet2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.hasAssetYtdCumPercentageRet2 = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional decimal asset_tot_cum_percentage_ret = 13;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.getAssetTotCumPercentageRet = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 13));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
*/
proto.assets.operations.asset_cum_perc_ret.prototype.setAssetTotCumPercentageRet = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
 */
proto.assets.operations.asset_cum_perc_ret.prototype.clearAssetTotCumPercentageRet = function() {
  return this.setAssetTotCumPercentageRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.hasAssetTotCumPercentageRet = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional decimal asset_tot_cum_percentage_ret2 = 14;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.getAssetTotCumPercentageRet2 = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 14));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
*/
proto.assets.operations.asset_cum_perc_ret.prototype.setAssetTotCumPercentageRet2 = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
 */
proto.assets.operations.asset_cum_perc_ret.prototype.clearAssetTotCumPercentageRet2 = function() {
  return this.setAssetTotCumPercentageRet2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.hasAssetTotCumPercentageRet2 = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional decimal asset_tot_cum_percentage_ret3 = 15;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.getAssetTotCumPercentageRet3 = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 15));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
*/
proto.assets.operations.asset_cum_perc_ret.prototype.setAssetTotCumPercentageRet3 = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
 */
proto.assets.operations.asset_cum_perc_ret.prototype.clearAssetTotCumPercentageRet3 = function() {
  return this.setAssetTotCumPercentageRet3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.hasAssetTotCumPercentageRet3 = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional decimal asset_tot_cum_percentage_ret4 = 16;
 * @return {?proto.assets.operations.decimal}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.getAssetTotCumPercentageRet4 = function() {
  return /** @type{?proto.assets.operations.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.decimal, 16));
};


/**
 * @param {?proto.assets.operations.decimal|undefined} value
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
*/
proto.assets.operations.asset_cum_perc_ret.prototype.setAssetTotCumPercentageRet4 = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.asset_cum_perc_ret} returns this
 */
proto.assets.operations.asset_cum_perc_ret.prototype.clearAssetTotCumPercentageRet4 = function() {
  return this.setAssetTotCumPercentageRet4(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_cum_perc_ret.prototype.hasAssetTotCumPercentageRet4 = function() {
  return jspb.Message.getField(this, 16) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.operations.asset_closed_op.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.operations.asset_closed_op.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.operations.asset_closed_op} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.asset_closed_op.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetTdyClosedLngPosOp: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    assetTdyClosedShrPosOp: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    assetTdyClosedLngNegOp: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    assetTdyClosedShrNegOp: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    assetYtdClosedNegativeOp: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    assetYtdClosedPositiveOp: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    assetTotClosedNegativeOp: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    assetTotClosedPositiveOp: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.operations.asset_closed_op}
 */
proto.assets.operations.asset_closed_op.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.operations.asset_closed_op;
  return proto.assets.operations.asset_closed_op.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.operations.asset_closed_op} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.operations.asset_closed_op}
 */
proto.assets.operations.asset_closed_op.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetTdyClosedLngPosOp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetTdyClosedShrPosOp(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetTdyClosedLngNegOp(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetTdyClosedShrNegOp(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetYtdClosedNegativeOp(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetYtdClosedPositiveOp(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetTotClosedNegativeOp(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetTotClosedPositiveOp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.operations.asset_closed_op.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.operations.asset_closed_op.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.operations.asset_closed_op} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.asset_closed_op.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeUint32(
      8,
      f
    );
  }
};


/**
 * optional uint32 asset_tdy_closed_lng_pos_op = 1;
 * @return {number}
 */
proto.assets.operations.asset_closed_op.prototype.getAssetTdyClosedLngPosOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.asset_closed_op} returns this
 */
proto.assets.operations.asset_closed_op.prototype.setAssetTdyClosedLngPosOp = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.asset_closed_op} returns this
 */
proto.assets.operations.asset_closed_op.prototype.clearAssetTdyClosedLngPosOp = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_closed_op.prototype.hasAssetTdyClosedLngPosOp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 asset_tdy_closed_shr_pos_op = 2;
 * @return {number}
 */
proto.assets.operations.asset_closed_op.prototype.getAssetTdyClosedShrPosOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.asset_closed_op} returns this
 */
proto.assets.operations.asset_closed_op.prototype.setAssetTdyClosedShrPosOp = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.asset_closed_op} returns this
 */
proto.assets.operations.asset_closed_op.prototype.clearAssetTdyClosedShrPosOp = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_closed_op.prototype.hasAssetTdyClosedShrPosOp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 asset_tdy_closed_lng_neg_op = 3;
 * @return {number}
 */
proto.assets.operations.asset_closed_op.prototype.getAssetTdyClosedLngNegOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.asset_closed_op} returns this
 */
proto.assets.operations.asset_closed_op.prototype.setAssetTdyClosedLngNegOp = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.asset_closed_op} returns this
 */
proto.assets.operations.asset_closed_op.prototype.clearAssetTdyClosedLngNegOp = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_closed_op.prototype.hasAssetTdyClosedLngNegOp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 asset_tdy_closed_shr_neg_op = 4;
 * @return {number}
 */
proto.assets.operations.asset_closed_op.prototype.getAssetTdyClosedShrNegOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.asset_closed_op} returns this
 */
proto.assets.operations.asset_closed_op.prototype.setAssetTdyClosedShrNegOp = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.asset_closed_op} returns this
 */
proto.assets.operations.asset_closed_op.prototype.clearAssetTdyClosedShrNegOp = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_closed_op.prototype.hasAssetTdyClosedShrNegOp = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 asset_ytd_closed_negative_op = 5;
 * @return {number}
 */
proto.assets.operations.asset_closed_op.prototype.getAssetYtdClosedNegativeOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.asset_closed_op} returns this
 */
proto.assets.operations.asset_closed_op.prototype.setAssetYtdClosedNegativeOp = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.asset_closed_op} returns this
 */
proto.assets.operations.asset_closed_op.prototype.clearAssetYtdClosedNegativeOp = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_closed_op.prototype.hasAssetYtdClosedNegativeOp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 asset_ytd_closed_positive_op = 6;
 * @return {number}
 */
proto.assets.operations.asset_closed_op.prototype.getAssetYtdClosedPositiveOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.asset_closed_op} returns this
 */
proto.assets.operations.asset_closed_op.prototype.setAssetYtdClosedPositiveOp = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.asset_closed_op} returns this
 */
proto.assets.operations.asset_closed_op.prototype.clearAssetYtdClosedPositiveOp = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_closed_op.prototype.hasAssetYtdClosedPositiveOp = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint32 asset_tot_closed_negative_op = 7;
 * @return {number}
 */
proto.assets.operations.asset_closed_op.prototype.getAssetTotClosedNegativeOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.asset_closed_op} returns this
 */
proto.assets.operations.asset_closed_op.prototype.setAssetTotClosedNegativeOp = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.asset_closed_op} returns this
 */
proto.assets.operations.asset_closed_op.prototype.clearAssetTotClosedNegativeOp = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_closed_op.prototype.hasAssetTotClosedNegativeOp = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint32 asset_tot_closed_positive_op = 8;
 * @return {number}
 */
proto.assets.operations.asset_closed_op.prototype.getAssetTotClosedPositiveOp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.asset_closed_op} returns this
 */
proto.assets.operations.asset_closed_op.prototype.setAssetTotClosedPositiveOp = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.asset_closed_op} returns this
 */
proto.assets.operations.asset_closed_op.prototype.clearAssetTotClosedPositiveOp = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.asset_closed_op.prototype.hasAssetTotClosedPositiveOp = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.assets.operations.update_payload.repeatedFields_ = [18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.operations.update_payload.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.operations.update_payload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.operations.update_payload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.update_payload.toObject = function(includeInstance, msg) {
  var f, obj = {
    openOperation: (f = msg.getOpenOperation()) && proto.assets.operations.open_operation.toObject(includeInstance, f),
    closedOperation: (f = msg.getClosedOperation()) && proto.assets.operations.closed_operation.toObject(includeInstance, f),
    assetNumTdyOp: (f = msg.getAssetNumTdyOp()) && proto.assets.operations.asset_num_tdy_op.toObject(includeInstance, f),
    assetCartesianOperationsList: jspb.Message.toObjectList(msg.getAssetCartesianOperationsList(),
    proto.assets.operations.asset_cartesian_operations.toObject, includeInstance),
    operationTradRetEnd: (f = msg.getOperationTradRetEnd()) && proto.assets.operations.operation_trad_ret_end.toObject(includeInstance, f),
    positionStatus: (f = msg.getPositionStatus()) && proto.assets.operations.position_status.toObject(includeInstance, f),
    operationAvgPrinc: (f = msg.getOperationAvgPrinc()) && proto.assets.operations.operation_avg_princ.toObject(includeInstance, f),
    fees: (f = msg.getFees()) && proto.assets.operations.fees.toObject(includeInstance, f),
    qtyMultiplierClose: (f = msg.getQtyMultiplierClose()) && proto.assets.operations.qty_multiplier_close.toObject(includeInstance, f),
    assetCumPercRetClose: (f = msg.getAssetCumPercRetClose()) && proto.assets.operations.asset_cum_perc_ret_close.toObject(includeInstance, f),
    assetCumPercRet: (f = msg.getAssetCumPercRet()) && proto.assets.operations.asset_cum_perc_ret.toObject(includeInstance, f),
    assetClosedOp: (f = msg.getAssetClosedOp()) && proto.assets.operations.asset_closed_op.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.operations.update_payload}
 */
proto.assets.operations.update_payload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.operations.update_payload;
  return proto.assets.operations.update_payload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.operations.update_payload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.operations.update_payload}
 */
proto.assets.operations.update_payload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 15:
      var value = new proto.assets.operations.open_operation;
      reader.readMessage(value,proto.assets.operations.open_operation.deserializeBinaryFromReader);
      msg.setOpenOperation(value);
      break;
    case 16:
      var value = new proto.assets.operations.closed_operation;
      reader.readMessage(value,proto.assets.operations.closed_operation.deserializeBinaryFromReader);
      msg.setClosedOperation(value);
      break;
    case 17:
      var value = new proto.assets.operations.asset_num_tdy_op;
      reader.readMessage(value,proto.assets.operations.asset_num_tdy_op.deserializeBinaryFromReader);
      msg.setAssetNumTdyOp(value);
      break;
    case 18:
      var value = new proto.assets.operations.asset_cartesian_operations;
      reader.readMessage(value,proto.assets.operations.asset_cartesian_operations.deserializeBinaryFromReader);
      msg.addAssetCartesianOperations(value);
      break;
    case 19:
      var value = new proto.assets.operations.operation_trad_ret_end;
      reader.readMessage(value,proto.assets.operations.operation_trad_ret_end.deserializeBinaryFromReader);
      msg.setOperationTradRetEnd(value);
      break;
    case 20:
      var value = new proto.assets.operations.position_status;
      reader.readMessage(value,proto.assets.operations.position_status.deserializeBinaryFromReader);
      msg.setPositionStatus(value);
      break;
    case 21:
      var value = new proto.assets.operations.operation_avg_princ;
      reader.readMessage(value,proto.assets.operations.operation_avg_princ.deserializeBinaryFromReader);
      msg.setOperationAvgPrinc(value);
      break;
    case 22:
      var value = new proto.assets.operations.fees;
      reader.readMessage(value,proto.assets.operations.fees.deserializeBinaryFromReader);
      msg.setFees(value);
      break;
    case 23:
      var value = new proto.assets.operations.qty_multiplier_close;
      reader.readMessage(value,proto.assets.operations.qty_multiplier_close.deserializeBinaryFromReader);
      msg.setQtyMultiplierClose(value);
      break;
    case 24:
      var value = new proto.assets.operations.asset_cum_perc_ret_close;
      reader.readMessage(value,proto.assets.operations.asset_cum_perc_ret_close.deserializeBinaryFromReader);
      msg.setAssetCumPercRetClose(value);
      break;
    case 25:
      var value = new proto.assets.operations.asset_cum_perc_ret;
      reader.readMessage(value,proto.assets.operations.asset_cum_perc_ret.deserializeBinaryFromReader);
      msg.setAssetCumPercRet(value);
      break;
    case 26:
      var value = new proto.assets.operations.asset_closed_op;
      reader.readMessage(value,proto.assets.operations.asset_closed_op.deserializeBinaryFromReader);
      msg.setAssetClosedOp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.operations.update_payload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.operations.update_payload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.operations.update_payload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.update_payload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpenOperation();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.assets.operations.open_operation.serializeBinaryToWriter
    );
  }
  f = message.getClosedOperation();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.assets.operations.closed_operation.serializeBinaryToWriter
    );
  }
  f = message.getAssetNumTdyOp();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.assets.operations.asset_num_tdy_op.serializeBinaryToWriter
    );
  }
  f = message.getAssetCartesianOperationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.assets.operations.asset_cartesian_operations.serializeBinaryToWriter
    );
  }
  f = message.getOperationTradRetEnd();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.assets.operations.operation_trad_ret_end.serializeBinaryToWriter
    );
  }
  f = message.getPositionStatus();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.assets.operations.position_status.serializeBinaryToWriter
    );
  }
  f = message.getOperationAvgPrinc();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.assets.operations.operation_avg_princ.serializeBinaryToWriter
    );
  }
  f = message.getFees();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.assets.operations.fees.serializeBinaryToWriter
    );
  }
  f = message.getQtyMultiplierClose();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.assets.operations.qty_multiplier_close.serializeBinaryToWriter
    );
  }
  f = message.getAssetCumPercRetClose();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.assets.operations.asset_cum_perc_ret_close.serializeBinaryToWriter
    );
  }
  f = message.getAssetCumPercRet();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.assets.operations.asset_cum_perc_ret.serializeBinaryToWriter
    );
  }
  f = message.getAssetClosedOp();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.assets.operations.asset_closed_op.serializeBinaryToWriter
    );
  }
};


/**
 * optional open_operation open_operation_ = 15;
 * @return {?proto.assets.operations.open_operation}
 */
proto.assets.operations.update_payload.prototype.getOpenOperation = function() {
  return /** @type{?proto.assets.operations.open_operation} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.open_operation, 15));
};


/**
 * @param {?proto.assets.operations.open_operation|undefined} value
 * @return {!proto.assets.operations.update_payload} returns this
*/
proto.assets.operations.update_payload.prototype.setOpenOperation = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.update_payload} returns this
 */
proto.assets.operations.update_payload.prototype.clearOpenOperation = function() {
  return this.setOpenOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_payload.prototype.hasOpenOperation = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional closed_operation closed_operation_ = 16;
 * @return {?proto.assets.operations.closed_operation}
 */
proto.assets.operations.update_payload.prototype.getClosedOperation = function() {
  return /** @type{?proto.assets.operations.closed_operation} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.closed_operation, 16));
};


/**
 * @param {?proto.assets.operations.closed_operation|undefined} value
 * @return {!proto.assets.operations.update_payload} returns this
*/
proto.assets.operations.update_payload.prototype.setClosedOperation = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.update_payload} returns this
 */
proto.assets.operations.update_payload.prototype.clearClosedOperation = function() {
  return this.setClosedOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_payload.prototype.hasClosedOperation = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional asset_num_tdy_op asset_num_tdy_op_ = 17;
 * @return {?proto.assets.operations.asset_num_tdy_op}
 */
proto.assets.operations.update_payload.prototype.getAssetNumTdyOp = function() {
  return /** @type{?proto.assets.operations.asset_num_tdy_op} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.asset_num_tdy_op, 17));
};


/**
 * @param {?proto.assets.operations.asset_num_tdy_op|undefined} value
 * @return {!proto.assets.operations.update_payload} returns this
*/
proto.assets.operations.update_payload.prototype.setAssetNumTdyOp = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.update_payload} returns this
 */
proto.assets.operations.update_payload.prototype.clearAssetNumTdyOp = function() {
  return this.setAssetNumTdyOp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_payload.prototype.hasAssetNumTdyOp = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * repeated asset_cartesian_operations asset_cartesian_operations_ = 18;
 * @return {!Array<!proto.assets.operations.asset_cartesian_operations>}
 */
proto.assets.operations.update_payload.prototype.getAssetCartesianOperationsList = function() {
  return /** @type{!Array<!proto.assets.operations.asset_cartesian_operations>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.assets.operations.asset_cartesian_operations, 18));
};


/**
 * @param {!Array<!proto.assets.operations.asset_cartesian_operations>} value
 * @return {!proto.assets.operations.update_payload} returns this
*/
proto.assets.operations.update_payload.prototype.setAssetCartesianOperationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.assets.operations.asset_cartesian_operations=} opt_value
 * @param {number=} opt_index
 * @return {!proto.assets.operations.asset_cartesian_operations}
 */
proto.assets.operations.update_payload.prototype.addAssetCartesianOperations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.assets.operations.asset_cartesian_operations, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.assets.operations.update_payload} returns this
 */
proto.assets.operations.update_payload.prototype.clearAssetCartesianOperationsList = function() {
  return this.setAssetCartesianOperationsList([]);
};


/**
 * optional operation_trad_ret_end operation_trad_ret_end_ = 19;
 * @return {?proto.assets.operations.operation_trad_ret_end}
 */
proto.assets.operations.update_payload.prototype.getOperationTradRetEnd = function() {
  return /** @type{?proto.assets.operations.operation_trad_ret_end} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.operation_trad_ret_end, 19));
};


/**
 * @param {?proto.assets.operations.operation_trad_ret_end|undefined} value
 * @return {!proto.assets.operations.update_payload} returns this
*/
proto.assets.operations.update_payload.prototype.setOperationTradRetEnd = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.update_payload} returns this
 */
proto.assets.operations.update_payload.prototype.clearOperationTradRetEnd = function() {
  return this.setOperationTradRetEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_payload.prototype.hasOperationTradRetEnd = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional position_status position_status_ = 20;
 * @return {?proto.assets.operations.position_status}
 */
proto.assets.operations.update_payload.prototype.getPositionStatus = function() {
  return /** @type{?proto.assets.operations.position_status} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.position_status, 20));
};


/**
 * @param {?proto.assets.operations.position_status|undefined} value
 * @return {!proto.assets.operations.update_payload} returns this
*/
proto.assets.operations.update_payload.prototype.setPositionStatus = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.update_payload} returns this
 */
proto.assets.operations.update_payload.prototype.clearPositionStatus = function() {
  return this.setPositionStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_payload.prototype.hasPositionStatus = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional operation_avg_princ operation_avg_princ_ = 21;
 * @return {?proto.assets.operations.operation_avg_princ}
 */
proto.assets.operations.update_payload.prototype.getOperationAvgPrinc = function() {
  return /** @type{?proto.assets.operations.operation_avg_princ} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.operation_avg_princ, 21));
};


/**
 * @param {?proto.assets.operations.operation_avg_princ|undefined} value
 * @return {!proto.assets.operations.update_payload} returns this
*/
proto.assets.operations.update_payload.prototype.setOperationAvgPrinc = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.update_payload} returns this
 */
proto.assets.operations.update_payload.prototype.clearOperationAvgPrinc = function() {
  return this.setOperationAvgPrinc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_payload.prototype.hasOperationAvgPrinc = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional fees fees_ = 22;
 * @return {?proto.assets.operations.fees}
 */
proto.assets.operations.update_payload.prototype.getFees = function() {
  return /** @type{?proto.assets.operations.fees} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.fees, 22));
};


/**
 * @param {?proto.assets.operations.fees|undefined} value
 * @return {!proto.assets.operations.update_payload} returns this
*/
proto.assets.operations.update_payload.prototype.setFees = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.update_payload} returns this
 */
proto.assets.operations.update_payload.prototype.clearFees = function() {
  return this.setFees(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_payload.prototype.hasFees = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional qty_multiplier_close qty_multiplier_close_ = 23;
 * @return {?proto.assets.operations.qty_multiplier_close}
 */
proto.assets.operations.update_payload.prototype.getQtyMultiplierClose = function() {
  return /** @type{?proto.assets.operations.qty_multiplier_close} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.qty_multiplier_close, 23));
};


/**
 * @param {?proto.assets.operations.qty_multiplier_close|undefined} value
 * @return {!proto.assets.operations.update_payload} returns this
*/
proto.assets.operations.update_payload.prototype.setQtyMultiplierClose = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.update_payload} returns this
 */
proto.assets.operations.update_payload.prototype.clearQtyMultiplierClose = function() {
  return this.setQtyMultiplierClose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_payload.prototype.hasQtyMultiplierClose = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional asset_cum_perc_ret_close asset_cum_perc_ret_close_ = 24;
 * @return {?proto.assets.operations.asset_cum_perc_ret_close}
 */
proto.assets.operations.update_payload.prototype.getAssetCumPercRetClose = function() {
  return /** @type{?proto.assets.operations.asset_cum_perc_ret_close} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.asset_cum_perc_ret_close, 24));
};


/**
 * @param {?proto.assets.operations.asset_cum_perc_ret_close|undefined} value
 * @return {!proto.assets.operations.update_payload} returns this
*/
proto.assets.operations.update_payload.prototype.setAssetCumPercRetClose = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.update_payload} returns this
 */
proto.assets.operations.update_payload.prototype.clearAssetCumPercRetClose = function() {
  return this.setAssetCumPercRetClose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_payload.prototype.hasAssetCumPercRetClose = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional asset_cum_perc_ret asset_cum_perc_ret_ = 25;
 * @return {?proto.assets.operations.asset_cum_perc_ret}
 */
proto.assets.operations.update_payload.prototype.getAssetCumPercRet = function() {
  return /** @type{?proto.assets.operations.asset_cum_perc_ret} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.asset_cum_perc_ret, 25));
};


/**
 * @param {?proto.assets.operations.asset_cum_perc_ret|undefined} value
 * @return {!proto.assets.operations.update_payload} returns this
*/
proto.assets.operations.update_payload.prototype.setAssetCumPercRet = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.update_payload} returns this
 */
proto.assets.operations.update_payload.prototype.clearAssetCumPercRet = function() {
  return this.setAssetCumPercRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_payload.prototype.hasAssetCumPercRet = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional asset_closed_op asset_closed_op_ = 26;
 * @return {?proto.assets.operations.asset_closed_op}
 */
proto.assets.operations.update_payload.prototype.getAssetClosedOp = function() {
  return /** @type{?proto.assets.operations.asset_closed_op} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.asset_closed_op, 26));
};


/**
 * @param {?proto.assets.operations.asset_closed_op|undefined} value
 * @return {!proto.assets.operations.update_payload} returns this
*/
proto.assets.operations.update_payload.prototype.setAssetClosedOp = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.update_payload} returns this
 */
proto.assets.operations.update_payload.prototype.clearAssetClosedOp = function() {
  return this.setAssetClosedOp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_payload.prototype.hasAssetClosedOp = function() {
  return jspb.Message.getField(this, 26) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.operations.update_snapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.operations.update_snapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.operations.update_snapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.update_snapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    updateType: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    key: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    eventTs: (f = msg.getEventTs()) && proto.assets.operations.tstamp.toObject(includeInstance, f),
    sigmas: (f = msg.getSigmas()) && proto.assets.operations.update_payload.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.operations.update_snapshot}
 */
proto.assets.operations.update_snapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.operations.update_snapshot;
  return proto.assets.operations.update_snapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.operations.update_snapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.operations.update_snapshot}
 */
proto.assets.operations.update_snapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {!proto.assets.operations.update_type} */ (reader.readEnum());
      msg.setUpdateType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 5:
      var value = new proto.assets.operations.tstamp;
      reader.readMessage(value,proto.assets.operations.tstamp.deserializeBinaryFromReader);
      msg.setEventTs(value);
      break;
    case 6:
      var value = new proto.assets.operations.update_payload;
      reader.readMessage(value,proto.assets.operations.update_payload.deserializeBinaryFromReader);
      msg.setSigmas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.operations.update_snapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.operations.update_snapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.operations.update_snapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.update_snapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!proto.assets.operations.update_type} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEventTs();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.operations.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getSigmas();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.operations.update_payload.serializeBinaryToWriter
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.assets.operations.update_snapshot.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.update_snapshot} returns this
 */
proto.assets.operations.update_snapshot.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.update_snapshot} returns this
 */
proto.assets.operations.update_snapshot.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_snapshot.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required update_type update_type = 2;
 * @return {!proto.assets.operations.update_type}
 */
proto.assets.operations.update_snapshot.prototype.getUpdateType = function() {
  return /** @type {!proto.assets.operations.update_type} */ (jspb.Message.getFieldWithDefault(this, 2, 1));
};


/**
 * @param {!proto.assets.operations.update_type} value
 * @return {!proto.assets.operations.update_snapshot} returns this
 */
proto.assets.operations.update_snapshot.prototype.setUpdateType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.update_snapshot} returns this
 */
proto.assets.operations.update_snapshot.prototype.clearUpdateType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_snapshot.prototype.hasUpdateType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required string service = 3;
 * @return {string}
 */
proto.assets.operations.update_snapshot.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.operations.update_snapshot} returns this
 */
proto.assets.operations.update_snapshot.prototype.setService = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.update_snapshot} returns this
 */
proto.assets.operations.update_snapshot.prototype.clearService = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_snapshot.prototype.hasService = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required string key = 4;
 * @return {string}
 */
proto.assets.operations.update_snapshot.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.operations.update_snapshot} returns this
 */
proto.assets.operations.update_snapshot.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.update_snapshot} returns this
 */
proto.assets.operations.update_snapshot.prototype.clearKey = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_snapshot.prototype.hasKey = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required tstamp event_ts = 5;
 * @return {!proto.assets.operations.tstamp}
 */
proto.assets.operations.update_snapshot.prototype.getEventTs = function() {
  return /** @type{!proto.assets.operations.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.tstamp, 5, 1));
};


/**
 * @param {!proto.assets.operations.tstamp} value
 * @return {!proto.assets.operations.update_snapshot} returns this
*/
proto.assets.operations.update_snapshot.prototype.setEventTs = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.update_snapshot} returns this
 */
proto.assets.operations.update_snapshot.prototype.clearEventTs = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_snapshot.prototype.hasEventTs = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional update_payload sigmas = 6;
 * @return {?proto.assets.operations.update_payload}
 */
proto.assets.operations.update_snapshot.prototype.getSigmas = function() {
  return /** @type{?proto.assets.operations.update_payload} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.update_payload, 6));
};


/**
 * @param {?proto.assets.operations.update_payload|undefined} value
 * @return {!proto.assets.operations.update_snapshot} returns this
*/
proto.assets.operations.update_snapshot.prototype.setSigmas = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.update_snapshot} returns this
 */
proto.assets.operations.update_snapshot.prototype.clearSigmas = function() {
  return this.setSigmas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_snapshot.prototype.hasSigmas = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.operations.end_snapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.operations.end_snapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.operations.end_snapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.end_snapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.operations.end_snapshot}
 */
proto.assets.operations.end_snapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.operations.end_snapshot;
  return proto.assets.operations.end_snapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.operations.end_snapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.operations.end_snapshot}
 */
proto.assets.operations.end_snapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.operations.end_snapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.operations.end_snapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.operations.end_snapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.end_snapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.assets.operations.end_snapshot.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.end_snapshot} returns this
 */
proto.assets.operations.end_snapshot.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.end_snapshot} returns this
 */
proto.assets.operations.end_snapshot.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.end_snapshot.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required string service = 2;
 * @return {string}
 */
proto.assets.operations.end_snapshot.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.operations.end_snapshot} returns this
 */
proto.assets.operations.end_snapshot.prototype.setService = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.end_snapshot} returns this
 */
proto.assets.operations.end_snapshot.prototype.clearService = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.end_snapshot.prototype.hasService = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.operations.update_ticker.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.operations.update_ticker.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.operations.update_ticker} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.update_ticker.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    updateType: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    sequence: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    key: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    eventTs: (f = msg.getEventTs()) && proto.assets.operations.tstamp.toObject(includeInstance, f),
    sigmas: (f = msg.getSigmas()) && proto.assets.operations.update_payload.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.operations.update_ticker}
 */
proto.assets.operations.update_ticker.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.operations.update_ticker;
  return proto.assets.operations.update_ticker.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.operations.update_ticker} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.operations.update_ticker}
 */
proto.assets.operations.update_ticker.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {!proto.assets.operations.update_type} */ (reader.readEnum());
      msg.setUpdateType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setSequence(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 6:
      var value = new proto.assets.operations.tstamp;
      reader.readMessage(value,proto.assets.operations.tstamp.deserializeBinaryFromReader);
      msg.setEventTs(value);
      break;
    case 7:
      var value = new proto.assets.operations.update_payload;
      reader.readMessage(value,proto.assets.operations.update_payload.deserializeBinaryFromReader);
      msg.setSigmas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.operations.update_ticker.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.operations.update_ticker.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.operations.update_ticker} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.update_ticker.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!proto.assets.operations.update_type} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeSint64(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEventTs();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.operations.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getSigmas();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.assets.operations.update_payload.serializeBinaryToWriter
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.assets.operations.update_ticker.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.update_ticker} returns this
 */
proto.assets.operations.update_ticker.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.update_ticker} returns this
 */
proto.assets.operations.update_ticker.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_ticker.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required update_type update_type = 2;
 * @return {!proto.assets.operations.update_type}
 */
proto.assets.operations.update_ticker.prototype.getUpdateType = function() {
  return /** @type {!proto.assets.operations.update_type} */ (jspb.Message.getFieldWithDefault(this, 2, 1));
};


/**
 * @param {!proto.assets.operations.update_type} value
 * @return {!proto.assets.operations.update_ticker} returns this
 */
proto.assets.operations.update_ticker.prototype.setUpdateType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.update_ticker} returns this
 */
proto.assets.operations.update_ticker.prototype.clearUpdateType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_ticker.prototype.hasUpdateType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required string service = 3;
 * @return {string}
 */
proto.assets.operations.update_ticker.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.operations.update_ticker} returns this
 */
proto.assets.operations.update_ticker.prototype.setService = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.update_ticker} returns this
 */
proto.assets.operations.update_ticker.prototype.clearService = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_ticker.prototype.hasService = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required sint64 sequence = 4;
 * @return {number}
 */
proto.assets.operations.update_ticker.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.update_ticker} returns this
 */
proto.assets.operations.update_ticker.prototype.setSequence = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.update_ticker} returns this
 */
proto.assets.operations.update_ticker.prototype.clearSequence = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_ticker.prototype.hasSequence = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required string key = 5;
 * @return {string}
 */
proto.assets.operations.update_ticker.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.operations.update_ticker} returns this
 */
proto.assets.operations.update_ticker.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.update_ticker} returns this
 */
proto.assets.operations.update_ticker.prototype.clearKey = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_ticker.prototype.hasKey = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * required tstamp event_ts = 6;
 * @return {!proto.assets.operations.tstamp}
 */
proto.assets.operations.update_ticker.prototype.getEventTs = function() {
  return /** @type{!proto.assets.operations.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.tstamp, 6, 1));
};


/**
 * @param {!proto.assets.operations.tstamp} value
 * @return {!proto.assets.operations.update_ticker} returns this
*/
proto.assets.operations.update_ticker.prototype.setEventTs = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.update_ticker} returns this
 */
proto.assets.operations.update_ticker.prototype.clearEventTs = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_ticker.prototype.hasEventTs = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional update_payload sigmas = 7;
 * @return {?proto.assets.operations.update_payload}
 */
proto.assets.operations.update_ticker.prototype.getSigmas = function() {
  return /** @type{?proto.assets.operations.update_payload} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.update_payload, 7));
};


/**
 * @param {?proto.assets.operations.update_payload|undefined} value
 * @return {!proto.assets.operations.update_ticker} returns this
*/
proto.assets.operations.update_ticker.prototype.setSigmas = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.update_ticker} returns this
 */
proto.assets.operations.update_ticker.prototype.clearSigmas = function() {
  return this.setSigmas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.update_ticker.prototype.hasSigmas = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.operations.alive.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.operations.alive.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.operations.alive} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.alive.toObject = function(includeInstance, msg) {
  var f, obj = {
    aliveId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.operations.alive}
 */
proto.assets.operations.alive.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.operations.alive;
  return proto.assets.operations.alive.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.operations.alive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.operations.alive}
 */
proto.assets.operations.alive.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setAliveId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.operations.alive.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.operations.alive.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.operations.alive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.alive.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
};


/**
 * required sint64 alive_id = 1;
 * @return {number}
 */
proto.assets.operations.alive.prototype.getAliveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.operations.alive} returns this
 */
proto.assets.operations.alive.prototype.setAliveId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.operations.alive} returns this
 */
proto.assets.operations.alive.prototype.clearAliveId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.alive.prototype.hasAliveId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.assets.operations.feed_event.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.assets.operations.feed_event.EventTypeCase = {
  EVENT_TYPE_NOT_SET: 0,
  SNAPSHOT: 1,
  TICKER: 2,
  END_SNAPSHOT: 3,
  ALIVE: 4
};

/**
 * @return {proto.assets.operations.feed_event.EventTypeCase}
 */
proto.assets.operations.feed_event.prototype.getEventTypeCase = function() {
  return /** @type {proto.assets.operations.feed_event.EventTypeCase} */(jspb.Message.computeOneofCase(this, proto.assets.operations.feed_event.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.operations.feed_event.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.operations.feed_event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.operations.feed_event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.feed_event.toObject = function(includeInstance, msg) {
  var f, obj = {
    snapshot: (f = msg.getSnapshot()) && proto.assets.operations.update_snapshot.toObject(includeInstance, f),
    ticker: (f = msg.getTicker()) && proto.assets.operations.update_ticker.toObject(includeInstance, f),
    endSnapshot: (f = msg.getEndSnapshot()) && proto.assets.operations.end_snapshot.toObject(includeInstance, f),
    alive: (f = msg.getAlive()) && proto.assets.operations.alive.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.operations.feed_event}
 */
proto.assets.operations.feed_event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.operations.feed_event;
  return proto.assets.operations.feed_event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.operations.feed_event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.operations.feed_event}
 */
proto.assets.operations.feed_event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.operations.update_snapshot;
      reader.readMessage(value,proto.assets.operations.update_snapshot.deserializeBinaryFromReader);
      msg.setSnapshot(value);
      break;
    case 2:
      var value = new proto.assets.operations.update_ticker;
      reader.readMessage(value,proto.assets.operations.update_ticker.deserializeBinaryFromReader);
      msg.setTicker(value);
      break;
    case 3:
      var value = new proto.assets.operations.end_snapshot;
      reader.readMessage(value,proto.assets.operations.end_snapshot.deserializeBinaryFromReader);
      msg.setEndSnapshot(value);
      break;
    case 4:
      var value = new proto.assets.operations.alive;
      reader.readMessage(value,proto.assets.operations.alive.deserializeBinaryFromReader);
      msg.setAlive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.operations.feed_event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.operations.feed_event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.operations.feed_event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.operations.feed_event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSnapshot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.operations.update_snapshot.serializeBinaryToWriter
    );
  }
  f = message.getTicker();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.operations.update_ticker.serializeBinaryToWriter
    );
  }
  f = message.getEndSnapshot();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.operations.end_snapshot.serializeBinaryToWriter
    );
  }
  f = message.getAlive();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.operations.alive.serializeBinaryToWriter
    );
  }
};


/**
 * optional update_snapshot snapshot = 1;
 * @return {?proto.assets.operations.update_snapshot}
 */
proto.assets.operations.feed_event.prototype.getSnapshot = function() {
  return /** @type{?proto.assets.operations.update_snapshot} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.update_snapshot, 1));
};


/**
 * @param {?proto.assets.operations.update_snapshot|undefined} value
 * @return {!proto.assets.operations.feed_event} returns this
*/
proto.assets.operations.feed_event.prototype.setSnapshot = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.assets.operations.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.feed_event} returns this
 */
proto.assets.operations.feed_event.prototype.clearSnapshot = function() {
  return this.setSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.feed_event.prototype.hasSnapshot = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional update_ticker ticker = 2;
 * @return {?proto.assets.operations.update_ticker}
 */
proto.assets.operations.feed_event.prototype.getTicker = function() {
  return /** @type{?proto.assets.operations.update_ticker} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.update_ticker, 2));
};


/**
 * @param {?proto.assets.operations.update_ticker|undefined} value
 * @return {!proto.assets.operations.feed_event} returns this
*/
proto.assets.operations.feed_event.prototype.setTicker = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.assets.operations.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.feed_event} returns this
 */
proto.assets.operations.feed_event.prototype.clearTicker = function() {
  return this.setTicker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.feed_event.prototype.hasTicker = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional end_snapshot end_snapshot = 3;
 * @return {?proto.assets.operations.end_snapshot}
 */
proto.assets.operations.feed_event.prototype.getEndSnapshot = function() {
  return /** @type{?proto.assets.operations.end_snapshot} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.end_snapshot, 3));
};


/**
 * @param {?proto.assets.operations.end_snapshot|undefined} value
 * @return {!proto.assets.operations.feed_event} returns this
*/
proto.assets.operations.feed_event.prototype.setEndSnapshot = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.assets.operations.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.feed_event} returns this
 */
proto.assets.operations.feed_event.prototype.clearEndSnapshot = function() {
  return this.setEndSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.feed_event.prototype.hasEndSnapshot = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional alive alive = 4;
 * @return {?proto.assets.operations.alive}
 */
proto.assets.operations.feed_event.prototype.getAlive = function() {
  return /** @type{?proto.assets.operations.alive} */ (
    jspb.Message.getWrapperField(this, proto.assets.operations.alive, 4));
};


/**
 * @param {?proto.assets.operations.alive|undefined} value
 * @return {!proto.assets.operations.feed_event} returns this
*/
proto.assets.operations.feed_event.prototype.setAlive = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.assets.operations.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.operations.feed_event} returns this
 */
proto.assets.operations.feed_event.prototype.clearAlive = function() {
  return this.setAlive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.operations.feed_event.prototype.hasAlive = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * @enum {number}
 */
proto.assets.operations.update_type = {
  SNAPSHOT: 1,
  VARIATIONS: 2
};

/**
 * @enum {number}
 */
proto.assets.operations.svc_status = {
  ONLINE: 0,
  OFFLINE: 1,
  BROKEN: 2
};

goog.object.extend(exports, proto.assets.operations);
