import React from 'react'
import { getStatus } from 'helpers/sessionStatusHelper'
import { SessionStatus, SessionType } from 'types/enums'
import * as Store from 'types/store'
import { Popover, Typography } from 'antd'
import moment from 'moment'

import colors from 'config/colors'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { FULL_DATE_FORMAT } from 'core/formats'

interface Props {
  errors: Store.SessionError[]
  session: Store.Session
}

const SessionStatusRenderer: React.FC<Props> = ({ errors, session }) => {
  if (!session) {
    return null
  }

  const { type, status } = session

  const isSessionVirtualOrBacktest = type === SessionType.Virtual || type === SessionType.BackTest

  const showWarningIcon = status !== SessionStatus.Broken && isSessionVirtualOrBacktest

  if (errors) {
    return (
      <Popover
        content={errors.map((error) => {
          return (
            <div key={error.time.toString()}>
              <Typography.Paragraph style={{ textAlign: 'end' }}>
                {moment(error.time).format(FULL_DATE_FORMAT)}
              </Typography.Paragraph>

              <Typography.Title level={4}>{error.errorDescription}</Typography.Title>
            </div>
          )
        })}
        trigger="hover"
      >
        <div className="broken-info-wrapper">
          {showWarningIcon && (
            <ExclamationCircleOutlined style={{ color: colors.red }} className="broken-info mr-5" />
          )}
          {getStatus(status)}
        </div>
      </Popover>
    )
  }

  return getStatus(status)
}

export default SessionStatusRenderer
