import * as d3 from 'd3'
import Debug from 'debug'
import { formatGliphNumber } from 'core/formats'

const debug = new Debug('Frontend.chart')
const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, 10))

// Default configuration properties.
// mode: 0=random colours, 1=green/red
const RANDOM_COLORS = 0
const GREEN_RED_COLORS = 1
const POSITION_MONITORING = 'position-monitoring'
const defaults = {
  width: 300,
  height: 300,
}

const SunburstChart = (e, o) => {
  //the svg selection we are working with
  const svg = d3.select(e)
  //options
  const opt = { ...defaults, ...o }
  const { width, height, tabType } = opt
  // const radius = (width) => width / 6;
  const radius = width / 6
  let filter = null
  const mode = tabType === POSITION_MONITORING ? 0 : 1

  //references
  let root

  const g = svg.append('g').attr('transform', `translate(${width / 2},${width / 2})`)
  const path = g.append('g')
  const parent = g.append('circle')

  const calculateValue = (d) => {
    if (d.children) {
      return d.children.reduce((sum, item) => sum + item.data.value || 0, 0)
    }
    return d.data.value
  }

  const getRedGreenColor = (d) => {
    const RED = 0
    const GREEN = 121

    const { colorPercent } = d.data
    const value = calculateValue(d)
    const color = value > 0 ? GREEN : RED
    const colorSaturation = ((100 - colorPercent) / 100) * 30 + 35 // the value will be between 35 to 65
    return `hsl(${color},100%,${colorSaturation}%)`
  }

  const getRandomColor = (d) => {
    // const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, 10))
    return color(d.data.name)
  }

  const getColor = (d) => {
    switch (mode) {
      case GREEN_RED_COLORS:
        return getRedGreenColor(d)
      case RANDOM_COLORS:
        return getRandomColor(d)
      default:
        return getRandomColor(d)
    }
  }

  const renderTitle = (d) => {
    const value = formatGliphNumber(calculateValue(d))
    if (d.children) {
      debug('Children are here', d)
    }
    if (tabType === POSITION_MONITORING) {
      return `${d.data.name} ${value} (${d.data.percent})`
    }

    return `${d.data.name} ${value}`
  }

  const arc = d3
    .arc()
    .startAngle((d) => d.x0)
    .endAngle((d) => d.x1)
    .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(radius * 1.5)
    .innerRadius((d) => d.y0 * radius)
    .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1))

  const init = () => null

  svg.attr('viewBox', [0, 0, width, height]).style('display', 'block').style('cursor', 'pointer')

  function arcVisible(d) {
    return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0
  }

  const partition = (data) => {
    const root = d3
      .hierarchy(data)
      .sum((d) => Math.abs(d.value))
      .sort((a, b) => Math.abs(b.value) - Math.abs(a.value))
    return d3.partition().size([2 * Math.PI, root.height + 1])(root)
  }

  function clicked(event, p) {
    filter = p.parent && p
    parent.datum(p.parent || root)

    root.each((d) => {
      d.target = {
        x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
        x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
        y0: Math.max(0, d.y0 - p.depth),
        y1: Math.max(0, d.y1 - p.depth),
      }
    })

    const t = g.transition().duration(750)

    // Transition the data on all arcs, even the ones that aren’t visible,
    // so that if this transition is interrupted, entering arcs will start
    // the next transition from the desired position.
    path
      .selectAll('path')
      .transition(t)
      .tween('data', (d) => {
        const i = d3.interpolate(d.current, d.target)
        return (t) => (d.current = i(t))
      })
      .filter(function (d) {
        return +this.getAttribute('fill-opacity') || arcVisible(d.target)
      })
      .attr('fill-opacity', (d) => (arcVisible(d.target) ? 1 : 0))
      .attrTween('d', (d) => () => arc(d.current))
  }

  //Public Functions
  const enter = (data) => {
    if (filter) {
      return
    }
    root = partition(data)
    root.each((d) => (d.current = d))
    const descendants = root.descendants().slice(1)

    path.selectAll('path').remove()

    const paths = path
      .selectAll('path')
      .data(descendants)
      .join('path')
      // .attr('fill', (d) => {
      //   return color(d.data.name)
      // })
      .attr('fill', getColor)
      .attr('fill-opacity', (d) => (arcVisible(d.current) ? 1 : 0))
      .attr('d', (d) => arc(d.current))

    paths.append('title').text(renderTitle)

    paths
      .filter((d) => {
        return d.children
      })
      .style('cursor', 'pointer')
      .on('click', clicked)

    parent
      .datum(root)
      .attr('r', radius)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('click', clicked)
  }

  const update = () => null

  const exit = () => null

  //Initialize the component
  init()

  function selectDimension() {
    // TODO: it's using a random child now, to fix when we have the correct data
    filter = null
  }

  return {
    enter: enter,
    update: update,
    exit: exit,
    selectDimension: selectDimension,
  }
}

export default SunburstChart
