import * as Api from 'types/api'
import { useForm } from 'antd/lib/form/Form'
import StrategyForm from 'components/StrategyViewer/forms/StrategyForm'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createStrategyAction, setLoadingState } from 'store/resources/strategies/actions'
import { LoadingState } from 'types/enums'
import { resetUserAction } from 'store/pages/strategy/actions'
import { selectStrategiesState } from 'store/resources/selectors'

const CreateStrategy: React.FC = () => {
  const dispatch = useDispatch()
  const loadingState = useSelector(selectStrategiesState)
  const [form] = useForm()

  const onFinish = (data: Api.StrategyRest) => {
    const postData: Api.StrategyRest = {
      ...data,
      baseCurrency: 'EUR',
    }

    dispatch(createStrategyAction(postData))
  }

  const onCancel = () => {
    dispatch(resetUserAction())
    dispatch(setLoadingState(LoadingState.Unset))
  }

  return (
    <StrategyForm
      form={form}
      loading={loadingState === LoadingState.Creating}
      onFinish={onFinish}
      onCancel={onCancel}
    />
  )
}

export default CreateStrategy
