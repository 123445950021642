import React from 'react'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'
import { groupBy, renderAvailableData } from 'core/helpers'
import { formatGliphNumber, formatNumber } from 'core/formats'
import { translateInstruments } from 'helpers/common'
import { Instruments } from 'types/enums'

interface MaxValue {
  value: number
  key: string
}
interface Props {
  data: Store.PositionMonitoring[]
}

const RightTable: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation()

  const sum = data.reduce(
    (initial, data) => {
      initial.valueShort += data.valueShort || 0
      initial.valueLong += data.valueLong || 0
      return initial
    },
    {
      valueShort: 0,
      valueLong: 0,
      net: 0,
    },
  )

  const getMaxValue = (
    data: Json<Store.PositionMonitoring[]>,
    value: string,
    render?: (value: string, number: number, percentage: number) => string,
  ): string => {
    const reduced: MaxValue[] = Object.keys(data).map((item) => {
      return {
        key: item,
        value: data[item].reduce((sum, item) => sum + (item[value] || 0), 0),
      }
    })

    return reduced
      .reduce((array, item) => {
        if (array.length === 0) {
          return [item]
        }

        if (array[0].value < item.value) {
          return [item]
        }

        if (array[0].value === item.value) {
          array.push(item)
        }

        return array
      }, [] as MaxValue[])
      .map((maxValue) => {
        const number = renderAvailableData(formatGliphNumber(maxValue.value))
        const percentage = renderAvailableData(formatNumber((maxValue?.value / sum[value]) * 100))
        if (render) {
          return render(maxValue.key, number, percentage)
        }
        return `${maxValue.key} ${number} ${percentage}%`
      })
      .join(',')
  }

  const byMarket = groupBy('market')(data)
  const byCurrency = groupBy('currency')(data)
  const byAssetClass = groupBy('assetClass')(data)

  const renderInstruments = (value: string, number: number, percentage: number) =>
    `${translateInstruments(value as Instruments)} ${number} ${percentage}%`

  const maxMarketLong = getMaxValue(byMarket, 'valueLong')
  const maxMarketShort = getMaxValue(byMarket, 'valueShort')
  const maxCurrencyLong = getMaxValue(byCurrency, 'valueLong')
  const maxCurrencyShort = getMaxValue(byCurrency, 'valueShort')
  const maxAssetClassLong = getMaxValue(byAssetClass, 'valueLong', renderInstruments)
  const maxAssetClassShort = getMaxValue(byAssetClass, 'valueShort', renderInstruments)

  // TABLE THREE
  const columns = [
    {
      title: '',
      dataIndex: 'description',
      key: 'description',
      width: 120,
    },
    {
      title: t('table.column.maxLong'),
      dataIndex: 'maxLong',
      key: 'maxLong',
      width: 144,
    },
    {
      title: t('table.column.maxShort'),
      dataIndex: 'maxShort',
      key: 'maxShort',
      width: 144,
    },
  ]

  const dataSource = [
    {
      key: '1',
      description: t('table.column.market').toUpperCase(),
      maxLong: maxMarketLong,
      maxShort: maxMarketShort,
    },
    {
      key: '2',
      description: t('table.column.currency').toUpperCase(),
      maxLong: maxCurrencyLong,
      maxShort: maxCurrencyShort,
    },
    {
      key: '3',
      description: t('table.column.assetClass').toUpperCase(),
      maxLong: maxAssetClassLong,
      maxShort: maxAssetClassShort,
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      className={'right-table nonDraggable'}
    />
  )
}

export default RightTable
