import { Button, Row, Space, Typography } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import { LoadingState } from 'types/enums'
import { useTranslation } from 'react-i18next'
import AppContentCard from 'components/AppContentCard'

interface Props {
  itemsName: string
  onClose: () => void
  onOk: () => void
  loadingState: LoadingState
  visible: boolean
}

export const DeleteItem: React.FC<Props> = ({ itemsName, onClose, onOk, loadingState, visible }) => {
  const { t } = useTranslation()

  const deleting = loadingState === LoadingState.Deleting

  const onCancel = () => {
    onClose()
  }

  return (
    <Modal centered open={visible} title={null} footer={null} closable={false}>
      <AppContentCard>
        <Typography.Text>{t('notification.message.deleteConfirm', { p0: itemsName })}</Typography.Text>
        <Row justify="end" className="mt-30">
          <Space>
            <Button type="primary" onClick={onCancel} disabled={deleting}>
              {t('general.cancel')}
            </Button>
            <Button type="dashed" onClick={onOk} loading={deleting} disabled={deleting}>
              {t('general.ok')}
            </Button>
          </Space>
        </Row>
      </AppContentCard>
    </Modal>
  )
}
