import React from 'react'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'
import { renderAvailableData } from 'core/helpers'
import { formatGliphNumber, formatNumber } from 'core/formats'

interface Props {
  data: Store.PositionMonitoring[]
}

const LeftTable: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation()

  const sum = data.reduce(
    (initial, current) => {
      initial.currency = current.currency || t('general.noData')
      initial.counterValue += current?.value || 0
      initial.short += current.valueShort || 0
      initial.long += current.valueLong || 0
      initial.net += current.netHedging || 0
      return initial
    },
    {
      currency: t('general.noData'),
      counterValue: 0,
      short: 0,
      long: 0,
      net: 0,
    },
  )

  const columns = [
    {
      title: '',
      dataIndex: 'sign',
      key: 'sign',
      width: 120,
    },
    {
      title: t('table.column.contervalueAbbr'),
      dataIndex: 'counterValue',
      key: 'counterValue',
      width: 144,
    },
    {
      title: t('table.column.percentage'),
      dataIndex: 'percentage',
      key: 'percentage',
      width: 144,
    },
  ]

  const dataSource = [
    {
      key: '1',
      sign: t('table.column.long').toUpperCase(),
      counterValue: renderAvailableData(formatGliphNumber(sum?.long)),
      percentage: `${renderAvailableData(formatNumber((sum?.long / sum?.counterValue) * 100))}%`,
    },
    {
      key: '2',
      sign: t('table.column.short').toUpperCase(),
      counterValue: renderAvailableData(formatGliphNumber(sum?.short)),
      percentage: `${renderAvailableData(formatNumber((sum?.short / sum?.counterValue) * 100))}%`,
    },
    {
      key: '3',
      sign: t('table.column.netHedging').toUpperCase(),
      counterValue: renderAvailableData(formatGliphNumber(sum?.net)),
      percentage: `${renderAvailableData(formatNumber(Math.abs(sum?.net / sum?.counterValue) * 100))}%`,
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      className={'left-table nonDraggable'}
    />
  )
}

export default LeftTable
