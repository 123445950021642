import React from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { themes } from './themes'
import { useTheme } from 'providers/ThemeProvider'

interface Props {
  clipped?: boolean
  full?: boolean
  fullHeight?: boolean
  height?: number
  className?: string
}

const AppContentCard: React.FC<Props> = ({
  className,
  children,
  clipped = false,
  full = false,
  fullHeight = false,
  height = 0,
}) => {
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)

  const contentCardClass = classNames({
    'app-content-card': true,
    'card-clipped': clipped,
    'card-full': full,
  })

  const cardClass = classNames(styles['app-content-card'], themeStyles['theme'], className)

  const getHeight = () => {
    if (height !== 0) {
      return height
    }

    return fullHeight ? '100%' : 'auto'
  }

  return (
    <section className={cardClass} style={{ height: getHeight() }}>
      <section data-augmented-ui className={contentCardClass}>
        {children}
      </section>
    </section>
  )
}

export default AppContentCard
