import { AnyAction } from 'redux'
import { LoadingState } from 'types/enums'
import * as Store from 'types/store'
import * as K from './constants'

export function createLayout(layout: Store.Layout): AnyAction {
  return {
    type: K.CREATE_LAYOUT,
    payload: layout,
  }
}

export function updateLayout(layout: Store.Layout): AnyAction {
  return {
    type: K.UPDATE_LAYOUT,
    payload: layout,
  }
}

export function deleteLayout(id: number): AnyAction {
  return {
    type: K.DELETE_LAYOUT,
    payload: id,
  }
}

export function fetchLayouts(): AnyAction {
  return {
    type: K.FETCH_LAYOUTS,
    payload: null,
  }
}

export function setLoadingState(state: LoadingState): AnyAction {
  return {
    type: K.SET_STATUS,
    payload: state,
  }
}
