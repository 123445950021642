import React, { useContext, useEffect, useState } from 'react'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
import * as Store from 'types/store'
import { isApiError, isGraphQlError } from 'core'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import PanelContent from 'v2/components/frames/StrategyCandleStick/PanelContent'
import * as Api from 'types/api'
import * as api from 'api/historicalResources'
import {
  mapHistoricalCartesianPosition,
  mapHistoricalCartesianReturn,
  mapHistoricalOperations,
  mapHistoricalStrategyCartesianHeadingData,
} from 'services/store/mapHistoricals'

const getCartesianPanelContentData = async (
  strategyId: number,
  sessionId: number,
): Promise<Partial<Store.StrategySigmas>> => {
  const response = await api.getStrategyPanelContentData(strategyId, sessionId)
  if (isApiError(response) || isGraphQlError(response)) {
    return null
  }

  const json = response as Api.HistoricalPanelContentDataResponse

  const cartesianOperations = json.data.historical_data_stg_cartesian_operations_v
  const cartesianGraphIndicators = json.data.historical_data_strategy_cartesian_graph_indicators_v[0]
  const cartesianReturn = json.data.historical_data_stg_trad_ret_bc_v

  const cartesianOperationsList = cartesianOperations && mapHistoricalOperations(cartesianOperations)
  const position = cartesianGraphIndicators && mapHistoricalCartesianPosition(cartesianGraphIndicators)
  const returnList = cartesianReturn && mapHistoricalCartesianReturn(cartesianReturn)

  const cartesianHeading =
    cartesianGraphIndicators && mapHistoricalStrategyCartesianHeadingData(cartesianGraphIndicators)

  return {
    cartesianOperationsList,
    position,
    returnList,
    cartesianHeading,
  }
}

const PanelContentContainer: React.FC = () => {
  const context = useContext(CandleStickFrameContext)
  const { resource } = context
  const strategy = resource as Store.Strategy
  const session = useSelector(selectOperativeSessionSession)
  const { id: sessionId } = session
  const [data, setData] = useState<Store.StrategySigmas>()

  const getData = async () => {
    const data = await getCartesianPanelContentData(strategy.id, sessionId)
    if (data) {
      setData(data as Store.StrategySigmas)
    }
  }
  useEffect(() => {
    getData()
  }, [resource])

  return <PanelContent sigmas={data} />
}

export default PanelContentContainer
