import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker, Form, InputNumber, Typography, Select, Switch, Row, Col } from 'antd'
import * as Store from 'types/store'
import { SessionStatus } from 'types/enums'
import moment from 'moment'
import * as Forms from 'types/form'
import { Control, Controller } from 'react-hook-form'
import FormField from 'components/FormField'

interface Props {
  session: Store.Session
  control: Control<Forms.BacktestPlayer>
  loading: boolean
}

const BacktestSessionParameters: React.FC<Props> = ({ session, control, loading }) => {
  const [tickByTick, setTickByTick] = useState<boolean>(false)

  const { t } = useTranslation()
  const { status } = session

  const disabled = status !== SessionStatus.Off && status !== SessionStatus.Created
  const lastAvailableDay = moment().startOf('day').subtract(1, 'day')
  const getValueForDateValue = (value: Date[]): [moment.Moment, moment.Moment] =>
    value[0] ? [moment(value[0]), moment(value[1])] : null


  const unitOptions = [
    {
      value: 'm',
      label: 'minutes',
    },
    {
      value: 's',
      label: 'seconds',
    },
    {
      value: 'ms',
      // disabled: true,
      label: 'milliseconds',
    },
  ]

  return (
    <div className="parameters-container">
      <Typography.Title level={2} className="mt-0">
        {t('general.parameters')}
      </Typography.Title>
      <Form>
        <div className="view-statistics">
          <div className="fieldset">
            <Typography.Text className="statistics-label">{t('operativeSession.strategies')}</Typography.Text>
            <Typography.Text className="statistics-input primary-color">{session.name}</Typography.Text>
          </div>

          <div className="fieldset">
            <Typography.Text className="statistics-label">{t('chart.timeframe')}</Typography.Text>
            <div>
              <Controller
                control={control}
                name="date"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormField error={error}>
                    <DatePicker.RangePicker
                      size="small"
                      picker="date"
                      disabledDate={(date) => date.startOf('day').isAfter(lastAvailableDay)}
                      allowEmpty={[true, true]}
                      placeholder={[t('general.from'), t('general.to')]}
                      disabled={disabled || loading}
                      onChange={([from, to]) =>
                        onChange(from && to ? [from.toDate(), to.toDate()] : [null, null])
                      }
                      value={getValueForDateValue(value)}
                    />
                  </FormField>
                )}
              />
              {/* <Form.Item name="date"></Form.Item> */}
            </div>
          </div>

          <Typography.Text className="statistics-label">Data frequency</Typography.Text>
          <Row gutter={24}>
            <Col className="gutter-row" span={4}>
            <Controller
              control={control}
              name="dataFrequencyValue"
              render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                <FormField
                  error={error}
                  // name={name}
                  rules={[{ required: false, min: 1 }]}
                  // normalize={(value: number) => (Number(value) >= 0 ? value : '')}
                >
                  <InputNumber
                    ref={ref}
                    size="small"
                    style={{width: 48}}
                    placeholder={tickByTick ? '' : '1'}
                    disabled={disabled || loading || tickByTick}
                    value={tickByTick ? '' : value}
                    onChange={onChange}
                  />
                </FormField>
              )}
            />
            </Col>

            <Col className="gutter-row" span={10}>
            <Controller
              control={control}
              name="dataFrequencyUnit"
              render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                <FormField
                  error={error}
                  // name={name}
                >
                  <Select
                    ref={ref}
                    size='small'
                    value={tickByTick ? '' : value}
                    placeholder="unit"
                    onChange={onChange}
                    disabled={disabled || loading || tickByTick}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    options={unitOptions}
                  />
                </FormField>
              )}
            />
            </Col>

            <Col className="gutter-row" span={8}>
              <Controller
                control={control}
                name="tickByTick"
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <FormField
                    error={error}
                    name={name}
                    label="Tick By Tick"
                  >
                    <Switch
                      size='small'
                      checked={value}
                      disabled={disabled || loading}
                      onChange={(e) => {
                        setTickByTick(e)
                        onChange(e)
                      }}
                    />
                  </FormField>
                )}
              />
            </Col>
          </Row>

          <div className="fieldset">
            <Typography.Text className="statistics-label">
              {t('operativeSession.executionSpeed')}
            </Typography.Text>
            <div>
              <Controller
                control={control}
                name="speedup"
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <FormField
                    error={error}
                    // name={name}
                    rules={[{ required: true }]}
                    normalize={(value: number) => (Number(value) >= 0 ? value : '')}
                  >
                    <InputNumber
                      size="small"
                      value={value}
                      onChange={onChange}
                      disabled={disabled || loading}
                    />
                  </FormField>
                )}
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default BacktestSessionParameters
