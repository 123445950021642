import React from 'react'
import { Table } from 'antd'
import * as Store from 'types/store'
import { ColumnsType } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import { renderAvailableData } from 'core/helpers'
import tableStyle from './table-contents.module.scss'
import { formatNumber, formatPercent } from 'core/formats'

interface Props {
  data: Store.IndicatorStatistics
  selectedAssets: Store.Asset[]
}

const StatisticsDetail: React.FC<Props> = ({ data, selectedAssets }) => {
  const { t } = useTranslation()

  if (!data) {
    return null
  }

  const columns: ColumnsType = [
    {
      dataIndex: 'title',
      width: 144,
      colSpan: 2,
    },
    {
      title: t('table.column.forwardAnalysis'),
      dataIndex: 'value',
      width: 144,
      colSpan: 0,
      render: (value) => renderAvailableData(formatNumber(value)),
    },
  ]

  const showMaxDrawdown = selectedAssets?.length === 1

  const dataSource = [
    {
      title: t('table.column.totalOperations'),
      value: data.totalOperations,
    },
    {
      title: t('table.column.posetiveOperations'),
      value: data.positiveOperations,
    },
    {
      title: t('table.column.negativeOperations'),
      value: data.negativeOperations,
    },
    {
      title: t('table.column.mean'),
      value: formatPercent(data.mean),
    },
    showMaxDrawdown && {
      title: t('table.column.maxDrowdown'),
      value: formatPercent(data.maxDrawdown),
    },
    {
      title: t('table.column.standartDeviation'),
      value: formatPercent(data.standardDeviation),
    },
    {
      title: t('table.column.sharpeRatio'),
      value: data.sharpeRatio,
    },
    {
      title: 'Tot Trading Ret.',
      value: data.totalTradingRet,
    },
    {
      title: 'Tot Ret.',
      value: data.totalRet,
    },
    {
      title: 'Tot Ret. Fees',
      value: data.totalRetFees,
    },
  ]

  const filteredData = dataSource.filter((item) => item)

  return (
    <div className={tableStyle.container}>
      <Table columns={columns} dataSource={filteredData} pagination={false} />
    </div>
  )
}

export default StatisticsDetail
