import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Typography, Divider } from 'antd'

import AppContentCard from 'components/AppContentCard'
import { Store as AntStore } from 'antd/lib/form/interface'
import { CrudOperation, LoadingState } from 'types/enums'
import { useForm } from 'antd/lib/form/Form'
import StrategyForm from './forms/StrategyForm'
import * as Store from 'types/store'

const { Title, Paragraph } = Typography

interface Props {
  strategy: Store.Strategy
  onFinish: (data: AntStore) => void
  loadingState: LoadingState
  operation: CrudOperation
  onCancel: () => void
}

const ReadStrategy: React.FC<Props> = ({ strategy, onFinish, loadingState, operation, onCancel }) => {
  const { t } = useTranslation()
  const [form] = useForm()
  const isEditing = operation === CrudOperation.Update

  useEffect(() => {
    isEditing && form.setFieldsValue(strategy)
  }, [strategy])

  const beforeOnfinish = (data: AntStore) => {
    onFinish(data)
  }

  return (
    <Row className="full-height">
      {!isEditing ? (
        <Col span={8}>
          <AppContentCard fullHeight>
            <Title className="col-title">{t('general.strategy')}</Title>

            <Row>
              <Col span={9}>
                <Paragraph>{t('general.name')}</Paragraph>
              </Col>
              <Col span={15} className="align-right">
                <Paragraph>{strategy.name} </Paragraph>
              </Col>
            </Row>
            <Divider type="horizontal" />

            <Row>
              <Col span={9}>
                <Paragraph>{t('strategies.target')}</Paragraph>
              </Col>
              <Col span={15} className="align-right">
                <Paragraph>{strategy.target} </Paragraph>
              </Col>
            </Row>
            <Divider type="horizontal" />
          </AppContentCard>
        </Col>
      ) : (
        <StrategyForm
          form={form}
          loading={loadingState === LoadingState.Updating}
          onFinish={beforeOnfinish}
          onCancel={onCancel}
        />
      )}
    </Row>
  )
}

ReadStrategy.displayName = 'ResourceStrategy'

export default ReadStrategy
