import * as Api from 'types/api'
import * as api from 'api/sessions'
import { call, select } from 'redux-saga/effects'
import { jsonToSessionAsset, jsonToSessions } from 'services/store/mapService'
import { ApplicationError, isApiError, isGraphQlError } from 'core'
import { ErrorCode } from 'types/enums'
import { getAssetsFromIds } from 'services/sessionService'
import * as Store from 'types/store'

const selectAssets = (state) => state.root.resources.assets.data

export function* fetchOperativeSession(sessionId: number) {
  const sessionResponse = yield call(api.getSession, sessionId)
  if (isApiError(sessionResponse) || isGraphQlError(sessionResponse)) {
    throw new ApplicationError(ErrorCode.Api, 'fetch getSessionAssets failed')
  }

  const sessionJson = (sessionResponse as Api.SessionsResponse).data.application_operative_session
  const session = jsonToSessions(sessionJson)[0]
  let json: Api.SessionAsset[]

  if (!session.isHistorical) {
    const response = yield call(api.getSessionAssets, sessionId)
    json = (response as Api.SessionAssetsResponse).data.application_asset_model_instance
  } else {
    const response = yield call(api.getHistoricSessionAssets, sessionId)
    json = (response as Api.HistoricalSessionAssetsResponse).data.historical_data_asset_model_instance_v
  }

  const sessionAssets = jsonToSessionAsset(json)
  const allAssets: Array<Store.Asset> = yield select(selectAssets)
  const assets = getAssetsFromIds(sessionAssets, allAssets)

  return {
    session,
    assets,
  }
}
