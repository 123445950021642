import * as K from './constants'
import * as Store from 'types/store'
import { AnyAction } from 'redux'

export function setCurrentNode(node: Store.UserAction): AnyAction {
  return {
    type: K.SET_CURRENT_NODE,
    payload: node,
  }
}

export function fetchSessionAssets(sessionId: number): AnyAction {
  return {
    type: K.FETCH_SESSION_ASSETS,
    payload: sessionId,
  }
}

export function fetchBrokenEntities(sessionId: number): AnyAction {
  return {
    type: K.FETCH_SESSION_BROKEN_ENTITIES,
    payload: sessionId,
  }
}

export function fetchSessionSetupUpdate(sessionId: number): AnyAction {
  return {
    type: K.FETCH_SESSION_SETUP_UPDATE,
    payload: sessionId,
  }
}

export function removeSessionAssets(payload: Store.UpdateSessionAssetsPayload): AnyAction {
  return {
    type: K.UPDATE_REMOVE_ASSETS,
    payload: payload,
  }
}

export function addSessionAssets(payload: Store.UpdateSessionAssetsPayload): AnyAction {
  return {
    type: K.UPDATE_ADD_ASSETS,
    payload: payload,
  }
}
