const homepage = process.env.REACT_APP_HOMEPAGE

const routes: Routes = {
  strategies: {
    key: 'strategies',
    url: `${homepage}/strategies`,
    private: true,
    menu: 'strategies',
  },
  sessions: {
    key: 'sessions',
    url: `${homepage}`,
    private: true,
    menu: 'sessions',
  },
  sessionSetup: {
    key: 'sessionSetup',
    url: `${homepage}/session-setup/:id`,
    private: true,
    menu: 'sessions',
  },
  historicalSetup: {
    key: 'historicalSetup',
    url: `${homepage}/historical-session-setup/:id`,
    private: true,
    menu: 'sessions',
  },
  launch: {
    key: 'launch',
    url: `${homepage}/launch/:type`,
    private: true,
    menu: 'launch',
  },
  operativeSession: {
    key: 'operativeSession',
    url: `${homepage}/operative-session/:id`,
    private: true,
    menu: 'sessions',
  },
  marketData: {
    key: 'marketData',
    url: `${homepage}/market-data`,
    private: true,
    menu: 'marketData',
  },
  profile: {
    key: 'profile',
    url: `${homepage}/profile`,
    private: true,
    menu: 'profile',
  },
  admin: {
    key: 'admin',
    url: `${homepage}/users-setup`,
    private: true,
    menu: 'admin',
  },
  labelEditor: {
    key: 'labelEditor',
    url: `${homepage}/label-editor`,
    private: true,
    menu: 'admin',
  },
  //Authentication Routes
  login: {
    key: 'login',
    url: `${homepage}/login`,
    private: false,
    menu: '',
  },
  resetPasswordRequest: {
    key: 'reset-password-request',
    url: `${homepage}/reset-password-request`,
    private: false,
    menu: '',
  },
  resetPassword: {
    key: 'reset-password',
    url: `${homepage}/reset-password`,
    private: false,
    menu: '',
  },
  systemInfo: {
    key: 'systemInfo',
    url: `${homepage}/system-info`,
    private: false,
    menu: '',
  },
  playground: {
    key: 'playground',
    url: `${homepage}/playground`,
    private: false,
    menu: '',
  },
  playgroundTheme: {
    key: 'playgroundTheme',
    url: `${homepage}/playground-theme`,
    private: false,
    menu: '',
  },
  //Error Routes
  notFoundPage: {
    key: 'not-found',
    url: `${homepage}/page-not-found`,
    private: false,
    menu: '',
  },
  errorPage: {
    key: 'error',
    url: `${homepage}/error`,
    private: false,
    menu: '',
  },
}

export default routes
