import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ExtendedDataNode } from 'types/ui'
import { setCurrentNode } from 'store/pages/setup-session/actions'
import { CrudOperation } from 'types/enums'
import { generateResourceNewTree } from 'services/treeService'

import { selectResources } from 'store/resources/selectors'
import HistoricalStrategyTreeView from 'components/HistoricalStrategyTreeView'
import * as api from 'api/historicalResources'
import { UsedHistoricalResources } from 'types/api'

const HistoricalAssetTreeView: React.FC<{sessionId: number}> = ({sessionId}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { portfolios, strategies, assets, groups } = useSelector(selectResources)
  const [usedResources, setUsedResources] = useState<Array<UsedHistoricalResources>>([])
  const [tree, setTree] = useState<Array<ExtendedDataNode>>()
  const [filter, setFilter] = useState<string>(null)

  useEffect(() => {
    // Fetch resources used by historical session
    api.getHistoricalUsedResource(sessionId)
      .then(data => {
        // console.log('data', data)
        setUsedResources(data.data.historical_data_asset_model_instance_v)
      })
      .catch(err => {
        setUsedResources([])
        console.error('Unable to fetch used resources data: ', err)
      })
  }, [])

  useEffect(() => {
    if (!assets.data.length) {
      return
    }

    const usedAssets = usedResources.length ? usedResources.map(res => res?.asset_id) : []
    const usedGroups = usedResources.length ? usedResources.map(res => res?.group_id) : []
    const usedPortfolios = usedResources.length ? usedResources.map(res => res?.portfolio_id) : []
    const usedStrategies = usedResources.length ? usedResources.map(res => res?.strategy_id) : []

    const historicalAssets = assets.data.filter(asset => usedAssets.includes(asset.id))
    const historicalGroups = groups.data.filter(group => usedGroups.includes(group.id))
    const historicalPortfolios = portfolios.data.filter(portfolio => usedPortfolios.includes(portfolio.id))
    const historicalStrategies = strategies.data.filter(strategy => usedStrategies.includes(strategy.id))

    // Grouped Tree
    const tree = generateResourceNewTree(
      historicalStrategies,
      historicalPortfolios,
      historicalGroups,
      historicalAssets,
      t('transfer.allStrategies'),
      filter,
    )
    setTree(tree)

  }, [filter, assets, groups, usedResources])

  if (!tree) {
    return null
  }

  const onNodeClicked = (node: ExtendedDataNode) => {
    if (node.resource) {
      dispatch(setCurrentNode({ node: node, operation: CrudOperation.Read }))
    }
  }

  const onFilterChange = (newFilter: string) => {
    setFilter(newFilter)
  }

  return (
    <HistoricalStrategyTreeView
      treeData={[...tree]} // Pass a copy of the tree to force re-render
      onNodeClicked={onNodeClicked}
      onFilterChange={onFilterChange}
    />
  )
}

export default HistoricalAssetTreeView
