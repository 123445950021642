import React from 'react'
import { useTranslation } from 'react-i18next'
import AppContentCard from 'components/AppContentCard'
import { Typography, Form, Input, Button, Row, Col, Tooltip } from 'antd'
import styles from './styles.module.scss'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import FormField from 'components/FormField'
import { LoadingState } from 'types/enums'
import cn from 'classnames'
import { selectErrorCode, selectUser } from 'store/user/selectors'
import { useSelector } from 'react-redux'
import { VALIDATE_PASSWORD_REGEX } from 'core'
import { getResponseMessage } from 'core/responseHelper'

const { Paragraph } = Typography

interface Props {
  changePassword: (data: Json) => void
  loadingState: LoadingState
}

const ChangePassword: React.FC<Props> = ({ changePassword, loadingState }) => {
  const { t } = useTranslation()
  const user = useSelector(selectUser)
  const errorCode = useSelector(selectErrorCode)
  const updating = loadingState === LoadingState.Updating

  const hasReservedData = (value: string): boolean => {
    const vv = value.toLowerCase()
    if (vv.includes(user.name.toLowerCase()) || vv.includes(user.surname.toLowerCase())) {
      return true
    }
    return false
  }

  const validationSchema = yup.object({
    oldPassword: yup.string().required(t('error.requiredField')),
    newPassword: yup
      .string()
      .required(t('error.requiredField'))
      .matches(VALIDATE_PASSWORD_REGEX, t('login.errors.requirementsPassword'))
      .test({
        message: t('error.passwordHasSensitiveData'),
        test: (value: string) => !hasReservedData(value),
      }),
    confirmation: yup
      .string()
      .required(t('error.requiredField'))
      .oneOf([yup.ref('newPassword'), null], t('login.errors.matchPassword')),
  })
  const { control, handleSubmit } = useForm({ resolver: yupResolver(validationSchema) })

  const getActionMessage = () => {
    switch (loadingState) {
      case LoadingState.UpdateFailure:
        return t(getResponseMessage(errorCode))
      case LoadingState.Updated:
        return t('user.passwordChangeSuccess')
      case LoadingState.Loaded:
        return ''
    }
  }

  const message = getActionMessage()

  return (
    <AppContentCard fullHeight className={styles['change-password']}>
      <section className="p-10 user-info">
        <Row>
          <Col className="pb-40" flex={'auto'}>
            <Typography.Title level={2}>{t('user.changePassword')}</Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col>
            <form onSubmit={handleSubmit(changePassword)}>
              <Controller
                control={control}
                name="oldPassword"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormField error={error}>
                    <Input.Password
                      autoComplete="off"
                      onChange={onChange}
                      value={value}
                      placeholder={t('user.oldPassword')}
                      className="mt-20"
                    />
                  </FormField>
                )}
              />
              <Controller
                control={control}
                name="newPassword"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormField error={error}>
                    <Tooltip
                      title={<span>{t('user.passwordTooltip')}</span>}
                      placement="right"
                      trigger="hover"
                    >
                      <Input.Password
                        autoComplete="new-password"
                        onChange={onChange}
                        value={value}
                        placeholder={t('user.newPassword')}
                        className="mt-20"
                      />
                    </Tooltip>
                  </FormField>
                )}
              />
              <Controller
                control={control}
                name="confirmation"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormField error={error}>
                    <Input.Password
                      autoComplete="new-password"
                      onChange={onChange}
                      value={value}
                      placeholder={t('user.confirmPassword')}
                      className="mt-20"
                    />
                  </FormField>
                )}
              />
              <Form.Item>
                <Button className="mt-40" size="large" type="dashed" htmlType="submit" loading={updating}>
                  {t('buttons.updatePassword')}
                </Button>
              </Form.Item>
            </form>
          </Col>
        </Row>
        {message && (
          <Paragraph
            className={cn({
              success: loadingState === LoadingState.Updated,
              failed: loadingState === LoadingState.UpdateFailure,
            })}
          >
            {message}
          </Paragraph>
        )}
      </section>
    </AppContentCard>
  )
}

export default ChangePassword
