import React, { useContext, memo } from 'react'
import { Row, Col, Button, Popover, Typography } from 'antd'
import * as Store from 'types/store'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import Trade from 'v2/components/frames/AssetCandleStick/Trade'
import LastJITrade from 'v2/components/frames/AssetCandleStick/LastJITrade'
import OpenOperation from './OpenOperation'
import AssetCartesianLastReturn from './AssetCartesianLastReturn'
import Operations from './Operations'
import BestBidAsk from './BestBidAsk'
import { getTimeFrameLabel, renderAvailableData, selectPopupContainer } from 'core/helpers'
import TreeImage from 'assets/icon/tree-view.svg'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
import CandlestickView from 'v2/components/frames/AssetCandleStick/CandlestickView'

interface Props {
  classname?: string
  sigmas: Store.AssetSigmas
  strategy: Store.Strategy
  portfolio: Store.Portfolio
}

const DetailsView: React.FC<Props> = ({ sigmas, strategy, portfolio, classname }) => {
  const { t } = useTranslation()
  const context = useContext(CandleStickFrameContext)
  const { timeFrame, resource, isFullscreen } = context
  const asset = resource as Store.Asset
  const securityBidAsk = sigmas?.securityBidAsk
  const trade = sigmas?.securityTrade
  const { bidPrice, bidTotalSize, askPrice, askTotalSize } = { ...securityBidAsk }

  const assetDetails = (
    <>
      <Row>
        <Col span={12}>{t('general.strategy')}</Col>
        <Col span={12}>{strategy.name}</Col>
      </Row>
      <Row>
        <Col span={12}>{t('strategies.target')}</Col>
        <Col span={12}>{strategy.target}</Col>
      </Row>
      <Row>
        <Col span={12}>{t('general.portfolio')}</Col>
        <Col span={12}>{portfolio.name}</Col>
      </Row>
    </>
  )

  return (
    <div className="details-candlestick-wrapper">
      <div className={classnames('details-view nonDraggable JI-RENDER-details-candlestick-wrapper', classname)}>
        <Row className="mb-10 details__top-row trade-typography">
          <Col className="trade-values" span={8}>
            <Typography.Title level={2}>{t('chart.bestBid')}</Typography.Title>
            <Typography.Title level={3}>{renderAvailableData(bidPrice)}</Typography.Title>
            <Typography.Title level={4}>{renderAvailableData(bidTotalSize)}</Typography.Title>
          </Col>

          <Col span={8}>
            <div className="trade-clock">
              <Typography.Title level={2}>{t('chart.trade')}</Typography.Title>
              <Typography.Title level={3}>{renderAvailableData(trade?.price)}</Typography.Title>
              <Typography.Title level={4}>{renderAvailableData(trade?.size)}</Typography.Title>
            </div>
          </Col>

          <Col className="trade-values" span={8}>
            <Typography.Title level={2}>{t('chart.bestAsk')}</Typography.Title>
            <Typography.Title level={3}>{renderAvailableData(askPrice)}</Typography.Title>
            <Typography.Title level={4}>{renderAvailableData(askTotalSize)}</Typography.Title>
          </Col>
        </Row>

        <Row className="trade-section trade-typography">
          <Col span={24} className="trade-col">
            <Popover
              placement="top"
              title={t('general.assetDetails')}
              content={assetDetails}
              trigger="click"
              getPopupContainer={(trigger) => selectPopupContainer(trigger, isFullscreen)}
            >
              <Button className="tree-image-icon nonDraggable">
                <img src={TreeImage} width="36" alt="" />
              </Button>
            </Popover>
            <>
              <Trade trade={sigmas?.securityTrade} />
              <BestBidAsk securityBidAsk={sigmas?.securityBidAsk} />
            </>
          </Col>
        </Row>

        <div className="operations-wrapper">
          <Row className="open-operation">
            <LastJITrade jiTrade={sigmas?.jiTrade} />
            <OpenOperation asset={asset} baseCurrency={strategy.baseCurrency} openOperation={sigmas?.openOperation} />
          </Row>

          <Row className="tdy-operations">
            <div className="tdy-operations__title-wrapper">
              <Typography.Title level={2} className="tdy-operations__title">
                {getTimeFrameLabel(timeFrame)} {t('chart.operations')}
              </Typography.Title>
            </div>
            <div className="tdy-operations__content">
              <Operations timeFrame={timeFrame} operations={sigmas?.assetCartesianOperationsList} />
              <AssetCartesianLastReturn
                timeFrame={timeFrame}
                data={sigmas?.assetCartesianLastReturnList}
                currency={asset.currency}
                baseCurrency={strategy.baseCurrency}
              />
            </div>
          </Row>
        </div>
      </div>

      <CandlestickView />
    </div>
  )
}

export default memo(DetailsView)
