import produce from 'immer'
import { AnyAction } from 'redux'
import { LoadingState, SessionStatus } from 'types/enums'
import * as K from './constants'
import * as Store from 'types/store'

// The initial state of the Reducer
export const initialState: Store.OperativeSessionPageState = {
  assets: [],
  session: null,
  state: LoadingState.Unset,
  viewType: 'graph',
  positionMonitoring: {
    assets: null,
  },
  returnAnalysis: {
    assets: null,
  },
}


const reducer = (state = initialState, action: AnyAction): Store.OperativeSessionPageState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case K.FETCH_OPERATIVE_SESSION_SUCCESS:
        const session = action.payload.session as Store.Session
        draft.session = session
        draft.assets = action.payload.assets as Array<Store.Asset>
        draft.positionMonitoring.assets = []
        draft.returnAnalysis.assets = []
        break

      case K.UPDATE_ASSETS_STATUS_SUCCESS:
        draft.assets = action.payload as Array<Store.Asset>
        break

      case K.SET_VIEW_TYPE:
        draft.viewType = action.payload
        break
      case K.SET_SESSION_PERIOD_PARAMS:
        const params = action.payload
        draft.session.periodParams = params
        break

      case K.UPDATE_POSITION_MONITORING_ASSETS:
        draft.positionMonitoring.assets = action.payload as Store.Asset[]
        break
      case K.UPDATE_RETURN_ANALYSIS_ASSETS:
        draft.returnAnalysis.assets = action.payload as Store.Asset[]
        break

      case K.CHANGE_SESSION_STATUS:
        draft.session.status = action.payload as SessionStatus
        break

      case K.SET_STATUS:
        draft.state = action.payload as LoadingState
        break
    }
  })
}

export default reducer
