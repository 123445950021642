import React from 'react'
import IconFont from 'core/IconFont'

import { Breadcrumb as AntDBreadcrumb } from 'antd'
import styles from './breadcrumb.module.scss'
import cn from 'classnames'

interface Props {
  slices: Array<string>
  className?: string
}

const Breadcrumb: React.FC<Props> = ({ slices, className }) => {
  return (
    <AntDBreadcrumb
      separator={<IconFont type="icon-angle-white" />}
      className={cn(styles.breadcrumb, className)}
    >
      {slices.map((slice, i) => (
        <AntDBreadcrumb.Item key={`${slice}${i}`}>{slice}</AntDBreadcrumb.Item>
      ))}
    </AntDBreadcrumb>
  )
}

export default Breadcrumb
