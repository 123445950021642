import { Button, Form, Input, Row, Space } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import AppContentCard from 'components/AppContentCard'
import { useForm } from 'antd/lib/form/Form'
import { SessionType } from 'types/enums'

interface Props {
  onCancel: () => void
  onConfirm: (layoutName: string, sessionType: SessionType) => void
  isLoading: boolean
  sessionType: SessionType
}

const CreateLayout: React.FC<Props> = ({ onCancel, onConfirm, isLoading, sessionType }) => {
  const { t } = useTranslation()
  const [form] = useForm()

  const onFinish = (data: Json) => {
    onConfirm(data.layoutName, sessionType)
  }

  return (
    <Modal centered open title={null} footer={null} closable={false}>
      <AppContentCard>
        <Form form={form} onFinish={onFinish}>
          <Form.Item name="layoutName" rules={[{ required: true }]}>
            <Input placeholder={t('operativeSession.layoutNamePlaceholder')} />
          </Form.Item>

          <Row justify="end" className="mt-30">
            <Space>
              <Button type="dashed" onClick={onCancel} disabled={isLoading}>
                {t('general.cancel')}
              </Button>
              <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
                {t('general.ok')}
              </Button>
            </Space>
          </Row>
        </Form>
      </AppContentCard>
    </Modal>
  )
}

export default CreateLayout
