import { config } from 'core'
import Debug from 'debug'

const debug = new Debug('sockets')
const connectionTimeout = config.socketOptions.timeout
export default class SocketConnection {
  constructor(url: string, onOpen: (client: WebSocket) => void, onMessage: (message: MessageEvent) => void) {
    this.connect(url, onOpen, onMessage)
  }

  attept = 1
  client: WebSocket = null
  closed = false
  timeOut: NodeJS.Timeout = null

  connect = (
    url: string,
    onOpen: (client: WebSocket) => void,
    onMessage: (message: MessageEvent) => void,
  ) => {
    const client = new WebSocket(url)

    client.onclose = () => {
      if (!this.closed) {
        this.timeOut = setTimeout(() => {
          this.attept++
          this.connect(url, onOpen, onMessage)
          this.timeOut = null
          debug(`reconnect attept: ${this.attept}`)
        }, connectionTimeout)
      }
    }

    client.onopen = () => onOpen(client)

    client.onmessage = onMessage

    this.client = client
  }

  close = () => {
    this.closed = true
    this.client.close()
    this.timeOut && clearTimeout(this.timeOut)
    debug('SocketConnection is closed by client')
  }
}
