import analysisIndicators from 'sockets/portfolios/portfolio_analysis_indicators_pb'
import operations from 'sockets/portfolios/portfolio_operations_pb'
import returns from 'sockets/portfolios/portfolio_return_pb'
import position from 'sockets/portfolios/portfolio_position_pb'
import referenceData from 'sockets/portfolios/portfolio_reference_data_pb'
import * as Store from 'types/store'
import { PortfolioFeed } from 'types/enums.api'
import {
  mapPortfolioAnalysisIndicatorsSigmas,
  mapPortfolioOperationsSigmas,
  mapPortfolioPositionSigmas,
  mapPortfolioReferenceDataSigmas,
  mapPortfolioReturnsSigmas,
} from 'services/protoMappers/portfolio'

export const deserializePortfolioBuffer = (
  buffer: ArrayBuffer,
  service: PortfolioFeed,
  subscriptionType: Store.SubscriptionType,
): [string, Store.PortfolioSigmas] => {
  const bytes = new Uint8Array(buffer)

  switch (service) {
    case PortfolioFeed.AnalysisIndicators: {
      const { feed_event } = analysisIndicators as any
      const deserializeData = feed_event.deserializeBinary(bytes)
      const feed = feed_event.toObject(false, deserializeData)
      const data = feed[subscriptionType]
      if (feed.alive || !data) {
        return
      }
      const sigmas = mapPortfolioAnalysisIndicatorsSigmas(data)

      return [data.key, sigmas]
    }

    case PortfolioFeed.Return: {
      const { feed_event } = returns
      const deserializeData = feed_event.deserializeBinary(bytes)
      const feed = feed_event.toObject(false, deserializeData)
      const data = feed[subscriptionType]
      if (feed.alive || !data) {
        return
      }
      const sigmas = mapPortfolioReturnsSigmas(data)
      return [data.key, sigmas]
    }

    case PortfolioFeed.Operations: {
      const { feed_event } = operations
      const deserializeData = feed_event.deserializeBinary(bytes)
      const feed = feed_event.toObject(false, deserializeData)
      const data = feed[subscriptionType]
      if (feed.alive || !data) {
        return
      }
      const sigmas = mapPortfolioOperationsSigmas(data)
      return [data.key, sigmas]
    }

    case PortfolioFeed.Position: {
      const { feed_event } = position
      const deserializeData = feed_event.deserializeBinary(bytes)
      const feed = feed_event.toObject(false, deserializeData)
      const data = feed[subscriptionType]
      if (feed.alive || !data) {
        return
      }
      // Incomplete We don't use these
      const sigmas = mapPortfolioPositionSigmas(data)
      return [data.key, sigmas]
    }

    case PortfolioFeed.ReferenceData: {
      const { feed_event } = referenceData
      const deserializeData = feed_event.deserializeBinary(bytes)
      const feed = feed_event.toObject(false, deserializeData)
      const data = feed[subscriptionType]
      if (feed.alive || !data) {
        return
      }

      const sigmas = mapPortfolioReferenceDataSigmas(data)
      return [data.key, sigmas]
    }
  }
}
