import * as K from './constants'
import * as Api from 'types/api'
import * as Store from 'types/store'
import { LoadingState } from 'types/enums'
import { AnyAction } from 'redux'

export function fetchGroups(): AnyAction {
  return {
    type: K.GROUPS_FETCH,
    payload: null,
  }
}

export function fetchHistoricalGroups(sessionId: number): AnyAction {
  return {
    type: K.HISTORICAL_GROUPS_FETCH,
    payload: sessionId,
  }
}

export function createGroup(data: Api.GroupRest): AnyAction {
  return {
    type: K.GROUP_CREATE,
    payload: data,
  }
}

export function updateGroup(data: Api.GroupRest): AnyAction {
  return {
    type: K.GROUP_UPDATE,
    payload: data,
  }
}

export function deleteGroup(data: Store.Group): AnyAction {
  return {
    type: K.GROUP_DELETE,
    payload: data,
  }
}

export function setLoadingState(loadingState: LoadingState): AnyAction {
  return {
    type: K.SET_STATUS,
    payload: loadingState,
  }
}
