import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { GridItemActions } from 'types/ui'
import * as Store from 'types/store'
import { removeFrame } from 'store/dashboard/actions'
import SignalsTable from 'v2/components/frames/SignalsPanel'
import { useSelector } from 'react-redux'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import * as api from 'api/historicalTables'
import * as Api from 'types/api'
import { isApiError, isGraphQlError } from 'core'
import { jsonToSignals } from 'services/store/mapHistoricalTables'

const getHistoricalSignalsData = async (sessionId: number) => {
  const response = await api.getSignalsTable(sessionId)

  if (isApiError(response) || isGraphQlError(response)) {
    return null
  }

  const data = response as Api.HistoricalSignals
  return jsonToSignals(data)
}

interface Props {
  frame: Store.Frame
}

const SignalsPanelFrame: React.FC<Props> = ({ frame }) => {
  const dispatch = useDispatch()
  const session = useSelector(selectOperativeSessionSession)
  const [signals, setSignals] = useState<Store.SignalsData[]>([])

  useEffect(() => {
    const getData = async () => {
      const data = await getHistoricalSignalsData(session.id)
      if (data) {
        setSignals(data)
      }
    }
    getData()
  }, [])

  const onItemClick = (action: GridItemActions) => {
    switch (action) {
      case GridItemActions.Close:
        dispatch(removeFrame(frame))
        break
    }
  }

  return <SignalsTable data={signals} onItemClick={onItemClick} isOffline={false} />
}

export default SignalsPanelFrame
