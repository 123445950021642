import React from 'react'
import { Spin } from 'antd'
import classNames from 'classnames'
import styles from './styles.module.scss'

const Spinner: React.FC = () => {
  return (
    <div className={classNames(styles['spinner'], 'spinner')}>
      <Spin />
    </div>
  )
}

export default Spinner
