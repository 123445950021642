import React from 'react'
import { ChartChild } from 'types/chart'

const Clipper: React.FC<ChartChild> = ({ chartWidth: width, chartHeight: height, clipId }) => {
  return (
    <defs>
      <clipPath id={clipId}>
        <rect width={width} height={height} />
      </clipPath>
    </defs>
  )
}

export default Clipper
