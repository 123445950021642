import React from 'react'
import { Col, Row, Typography } from 'antd'
import * as Store from 'types/store'

import { useTranslation } from 'react-i18next'
import { formatGliphNumber } from 'core/formats'
import TDYYTDTable from './TDYYTDTable'
import styles from './tables.module.scss'
import { TimeFrame } from 'types/enums'

interface Props {
  data: Store.ReturnAnalysis[][]
}

const ReturnAnalysisTable: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation()

  const tdyData = data.map((item) => {
    return item.find((returnAnalysis) => returnAnalysis.type === TimeFrame.TDY)
  })

  const ytdData = data.map((item) => {
    return item.find((returnAnalysis) => returnAnalysis.type === TimeFrame.YTD)
  })

  const ytdSum = ytdData.reduce((sum, value) => sum + (value.value || 0), 0)
  const tdySum = tdyData.reduce((sum, value) => sum + (value.value || 0), 0)

  return (
    <Row className={styles['tables']}>
      <Col span={24} className="tables-container">
        <div className="left-table nonDraggable">
          <div className="tables-top-row trading-return-card">
            <div className="title-wrapper">
              <Typography.Title level={2}>{t('table.column.tdyTradingReturn')}</Typography.Title>
            </div>

            <Typography.Title level={3}>{`${formatGliphNumber(tdySum)} EUR`}</Typography.Title>
          </div>
          <TDYYTDTable data={tdyData} />
        </div>
        <div className="right-table nonDraggable">
          <div className="tables-top-row trading-return-card">
            <div className="title-wrapper">
              <Typography.Title level={2}>{t('table.column.ytdTradingReturn')}</Typography.Title>
            </div>

            <Typography.Title level={3}>{`${formatGliphNumber(ytdSum)} EUR`}</Typography.Title>
          </div>
          <TDYYTDTable data={ytdData} />
        </div>
      </Col>
    </Row>
  )
}

export default ReturnAnalysisTable
