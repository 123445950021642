import React, { useContext } from 'react'
import { Button, Dropdown, Menu, Typography } from 'antd'
import CandlestickView from './common/CandlestickView'
import ViewTypeSelector from '../ViewTypeSelector'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
import { t } from 'core'
import * as Store from 'types/store'
import { ViewType } from 'types/ui'
import { SessionStatus, TimeFrame } from 'types/enums'
import { DownOutlined } from '@ant-design/icons'
import AssetStatusDropdownContainer from 'v2/containers/CandleStick/Strategy/AssetStatusDropdown'
import { renderAvailableData, selectPopupContainer } from 'core/helpers'
import DetailsView from './common/DetailsView'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import { useSelector } from 'react-redux'

interface Props {
  sigmas: Store.StrategySigmas
}

const PanelContent: React.FC<Props> = ({ sigmas }) => {
  const context = useContext(CandleStickFrameContext)
  const { viewType, timeFrame, resource, isFullscreen } = context
  const strategy = resource as Store.Strategy
  const session = useSelector(selectOperativeSessionSession)

  const isBackTest = timeFrame === TimeFrame.TOT

  const isStatusChangeDisabled =
    isBackTest || session.status === SessionStatus.Broken || session.status !== SessionStatus.Running

  const onChangeView = (viewType: ViewType) => {
    context.setViewType(viewType)
  }

  const onChangeTimeFrame = (timeFrame: TimeFrame) => {
    context.setTimeFrame(timeFrame)
  }

  const timeFrameMenu = (
    <Menu className="nonDraggable" onClick={({ key }) => onChangeTimeFrame(key as TimeFrame)}>
      <Menu.Item key={TimeFrame.TDY}>{t('general.TDY')}</Menu.Item>
      <Menu.Item key={TimeFrame.YTD}>{t('general.YTD')}</Menu.Item>
    </Menu>
  )

  return (
    <>
      <div className="strategy-description-row nonDraggable">
        <Typography.Paragraph className="title-value">
          {t('chart.currency')}
          <span>{renderAvailableData(sigmas?.cartesianHeading?.baseCurrency)}</span>
        </Typography.Paragraph>
        <Typography.Paragraph className="title-value">
          {t('chart.prtNum')}
          <span>{renderAvailableData(sigmas?.cartesianHeading?.portfoliosNum)}</span>
        </Typography.Paragraph>
        <Typography.Paragraph className="title-value">
          {t('chart.assetNum')} <span>{renderAvailableData(sigmas?.cartesianHeading?.assetsNum)}</span>
        </Typography.Paragraph>
        <Typography.Paragraph className="title-value">
          {t('chart.activatedModelsNum')}
          <span>{renderAvailableData(sigmas?.cartesianHeading?.activatedModelsNum)}</span>
        </Typography.Paragraph>
      </div>

      <div className="strategy-config-row nonDraggable">
        <Dropdown
          overlay={timeFrameMenu}
          trigger={['click']}
          getPopupContainer={(trigger) => selectPopupContainer(trigger, isFullscreen)}
          className="dropdown dropdown--time-horizon"
          disabled={isBackTest}
        >
          <Button type="dashed">
            {timeFrame} <DownOutlined />
          </Button>
        </Dropdown>

        {!isStatusChangeDisabled && <AssetStatusDropdownContainer strategy={strategy} />}

        <ViewTypeSelector value={viewType} onChange={onChangeView} />
      </div>

      {viewType === ViewType.Details || isFullscreen ? <DetailsView sigmas={sigmas} /> : <CandlestickView />}
    </>
  )
}

export default PanelContent
