import React from 'react'
import { GridItemActions } from 'types/ui'

import { Col, Row, Typography } from 'antd'

import { useTranslation } from 'react-i18next'
import ContextMenu from '../ContextMenu'

interface Props {
  onItemClick: (action: GridItemActions) => void
  title: string
}

const Unavailable: React.FC<Props> = ({ onItemClick, title }) => {
  const { t } = useTranslation()

  return (
    <section>
      <div>
        <div className="frame-header">
          <div className="frame-header__top-row">
            <Typography.Title level={3} className="title">
              {title}
            </Typography.Title>
            <ContextMenu
              isFullscreen={false}
              onMenuItemClick={onItemClick}
              items={[{ key: GridItemActions.Close, label: t('contextMenu.close') }]}
            />
          </div>
        </div>
        <div className="frame-content">
          <Row justify="space-around" align="middle" style={{ height: '100%' }}>
            <Col>{t('operativeSession.missingResource')}</Col>
          </Row>
        </div>
      </div>
    </section>
  )
}

export default Unavailable
