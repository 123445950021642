import AddFrameModal from 'v2/components/settings/modals/AddFrameModal'
import * as Store from 'types/store'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createFrame, removeFrame } from 'store/dashboard/actions'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import { FrameType, SessionType, TimeFrame } from 'types/enums'
import { v4 as uuidv4 } from 'uuid'
import { generateLayout } from 'core/layoutHelper'
import { getViewTypeByFrameType } from 'core/frameHelper'
import debounce from 'debounce'

interface Props {
  initialFrame?: Store.Frame
  visible: boolean
  initialX: number
  initialY: number
  onClose: () => void
}

const AddFrameModalContainer: React.FC<Props> = ({ initialFrame, visible, onClose, initialX, initialY }) => {
  const dispatch = useDispatch()
  const { type: sessionType } = useSelector(selectOperativeSessionSession)
  const timeFrame = sessionType === SessionType.BackTest ? TimeFrame.TOT : TimeFrame.TDY

  //Debounce is required to prevend double click adding 2 frames (#750)
  const dispatchCreate = debounce((frame: Store.Frame) => {
    dispatch(createFrame(frame))
  }, 100)

  const addFrame = (selectedResources: Store.SelectedResource[], type: FrameType) => {
    const id = uuidv4() as string
    const frame: Store.Frame = {
      selectedResources: selectedResources,
      sessionType: sessionType,
      frameType: type,
      options: {
        timeFrame,
        viewType: getViewTypeByFrameType(type),
      },
      id,
      layout: generateLayout(id, type, initialX, initialY),
    }

    frame.sessionType = sessionType
    dispatchCreate(frame)
    // dispatch(createFrame(frame))
  }

  const editFrame = (frame: Store.Frame) => {
    //remove old frame
    dispatch(removeFrame(frame))
    //add updated one (id must change to trigger UI changes)
    const id = uuidv4()
    frame.id = id
    frame.layout.i = id
    dispatchCreate(frame)
    // dispatch(createFrame(frame))
  }

  return (
    <AddFrameModal
      initialFrame={initialFrame}
      onAdd={addFrame}
      onEdit={editFrame}
      sessionType={sessionType}
      visible={visible}
      onClose={onClose}
    />
  )
}

export default AddFrameModalContainer
