import produce from 'immer'
import * as K from './constants'
import * as Store from 'types/store'
import { AnyAction } from 'redux'
import { LoadingState } from 'types/enums'
import { ResponseCode } from 'types/enums.api'

// The initial state of the Reducer
export const initialState: Store.AdminPageState = {
  users: [],
  state: LoadingState.Unset,
  errorCode: null,
}

const reducer = (state = initialState, action: AnyAction): Store.AdminPageState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case K.FETCH_USERS_SUCCESS:
        const users = action.payload as Store.SystemUser[]
        draft.users = users
        draft.state = LoadingState.Loaded
        break
      case K.NEW_USER_SUCCESS:
        const user = action.payload as Store.SystemUser
        draft.users.push(user)
        break
      case K.DELETE_USER_SUCCESS:
        const id = action.payload as number

        draft.users = draft.users.filter((user) => user.id !== id)
        break
      case K.EDIT_USER_SUCCESS:
        const systemUser = action.payload as Store.SystemUser
        draft.users = draft.users.map((user) => {
          if (user.id === systemUser.id) {
            return systemUser
          }
          return user
        })
        break

      case K.SET_LOADING_STATE:
        const loadingState = action.payload as LoadingState
        draft.state = loadingState
        break
      case K.SET_ERROR:
        draft.errorCode = action.payload as ResponseCode
        break
    }
  })
}

export default reducer
