import React from 'react'
import { Row, Col, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'
import { renderAvailableData } from 'core/helpers'
import { formatNumber } from 'core/formats'
import OpenOperationChart from 'charts/OpenOperationChart'

interface Props {
  asset: Store.Asset
  baseCurrency: string
  openOperation: Store.Operation
}


const OpenOperation: React.FC<Props> = ({ asset, openOperation, baseCurrency }) => {
  const { t } = useTranslation()

  return (
    <Col className="circle-col">
      <div className="open-operation__title-wrapper">
        <Typography.Title level={2}>{t('chart.openOperation')}</Typography.Title>
      </div>

      <div className="open-operation-row">
        <div className="open-operation-graph">
          <OpenOperationChart openOperation={openOperation} />
        </div>
        <div className="open-operation-values">
          <div className="title-wrapper">
            <Typography.Title level={2}>{t('chart.return')}</Typography.Title>
          </div>

          <Row align="middle" className="content-wrapper">
            <Col span={24}>
              <Typography.Title level={3}>
                {renderAvailableData(formatNumber(openOperation?.totalTradingReturn))}
                <Typography.Text> {asset.currency}</Typography.Text>
              </Typography.Title>
            </Col>
            {asset.currency !== baseCurrency && (
              <Col span={24}>
                <Typography.Title level={4}>
                  {renderAvailableData(formatNumber(openOperation?.totalTradingReturnBc))}
                  <Typography.Text> {baseCurrency}</Typography.Text>
                </Typography.Title>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </Col>
  )
}

export default OpenOperation
