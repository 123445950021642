import React, { useState } from 'react'
import { Dropdown } from 'antd'
import { ExtendedDataNode, TransferType } from 'types/ui'
import { useTranslation } from 'react-i18next'
import { getAllPortfolios, getAllAssets, getAllStrategies, getAllGroups } from 'helpers/transfer/itemAddHelper'
import { TransferContext } from 'types/enums'

interface Props {
  node: ExtendedDataNode
  onClick: (node: ExtendedDataNode, type: TransferType) => void
  context: TransferContext
}

const AddStrategies: React.FC<Props> = ({ node, onClick, context }) => {
  const { t } = useTranslation()

  const getItems = () => {
    if (node.children.length === 0) {
      return []
    }
    switch (context) {
      case TransferContext.All:
        return [getAllStrategies(node), getAllPortfolios(node), getAllGroups(node),  getAllAssets(node)]
      case TransferContext.AtBacktest:
        return []
      default:
        return [getAllAssets(node)]
    }
  }

  const trigger: any =
    context === TransferContext.AtSessionLaunch ? ['contextMenu'] : ['click', 'contextMenu']

  const [menu, setMenu] = useState([])

  const onVisibleChange = (visible: boolean) => {
    if (visible) {
      setMenu(getItems())
    } else {
      setMenu([])
    }
  }

  const dropDownMenu = menu.map(item => {
    return {
      key: item.key,
      label: t(item.label),
      onClick: () => {onClick(node, item.transferType)},
    }
  })

  return (
    <Dropdown
      menu={{items: dropDownMenu}}
      trigger={trigger}
      getPopupContainer={(trigger) => trigger.parentElement}
      onOpenChange={onVisibleChange}
    >
      <span>{node.title}</span>
    </Dropdown>
  )
}

export default AddStrategies
