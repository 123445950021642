import { Menu, Typography } from 'antd'
import LeftRail from 'components/LeftRail'
import { LeftMenuItem, LeftMenuPageContext } from 'contexts/LeftMenuContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import style from './styles.module.scss'

const { Title } = Typography

const RightMenu: React.FC = () => {
  const { t } = useTranslation()
  const { currentItem, setCurrent } = useContext(LeftMenuPageContext)

  return (
    <LeftRail customWidth={300}>
      <Title>{t('admin.title')}</Title>
      <Menu
        className={style['admin-sidebar']}
        defaultSelectedKeys={[currentItem]}
        onSelect={(info) => setCurrent(info.key as LeftMenuItem)}
      >
        <Menu.Item className="item" key={LeftMenuItem.Users}>
          {t('admin.users')}
        </Menu.Item>
        <Menu.Item className="item" key={LeftMenuItem.Roles}>
          {t('admin.roles')}
        </Menu.Item>
      </Menu>
    </LeftRail>
  )
}

export default RightMenu
