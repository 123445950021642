import React from 'react'
import { ExtendedDataNode, NodeType } from 'types/ui'
import * as Store from 'types/store'
import { getAssetStatusIcon } from 'helpers/transfer/core'
import { Popover } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import colors from 'config/colors'
import { AssetStatus } from 'types/enums'
import { useTranslation } from 'react-i18next'

interface Props {
  node: ExtendedDataNode
}

const ReadItem: React.FC<Props> = ({ node }) => {
  const { t } = useTranslation()

  const getIcon = () => {
    switch (node.type) {
      case NodeType.Asset:
        const asset = node.resource as Store.Asset
        return getAssetStatusIcon(asset.status)
      case NodeType.Portfolio: {
        const isBroken =
          node.children.length > 0 &&
          node.children.some((asset) => (asset.resource as Store.Asset).status === AssetStatus.Broken)
        if (isBroken) {
          return (
            <Popover content={t('error.selectedBrokenAsset')} trigger="hover">
              <ExclamationCircleOutlined style={{ color: colors.red }} className="mr-5" />
            </Popover>
          )
        }
        break
      }
      case NodeType.Strategy:
        const isBroken =
          node.children.length > 0 &&
          node.children.some(
            (portfolio) =>
              portfolio.children.length > 0 &&
              portfolio.children.some(
                (asset) => (asset.resource as Store.Asset).status === AssetStatus.Broken,
              ),
          )
        if (isBroken) {
          return (
            <Popover content={t('error.selectedBrokenAsset')} trigger="hover">
              <ExclamationCircleOutlined style={{ color: colors.red }} className="mr-5" />
            </Popover>
          )
        }
    }
    return null
  }

  return (
    <span>
      {getIcon()}
      {node.title}
    </span>
  )
}

export default ReadItem
