import { t } from 'core/i18n'
import { ResponseCode } from 'types/enums.api'

type ResponseMapping = {
  code: ResponseCode
  label: string
}

const responseMap: ResponseMapping[] = [
  {
    code: ResponseCode.BadRequest,
    label: 'error.badRequest',
  },
  {
    code: ResponseCode.AssetAlreadyExist,
    label: 'error.assetExists',
  },
  {
    code: ResponseCode.AssetDoesNotExist,
    label: 'error.assetDoesNotExist',
  },
  {
    code: ResponseCode.InvalidGrant,
    label: 'error.invalidGrant',
  },
  {
    code: ResponseCode.PortfolioAlreadyExist,
    label: 'error.portfolioExists',
  },
  {
    code: ResponseCode.PortfolioDoesNotExist,
    label: 'error.portfolioDoesNotExist',
  },
  {
    code: ResponseCode.PasswordContainsName,
    label: 'error.passwordCointainsName',
  },
  {
    code: ResponseCode.PasswordContainsSurname,
    label: 'error.passwordContainsSurname',
  },
  {
    code: ResponseCode.PasswordContainsEmail,
    label: 'error.passwordContainsEmail',
  },
  {
    code: ResponseCode.PasswordValidationError,
    label: 'error.passwordValidationError',
  },
  {
    code: ResponseCode.SessionAlreadyExist,
    label: 'error.sessionExists',
  },
  {
    code: ResponseCode.SessionDoesNotExist,
    label: 'error.sessionDoesNotExists',
  },
  {
    code: ResponseCode.UploadError,
    label: 'error.uploadError',
  },
  {
    code: ResponseCode.UserNotExist,
    label: 'error.userNotExist',
  },
  {
    code: ResponseCode.UserExists,
    label: 'error.userExist',
  },
]

export const getResponseMessage = (responseCode: ResponseCode) => {
  const item = responseMap.find((el) => el.code === responseCode)
  if (!item) {
    return t('notification.message.somethingWentWrong')
  }
  return t(item.label)
}
